import type { SorterResult } from 'antd/lib/table/interface';
import { 
  DEFAULT_THREAD_SORT_DIRS, 
  Thread, 
  ThreadSort, 
  ThreadSortField,
} from 'types/savings';

import moment from 'moment';
import { ReactNode, useState } from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { Icon } from 'shared/Icon';
import { StatusOverlay } from 'shared/Overlay';
import { ThreadStatusBadge } from '../ThreadStatusBadge';
import { OpportunitiesBlock } from './OpportunitiesBlock';
import { ThreadStatusFilter } from '../controls/ThreadStatusFilter';
import { useThreadStatusQuery } from '../hooks/threadStatusQuery';
import { useThreadSortQuery } from '../hooks/threadSortQuery';
import { DEFAULT_THREAD_SORT } from 'types/savings';
import {
  renderThreadSecondaryId,
  compareThreadSecondaryIds,

  compareThreadCreatedAt,

  compareThreadNames,
  compareThreadStatuses,
  compareThreadOpporutnityCounts,
  compareThreadSavings,

  onThreadStatusFilter
} from 'helpers/savings';
import { formatCurrency, formatNormal } from 'helpers/formatter';
import { useSavingsStore } from 'store/savings';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { TeamRenderer } from 'shared/TeamRenderer';
import { InputSearch } from 'shared/Input';
import { SectionCaption, SectionTitle } from 'shared/Section';

export interface ThreadsTableProps extends Pick<React.ComponentProps<typeof Table>, 'rowSelection' | 'pagination'>{
  title: ReactNode;
  subtitle?: ReactNode;
  prefilter?: (thread: Thread) => boolean;
  useQuery?: boolean;
  extraActions?: ReactNode;
}

export const ThreadsTable = (props: ThreadsTableProps) => {
  const {
    title,
    subtitle,
    prefilter,
    rowSelection,
    useQuery,
    extraActions,
    pagination = {
      pageSize: 10,
      pageSizeOptions: [10, 20, 50]
    },
  } = props;

  const [statusFilter, setStatusFilter] = useThreadStatusQuery();
  const [inputSerach, setInputSearch] = useState<string>('');

  const [sort, setSort] = useQuery ?
    useThreadSortQuery() :
    useState<ThreadSort>(DEFAULT_THREAD_SORT);
  
  const threads = useSavingsStore((store) => store.getThreads());

  const prefilteredThreads = useDerivedDataEntry(
    { threads, prefilter, inputSerach, statusFilter },
    ({ threads, prefilter, statusFilter }) => {
      const input = inputSerach.trim().toLowerCase();
      let filteredThreads = threads
        .filter((thread) => input === '' || thread.name.toLowerCase().includes(input))
        .filter((thread) => {
          if (statusFilter === 'active') {
            return ['open', 'validation', 'implementation', 'rejected'].includes(thread.status)
          } else if (statusFilter === 'all') {
            return true
          } else {
            return thread.status === statusFilter
          }
        })

      if (!prefilter) {
        return filteredThreads
      }

      return filteredThreads.filter(prefilter)
    }
  );

  const getColumnSort = (field: ThreadSortField) => sort.field === field ? sort.dir : null;

  return (
    <div className='relative'>
      <div className='flex mb-5'>
        <div>
          <div className='flex mb-0.5 gap-4'>
            <SectionTitle>
              {title}
            </SectionTitle>
            <InputSearch
              value={inputSerach}
              onChange={(text) => {
                setInputSearch(text);
              }}
            />
          </div>
          {subtitle && (
            <SectionCaption>
              {subtitle}
            </SectionCaption>
          )}
          <div className='my-2'>
            {extraActions}
          </div>
        </div>
        <div className='mb-auto ml-auto'>
          <ThreadStatusFilter value={statusFilter} onChange={setStatusFilter} />
        </div>
      </div>

      <Table
        className='w-full'
        tableLayout='fixed'
        scroll={{ x: 1740 }}
        size='small'
        rowKey={({ id }) => id}
        dataSource={prefilteredThreads.status === 'success' ? prefilteredThreads.data : []}
        pagination={pagination}
        rowSelection={rowSelection}
        expandable={{
          rowExpandable: (thread) => thread.status !== 'deleted',
          // TODO
          expandedRowRender: (thread) => {
            return (
              <OpportunitiesBlock
                title='Thread opportunities'
                prefilter={{ thread_id: thread.id }}
                ignorePageFilters
              />
            )
          }
        }}
        onChange={(_, _tableFilters, tableSort, meta) => {
          /*
          if (filters && onFilter && meta.action === 'filter') {
            onFilter({
              ...tableFilters,
              costImpact: filters.costImpact
            } as OpportunityFilterValue);
          }
           */

          if (meta.action === 'sort') {
            console.log(tableSort)
            console.log(meta)
            const field = (tableSort as SorterResult<Thread>).columnKey as ThreadSortField || sort.field;
            const dir = sort.field !== field ?
              DEFAULT_THREAD_SORT_DIRS[field] :
              sort.dir === 'ascend' ? 'descend' : 'ascend';

            setSort({ field, dir });
          }
        }}
        columns={[
          {
            key: 'id',
            title: 'ID',
            width: 100,
            fixed: 'left',
            sorter: compareThreadSecondaryIds,
            sortOrder: getColumnSort('id'),
            render: (thread) => (
              <Link
                to={`/savings/threads/${thread.id}`}
                className='!text-caladon-blue font-medium'
              >
                {renderThreadSecondaryId(thread)}
              </Link>
            )
          },
          {
            key: 'name',
            title: 'Name',
            fixed: 'left',
            sorter: compareThreadNames,
            sortOrder: getColumnSort('name'),
            render: ({ id, name }) => (
              <Link
                className='!text-caladon-blue'
                to={`/savings/threads/${id}`}
              >
                {name}
              </Link>
            )
          },
          {
            key: 'costImpactSum',
            title: 'Active Savings / Mo',
            width: 180,
            align: 'right',
            sorter: compareThreadSavings,
            sortOrder: getColumnSort('costImpactSum'),
            render: ( _, thread ) => <div>{formatCurrency(thread.cost_impact_sum * 30)}</div>,
          },
          {
            key: 'platforms',
            title: 'Platform',
            width: 100,
            render: (_, thread) => (
                <>
                  {thread.platforms ? (
                    thread.platforms.map((platform) => <Icon key={platform} icon={platform} className='h-[20px] mr-[5px]' />)
                    ) : <div>None</div>
                  }
                </>
            ),
          },
          {
            key: 'opportunitiesCount',
            title: '# of Opportunities',
            width: 180,
            sorter: compareThreadOpporutnityCounts,
            sortOrder: getColumnSort('opportunitiesCount'),
            render: (_, thread) => <div>{formatNormal(thread.opportunities_count ?? 0)}</div>,
          },
          {
            key: 'status',
            title: 'Status',
            width: 180,
            sorter: compareThreadStatuses,
            sortOrder: getColumnSort('status'),
            //filters: statusFilters,
            onFilter: onThreadStatusFilter,
            render: ({ status }) => <ThreadStatusBadge>{status}</ThreadStatusBadge>
          },
          {
            key: 'team',
            title: 'Team',
            width: 180,
            render: ({ team_id }) => team_id ? <TeamRenderer id={team_id} /> : '-'
          },
          {
            key: 'assignee',
            title: 'Assignee',
            width: 240,
            render: ({ assignee }) => assignee ? `${assignee.name} (${assignee.email})` : '–'
          },
          {
            key: 'createdBy',
            title: 'Created By',
            width: 180,
            render: ({ owner }) => owner ? owner.name : '-'
          },
          {
            key: 'createdAt',
            title: 'Created At',
            width: 120,
            sorter: compareThreadCreatedAt,
            sortOrder: getColumnSort('createdAt'),
            render: ({ created_at }) => created_at ? moment(created_at).format('YYYY/MM/DD') : '-'
          }
        ]}
      />

      <StatusOverlay status={prefilteredThreads.status} />
    </div>
  );
}
