import type { Widget } from 'types/dashboards';
import {
  isCostViewChartWidget,
  isUnitMetricChartWidget
} from 'types/dashboards'

import { EmptyWidgetBlock } from './EmptyWidget';
import { CostViewChartWidgetBlock } from './CostViewChartWidget';
import { UnitMetricChartWidgetBlock } from './UnitMetricChartWidget';

export interface WidgetBlockProps {
  widget: Widget;
  onChange: (widget: Widget) => void;
  onRemove: () => void;
}

export const WidgetBlock = (props: WidgetBlockProps) => {
  const {
    widget,
    onChange,
    onRemove
  } = props;

  if (isCostViewChartWidget(widget)) {
    return (
      <CostViewChartWidgetBlock
        widget={widget}
        onChange={onChange}
        onRemove={onRemove}
      />
    );
  }

  if (isUnitMetricChartWidget(widget)) {
    return (
      <UnitMetricChartWidgetBlock
        widget={widget}
        onChange={onChange}
        onRemove={onRemove}
      />
    );
  }

  return (
    <EmptyWidgetBlock
      widget={widget}
      onChange={onChange}
      onRemove={onRemove}
    />
  );
};
