import type { ConstructorRules, FilterStringValueCondition, SingleRule } from 'types/filters';
import type { RuleValueCondition } from 'types/rules';
import type { RuleNode } from 'types/rules';

import { isRootRule, isChildRule } from 'types/filters';
import { getOptions } from 'helpers/filters';
import {mergeArrays} from 'helpers/array';

export const getSiblingValues = (children: RuleNode[], index: number): string[] | Record<string, string>[] => {
  const result: (string | Record<string, string>)[] = [];

  children.forEach((child, childIndex) => {
    if (childIndex !== index && child.condition) {
      result.push(...child.condition.values)
    }
  });

  return result as string[] | Record<string, string>[];
}

export const getChildTagKeys = (node: RuleNode): string[] => {
  const ownKeys: string[] = node.tags ?
    node.tags.map(([key]) => key).filter(Boolean) as string[] :
    [];

  const childrenKeys = node.children ?
    node.children.map(getChildTagKeys) : 
    [];

  const keys = mergeArrays(ownKeys, ...childrenKeys);

  return keys;
}

export const getChildTags = (node: RuleNode): { [key: string] : string[] } => {
  const tags: { [key: string]: string[] } = node.tags ?
    node.tags.reduce((acc, [key, value]) => key ? {
      ...acc,
      [key]: value ? [value] : []
    } : acc, {}) :
    {};

  const childrenTags = node.children ?
    node.children.map(getChildTags) : 
    [];

  childrenTags.forEach((childTags) => {
    Object.entries(childTags).forEach(([key, values]) => {
      if (!tags[key]) {
        tags[key] = values;
      } else {
        tags[key] = mergeArrays(tags[key], values);
      }
    });
  });

  return tags;
}

export const getNodeConstructorRule = (
  constructorRules: ConstructorRules,
  parentConditions: RuleValueCondition[],
  field: string
): SingleRule => {
  let rule = constructorRules[field];

  if (!rule) {
    throw new Error('');
  }

  if (isRootRule(rule)) {
    rule = {
      ...rule,
      children: undefined
    };
  }

  if (isChildRule(rule)) {
    const parentFields = [...rule.parents];
    const relevantParentConditions = parentFields.map((field) => {
      const conditions = parentConditions.filter((condition) => condition.field_name === field);

      if (!conditions.length) {
        throw new Error('');
      }

      return conditions as FilterStringValueCondition[];
    });

    const options = getOptions(relevantParentConditions, rule);
    
    rule = {
      ...rule,
      parents: undefined,
      children: undefined,
      options
    };
  }

  return {
    ...rule,
    regexAllowed: false,
    exclusionAllowed: false
  };
}
