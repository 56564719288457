import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { message } from 'antd'
import SpashPage from 'shared/Page/SplashPage'
import { Icon } from 'shared/Icon/Icon'
import { useGlobalState } from 'state/globalState'
import { ReactComponent as LogoLines } from 'assets/images/LogoLines.svg'
import { Section } from 'shared/Section'
import { singleSignOnCallback } from 'services/auth'

export const SSOCallback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const init = useGlobalState((store) => store.init);

  const validateCode = async () => {
    const code = searchParams.get('code')
    if (!code) return

    singleSignOnCallback(code).then(() => {
      init().then(() => {
        navigate('/')
      })
    }).catch((e) => {
      message.error('SSO Error');
      navigate('/login')
    })
  }

  useEffect(() => {
    validateCode();
  }, [])

  return (
    <SpashPage>
      <Section className="flex flex-col items-center mt-[50px] md:px-[200px] py-[40px] px-[10px]">
        <LogoLines />
        <h5 className="text-center mt-[30px]">Logging in with SSO</h5>
        <Icon className="mt-[50px] w-[40px] h-[40px] text-cyanine-blue" icon="spinner" spin />
      </Section>
    </SpashPage>
  )
}
