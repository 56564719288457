import type { ReactElement } from 'react';
import type { DataEntry } from 'types/dataEntries';
import type { Team } from 'types/teams';

import { useTeamsStore } from 'store/teams';

export interface TeamRendererProps {
  id: number;
  children?: (team: DataEntry<Team>) => ReactElement;
}

const defaultRenderer = (team: DataEntry<Team>) => <>{team.status === 'success' ? team.data.name : '-'}</>;

export const TeamRenderer = (props: TeamRendererProps): ReactElement | null => {
  const {
    id,
    children = defaultRenderer
  } = props;

  const team = useTeamsStore((store) => store.getEntry(id));

  return children(team) || null;
};
