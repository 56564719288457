export type TabSize = 'lg' | 'md' | 'sm' | 'xs';

export interface TabProps<TValue extends string = string> {
  size?: TabSize;
  value: TValue;
  selected?: boolean;
  onClick?: (value: TValue) => void;
  children?: React.ReactNode;
}

const cn = ({ selected, size } : { size: TabSize, selected: boolean }) => [
  'border-b',
  selected ?
    'border-cyanine-blue' :
    'border-transparent opacity-30',
  {
    xs: 'text-[11px] line-[13px]',
    sm: '',
    md: 'text-[14px] line-[20px] py-[10px]',
    lg: ''
  }[size],
].join(' ');

export const Tab = <TValue extends string = string>(props: TabProps<TValue>) => {
  const {
    size = 'md',
    value,
    selected = false,
    children,
    onClick
  } = props;
  
  const handleClick = () => {
    onClick && onClick(value);
  }

  return (
    <button
      type='button'
      className={cn({ size, selected })}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
