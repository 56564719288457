import type { ReactNode, MouseEventHandler, HTMLAttributes } from 'react';

export interface SectionProps extends HTMLAttributes<HTMLDivElement> {
}

const Section = ({ className = '', ...rest }: SectionProps) => (
  <section className={`overflow-hidden bg-white rounded-2 ${className}`} {...rest} />
)

const Head = ({ children, wfull = false, className = '' }: { children: ReactNode, wfull?: boolean, className?: string }) => (
  <div className={`flex items-center h-[60px] ${wfull ? 'px-[20px]' : 'mx-[20px]'} text-subtitle-1 whitespace-nowrap font-medium text-oxford-blue shadow-border-b ${className}`}>
    {children}
  </div>
)

const Right = ({ children }: { children: ReactNode }) => (
  <div className="ml-auto">{children}</div>
)

const Content = ({ children, className }: { children: ReactNode, className?: string }) => (
  <div className={`mx-[20px] overflow-hidden ${className}`}>{children}</div>
)

const InputDescription = ({ children }: { children: ReactNode }) => (
  <p className="mb-[15px] text-[13px] leading-[20px] text-silver-grey-800">
    {children}
  </p>
)

export default Object.assign(Section, {
  Head, Content, Right, InputDescription
})
