import { ReactComponent as RemoveIcon } from 'assets/images/CloseCircle.svg';

export interface RemoveButtonProps {
  onClick: () => void;
}

export const RemoveButton = (props: RemoveButtonProps) => {
  const {
    onClick
  } = props;

  return (
    <button
      onClick={onClick}
    >
      <RemoveIcon
        className='w-[11px] h-[11px]'
      />
    </button>
  );
};
