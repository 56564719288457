import type { GlobalFilterDraft, GlobalFilterPayload, GlobalFilterDeps } from 'types/globalFilters';
import { serializeGlobalFilterMap } from './filters';


export const serializeGlobalFilterDraft = (draft: GlobalFilterDraft, deps: GlobalFilterDeps): GlobalFilterPayload => {
  const {
    filters,
    ...rest
  } = draft;

  return {
    ...serializeGlobalFilterMap(filters, deps.globalFilterConstructor),
    ...rest
  };
}
