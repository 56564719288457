import type { Action } from 'types/dataEntries';
import type {
  FilterGroupCondition,
  FilterValueCondition
} from 'types/filters';

export type RuleValueCondition = FilterValueCondition;

export interface RuleFilterBranch extends FilterGroupCondition {
  operator: 'and';
  condition: RuleValueCondition[];
}

export interface RuleFilterCondition extends FilterGroupCondition {
  operator: 'or';
  condition: RuleFilterBranch[];
}

export interface RuleTag {
  key: string;
  value: string;
  filter: RuleFilterCondition;
  weight: number[]; 
}

export interface RuleDraftBackend {
  name: string;
  metric_source: string;
  tags: RuleTag[];
}

export interface RuleInfo {
  id: number;
  organization_id: number;
  created_by: number;
}

export interface RuleBackend extends RuleDraftBackend, RuleInfo {}

export type RuleNodeTag = [key: string | null, value: string | null];

export interface RuleNode {
  field?: string | null;
  children?: RuleNode[];
  tags?: RuleNodeTag[];
  rest?: boolean;
  condition?: RuleValueCondition;
}

export interface RuleRoot extends RuleNode {
  field: string | null;
  children: RuleNode[];
}

export interface RuleParentNode extends RuleNode {
  field: string;
  children: RuleNode[];
}

export interface RuleDraft {
  name: string;
  src: string;
  root: RuleRoot;
}

export const isRuleParentNode = (node: RuleNode): node is RuleParentNode => !!node.field && !!node.children;

export interface Rule extends RuleDraft, RuleInfo {}

export type RuleCreateAction = Action<'create'>;
export type RuleUpdateAction = Action<'update'>;
export type RuleDeleteAction = Action<'delete'>;
export type RuleAction = RuleCreateAction | RuleUpdateAction | RuleDeleteAction;

export interface RuleConstructorFields {
  rule_names: Record<string, string>;
  rule_tag_keys: Record<string, string[]>;
}
