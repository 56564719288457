import type { ReactNode } from 'react';
import type { ListEntry } from 'types/dataEntries';

import { useEffect } from 'react';
import { message } from 'antd';
import { useGlobalState } from 'state/globalState';
import { IconButton } from 'shared/Button';
import { AddControl } from './AddControl';

export interface ListEditorRowProps<TData = any> {
  item: ListEntry<TData>;
  addedMessage: (item: ListEntry<TData>) => ReactNode;
  addErrorMessage: (item: ListEntry<TData>) => ReactNode;
  removedMessage: (item: ListEntry<TData>) => ReactNode;
  removeErrorMessage: (item: ListEntry<TData>) => ReactNode;
  children: (item: ListEntry<TData>) => ReactNode;
  onRemove: (item: ListEntry<TData>) => void;
  onAddConfirm: (item: ListEntry<TData>) => void;
  onRemoveConfirm: (item: ListEntry<TData>) => void;
  editable?: boolean;
}

export const ListEditorRow = <TData = any>(props: ListEditorRowProps<TData>) => {
  const user = useGlobalState((state) => state.user);
  const {
    item,
    children,
    addedMessage,
    addErrorMessage,
    removedMessage,
    removeErrorMessage,
    onAddConfirm,
    onRemove,
    onRemoveConfirm,
    editable
  } = props;

  useEffect(() => {
    switch(item.status) {
      case 'added':
        message.success(addedMessage(item));
        onAddConfirm(item);
        break;

      case 'add-error': 
        message.error(addErrorMessage(item));
        onRemoveConfirm(item);
        break;

      case 'removed':
        message.success(removedMessage(item));
        onRemoveConfirm(item);
        break;

      case 'remove-error':
        message.error(removeErrorMessage(item));
        onAddConfirm(item);
        break;
    }
  }, [item.status]);

  return (
    <li className='flex items-center p-1 hover:bg-silver-grey shadow-border-b'>
      <div className='grow'>
        {children(item)}
      </div>
      {editable && user && !user.team && (
        <IconButton
          icon='close'
          size='xs'
          type='tertiary'
          className='ml-auto'
          disabled={['removing', 'removed'].includes(item.status)}
          onClick={() => {
            onRemove(item);
          }}
        />
      )}
    </li>
  );
}

export interface ListEditorProps<TData> {
  items: ListEntry<TData>[];
  addOptions: ListEntry<TData>[];
  getItemKey: (item: ListEntry<TData>) => string;
  getItemTitle: (item: ListEntry<TData>) => string;
  addedMessage: (item: ListEntry<TData>) => ReactNode;
  addErrorMessage: (item: ListEntry<TData>) => ReactNode;
  removedMessage: (item: ListEntry<TData>) => ReactNode;
  removeErrorMessage: (item: ListEntry<TData>) => ReactNode;
  children: (item: ListEntry<TData>) => ReactNode;
  onAdd: (item: ListEntry<TData>) => void;
  onAddConfirm: (item: ListEntry<TData>) => void;
  onRemove: (item: ListEntry<TData>) => void;
  onRemoveConfirm: (item: ListEntry<TData>) => void;
  editable?: boolean;
}

export const ListEditor = <TData extends { id: number }>(props: ListEditorProps<TData>) => {
  const user = useGlobalState((state) => state.user);
  const {
    items,
    addOptions,
    onAdd,
    getItemKey,
    getItemTitle,
    editable,
    ...itemProps
  } = props;

  return (
    <div>
      <ul className='my-3'>
        {items.map((item) => (
          <ListEditorRow
            key={getItemKey(item)}
            item={item}
            editable={editable}
            {...itemProps}
          />
        ))}
      </ul>
      {user && !user.team && editable && (
        <AddControl
          options={addOptions.map((item) => ({
            value: getItemKey(item),
            label: getItemTitle(item)
          }))}
          onAdd={(keyToAdd: string) => {
            const itemToAdd = addOptions.find((item) => getItemKey(item) === keyToAdd);
            if (itemToAdd) {
              onAdd(itemToAdd);
            }
          }}
        />
      )}
    </div>
  );
}
