import type { Setter } from 'types/common';
import type { NumberRangeDataFilterValue, NumberRangeDataFilterConfig, NumberRangeDataFilterOptions } from 'types/dataFilters';

import { DataFilterValueText } from './DataFilterValueText';
import { DataFilterValueHolder } from './DataFilterValueHolder';
import { RemoveButton } from './RemoveButton';
import { isEmptyRangeDataFilterValue } from 'helpers/dataFilters';

export interface RangeDataFilterValueDisplayProps {
  value: NumberRangeDataFilterValue;
  config: NumberRangeDataFilterConfig;
  options: NumberRangeDataFilterOptions;
  onChange: Setter<NumberRangeDataFilterValue>;
}

const defaultFormat = (value: NumberRangeDataFilterValue) =>
  value.max === null ?
    `> $${value.min}` :
  value.min === null ?
    `< $${value.max}` :
    `${value.min} – ${value.max}`
        

export const RangeDataFilterValueDisplay = (props: RangeDataFilterValueDisplayProps) => {
  const {
    value,
    config,
    onChange
  } = props;

  const {
    format = defaultFormat
  } = config;

  if (isEmptyRangeDataFilterValue(value)) {
    return null;
  }

  return (
    <DataFilterValueHolder>
      <DataFilterValueText>
        {format(value)}
      </DataFilterValueText>

      <RemoveButton
        onClick={() => {
          onChange({
            min: null,
            max: null
          });
        }}
      />
    </DataFilterValueHolder>
  );
};
