import { IconButton } from 'shared/Button';

export interface ArrowButtonProps {
  to: string;
}

export const ArrowButton = (props: ArrowButtonProps) => {
  return (
    <IconButton
      to={props.to}
      size='sm'
      type='tertiary'
      icon='arrow-right'
      className='ml-auto mr-[-10px] text-silver-grey-600 hover:text-silver-grey-800 hover:bg-silver-grey-200'
    />
  );
};
