import type { ReactElement, ForwardedRef } from 'react';
import type { ScrollPaneProps } from './ScrollPane';

import {
  forwardRef,
  Children,
  cloneElement,
  useRef,
  useImperativeHandle
} from 'react';

export interface ScrollPanesProps<TValue extends string> {
  value?: TValue;
  onChange: (value: TValue) => void;
  children: ReactElement<ScrollPaneProps<TValue>>[];
}

export interface ScrollPanesRef<TValue extends string> {
  scrollToPane: (value: TValue) => void;
}

export const ScrollPanes = forwardRef(<TValue extends string>(props: ScrollPanesProps<TValue>, ref: ForwardedRef<ScrollPanesRef<TValue>>) => {
  const {
    children,
    onChange
  } = props;

  const rootRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    scrollToPane: (paneValue) => {
      const root = rootRef.current;

      if (!root) {
        return;
      }

      const panes = Array.from(root.childNodes) as HTMLElement[];
      const paneToScroll = panes.find((pane) => pane.dataset.value === paneValue);

      if (paneToScroll?.firstChild) {
        (paneToScroll.firstChild as HTMLElement).scrollIntoView({ behavior: 'smooth' });
      }
    }
  }), []);

  return (
    <div ref={rootRef}>
      {Children.map(children, (child) => {
        return cloneElement(
          child, 
          {
            key: child.props.value,
            onIntersect: onChange
          }
        );
      })}
    </div>
  );
});
