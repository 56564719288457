import type { Opportunity } from 'types/savings';

import { useState, useMemo, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import { Page } from 'shared/Page';
import { Icon } from 'shared/Icon';
import { Grid } from 'shared/Grid';
import { Button2 as Button } from 'shared/Button';
import { Section } from 'shared/Section';
import { ThreadStatusBadge } from './ThreadStatusBadge';
import { GitHubPRsList } from './GitHubPRsList';
import { JiraIssuesList } from './JiraIssuesList';
import { OpportunitiesBlock } from './content/OpportunitiesBlock';
import { 
  IntegrationSelectDialog,
  JiraIssueCreateDialog,
  OpportunityAssignDialog,
  OpportunityAutomationDialog,
} from './dialogs';
import { NonPayingBanner } from './NonPayingBanner';
import { UnassignSelectedButton } from './controls';
import { ThreadInfoSection } from './content/ThreadInfoSection';
import { ThreadDeleteButton } from './controls/ThreadDeleteButton';
import { ThreadDeleteDialog } from './dialogs/ThreadDeleteDialog';
import { ThreadTeamDialog } from './dialogs/ThreadTeamDialog';

import { 
  useSavingsStore,
  useOpportunityAssignDialog,
  useJiraIssueCreateDialog
} from 'store/savings';
import { useIntegrationsStore } from 'store/integrations';
import { useIntegrationSelectDialog } from 'store/integrations/integrationSelectDialog'
import { renderThreadSecondaryId } from 'helpers/savings'
import { ThreadInfoDialog } from './dialogs/ThreadInfoDialog';
import { OpportunityActionButton } from './controls/OpportunityActionButton';
import {ThreadAssigneeDialog} from './dialogs/ThreadAssigneeDialog';

export const workflowIntegrationProviders = ['jira_cloud', 'jira_server']

export const ThreadPage = () => {
  const params = useParams<{ id: string }>();
  const id = +(params.id as string);

  const thread = useSavingsStore((store) => store.getThread(id));
  const integrations = useIntegrationsStore((store) => store.getIntegrations());

  const openAssignDialog = useOpportunityAssignDialog((store) => store.open);
  const openJiraIssueCreateDialog = useJiraIssueCreateDialog((store) => store.open);
  const openIntegrationSelectDialog = useIntegrationSelectDialog((store) => store.open)

  const [selected, setSelected] = useState<Opportunity[]>([]);

  const [selectedIntegration, setSelectedIntegration] = useState<string | null>(null)

  const workflowIntegrations = useMemo(() => {
    if (integrations.status != 'success') {
      return []
    }
    return integrations.data.filter((integration) => 
      workflowIntegrationProviders.includes(integration.provider))
  }, [integrations])

  useEffect(() => {
    if (selectedIntegration == 'jira_cloud' || selectedIntegration == 'jira_server') {
      openJiraIssueCreateDialog()
    }
  }, [selectedIntegration])

  const prefilter = useMemo(
    () => ({ thread_id: id }),
    [id]
  );

  return (
    <Page>
      <Page.Head
        title={thread.status === 'success' ?
          <div className='flex items-center gap-2'>
            <Link
              className='text-blue hover:!text-blue-hover'
              to='/savings/threads/'
            >
              Savings Threads
            </Link>

            <Icon icon='arrow-right' />

            {`${renderThreadSecondaryId(thread.data)} `}

            {thread.data.name}

            <ThreadStatusBadge>{thread.data.status}</ThreadStatusBadge>
          </div>
          :
          'Loading...'
        }
      >
        {thread.status === 'success' && thread.data.status !== 'deleted' && <ThreadDeleteButton threadId={id} />}
      </Page.Head>

      <Grid className='pb-[60px]'>
        <NonPayingBanner className='col-span-12' />

        <ThreadInfoSection id={id} className='col-span-6' />

        <JiraIssuesList threadId={id} className='col-span-6' />

        <Section className='p-5 col-span-12'>
          <OpportunitiesBlock
            title={'Opportunities'}
            prefilter={prefilter}
            selected={selected}
            onSelect={setSelected}
            extraHeaderActions={(
              <div className=''>
                <div>
                    <Button
                      size='xs'
                      theme='link'
                      layout='inline'
                      iconRight='plus'
                      onClick={openAssignDialog}
                    >
                      Add more opportunities
                    </Button>
                  </div>
                <div className='mt-4'>
                  <Button
                    size='s'
                    theme='link'
                    layout='inline'
                    iconRight='arrow-right'
                    onClick={() => {
                      if (workflowIntegrations.length == 1) {
                        setSelectedIntegration(workflowIntegrations[0].provider)
                      } else {
                        openIntegrationSelectDialog()
                      }
                    }}
                    disabled={workflowIntegrations.length === 0 || selected.length === 0}
                  >
                    Start Workflow          
                  </Button>
                </div>
              </div>
            )}
            extraOpportunityActionsRight={
              <UnassignSelectedButton
                selected={selected}
                onSelect={setSelected}
              />
            }
            extraTableActions={{
              title: 'Actions',
              key: 'actions',
              width: 70,
              fixed: 'right' as 'right',
              render: (opp: Opportunity) => <OpportunityActionButton opportunity={opp} />
            }}
            ignorePageFilters
          />
        </Section>

        <GitHubPRsList threadId={id} className='col-span-12' />
      </Grid>

      <OpportunityAssignDialog 
        threadId={id} 
        teamId={thread.status === "success" ? thread.data.team_id : null}
      />

      {(selectedIntegration == 'jira_cloud' || selectedIntegration == 'jira_server') && 
        <JiraIssueCreateDialog
          setSelectedIntegration={setSelectedIntegration} 
          threadId={id}
          provider={selectedIntegration} 
          selected={selected}
        />
      }

      <IntegrationSelectDialog 
        setSelectedIntegration={setSelectedIntegration} 
        integrations={workflowIntegrations}
      />

      <ThreadDeleteDialog />
      <ThreadTeamDialog />
      <ThreadInfoDialog />
      <ThreadAssigneeDialog />
      <OpportunityAutomationDialog />
    </Page>
  );
}
