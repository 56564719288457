import type { Granularity } from 'types/common';

import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isGranularity } from 'types/common';

export type UseGranularityResult = [
  Granularity,
  (granularity: Granularity) => void
]

export const useGranularity = (): UseGranularityResult => {
  const [searchParams, setSearchParams] = useSearchParams();

  const granularityParam = searchParams.get('granularity');

  const granularity: Granularity = isGranularity(granularityParam) ? granularityParam : 'day';

  const setGranularity = useCallback((newGranularity: Granularity) => {
    if (newGranularity === granularity) {
      return;
    }

    const newSearchParams = new URLSearchParams(searchParams);

    if (newGranularity === 'day') {
      newSearchParams.delete('granularity');
    } else {
      newSearchParams.set('granularity', newGranularity);
    }

    setSearchParams(newSearchParams, { replace: true });
  }, [searchParams]);

  return [
    granularity,
    setGranularity
  ];
};
