import type { DataEntry } from 'types/dataEntries';
import type { CostViewChart, CostViewRequestFilter } from 'types/costViews';

import { useDatesQuery } from 'hooks/useDatesQuery';
import { useCostFilterValue } from 'hooks/costFilter';
import { useCostViewChartStore } from 'store/costViewChart';
import { ValueBox } from 'shared/ValueBox';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { formatPercent } from 'helpers/formatter';
import { useCumulative } from '../hooks/cumulative';

export interface GrowthRateProps {
  requestFilter: DataEntry<CostViewRequestFilter>;
}

export const GrowthRate = (props: GrowthRateProps) => {
  const {
    requestFilter
  } = props;

  const [cumulative] = useCumulative();
  const {
    startDate,
    granularity
  } = useDatesQuery();

  const costFilterValue = useCostFilterValue();
  const shouldShow = cumulative && granularity !== 'quarter';

  const chartDataEntry: DataEntry<CostViewChart> = useCostViewChartStore(({ getEntry }) => {
    if (!shouldShow || requestFilter.status !== 'success') {
      return { status: 'idle' };
    }

    const start = startDate
      .clone()
      .startOf('month')
      .add(-100, 'day') // -100 days + startOf to avoid jumping over February
      .startOf('month');

    const end = start
      .clone()
      .add(100, 'day')
      .endOf('month');

    return getEntry({
      ...costFilterValue,
      startDate: start.format('YYYY-MM-DD'),
      endDate: end.format('YYYY-MM-DD'),
      granularity: 'month',
      filter: requestFilter.data
    });
  });

  const avgGrowth = useDerivedDataEntry({
    chartDataEntry
  }, ({
    chartDataEntry
  }) => {
    const growths = chartDataEntry.reduce((growths, item, index) => {
      if (index > 0) {
        const prev = chartDataEntry[index - 1];

        if (!prev.current_timeframe_cost || !item.current_timeframe_cost) {
          growths.push(0);
        } else {
          growths.push(
            item.current_timeframe_cost / prev.current_timeframe_cost - 1
          );
        }
      }
      
      return growths;
    }, [] as number[]);

    return (growths.reduce((a, b) => a + b, 0)) / (growths.length || 1);
  });

  if (!cumulative || granularity === 'quarter') {
    return null;
  }

  return <ValueBox.SimpleValueBox
    label='Prev. 3 Months Avg Growth'
    text={avgGrowth.status === 'success' ?
      formatPercent(avgGrowth.data) :
      'Loading'
    }
  />
}
