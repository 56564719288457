import type { 
  CFNStack,
  StackSetType,
  AccountFilterType
} from 'types/dataCollection';
import React, { useState, useEffect } from 'react'
import { Input, message, Select } from 'antd'
import Dialog from 'shared/Dialog/Dialog';
import Button from 'shared/Button/Button'
import { useCFNStacksStore } from 'store/cfnStacks';
import { useAWSConfigCreateDialog } from 'store/dataConfigs/awsCreateDialog';

const stackSetTypes = [
  'co_stack_set_new_cur',
  'co_stack_set_existing_cur',
  'co_stack_set',
  'automation_stack_set',
]

const existingCURTypes = [
  'co_stack_set_existing_cur',
  'co_existing_cur',
]

export interface CFNCreateDialogProps {
  initialStack: CFNStack | null;
  stackType: StackSetType;
  setStackType: (type: StackSetType | null) => void;
}

export const CFNCreateDialog = (props: CFNCreateDialogProps) => {
  const { opened, close } = useAWSConfigCreateDialog();
  const {
    initialStack,
    stackType,
    setStackType,
  } = props;
  const { saveCFNStacks, deleteCFNStacks } = useCFNStacksStore();
  const [ouIds, setOUIds] = useState('')
  const [accounts, setAccounts] = useState('')
  const [accountFilterType, setAccountFilterType] = useState<AccountFilterType>('UNION')
  const [reportName, setStackName] = useState('')
  const [reportPrefix, setStackPrefix] = useState('')
  const [reportBucket, setStackBucket] = useState('')

  const handleAWSOrgsClick = () => {
    window.open('https://us-east-1.console.aws.amazon.com/organizations/v2/home/accounts', '_blank')
  }

  const handleRootIDDocsClick = () => {
    window.open('https://docs.cloudthread.io/guides/onboarding/connecting-aws-account', '_blank')
  }

  const handleAccountFilterInfoClick = () => {
    window.open('https://docs.aws.amazon.com/AWSCloudFormation/latest/APIReference/API_DeploymentTargets.html', '_blank')
  }

  const handleSetOUIds = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOUIds(e.target.value)
  }

  const handleSetAccountss = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccounts(e.target.value)
  }

  const handleSetAccountFilterType = (accountFilterType: AccountFilterType) => {
    setAccountFilterType(accountFilterType)
  }

  const handleChangeStackName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStackName(e.target.value)
  }

  const handleChangeStackPrefix = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStackPrefix(e.target.value)
  }

  const handleChangeStackBucket = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStackBucket(e.target.value)
  }

  useEffect(() => {
    if (!opened) {
      setOUIds('')
      setAccounts('')
      setAccountFilterType('UNION')
      setStackName('')
      setStackPrefix('')
      setStackBucket('')
    } else if (initialStack) {
      setOUIds(initialStack.aws_org_root_id ?? initialStack.aws_ou_ids?.join(', ') ?? '')
      setAccounts(initialStack.aws_accounts?.join(', ') ?? '')
      setAccountFilterType(initialStack.aws_account_filter_type ?? 'UNION')
      setStackName(initialStack.aws_org_existing_cur_report_name ?? '')
      setStackPrefix(initialStack.aws_org_existing_cur_report_prefix ?? '')
      setStackBucket(initialStack.aws_org_existing_cur_report_bucket ?? '')
    }
  }, [opened, initialStack])

  return (
    <Dialog
      open={opened}
      onClose={() => {
        close();
        setStackType(null);
      }}
      className='w-[510px] overflow-y-auto'
    >
      <Dialog.Title>
      {'Create Cloudformation Stack'}
      </Dialog.Title>

      <div className='px-[30px] pb-[30px]'>
        {initialStack && (
          <div className='mb-5 text-[15px]'>You can continue with your previously generated stack or generate a new one</div>
        )}
        {stackSetTypes.includes(stackType) && (
          <div className='mb-[30px]'>
            <div className='mb-2 font-medium text-[16px]'>
              AWS Organizational Unit IDs (e.g. r-2xow, ou-2xow-8few987h)
            </div>
            <Input
              className="w-full mb-2"
              onChange={handleSetOUIds}
              value={ouIds}
            />
            <div className='mb-5 text-[12px]'>
              To determine your Root ID go to <a className='underline' onClick={handleAWSOrgsClick}>AWS Organizations</a> or
              visit our documentation <a className='underline' onClick={handleRootIDDocsClick}>here.</a>
            </div>
            <div className='mb-2 font-medium text-[16px]'>
              Specifc AWS Sub-Accounts (e.g. 123456789012) to include / exclude
            </div>
            <Input
              className="w-full mb-5"
              onChange={handleSetAccountss}
              value={accounts}
            />
            <div className='mb-5 text-[12px]'>
              This field is only necessary if you plan on including or excluding particular accounts 
              from the Organizational Unit IDs, or adding additional accounts beyond 
              the Organizational Unit IDs. If you simply want to integrate the entire set of accounts
              covered via the Organizational Unit IDs, leave this blank.
            </div>
            <div className='mb-2 font-medium text-[16px]'>
              AWS Account Filter Type
            </div>
            <Select
              size='small'
              className="w-full mb-2"
              options={[
                {'value': 'UNION', 'label': 'Union'},
                {'value': 'INTERSECTION', 'label': 'Intersection'},
                {'value': 'DIFFERENCE', 'label': 'Difference'},
              ]}
              value={accountFilterType}
              onChange={handleSetAccountFilterType}
            />
            <div className='mb-5 text-[12px]'>
              To determine the correct account filter type visit AWS 
              documentation <a className='underline' onClick={handleAccountFilterInfoClick}>here.</a> This 
              value only applies when AWS Accounts is set.
            </div>
          </div> 
        )}
        {existingCURTypes.includes(stackType) && (
          <>
            <div className='mb-[30px]'>
              <div className='text-[16px] font-medium mb-[10px]'>
                Report name
              </div>
              <Input
                value={reportName}
                onChange={handleChangeStackName}
              />
            </div>
            <div className='mb-[30px]'>
              <div className='text-[16px] font-medium mb-[10px]'>
                Report prefix
              </div>
              <Input
                value={reportPrefix}
                onChange={handleChangeStackPrefix}
              />
            </div>
            <div className='mb-[30px]'>
              <div className='text-[16px] font-medium mb-[10px]'>
                Report bucket
              </div>
              <Input
                value={reportBucket}
                onChange={handleChangeStackBucket}
              />
            </div>
          </>
        )}
        <div className='flex justify-end'>
          <Button
            label='Cancel'
            type='tertiary'
            style='caladon'
            onClick={() => {
              close();
              setStackType(null);
            }}
          />
          {initialStack && (
            <Button
              label='Use previous stack'
              type='tertiary'
              style='caladon'
              onClick={close}
            />
          )}
          <Button
            label='Save Stack'
            type='primary'
            style='caladon'
            onClick={async () => {
              let config: Record<string, any> = {
                stack_type: stackType,
              }
              if (stackSetTypes.includes(stackType)) {
                if (ouIds === '') {
                  message.error("Organization Unit IDs cannot be empty")
                  return
                }
                const regexpOU = new RegExp('^(r-[0-9a-z]{4,32}|ou-[a-z0-9]{4,32}-[a-z0-9]{8,32})$')
                const aws_ou_ids = ouIds.split(',').map((ou) => ou.trim()).filter((ou) => ou !== '')
                if (aws_ou_ids.filter((ou) => !regexpOU.test(ou)).length > 0) {
                  message.error('At least one Organizational Unit ID is invalid')
                  return
                }
                if (
                  aws_ou_ids.filter((ou) => ou.startsWith('r')).length > 0
                  && aws_ou_ids.filter((ou) => ou.startsWith('ou')).length > 0
                ) {
                  message.error('You can only specify a Root ID or Organizational Unit IDs')
                  return
                }
                config.aws_ou_ids = aws_ou_ids
                const regexpAccount = new RegExp('^[0-9]{12}$')
                const aws_accounts = accounts.split(',').map((acc) => acc.trim()).filter((acc) => acc !== '')
                if (aws_accounts.filter((acc) => !regexpAccount.test(acc)).length > 0) {
                  message.error('At least one account is invalid')
                  return
                }
                config.aws_accounts = aws_accounts.length > 0 ? aws_accounts : undefined
                config.aws_account_filter_type = aws_accounts.length > 0 ? accountFilterType : undefined
              }
              if (existingCURTypes.includes(stackType)) {
                if (reportName === '') {
                  message.error("Report Name cannot be empty")
                  return
                }
                if (reportPrefix === '') {
                  message.error("Report Prefix cannot be empty")
                  return
                }
                if (reportBucket === '') {
                  message.error("Report Bucket cannot be empty")
                  return
                }
                config.aws_org_existing_cur_report_name = reportName
                config.aws_org_existing_cur_report_prefix = reportPrefix
                config.aws_org_existing_cur_report_bucket = reportBucket
              }

              saveCFNStacks(config);
              if (initialStack) {
                deleteCFNStacks(initialStack.id)
              }
              close();
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default CFNCreateDialog
