import type {
  SingleRule,
  FilterValueType,
  StringRule,
  DictRule,
  FilterValueCondition
} from 'types/filters';

import { StringRuleValue } from './StringRuleValue';
import { StringRegexRuleValue } from './StringRegexRuleValue';
import { DictRegexRuleValue } from './DictRegexRuleValue';
import { AsyncDictRuleValue } from './AsyncDictRuleValue';

export interface RuleValueProps<TValue extends (string | Record<string, string>)> {
  rule: SingleRule;
  value: TValue;
  aliases?: Record<string, string>;
  options: string[];
  siblingValues: TValue[];
  parentValue?: FilterValueCondition[];
  valueType: FilterValueType;
  disabled?: boolean;
  onChange: (value: TValue) => void;
}

export const RuleValue = <TValue extends (string | Record<string, string>)>(props: RuleValueProps<TValue>) => {
  const {
    rule,
    valueType,
    value,
    options,
    siblingValues,
    parentValue,
    disabled,
    onChange
  } = props;

  switch (valueType) {
    case 'string':
      return (
        <StringRuleValue
          rule={rule as StringRule}
          value={value as string}
          options={options}
          aliases={(rule as StringRule).aliases}
          siblingValues={siblingValues as string[]}
          disabled={disabled}
          onChange={onChange as (value: string) => void}
        />
      );

    case 'string-regex':
      return (
        <StringRegexRuleValue
          rule={rule as StringRule}
          value={value as string}
          disabled={disabled}
          onChange={onChange as (value: string) => void}
        />
      );

    case 'dict':
      return (
        <AsyncDictRuleValue
          rule={rule as DictRule}
          field={rule.name}
          value={value as Record<string, string>}
          options={options}
          siblingValues={siblingValues as Record<string, string>[]}
          parentValue={parentValue}
          disabled={disabled}
          onChange={onChange as (value: Record<string, string>) => void}
        />
      );

    case 'dict-regex':
      return (
        <DictRegexRuleValue
          rule={rule as DictRule}
          value={value as Record<string, string>}
          options={options}
          siblingValues={siblingValues as Record<string, string>[]}
          disabled={disabled}
          onChange={onChange as (value: Record<string, string>) => void}
        />
      );
  }
};
