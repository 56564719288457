import type { Opportunity } from 'types/savings';

import { message } from 'antd';
import { Button2 as Button } from 'shared/Button';
import { useSavingsStore } from 'store/savings';
import { noop } from 'helpers/utils';
import { renderOpportunitySlug } from 'helpers/savings';

export interface RejectSelectedButtonProps {
  selected: Opportunity[];
  onSelect: (selected: Opportunity[]) => void;
}

export const RejectSelectedButton = (props: RejectSelectedButtonProps) => {
  const {
    selected,
    onSelect = noop
  } = props;

  const rejectOpportunity = useSavingsStore((store) => store.rejectOpportunity);
  const actions = useSavingsStore((store) => store.opportunityActions);

  const disabled = selected.length === 0 ||
    selected.some(({ id }) => actions.get(id));
  
  return (
    <Button
      size='xs'
      theme='link'
      layout='inline'
      iconLeft='close'
      disabled={disabled}
      onClick={() => {
        Promise.allSettled(
          selected.map(({ id }) => rejectOpportunity(id))
        )
          .then((results) => {
            const fulfilled: Opportunity[] = [];
            const rejected: Opportunity[] = [];

            results.forEach((result, i) => {
              (result.status === 'fulfilled' ? fulfilled : rejected).push(selected[i])
            });

            if (rejected.length === 0) {
              message.success('Opportunities rejected');
            } else if (fulfilled.length === 0) {
              message.error('Cannot reject opportunities');
            } else {
              message.warning(<>
                Opportunities resolved partially<br/>
                Rejected: {fulfilled.map(renderOpportunitySlug).join(', ')}<br/>
                Could not reject: {rejected.map(renderOpportunitySlug).join(', ')}
              </>);
            }

            onSelect(rejected);
          });
      }}
    >
      Reject
    </Button>
  );
}
