import { createKeyedStore } from 'store/createKeyedStore';
import { createKeyedStoreSelector } from 'store/createKeyedStoreSelector';
import { fetchJiraUsers } from 'services/savings'
import { identity } from 'helpers/utils';

export const useJiraServerUsersStore = createKeyedStore({
  fetchData: (jira_project_key: string) => fetchJiraUsers('jira_server', jira_project_key),
  paramsToKey: identity
});

export const useJiraServerUsers = createKeyedStoreSelector(useJiraServerUsersStore);
