import type { ListDataFilterValue } from 'types/dataFilters';
import type { Opportunity, OpportunityTag } from 'types/savings';

import {
  createListDataFilterFunction,
  createQueryDataFilterFunction,
  createRangeDataFilterFunction
} from 'helpers/dataFilters';

import {
  returnTrue
} from 'helpers/utils';

import {
  pickOpportunityCostImpact,
  pickOpportunityDifficulty,
  pickOpportunityGroup,
  pickOpportunityCategory,
  pickOpportunityAccount,
  pickOpportunityService,
  pickOpportunityRegion,
  pickOpportunityTags,
  pickOpportunityPlatform,
  pickOpportunityPriority,
  pickOpportunityAccountTags,
} from './pick';

import {
  renderOpportunitySlug,
  renderOpportunityTypeIdString
} from './render';

export const createDifficultyFilter = createListDataFilterFunction(pickOpportunityDifficulty);
export const createGroupFilter = createListDataFilterFunction(pickOpportunityGroup);
export const createCategoryFilter = createListDataFilterFunction(pickOpportunityCategory);
export const createAccountFilter = createListDataFilterFunction(pickOpportunityAccount);
export const createServiceFilter = createListDataFilterFunction(pickOpportunityService);
export const createRegionFilter = createListDataFilterFunction(pickOpportunityRegion);
export const createPlatformFilter = createListDataFilterFunction(pickOpportunityPlatform);

export const createCostImpactFilter = createRangeDataFilterFunction(
  pickOpportunityCostImpact,
  (a: number, b: number) => a - b
);

export const createPriorityFilter = createRangeDataFilterFunction(
  pickOpportunityPriority,
  (a: number, b: number) => a - b
);

const createTagsDataFilterFunction = (picker: (opp: Opportunity) => OpportunityTag[]) => (filter: ListDataFilterValue) => {
  if (filter === null || filter.length === 0) {
    return returnTrue;
  }

  const filterSet = new Set(filter);

  return (opp: Opportunity) => {
    const tags = picker(opp);

    return tags.some(({ key, value }) => filterSet.has(`${key}:${value}`));
  }
}

export const createTagsFilter = createTagsDataFilterFunction(pickOpportunityTags);
export const createAccountTagsFilter = createTagsDataFilterFunction(pickOpportunityAccountTags);

export const createQueryFilter = createQueryDataFilterFunction([
  renderOpportunitySlug,
  renderOpportunityTypeIdString,
  pickOpportunityRegion,
  pickOpportunityAccount,
  pickOpportunityService,
]);
