import type { DataEntry, Resolved } from 'types/dataEntries';
import type { EditableStoreState, EditableStore, EditableStoreAction, BackendEntryBase } from 'types/stores';

import create from 'zustand';
import { useCallback, useEffect } from 'react';
import { Map as ImmutableMap } from 'immutable';

export interface CreateEditableStoreParams<
  TPayload extends {},
  TBackendEntry extends TPayload & BackendEntryBase,
  TLibraryEntry = TBackendEntry,
  TDraft = TPayload
> {
  fetchLibrary: () => Promise<TLibraryEntry[]>;

  fetchEntry: (id: number) => Promise<TBackendEntry>;
  createEntry: (payload: TPayload) => Promise<TBackendEntry>;
  updateEntry: (id: number, payload: TPayload) => Promise<TBackendEntry>;
  deleteEntry: (id: number) => Promise<void>;
  
  emptyDraft: TDraft;
  backendEntryToDraft: (entry: TBackendEntry) => TDraft;
  backendEntryToLibraryEntry: (entry: TBackendEntry) => TLibraryEntry;
  draftToPayload: (draft: TDraft) => TPayload;
}

export function createEditableStore<
  TPayload extends {},
  TBackendEntry extends TPayload & BackendEntryBase,
  TLibraryEntry extends BackendEntryBase = TBackendEntry,
  TDraft = TPayload
>(params: CreateEditableStoreParams<
  TPayload,
  TBackendEntry,
  TLibraryEntry,
  TDraft
>): EditableStore<TBackendEntry, TLibraryEntry, TDraft> {
  const {
    fetchLibrary,
    fetchEntry,
    createEntry,
    updateEntry,
    deleteEntry,

    emptyDraft,
    backendEntryToDraft,
    backendEntryToLibraryEntry,
    draftToPayload
  } = params;

  return create<EditableStoreState<TBackendEntry, TLibraryEntry, TDraft>>((set, get) => ({
    library: { status: 'idle' },
    entries: ImmutableMap(),
    drafts: ImmutableMap(),
    actions: ImmutableMap(),

    //
    fetchLibrary: () => {
      const { library } = get();

      if (library.status === 'idle') {
        set({
          library: { status: 'loading' }
        });
      }

      fetchLibrary()
        .then((data) => {
          set({
            library: {
              status: 'success',
              data
            }
          });
        })
        .catch((error) => {
          set({
            library: {
              status: 'error',
              error
            }
          });
        });
    },

    //
    getLibrary: () => {
      const {
        library
      } = get();

      if (library.status === 'idle') {
        get().fetchLibrary();
      }

      return get().library;
    },

    //
    getAction: (key: string | number) => {
      const {
        actions
      } = get();

      return actions.get(key) || null;
    },

    //
    setAction: (key: string | number, action: EditableStoreAction<TBackendEntry>) => {
      const {
        actions
      } = get();
      
      set({
        actions: actions.set(key, action)
      });
    },

    //
    discardAction: (key: string | number) => {
      const {
        actions
      } = get();

      set({
        actions: actions.remove(key)
      });
    },
    
    //
    getEntry: (id: number) => {
      const {
        entries,
        setEntry
      } = get();

      let entry = entries.get(id);

      if (entry) {
        return entry;
      }

      fetchEntry(id)
        .then((data) => {
          setEntry(id, {
            status: 'success',
            data
          });
        })
        .catch((error) => {
          setEntry(id, {
            status: 'error',
            error
          });
        });

      entry = { status: 'loading' };

      setEntry(id, entry);

      return entry;
    },

    //
    setEntry: (id: number, entry: DataEntry<TBackendEntry>) => {
      const {
        entries,
        library,
        discardDraft
      } = get();

      set({
        entries: entries.set(id, entry)
      });
      
      discardDraft(id);

      if (library.status === 'success' && entry.status === 'success') {
        let index = library.data.findIndex((item) => item.id === id);

        if (index === -1) {
          index = library.data.length;
        }

        const data = [...library.data];

        data[index] = backendEntryToLibraryEntry(entry.data);

        set({
          library: {
            ...library,
            data
          }
        });
      }
    },

    //
    discardEntry: (id: number) => {
      const {
        entries,
        library,
        discardDraft,
        discardAction
      } = get();

      set({
        entries: entries.remove(id)
      }); 

      discardDraft(id);
      discardAction(id);

      if (library.status === 'success') {
        const data = library.data.filter((item) => item.id !== id);

        if (data.length !== library.data.length) {
          set({
            library: {
              ...library,
              data
            }
          });
        }
      }
    },

    createEntry: (key: string | number) => {
      const {
        getDraft,
        setEntry,
        getAction,
        setAction,
        discardAction
      } = get();

      const draft = getDraft(key);

      if (draft.status !== 'success') {
        throw new Error(`Can't create entry: draft with id:${key} has not loaded`);
      }
      
      const action = getAction(key);

      if (action && action.status === 'in-progress') {
        throw new Error(`Can't create entry: '${action.type}' action in progress for draft key:${key}`);
      }
      
      const payload = draftToPayload(draft.data);

      setAction(key, {
        type: 'create',
        status: 'in-progress'
      });
      
      return createEntry(payload)
        .then((data) => {
          setEntry(data.id, {
            status: 'success',
            data
          });

          discardAction(key);

          return data;
        })
        .catch((error) => {
          discardAction(key);

          return Promise.reject(error);
        });
    },

    updateEntry: (id: number) => {
      const {
        getDraft,
        getAction,
        setAction,
        setEntry,
        discardAction
      } = get();

      const draft = getDraft(id);

      if (draft.status !== 'success') {
        throw new Error(`Can't update entry: draft with id:${id} has not loaded`);
      }
      
      const action = getAction(id);

      if (action && action.status === 'in-progress') {
        throw new Error(`Can't update entry: '${action.type}' action in progress for draft key:${id}`);
      }
      
      const payload = draftToPayload(draft.data);

      setAction(id, {
        type: 'update',
        status: 'in-progress'
      });

      return updateEntry(id, payload)
        .then((data) => {
          setEntry(id, {
            status: 'success',
            data
          });
          discardAction(id);

          return data;
        })
        .catch((error) => {
          discardAction(id);

          return Promise.reject(error);
        });
    },

    deleteEntry: (id: number) => {
      const {
        setAction,
        getAction,
        discardAction,
        discardEntry
      } = get();

      const action = getAction(id);

      if (action && action.status === 'in-progress') {
        throw new Error(`Can't delete entry: '${action.type}' action in progress for entry id:${id}`);
      }

      setAction(id, {
        type: 'delete',
        status: 'in-progress'
      });

      return deleteEntry(id)
        .then(() => {
          discardEntry(id);
        })
        .catch((error) => {
          discardAction(id);

          return Promise.reject(error);
        });
    },

    //
    getDraft: (key: number | string): DataEntry<TDraft> => {
      const {
        drafts,
        getEntry
      } = get();

      let draftEntry = drafts.get(key);

      if (draftEntry) {
        return draftEntry;
      }
      
      if (typeof key === 'string') {
        draftEntry = {
          status: 'success',
          data: emptyDraft
        };
      
        set({
          drafts: drafts.set(key, draftEntry)
        });

        return draftEntry;
      }

      const entry = getEntry(key);

      if (entry.status !== 'success') {
        return entry;
      }

      draftEntry = {
        status: 'success',
        data: backendEntryToDraft(entry.data)
      };
      
      set({
        drafts: drafts.set(key, draftEntry)
      });

      return draftEntry;
    },

    //
    setDraft: (key: string | number, draft: TDraft) => {
      const { drafts } = get();

      set({
        drafts: drafts.set(key, {
          status: 'success',
          data: draft
        })
      });
    },

    //
    discardDraft: (key: string | number) => {
      const { drafts } = get();

      set({
        drafts: drafts.remove(key)
      });
    }
  }));
}

export function createLibrarySelector<TLibraryEntry extends BackendEntryBase>(useStore: EditableStore<any, TLibraryEntry, any>): () => DataEntry<TLibraryEntry[]> {
  return () => useStore((store) => store.getLibrary());
}

export function createEntrySelector<TBackendEntry extends BackendEntryBase>(useStore: EditableStore<TBackendEntry, any, any>): (id: number) => DataEntry<TBackendEntry> {
  return (id: number) => useStore((store) => store.getEntry(id));
}

export function createEntrySelectorWithDeps<TBackendEntry extends BackendEntryBase>(
  useStore: EditableStore<TBackendEntry, any, any>
): <Deps extends { [key: string]: DataEntry }>(
  getId: (resolved: Resolved<Deps>) => number,
  deps: Deps
) => DataEntry<TBackendEntry> {
  return <Deps extends { [key: string]: DataEntry }>(
    getId: (resolved: Resolved<Deps>) => number,
    deps: Deps
  ) => useStore((store) => {
    const { getEntry } = store;
    const depsArray = Object.values(deps);

    for (let status in ['error', 'loading', 'idle']) {
      for (let dep of depsArray) {
        if (dep.status === status) {
          return dep;
        }
      }
    }
    
    const id = getId(deps as Resolved<Deps>);

    return getEntry(id);
  });
}

export function createDraftSelector<TDraft>(useStore: EditableStore<any, any, TDraft>): (key: string | number) => [
  DataEntry<TDraft>,
  (draft: TDraft) => void
] {
  return (key: number | string) => {
    const draft = useStore((store) => store.getDraft(key));
    const setDraft = useStore((store) => store.setDraft);
    const discardDraft = useStore((store) => store.discardDraft);
    const setDraftBound = useCallback((draft: TDraft) => setDraft(key, draft), [key, setDraft]);

    useEffect(() => {
      return () => {
        discardDraft(key);
      };
    }, [key]);

    return [draft, setDraftBound];
  }
}
