import { Button2 as Button } from 'shared/Button';
import { useSavingsStore, useThreadCreateDialog } from 'store/savings';

export interface OpportunityNewThreadButtonProps {
  opportunityId: number;
}

export const OpportunityNewThreadButton = (props: OpportunityNewThreadButtonProps) => {
  const {
    opportunityId
  } = props;

  const currentAction = useSavingsStore((store) => store.opportunityActions.get(opportunityId));
  const newThread = useSavingsStore((store) => store.newThread);
  const setNewThread = useSavingsStore((store) => store.setNewThread);
  const opportunity = useSavingsStore((store) => store.getOpportunity(opportunityId));
  const openThreadCreateDialog = useThreadCreateDialog((store) => store.open);

  return (
    <Button
      size='xs'
      theme='link'
      layout='inline'
      iconLeft='circle-plus'
      disabled={currentAction && currentAction.status === 'in-progress' || opportunity.status !== 'success'}
      onClick={() => {
        if (opportunity.status === 'success') {
          setNewThread({ ...newThread, opportunities: [opportunity.data] });
          openThreadCreateDialog();
        }
      }}
    >
      Create New Thread
    </Button>
  );
}
