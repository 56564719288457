import create from 'zustand';
import type { Granularity } from 'helpers/formatter';
import type { FetchStatus } from 'types/common';
import type { CostView, CostViewChartData, CostType } from 'types/costViews';

import {
  fetchCostView,
  fetchCostViewChart
} from 'services/costViews';

import {
  
} from 'services/metric';

type ChartDataRequest = Promise<any>;

interface ChartDataEntry {
  status: FetchStatus;
  request: ChartDataRequest | null;
  chartData: CostViewChartData[];
}

type CostViewRequest = Promise<any>;

export interface CostViewEntry {
  status: FetchStatus;
  request: CostViewRequest | null;
  costView: CostView | null;
  charts: Record<string, ChartDataEntry>;
}

interface CostViewsState {
  costViews: Record<number, CostViewEntry>;
}

interface CostViewsActions {
  setCostViewEntry: (id: number, entry: CostViewEntry) => void;
  getCostViewEntry: (id: number) => CostViewEntry;
  fetchCostView: (id: number) => CostViewRequest;

  setChartDataEntry: (id: number, startDate: string, endDate: string, entry: ChartDataEntry) => void;
  getChartDataEntry: (id: number, startDate: string, endDate: string) => ChartDataEntry;
  fetchChartData: (id: number, startDate: string, endDate: string, granularity: Granularity, costType: CostType) => ChartDataRequest;
}

interface CostViewsStore extends CostViewsState, CostViewsActions { [key: string]: any }

const DEFAULT_STATE: CostViewsState = {
  costViews: {}
};

export const useCostViewsStore = create<CostViewsStore>((set, get) => ({
  ...DEFAULT_STATE,

  setCostViewEntry: (id, entry) => {
    const { costViews } = get();

    set({
      costViews: {
        ...costViews,
        [id]: entry
      }
    });
  },

  getCostViewEntry: (id) => {
    const { costViews, setCostViewEntry } = get();
    
    let entry: CostViewEntry = costViews[id];

    if (!entry) {
      entry = {
        status: 'idle',
        request: null,
        costView: null,
        charts: {}
      };

      setCostViewEntry(id, entry);
    }

    return entry;
  },

  setChartDataEntry: (id, startDate, endDate, entry) => {
    const { getCostViewEntry, setCostViewEntry } = get();

    const costViewEntry = getCostViewEntry(id);

    setCostViewEntry(id, {
      ...costViewEntry,
      charts: {
        ...costViewEntry.charts,
        [`${startDate}/${endDate}`]: entry
      }
    });
  },

  getChartDataEntry: (id, startDate, endDate) => {
    const {
      getCostViewEntry,
      setChartDataEntry
    } = get();

    const costViewEntry: CostViewEntry = getCostViewEntry(id);
    const dateKey = `${startDate}/${endDate}`;

    let chartDataEntry = costViewEntry.charts[dateKey];
    
    if (!chartDataEntry) {
      chartDataEntry = {
        status: 'idle',
        request: null,
        chartData: []
      };

      setChartDataEntry(id, startDate, endDate, chartDataEntry);
    }

    return chartDataEntry;
  },

  fetchCostView: (id: number) => {
    const {
      getCostViewEntry,
      setCostViewEntry
    } = get();

    const costViewEntry = getCostViewEntry(id);
    
    if (costViewEntry.request) {
      return costViewEntry.request;
    }

    const request = fetchCostView(id)
      .then(({ data: costView }) => {
        setCostViewEntry(id, { 
          ...costViewEntry, 
          costView,
          status: 'success'
        });
      })
      .catch((err) => {
        setCostViewEntry(id, { 
          ...costViewEntry, 
          status: 'error'
        });

        return Promise.reject(err);
      });

    setCostViewEntry(id, { 
      ...costViewEntry, 
      status: 'loading',
      request
    });

    return request;
  },

  fetchChartData: (id: number, startDate: string, endDate: string, granularity: Granularity, costType: CostType) => {
    const {
      getChartDataEntry,
      setChartDataEntry,
      getCostViewEntry,
      fetchCostView
    } = get();

    const chartDataEntry = getChartDataEntry(id, startDate, endDate);

    if (chartDataEntry.request) {
      return chartDataEntry.request;
    }

    const request = fetchCostView(id)
      .then(() => {
        const { costView } = getCostViewEntry(id);

        return fetchCostViewChart({
          startDate,
          endDate,
          granularity,
          costType,
          // TODO: get rid of this hardcode and migrate to `store/costViewChart`
          costDimensions: [],
          costAmortization: false,
          filter: (costView as CostView).filter
        })
      })
      .then(({ data: chartData }) => {
        setChartDataEntry(id, startDate, endDate, {
          ...chartDataEntry,
          status: 'success',
          chartData
        });
      })
      .catch((err) => {
        setChartDataEntry(id, startDate, endDate, { 
          ...chartDataEntry, 
          status: 'error'
        });

        return Promise.reject(err);
      });

    setChartDataEntry(id, startDate, endDate, { 
      ...chartDataEntry, 
      status: 'loading',
      request
    });

    return request;
  }
}));
