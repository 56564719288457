import { useCFNStacksStore } from 'store/cfnStacks'
import { useInterval } from 'helpers/useInterval'
import SplashPage from 'shared/Page/SplashPage'
import { useGlobalState } from 'state/globalState'
import { useEffect } from 'react'
import { CfnRunning } from './CfnRunning'
import { CfnDone } from './CfnDone'

export const ConnectCfnSync = () => {
  const {
    checkInterval,
    setCheckInterval,
  } = useCFNStacksStore()
  const { init, accountReady } = useGlobalState()

  useInterval(init, checkInterval)

  useEffect(() => {
    if (accountReady) {
      setCheckInterval(null)
    }
  }, [accountReady])

  return (
    <SplashPage>
      {!accountReady && <CfnRunning />}
      {accountReady && <CfnDone />}
    </SplashPage>
  )
}
