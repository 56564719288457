import { useCallback } from 'react';
import { message } from 'antd';
import { Button } from 'shared/Button';
import { useSSOSettingsStore } from 'store/ssoSettings';

export const SubmitButton = () => {
  const allowedDomains = useSSOSettingsStore((state) => state.allowedDomains);
  const settingsCreated = useSSOSettingsStore((state) => state.settingsCreated);
  const createSSOSettings = useSSOSettingsStore((state) => state.createSSOSettings);
  const updateSSOSettings = useSSOSettingsStore((state) => state.updateSSOSettings);

  const disabled = allowedDomains.length === 0;

  const handleClick = useCallback(async () => {
    try {
      if (settingsCreated) {
        await updateSSOSettings();
      } else {
        await createSSOSettings();
      }

      message.success('SSO settings saved');
    } catch {
      message.error('Unable to save SSO settings');
    }
  }, [settingsCreated, createSSOSettings, updateSSOSettings]);

  return (
    <div className='mt-[30px]'>
      <Button
        size='md'
        style='caladon'
        disabled={disabled}
        onClick={handleClick}
        label={settingsCreated ? 'Update Settings' : 'Provision SSO Settings'}
      />
    </div>
  );
};
