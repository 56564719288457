import { message } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button } from 'shared/Button';
import {
  useRulesStore,
  useRuleDeleteDialogStore
} from 'store/rules';

export interface DeleteDialogProps {
  onConfirm?: () => void;
}

export const DeleteDialog = (props: DeleteDialogProps) => {
  const { onConfirm } = props;
  const { id, close } = useRuleDeleteDialogStore();

  const rule = useRulesStore((state) => id !== null && state.getEntry(id));
  const deleteRule = useRulesStore((state) => state.deleteEntry);

  const name = rule && rule.status === 'success' && rule.data.name;

  return (
    <Dialog
      className='w-[420px] pt-[40px] px-[30px] pb-[20px]'
      open={id !== null}
      onClose={close}
    >
      <div className='font-semibold text-center text-subtitle-1'>
        Are you sure you want to delete rule '{name}' permanently?
      </div>

      <Button
        className='justify-center mt-[30px] bg-negative'
        style='caladon'
        label={`Yes, delete '${name}'`}
        onClick={() => {
          onConfirm && onConfirm();
          close();
          deleteRule(id as number)
            .then(() => {
              message.success(`Rule ${name} deleted`);
            })
            .catch(() => {
              message.error(`Unable to delete rule ${name}`);
            })
        }}
      />

      <Button
        className='justify-center mt-[20px]'
        type='tertiary'
        label='Cancel'
        onClick={close}
      />
    </Dialog>
  )
}
