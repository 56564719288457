import type {
  EC2OfferingClass,
  Lookback,
  OpportunityFiltersParams,
  OpportunityFiltersRequestBody,
  OpportunityFiltersRequestQuery,
  OpportunityFiltersRequestParams
} from 'types/savings';

export const opportunityFiltersParamsToRequestParams = (params: OpportunityFiltersParams): OpportunityFiltersRequestParams => {
  const {
    prefilter,
    pageFilters
  } = params;

  const query: OpportunityFiltersRequestQuery = {
    ...prefilter,
    team_id: prefilter.team_id ? prefilter.team_id : pageFilters && pageFilters.teamId,
    global_filter_ids: pageFilters && pageFilters.globalFilterIds,
    meta_category: pageFilters && {
      both: ['usage', 'rate', 'custom'],
      usage: ['usage', 'custom'],
      rate: ['rate', 'custom']
    }[pageFilters.group] as ('usage' | 'rate' | 'custom')[],
    platform: pageFilters && (pageFilters.platform === 'all' ? ['aws', 'gcp'] : [pageFilters.platform]),
    priority: pageFilters && pageFilters.priority,
  };

  const body: OpportunityFiltersRequestBody = {
    source: pageFilters && pageFilters.source,
    savings_plans: pageFilters && pageFilters.rateCommitments.savingsPlans.enabled ? {
      term: pageFilters.rateCommitments.savingsPlans.term,
      payment_opt: pageFilters.rateCommitments.savingsPlans.payment,
      lookback_days: pageFilters.rateCommitments.savingsPlans.lookback as Lookback
    } : undefined,
    reserved_instances: pageFilters && pageFilters.rateCommitments.reservedInstances.enabled ? {
      term: pageFilters.rateCommitments.reservedInstances.term,
      payment_opt: pageFilters.rateCommitments.reservedInstances.payment as 'NO_UPFRONT' | 'PARTIAL_UPFRONT',
      offering_class: pageFilters.rateCommitments.reservedInstances.ec2OfferingClass as EC2OfferingClass
    } : undefined
  };

  return [query, body]
};
