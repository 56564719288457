import moment from 'moment';
import { useMemo } from 'react';
import { Section, SectionTitle } from 'shared/Section';
import { PeriodChart } from 'shared/PeriodChart';
import { StatusOverlay } from 'shared/Overlay';
import { useSavingsStore } from 'store/savings';
import { pluralize } from 'helpers/formatter';

export interface OpportunityCostChartSectionProps {
  className?: string;
  opportunityId: number;
}

export const OpportunityCostChartSection = (props: OpportunityCostChartSectionProps) => {
  const {
    className = '',
    opportunityId
  } = props;

  const opportunity = useSavingsStore((store) => store.getOpportunity(opportunityId));

  const chartData = useMemo(() => {
    if (opportunity.status !== 'success') {
      return [];
    }

    return opportunity.data.opportunity_details?.cost_historical?.sort((a, b) => moment(a.date).isAfter(b.date) ? 1 : -1) || [];
  }, [opportunity])

  if (chartData.every((item) => !item.cost_impact)) {
    return null;
  }

  return (
    <Section className={`p-5 relative ${className}`}>
      <SectionTitle>
        Resource Cost {chartData.length ?
          `${chartData.length} ${pluralize(chartData.length, { one: 'day', other: 'days' })}` :
          null
        }
      </SectionTitle>

      <div className='relative'>
        <PeriodChart
          currentKey='cost_impact'
          previousKey='_'
          currentName='Resource Cost'
          granularity='day'
          data={chartData}
        />

        <StatusOverlay status={opportunity.status} />
      </div>
    </Section>
  );
}
