import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/images/LogoCorner.svg';
import { Section } from 'shared/Section';

export const Welcome = () => (
  <div className='flex items-center justify-center min-h-[100vh]'>
    <Section className='m-[60px] px-[60px] pt-[50px] pb-[60px] max-w-[800px] relative overflow-hidden'>
      <Logo className='absolute top-0 left-[-4px] opacity-20' width={179*4} height={39*4} />
      <div className='relative'>
        <h1 className='mb-4 text-[32px] font-medium'>Welcome to Cloudthread!</h1>

        <p className='mb-4'>
          We're excited to help you create a culture of engineering cloud cost ownership.
        </p>

        <p className='mb-4'>
          For the next 24 hours, your <a target='_blank' className='text-cyanine-blue hover:text-caladon-blue' href='https://docs.aws.amazon.com/cur/latest/userguide/what-is-cur.html'>Cost and Usage Report</a> will be populating and we'll be collecting savings opportunites. All Cloudthread's features will only be accessible when this is complete.
        </p>

        <p className='mb-4'>
          In the meantime, with the support of the Cost Explorer API,  you can:
        </p>

        <ul className='pl-4 mb-4 list-disc'>
          <li className='mb-2'>
            <a target='_blank' className='text-cyanine-blue hover:text-caladon-blue' href='https://docs.cloudthread.io/guides/optimizing-cloud-costs'>Use Savings Threads and Opportunities</a>  to start saving on your cloud bill
          </li>
          <li className='mb-2'>
            <a target='_blank' className='text-cyanine-blue hover:text-caladon-blue' href='https://docs.cloudthread.io/guides/creating-custom-cost-views'>Use Cost Filters and Cost Views</a>  to view cost slices meaningful to your organization
          </li>
          <li className='mb-2'>
            <a target='_blank' className='text-cyanine-blue hover:text-caladon-blue' href='https://docs.cloudthread.io/guides/performing-root-cause-analysis'>Use Cost Breakdown</a> to do granular root cause analysis of cost spikes
          </li>
          <li className='mb-2'>
            <Link to='/settings/third-part-integrations' className='text-cyanine-blue hover:text-caladon-blue'>Integrate with Slack</Link> for reporting and alerting
          </li>
          <li className='mb-2'>
            <Link to='/settings/third-part-integrations' className='text-cyanine-blue hover:text-caladon-blue'>Integrate with Jira</Link> for improve savings workflow engagement
          </li>
          <li className='mb-2'>
            <Link to='/tags/catalog' className='text-cyanine-blue hover:text-caladon-blue'>Create tag catalog entries</Link> to power automated infrastructue-as-code tagging
          </li>
        </ul>

        <p>
          If you have questions, don't hesitate to reach out through the chat icon bottom right (you'll immediately be speaking with a real human) or reference <a className='text-cyanine-blue hover:text-caladon-blue' target='_blank' href='https://docs.cloudthread.io/'>our docs.</a>
        </p>
      </div>
    </Section>
  </div>
)
