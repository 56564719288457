export type FilterValueType = 'string' | 'dict' | 'string-regex' | 'dict-regex';
export type FilterComparator = 'equals' | 'not equals';
export type FilterValueGroup = 'in' | 'like';
export type FilterGroupOperator = 'or' | 'and';

export type StringValue = string | null;
export type DictValue = [StringValue, StringValue];

export interface FilterStringCondition {
  field_name: string;
  values: string[];
  value_type: 'string';
  comparator: FilterComparator;
}

export interface FilterRegexStringCondition {
  field_name: string;
  values: string[];
  value_type: 'string-regex';
  comparator: FilterComparator;
}

export type FilterStringValueCondition = FilterStringCondition | FilterRegexStringCondition;

export interface FilterDictCondition {
  field_name: string;
  values: Record<string, string>[];
  value_type: 'dict';
  comparator: FilterComparator;
}

export interface FilterRegexDictCondition {
  field_name: string;
  values: Record<string, string>[];
  value_type: 'dict-regex';
  comparator: FilterComparator;
}

export type FilterDictValueCondition = FilterDictCondition | FilterRegexDictCondition;

export type FilterValueCondition = FilterStringValueCondition | FilterDictValueCondition;

export const isFilterStringCondition = (condition: FilterValueCondition): condition is FilterStringCondition => condition.value_type === 'string';
export const isFilterDictCondition = (condition: FilterValueCondition): condition is FilterDictCondition => condition.value_type === 'dict';
export const isFilterRegexStringCondition = (condition: FilterValueCondition): condition is FilterRegexStringCondition => condition.value_type === 'string-regex';
export const isFilterRegexDictCondition = (condition: FilterValueCondition): condition is FilterRegexDictCondition => condition.value_type === 'dict-regex';

export const isFilterStringValueCondition = (condition: FilterValueCondition): condition is FilterStringValueCondition => condition.value_type.startsWith('string');
export const isFilterDictValueCondition = (condition: FilterValueCondition): condition is FilterDictValueCondition => condition.value_type.startsWith('dict');

export interface FilterGroupCondition {
  operator: FilterGroupOperator;
  condition: FilterCondition[];
}

export type FilterCondition = FilterValueCondition | FilterGroupCondition;

export const isFilterGroupCondition = (condition: FilterCondition): condition is FilterGroupCondition => !!(condition as FilterGroupCondition).operator;
export const isFilterValueCondition = (condition: FilterCondition): condition is FilterValueCondition => !!(condition as FilterValueCondition).value_type;

export interface FilterNestedCondition extends FilterGroupCondition {
  operator: 'or',
  condition: FilterNestedConditionItem[];
};

export interface FilterNestedConditionItem extends FilterGroupCondition {
  operator: 'and',
  condition: [
    FilterValueCondition,
    ...FilterNestedCondition[]
  ]
};

export const isFilterNestedCondition = (condition: FilterCondition): condition is FilterNestedCondition =>
  isFilterGroupCondition(condition) &&
  condition.operator === 'or' &&
  condition.condition.every((childCondition) =>
    isFilterGroupCondition(childCondition) &&
    childCondition.operator === 'and' &&
    childCondition.condition.length > 0 &&
    isFilterValueCondition(childCondition.condition[0])
  );

export interface Filter {
  src: string;
  filter: FilterCondition;
}
