import type { ReactElement } from 'react';
import type { PaneProps } from './Pane';

import { Children, cloneElement } from 'react';

export interface PanesProps<TValue extends string = string> {
  value: TValue | null;
  children?: ReactElement<PaneProps<TValue>> | ReactElement<PaneProps<TValue>>[];
}

export const Panes = <TValue extends string = string>(props: PanesProps<TValue>) => {
  const {
    value,
    children
  } = props;

  return (
    <>
      {Children.map(children, (child) => child && cloneElement(child, {
        key: child.props.value,
        selected: child.props.value === value
      }))}
    </>
  );
};
