import type {
  ChildValues,
  FilterStringValueCondition,
  NestedRule
} from 'types/filters';

import { mergeArrays } from 'helpers/array';
import { matchLike } from 'helpers/regex';

export const mergeOptions = (optionsA: ChildValues | string[], optionsB: ChildValues | string[]): ChildValues | string[] => {
  if (Array.isArray(optionsA)) {
    return mergeArrays(optionsA, optionsB as string[]);
  }

  const optA = optionsA as ChildValues;
  const optB = optionsB as ChildValues;

  const result: ChildValues = {};

  const keys = Array.from(new Set([...Object.keys(optA), ...Object.keys(optB)]).values());

  keys.forEach((key) => {
    if (optA[key]) {
      if (optB[key]) {
        result[key] = mergeOptions(optA[key], optB[key]);
      } else {
        result[key] = optA[key];
      }
    } else if (optB[key]) {
      result[key] = optB[key];
    }
  });

  return result;
}



export const getOptions = (parentConditions: (FilterStringValueCondition | FilterStringValueCondition[])[], rule: NestedRule): string[] => {
  const { options } = rule;

  if (Array.isArray(options)) {
    return options;
  }

  return parentConditions.reduce((acc, conds) => {
    if (!acc) {
      return acc;
    }

    if (!Array.isArray(conds)) {
      conds = [conds];
    }

    let childEntries = Object.entries(acc as ChildValues);

    for (let cond of conds) {
      if (cond.value_type === 'string' && cond.comparator === 'equals') {
        return (acc as ChildValues)[cond.values[0]];
      }

      if (rule.exactParentRequired) {
        childEntries = [];
      }

      if (cond.value_type === 'string' && cond.comparator === 'not equals') {
        childEntries = childEntries.filter(([key]) => !cond.values.includes(key));
      }

      if (cond.value_type === 'string-regex' && cond.comparator === 'equals') {
        childEntries = childEntries.filter(([key]) => cond.values.some((pattern) => matchLike(pattern, key)));
      }

      if (cond.value_type === 'string-regex' && cond.comparator === 'not equals') {
        childEntries = childEntries.filter(([key]) => cond.values.every((pattern) => !matchLike(pattern, key)));
      }
    }

    if (childEntries.length === 0) {
      return undefined;
    }

    const merged = childEntries.map(([_, value]) => value).reduce(mergeOptions);

    return merged;
  }, options as ChildValues | string[]) as string[] || [] as string[];
}
