import type { Team } from 'types/teams';
import { ListEditor } from './ListEditor';
import { useTeamReportConfigsStore } from 'store/teamReportConfigs';

export interface TeamReportConfigsProps {
  team: Team;
}

export const TeamReportConfigs = (props: TeamReportConfigsProps) => {
  const {
    team
  } = props;

  const teamEntry = useTeamReportConfigsStore((state) => state.getEntry(team.id));
  const allReportConfigs = useTeamReportConfigsStore((state) => state.getAllReportConfigs());
  const addReportConfig = useTeamReportConfigsStore((state) => state.addTeamReportConfig);
  const confirmAddition = useTeamReportConfigsStore((state) => state.confirmAddition);
  const removeReportConfig = useTeamReportConfigsStore((state) => state.removeTeamReportConfig);
  const confirmRemoval = useTeamReportConfigsStore((state) => state.confirmRemoval);

  if (teamEntry.status === 'idle') {
    return null;
  }

  if (teamEntry.status === 'loading') {
    return <div>
      Loading
    </div>;
  }

  if (teamEntry.status === 'load-error') {
    return <div>
      Error
    </div>;
  }


  return (
    <ListEditor
      items={teamEntry.data.toArray().map(([_, reportConfig]) => reportConfig)}
      addOptions={allReportConfigs.status === 'loaded' ? 
        allReportConfigs.data.map((config) => ({
          status: 'loaded',
          data: config
        })) :
        []
      }
      getItemKey={(reportConfig) => '' + reportConfig.data.id}
      getItemTitle={(reportConfig) => reportConfig.data.name}
      addedMessage={(reportConfig) => `Report ${reportConfig.data.name} added to team ${team.name}`}
      addErrorMessage={(reportConfig) => `Unable to add report ${reportConfig.data.name} to team ${team.name}`}
      removedMessage={(reportConfig) => `Report ${reportConfig.data.name} removed from team ${team.name}`}
      removeErrorMessage={(reportConfig) => `Unable to add report ${reportConfig.data.name} from team ${team.name}`}
      onAdd={(reportConfig) => {
        addReportConfig(team.id, reportConfig.data.id, reportConfig.data);
      }}
      onAddConfirm={(reportConfig) => {
        confirmAddition(team.id, reportConfig.data.id);
      }}
      onRemove={(reportConfig) => {
        removeReportConfig(team.id, reportConfig.data.id);
      }}
      onRemoveConfirm={(reportConfig) => {
        confirmRemoval(team.id, reportConfig.data.id);
      }}
    >
      {(reportConfig) => reportConfig.data.name}
    </ListEditor>
  );
};
