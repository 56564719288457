import type { Opportunity } from 'types/savings';

import { Button2 as Button } from 'shared/Button';
import { useOpportunityAutomationDialog } from 'store/savings';
import { isAutomatableOpportunity } from 'helpers/savings';

export interface AutomateSelectedButtonProps {
  selected?: Opportunity[];
}

export const AutomateSelectedButton = (props: AutomateSelectedButtonProps) => {
  const {
    selected
  } = props;

  const automatable = selected ? selected.filter((opp) => !opp.in_progress_job_id && isAutomatableOpportunity(opp)) : [];
  const openOpportunityAutomationDialog = useOpportunityAutomationDialog((store) => store.open);

  return (
    <Button
      size='xs'
      theme='link'
      layout='inline'
      iconLeft='rocket'
      className='!text-orange hover:!text-orange-hover disabled:!text-black/30'
      disabled={automatable.length === 0}
      onClick={() => {
        openOpportunityAutomationDialog(automatable);
      }}
    >
      Automate {automatable.length > 0 && `(${automatable.length})`}
    </Button>
  );
};
