import type { ThreadStatusGroup } from 'types/savings';

import { DEFAULT_THREAD_STATUS_GROUP, isThreadStatusGroup } from 'types/savings';
import { useQueryParam } from 'hooks/queryParams';

const parse = (param: string | null) => isThreadStatusGroup(param) ? param : DEFAULT_THREAD_STATUS_GROUP;
const serialize = (value: ThreadStatusGroup) => value === DEFAULT_THREAD_STATUS_GROUP ? null : value;

export const useThreadStatusQuery = () => useQueryParam({
  name: 'status',
  parse,
  serialize
});
