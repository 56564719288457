import type { Opportunity } from 'types/savings';

import { ArchiveActionButton } from './ArchiveActionButton';
import { RestoreActionButton } from './RestoreActionButton';
import { OpportunityAutomationButton } from './OpportunityAutomationButton';
import { isAutomatableOpportunity } from 'helpers/savings';

export interface OpportunityActionButtonProps {
  opportunity: Opportunity;
}

export const OpportunityActionButton = (props: OpportunityActionButtonProps) => {
  const {
    opportunity
  } = props;

  if (opportunity.status === 'trash') {
    return <RestoreActionButton opportunity={opportunity} />;
  }

  return <>
    <ArchiveActionButton opportunity={opportunity} />
    {isAutomatableOpportunity(opportunity) &&
      <OpportunityAutomationButton
        opportunity={opportunity}
        icon='rocket'
        theme='gray'
        disabled={!!opportunity.in_progress_job_id}
        className='text-orange hover:!text-orange-hover ml-2 disabled:!text-black/30'
      />
    }
  </>;
}
