import type { 
  DataConfig, 
  DataCollectionStatus, 
  DataCollectionStatusType, 
  ThresholdsSummary, 
  DataConfigUpdatePayload,
  ParameterGroup,
  ThresholdsMap,
} from 'types/dataCollection';
import moment, { Moment } from 'moment';
import { maxDate } from 'helpers/date';
import { createDatesComparator } from 'helpers/sort';

export const filterUnviewedErrors = (config: DataConfig, statuses: DataCollectionStatusType[] = ['error', 'access']): DataCollectionStatus[] => {
  const statusLastViewedAt = moment(config.status_last_viewed_at || [1970, 0, 1]);

  return config.statuses.filter((status) =>
    statuses.includes(status.status_type) &&
    statusLastViewedAt.isBefore(status.created_at)
  );
}

export const hasUnviewedErrors = (config: DataConfig, statuses: DataCollectionStatusType[] = ['error', 'access']): boolean => {
  const statusLastViewedAt = moment(config.status_last_viewed_at || [1970, 0, 1]);

  return config.statuses.some((status) =>
    statuses.includes(status.status_type) &&
    statusLastViewedAt.isBefore(status.created_at)
  );
}

export const compareCreatedAt = createDatesComparator((status: DataCollectionStatus) => status.created_at ? moment(status.created_at) : null);

export const splitStatusesByCollectionId = (statuses: DataCollectionStatus[]): Record<string, DataCollectionStatus[]> => {
  const splitted = statuses.reduce((acc, status) => {
    acc[status.collection_id] ??= [];
    acc[status.collection_id].push(status);

    return acc;
  }, {} as Record<string, DataCollectionStatus[]>);

  Object.entries(splitted).forEach(([collectionId, collectionStatuses]) => {
    splitted[collectionId] = collectionStatuses.sort(compareCreatedAt).reverse();
  });

  return splitted;
}

export const pickConfigsWithLatestNonViewedIssues = (configs: DataConfig[], dateLimit: Moment): [DataConfig, DataCollectionStatus[]][] => {
  return configs.map((config) => {
    const configLastViewedAt = moment(config.status_last_viewed_at || 0);
    const configMinDate = maxDate(configLastViewedAt, dateLimit);
    const statusesByCollectionId = splitStatusesByCollectionId(config.statuses);

    const latestStatuses = Object.values(statusesByCollectionId)
      .map(([status]) => status)
      .filter(Boolean);

    const issues = latestStatuses.filter((status) => ['access', 'error'].includes(status.status_type));

    const nonViewedIssues = issues.filter((issue) => configMinDate.isBefore(issue.created_at));

    const result: [DataConfig, DataCollectionStatus[]] = [config, nonViewedIssues];

    return result;
  }).filter(([_, issues]) => issues.length);
}

export const pickLatestNonViewedIssues = (configs: DataConfig[], minDate: Moment): DataCollectionStatus[] => {
  return pickConfigsWithLatestNonViewedIssues(configs, minDate)
    .reduce((acc, [_, issues]) => [...acc, ...issues], [] as DataCollectionStatus[]);
}


export const mergeThresholds = (map: ThresholdsMap): ThresholdsSummary => {
  const result: ThresholdsSummary = {
    ids: [],
    thresholds: {},
    thresholdsTitles: {}
  };

  Object.entries(map).forEach(([id, thresholdsByOppTypeId]) => {
    result.ids.push(+id);

    Object.entries(thresholdsByOppTypeId).forEach(([opportunityTypeId, thresholds]) => {
      if (!result.thresholds[opportunityTypeId]) {
        result.thresholds[opportunityTypeId] = {};
      }

      Object.entries(thresholds).forEach(([thresholdKey, thresholdConfig]) => {
        if (!result.thresholdsTitles[opportunityTypeId]) {
          result.thresholdsTitles[opportunityTypeId] = thresholdConfig.parameter_group_title
        }

        if (!result.thresholds[opportunityTypeId][thresholdKey]) {
          result.thresholds[opportunityTypeId][thresholdKey] = {
            ...thresholdConfig,
            values: {}
          };
        }

        const thresholdConfigValue = thresholdConfig.value + ''
        if (!result.thresholds[opportunityTypeId][thresholdKey].values[thresholdConfigValue]) {
          result.thresholds[opportunityTypeId][thresholdKey].values[thresholdConfigValue] = [];
        }

        result.thresholds[opportunityTypeId][thresholdKey].values[thresholdConfigValue].push(+id);
      });
    });
  });

  return result;
}

export const mergeDrafts = (drafts: DataConfigUpdatePayload[]) => {
  const result: DataConfigUpdatePayload = {
    thresholds: {}
  };

  drafts.forEach((draft) => {
    const thresholdsByOppTypeId = draft.thresholds;

    thresholdsByOppTypeId && Object.entries(thresholdsByOppTypeId).forEach(([opportunityTypeId, thresholds]) => {
      if (!result.thresholds) {
        return;
      }

      if (!result.thresholds[opportunityTypeId]) {
        result.thresholds[opportunityTypeId] = {};
      }

      Object.entries(thresholds).forEach(([thresholdKey, thresholdConfig]) => {
        if (!result.thresholds) {
          return;
        }

        if (!result.thresholds[opportunityTypeId][thresholdKey]) {
          result.thresholds[opportunityTypeId][thresholdKey] = {
            ...thresholdConfig,
          }
        } else if (result.thresholds[opportunityTypeId][thresholdKey].value !== thresholdConfig.value) {
          result.thresholds[opportunityTypeId][thresholdKey].value = thresholdConfig.default_value;
        }
      });
    });
  });

  return result;
}

export const mapThresholds = (paramGroups: ParameterGroup[]): ThresholdsMap => {
  const result: ThresholdsMap = {};

  paramGroups.forEach((paramGroup) => {
    paramGroup.data_collection_config_ids.forEach((id) => {
      if (!result[id]) {
        result[id] = {}
      }

      if (!result[id][paramGroup.parameter_group]) {
        result[id][paramGroup.parameter_group] = {};
      }
  
      if (!result[id][paramGroup.parameter_group][paramGroup.name]) {
        result[id][paramGroup.parameter_group][paramGroup.name] = {
          ...paramGroup.param_dict,
          value: paramGroup.value,
          parameter_group_title: paramGroup.parameter_group_title,
        };
      }
    })
  });

  return result;
}
