import type { Granularity } from 'types/common';
import type { DataEntry } from 'types/dataEntries';
import type { CostViewFilter, CostViewFilterWithTeam } from 'types/costViews/filters';
import type { DrillDownStep, DrillDownField, DrillDownData } from 'types/costViews/drillDown';

import { Section } from 'shared/Section';
import { Loading } from 'shared/Loading';
import { DrilldownStep } from './DrilldownStep';
import { Button, IconButton } from 'shared/Button';
import { useGlobalState } from 'state/globalState';
import { useTagOverviewStore } from '../useTagOverviewStore';

export interface DrilldownProps {
  className?: string;
  startDate: string;
  endDate: string;
  granularity: Granularity;
  filter: CostViewFilter | CostViewFilterWithTeam;
  breakdown: DrillDownField;
  drillDownSteps: DrillDownStep[];
  drillDown: DataEntry<DrillDownData>;
  availableSteps: DrillDownField[][],
  availableBreakdowns: DrillDownField[],
  setCurrentStep: (value: string) => void;
  setBreakdown: (field: DrillDownField) => void;
  swapStepField: (oldField: DrillDownField, newField: DrillDownField) => void;
  swapStepValue: (field: DrillDownField, value: string) => void;
  removeDrillDown: () => void;
  rollbackDrillDown: () => void;
}

export const Drilldown = (props: DrilldownProps) => {
  const {
    className = '',
    startDate,
    endDate,
    granularity,
    filter,
    breakdown,
    drillDownSteps,
    drillDown,
    availableSteps,
    availableBreakdowns,
    setCurrentStep,
    setBreakdown,
    swapStepField,
    swapStepValue,
    removeDrillDown,
    rollbackDrillDown
  } = props;

  const { 
    setDrillDownOpened,
  } = useTagOverviewStore()
  //const logEvent = useGlobalState((state) => state.logEvent);

  const hasBackButton = drillDownSteps.length > 1

  const handleClearClick = () => {
    removeDrillDown()
    //logEvent('clear_drilldown')
  }

  const handleRollbackClick = () => {
    rollbackDrillDown()
    //logEvent('rollback_drilldown')
  }

  return (
    <Section className={`relative flex flex-col ${className}`}>
      <Section.Head wfull className="shrink-0 grow-0">
        Drilldown
        <IconButton className="ml-auto text-[13px]" size="md" type="tertiary" icon="aside-layout" title="Hide" onClick={() => setDrillDownOpened(false)} />
      </Section.Head>
      <div className="min-h-0 px-4 py-3 overflow-y-auto shrink grow">
        {drillDownSteps.map(([field, value], index) => {
          return (
            <DrilldownStep
              startDate={startDate}
              endDate={endDate}
              granularity={granularity}
              filter={filter}
              drillDownSteps={drillDownSteps.slice(0, index)}
              key={field}
              field={field}
              value={value} 
              fieldOptions={availableSteps[index]}
              onFieldChange={(newField: DrillDownField) => {
                swapStepField(field, newField);
                //logEvent('swap_drilldown_step', { 'new_field': newField, 'old_field': field })
              }}
              onValueChange={(newValue: string) => {
                swapStepValue(field, newValue);
                //logEvent('swap_drilldown_step_value', { 'field': field })
              }}
            />
          );
        })}
        {availableBreakdowns.length > 0 && (
          <DrilldownStep
            startDate={startDate}
            endDate={endDate}
            granularity={granularity}
            filter={filter}
            drillDownSteps={drillDownSteps}
            field={breakdown}
            value={null} 
            fieldOptions={availableBreakdowns}
            onFieldChange={(key) => {
              setBreakdown(key);
              //logEvent('set_breakdown', { 'breakdown': key, 'source': 'drilldown_pane' });
            }}
            onValueChange={(key) => {
              setCurrentStep(key);
              //logEvent('set_drilldown_current_step', { 'field': breakdown, 'source': 'drilldown_pane' });
            }}
          />
        )}
      </div>
      <div className="h-[60px] flex items-center px-[20px] shadow-border-t shrink-0">
        {hasBackButton && (
          <Button className="basis-1/2 justify-center mr-[5px]" type="tertiary" size="md" icon="arrow-back" label="Step Back" onClick={handleRollbackClick} />
        )}
        <Button className="basis-1/2 justify-center ml-[5px]" type="tertiary" size="md" icon="close-circle" label="Clear and Close" onClick={handleClearClick} />
      </div>
      {drillDown.status === 'loading' && <Loading />}
    </Section>
  )
}
