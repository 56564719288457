import type { Setter } from 'types/common';
import type { ThreadSort } from 'types/savings';

import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setSearchParam } from 'helpers/queryParams';
import {
  parseThreadSortField,
  serializeThreadSortField,
  getThreadSortDirParser,
  getThreadSortDirSerializer
} from 'helpers/savings';

export const useThreadSortQuery = (): [ThreadSort, Setter<ThreadSort>] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const fieldParam = searchParams.get('sortBy');
  const field = parseThreadSortField(fieldParam);

  const parseThreadSortDir = getThreadSortDirParser(field);

  const dirParam = searchParams.get('sortDir');
  const dir = parseThreadSortDir(dirParam);

  const sort = useMemo(() => ({ dir, field }), [dir, field]);

  const setSort = useCallback((newSort: ThreadSort) => {
    const newFieldParam = serializeThreadSortField(newSort.field);
    const parseNewThreadSortDir = getThreadSortDirSerializer(newSort.field)
    const newDirParam = parseNewThreadSortDir(newSort.dir);

    console.log(newFieldParam, newDirParam);

    setSearchParams((currentSearchParams) => {
      const newSearchParams = new URLSearchParams(currentSearchParams);

      setSearchParam(newSearchParams, 'sortBy', newFieldParam);
      setSearchParam(newSearchParams, 'sortDir', newDirParam);
    
      return newSearchParams;
    });
  }, []);

  return [sort, setSort];
}
