import React, { Children, cloneElement, useState } from 'react';
import type { TabProps, TabSize } from './Tab';

export interface TabsProps<TValue extends string = string> {
  defaultValue?: TValue | null;
  value?: TValue | null;
  size?: TabSize;
  onChange?: (value: TValue) => void;
  children?: React.ReactElement<TabProps<TValue>> | React.ReactElement<TabProps<TValue>>[];
}

export const Tabs = <TValue extends string = string>(props: TabsProps<TValue>) => {
  const {
    size = 'md',
    defaultValue,
    children,
    onChange
  } = props; 
  const [stateValue, setStateValue] = useState<TValue | null | undefined>(defaultValue);
  const value = props.value !== undefined ? props.value : stateValue;

  const handleTabClick = (tabValue: TValue) => {
    if (tabValue !== value) {
      onChange && onChange(tabValue);
      setStateValue(tabValue);
    }
  };

  const itemClassName = `last-child:mr-0 ${{
    xs: 'text-[11px] line-[13px] mr-[8px]',
    sm: 'mr-[12px]',
    md: 'mr-[20px]',
    lg: 'mr-[24px]'
  }[size]}`;

  return (
    <ul className='flex'>
      {Children.map(
        children,
        (tab) => tab ?
          <li className={itemClassName}>
            {cloneElement(tab, {
              key: tab.props.value,
              size,
              selected: tab.props.value === value,
              onClick: (value: TValue) => {
                tab.props.onClick && tab.props.onClick(value);
                handleTabClick(value);
              }
            })}
          </li> :
          null
      )}
    </ul>
  );
}
