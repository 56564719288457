import { Checkbox } from 'antd';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useDataConfigWithThresholdsStore } from 'store/dataConfigs';
import { isSavingsDataConfig } from 'types/dataCollection';

export interface AccountsListProps {
  value: number[];
  onChange: (value: number[]) => void;
}

export const AccountsList = (props: AccountsListProps) => {
  const dataConfigs = useDataConfigWithThresholdsStore((store) => store.getLibrary());
  
  const options = useDerivedDataEntry(
    { dataConfigs },
    ({ dataConfigs }) =>
    dataConfigs
      .filter(isSavingsDataConfig)
      .map((config) => {
          const awsAccountId = config.collection_details.aws_account_id;
          const awsAccountName = config.collection_details.aws_account_name;

          const label = awsAccountName ? `${awsAccountName} (${awsAccountId})` : awsAccountId;

          return {
            value: config.id,
            label
          }
        })
  );

  const {
    value,
    onChange
  } = props;
  
  return (
    <div className='flex flex-col border-r border-r-silver-grey-100'>
      {options.status === 'success' && (
        <div className='py-[10px] pl-[30px] pr-[20px] border-b border-b-silver-grey-100'>
          <Checkbox
            checked={value.length === options.data.length}
            indeterminate={value.length > 0 && options.data.length < value.length}
            onChange={(event) => {
              onChange(
                event.target.checked ? 
                  options.data.map((opt) => opt.value) :
                  []
              );
            }}
          >
            All Accounts
          </Checkbox>
        </div>
      )}
      <div className='overflow-x-auto pl-[30px] pr-[20px]'>
        {options.status === 'success' && options.data.map((option) =>
          <div key={option.value} className='my-[10px]'>
            <Checkbox
              value={option.value}
              checked={value.includes(option.value)}
              onChange={(event) => {
                const newValue = event.target.checked ?
                  [...value, option.value] :
                  value.filter((val) => val !== option.value);

                onChange(newValue);
              }}
            >
              {option.label}
            </Checkbox>
          </div>
        )}
      </div>
    </div>
  );
}
