import type { Granularity } from 'types/dateFilter';
import type { DataEntry } from 'types/dataEntries';
import type { CostViewChart, CostViewForecastInfo } from 'types/costViews/charts';
import type { EventsData } from 'types/costViews/events';
import type { ChartType } from 'types/chart';

import { PeriodChart } from 'shared/PeriodChart';
import { PeriodBarChart } from 'shared/PeriodBarChart';
import { StatusOverlay } from 'shared/Overlay';

import { useCumulative } from 'pages/CostsOverview/hooks/cumulative';

export interface TotalChartProps {
  chartDataEntry: DataEntry<CostViewChart>;
  prevChartDataEntry: DataEntry<CostViewChart>;
  forecast: DataEntry<CostViewForecastInfo> | null;
  budget?: number;
  chartType: ChartType;
  events: EventsData[];
  setEventsToDisplay: (targetEvents: EventsData[]) => void;
  format: (value: number) => string;
  granularity: Granularity;
}

export const TotalChart = (props: TotalChartProps) => {
  const {
    chartDataEntry,
    prevChartDataEntry,
    forecast,
    chartType,
    events,
    setEventsToDisplay,
    format,
    budget,
    granularity
  } = props;

  const [cumulative] = useCumulative();
  const data = chartDataEntry.status === 'success' ? chartDataEntry.data : [];
  const formatter = ({ value }: { value: number }) => format(value);

  return (
    <div className='relative'>
      {chartType === 'bar' ? (
        <PeriodBarChart
          events={events}
          setEventsToDisplay={setEventsToDisplay}
          data={data}
          prevData={cumulative ? prevChartDataEntry.status ==='success' ? prevChartDataEntry.data : [] : undefined}
          cumulative={cumulative}
          budget={budget}
          currentKey='current_timeframe_cost'
          previousKey='previous_timeframe_cost'
          granularity={cumulative ? 'day' : granularity}
          formatter={formatter}
          forecast={forecast && forecast.status === 'success' ? forecast.data.data : undefined}
        />
      ) : (
        <PeriodChart
          events={events}
          setEventsToDisplay={setEventsToDisplay}
          data={data}
          prevData={cumulative ? prevChartDataEntry.status ==='success' ? prevChartDataEntry.data : [] : undefined}
          cumulative={cumulative}
          budget={budget}
          currentKey='current_timeframe_cost'
          previousKey='previous_timeframe_cost'
          granularity={cumulative ? 'day' : granularity}
          formatter={formatter}
          forecast={forecast && forecast.status === 'success' ? forecast.data.data : undefined}
        />
      )}

      {chartDataEntry.status === 'idle' && (
        <div className='absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center rounded-inherit bg-white/80'>
          <div className='font-medium text-[24px] text-gray-300'>
            Please fill required fields
          </div>
        </div>
      )}

      <StatusOverlay status={chartDataEntry.status} />
    </div>
  )
};
