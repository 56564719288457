import type { Granularity } from 'types/dateFilter';
import type { CostFilterFetchParams } from 'types/costViews';

export type WidgetType = 'cost-view-chart' | 'unit-metric-chart';

export interface CostViewChartWidget {
  type: 'cost-view-chart';
  settings: {
    viewId: number | null;
    duration: number;
    granularity: Granularity;
  } & CostFilterFetchParams;
}

export interface UnitMetricChartWidget {
  type: 'unit-metric-chart';
  settings: {
    metricId: number | null;
    duration: number;
    granularity: Granularity;
  } & CostFilterFetchParams;
}

export interface EmptyWidget {
  type: null;
  settings: {};
}

export type Widget = CostViewChartWidget | UnitMetricChartWidget | EmptyWidget;

export const isCostViewChartWidget = (widget: Widget): widget is CostViewChartWidget => widget.type === 'cost-view-chart';
export const isUnitMetricChartWidget = (widget: Widget): widget is UnitMetricChartWidget => widget.type === 'unit-metric-chart';
export const isEmptyWidget = (widget: Widget): widget is CostViewChartWidget => widget.type === 'cost-view-chart';
