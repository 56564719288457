import type { ReactNode, ComponentProps } from 'react';
import type { CostViewFilter, DrillDownField } from 'types/costViews';

import moment from 'moment';
import { useMemo } from 'react';

import { formatCurrency } from 'helpers/formatter';

import { Icon } from 'shared/Icon';
import { Section } from 'shared/Section';
import { Loading } from 'shared/Loading';
import { DoughnutChart } from 'shared/Chart';
import { ArrowButton } from './ArrowButton';

import { useCostViewDrillDown } from 'store/costViewDrillDown';

export interface DiagramSectionProps {
  icon: ComponentProps<typeof Icon>['icon'];
  title: ReactNode;
  breakdown: DrillDownField;
}

const COLORS = [
  '#00A6C7',
  '#1E6EC2',
  '#F2A65A',
  '#4749BF',
  '#7678ED'
];

const EMPTY_FILTER: CostViewFilter = {
  src: 'cur',
  filter: { operator: 'or', condition: [] },
};

export const DiagramSection = (props: DiagramSectionProps) => {
  const {
    icon,
    title,
    breakdown,
  } = props;

  const drillDown = useCostViewDrillDown({
    endDate: moment().add(-1, 'day').format('YYYY-MM-DD'),
    startDate: moment().add(-1, 'month').format('YYYY-MM-DD'),
    granularity: 'month',
    costType: 'unblended_cost',
    costDimensions: [],
    costAmortization: false,
    filter: EMPTY_FILTER,
    breakdown,
    drillDownSteps: []
  });

  const mainEntries = useMemo(() => {
    if (drillDown.status !== 'success') {
      return [];
    }

    let total = 0;

    const entries = Object.entries(
      drillDown.data.data
        .filter((data) => (data.current_timeframe_cost ?? 0) >= 0).reduce((acc, item) => {
          const { current_timeframe_cost: cost } = item;
          const key = item[breakdown];
          const label = drillDown.data.alias_map[key] || key;

          total += cost ?? 0;

          let existingEntry = acc[key];

          if (!existingEntry) {
            existingEntry = {
              cost: 0,
              label
            };

            acc[key] = existingEntry;
          }
        
          existingEntry.cost += cost ?? 0;

          return acc;
        }, {} as {
          [key: string]: {
            cost: number,
            label: string
          }
        })
      )
      .map(([_, entry]) => entry)
      .sort((entryA, entryB) => entryB.cost - entryA.cost)
      .map((entry, index) => ({
        ...entry,
        color: COLORS[index]
      }));

    const otherIndex = entries.findIndex((entry, index) => index >= 5 || entry.cost / total < 0.01);

    const mainEntries = entries.slice(0, otherIndex);
    const otherEntries = entries.slice(otherIndex);

    if (otherEntries.length > 0) {
      mainEntries.push({
        label: 'Other',
        cost: otherEntries.reduce((sum, { cost }) => sum + cost, 0),
        color: '#D3DCE6'
      });
    }

    return mainEntries;
  }, [drillDown]);

  const data = useMemo(() => {
    return {
      datasets: [{
        data: mainEntries.map(({ cost }) => cost),
        backgroundColor: mainEntries.map(({ color }) => color),
        borderAlign: 'inner' as 'inner',
        borderWidth: 0
      }],
      labels: mainEntries.map(({ label }) => label)
    };
  }, [mainEntries]);

  return (
    <Section className='relative'>
      <div className='px-[20px] h-[50px] text-[16px] font-medium flex items-center'>
        <Icon
          icon={icon}
          className='mr-2'
        />
        {title}
        <ArrowButton to={`/costs-overview/?breakdown=${breakdown}`} />
      </div>

      <div className='flex items-center pb-[10px]'>
        <div className='w-[180px] h-[180px]'>
          {data && (
            <DoughnutChart
              data={data}
              options={{
                cutout: 45,
                layout: {
                  padding: 15
                }
              }}
            />
          )}
        </div>

        <ul className='grow shrink min-w-0 pl-[10px] pr-[20px]'>
          {mainEntries.map(({ cost, label, color }, index) => (
            <li className={`flex items-center py-1 ${index > 0 ? 'shadow-border-t' : ''}`}>
              <div
                className={`text-[13px] w-[8px] h-[8px] rounded mr-[10px] bg-[${color}]`}
                style={{ backgroundColor: color }}
              />
              <div className='whitespace-nowrap overflow-hidden text-ellipsis mr-[10px] min-w-0' title={label}>
                {label}
              </div>
              <div className='ml-auto'>
                {formatCurrency(cost)}
              </div>
            </li>
          ))}
        </ul>
      </div>

      {drillDown.status === 'loading' && <Loading />}
    </Section>
  );
};
