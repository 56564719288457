import { Input } from 'antd';
import { useState }  from 'react';
import { Button } from 'shared/Button';
import { StatusOverlay } from 'shared/Overlay';
import { useJiraWebhook } from 'store/jiraWebhook';
import { JiraProvider } from 'types/users';

export interface JiraWebhookProps {
  provider: JiraProvider;
}

export const JiraWebhook = (props: JiraWebhookProps) => {
  const { provider } = props;
  const webhook = useJiraWebhook(provider);
  const [shown, setShown] = useState(false)

  return <div className='relative p-2'>
    <div className='flex items-center justify-end'>
      <Button
        type='secondary'
        size='sm'
        onClick={() => { setShown(!shown); }}
        label={shown ? 'Hide Webhook URL' : 'Show Webhook URL'}
      />
    </div>
    {shown &&
      <div className='relative mt-2'>
        <Input.TextArea
          className='w-full'
          autoSize
          readOnly
          value={webhook.status === 'success' ?
            webhook.data.webhook_url :
            ''
          }
        />
        <StatusOverlay status={webhook.status} />
      </div>
    } 
  </div>
}
