import { Icon } from 'shared/Icon';
import { Overlay } from './Overlay';

export interface StatusOverlayProps {
  status: 'idle' | 'loading' | 'success' | 'error';
}

export const StatusOverlay = (props: StatusOverlayProps) => {
  const {
    status
  } = props;

  if (status === 'success') {
    return null;
  }

  if (status === 'error') {
    return (
      <Overlay>
        <span className='font-bold text-gray-300 text-[40px]'>
          Error
        </span>
      </Overlay>
    );
  }

  return (
    <Overlay>
      <Icon className='w-[40px] h-[40px] text-cyanine-blue' icon='spinner' spin />
    </Overlay>
  );
}
