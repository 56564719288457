import type { Setter } from 'types/common';
import { useCallback } from 'react';
import { useQueryParam } from 'hooks/queryParams';
import { useTeamsStore } from 'store/teams';
import { useGlobalState } from 'state/globalState';

export const useTeamId = (defaultTeamId: number | null = null): [number | null, Setter<number | null> | null] => {
  const user = useGlobalState((store) => store.user);
  const teams = useTeamsStore((store) => store.getLibrary()); 

  const parse = useCallback((param: string | null) => {
    if (param === null || teams.status !== 'success') {
      return defaultTeamId;
    }

    if (param === 'none') {
      return null;
    }

    const teamId = +param;

    if (teams.data.some((team) => team.id === teamId)) {
      return teamId;
    }

    return defaultTeamId;
  }, [teams, defaultTeamId]);

  const serialize = useCallback((teamId: number | null) => {
    if (teamId === defaultTeamId) {
      return null;
    }

    if (teamId === null) {
      return 'none';
    }

    return `${teamId}`;
  }, [defaultTeamId]);

  const query = useQueryParam({
    name: 'teamId',
    parse,
    serialize
  });

  if (user) {
    if (['admin', 'owner'].includes(user.role) && !user.team) {
      return query;
    }
    
    return [user.team?.id ?? null, null];
  }
  
  return [null, null];
};
