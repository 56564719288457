import type { IdleDataEntry } from 'types/dataEntries';
import type { KeyedStore } from 'types/stores';
import type { JiraUser } from 'types/savings'
import { useMemo } from 'react';
import { Select } from 'antd';

export interface JiraUserSelectProps {
  projectKey: string;
  value: string;
  onChange: (value: string, label: string) => void;
  store: KeyedStore<string, string, JiraUser[]>;
}

export const JiraUserSelect = (props: JiraUserSelectProps) => {
  const {
    projectKey,
    value,
    onChange,
    store
  } = props;

  const users = store((store) => {
    if (!projectKey) {
      return { status: 'idle' } as IdleDataEntry;
    }

    return store.getEntry(projectKey);
  });

  const options = useMemo(() => {
    if (users.status !== 'success') {
      return [];
    }

    return users.data.map(({ name, jira_account_id }) => ({
      value: jira_account_id,
      label: name
    }));
  }, [users]);

  return <Select
    className='w-full'
    disabled={options.length === 0}
    value={value}
    onChange={(value, option) => {
      onChange(value, (option as {value: string, label: string}).label)
    }}
    options={options}
    allowClear
  />;
}
