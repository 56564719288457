import type { DataEntry } from 'types/dataEntries';
import type { RuleTag } from 'types/rules';

import { useCostFilterValue } from 'hooks/costFilter';
import { useCostViewChartStore } from 'store/costViewChart';
import { FetchStatus } from 'types/common';
import moment from 'moment';
import {CostViewChart} from 'types/costViews';
import {useDatesQuery} from 'hooks/useDatesQuery';
import {cleanupCostViewFilter} from 'helpers/costViews';

export interface UseTagCostsParams {
  src: string;
  tagFilters: {
    [key: string]: {
      [value: string]: RuleTag;
    }
  }
};

interface TagCosts {
  [key: string]: {
    [value: string]: {
      absolute: number;
      relative: number;
    }
  }
}

const calcCost = (chart: DataEntry<CostViewChart>) => chart.status === 'success' ?
  chart.data.reduce((sum, item) => item.current_timeframe_cost ? sum + item.current_timeframe_cost : sum, 0) :
  0;

export const useTagCosts = (params: UseTagCostsParams) => {
  const {
    src,
    tagFilters
  } = params;

  const {
    startDate,
    endDate
  } = useDatesQuery({ defaultGranularity: 'month', defaultLength: 0 });

  const costFilter = useCostFilterValue();
  const commonParams = {
    ...costFilter,
    granularity: 'month' as 'month',
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  };

  return useCostViewChartStore((store) => {
    const tagCosts: TagCosts = {};
    const totalChart = store.getEntry({
      ...commonParams,
      filter: cleanupCostViewFilter({
        src,
        filter: {
          operator: 'or',
          condition: []
        }
      })
    });
    const totalCost = calcCost(totalChart);

    let status: FetchStatus = totalChart.status;

    Object.entries(tagFilters).forEach(([key, values]) => {
      tagCosts[key] = {};

      Object.entries(values).forEach(([value, tag]) => {
        const chart = store.getEntry({
          filter: { src, filter: tag.filter },
          ...commonParams
        });

        if (status !== 'error' && chart.status !== 'success') {
          status = chart.status;
        }
          
        if (status === 'success') {
          const tagCost = calcCost(chart);

          tagCosts[key][value] = {
            absolute: tagCost,
            relative: tagCost / totalCost
          };
        }
      });
    });

    if (status === 'success') {
      return {
        status,
        data: {
          totalCost,
          tagCosts
        }
      };
    }

    return { status };
  });
}
