import { useCostViewConstructor } from 'store/costViewConstructor';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { parseTeamFilterConstructor } from 'helpers/teams';

export const useTeamFilterConstructor = () => {
  const costViewConstructor = useCostViewConstructor(); 

  return useDerivedDataEntry(
    { costViewConstructor },
    ({ costViewConstructor }) => parseTeamFilterConstructor(costViewConstructor)
  );
};
