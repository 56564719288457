import type { OpportunityPagination, StatusFilterValue } from 'types/savings';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Input }  from 'antd';
import { Button2 as Button } from 'shared/Button';
import { Dialog } from 'shared/Dialog';
import { OpportunitiesBlock } from '../content/OpportunitiesBlock';
import { useSavingsStore, useThreadCreateDialog } from 'store/savings';
import { message } from 'antd';
import { renderOpportunitySlug, validateThreadDraft } from 'helpers/savings';
import { RadioButton } from 'shared/RadioButtonGroup';

export interface  ThreadCreateDialogProps {
  ignorePageFilters?: boolean;
}

export const ThreadCreateDialog = (props: ThreadCreateDialogProps) => {
  const {
    ignorePageFilters
  } = props;

  const navigate = useNavigate();
  const { opened, close } = useThreadCreateDialog();
  const newThread = useSavingsStore((store) => store.newThread);
  const setNewThread = useSavingsStore((store) => store.setNewThread);
  const createThread = useSavingsStore((store) => store.createThread);
  const resetNewThread = useSavingsStore((store) => store.resetNewThread);

  const [pagination, setPagination] = useState<OpportunityPagination>({
    ids: [],
    perPage: 10
  });

  const [statusFilter, setStatusFilter] = useState<StatusFilterValue>('unassigned')

  const [tableExpanded, setTableExpanded] = useState(false);

  useEffect(() => {
    if (opened) {
      setTableExpanded(newThread.opportunities.length === 0);
    }
  }, [opened]);

  return (
    <Dialog
      className='w-[800px]'
      open={opened}
      onClose={() => {
        close();
        resetNewThread();
      }}
    >
      <Dialog.Title>
        Create New Thread
      </Dialog.Title>

      <div className='px-8 overflow-x-auto border-t'>
        <div className='mt-4 mb-2 font-medium text-subtitle-1'>
          Name
        </div>

        <Input
          className='mb-[20px]'
          value={newThread.name}
          onChange={(event) => {
            setNewThread({
              ...newThread,
              name: event.target.value
            });
          }}
        />

        <div className='mb-2 font-medium text-subtitle-1'>
          Description
        </div>
        
        <Input.TextArea
          className='mb-[20px]'
          value={newThread.body}
          onChange={(event) => {
            setNewThread({
              ...newThread,
              body: event.target.value
            });
          }}
        />

        {tableExpanded ? (
          <OpportunitiesBlock
            title={`Opportunities (${newThread.opportunities.length})`}
            selected={newThread.opportunities}
            onSelect={(opportunities) => {
              setNewThread({ ...newThread, opportunities });
            }}
            pagination={pagination}
            onPagination={setPagination}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            overrideOpportunityActions={<div/>}
            extraStatusFilters={<RadioButton value='unassigned'>Unassigned</RadioButton>}
            ignorePageFilters={ignorePageFilters}
          />
        ) : <>
          <div className='flex justify-between mb-2 font-medium text-subtitle-1'>
            Opportunities ({newThread.opportunities.length})

            <Button
              size='xs'
              theme='gray'
              layout='inline'
              iconLeft='edit'
              onClick={() => { setTableExpanded(true); }}
            >
              Edit
            </Button>
          </div>

          <ul className='mb-5'>
            {newThread.opportunities.map((opp) => <li className='my-[5px]'>
              {renderOpportunitySlug(opp)}
            </li>)}
          </ul>

        </>}

      </div>

      <div className='flex justify-end p-4 border-t'>
        <Button
          size='m'
          theme='filled'
          disabled={!validateThreadDraft(newThread)}
          onClick={() => {
            createThread()
              .then((newThread) => {
                message.success(`Thread '${newThread.name}' created`);
                close();
                resetNewThread();
                navigate(`/savings/threads/${newThread.id}`);
              })
              .catch(() => {
                message.error('Unable to create thread');
              });
          }}
        >
          Create New Thread
        </Button>
      </div>
    </Dialog>
  );
};
