import type { ChangeEvent, MouseEvent } from 'react';
import isValidDomain from 'is-valid-domain';
import { useCallback } from 'react';
import { Input } from 'antd';
import { IconButton } from 'shared/Button';
import { useSSOSettingsStore } from 'store/ssoSettings';

export const AllowedDomainsControl = () => {
  const allowedDomains = useSSOSettingsStore((state) => state.allowedDomains);
  const addDomain = useSSOSettingsStore((state) => state.addDomain);
  const removeDomain = useSSOSettingsStore((state) => state.removeDomain);
  const domainToAdd = useSSOSettingsStore((state) => state.domainToAdd);
  const setDomainToAdd = useSSOSettingsStore((state) => state.setDomainToAdd);

  const handleDomainToAddChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDomainToAdd(event.target.value);
  }, [setDomainToAdd]);

  const handleRemoveDomainClick = useCallback((event: MouseEvent) => {
    const { index } = (event.target as HTMLButtonElement).dataset;

    removeDomain(+(index as string));
  }, [removeDomain]); 

  return (
    <div className='mb-[30px]'>
      <h2 className='font-medium text-subtitle-2 mb-[8px]'>Allowed domains</h2>
        {allowedDomains.length === 0 ? (
          <p>To get started with SSO, add at least one allowed domain</p>
        ) : (
          <ul className='w-2/3'>
            {allowedDomains.map((domain, index) => (
              <li key={domain} className={`flex items-center px-[10px] py-[4px] ${index > 0 ? 'shadow-border-t' : ''}`}>
                <div className='grow'>{domain}</div>
                <IconButton
                  icon='minus'
                  size='sm'
                  type='tertiary'
                  data-index={index}
                  onClick={handleRemoveDomainClick}
                />
              </li> 
            ))}
          </ul>
        )}

        <div className='relative flex items-center mt-[10px] w-2/3'>
          <Input
            type='secondary'
            className='grow mr-[4px]'
            value={domainToAdd}
            placeholder='Add domain'
            onChange={handleDomainToAddChange}
          />
          <IconButton
            icon='plus'
            size='sm'
            type='tertiary'
            className='absolute right-[10px]'
            onClick={addDomain}
            disabled={!isValidDomain(domainToAdd, { allowUnicode: true })}
          />
        </div>
    </div>
  );
}
