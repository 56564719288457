import type { Source } from 'types/unitMetrics';
import type { Setter } from 'types/common';
import type { TeamFilterConstructor, TeamFilterMap } from 'types/teams';
import type { CostViewFilter, CostViewConstructor } from 'types/costViews';

import { useEffect, useState, useMemo } from 'react';
import { Button } from 'shared/Button';
import { Section } from 'shared/Section';
import { PortalRoot } from 'shared/Portal';
import { useGlobalState } from 'state/globalState';
import { CostViewFilters, SourceControl } from 'shared/Filters';
import { TeamFilterSelect } from 'shared/TeamFilterSelect';
import { toImmutableCostViewFilter, parseCostViewFilter, validateCostViewFilter, cleanupCostViewFilter } from 'helpers/costViews';

export interface FiltersBlockProps {
  value: CostViewFilter;
  costViewConstructor: CostViewConstructor;
  onChange: (value: any) => void;
  teamId?: number | null;
  onTeamIdChange?: Setter<number | null> | null;
  teamFilters?: TeamFilterMap;
  teamFilterConstructor?: TeamFilterConstructor;
}

export const FiltersBlock = (props: FiltersBlockProps) => {
  const {
    value,
    costViewConstructor,
    onChange,
    teamId,
    onTeamIdChange,
    teamFilters,
    teamFilterConstructor
  } = props;

  const { logEvent } = useGlobalState();

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  
  const handleApplyClick = () => {
    onChange(cleanupCostViewFilter(currentValue));
    logEvent('apply_filter', { 'view': 'cost_view' })
  }

  const handleCleanClick = () => {
    setCurrentValue(parseCostViewFilter({
      ...currentValue,
      filter: {
        operator: 'or',
        condition: []
      }
    }, costViewConstructor));

    logEvent('clear_filter', { 'view': 'cost_view' })
  }

  const immutableValue = useMemo(() => toImmutableCostViewFilter(value), [value]);
  const immutableCurrentValue = useMemo(() => toImmutableCostViewFilter(currentValue), [currentValue]);


  const empty = currentValue.filter.condition.length === 0;
  const changed = immutableCurrentValue.toString() !== immutableValue.toString();
  const valid = useMemo(() => validateCostViewFilter(currentValue, costViewConstructor), [currentValue]);

  return (
    <Section className='flex flex-col grow'>
      <Section.Head wfull className='justify-between'>
        Filters
        <SourceControl
          value={currentValue.src as Source}
          options={Object.keys(costViewConstructor)}
          onChange={(src) => {
            setCurrentValue(parseCostViewFilter({
              ...currentValue,
              src,
            }, costViewConstructor));
          }}
        />
      </Section.Head>

      {teamId !== undefined && onTeamIdChange && (
        <TeamFilterSelect
          value={teamId}
          onChange={onTeamIdChange}
        />
      )}

      <div className='basis-0 grow shrink overflow-auto px-[20px] pb-[20px] no-scrollbar'>
        <PortalRoot>
          {costViewConstructor && (
            <CostViewFilters
              value={currentValue}
              costViewConstructor={costViewConstructor}
              onChange={setCurrentValue}
              teamFilters={teamFilters}
              teamFilterConstructor={teamFilterConstructor}
            />
          )}
        </PortalRoot>
      </div>
      <div className='h-[60px] flex items-center px-[20px] shadow-border-t'>
        <Button
          className='basis-1/2 justify-center mr-[5px]'
          type='tertiary'
          disabled={empty}
          onClick={handleCleanClick}
          label='Clear All'
        />
        <Button
          className='basis-1/2 justify-center ml-[5px]'
          style='caladon'
          disabled={!changed || !valid}
          onClick={handleApplyClick}
          label='Apply'
        />
      </div>
    </Section>
  );
}
