import type { DataEntry } from 'types/dataEntries';
import type { Source } from 'types/unitMetrics';
import type { CostViewFilter, CostViewRequestFilter } from 'types/costViews';

import { useEffect, useMemo, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import moment from 'moment';

import { DateFilter } from 'shared/DateFilter';
import { CostFilter } from 'shared/CostFilter';
import { getSourceTitle } from 'shared/Filters/constants';
import { Page } from 'shared/Page';
import { IconButton } from 'shared/Button';
import { SaveViewDialog } from 'pages/CostsOverview/SaveViewDialog';
import { DeleteViewDialog } from 'pages/CostsOverview/DeleteViewDialog';
import { EditableTitle } from 'shared/EditableTitle';
import { Breakdown } from './Breakdown/Breakdown';
import { Drilldown } from './Drilldown/Drilldown';
import { Total } from './Total/Total';
import { FilterButton } from 'shared/FilterButton';
import { FiltersBlock } from './FiltersBlock';
import { Loading } from 'shared/Loading';
import { MoversAndShakers } from './MoversAndShakers';

import { parseCostViewFilter, validateCostViewFilter } from 'helpers/costViews';

import { useCostOverviewStore } from './useCostOverviewStore'
import { useDatesQuery } from 'hooks/useDatesQuery';
import { useCostFilterValue } from 'hooks/costFilter';
import { useDrillDownQuery } from 'hooks/useDrillDownQuery';
import { useGlobalState } from 'state/globalState';
import { useCostViewChartStore } from 'store/costViewChart';
import { useCostViewDrillDownStore } from 'store/costViewDrillDown';
import { useEventStore } from 'store/events';
import { useCostViewConstructor } from 'store/costViewConstructor';
import { useTeamId } from 'hooks/teamId';
import { useTeamFilters } from 'hooks/teamFilters';
import { useTeamFilterConstructor } from 'store/teamFilterConstructor';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { Icon } from 'shared/Icon';
import { useSaveViewDialog } from 'store/costLab';
import { useCumulative } from './hooks/cumulative';
import {useBooleanQueryParam} from 'hooks/queryParams';

const CostsOverview = () => {
  const {
    startDate,
    endDate,
    granularity,
    setDatesQuery
  } = useDatesQuery();

  const costFilterValue = useCostFilterValue();

  const {
    breakdown,
    drillDownSteps,
    availableSteps,
    availableBreakdowns,
    setCurrentStep,
    setBreakdown,
    swapStepField,
    swapStepValue,
    removeDrillDown,
    rollbackDrillDown
  } = useDrillDownQuery();

  const {
    filterParam: filter,
    filterChanged,
    setFilter,
    updateFilter,
    updateView,
    isFiltersApplied,
    drillDownOpened,
    deleteView,
    view,
    viewStatus,
    fetchView,
    newViewName,
    setNewViewName,
    setDrillDown,
  } = useCostOverviewStore();

  const [cumulative, setCumulative] = useCumulative();

  const {
    events,
    eventTypes,
    selectedEventTypes,
    fetchEvents,
    fetchEventTypes,
    setEventTypes,
  } = useEventStore();

  const costViewConstructor = useCostViewConstructor();

  useEffect(() => {
    setDrillDown(drillDownSteps);
  }, [drillDownSteps, breakdown]);

  const navigate = useNavigate();
  const { user, collapseSideMenu } = useGlobalState()
  const logEvent = useGlobalState((state) => state.logEvent);
  const [filtersOpened, setFiltersOpened] = useBooleanQueryParam('filtersOpened');
  const [deleteViewDialogOpen, setDeleteViewDialogOpen] = useState(false)

  const openSaveViewDialog = useSaveViewDialog((store) => store.open);

  const params = useParams<{id?: string}>()
  const id = params.id ? +params.id : null;

  const [teamId, onTeamIdChange] = useTeamId();
  const teamFilters = useTeamFilters(teamId);
  const teamFilterConstructor = useTeamFilterConstructor();

  useEffect(() => {
    fetchView(id);
    setNewViewName('')
  }, [id]);

  useEffect(() => {
    if (costViewConstructor.status === 'success' && viewStatus === 'success') {
      if (view) {
        setFilter(
          parseCostViewFilter(view.filter, costViewConstructor.data)
        );
      } else {
        setFilter({
          src: 'cur',
          filter: { operator: 'or', condition: [] }
        });
      }
    }
  }, [costViewConstructor.status, viewStatus]);

  const handleSaveViewClick = async () => {
    try {
      await updateView();
      message.success(`Costs View '${newViewName !== '' ? newViewName : view?.name}' saved'`);
    } catch {
      message.error(`Unable to save '${view?.name}'`);
    }
  }

  const handleFiltersClick = () => {
    setFiltersOpened(!filtersOpened)

    if (!filtersOpened) {
      collapseSideMenu()
    }

    logEvent('open_filters', { 'view': 'cost_view', 'state': !filtersOpened });
  }

  const handleFiltersBlockChange = (value: any) => {
    updateFilter(value);
  }

  const filtersApplied = isFiltersApplied();

  const canSave = (filtersApplied && filterChanged) || (newViewName !== '' && newViewName !== view?.name)

  const filterEntry: DataEntry<CostViewFilter> = useMemo(() => {
    if (viewStatus !== 'success') {
      return { status: viewStatus } as DataEntry;
    }

    if (costViewConstructor.status !== 'success') {
      return costViewConstructor as DataEntry;
    }

    if (!validateCostViewFilter(filter, costViewConstructor.data)) {
      return { status: 'idle' };
    }

    return {
      status: 'success',
      data: filter
    };

  }, [filter, viewStatus, costViewConstructor]);

  const cumulativeGranularity = granularity === 'quarter' ? 'quarter' : 'month'
  const today = useMemo(() => moment(), []);

  const startOfPeriod = useMemo(() => endDate.clone().startOf(cumulativeGranularity), [endDate, cumulativeGranularity]);
  const endOfPeriod = useMemo(() => endDate.clone().endOf(cumulativeGranularity), [endDate, cumulativeGranularity]);

  const startDateAdjusted = cumulative ?
    startOfPeriod :
    startDate;

  const endDateAdjusted = cumulative ?
    endOfPeriod  :
    endDate.isAfter(today) ? today : endDate;

  const granularityAdjusted = cumulative ? 'day' : granularity;

  const prevStartDateAdjusted = cumulative ? 
    startDateAdjusted.clone().subtract(1, 'day').startOf(cumulativeGranularity) :
    startDateAdjusted.clone().subtract(endDateAdjusted.diff(startDateAdjusted, granularity) + 1, granularity)

  const prevEndDateAdjusted = cumulative ? 
    startDateAdjusted.clone().subtract(1, 'day') :
    endDateAdjusted.clone().subtract(endDateAdjusted.diff(startDateAdjusted, granularity) + 1, granularity)

  const startDateStr = startDateAdjusted.format('YYYY-MM-DD');
  const endDateStr = endDateAdjusted.format('YYYY-MM-DD');

  const prevStartDateStr = prevStartDateAdjusted.format('YYYY-MM-DD');
  const prevEndDateStr = prevEndDateAdjusted.format('YYYY-MM-DD');

  useEffect(() => {
    fetchEvents({
      startDate: startDateAdjusted.format('YYYY-MM-DD'),
      endDate: endDateAdjusted.format('YYYY-MM-DD')
    });
    fetchEventTypes({
      startDate: startDateAdjusted.format('YYYY-MM-DD'),
      endDate: endDateAdjusted.format('YYYY-MM-DD')
    });
  }, [startDate, endDate]);

  const requestFilter: DataEntry<CostViewRequestFilter> = useDerivedDataEntry(
    { filterEntry, teamFilters, teamId, onTeamIdChange },
    ({ filterEntry, teamFilters, teamId, onTeamIdChange }) => {
      if (teamId && onTeamIdChange) {
        return {
          src: filter.src,
          filter: {
            operator: 'and',
            condition: [
              teamFilters[filter.src as Source].filter,
              filterEntry.filter 
            ]
          }
        }
      }

      return filterEntry;
    }
  );


  const chartDataEntry = useCostViewChartStore(({ getEntry }) => {
    if (requestFilter.status !== 'success') {
      return requestFilter;
    }

    return getEntry({
      ...costFilterValue,
      startDate: startDateStr,
      endDate: endDateStr,
      granularity: granularityAdjusted,
      filter: requestFilter.data
    });
  });

  const prevChartDataEntry = useCostViewChartStore(({ getEntry }) => {
    if (requestFilter.status !== 'success') {
      return requestFilter;
    }

    return getEntry({
      ...costFilterValue,
      startDate: prevStartDateStr,
      endDate: prevEndDateStr,
      granularity: granularityAdjusted,
      filter: requestFilter.data
    });
  });

  const drillDownEntry = useCostViewDrillDownStore((store) => {
    if (requestFilter.status !== 'success') {
      return requestFilter;
    }

    return store.getEntry({
      ...costFilterValue,
      startDate: startDateStr,
      endDate: endDateStr,
      granularity: granularityAdjusted,
      filter: requestFilter.data,
      breakdown,
      drillDownSteps
    });
  });

  const total = (
    <Total
      unit={filter && ['cur', 'gcp'].includes(filter.src) ? 'cost' : 'unit'}
      viewId={id}
      className='mb-4 grow'
      chartDataEntry={chartDataEntry}
      prevChartDataEntry={prevChartDataEntry}
      startMoment={startDateAdjusted}
      endMoment={endDateAdjusted}
      prevStartMoment={prevStartDateAdjusted}
      prevEndMoment={prevEndDateAdjusted}
      events={events}
      eventTypes={eventTypes}
      selectedEventTypes={selectedEventTypes}
      setEventTypes={setEventTypes}
      granularity={cumulative ? cumulativeGranularity : granularity}
      requestFilter={requestFilter}
    />
  );

  const pageTitle = useMemo(() => {
    if (viewStatus == 'loading') {
      return 'Loading';
    }

    if (id === null) {
      return 'Cost Lab'
    }

    return (
      <div className='flex items-center gap-2'>
        <Link
          to='/costs-overview/library'
          className='text-blue hover:text-blue-hover'
        >
          Cost Library
        </Link>

        <Icon icon='arrow-right' />

        <EditableTitle
          value={newViewName || view?.name || ''}
          placeholder='Enter view name'
          onChange={setNewViewName}
        />
      </div>
    );
  }, [viewStatus, view, newViewName]);

  if (viewStatus === 'error') {
    return <div className='h-[100vh] flex items-center justify-center font-bold text-[128px] text-silver-grey-500'>
      Error
    </div>;
  }

  return (
    <Page className='pb-[60px]'>
      <Page.Head
        title={pageTitle}
        subtitle={filter && `Source: ${getSourceTitle(filter.src)}`}
      >
        <div className='flex items-end mr-auto'>
        {view && (
          <>
            <IconButton
              className="ml-[15px] text-[10px] shrink-0 w-[35px]"
              icon="trash-can"
              geometry="circle"
              hoverStyle="negative"
              type="tertiary"
              size="xs"
              direction="column"
              onClick={() => setDeleteViewDialogOpen(true)}
            >
              Delete
            </IconButton>

            <IconButton
              className="ml-[15px] text-[10px] shrink-0 w-[35px]"
              icon="save" geometry="circle"
              hoverStyle="positive"
              type="tertiary"
              size="sm"
              direction="column"
              disabled={!canSave}
              onClick={handleSaveViewClick}
            >
              Save
            </IconButton>
          </>
        )}

        {user?.org_is_data_ready && (
          <IconButton
            className="ml-[15px] text-[10px] shrink-0 w-[35px]"
            icon="circle-plus"
            geometry="circle"
            hoverStyle="normal"
            type="tertiary"
            size="xs"
            direction="column"
            disabled={!canSave}
            onClick={openSaveViewDialog}
          >
            Save As...
          </IconButton>
        )}
        {view && filter.src === 'cur' && (
          <>     
            <IconButton
              className="ml-[15px] text-[10px] shrink-0 w-[35px]"
              icon="alert"
              geometry="circle"
              hoverStyle="normal"
              type="tertiary"
              size="xs"
              direction="column"
              onClick={() => {
                navigate(`/notification-center?new_view_alert=${id}`, { replace: true });
              }}
            >
              Alert
            </IconButton>

            <IconButton
              className="ml-[15px] text-[10px] shrink-0 w-[35px]"
              icon="notes"
              geometry="circle"
              hoverStyle="normal"
              type="tertiary"
              size="xs"
              direction="column"
              onClick={() => {
                navigate(`/notification-center?new_view_report=${id}`, { replace: true });
              }}
            >
              Report
            </IconButton>
          </>
        )}
        </div>

        <div className='flex items-center justify-end min-w-0 ml-auto gap-4 shrink'>
          {user?.org_is_data_ready && filter.src === 'cur' && <CostFilter />}

          <DateFilter
            withCumulative
            cumulative={cumulative}
            startDate={startDateAdjusted}
            endDate={endDateAdjusted}
            granularity={cumulative ? cumulativeGranularity : granularity}
            onChange={(params) => {
              setDatesQuery(params);

              if (params.granularity !== granularityAdjusted) {
                logEvent('set_granularity', { 'view': 'cost_view', 'granularity': params.granularity });
              }

              if (params.startDate !== startDateAdjusted || params.endDate !== endDateAdjusted) {
                logEvent('set_period', { 'view': 'cost_view' });
              }
            }}
            onCumulativeChange={() => {
              setCumulative(!cumulative);
            }}
          />

          <FilterButton checked={filtersOpened} onClick={handleFiltersClick} />
        </div>
      </Page.Head>

      <div>
        {!filtersOpened && total}

        {filtersOpened && 
          <div
            className={
              `ml-[15px] w-[370px] float-right min-h-[0px] flex flex-col  mb-[15px] ${drillDownOpened ? 'relative ' : 'sticky top-[70px]'}`
            }
            style={{ height: drillDownOpened ? 440 : 'calc(100vh - 80px)' }}
          >
            {(
              costViewConstructor.status === 'success' &&
              teamFilters.status === 'success' &&
              teamFilterConstructor.status === 'success'
            ) ?
              <FiltersBlock
                costViewConstructor={costViewConstructor.data}
                value={filter}
                onChange={handleFiltersBlockChange}
                teamId={teamId}
                onTeamIdChange={onTeamIdChange}
                teamFilters={teamFilters.data}
                teamFilterConstructor={teamFilterConstructor.data}
              /> :
              <Loading />
            }
          </div>
        }

        {(<>
          {filter.src === 'cur' && (filtersOpened || drillDownOpened) && (
            <div className="ml-[15px] mb-[15px] w-[370px] sticky top-[65px] min-h-[1px] float-right clear-right flex flex-col" style={{ maxHeight: 'calc(100vh - 80px)' }}>
              {drillDownOpened && <Drilldown
                className="min-h-0 shrink"
                startDate={startDateStr}
                endDate={endDateStr}
                granularity={granularityAdjusted}
                filter={filter}
                drillDown={drillDownEntry}
                breakdown={breakdown}
                drillDownSteps={drillDownSteps}
                availableSteps={availableSteps}
                availableBreakdowns={availableBreakdowns}
                setCurrentStep={setCurrentStep}
                setBreakdown={setBreakdown}
                swapStepField={swapStepField}
                swapStepValue={swapStepValue}
                removeDrillDown={removeDrillDown}
                rollbackDrillDown={rollbackDrillDown}
              />}
            </div>
          )}

          <div className={filtersOpened || drillDownOpened ? 'mr-[385px] pb-[15px]' : 'pb-[15px]'}>
            {filtersOpened && total}

          {filter.src === 'cur' && <Breakdown
              startDate={startDateStr}
              endDate={endDateStr}
              startMoment={startDateAdjusted}
              endMoment={endDateAdjusted}
              prevStartMoment={prevStartDateAdjusted}
              prevEndMoment={prevEndDateAdjusted}
              granularity={granularityAdjusted}
              breakdown={breakdown}
              drillDown={drillDownEntry}
              drillDownEnabled={drillDownSteps.length > 0}
              availableBreakdowns={availableBreakdowns}
              setBreakdown={setBreakdown}
              setCurrentStep={setCurrentStep}
              requestFilter={requestFilter}
            />}
          </div>
        </>)}
      </div>

      {user?.org_is_data_ready && filter.src === 'cur' && (<>
        <MoversAndShakers
          id={id}
          startDate={startDateStr}
          endDate={endDateStr}
          granularity={granularityAdjusted}
          filter={filter}
          startMoment={startDateAdjusted}
          endMoment={endDateAdjusted}
          prevStartMoment={prevStartDateAdjusted}
          prevEndMoment={prevEndDateAdjusted}
        />
      </>)}

      <SaveViewDialog />

      {view && (
        <DeleteViewDialog
          name={view.name}
          open={deleteViewDialogOpen}
          onClose={() => { setDeleteViewDialogOpen(false); }}
          onConfirm={async () => {
            setDeleteViewDialogOpen(false);
            navigate('/costs-overview');

            try {
              await deleteView();
              message.success(`Costs View '${view!.name}' deleted`);
            } catch {
              message.error(`Unable to delete '${view!.name}`);
            }
          }}
        />
      )}
    </Page>
  )
}

export default CostsOverview
