import type { Granularity } from 'types/common';
import type { Moment } from 'moment';
import type { Dayjs } from 'dayjs';

import moment from 'moment';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import { useGlobalState } from 'state/globalState';

import { Tabs, Tab } from 'shared/Tabs';

dayjs.extend(weekOfYear);

const { RangePicker } = DatePicker;

const GRANULARITY: Granularity[] = [
  'hour',
  'day',
  'week',
  'month',
  'quarter',
  'year'
];

export interface DateFilterProps {
  startDate: Moment;
  endDate: Moment;
  granularity: Granularity;
  withCumulative?: boolean;
  cumulative?: boolean;
  onChange: (params: {
    startDate: Moment;
    endDate: Moment;
    granularity: Granularity;
  }) => void;
  onCumulativeChange?: (cumulative: boolean) => void;
}

const DateFilter = (props: DateFilterProps) => {
  const {
    startDate,
    endDate,
    granularity,
    cumulative,
    withCumulative,
    onChange
  } = props;

  const user = useGlobalState((state) => state.user);

  const [dates, setDates] = useState<[Moment | null, Moment | null]>(() => [startDate, endDate]);
  const [calDates, setCalDates] = useState<[Moment | null, Moment | null]>(() => [startDate, endDate]);

  useEffect(() => {
    if (
      !dates[0] ||
      !dates[1] ||
      !startDate.isSame(dates[0], granularity) ||
      !endDate.isSame(dates[1], granularity)
    ) {
      setDates([startDate, endDate]);
    }
  }, [startDate, endDate, granularity]);

  /*
  useEffect(() => {
    if (cumulative) {
      onChange({
        startDate: endDate.clone().startOf('month'),
        endDate: endDate.clone().endOf('month'),
        granularity: 'day'
      });
    }
  }, [cumulative]);
  */

  const picker = (withCumulative && cumulative) ?
    granularity === 'quarter' ? 'quarter' : 'month' :
    granularity === 'day' || granularity === 'hour' ? undefined : granularity;

  const handleDatesChange = useCallback((value: [Dayjs | null, Dayjs | null] | null) => {
    const dates = value ?
      value.map((date) => date && moment(date.toDate())) :
      [null, null];

    setDates(dates as [Moment | null, Moment | null]);

    let [startDate, endDate] = dates;

    if (startDate && endDate) {
      onChange({
        startDate,
        endDate,
        granularity
      });
    }
  }, [onChange, granularity]);

  const handleGranularityChange = useCallback((granularity: Granularity) => {
    onChange({
      startDate,
      endDate,
      granularity
    });
  }, [startDate, endDate, onChange]);

  const today = useMemo(() => moment().startOf('day'), []);
  const yesterday = useMemo(() => today.clone().add(-1, 'day'), [today]);
  const weekAgo = useMemo(() => today.clone().add(-1, 'week'), [today, yesterday]);
  const monthAgo = useMemo(() => today.clone().add(-1, 'month'), [today, yesterday]);
  const quarterAgo = useMemo(() => today.clone().add(-1, 'quarter'), [today, yesterday]);
  const yearAgo = useMemo(() => yesterday.clone().add(-1, 'year'), [today, yesterday]);

  const minDate = useMemo(() => {
    if (user && !user.org_is_data_ready) {
      return yesterday.clone().add(-6, 'month');
    }

    return undefined;
  }, [yesterday, user]);

  const disabledDate = useCallback(
    (date: Dayjs) => date.isAfter(yesterday.toDate()) || !!minDate && date.isBefore(minDate.toDate()),
    [yesterday, minDate]
  );

  const [hoveredDate, setHoveredDate] = useState<Moment | null>(null);

  const weekDateRender = useCallback((date: Dayjs, _: Dayjs, info: { range: 'start' | 'end' }) => {
    const selected =
      !!calDates[0] && calDates[0].isSame(date.toDate(), 'week') ||
      !!calDates[1] && calDates[1].isSame(date.toDate(), 'week');

    const inRange = !selected &&
      !!calDates[0] && date.isAfter(calDates[0].toDate()) &&
      !!calDates[1] && date.isBefore(calDates[1].toDate());

    const hovered = !!hoveredDate && (
      date.isSame(hoveredDate.toDate(), 'week') ||
      info.range === 'end' && date.isBefore(hoveredDate.toDate()) && !!calDates[0] && date.isAfter(calDates[0].toDate()) ||
      info.range === 'start' && date.isAfter(hoveredDate.toDate()) && !!calDates[1] && date.isBefore(calDates[1].toDate())
    );

    const onMouseOver = () => {
      setHoveredDate(moment(date.toDate()).startOf('week'));
    }

    const onMouseOut = () => {
      setHoveredDate(null);
    }

    const backgroundColor = 
      selected ? '#1890ff' :
      hovered ? '#cbe6ff' :
      inRange ? '#e6f7ff' :
      undefined;

    return (
      <div
        style={{
          position: 'relative' as 'relative',
          zIndex: 2,
          margin: '-2px 0',
          padding: '2px 0',
          color: selected ? 'rgba(255,255,255)' : 'rgba(0,0,0)',
          backgroundColor 
        }}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        {date.get('day') === 0 && !!backgroundColor && (
          <div style={{
            position: 'absolute',
            background: 'inherit',
            top: 0,
            bottom: 0,
            right: '100%',
            left: '-32px',
            padding: 2,
            color: selected ? 'rgba(255,255,255)' : 'rgba(0,0,0,.25)',
          }}>
            {date.get('week' as any)}
          </div>
        )}
        {date.get('date')}
      </div>
    );
  }, [hoveredDate, calDates]);

  const handleCalendarChange = useCallback((values: [Dayjs | null, Dayjs | null] | null) => {
    setCalDates(values ?
      values.map((date) => date && moment(date.toDate())) as [Moment | null, Moment | null]:
      [null, null]
    )
  }, []);

  return (
    <div className='overflow-hidden border rounded border-silver-grey-700/40 px-[4px] w-[220px] shrink-0'>
      <div className='text-caption-2 px-2 mt-[4px] '>
        {cumulative ? 'Cumulative mode' : `Granularity: ${granularity}`}
      </div>

      {cumulative ?
        <DatePicker
          size='small'
          bordered={false}
          className='mt-[-80px] pt-[80px] pr-[24px] mr-[-24px]'
          inputReadOnly
          value={dayjs(endDate.toDate())}
          picker={picker}
          disabledDate={disabledDate}
          onChange={(date) => {
            const startDate = date && moment(date.toDate()).startOf('month');
            const endDate = date && moment(date.toDate()).endOf('month');

            setDates([startDate, endDate]);

            if (startDate && endDate) {
              onChange({
                startDate,
                endDate,
                granularity: 'day'
              });
            }
          }}
          suffixIcon={null}
          renderExtraFooter={() => <>
            <div className='flex items-center px-4 shadow-border-b'>
              <div className='mr-5 font-medium'>Timeframe:</div>
              <Tabs value={granularity} onChange={handleGranularityChange}>
                {['month', 'quarter'].map((granularityOption) => (
                  <Tab key={granularityOption} value={granularityOption}>
                    <span className='capitalize'>{granularityOption}</span>
                  </Tab>
                ))}
              </Tabs>
            </div>
          </>}
        /> :
        <RangePicker
          size='small'
          bordered={false}
          className='mt-[-80px] pt-[80px] pr-[24px] mr-[-24px]'
          inputReadOnly
          ranges={{
            /*
            Yesterday: [yesterday.toDate(), yesterday.toDate()],
            'Last Week': [weekAgo, yesterday],
            'Last Month': [monthAgo, yesterday],
            'Last Quarter': [quarterAgo, yesterday],
            'Last Year': [yearAgo, yesterday]
             */
          }}
          value={dates.map((date) => date && dayjs(date.toDate())) as [Dayjs | null, Dayjs | null]}
          picker={picker}
          disabledDate={disabledDate}
          onChange={handleDatesChange}
          onCalendarChange={handleCalendarChange}
          suffixIcon={null}
          //dateRender={granularity === 'week' ? weekDateRender : undefined}
          renderExtraFooter={() => <>
            <div className='flex items-center px-4 shadow-border-b'>
              <div className='mr-5 font-medium'>Granularity:</div>
              <Tabs value={granularity} onChange={handleGranularityChange}>
                {GRANULARITY.map((granularityOption) => (
                  <Tab key={granularityOption} value={granularityOption}>
                    <span className='capitalize'>{granularityOption}</span>
                  </Tab>
                ))}
              </Tabs>
            </div>
          </>}
        />
      }
    </div>
  )
}
export default DateFilter;
