import type { UnitMetric } from 'types/unitMetrics';
import type { ListEntry } from 'types/dataEntries';
import type { GeneralMember } from 'types/teams';

import create from 'zustand';
import { Map as ImmutableMap } from 'immutable';
import { fetchUnitMetrics } from 'services/unitMetrics';
import {
  fetchUnitMetricMembers,
  addUnitMetric,
  removeUnitMetric,
} from 'services/teams';

type MemberData = GeneralMember;
type TeamUnitMetricEntry = ListEntry<MemberData>;

interface TeamUnitMetricsIdle { status: 'idle' }
interface TeamUnitMetricsLoading { status: 'loading' }
interface TeamUnitMetricsLoadError { status: 'load-error'; error: any; }
interface TeamUnitMetricsLoaded {
  status: 'loaded';
  data: ImmutableMap<number, TeamUnitMetricEntry>;
}

type TeamUnitMetricsEntry = TeamUnitMetricsIdle | TeamUnitMetricsLoading | TeamUnitMetricsLoadError | TeamUnitMetricsLoaded;

type UnitMetricEntry = 
  { status: 'idle' } |
  { status: 'loading' } |
  { status: 'load-error'; error: any } |
  {
    status: 'loaded',
    data: UnitMetric[]
  }

export interface TeamUnitMetricsState {
  allUnitMetrics: UnitMetricEntry;
  entries: ImmutableMap<number, TeamUnitMetricsEntry>;
}

export interface TeamUnitMetricsActions {
  getAllUnitMetrics: () => UnitMetricEntry;
  getEntry: (teamId: number) => TeamUnitMetricsEntry;
  setTeamUnitMetric: (teamId: number, memberId: number, memberEntry: TeamUnitMetricEntry) => void;
  addTeamUnitMetric: (teamId: number, memberId: number, memberData: MemberData) => void;
  confirmAddition: (teamId: number, memberId: number) => void;
  removeTeamUnitMetric: (teamId: number, memberId: number) => void;
  confirmRemoval: (teamId: number, memberId: number) => void;
}

export interface TeamUnitMetricsStore extends TeamUnitMetricsState, TeamUnitMetricsActions {}

const DEFAULT_STATE: TeamUnitMetricsState = {
  allUnitMetrics: { status: 'idle' },
  entries: ImmutableMap()
};

export const useTeamUnitMetricsStore = create<TeamUnitMetricsStore>((set, get) => ({
  ...DEFAULT_STATE,

  getAllUnitMetrics: () => {
    let { allUnitMetrics } = get();

    if (allUnitMetrics.status === 'idle') {
      allUnitMetrics = { status: 'loading' };

      set({ allUnitMetrics });

      fetchUnitMetrics()
        .then(({ data }) => {
          set({
            allUnitMetrics: {
              status: 'loaded',
              data
            }
          });
        })
        .catch((error: any) => {
          set({
            allUnitMetrics: {
              status: 'load-error',
              error
            }
          });
        });
    }

    return allUnitMetrics;
  },

  setTeamUnitMetric: (teamId: number, memberId: number, member: TeamUnitMetricEntry) => {
    const { entries } = get();

    const team = entries.get(teamId);

    if (!team || team.status !== 'loaded') {
      return;
    }

    set({
      entries: entries.set(teamId, {
        ...team,
        data: team.data.set(memberId, member)
      })
    });
  },

  getEntry: (teamId: number) => {
    let { entries } = get();
    
    let members = entries.get(teamId);

    if (members) {
      return members;
    }
    
    members = { status: 'loading' };
    entries = entries.set(teamId, members);
    set({ entries });

    fetchUnitMetricMembers(teamId)
      .then(({ data }) => {
        entries = get().entries;
        
        const memberEntries = data.reduce(
          (map: ImmutableMap<number, TeamUnitMetricEntry>, member) => map.set(member.id, {
            status: 'loaded',
            data: member
          }),
          ImmutableMap() as ImmutableMap<number, TeamUnitMetricEntry>
        );

        entries = entries.set(teamId, {
          status: 'loaded',
          data: memberEntries
        });

        set({ entries });
      })
      .catch((error: any) => {
        entries = get().entries;
        entries.set(teamId, {
          status: 'load-error',
          error
        });
      });

    return members;
  },

  removeTeamUnitMetric(teamId: number, memberId: number) {
    let {
      getEntry,
      setTeamUnitMetric
    } = get();

    let team = getEntry(teamId);

    if (team.status !== 'loaded') {
      return;
    }

    const member = team.data.get(memberId);

    if (!member || member.status !== 'loaded') {
      return;
    }
    
    const deletingMember: TeamUnitMetricEntry = {
      data: member.data,
      status: 'removing'
    };
    
    setTeamUnitMetric(teamId, memberId, deletingMember);

    removeUnitMetric(teamId, memberId)
      .then(() => {
        const deletedMember: TeamUnitMetricEntry = {
          ...deletingMember,
          status: 'removed'
        };

        setTeamUnitMetric(teamId, memberId, deletedMember);
      })
      .catch((error) => {
        const deleteErrorMember: TeamUnitMetricEntry = {
          ...deletingMember,
          status: 'remove-error',
          error
        };

        setTeamUnitMetric(teamId, memberId, deleteErrorMember);
      });
  },

  confirmRemoval: (teamId: number, memberId: number) => {
    let { entries } = get();

    let team = entries.get(teamId);

    if (!team || team.status === 'idle' || team.status === 'loading' || team.status === 'load-error') {
      return;
    }

    team = {
      ...team,
      data: team.data.delete(memberId)
    };

    set({
      entries: entries.set(teamId, team)
    });
  },

  addTeamUnitMetric: (teamId: number, memberId: number, memberData: MemberData) => {
    const {
      entries,
      setTeamUnitMetric
    } = get();

    const team = entries.get(teamId);
    
    if (!team || team.status !== 'loaded') {
      return;
    }

    const oldTeamKey = entries.findKey((team) => {
      if (team.status !== 'loaded') {
        return false;
      }

      return team.data.has(memberId);
    });

    if (oldTeamKey) {
      set({ 
        entries: entries.remove(memberId)
      });
    }

    const addingMember: TeamUnitMetricEntry = {
      status: 'adding',
      data: memberData
    };

    setTeamUnitMetric(teamId, memberId, addingMember);

    addUnitMetric(teamId, memberId)
      .then(() => {
        const addedMember: TeamUnitMetricEntry = {
          status: 'added',
          data: memberData
        };

        setTeamUnitMetric(teamId, memberId, addedMember);
      })
      .catch((error: any) => {
        const addErrorMember: TeamUnitMetricEntry = {
          status: 'add-error',
          error,
          data: memberData
        };

        setTeamUnitMetric(teamId, memberId, addErrorMember);
      });
  },

  confirmAddition: (teamId: number, memberId: number) => {
    let { entries } = get();

    let team = entries.get(teamId);

    if (!team || team.status === 'idle' || team.status === 'loading' || team.status === 'load-error') {
      return;
    }

    let member = team.data.get(memberId);

    if (!member) {
      return;
    }

    team = {
      ...team,
      data: team.data.set(memberId, {
        status: 'loaded',
        data: member.data
      })
    };

    set({
      entries: entries.set(teamId, team)
    });
  }
}));
