import type {
  OpportunitiesParams,
  OpportunitiesStoreKey,
  OpportunitiesStoreKeyProps,
  OpportunitiesStoreKeyFactory
} from 'types/savings';

import { Record as ImmutableRecord } from 'immutable';

import {
  createPageFiltersQueryKey,
  createDedupeBodyKey,
  createPrefilterQueryKey,
  createTableFiltersQueryKey,
  createSortQueryKey,
  pageFiltersQueryToKey,
  dedupeBodyToKey,
  prefilterQueryToKey,
  tableFiltersQueryToKey,
  sortQueryToKey,
  createPagingQueryKey,
  pagingQueryToKey
} from './storeKeys';

import { opportunitiesParamsToRequestParams } from './opportunitiesRequest';

const createOpportunitiesStoreKey: OpportunitiesStoreKeyFactory = ImmutableRecord<OpportunitiesStoreKeyProps>({
  pageFilters: createPageFiltersQueryKey(),
  dedupeBody: createDedupeBodyKey(),
  prefilter: createPrefilterQueryKey(),
  tableFilters: createTableFiltersQueryKey(),
  sort: createSortQueryKey(),
  pagination: createPagingQueryKey()
});

export const opportunitiesParamsToKey = (params: OpportunitiesParams): OpportunitiesStoreKey => {
  const [query, body] = opportunitiesParamsToRequestParams(params);

  const pageFilters = pageFiltersQueryToKey(query);
  const dedupeBody = dedupeBodyToKey(body);
  const prefilter = prefilterQueryToKey(query);
  const tableFilters = tableFiltersQueryToKey(query);
  const sort = sortQueryToKey(query);
  const pagination = pagingQueryToKey(query);

  return createOpportunitiesStoreKey({
    pageFilters,
    dedupeBody,
    prefilter,
    tableFilters,
    sort,
    pagination
  });
}
