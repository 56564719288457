import type { Granularity } from 'types/common';
import type { CostViewFilter } from 'types/costViews/filters';
import type { DrillDownField } from 'types/costViews/drillDown';
import type { Moment } from 'moment';

import { useState } from 'react';

import { InputNumber, Select } from 'antd';
import { Button2 as Button } from 'shared/Button';
import { Section, SectionTitle } from 'shared/Section';
import { Loading } from 'shared/Loading';
import { DateText } from 'shared/DateText'

import { useCostFilterValue } from 'hooks/costFilter';
import { useMoversAndShakers } from 'store/moversAndShakers';
import { formatCurrency, formatPercent } from 'helpers/formatter';
import {FormRow, Label} from 'shared/Form';
import {StatusOverlay} from 'shared/Overlay';

export interface MoversAndShakersProps {
  id: number | null;
  startDate: string;
  endDate: string;
  granularity: Granularity;
  filter: CostViewFilter;
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
}

export const MoversAndShakers = (props: MoversAndShakersProps) => {
  const {
    id,
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment,
    ...rest
  } = props;
  const [request, setRequest] = useState<{
    breakdown: DrillDownField;
    minSpend: number;
    absoluteThreshold: number;
    relativeThreshold: number;
  }>({
    breakdown: 'accounts',
    minSpend: 0,
    absoluteThreshold: 0,
    relativeThreshold: 0
  });
  const [breakdown, setBreakdown] = useState<DrillDownField>('accounts');
  const [minSpend, setMinSpend] = useState(0);
  const [absoluteThreshold, setAbsoluteThreshold] = useState(0);
  const [relativeThreshold, setRelativeThreshold] = useState(0);

  const costFilterValue = useCostFilterValue();

  const moversAndShakers = useMoversAndShakers({
    ...rest,
    ...costFilterValue,
    ...request
  });

  return (
    <Section className='p-5'>
      <SectionTitle>Movers and Shakers</SectionTitle>

      <div className='grid grid-cols-3 gap-10'>
        <div className='pt-4'>
          <FormRow size='s'>
            <Label size='s'>
              Dimension
            </Label>
            <Select
              className='w-1/2'
              value={breakdown}
              onChange={setBreakdown}
              options={[
                { value: 'accounts', label: 'Accounts' },
                { value: 'regions', label: 'Regions' },
                { value: 'services', label: 'Services' }
              ]}
            />
          </FormRow>
          
          <FormRow size='s'>
            <Label size='s'>
              Minimum spend
            </Label>

            <InputNumber
              className='w-1/2'
              addonBefore='$'
              min={0}
              precision={2}
              value={minSpend}
              onChange={(value) => {
                setMinSpend(value || 0);
              }}
            />
          </FormRow>

          <div className='flex my-4'>
            <FormRow size='s'>
              <Label size='s'>
                Absolute threshold
              </Label>

              <InputNumber
                addonBefore='$'
                min={0}
                precision={2}
                value={absoluteThreshold}
                onChange={(value) => {
                  setAbsoluteThreshold(value || 0);
                }}
              />
            </FormRow>
            
            <div className='mx-4 font-medium mt-7'>
              OR
            </div>

            <FormRow size='s'>
              <Label size='s'>
                Relative threshold
              </Label>

              <InputNumber
                addonAfter='%'
                min={0}
                precision={2}
                value={relativeThreshold}
                onChange={(value) => {
                  setRelativeThreshold(value || 0);
                }}
              />
            </FormRow>
          </div>

          <div className='flex justify-end'>
            <Button
              theme='bordered'
              size='m'
              disabled={
                breakdown === request.breakdown &&
                minSpend === request.minSpend &&
                absoluteThreshold === request.absoluteThreshold &&
                relativeThreshold === request.relativeThreshold
              }
              onClick={() => {
                setRequest({
                  breakdown,
                  minSpend,
                  absoluteThreshold,
                  relativeThreshold
                });
              }}
            >
              Apply
            </Button>
          </div>
        </div>

        <div className='relative flex flex-col col-span-2'>
          {moversAndShakers.status === 'success' && (
            <>
              {moversAndShakers.data.data.length ? (
                <div>
                  <div className='mt-5'>
                    <div className='flex'>
                      <DateText
                        description={'Current Period'}
                        startMoment={startMoment}
                        endMoment={endMoment}
                      />
                      <div  className='ml-5'>
                        <DateText
                          description={'Previous Period'}
                          startMoment={prevStartMoment}
                          endMoment={prevEndMoment}
                        />
                      </div>
                    </div>
                  </div>
                  <ul className='mb-auto'>
                    {moversAndShakers.data.data.slice(0, 5).map((item) => (
                      <li className='my-5' key={item[request.breakdown]}>
                        <div className='mb-1 font-medium text-[16px] shadow-border-b pb-2 mb-2'>
                          {moversAndShakers.data.alias_map[item[request.breakdown]] || item[request.breakdown]}
                        </div>
                        <div className='grid grid-cols-3 text-[13px]'>
                          <div>
                            Current period:
                            <div className='text-[13px] font-medium'>
                              {formatCurrency(item.current_timeframe_cost)}
                            </div>
                          </div>
                          <div>
                            Previous period:
                            <div className='text-[13px] font-medium'>
                              {formatCurrency(item.previous_timeframe_cost)}
                            </div>
                          </div>
                          <div>
                            PoP change:
                            <div className='text-[13px] font-medium'>
                              {formatPercent(item.pop_change - 1)} ({formatCurrency(item.pop_diff)})
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className='text-center font-medium text-[32px] my-auto'>No Movers and Shakers</div>
              )}

              <div className='flex justify-end'>
                <Button
                  iconLeft='notes'
                  size='m'
                  to={`/notification-center?new_view_report=${id ? id : -1}&minSpend=${minSpend}&absoluteThreshold=${absoluteThreshold}&relativeThreshold=${relativeThreshold}`}
                >
                  Create Report
                </Button>
              </div>
            </>
          )}

          <StatusOverlay status={moversAndShakers.status} />
        </div>
      </div>
    </Section>
  );
};
