import type { HTMLAttributes } from 'react';

import { useState, useEffect } from 'react';

export interface ScrollPaneProps<TValue extends string = string> extends HTMLAttributes<HTMLDivElement> {
  value: TValue;
  offset?: number;
  onIntersect?: (value: TValue) => void;
}

export const ScrollPane = <TValue extends string = string>(props: ScrollPaneProps<TValue>) => {
  const {
    className = '',
    children,
    value,
    offset = 0,
    onIntersect,
    ...rest
  } = props;

  const [topIntersector, setTopIntersector] = useState<HTMLDivElement | null>(null);
  const [bottomIntersector, setBottomIntersector] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!topIntersector || !bottomIntersector || !onIntersect) {
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        onIntersect(value);
      }
    }, {
      threshold: 1
    });

    observer.observe(topIntersector);
    observer.observe(bottomIntersector);

    return () => {
      observer.disconnect();
    };
  }, [onIntersect, value, topIntersector, bottomIntersector]);

  return (
    <div
      data-value={value}
      className={`relative ${className}`}
      {...rest}
    >
      <div
        ref={setTopIntersector}
        className='absolute w-[1px] pointer-events-none h-[50vh] max-h-[100%]'
        style={{ top: offset }}
      />
      {children}
      <div
        ref={setBottomIntersector}
        className='absolute bottom-0 w-[1px] pointer-events-none h-[50vh] max-h-[100%]'
      />
    </div>
  );
};
