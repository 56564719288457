export interface ClearButtonProps {
  onClick: () => void;
}

export const ClearButton = (props: ClearButtonProps) => {
  const {
    onClick
  } = props;

  return (
    <button
      type='button'
      className='text-[11px] leading-[13px] font-medium text-caladon-blue cursor-pointer ml-auto px-[10px] py-[5px]'
      onClick={onClick}
    >
      Clear Filters
    </button>
  );
};
