import type { Setter } from 'types/common';
import type { ChartType } from 'types/chart';

import { RadioButton, RadioButtonGroup } from 'shared/RadioButtonGroup';

export interface ChartTypeControlProps {
  value: ChartType;
  onChange: Setter<ChartType>;
}
export const ChartTypeControl = (props: ChartTypeControlProps) => {
  const {
    value,
    onChange
  } = props;

  return (
    <RadioButtonGroup
      name='chartType'
      value={value}
      onChange={(_, { value }) => {
        onChange(value);
      }}
    >
      <RadioButton value='line'>Line</RadioButton>
      <RadioButton value='bar'>Bar</RadioButton>
    </RadioButtonGroup>
  );
}
