import type { ReactNode } from 'react';

export interface TextToggleProps<TValue extends string> {
  value: TValue;
  onChange: (value: TValue) => void;
  children: Record<TValue, ReactNode>;
}

export const TextToggle = <TValue extends string>(props: TextToggleProps<TValue>) => {
  const {
    value,
    onChange,
    children
  } = props;

  const handleChange = () => {
    const values = Object.keys(children) as TValue[];
    let index = values.indexOf(value);

    index = (index + 1) % values.length;

    onChange(values[index]);
  };

  return (
    <span className='h-[24px] flex items-center ml-1 px-[8px] rounded bg-silver-grey cursor-pointer' onClick={handleChange}>
      {children[value]} 
    </span>
  );
};
