import type { ReactNode, ChangeEvent } from 'react';

export type RadioButtonTheme = 'default' | 'flat';

export interface RadioButtonProps<TValue extends string = string> {
  name?: string;
  value: TValue;
  theme?: RadioButtonTheme;
  checked?: boolean;
  children?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>, params: { name?: string, value: TValue, checked: boolean }) => void;
}

interface ClassNameParams {
  checked?: boolean;
  theme: RadioButtonTheme;
}

const cn = (params: ClassNameParams) => {
  const {
    checked,
    theme
  } = params;

  const commonCn = `inline-block ${checked ? '' : 'cursor-pointer'}`;
  const sizeCn = 'py-1 px-2 text-caption-1 font-medium';

  let themeCn;

  switch (theme) {
    case 'flat': {
      themeCn = `border ${
        checked ?
          'bg-blue color-white' :
          'border-blue'
      }`;
      break;
    }
    default: {
      themeCn = `rounded-[6px] ${
        checked ?
          'bg-white drop-shadow-sm' :
          'text-gray-700 hover:bg-white/80'
      }`;
    }
  }

  return [
    commonCn,
    sizeCn,
    themeCn
  ].filter(Boolean).join(' ');
}

export const RadioButton = <TValue extends string = string>(props: RadioButtonProps<TValue>) => {
  const {
    name,
    value,
    theme = 'default',
    checked,
    children,
    onChange
  } = props;

  return (
    <label className={cn({ checked, theme })}>
      <input
        className='absolute w-0 h-0 opacity-0'
        type='radio'
        name={name}
        value={value}
        checked={checked}
        onChange={(event) => {
          onChange && onChange(event, {
            name,
            value,
            checked: true
          });
        }}
      />
      {children}
    </label>
  );
};
