import type { DataEntry } from 'types/dataEntries';
import type { DrillDownData } from 'types/tagViews/drillDown';
import type { ChartType } from 'types/chart'


import { useMemo } from 'react';

import { groupBy as arrayGroupBy } from 'helpers/groupBy';
import { formatPercent, granularityTooltip } from 'helpers/formatter';

import { Legend } from 'shared/Legend';
import { Loading } from 'shared/Loading';
import { ExportToCsvButton } from 'shared/ExportToCsvButton';
import { LineChart, BarChart, verticalLinePlugin } from 'shared/Chart';
import { SectionFooter } from 'shared/Section';

import { useGranularity } from 'hooks/useGranularity';
import { useDrillDownQuery } from 'hooks/useDrillDownQuery';

export interface AbsoluteCostsChartProps {
  drillDown: DataEntry<DrillDownData>;
  chartType: ChartType;
  setCurrentStep: (value: string) => void;
}

export const AbsoluteCostsChart = (props: AbsoluteCostsChartProps) => {
  const [granularityParam] = useGranularity();
  const { breakdown: groupBy } = useDrillDownQuery();
  const { drillDown, chartType, setCurrentStep } = props;

  const granularity = granularityParam;

  const getLabel = useMemo(() => 
    drillDown.status === 'success' ? 
      (name: string) => drillDown.data.alias_map[name] || name : 
      (name: string) => name,
    [drillDown]
  );

  const datasets = useMemo(() => {
    if (drillDown.status !== 'success') {
      return [];
    }

    const entries = Object.entries(arrayGroupBy(drillDown.data.data, groupBy));

    return entries.map(([name, data], index) => {
      const hue: number = 100 * index % 360;
      const color = `hsl(${hue}, 50%, 50%)`;
      const transparentColor = `hsla(${hue}, 50%, 50%, 0.25)`;

      return {
        label: getLabel(name),
        data,
        borderColor: color,
        backgroundColor: color,
        pointHoverBorderColor: transparentColor,
        name,
      };
    });
  }, [drillDown, groupBy]);

  const series = useMemo(() => datasets.map(({ label, borderColor }) => ({ name: label, color: borderColor })), [datasets]);
  const sortedDataSets = useMemo(() => datasets.sort((a, b) => b.data[0].current_timeframe_tagged_percent - a.data[0].current_timeframe_tagged_percent), [datasets])
  const sortedIndexMap: {[key: number]: string} = useMemo(() => sortedDataSets.reduce((a, v, index) => ({ ...a, [index]: v.name}), {}), [sortedDataSets]);

  return (
    <div className="relative">
      <div className='relative h-[320px]'>
        {chartType === 'bar' ? (
          <BarChart
            options={{
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (ctx) => `${ctx.dataset.label}: ${formatPercent(ctx.parsed.y)}`
                  },
                  itemSort: (a, b) => (b.parsed as any).y - (a.parsed as any).y
                }
              },
              onClick: (evt, activeElements) => {
                const target_points = activeElements.filter((point) =>
                  (evt.x && evt.y && (
                    (evt.x >= point.element.x - ((point.element as any).width as number) / 2) && 
                    (evt.x < point.element.x + ((point.element as any).width as number) / 2) &&
                    (evt.y < point.element.y + ((point.element as any).height as number)) &&
                    (evt.y >= point.element.y)
                  ))
                )
                if (target_points.length) {
                  setCurrentStep(sortedIndexMap[target_points[0].datasetIndex])
                }                
              },
              scales: {
                y: {
                  stacked: true,
                  ticks: {
                    callback: (value: number | string) => formatPercent(value as number)
                  }
                },
                x: {
                  type: 'time',
                  stacked: true,
                  time: {
                    unit: granularity,
                    displayFormats: {
                      hour: 'hA',
                      day: 'MMM DD',
                      week: 'MMM DD',
                      month: 'MMM YYYY',
                      quarter: 'Qo YYYY',
                      year: 'YYYY'
                    },
                    tooltipFormat: granularityTooltip(granularity),
                  },
                }
              },
              parsing: {
                xAxisKey: 'date',
                yAxisKey: 'current_timeframe_tagged_percent'
              }
            }}
            data={{ datasets: sortedDataSets }}
            plugins={verticalLinePlugin}
          />
        ) : (
          <LineChart
            options={{
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (ctx) => `${ctx.dataset.label}: ${formatPercent(ctx.parsed.y)}`
                  },
                  itemSort: (a, b) => (b.parsed as any).y - (a.parsed as any).y
                }
              },
              onClick: (evt, activeElements) => {
                const target_points = activeElements.filter((point) =>
                  (evt.x && Math.abs(point.element.x - evt.x) <= 20) &&
                  (evt.y && Math.abs(point.element.y - evt.y) <= 20)
                )
                if (target_points.length) {
                  setCurrentStep(sortedIndexMap[target_points[0].datasetIndex])
                }                
              },
              scales: {
                y: {
                  ticks: {
                    callback: (value: number | string) => formatPercent(value as number)
                  }
                },
                x: {
                  type: 'time',
                  time: {
                    unit: granularity,
                    displayFormats: {
                      hour: 'hA',
                      day: 'MMM DD',
                      week: 'MMM DD',
                      month: 'MMM YYYY',
                      quarter: 'Qo YYYY',
                      year: 'YYYY'
                    },
                    tooltipFormat: granularityTooltip(granularity)
                  },
                }
              },
              parsing: {
                xAxisKey: 'date',
                yAxisKey: 'current_timeframe_tagged_percent'
              }
            }}
            data={{ datasets }}
            plugins={verticalLinePlugin}
          />
        )}
      </div>

      {drillDown.status === 'loading' && <Loading />}

      <SectionFooter>
        <div className='overflow-x-scroll no-scrollbar pl-[20px] ml-[-20px] mr-[20px]'>
          <Legend className="mr-[20px]" series={series} />
        </div>
        {drillDown.status === 'success' && <ExportToCsvButton fileName='drilldown_chart.csv' data={drillDown.data.data} />}
      </SectionFooter>
    </div>
  )
};
