import type { FetchStatus, Setter } from 'types/common';
import type { DataEntry } from 'types/dataEntries';
import type {
  UnitMetricFilter,
  UnitMetricConstructor
} from 'types/unitMetrics';
import type {
  TeamFilterConstructor,
  TeamFilterMap
} from 'types/teams';

import { useMemo } from 'react';
import { Button } from 'shared/Button';
import { Loading } from 'shared/Loading';
import { Section } from 'shared/Section';
import { PortalRoot } from 'shared/Portal';
import { UnitMetricFilters } from 'shared/Filters';
import { useGlobalState } from 'state/globalState';
import { TeamFilterSelect } from 'shared/TeamFilterSelect';

export interface SettingsSectionProps {
  status: FetchStatus;
  filter: UnitMetricFilter | null;
  setFilter: (filter: UnitMetricFilter) => void;
  resetFilter: () => void;
  clearFilter: () => void;
  filterHasChanged: boolean;
  unitMetricConstructor: DataEntry<UnitMetricConstructor>;
  teamFilters: DataEntry<TeamFilterMap>;
  teamFilterConstructor: DataEntry<TeamFilterConstructor>;
  teamId: number | null;
  setTeamId: Setter<number | null> | null;
}

export const SettingsSection = (props: SettingsSectionProps) => {
  const logEvent = useGlobalState((state) => state.logEvent);
  const {
    status,
    filter,
    setFilter,
    resetFilter,
    clearFilter,
    filterHasChanged,
    unitMetricConstructor,
    teamId,
    setTeamId,
    teamFilters,
    teamFilterConstructor
  } = props;

  const filterAvailable = !!filter;
  const filterIsEmpty = useMemo(() => !filter/* && isEmptyUnitMetricFilter(filter)*/, [filter]);

  return (
    <Section className='flex flex-col h-full stretch'>
      <div className='font-medium text-subtitle-1 px-[20px] py-[15px] shadow-border-b flex items-center justify-between min-h-[60px]'>
        Settings
      </div>

      {setTeamId && 
        <TeamFilterSelect
          value={teamId}
          onChange={setTeamId}
        />
      }

      <PortalRoot className='relative overflow-y-scroll shrink grow no-scrollbar'>
        {filterAvailable &&
          unitMetricConstructor.status === 'success' &&
          teamFilters.status === 'success' &&
          teamFilterConstructor.status === 'success' &&
        (
          <UnitMetricFilters
            value={filter}
            unitMetricConstructor={unitMetricConstructor.data}
            teamFilters={teamFilters.data}
            teamFilterConstructor={teamFilterConstructor.data}
            onChange={setFilter}
          />
        )}

        {status === 'loading' && <Loading />}
      </PortalRoot>

      {filterAvailable && (
        <div className='flex p-3 pr-[78px] shadow-border-t'>
          <Button
            type='secondary'
            style='oxford'
            disabled={!filterHasChanged}
            onClick={() => {
              logEvent('reset_filter', { view: 'unit_metric', filter_mode: 'advanced' });
              resetFilter();
            }}
            className='mr-3'
            label='Reset'
          />
          <Button
            type='secondary'
            style='oxford'
            disabled={filterIsEmpty}
            onClick={() => {
              logEvent('clear_filter', { view: 'unit_metric', filter_mode: 'advanced' });
              clearFilter();
            }}
            className='mr-3'
            label='Clear'
          />
        </div>
      )}
    </Section>
  );
};
