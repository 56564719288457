import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Section, SectionTitle } from 'shared/Section';
import { DoughnutChart } from 'shared/Chart';
import {
  SimpleTable,
  SimpleTableRow,
  SimpleTableCell,
  SimpleTableHeadCell
} from 'shared/SimpleTable';
import { StatusOverlay } from 'shared/Overlay';
import { formatCurrency } from 'helpers/formatter';
import { ACTIVE_OPPORTUNITY_STATUSES } from 'helpers/savings';
import { useCostImpactSummary } from 'store/savings';
import { usePageFilters } from '../hooks/pageFilters';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { identity } from 'helpers/utils';

export interface CategorySavingsSectionProps {
  className?: string;
}

const COLORS = [
  '#00A6C7',
  '#1E6EC2',
  '#F2A65A',
  '#D65780',
  '#4749BF',
  '#A0E8AF', 
  '#7678ED',
  '#A23E48',
  '#C0DFA1',
  '#333333',
];

export const CategorySavingsSection = (props: CategorySavingsSectionProps) => {
  const {
    className = ''
  } = props;

  const pageFilters = usePageFilters();

  const params = useDerivedDataEntry({
    prefilter: {
      status: ACTIVE_OPPORTUNITY_STATUSES,
    },
    pageFilters,
    tableFilters: {},
    groupBy: 'category' as const,
  }, identity);

  const costImpactSummary = useCostImpactSummary(params);

  const limit = 10;

  const categories = useDerivedDataEntry(
    { costImpactSummary, limit },
    ({ costImpactSummary, limit }) => {
      const categoriesMap = costImpactSummary.reduce((categories, data) => {
        if (!data.group_by) {
          return categories;
        }

        if (!categories[data.group_by]) {
          categories[data.group_by] = {
            key: data.group_by,
            title: data.group_by,
            count: 0,
            savings: 0,
            color: ''
          };
        }

        categories[data.group_by].count += data.opportunities_count;
        categories[data.group_by].savings += data.cost_impact_sum * 30;

        return categories;
      }, {} as {
        [category: string]: {
          key: string;
          title: ReactNode;
          count: number;
          savings: number;
          color: '';
        }
      });

      const all = Array.from(Object.values(categoriesMap)).sort((catA, catB) => catB.savings - catA.savings);

      const top = all.slice(0, limit).map((el, i) => ({ ...el, color: COLORS[i] }));
      const other = all.slice(limit);

      if (other.length > 0) {
        top.push(other.reduce((other, category) => {
          other.count += category.count;
          other.savings += category.savings;

          return other;
        }, {
          key: 'other',
          title: 'Other',
          count: 0,
          savings: 0,
          color: '#D3DCE6'
        }));
      }

      return {
        all,
        top,
        other
      };
    }
  );

  const chartData = useDerivedDataEntry(
    { categories },
    ({ categories }) => ({
      datasets: [{
        data: categories.top.map((cat) => cat.savings),
        backgroundColor: categories.top.map((cat) => cat.color),
        borderAlign: 'inner' as 'inner',
        borderWidth: 0
      }],
      labels: categories.top.map((cat) => cat.title)
    })
  );

  return (
    <Section className={`relative p-5 ${className}`}>
      <SectionTitle className='mb-5'>
        Active Savings / Mo by Category
      </SectionTitle>

      <div className='flex items-start gap-5'>
        <div className='relative w-[400px] h-[400px] -m-5 shrink-0'>
          {chartData.status === 'success' && 
            <DoughnutChart
              width={320}
              height={320}
              options={{
                interaction: {
                  mode: 'point'
                },
                cutout: 100,
                layout: {
                  padding: 40
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: (ctx) => `${ctx.label}: ${formatCurrency(ctx.parsed)}`
                    }
                  }
                }
              }}
              data={chartData.data}
            />
          }
        </div>

        <SimpleTable className='col-span-3'>
          <thead>
            <tr>
              <SimpleTableHeadCell className='text-left'>
                Category
              </SimpleTableHeadCell>

              <SimpleTableHeadCell className='text-left'>
                # of Opportunities
              </SimpleTableHeadCell>

              <SimpleTableHeadCell className='text-left'>
                Active Savings / Mo
              </SimpleTableHeadCell>
            </tr>
          </thead>

          <tbody>
            {categories.status === 'success' && categories.data.top.map((category) => 
              <SimpleTableRow key={category.key}>
                <SimpleTableCell>
                  <Link
                    className='text-blue hover:text-blue-hover'
                    to={`/savings/opportunities?category=${
                      category.key === 'other' ?
                        categories.data.other.map((cat) => cat.key).join(',') :
                        category.key
                    }`}
                  >
                    <span
                      style={{ backgroundColor: category.color }}
                      className='inline-block w-2 h-2 mr-2 rounded'
                    />

                    {category.title}
                  </Link>
                </SimpleTableCell>

                <SimpleTableCell className='text-left'>
                  {category.count}
                </SimpleTableCell>

                <SimpleTableCell className='text-left'>
                  {formatCurrency(category.savings)}
                </SimpleTableCell>
              </SimpleTableRow>
            )}
          </tbody>
        </SimpleTable>
      </div>

      <StatusOverlay status={categories.status} />
    </Section>
  );
};
