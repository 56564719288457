import { useEffect } from 'react';
import { message } from 'antd';
import { IconButton } from 'shared/Button';
import { useTagCatalogsStore } from 'store/tagCatalogs';

export interface DeleteCatalogButtonProps {
  id: number;
}

export const DeleteCatalogButton = (props: DeleteCatalogButtonProps) => {
  const { id } = props;

  const tagCatalog = useTagCatalogsStore((store) => {
    const listEntry = store.getEntries();

    if (listEntry.status !== 'success') {
      return null;
    }

    return listEntry.data.find((catalog) => catalog.source.id === id) || null;
  });

  const deleteTagCatalog = useTagCatalogsStore((store) => store.deleteEntry);
  const confirmDeletionSuccess = useTagCatalogsStore((store) => store.confirmDeletionSuccess);
  const discardDeletionError = useTagCatalogsStore((store) => store.discardDeletionError);

  useEffect(() => {
    if (tagCatalog?.action?.type !== 'delete') {
      return;
    }

    if (tagCatalog.action.status === 'success') {
      message.success(`Tag catalog ${tagCatalog.source.name} deleted`);
      confirmDeletionSuccess(id);
    } else if (tagCatalog.action.status === 'error') {
      message.error(<>
        {`Unable to delete tag catalog '${tagCatalog.source.name}. `}
        <button
          onClick={() => {
            deleteTagCatalog(id);
          }}
        >
          Retry
        </button>
      </>);

      discardDeletionError(id);
    }
  }, [tagCatalog?.action?.status]);

  return (
    <IconButton
      icon='trash'
      size='sm'
      type='tertiary'
      disabled={!tagCatalog || !!tagCatalog.action}
      onClick={() => {
        deleteTagCatalog(id);
      }}
    />
  );
};
