type BtnSocialAuthPropsType = {
  classes: string
  icon: string
  text: string
  onClick: () => void
}

const BtnSocialAuth = ({
  classes = '', icon, text, onClick,
}: BtnSocialAuthPropsType) => (
  <button type="button" className={`${classes} flex justify-center items-center w-full h-[46px] border border-grey-transparent rounded-[10px]`} onClick={onClick}>
    <img className="w-[24px] h-[24px] mr-[10px]" src={icon} alt="Social Icon" />
    <span className="font-medium text-[15px] leading-[22px] tracking-[-0.24px] color-oxford-blue">
      {text}
    </span>
  </button>
)

export default BtnSocialAuth
