import type { ReactNode } from 'react';
import type { CostType, CostDimension } from 'types/costViews';

export const COST_TYPE_LABELS: Record<CostType, ReactNode> = {
  unblended_cost: 'Unblended',
  public_cost: 'Public'
};

export const COST_DIMENSION_LABELS: Record<CostDimension, ReactNode> = {
  Tax: 'Taxes',
  Credit: 'Credits',
  Refund: 'Refunds',
  Discount: 'Discounts',
  Fee: 'Fees',
};
