import { ChangeEvent, useState } from 'react';
import { useCallback } from 'react';

import { Icon } from 'shared/Icon';
import { useGlobalState } from 'state/globalState';

export interface EditableTitleProps {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export const EditableTitle = (props: EditableTitleProps) => {
  const logEvent = useGlobalState((state) => state.logEvent);
  const {
    value,
    placeholder = 'Enter metric name',
    onChange
  } = props;

  const [focused, setFocused] = useState(false)

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, [onChange]);

  return (
    <div className={`relative inline-flex items-start font-medium min-w-0 max-w-[240px] pr-[20px] ${value ? '' : 'text-black/25'}`}>
      <span className='invisible whitespace-pre max-w-[240px]'>{value || placeholder}</span>
      {!focused && <Icon icon='edit' className='absolute top-[2px] right-0 cursor-pointer text-black/25 hover:text-black' />}
      <input
        className={`
          absolute
          top-0
          bottom-0
          left-0
          right-[20px]
          font-medium
          bg-transparent
          outline-none
          mb-[-1px]
          border-b
          border-b-transparent
          hover:border-b-silver-grey-500
          focus:border-b-cyanine-blue
          my-[0px]
          py-[0px]
          text-inherit
          ${focused ? '' : 'text-ellipsis'}
        `}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={() => {
          setFocused(true);
          logEvent('change_metric_name', { view: 'unit_metric', source: 'header' })
        }}
        onBlur={() => {
          setFocused(false);
        }}
      />
    </div>
  );
};
