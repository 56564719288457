import type {
  UnitMetricConstructor,
  UnitMetricConstructorBackendCustom
} from 'types/unitMetrics';
import type { 
  CostViewConstructorBackend,
  CostViewConstructorBackendGCP,
} from 'types/costViews';

import { 
  parseRules,
  parseAccounts,
  parsePayerAccounts,
  parseAccountTags,
  parseRegions,
  parseTags,
  parseServices,
  parseUsageTypes,
  parseOperations,
  parseBillInvoiceIds,
  parseChargeCategories,
  parsePuchaseOptions,
  parseRISPARNs,
  parseServicesHierarchy,
  parseRegionsHierarchy,
  parseK8SHierarchy,
  parseCustomData,
  parseGCP,
  filterEmpty,
} from 'helpers/costViews';

export const parseUnitMetricConstructor = (
  constructorBackend: CostViewConstructorBackend,
  constructorCustom: UnitMetricConstructorBackendCustom,
  constructorGCP?: CostViewConstructorBackendGCP
): UnitMetricConstructor => {
  const accounts = parseAccounts(constructorBackend);
  const regions = parseRegions(constructorBackend);
  const tags = parseTags(constructorBackend);
  const gcp = constructorGCP && parseGCP(constructorGCP);

  const serviceMapExists = Object.keys(constructorBackend.service_filter_map).length > 0
  let curFilters = {}
  if (serviceMapExists) {
    const account_tags = parseAccountTags(constructorBackend);
    const bill_payer_account_ids = parsePayerAccounts(constructorBackend);
    const servicesHierarchy = parseServicesHierarchy(constructorBackend);
    const k8sHierarchy = parseK8SHierarchy(constructorBackend);
    const regionsHiearchy = parseRegionsHierarchy(constructorBackend);
    const bill_invoice_ids = parseBillInvoiceIds(constructorBackend);
    const charge_categories = parseChargeCategories(constructorBackend);
    const purchase_options = parsePuchaseOptions(constructorBackend);
    const ri_sp_arns = parseRISPARNs(constructorBackend);
    curFilters = {
      accounts,
      bill_payer_account_ids,
      account_tags,
      ...regionsHiearchy,
      tags,
      ...k8sHierarchy,
      ...servicesHierarchy,
      bill_invoice_ids,
      charge_categories,
      purchase_options,
      ri_sp_arns
    }
  } else {
    const services = parseServices(constructorBackend);
    const usage_types = parseUsageTypes(constructorBackend);
    const operations = parseOperations(constructorBackend);
    curFilters = {
      accounts,
      regions,
      tags,
      services,
      usage_types,
      operations
    }
  }

  const filter = filterEmpty({
    cur: {
      rules: {
        ...curFilters,
        ...parseRules(constructorBackend)
      }
    },
    custom_data: {
      minItems: 1,
      maxItems: 1,
      rules: {
        ...parseCustomData(constructorCustom),
        ...parseRules(constructorCustom.denominator),
      }
    },
    /*...(gcp ? {
      gcp: {
        rules: gcp
      }
    } : {})*/
  })

  return {
    numerator: filter,
    denominator: filter,
  };
};
