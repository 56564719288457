import type { PartialState } from 'zustand';
import type { DataEntry } from 'types/dataEntries';

import { withKeyDep } from './decorators';

export const createGetDraft = <
  TEntry,
  TDraft,
  TState extends {
    getEntry: (id: number) => DataEntry<TEntry>;
    drafts: { [key: string | number]: DataEntry<TDraft> };
  }
>({
  get,
  emptyDraft,
  parseDraft
}: {
  get: () => TState;
  emptyDraft?: TDraft;
  parseDraft: (entry: TEntry) => TDraft;
}) => withKeyDep((key: string | number) => {
  const { drafts, getEntry } = get(); 

  let draft = drafts[key];

  if (draft) {
    return draft;
  }

  if (typeof key === 'string') {
    if (!emptyDraft) {
      throw new Error('No empty draft provided');
    }

    draft = {
      status: 'success',
      data: emptyDraft
    };
  } else {
    const entry = getEntry(key);

    if (entry.status !== 'success') {
      return entry;
    }

    draft = {
      status: 'success',
      data: parseDraft(entry.data)
    };
  }

  drafts[key] = draft;

  return draft;
});

export const createSetDraft = <
  TDraft,
  TState extends {
    drafts: { [key: string | number]: DataEntry<TDraft> };
  }
>({
  get,
  set
}: {
  get: () => TState;
  set: (state: PartialState<TState>) => void;
}) => (key: string | number, draft: TDraft) => {
  const { drafts } = get(); 

  set({
    drafts: {
      ...drafts,
      [key]: {
        status: 'success',
        data: draft
      }
    }
  } as PartialState<TState>);
};
