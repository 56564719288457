import { useMemo } from 'react';
import moment from 'moment';

import type { Granularity } from 'helpers/formatter';
import type { EventsData, CostViewForecast } from 'types/costViews';
import { formatCurrency, granularityTooltip } from 'helpers/formatter';

import { verticalLinePlugin, LineChart } from 'shared/Chart';

export interface PeriodChartProps<P extends string = 'previous', C extends string = 'current'> {
  currentKey: C;
  previousKey: P;
  granularity: Granularity;
  currentName?: string;
  previousName?: string;
  currentColor?: string;
  previousColor?: string;
  height?: number | string;
  formatter?: (params: { value: number }) => string;
  cumulative?: boolean;
  budget?: number;
  events?: EventsData[];
  forecast?: CostViewForecast;
  setEventsToDisplay?: (targetEvents: EventsData[]) => void;
  data: ({
    date: string
  } & {
    [c in C | P]: number | null;
  })[];
  prevData?: ({
    date: string
  } & {
    [c in C | P]: number | null;
  })[]
}

const defaultFormatter = ({ value }: { value: number }) => formatCurrency(value);

export const PeriodChart = <P extends string = 'previous', C extends string = 'current'>(props: PeriodChartProps<P, C>) => {
  const {
    height = 250,
    data,
    prevData,
    granularity,
    currentKey,
    previousKey,
    budget,
    events,
    setEventsToDisplay,
    formatter = defaultFormatter,
    cumulative = false,
    currentName = 'Current',
    previousName = 'Previous',
    currentColor = '#00b4d8',
    previousColor = '#DA5665',
    forecast
  } = props;

  const currentData: { x: string, y: number | null, forecast?: boolean }[] = useMemo(() => {
    const today = moment().startOf('day');
    const filteredData = data.filter((item) => moment(item.date).isBefore(today));

    if (cumulative) {
      let sum = 0;

      const accumulated = filteredData.map((item) => {
        sum += item[currentKey] ?? 0;

        return {
          y: sum,
          x: item.date
        };
      });

      if (!forecast) {
        return accumulated;
      }

      return [
        ...accumulated,
        ...forecast.map((item) => {
          sum += item.forecast;

          return {
            y: sum,
            x: item.date,
            forecast: true
          };
        })
      ];
    }

    return filteredData.map((item) => ({ y: item[currentKey], x: item.date }))
  }, [data, currentKey, cumulative, forecast]);

  const previousData: { x: string, y: number | null }[] = useMemo(() => {
    if (cumulative) {
      let sum = 0;

      return (prevData || data).map((item) => {
        sum += item[prevData ? currentKey : previousKey] ?? 0;

        return {
          y: sum,
          x: item.date
        };
      });
    }
    return prevData ? 
      prevData.map((item) => ({ y: item[currentKey], x: item.date })) :
      data.map((item) => ({ y: item[previousKey], x: item.date }));
  }, [data, previousKey, cumulative, prevData]);

  const budgetData: { x: string, y: number }[] | null = useMemo(() => {
    if (cumulative && budget) {
      return data.map((item) => ({ y: budget, x: item.date, budget: true }))
    }

    return null;
  }, [cumulative, budget, data]);

  const eventParse = granularity === 'hour' ? 'YYYY-MM-DDTHH:00:00+00:00' : 'YYYY-MM-DDT00:00:00+00:00' 
  const eventsData: any[] = useMemo(() => {
    if (events) {
      return events.map((event) => { 
        return {
          xScaleID: 'xCurrent',
          type: 'line',
          xMin: moment(event.timestamp, eventParse),
          xMax: moment(event.timestamp, eventParse),
          mode: 'vertical',
          borderColor: 'rgba(5, 223, 247, 0.8)',
          borderWidth: 1,
        }
      })
    }
    return []
  }, [events])

  const length = Math.max(data.length, prevData?.length || 0);

  return (
    <div className='relative' style={{ height }}>
      <LineChart
        options={{
          plugins: {
            annotation: {
              annotations: eventsData
            },
            tooltip: {
              callbacks: {
                label: (ctx) => `${ctx.dataset.label}${(ctx.raw as any).forecast ? ' (forecast)' : ''}: ${formatter({ value: ctx.parsed.y })}`,
                footer: (ctx) => {
                  if (events) {
                    const dateEvents = events.filter((event) => moment(event.timestamp, eventParse).unix() === (((ctx[0] as any).parsed as any).x as number) / 1000)
                    if (dateEvents.length) {
                      return [...['Events:'], ...dateEvents.map((event, index) => `${index + 1}. ${event.payload.description}`)]
                    }
                  }
                  return ''
                },
              },
              itemSort: (a, b) => (b.parsed as any).y - (a.parsed as any).y
            }
          },
          onHover: (evt, activeElements) => {
            if (
              events &&
              setEventsToDisplay &&
              activeElements[0]?.element &&
              activeElements[1]?.element
            ) {
              const maxX = Math.max(activeElements[0].element.x, activeElements[1].element.x)
              const minX = Math.min(activeElements[0].element.x, activeElements[1].element.x)
              if (evt.x && evt.x >= minX - 20 && evt.x <= maxX + 20) {
                const dateEvents = events.filter((event) => moment(event.timestamp, eventParse).unix() === ((((activeElements[0].element as any).$context as any).parsed as any).x as number) / 1000)
                setEventsToDisplay(dateEvents)
              } else {
                setEventsToDisplay([])
              }
            }        
          },
          scales: {
            y: {
              ticks: {
                callback: (value: any, ctx) => formatter({ value } as { value: number })
              },
            },
            xCurrent: {
              position: 'bottom',
              type: 'timeseries',
              min: data.length ? data[0].date : undefined,
              max: data.length ? moment(data[0].date).add(length - 1, granularity).format('YYYY-MM-DD') : undefined,
              time: {
                unit: granularity,
                displayFormats: {
                  hour: 'hA',
                  day: 'MMM DD',
                  week: '[W]w YYYY',
                  month: 'MMM YYYY',
                  quarter: 'Qo YYYY',
                  year: 'YYYY'
                },
                tooltipFormat: granularityTooltip(granularity)
              },
              grid: {
                color: '#E0E4E9',
                borderDash: [4, 2]
              },
              ticks: {
                autoSkipPadding: 4,
                maxRotation: 0,
                font: {
                  size: 12,
                }
              }
            },
            xPrevious: {
              display: !!prevData,
              position: 'bottom',
              type: 'timeseries',
              min: prevData?.length ? prevData[0].date : undefined,
              max: prevData?.length ? moment(prevData[0].date).add(length - 1, granularity).format('YYYY-MM-DD') : undefined,
              time: {
                unit: granularity,
                displayFormats: {
                  hour: 'hA',
                  day: 'MMM DD',
                  week: '[W]w YYYY',
                  month: 'MMM YYYY',
                  quarter: 'Qo YYYY',
                  year: 'YYYY'
                },
                tooltipFormat: granularityTooltip(granularity)
              },
              grid: {
                display: false,
                drawBorder: false,
                tickLength: 0
              },
              ticks: {
                padding: 0,
                maxRotation: 0,
                includeBounds: true,
                autoSkipPadding: 10,
                color: 'rgba(0, 0, 0, .4)',
                font: {
                  size: 10,
                  lineHeight: 0.8
                }
              }
            }
          },
          datasets: {
            line: {
              clip: { left: 10, right: 10, top: 10, bottom: 10 }
            }
          }
        }}
        data={{
          datasets: [
            ...(
              budgetData ? [{
                xAxisID: 'xCurrent',
                label: 'Budget',
                data: budgetData,
                borderColor: 'gray',
                backgroundColor: 'gray',
                borderDash: [3, 3]
              }] : []
            ),
            {
              xAxisID: 'xCurrent',
              label: currentName,
              data: currentData,
              borderColor: currentColor,
              backgroundColor: currentColor,
              segment: {
                borderDash: (ctx) => {
                  return (ctx.p1 as any).raw?.forecast ? [4, 3] : undefined;
                }
              }
            },
            {
              xAxisID: prevData ? 'xPrevious' : 'xCurrent',
              label: previousName,
              data: previousData,
              borderColor: previousColor,
              backgroundColor: previousColor,
            },
          ]
        }}
        plugins={verticalLinePlugin}
      />
    </div>
  );
};
