import create from 'zustand'

import type { FetchStatus } from 'types/common';
import type { AlertConfig } from 'types/alerts';
import { createChannels } from 'helpers/channels'

import {
  getAlertConfigs,
  saveAlertConfig,
  editAlertConfig,
  deleteAlertConfig,
} from 'services/alert'
import moment from 'moment';

interface FetchParams {
  force?: boolean;
}

export interface AlertsState {
  status: FetchStatus;
  alertConfigs: AlertConfig[];
}

export interface AlertActions {
  fetchAlertConfigs: (params?: FetchParams) => void;
  saveAlertConfig: (metric_type: string, config: any) => void;
  deleteAlertConfig: (metric_type: string, id: number) => Promise<void>;
  setViewedAlertConfig: (metric_type: string, id: number) => void;
  setIsActiveAlertConfig: (metric_type: string, id: number, is_active: boolean) => void;
}

export interface AlertConfigStore extends AlertsState, AlertActions {}

const DEFAULT_STATE: AlertsState = {
  status: 'idle',
  alertConfigs: [],
};

export const useAlertConfigStore = create<AlertConfigStore>((set, get) => {
  let requestCount = 0;

  return {
    ...DEFAULT_STATE,

    fetchAlertConfigs: async (params = {}) => {
      const { force } = params;
      const { status } = get();

      /*
      if (!force && ['loading', 'success'].includes(status)) {
        return;
      }
      */

      try {
        if (status !== 'success') {
          set({ status: 'loading' });
        }

        const requestNumber = ++requestCount;
        
        const { data: costConfigs } = await getAlertConfigs('cost');
        const costAlertConfigs = costConfigs.map((data: any) => {
          const newAlert: AlertConfig = {
            id: data.id,
            table_id: `cost${data.id}`,
            name: data.name,
            owner: data.owner.name,
            metric_type: 'cost',
            filter_id: data.filter_id,
            alert_type: data.alert_details.compare_to ? 'Relative' : 'Absolute',
            threshold: data.alert_details.threshold,
            period: data.alert_details.period.length,
            cost_column: data.alert_details.cost_column,
            amortization: data.alert_details.amortization,
            exclude: data.alert_details.exclude,
            channels: createChannels(data.alert_details),
            email_recepients: data.alert_details.providers.email.recipients,
            slack_id: data.alert_details.providers.slack.integration_id,
            slack_channels: data.alert_details.providers.slack.recipients,
            is_active: data.is_active,
            last_violated_at: data.last_violated_at,
            last_viewed_at: data.last_viewed_at,
            created_at: data.created_at,
          }
          return newAlert
        })
        const { data: unitConfigs } = await getAlertConfigs('unit');
        const unitAlertConfigs = unitConfigs.map((data: any) => {
          const newAlert: AlertConfig = {
            id: data.id,
            table_id: `unit${data.id}`,
            name: data.name,
            owner: data.owner.name,
            metric_type: 'unit',
            filter_id: data.filter_id,
            alert_type: data.alert_details.compare_to ? 'Relative' : 'Absolute',
            threshold: data.alert_details.threshold,
            period: data.alert_details.period.length,
            cost_column: data.alert_details.cost_column,
            amortization: data.alert_details.amortization,
            exclude: data.alert_details.exclude,
            channels: createChannels(data.alert_details),
            email_recepients: data.alert_details.providers.email.recipients,
            slack_id: data.alert_details.providers.slack.integration_id,
            slack_channels: data.alert_details.providers.slack.recipients,
            is_active: data.is_active,
            last_violated_at: data.last_violated_at,
            last_viewed_at: data.last_viewed_at,
            created_at: data.created_at,
          }
          return newAlert
        })

        if (requestNumber === requestCount) {
          set({
            alertConfigs:[...costAlertConfigs, ...unitAlertConfigs],
            status: 'success'
          });
        }
      } catch(error) {
        set({ status: 'error' });
      }
    },

    saveAlertConfig: async (metric_type: string, config: any) => {
      const { alertConfigs } = get();
      try {
        let data
        if (config.id === -1) {
          ({ data } = await saveAlertConfig(metric_type, config))
        } else {
          ({ data } = await editAlertConfig(metric_type, config, config.id))
        }
        const newAlert: AlertConfig = {
          id: data.id,
          table_id: `${metric_type}${data.id}`,
          name: data.name,
          owner: data.owner.name,
          metric_type,
          filter_id: data.filter_id,
          alert_type: data.alert_details.compare_to ? 'Relative' : 'Absolute',
          threshold: data.alert_details.threshold,
          period: data.alert_details.period.length,
          cost_column: data.alert_details.cost_column,
          amortization: data.alert_details.amortization,
          exclude: data.alert_details.exclude,
          channels: createChannels(data.alert_details),
          email_recepients: data.alert_details.providers.email.recipients,
          slack_id: data.alert_details.providers.slack.integration_id,
          slack_channels: data.alert_details.providers.slack.recipients,
          is_active: data.is_active,
          last_violated_at: data.last_violated_at,
          last_viewed_at: data.last_viewed_at,
          created_at: data.created_at,
        }
        const static_alerts = alertConfigs.filter((alert) => alert.id !== newAlert.id)
        set({ alertConfigs: [newAlert, ...static_alerts] })
      } catch(error) {
        set({ alertConfigs });
        throw error;
      }
    },

    deleteAlertConfig: async (metric_type: string, id: number) => {
      const { alertConfigs } = get();
      set({ alertConfigs: alertConfigs.filter((alert) => alert.id !== id) });

      try {
        await deleteAlertConfig(metric_type, id);
      } catch(error) {
        set({ alertConfigs });
        throw error;
      }
    },

    setViewedAlertConfig: async (metric_type: string, id: number) => {
      const { alertConfigs } = get();
      try {
        const config = {
          'last_viewed_at': moment.now(),
        }
        const { data } = await editAlertConfig(metric_type, config, id)
        const newAlert: AlertConfig = {
          id: data.id,
          table_id: `${metric_type}${data.id}`,
          name: data.name,
          owner: data.owner.name,
          metric_type,
          filter_id: data.filter_id,
          alert_type: data.alert_details.compare_to ? 'Relative' : 'Absolute',
          threshold: data.alert_details.threshold,
          period: data.alert_details.period.length,
          cost_column: data.alert_details.cost_column,
          amortization: data.alert_details.amortization,
          exclude: data.alert_details.exclude,
          channels: createChannels(data.alert_details),
          email_recepients: data.alert_details.providers.email.recipients,
          slack_id: data.alert_details.providers.slack.integration_id,
          slack_channels: data.alert_details.providers.slack.recipients,
          is_active: data.is_active,
          last_violated_at: data.last_violated_at,
          last_viewed_at: data.last_viewed_at,
          created_at: data.created_at,
        }
        const static_alerts = alertConfigs.filter((alert) => alert.id !== newAlert.id)
        set({ alertConfigs: [newAlert, ...static_alerts] })
      } catch(error) {
        set({ alertConfigs });
        throw error;
      }
    },

    setIsActiveAlertConfig: async (metric_type: string, id: number, is_active: boolean) => {
      const { alertConfigs } = get();
      try {
        const config = {
          'is_active': is_active,
        }
        const { data } = await editAlertConfig(metric_type, config, id)
        const newAlert: AlertConfig = {
          id: data.id,
          table_id: `${metric_type}${data.id}`,
          name: data.name,
          owner: data.owner.name,
          metric_type,
          filter_id: data.filter_id,
          alert_type: data.alert_details.compare_to ? 'Relative' : 'Absolute',
          threshold: data.alert_details.threshold,
          period: data.alert_details.period.length,
          cost_column: data.alert_details.cost_column,
          amortization: data.alert_details.amortization,
          exclude: data.alert_details.exclude,
          channels: createChannels(data.alert_details),
          email_recepients: data.alert_details.providers.email.recipients,
          slack_id: data.alert_details.providers.slack.integration_id,
          slack_channels: data.alert_details.providers.slack.recipients,
          is_active: data.is_active,
          last_violated_at: data.last_violated_at,
          last_viewed_at: data.last_viewed_at,
          created_at: data.created_at,
        }
        const static_alerts = alertConfigs.filter((alert) => alert.id !== newAlert.id)
        set({ alertConfigs: [newAlert, ...static_alerts] })
      } catch(error) {
        set({ alertConfigs });
        throw error;
      }
    }
  };
});
