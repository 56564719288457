import type { NumberThresholdConfig } from 'types/dataCollection';

import { InputNumber, Slider } from 'antd'
import { Button } from 'shared/Button';

export interface NumberThresholdEditorProps {
  config: NumberThresholdConfig;
  disabled?: boolean;
  onChange: (config: NumberThresholdConfig) => void;
}

export const NumberThresholdEditor = (props: NumberThresholdEditorProps) => {
  const {
    config,
    onChange
  } = props;

  const {
    title,
    value,
    type,
    min_value: min,
    max_value: max,
    default_value: defaultValue
  } = config;

  const step = type === 'int' ? 1 : (max - min) / 100;

  return (
    <div className='my-[20px]'>
      <h4 className='font-medium mb-[10px]'>
        {title}
      </h4>
      <div className='flex gap-[10px]'>
        <InputNumber
          min={min}
          max={max}
          value={value}
          onChange={(value) => {
            onChange({
              ...config,
              value: value !== null ? value : defaultValue
            });
          }}
        />

        <Slider
          className='grow'
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={(value) => {
            onChange({ ...config, value });
          }}
        />

        <Button
          size='sm'
          type='tertiary'
          icon='undo'
          label='Reset to default'
          onClick={() => {
            onChange({ ...config, value: defaultValue });
          }}
        />
      </div>
    </div>
  );
}
