import { ReactComponent as Arrow } from 'assets/images/ArrowFilled.svg';
import { formatPercent } from 'helpers/formatter';

export interface DiffBadgeProps {
  value: number;
}

export const DiffBadge = (props: DiffBadgeProps) => {
  const { value } = props;

  const color = value > 0 ?
  'text-negative bg-negative/10' :
  'text-positive bg-positive/10';

  return (
    <span className={`flex items-center text-[11px] leading-[18px] font-medium ${color} rounded px-[3px]`}>
      <Arrow className={value < 0 ? 'rotate-180' : undefined} />
      {formatPercent(Math.abs(value))}
    </span>
  );
};
