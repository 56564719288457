import type { DataEntry } from 'types/dataEntries';
import type { Opportunity, OpportunityTypeId } from 'types/savings';
import { isPaywalledOpportunity } from 'types/savings';
import { useMemo, createElement } from 'react';
import { Dummy } from 'pages/savings/Dummy';

import { 
  isTrustedAdvisorOpportunityTypeId,
  isComputeOptimizerOpportunityTypeId,
  isCostExplorerOpportunityTypeId,
} from 'types/savings';

import { formatCurrency } from 'helpers/formatter';
import { createStringsComparator } from 'helpers/sort';
import { Tooltip } from 'antd';

type Sorter = (oppA: Opportunity, oppB: Opportunity) => number;

const decorateSorter = (sorter: Sorter): Sorter => (oppA: Opportunity, oppB: Opportunity) => {
  if (isPaywalledOpportunity(oppA)) {
    return -1;
  }

  if (isPaywalledOpportunity(oppB)) {
    return 1;
  }

  return sorter(oppA, oppB);
}

type Renderer = (opp: Opportunity) => React.ReactNode;

const decorateRenderer = (renderer: Renderer, { minWidth = 20, maxWidth = 80 } : { minWidth?: number, maxWidth?: number } = {}): Renderer =>
  (opp: Opportunity) => isPaywalledOpportunity(opp) ?
    createElement(Dummy, { minWidth, maxWidth, height: 14 }) :
    renderer(opp);

export const renderCostImpact = (opp: Opportunity) => (opp.source === 'custom' || opp.opportunity_details?.cost_impact_calculated) ?
  formatCurrency(opp.cost_impact * 30) :
  'Calculating'
;

export const renderOpportunitySecondaryId = decorateRenderer((opp: Opportunity) => `OP-${opp.secondary_id}`);
export const renderOpportunityDetails = (opp: Opportunity) => JSON.stringify(
  opp.opportunity_details,
  (key, value) => {
    if ([
      'usage_historical',
      'usage_historical_src',
      'cost_historical',
      'cost_historical_src',
      'flagged_resource'
    ].includes(key)) {
      return undefined;
    }

    return value;
  },
  2
);

const renderOpportunitySource = decorateRenderer((opp: Opportunity) => ({
  trustedadvisor: 'Trusted Advisor',
  costexplorer: 'Cost Explorer',
  computeoptimizer: 'Compute Optimizer',
  custom: 'Manual',
  aws: 'AWS'
})[opp.source] || opp.source);

// Account, Service, Region
const pickOpportunityRegion = (opp: Opportunity) => opp.source === 'trustedadvisor' ?
  opp.opportunity_details?.record?.flagged_resource_metadata?.Region :
  opp.opportunity_details?.cost_attributes?.region_name;

const pickOpportunityService = (opp: Opportunity) => opp.opportunity_details?.cost_attributes?.service;

const pickOpportunityAccount = (opp: Opportunity) => opp.source === 'trustedadvisor' ?
  opp.opportunity_details?.record?.flagged_resource_metadata?.Account :
  opp.opportunity_details?.cost_attributes?.aws_account_id;

export const compareOpportunityRegions = decorateSorter(createStringsComparator(pickOpportunityRegion));
export const compareOpportunityServices = decorateSorter(createStringsComparator(pickOpportunityService));
export const compareOpportunityAccounts = decorateSorter(createStringsComparator(pickOpportunityAccount));

const renderOpportunityRegion = decorateRenderer((opp: Opportunity) => pickOpportunityRegion(opp) || 'No Region');
const renderOpportunityService = decorateRenderer((opp: Opportunity) => pickOpportunityService(opp) || 'No Service');
const renderOpportunityAccount = decorateRenderer((opp: Opportunity) => pickOpportunityAccount(opp) || 'No Account');

const createOnFilter = <T,>(pick: (record: T) => string | undefined) => (value: string | number | boolean, record: T) => value === pick(record);

export const onOpportunityRegionFilter = createOnFilter(pickOpportunityRegion);
export const onOpportunityServiceFilter = createOnFilter(pickOpportunityService);
export const onOpportunityAccountFilter = createOnFilter(pickOpportunityAccount);

interface FilterItem {
  text: React.ReactNode;
  value: string;
  children?: FilterItem[];
}

const createUseFilters = <T,>(
  pickValue: (record: T) => string | undefined,
  pickText: (record: T) => React.ReactNode
) => (entry: DataEntry<T[]>): FilterItem[] => useMemo(() => {
  if (entry.status !== 'success') {
    return []
  }

  const valuesEncountered = new Set<string>();

  return entry.data.reduce((filters, record) => {
    if (isPaywalledOpportunity(record as any)) {
      return filters;
    }
    const value = pickValue(record);

    if (value && !valuesEncountered.has(value)) {
      valuesEncountered.add(value);
      filters.push({
        value,
        text: pickText(record)
      });
    }

    return filters;
  }, [] as FilterItem[]);
}, [entry]);

export const useOpportunityRegionFilters = createUseFilters(pickOpportunityRegion, renderOpportunityRegion);
export const useOpportunityServiceFilters = createUseFilters(pickOpportunityService, renderOpportunityService);
export const useOpportunityAccountFilters = createUseFilters(pickOpportunityAccount, renderOpportunityAccount);

// Opportunity types
const OPPORTUNITY_TYPE_TITLES: Record<OpportunityTypeId, string> = {
  '0001_unattached_ebs_volumes': 'Unattached EBS Volumes',
  '0003_standardstorage_ebs_snapshots': 'Standard Storage EBS Snapshots',
  '0004_unused_elastic_ips': 'Unused Elastic IPs',
  '0005_unused_classic_loadbalancers': 'Unused Classic Load Balancers',
  '0006_rightsizing_ec2': 'Compute Optimizer EC2 Rightsizing',
  '0007_rightsizing_ecs': 'Compute Optimizer ECS Rightsizing',
  '0008_rightsizing_lambda': 'Compute Optimizer Lambda Rightsizing',
  '0009_rightsizing_ebs': 'Compute Optimizer EBS Rightsizing',
  '0010_ce_ri_ec2': 'Cost Explorer EC2 Rerserved Instance',
  '0011_ce_ri_rds': 'Cost Explorer RDS Rerserved Instance',
  '0012_ce_ri_redshift': 'Cost Explorer Redshift Rerserved Instance',
  '0013_ce_ri_elasticache': 'Cost Explorer ElastiCache Rerserved Instance',
  '0014_ce_ri_elasticsearch': 'Cost Explorer ElasticSearch Rerserved Instance',
  '0015_ce_ri_opensearch': 'Cost Explorer OpenSearch Rerserved Instance',
  '0016_rds_rightsizing': 'RDS Righsizing',
  '0017_opensearch_rightsizing': 'OpenSearch Righsizing',
  '0018_redis_rightsizing': 'Redis Righsizing',
  '0019_memcached_rightsizing': 'MemCached Righsizing',
  '0020_dynamodb_rightsizing': 'DynamoDB Righsizing',
  '0021_ce_sp_compute': 'Cost Explorer Savings Plan Compute',
  '0022_ce_sp_ec2': 'Cost Explorer Savings Plan EC2',
  '0023_ce_sp_sagemaker': 'Cost Explorer Savings Plan SageMaker',
  '0024_rightsizing_asg': 'Compute Optimizer ASG Rightsizing',
  '0025_natgw': 'VPC Endpoints instead of NAT Gateways for S3 and DynamoDB',
  '0100_unused_cw_alarms_ec2': 'Unused EC2 CloudWatch Alarms',
  '0101_unused_cw_alarms_rds': 'Unused RDS CloudWatch Alarms',
  '0102_unused_cw_dashboards': 'Excess CloudWatch Dashboards',
  '0103_cw_log_retention': 'Excess CloudWatch Log Retention',
  '0104_stopped_instance_ebs_volumes': 'EBS Volumes Attached to Stopped Instances',
  '0105_unused_machine_images': 'Unused Machine Images',
  '0106_old_machine_images': 'Old Machine Images',
  '0107_old_archivestorage_ebs_snapshots': 'Old Archived Storage EBS Snapshots',
  '0108_efs_lifecycle_policy': 'Missing EFS Intelligent Tiering',
  '0109_s3_intelligent_tiering': 'Missing S3 Intelligent Tiering',
  '0110_idle_nat_gateways': 'Idle NAT Gateways',
  '0111_auroa_instances_to_graviton': 'Graviton-eligible RDS Instances',
  '0112_ddb_infrequent_access': 'DynamoDB Standard / Infrequent Access Switch',
  '0113_opensearch_instances_to_graviton': 'Graviton-eligible OpenSearch Instances',
  '0114_idle_vpc_endpoints': 'Idle VPC Endpoints',
  '0115_duplicate_cloudtrail': 'Duplicate CloudTrail Trails',
}

const renderOpportunityTypeIdString = (opportunity: Opportunity) =>
  (isTrustedAdvisorOpportunityTypeId(opportunity.opportunity_type_id) ? 
    opportunity.opportunity_details?.record?.check_name :
    OPPORTUNITY_TYPE_TITLES[opportunity.opportunity_type_id]
  ) || opportunity.opportunity_type_id;

const renderOpportunityTypeId = decorateRenderer((opp: Opportunity) => {
  const text = renderOpportunityTypeIdString(opp);

  return <Tooltip
    title={
      <>
        <b>{renderOpportunityService(opp)}</b>
        <p>{opp.opportunity_details?.opportunity_description}</p>
      </>
    }
  >
    {text}
  </Tooltip>
});

export const pickMetaCategory = (opportunity: Opportunity) => opportunity.source === 'custom' ?
    'manual' :
    opportunity.opportunity_details?.meta_category;

export const renderMetaCategory = decorateRenderer((opportunity: Opportunity) => {
  const category = pickMetaCategory(opportunity);

  if (category) {
    return category[0].toUpperCase() + category.substring(1);
  }

  return '';
});

export const compareOpportunityTypeId = decorateSorter(createStringsComparator(renderOpportunityTypeIdString));

export const useOpportunityTypeIdFilters = (opportunities: DataEntry<Opportunity[]>) => useMemo(() => {
  if (opportunities.status !== 'success') {
    return [];
  }

  const typesEncountered = new Set<OpportunityTypeId>();

  const filters: FilterItem[] = [];
  const trustedAdvisorFilters: FilterItem[] = [];
  const computeOptimizerFilters: FilterItem[] = [];
  const costExplorerFilters: FilterItem[] = [];

  opportunities.data.forEach((opportunity) => {
    const typeId = opportunity.opportunity_type_id;

    if (!typesEncountered.has(typeId)) {
      typesEncountered.add(typeId);

      const item = {
        value: typeId,
        text: renderOpportunityTypeId(opportunity)
      };

      if (isTrustedAdvisorOpportunityTypeId(typeId)) {
        trustedAdvisorFilters.push(item);
      } else if (isComputeOptimizerOpportunityTypeId(typeId)) {
        computeOptimizerFilters.push(item);
      } else if (isCostExplorerOpportunityTypeId(typeId)) {
        costExplorerFilters.push(item);
      } else {
        filters.push(item);
      }
    }
  });

  if (trustedAdvisorFilters.length) {
    filters.push({
      text: 'Trusted Advisor',
      value: '0002',
      children: trustedAdvisorFilters
    });
  }
  if (computeOptimizerFilters.length) {
    filters.push({
      text: 'Compute Optimizer',
      value: '0006_0009',
      children: computeOptimizerFilters
    });
  }
  if (costExplorerFilters.length) {
    filters.push({
      text: 'Cost Explorer',
      value: '0010_0015',
      children: costExplorerFilters
    });
  }

  return filters;
}, [opportunities]);

export const useOpportunityMetaCategoryFilters = (opportunities: DataEntry<Opportunity[]>) => useMemo(() => {
  if (opportunities.status !== 'success') {
    return [];
  }

  const typesEncountered = new Set<string>();

  const filters: FilterItem[] = [];

  opportunities.data.forEach((opportunity) => {
    const category = pickMetaCategory(opportunity);

    if (category && !typesEncountered.has(category)) {
      typesEncountered.add(category);

      const item = {
        value: category,
        text: renderMetaCategory(opportunity)
      };

      filters.push(item);
    }
  });

  return filters;
}, [opportunities]);


export const onOpportunityTypeIdFilter = createOnFilter((opportunity: Opportunity) => opportunity.opportunity_type_id);
export const onOpportunityMetaCategoryFilter = createOnFilter(pickMetaCategory);
export const compareOpportunityTypeIds = decorateSorter(createStringsComparator(renderOpportunityTypeIdString));

const pickOpportunityDifficulty = (opportunity: Opportunity) => {
  const { difficulty } = opportunity.opportunity_details || {};

  if (!difficulty || difficulty === 'unknown') {
    return 'easy';
  }

  return difficulty;
}

const DIFFICULTY_ORDER = {
  easy: 0,
  medium: 1,
  hard: 2,
  unknown: 3
}

export const compareOpportunityDifficulty = decorateSorter(
  (oppA: Opportunity, oppB: Opportunity) =>
    DIFFICULTY_ORDER[pickOpportunityDifficulty(oppA)] - DIFFICULTY_ORDER[pickOpportunityDifficulty(oppB)]
);

export const onOpportunityDifficultyFilter = createOnFilter(pickOpportunityDifficulty);
export const opportunityDifficultyFilter = [
  {
    text: 'Easy',
    value: 'easy',
  },
  {
    text: 'Medium',
    value: 'medium',
  },
  {
    text: 'Hard',
    value: 'hard',
  }
];

const pickOpportunityTags = (opportunity: Opportunity) => (
  opportunity.opportunity_details?.cost_attributes?.tags ||
  opportunity.opportunity_details?.cost_attributes?.tags_historical ||
  []
).filter(({ value }) => !!value);

export const useOpportunityTagsFilters = (entry: DataEntry<Opportunity[]>) => {
  return useMemo(() => {
    if (entry.status !== 'success') {
      return [];
    }

    const result: FilterItem[] = [];

    entry.data.forEach((opportunity) => {
      pickOpportunityTags(opportunity).forEach((tag) => {
        const { key, value } = tag;

        let keyNode = result.find((filterItem) => filterItem.value === key);

        if (!keyNode) {
          keyNode = {
            text: key,
            value: key,
            children: []
          };
          result.push(keyNode);
        }

        let valueNode = keyNode.children!.find((filterItem) => filterItem.value === `${key}:${value}`);

        if (!valueNode) {
          valueNode = {
            text: value || 'No value',
            value: `${key}:${value}`
          };
          keyNode.children!.push(valueNode);
        }
      });
    });

    return result;
  }, [entry]);
}

export const onOpportunityTagsFilter = (keyValue: any, opportunity: Opportunity) => {
  const tags = pickOpportunityTags(opportunity);

  return tags.some(({ key, value }) => `${key}:${value}` === keyValue);
};

export const useOpportunityCostImpactFilters = (entry: DataEntry<Opportunity[]>) => useMemo(() => {
  if (entry.status !== 'success') {
    return { min: 0, max: 0 };
  }

  return {
    min: 0,
    max: entry.data.reduce((max, opportunity) => Math.max(max, opportunity.cost_impact * 30), 0)
  };
}, [entry]);
