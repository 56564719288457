import type { DataEntry } from 'types/dataEntries';
import { CostViewForecast, CostViewForecastFetchParams, ForecastSettings, ForecastWithSettings } from 'types/costViews';

import moment from 'moment';
import { useEffect, useState } from 'react';
import { mapDates } from 'helpers/date';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useCostViewForecastStore } from 'store/costViewForecast';

export const useForecast = (params: CostViewForecastFetchParams | DataEntry<CostViewForecastFetchParams>): DataEntry<ForecastWithSettings> => {
  const forecast = useCostViewForecastStore((store) => store.getEntry(params));
  const [settings, setSettings] = useState<ForecastSettings>(() => ({
    fixed: false,
    fixedMode: 'last',
    increasePercentage: 0,
    increaseDate: moment()
  }));


  useEffect(() => {
    if (forecast.status === 'success') {
      setSettings({
        fixed: false,
        fixedMode: 'last',
        increasePercentage: 0,
        increaseDate: moment()
      })
    }
  }, [forecast]);

  return useDerivedDataEntry({
    params,
    forecast,
    settings
  }, ({
    params,
    forecast,
    settings
  }) => {
    let data: CostViewForecast;
    const lastCost = forecast.raw_data[forecast.raw_data.length - 1].current_timeframe_cost || 0;
    const avgCost = forecast.raw_data.reduce((sum, { current_timeframe_cost }) => sum + (current_timeframe_cost || 0), 0) / forecast.raw_data.length

    let baseCost = lastCost;

    if (settings.fixed) {
      if (settings.fixedMode === 'avg') {
        baseCost = avgCost;
      }
      data = mapDates({
        start: moment(params.forecastStartDate),
        end: moment(params.forecastEndDate),
        callback: (date) => ({
          date: date.format('YYYY-MM-DD'),
          forecast: date.isBefore(settings.increaseDate) ?
              baseCost:
              baseCost * (1 + settings.increasePercentage / 100)
        })
      });
    } else if (forecast.slope > 0) {
      data = forecast.data;
    } else {
      data = mapDates({
        start: moment(params.forecastStartDate),
        end: moment(params.forecastEndDate),
        callback: (date) => ({
          date: date.format('YYYY-MM-DD'),
          forecast: baseCost
        })
      })
    }

    return {
      ...forecast,
      data,
      settings,
      setSettings,
      lastCost,
      avgCost
    };
  });
}
