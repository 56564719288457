import type { BillingPlan } from 'types/billing';

import moment from 'moment';
import { P, A } from './Typography';
import { formatNormal } from 'helpers/formatter';

export interface PlanTextProps {
  totalSpend?: number | null;
  startDate?: string | null;
  endDate?: string | null;
  plan?: BillingPlan | null;
}

export const PlanText = (props: PlanTextProps) => {
  const {
    totalSpend,
    startDate,
    endDate,
    plan
  } = props;

  return <>
    {(
      totalSpend &&
      startDate &&
      endDate &&
      plan
    ) && (
      <P>
        Based on your cloud spend of <b>${formatNormal(totalSpend)}</b> per month
        calculated <b>{moment(startDate).format('YYYY/MM/DD')}</b>{' '}
        to <b>{moment(endDate).format('YYYY/MM/DD')}</b>{' '}
        your organization is in the <b>{plan.name.toUpperCase()}</b> Tier.
      </P>
    )}

    {plan && (
      <P>
        Based on our <A href='https://www.cloudthread.io/pricing' target="_blank"><b>pricing tiers</b></A>, unrestricted use of Cloudthread will be <b>${formatNormal(plan.price / 100)}</b> per month.
      </P>
    )}
  </>;
}
