import type { ReactNode } from 'react';
import type { Setter } from 'types/common';
import type {
  DataFilterConfig,
  ListDataFilterValue,
  ListDataFilterOptions,
  NumberRangeDataFilterValue,
  NumberRangeDataFilterOptions,
  GetDataFilterValueType,
  GetDataFilterOptionsType
} from 'types/dataFilters';

import { useState } from 'react';
import { Popover } from 'antd';
import { DataFilterSelectButton } from './DataFilterSelectButton';
import { isEmptyDataFilterValue } from 'helpers/dataFilters';
import { isNumberRangeDataFilterConfig, isListDataFilterConfig } from 'types/dataFilters';
import { ListDataFilterDropdown } from './ListDataFilterDropdown';
import { RangeDataFilterDropdown } from './RangeDataFilterDropdown';

export interface DataFilterSelectProps<TConfig extends DataFilterConfig> {
  config: TConfig;
  value: GetDataFilterValueType<TConfig>;
  options: GetDataFilterOptionsType<TConfig>;
  onChange: Setter<GetDataFilterValueType<TConfig>>;
}

export const DataFilterSelect = <TConfig extends DataFilterConfig>(props: DataFilterSelectProps<TConfig>) => {
  const {
    value,
    config,
    options,
    onChange
  } = props;

  const [opened, setOpened] = useState(false);

  let dropdown: ReactNode = null;

  if (isNumberRangeDataFilterConfig(config)) {
    dropdown =
      <RangeDataFilterDropdown
        value={value as NumberRangeDataFilterValue}
        opened={opened}
        config={config}
        options={options as NumberRangeDataFilterOptions}
        onChange={(value: NumberRangeDataFilterValue) => {
          onChange(value as GetDataFilterValueType<TConfig>);
          setOpened(false);
        }}
      />;
  }

  if (isListDataFilterConfig(config)) {
    if ((options as ListDataFilterOptions).length <= 1) {
      return null;
    }

    dropdown = 
      <ListDataFilterDropdown
        value={value as ListDataFilterValue}
        opened={opened}
        config={config}
        options={options as ListDataFilterOptions}
        onChange={(value: ListDataFilterValue) => {
          onChange(value as GetDataFilterValueType<TConfig>);
          setOpened(false);
        }}
      />;
  }

  return (
    <Popover
      content={dropdown}
      open={opened}
      trigger='click'
      placement='bottom'
      onOpenChange={setOpened}
    >
      <DataFilterSelectButton
        empty={isEmptyDataFilterValue(value)}
        opened={opened}
      >
        {config.title}
      </DataFilterSelectButton>
    </Popover>
  );
};
