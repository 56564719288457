import type {
  DrillDownField,
  DrillDownStep
} from 'types/costViews/drillDown';
import { useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GROUPBY, getDefaultGroupByFor } from 'pages/CostsOverview/groupByOptions';

const drillDownOrder: DrillDownField[] = GROUPBY.map(({ key }) => key as DrillDownField);
const drillDownQueryKeys = new Set(drillDownOrder);
const isDrillDownField = (key: any): key is DrillDownField => drillDownQueryKeys.has(key as DrillDownField);

interface UseDrilldownQueryResult {
  drillDownSteps: DrillDownStep[];
  breakdown: DrillDownField;
  availableSteps: DrillDownField[][];
  availableBreakdowns: DrillDownField[];
  setBreakdown: (groupBy: DrillDownField) => void;
  setCurrentStep: (stepValue: string) => void;
  swapStepValue: (stepField: DrillDownField, stepValue: string) => void;
  swapStepField: (oldField: DrillDownField, newField: DrillDownField) => void;
  removeDrillDown: () => void;
  rollbackDrillDown: () => void;
}

const getNextBreakdown = (filterBy: DrillDownStep[]): DrillDownField => {
  const filterBySet = new Set(filterBy.map(([key]) => key));
  const [lastStepKey] = filterBy[filterBy.length - 1];

  if (filterBy.length) {
    const defaultNextStep = getDefaultGroupByFor(lastStepKey);

    if (defaultNextStep && !filterBySet.has(defaultNextStep)) {
      return defaultNextStep;
    }
  }

  return drillDownOrder.find((groupBy) => !filterBySet.has(groupBy)) || lastStepKey;
};

export const useDrillDownQuery = (): UseDrilldownQueryResult => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const {
    breakdown,
    drillDownSteps,
    availableSteps,
    availableBreakdowns
  } = useMemo(
    () => {
      let breakdown: DrillDownField = 'accounts';
      const drillDownSteps: DrillDownStep[] = [];
      const availableSteps: DrillDownField[][] = [];

      const filledSteps = new Set<DrillDownField>();

      Array.from(searchParams.entries()).forEach((entry) => {
        const [key, value] = entry; 

        if (key === 'breakdown') {
          if (isDrillDownField(value)) {
            breakdown = value;
          }
        } else if (isDrillDownField(key)) {
          drillDownSteps.push(entry as DrillDownStep);
          availableSteps.push(drillDownOrder.filter((field) => !filledSteps.has(field)));
          filledSteps.add(key);
        }
      });

      if (filledSteps.has(breakdown)) {
        breakdown = getNextBreakdown(drillDownSteps);
      }

      const availableBreakdowns = drillDownOrder.filter((field) => !filledSteps.has(field));

      return {
        breakdown,
        drillDownSteps,
        availableSteps,
        availableBreakdowns
      };
    },
    [searchParams]
  );

  const setBreakdown = useCallback((newBreakdown: DrillDownField) => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (newBreakdown !== breakdown) {
      newSearchParams.set('breakdown', newBreakdown);
      setSearchParams(newSearchParams);
    };
  }, [searchParams]);

  const setCurrentStep = useCallback((stepValue: string) => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set(breakdown, stepValue);
    newSearchParams.delete('breakdown');

    setSearchParams(newSearchParams);
  }, [searchParams]);

  const swapStepValue = useCallback((stepField: DrillDownField, stepValue: string) => {
    const newSearchParams = new URLSearchParams(searchParams);

    let stepFound = false;

    drillDownSteps.forEach(([key]) => {
      if (stepFound) {
        newSearchParams.delete(key);
      } else if (key === stepField) {
        stepFound = true;
      }
    });

    newSearchParams.set(stepField, stepValue);
    newSearchParams.delete('breakdown');
    
    setSearchParams(newSearchParams);
  }, [searchParams]);

  const swapStepField = useCallback((oldField: DrillDownField, newField: DrillDownField) => {
    const newSearchParams = new URLSearchParams(searchParams);

    let stepFound = false;

    drillDownSteps.forEach(([key]) => {
      if (stepFound || key === oldField) {
        newSearchParams.delete(key);
        stepFound = true;
      }
    });

    newSearchParams.set('breakdown', newField);
    
    setSearchParams(newSearchParams);
  }, [searchParams]);


  const rollbackDrillDown = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);

    const [key] = drillDownSteps[drillDownSteps.length - 1];

    newSearchParams.delete(key);
    newSearchParams.set('breakdown', key);

    setSearchParams(newSearchParams);
  }, [searchParams]);

  const removeDrillDown = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.delete('breakdown');
    drillDownSteps.forEach(([key]) => {
      newSearchParams.delete(key);
    });

    setSearchParams(newSearchParams);
  }, [searchParams]);
  
  return {
    breakdown,
    drillDownSteps,
    availableSteps,
    availableBreakdowns,
    setBreakdown,
    setCurrentStep,
    swapStepField,
    swapStepValue,
    removeDrillDown,
    rollbackDrillDown
  };
};
