import type { RuleRoot, RuleTag } from 'types/rules';

import moment from 'moment';
import dayjs from 'dayjs';
import { useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker } from 'antd';
import { Page } from 'shared/Page';
import { Button } from 'shared/Button';
import { Section } from 'shared/Section';
import { Loading } from 'shared/Loading';
import { EditableTitle } from 'shared/EditableTitle';
import { SourceControl } from 'shared/Filters';
import { RuleTreeEditor } from './RuleTreeEditor';
import { RuleActionButtons } from './RuleActionButtons';
import { TagsSummary } from './TagsSummary';
import { DeleteDialog } from './DeleteDialog';
import { SaveAsNewDialog } from './SaveAsNewDialog';
import { ConfirmSrcChangeDialog } from './ConfirmSrcChangeDialog';
import { CostFilter } from 'shared/CostFilter';

import { getChildTags, serializeRuleDraft } from 'helpers/rules';

import { useRuleDraft } from 'store/rules';
import { useCostViewConstructor } from 'store/costViewConstructor';
import { useDatesQuery } from 'hooks/useDatesQuery';
import {Source} from 'types/unitMetrics';

export const RulePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const key = id ? +id : 'new';

  const [draft, setDraft] = useRuleDraft(key);
  const costViewConstructor = useCostViewConstructor();

  const [nextSrc, setNextSrc] = useState<string | null>(null);

  const [tagsShown, setTagsShown] = useState(false);

  const tags: { [key: string]: string[] } = draft.status === 'success' ? getChildTags(draft.data.root) : {};

  const { startDate, setDatesQuery } = useDatesQuery();

  const tagFilters = useMemo(() => {
    if (draft.status !== 'success') {
      return {};
    }

    const draftBackend = serializeRuleDraft(draft.data);

    const tagFilters: { [key: string]: { [value: string]: RuleTag }} = {};

    draftBackend.tags.forEach((tag) => {
      const { key, value } = tag;

      if (!tagFilters[key]) {
        tagFilters[key] = {};
      }

      tagFilters[key][value] = tag;
    }, {} as Record<string, Record<string, RuleTag>>);

    return tagFilters;
  }, [draft]);

  if (costViewConstructor.status === 'idle' || draft.status === 'idle') {
    return (
      <Page className='relative'>
        <div />
      </Page>
    );
  }

  if (costViewConstructor.status === 'error' || draft.status === 'error') {
    return (
      <Page className='relative'>
        <div>
          Error
        </div>
      </Page>
    );
  }

  if (costViewConstructor.status === 'loading' || draft.status === 'loading') {
    return (
      <Page className='relative'>
        <div className='relative mt-[20%]'>
          <Loading />
        </div>
      </Page>
    );
  }


  return <Page>
    <Page.Head>
      <EditableTitle
        value={draft.data.name}
        placeholder='Enter rule name'
        onChange={(name) => {
          setDraft({
            ...draft.data,
            name
          });
        }}
      />

      <RuleActionButtons id={key} />

      <div className='flex items-center ml-auto'>
        <CostFilter />

        <div className='overflow-hidden border rounded border-silver-grey-700/40 px-[4px] w-[220px] shrink-0'>
          <div className='text-[10px] px-2 mt-[4px] mb-[-2px]'>
            Month
          </div>

          <DatePicker
            size='small'
            bordered={false}
            className='mt-[-80px] pt-[80px] pr-[24px] mr-[-24px]'
            inputReadOnly
            suffixIcon={null}
            value={dayjs(startDate.toDate())}
            picker='month'
            disabledDate={(date) => date.isAfter(dayjs())}
            onChange={(value) => {
              if (value) {
                setDatesQuery({
                  startDate: moment(value.toDate()),
                  endDate: moment(value.toDate()),
                  granularity: 'month'
                });
              }
            }}
          />
        </div>

        <Button
          size='md'
          style='oxford'
          type='secondary'
          className={`h-[43px] ml-[10px] ${tagsShown ? '' : 'border-transparent'}`}
          icon='tag'
          label='Tags Summary'
          onClick={() => {
            setTagsShown((tagsShown) => !tagsShown);
          }}
        />
      </div>
    </Page.Head>

    <Section className='px-[10px] py-[5px] inline-flex border border-gray-300 rounded-[10px!important]'>
      <SourceControl
        value={draft.data.src as Source}
        options={Object.keys(costViewConstructor.data)}
        onChange={(src) => {
          if (draft.data.root.children?.length > 0 || draft.data.root.field) {
            setNextSrc(src);
          } else {
            setDraft({
              ...draft.data,
              src
            });
          }
        }}
      />
    </Section>

    <div className='h-[15px] border-l border-l-gray-300 ml-2' />

    <div className='flex pr-[400px]'>
      <RuleTreeEditor
        src={draft.data.src}
        tags={tags}
        value={draft.data.root}
        onChange={(root) => {
          setDraft({
            ...draft.data,
            root: root as RuleRoot
          });
        }}
        constructorRules={costViewConstructor.data[draft.data.src].rules}
      />
    </div>

    {tagsShown && (
      <div className='fixed top-[70px] right-[15px] bottom-[85px] mb-auto w-[370px] z-10 flex flex-col'>
        <TagsSummary
          src={draft.data.src}
          tagFilters={tagFilters}
          costViewConstructor={costViewConstructor.data}
        />
      </div>
    )}

    <SaveAsNewDialog />

    <DeleteDialog
      onConfirm={() => {
        navigate('/rules');
      }}
    />

    <ConfirmSrcChangeDialog
      src={nextSrc}
      onCancel={() => {
        setNextSrc(null);
      }}
      onConfirm={() => {
        setDraft({
          ...draft.data,
          src: nextSrc as string,
          root: {
            field: null,
            children: []
          }
        });
        setNextSrc(null);
      }}
    />
  </Page>;
}
