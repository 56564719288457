import type { DataEntry } from 'types/dataEntries';
import type { TagValue } from 'types/tags';
import { Select } from 'antd';
import { useTagsStore } from 'store/tags';

export interface TagValueSelectProps {
  tagKey: string | null;
  value: string | null;
  onChange: (value: string | null) => void;
}

export const TagValueSelect = (props: TagValueSelectProps) => {
  const {
    tagKey: key,
    value,
    onChange
  } = props;

  const values = useTagsStore((store) => {
    const keys = store.getKeys();

    if (key && keys.status === 'success') {
      const id = keys.data.find((k) => k.key === key)?.id;

      if (id !== undefined) {
        return store.getValues(id);
      }
    }

    return { status: 'idle' } as DataEntry<TagValue[]>;
  });

  return (
    <Select
      disabled={!key}
      value={value}
      options={values.status === 'success' ? values.data.map(({ value }) => ({ label: value, value })) : []}
      onChange={onChange}
    />
  );
}
