import type { GlobalFilter, GlobalFilterDraft, GlobalFilterDeps } from 'types/globalFilters';
import { parseGlobalFilterFields } from './filters';

export const parseGlobalFilterDraft = (globalFilter: GlobalFilter, deps: GlobalFilterDeps): GlobalFilterDraft => {
  return {
    name: globalFilter.name,
    filters: parseGlobalFilterFields(globalFilter, deps.globalFilterConstructor),
    is_reserved: false,
  };
}
