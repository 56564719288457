import { Grid } from 'shared/Grid';
import { Section, SectionGroupTitle, SectionTitle } from 'shared/Section';
import { useJobLogsStore } from 'store/savings';
import { JobLogTable } from 'shared/JobLogTable';

export interface JobLogSectionProps {
  className?: string;
  opportunityId: number;
}

export const JobLogSection = (props: JobLogSectionProps) => {
  const {
    className = '',
    opportunityId
  } = props;

  const jobLog = useJobLogsStore((store) => store.getEntry(opportunityId));

  return (
    jobLog.status === 'success' && jobLog.data.length > 0 ?

    <Grid className={className}>
      <SectionGroupTitle className='col-span-12'>
        Automation Context
      </SectionGroupTitle>

      <Section className={`p-5 relative col-span-12`}>
        <SectionTitle className='mb-4'>
          Job Log
        </SectionTitle>

        <JobLogTable opportunityId={opportunityId} />
      </Section>
    </Grid>

    : null
  );
}
