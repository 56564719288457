import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ActionButtons } from '../UnitMetric/ActionButtons';
import {
  useRulesStore,
  useRuleDeleteDialogStore,
  useRuleSaveAsNewDialogStore
} from 'store/rules';

export interface RuleActionButtonsProps {
  id: number | string;
}

export const RuleActionButtons = (props: RuleActionButtonsProps) => {
  const navigate = useNavigate();

  const { id } = props;
  const ruleIsNew = typeof id === 'string';

  const currentAction = useRulesStore((store) => store.getAction(id));
  const actionInProgress = currentAction?.status === 'in-progress'

  const openDeleteDialog = useRuleDeleteDialogStore((store) => store.open);
  const openSaveAsNewDialog = useRuleSaveAsNewDialogStore((store) => store.open);
  const updateRule = useRulesStore((store) => store.updateEntry);

  return (
    <ActionButtons
      del={{
        available: !ruleIsNew,
        disabled: actionInProgress,
        onClick: () => {
          openDeleteDialog(id as number);
        }
      }} 
      save={{
        available: !ruleIsNew,
        disabled: actionInProgress,
        onClick: () => {
          updateRule(id as number)
            .then((newRule) => {
              message.success('Rule created');
              navigate(`/rules/${newRule.id}/`);
            })
            .catch(() => {
              message.error('Unable to create rule');
            });
        }
      }} 
      saveAsNew={{
        disabled: false,
        onClick: () => {
          openSaveAsNewDialog(id);
        }
      }}
      newAlert={{ available: false }}
      newReport={{ available: false }}
    />
  );
}
