import {
  Filter,
  FilterCondition,
  FilterConstructor,
  FilterNestedCondition,
  FilterValueCondition,
  isFilterNestedCondition,
  isFilterStringValueCondition,
  isFilterValueCondition,
} from 'types/filters';

import {
  isFilterGroupCondition
} from 'types/filters';

export const findRule = (root: FilterCondition, check: (condition: FilterCondition) => boolean): FilterCondition | undefined => {
  const stack: FilterCondition[] = [root];

  while (stack.length) {
    const condition = stack.pop() as FilterCondition;

    if (check(condition)) {
      return condition;
    }

    if (isFilterGroupCondition(condition)) {
      stack.push(...condition.condition);
    }
  }
}

export const isValueConditionFilled = (condition: FilterValueCondition): boolean => {
  if (!condition.values.length) {
    return false;
  }

  if (isFilterStringValueCondition(condition)) {
    return condition.values.some((value) => !!value);
  }

  return condition.values.some((value) => {
    const entry = Object.entries(value)[0];

    return !!(entry && entry[0] && entry[1]);
  });
}

export const checkRequiredRules = (filter: Filter, constructor: FilterConstructor): boolean => {
  const rules = constructor[filter.src];
  const requiredRules = Object.values(rules).filter((rule) => rule.required);

  return requiredRules.every((rule) => {
    return findRule(filter.filter, (condition) => {
      return isFilterValueCondition(condition) &&
        condition.field_name === rule.name &&
        isValueConditionFilled(condition);
    });
  });
}

export const checkRequiredChildren = (filter: Filter, constructor: FilterConstructor): boolean => {
  const rules = constructor[filter.src];
  const rulesWithRequiredChildren = Object.values(rules).filter((rule) => rule.requiredChildren);

  return rulesWithRequiredChildren.every((rule) => {
    const condition = findRule(filter.filter, (cond) =>
      isFilterNestedCondition(cond) &&
        cond.condition[0].condition[0].field_name === rule.name
    ) as FilterNestedCondition | undefined;

    if (!condition) {
      return true;
    }

    return condition.condition.every((item) => {
      return (rule.requiredChildren as string[]).every((childField) => {
        return findRule(item, (cond) => {
          return isFilterValueCondition(cond) &&
            cond.field_name === childField &&
            isValueConditionFilled(cond);
        });
      });
    });
  });
}

export const validateFilter = (filter: Filter, constructor: FilterConstructor): boolean => {
  return checkRequiredRules(filter, constructor) && checkRequiredChildren(filter, constructor);
}
