import type { Setter } from 'types/common';
import type { ListDataFilterValue, ListDataFilterOptions, ListDataFilterOption, ListDataFilterConfig } from 'types/dataFilters';

import { Fragment, ReactNode, useMemo, useState } from 'react';
import { DataFilterValueText } from './DataFilterValueText';
import { DataFilterValueHolder } from './DataFilterValueHolder';
import { RemoveButton } from './RemoveButton';
import { Popover } from 'antd';
import { ListDataFilterDropdown } from './ListDataFilterDropdown';

export interface ListDataFilterValueDisplayProps<T extends (string | number)> {
  value: ListDataFilterValue<T>;
  config: ListDataFilterConfig;
  options: ListDataFilterOptions<T>;
  onChange: Setter<ListDataFilterValue<T>>;
}

export const ListDataFilterValueDisplay = <T extends (string | number),>(props: ListDataFilterValueDisplayProps<T>) => {
  const {
    value,
    config,
    options,
    onChange
  } = props;

  if (!value || value.length === 0) {
    return null;
  }

  const optionsMap = useMemo(() => {
    const map: { [key: string]: ReactNode } = {};

    const fillMap = (option: ListDataFilterOption<T>, prefix?: ReactNode) => {
      if (option.children) {
        option.children.forEach((opt) => fillMap(opt, option.text));
      } else {
        map[option.value] = config.tags ? <>
          {prefix}
          {': '}
          {option.text}
        </> : option.text;
      }
    }

    options.forEach(fillMap);

    return map;
  }, [options, config])

  const [opened, setOpened] = useState(false);

  if (value.length > 2) {
    return (
      <DataFilterValueHolder>
        <span className=''>
          <DataFilterValueText>
            {optionsMap[value[0]] || value[0]}
          </DataFilterValueText>


          <span className='align-middle'>
          {' and '}

            <Popover
              open={opened}
              onOpenChange={setOpened}
              content={
                <ListDataFilterDropdown
                  opened={opened}
                  value={value}
                  config={config}
                  options={value.map((val) => ({
                    value: val,
                    text: optionsMap[val]
                  }))}
                  onChange={onChange}
                />
              }
            >
              <span className='underline'>
                {value.length - 1} more
              </span>
            </Popover>
          </span>
        </span>

        <RemoveButton
          onClick={() => {
            onChange([]);
          }}
        />
      </DataFilterValueHolder>
    );
  }

  return (
    <DataFilterValueHolder>
      {value.map((val, index) =>
        <Fragment key={index}>
          <DataFilterValueText>
            {optionsMap[val] || val}
          </DataFilterValueText>

          <RemoveButton
            onClick={() => {
              const newValue = [...value];

              newValue.splice(index, 1);
              
              onChange(newValue);
            }}
          />
        </Fragment>
      )}
    </DataFilterValueHolder>
  );
};
