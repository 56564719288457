import { ReactNode } from 'react'
import Logo from 'assets/images/Logo.svg'
import LogoSmall from 'assets/images/LogoSmall.svg'

const SplashPage = ({ children, className = '' } : {children: ReactNode, className?: string}) => (
  <div className={`w-full min-h-screen bg-grey-transparent-dashboard pt-[80px] md:px-[200px] px-[10px] ${className}`}>
    <img className="hidden md:block h-[56px] absolute top-0 left-0" src={Logo} alt="Logo" />
    <img className="visible md:invisible h-[40px] absolute top-0 left-0 mt-[10px] ml-[10px]" src={LogoSmall} alt="Logo" />
    {children}
  </div>
)

export default SplashPage
