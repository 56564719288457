import type { DataEntry } from 'types/dataEntries';
import type { ListDataFilterOption } from 'types/dataFilters';
import type { JobLogFilterValue, JobLogFilterOptions, JobLogEntry } from 'types/savings';

import { useState } from 'react';

import { Table } from 'antd';
import { DataFiltersControl } from 'shared/DataFilters';
import { JOB_STATUSES, JOB_STATUS_TITLES, JOB_LOG_FILTER_CONFIG } from 'types/savings';

import { useJobLogs } from 'store/savings';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import moment from 'moment';
import {Link} from 'react-router-dom';

export interface JobLogTableProps {
  opportunityId?: number | null;
}

export const JobLogTable = (props: JobLogTableProps) => {
  const {
    opportunityId = null
  } = props;

  const logs = useJobLogs(opportunityId);

  const [filterValue, setFilterValue] = useState<JobLogFilterValue>({
    opportunity_id: [],
    status: [],
    job_id: []
  });

  const filterOptions: DataEntry<JobLogFilterOptions> = useDerivedDataEntry({
    logs,
    opportunityId
  }, ({
    logs,
    opportunityId
  }) => {
    const opportunity_id = opportunityId ? [] : Array.from(
      Object.values(
        logs.reduce((acc, logEntry) => {
          if (!acc[logEntry.opportunity_id]) {
            acc[logEntry.opportunity_id] = {
              value: logEntry.opportunity_id,
              text: `OPP-${logEntry.opportunity_secondary_id}`
            }
          }

          return acc;
        }, {} as { [key: number]: ListDataFilterOption })
      )
    );

    const status = Array.from(
      new Set(logs.map((logEntry) => logEntry.status))
    ).sort((statusA, statusB) => JOB_STATUSES.indexOf(statusA) - JOB_STATUSES.indexOf(statusB))
      .map((status) => ({
        value: status,
        text: JOB_STATUS_TITLES[status]
      }));

    const job_id = Array.from(
      new Set(logs.map((logEntry) => logEntry.job_id))
    )
      .sort((idA, idB) => idA - idB)
      .map((jobId) => ({
        value: jobId,
        text: jobId
      }));

    return {
      opportunity_id,
      status,
      job_id
    };
  });

  const filteredLogs = useDerivedDataEntry({
    logs,
    filterValue
  }, ({
    logs,
    filterValue
  }) => {
    let result = logs;

    (['opportunity_id', 'status', 'job_id'] as const).forEach((key) => {
      if (filterValue[key].length > 0) {
        const set = new Set(filterValue[key]);

        result = result.filter((logEntry) => set.has(logEntry[key]));
      }
    });

    return result;
  });

  return (
    <div>
      <DataFiltersControl
        configs={JOB_LOG_FILTER_CONFIG}
        value={filterValue}
        options={filterOptions.status === 'success' && filterOptions.data}
        onChange={setFilterValue}
      />

      <Table
        size='small'
        pagination={{
          showSizeChanger: true
        }}
        dataSource={filteredLogs.status === 'success' ? filteredLogs.data : []}
        columns={[
          ...(opportunityId === null ? [{
            key: 'opportunity',
            title: 'Opportunity',
            width: 110,
            render: (logEntry: JobLogEntry) => <Link
              className='text-blue hover:!text-blue-hover'
              to={`/savings/opportunities/${logEntry.opportunity_id}`}
            >
              OPP-{logEntry.opportunity_secondary_id}
            </Link>
          }] : []),
          {
            key: 'created_at',
            title: 'Created At',
            defaultSortOrder: 'descend',
            width: 180,
            sorter: (a, b) => moment(a.created_at).isAfter(b.created_at) ? 1 : -1,
            render: (logEntry: JobLogEntry) => moment(logEntry.created_at).format('YYYY-MM-DD HH:mm:ss')
          },
          {
            key: 'owner',
            title: 'Created By',
            width: 180,
            render: (logEntry: JobLogEntry) => logEntry.owner ?
              `${logEntry.owner.name} (${logEntry.owner.email})` :
              'Cloudthread'
          },
          {
            key: 'job_id',
            title: 'Job ID',
            width: 80,
            render: (logEntry: JobLogEntry) => logEntry.job_id
          },
          {
            key: 'status',
            title: 'Job Status',
            width: 160,
            render: (logEntry: JobLogEntry) => JOB_STATUS_TITLES[logEntry.status]
          },
          {
            key: 'message',
            title: 'Message',
            render: (logEntry) => logEntry.message || '-'
          },
        ]}
      />
    </div>
  );
}
