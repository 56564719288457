import { useState } from 'react';
import { Button2 as Button } from 'shared/Button';

export interface JSONDumpProps {
  className?: string;
  children: Object;
  expandable?: boolean;
  replacer?: ((this: any, key: string, value: any) => any);
}

export const JSONDump = (props: JSONDumpProps) => {
  const {
    expandable = true,
    className = '',
    children,
    replacer
  } = props;

  const [expanded, setExpanded] = useState(false);

  return(
    <div className={`relative flex items-stretch ${className}`}>
      <pre className={`${!expandable || expanded ? 'max-h-[320px]' : 'h-[62px]'} overflow-auto bg-gray-100 p-3 rounded grow`}>
        {JSON.stringify(
          children,
          replacer,
          2
        )}
      </pre>

    {expandable &&
      <Button
        className='absolute top-4 right-5'
        size='s'
        theme='bordered'
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {expanded ? 'Collapse' : 'Expand'}
      </Button>
    }
    </div>
  );

}
