import type { ReactNode } from 'react';

export interface DataFilterValueHolderProps {
  children: ReactNode;
}

export const DataFilterValueHolder = (props: DataFilterValueHolderProps) => {
  const {
    children
  } = props;
  
  return (
    <span
      className='inline-flex items-center text-[11px] leading-[13px] font-medium p-[5px] gap-[5px] rounded-[11px] bg-[rgba(98,111,128,0.08)]'
    >
      {children}
    </span>
  );
};
