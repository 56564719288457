import type { RateCommitmentType , Opportunity} from 'types/savings';

import { useState } from 'react';
import { Popover } from '@headlessui/react';
import { Checkbox, Select } from 'antd';
import { Button2 as Button } from 'shared/Button';
import { InfoIcon } from 'shared/InfoIcon';
import { RadioButton, RadioButtonGroup } from 'shared/RadioButtonGroup';
import { TeamSelect } from './controls/TeamSelect';
import { GlobalFilterSelect } from './controls/GlobalFilterSelect';
import { ThreadFromSelectedButton } from './controls/ThreadFromSelectedButton';
import { useGlobalState } from 'state/globalState';
import { useSavingsSettingsStore } from 'store/savings';
import {useOrgSettingsStore} from 'store/orgs';

interface SettingsPanelProps {
  showTeamSetting?: boolean;
  selected?: Opportunity[];
  onSelect?: (opportunities: Opportunity[]) => void;
}

export const SettingsPanel = (props: SettingsPanelProps) => {
  const user = useGlobalState((store) => store.user);

  const { 
    selected,
    onSelect 
  } = props;
  const userHasNoTeam = !!user && !user.team;
  const showTeamSetting = props.showTeamSetting !== undefined ?
    props.showTeamSetting :
    userHasNoTeam && user.role !== 'member';

  const orgId = user!.org_id;
  const orgSettings = useOrgSettingsStore((store) => store.getEntry(orgId));
  const showRateOpt =  orgSettings.status === 'success' && orgSettings.data.rate_optimization_allowed;

  const {
    platform,
    setPlatform,

    group,
    setGroup,

    source,
    setSource,

    priority,
    setPriority,

    rateCommitments,
    setCommitmentEnabled,
    setCommitmentTerm,
    setCommitmentPayment,
    setCommitmentEC2OfferingClass,
    setCommitmentLookback,
  } = useSavingsSettingsStore();

  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [newPriority, setNewPriority] = useState('');

  return (
    <div className='flex items-center ml-auto gap-5'>
      {showTeamSetting && <div className='items-start gap-0.5 font-medium text-caption-1 whitespace-nowrap flex flex-col'>
        <div>Team</div>
        <TeamSelect />
      </div>}

      <div className='items-start gap-0.5 font-medium text-caption-1 whitespace-nowrap flex flex-col'>
        <div>Global Filters</div>
        <GlobalFilterSelect />
      </div>

      <Popover className='inline-flex'>
        <Popover.Button as='span' className='inline-flex'>
          <Button
            size='xs'
            theme='black'
            layout='inline'
            iconLeft='settings'
          >
            Settings
          </Button>
        </Popover.Button>

        <Popover.Panel
          className='absolute top-[100%] right-0 bg-white mt-2 drop-shadow-lg z-100 p-5 rounded w-[800px]'
        >
          <div className='grid grid-cols-3 gap-x-5'>
            <div className='items-center gap-0.5 font-medium whitespace-nowrap flex-wrap mb-8'>
              <div className='mb-2 font-medium'>Platform</div>

              <RadioButtonGroup
                name='platform'
                value={platform}  
                onChange={(_, { value }) => {
                  setPlatform(value);
                }}
              >
                <RadioButton value='all'>All</RadioButton>
                <RadioButton value='aws'>
                  AWS
                </RadioButton>
                <RadioButton value='gcp'>
                  Google Cloud
                </RadioButton>
              </RadioButtonGroup>
            </div>

            {showRateOpt &&
              <div className='items-center gap-0.5 font-medium whitespace-nowrap flex-wrap mb-8'>
                <div className='mb-2 font-medium'>Optimizations</div>

                <RadioButtonGroup
                  name='group'
                  value={group}  
                  onChange={(_, { value }) => {
                    setGroup(value);
                  }}
                >
                  <RadioButton value='both'>All</RadioButton>
                  <RadioButton value='usage'>Usage</RadioButton>
                  <RadioButton value='rate'>Rate</RadioButton>
                </RadioButtonGroup>
                <InfoIcon className='ml-2'>
                  <div className='font-medium'>
                    Usage Optimization
                  </div>
                  <p>
                    Opportunities to reduce resource usage waste. Eliminate things you’re paying for but not using. 
                  </p>

                  <div className='mt-2 font-medium'>
                    Rate Optimization
                  </div>
                  <p>
                    Opportunities to maximize resource committed use discounts. Pay less for things you know you’ll be using. 
                  </p>
                </InfoIcon>
              </div>
            }

            <div className='items-center gap-0.5 font-medium whitespace-nowrap flex-wrap mb-8'>
              <div className='mb-2 font-medium'>Deduplication Source</div>
              <div className='flex items-center font-medium gap-2'>
                <RadioButtonGroup
                  name='source'
                  value={source}  
                  onChange={(_, { value }) => {
                    setSource(value);
                  }}
                >
                  <RadioButton value='costexplorer'>Cost Explorer</RadioButton>
                  <RadioButton value='trustedadvisor'>Trusted Advisor</RadioButton>
                </RadioButtonGroup>

                <InfoIcon>
                  Cloudthread defaults to Cost Explorer Savings Plans and Reserved Instance recommendations, as well
                  as Compute Optimizer EC2, EBS, and Lambda Rightsizing, and a selection of Cloudthread discovered  
                  opportunites. These recommendations are also available from Trusted Advisor.
                </InfoIcon>
              </div>
            </div>

            <div className='items-center gap-0.5 font-medium whitespace-nowrap flex-wrap mb-8'>
              <div className='mb-2 font-medium'>Priority</div>
              <div  className='w-[200px]'>
                <Select
                  size='small'
                  className='w-full'
                  mode='tags'
                  open={false}
                  options={[]}
                  searchValue={newPriority}
                  value={priority ?? []}
                  tokenSeparators={[',', ' ']}
                  onSearch={(val: string) => {
                    setNewPriority(val.replace(/\D/g, ''));
                  }}
                  onChange={(value) => {
                    setNewPriority('');
                    setPriority(value);
                  }}
                />
              </div>
            </div>
          </div>

          {showRateOpt && 
            <div className='items-center gap-0.5 font-medium whitespace-nowrap flex-wrap mb-8'>
              <div className='mb-2 font-medium'>Rate Optimization Settings</div>
              <div className='relative flex mb-5 gap-10' ref={setContainer}>
                {(['savingsPlans', 'reservedInstances'] as RateCommitmentType[]).map((commType) => {
                  const {
                    enabled,
                    term,
                    payment,
                    ec2OfferingClass,
                    lookback
                  } = rateCommitments[commType];

                  return (
                    <div className='w-1/2' key={commType}>
                      <div className='font-medium'>
                        <Checkbox
                          checked={enabled}
                          onChange={(e) => {
                            setCommitmentEnabled(commType, e.target.checked);
                          }}
                        >
                          {{
                            savingsPlans: 'Savings Plans',
                            reservedInstances: 'Reserved Instances'
                          }[commType]}
                        </Checkbox>
                      </div>

                      <div className='flex items-center justify-between my-2'>
                        <div className='w-1/2 font-medium'>
                          Term
                        </div>

                        <Select
                          size='small'
                          className='grow'
                          value={term}
                          getPopupContainer={() => container as HTMLDivElement}
                          onChange={(value) => {
                            setCommitmentTerm(commType, value);
                          }}
                          options={[
                            { value: 'ONE_YEAR', label: 'One Year' },
                            { value: 'THREE_YEARS', label: 'Three Years' }
                          ]}
                        >
                        </Select>
                      </div>

                      <div className='flex items-center justify-between my-2'>
                        <div className='w-1/2 font-medium'>
                          Payment
                        </div>

                        <Select
                          size='small'
                          className='grow'
                          value={payment}
                          getPopupContainer={() => container as HTMLDivElement}
                          onChange={(value) => {
                            setCommitmentPayment(commType, value);
                          }}
                          options={[
                            ...(source === 'costexplorer' ? [
                              { value: 'NO_UPFRONT', label: 'No Upfront' },
                            ] : []),
                            { value: 'PARTIAL_UPFRONT', label: 'Partial Upfront' },
                            { value: 'ALL_UPFRONT', label: 'All Upfront' }
                          ]}
                        >
                        </Select>
                      </div>

                      {ec2OfferingClass !== undefined && (
                        <div className='flex items-center my-2'>
                          <div className='flex items-center w-1/2'>
                            <div className='font-medium'>
                              EC2 Offering Class
                            </div>

                            <InfoIcon>
                              Applies to the EC2 Reserved Instance recommendations only
                            </InfoIcon>
                          </div>

                          <Select
                            size='small'
                            className='grow'
                            value={ec2OfferingClass}
                            getPopupContainer={() => container as HTMLDivElement}
                            onChange={(value) => {
                              setCommitmentEC2OfferingClass(commType, value);
                            }}
                            options={[
                              { value: 'STANDARD', label: 'Standard' },
                              { value: 'CONVERTIBLE', label: 'Convertible' }
                            ]}
                          />
                        </div>
                      )}

                      {lookback !== undefined && (
                        <div className='flex items-center justify-between my-2'>
                          <div className='w-1/2 font-medium'>
                            Lookback
                          </div>
                          <Select
                            size='small'
                            className='grow'
                            value={lookback}
                            getPopupContainer={() => container as HTMLDivElement}
                            onChange={(value) => {
                              setCommitmentLookback(commType, value);
                            }}
                            options={[
                              { value: 'SEVEN_DAYS', label: '7 days' },
                              { value: 'THIRTY_DAYS', label: '30 days' },
                              { value: 'SIXTY_DAYS', label: '60 days' }
                            ]}
                          >
                          </Select>
                        </div>
                      )}
                    </div>
                  );
                })}

                <div className='absolute top-0 bottom-0 border-l border-l-gray-200 left-[50%] ' />
              </div>
            </div>
          }
        </Popover.Panel>
      </Popover>

      <ThreadFromSelectedButton 
        selected={selected}
        onSelect={onSelect}
      />
    </div>
  );
}
