import { Button } from 'shared/Button';
import { redirectToCheckout } from 'services/billing';

export interface SubscribeButtonProps {
  children: React.ReactNode;
}

export const SubscribeButton = (props: SubscribeButtonProps) => {
  const {
    children
  } = props;

  return <Button
    className='mt-[40px] !bg-positive'
    style='caladon'
    label={children}
    onClick={redirectToCheckout}
  />;
}
