import type { StringRule } from 'types/filters';
import { useMemo } from 'react';
import { Select } from 'antd';
import { getSelectPlaceholder } from './constants';

export interface StringRuleValueProps {
  rule: StringRule;
  value: string;
  options: string[];
  aliases?: Record<string, string>;
  siblingValues: string[];
  disabled?: boolean;
  onChange: (value: string) => void;
}

export const StringRuleValue = (props: StringRuleValueProps) => {
  const {
    rule,
    value,
    options,
    aliases,
    siblingValues,
    disabled,
    onChange
  } = props;

  const selectOptions = useMemo(
    () => options
      .filter((value) => !siblingValues.includes(value))
      .map((value) => ({
        value: value,
        label: aliases && aliases[value] ?
          `${aliases[value]} (${value})` :
          value
      })),
    [options, aliases, siblingValues]
  );

  return (
    <Select
      className='grow'
      showSearch
      placeholder={getSelectPlaceholder(rule.name)}
      value={value}
      options={selectOptions}
      disabled={disabled}
      onChange={onChange}
    />
  );
};
