import type { HiddenFeature } from 'types/orgs'
import { HIDDEN_FEATURES } from 'types/orgs';
import { Select, message, Checkbox } from 'antd';
import { Button2 as Button } from 'shared/Button';
import { StatusOverlay } from 'shared/Overlay';
import { useGlobalState } from 'state/globalState';
import { useOrgSettingsStore } from 'store/orgs';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import {SectionTitle} from 'shared/Section';
import {FormRow, Label} from 'shared/Form';

export const OrgSettingsTab = () => {
  const user = useGlobalState((state) => state.user);

  const orgId = user?.org_id as number;
  const oldOrgSettings = useOrgSettingsStore((store) => store.getEntry(orgId));
  const newOrgSettings = useOrgSettingsStore((store) => store.getDraft(orgId));
  const setOrgSettings = useOrgSettingsStore((store) => store.setDraft);
  const updateOrgSettings = useOrgSettingsStore((store) => store.updateEntry);
  const updateAction = useOrgSettingsStore((store) => store.actions[orgId] || null);

  const settings = useDerivedDataEntry(
    { oldOrgSettings, newOrgSettings, updateAction },
    ({ oldOrgSettings, newOrgSettings, updateAction }) => {
      const submitting = updateAction && updateAction.status === 'in-progress';

      const hidden_features = {
        disabled: submitting,
        value: newOrgSettings.hidden_features ?? [],
        options:  HIDDEN_FEATURES.map(feature => { return {label: feature, value: feature}}),
        hidden_features: newOrgSettings.hidden_features,
        onChange: (value: HiddenFeature[]) => {
          setOrgSettings(orgId, {
            ...newOrgSettings,
            hidden_features: value,
          });
        }
      };

      const submit = {
        children: submitting ? 'Saving' : 'Save',
        disabled:
          submitting ||
          hidden_features.hidden_features === oldOrgSettings.hidden_features,
        onClick: () => {
          updateOrgSettings(orgId)
            .then(() => {
              message.success('Organization settings updated');
            })
            .catch(() => {
              message.error('Cannot update organization settings');
            });
        }
      };

      const rateOptimization = {
        disabled: submitting,
        checked: newOrgSettings.rate_optimization_allowed,
        onChange: (event: { target: { checked: boolean }}) => {
          setOrgSettings(orgId, {
            ...newOrgSettings,
            rate_optimization_allowed: event.target.checked
          });
        }
      }

      return {
        rateOptimization,
        hidden_features,
        submit
      };
    }
  );

  const {
    rateOptimization,
    hidden_features,
    submit
  } = settings.status === 'success' ? settings.data : {
    rateOptimization: { disabled: true },
    hidden_features: { disabled: true, hidden_features: [] },
    submit: { disabled: true, label: 'Save' }
  };


  return (
    <div className='relative my-10'>
      <SectionTitle className='mb-4'>
        Organization Settings
      </SectionTitle>

      <FormRow size='s'>
        <Checkbox {...rateOptimization}>
          Enable rate optimization
        </Checkbox>
      </FormRow>

      <FormRow size='s'>
        <Label size='s'>
          Hide certain features for Members
        </Label>

        <Select 
          className='w-[300px]' 
          mode='multiple' 
          {...hidden_features} 
        />
      </FormRow>

      <Button
        size='m'
        {...submit}
      />

      <StatusOverlay status={newOrgSettings.status} />
    </div>
  );
};
