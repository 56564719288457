import { Toggle } from 'shared/Toggle';
import { useSSOSettingsStore } from 'store/ssoSettings';

export const ToggleEnabledControl = () => {
  const enabled = useSSOSettingsStore((store) => store.enabled);
  const toggleEnabled = useSSOSettingsStore((store) => store.toggleEnabled);

  return (
    <div className='my-[30px]'>
      <div className='flex items-center'>
        <Toggle
          className='mr-[8px]'
          checked={!enabled}
          onChange={toggleEnabled}
        /> 
        Disable SSO 
      </div>
      <div className='ml-[52px] text-silver-grey-900 text-[11px]'>
        You can disable or enable back SSO for your organisation at any time.<br/>
        If you do so, the connection will still persist.
      </div>
    </div>
  );
};
