import type { DataEntry } from 'types/dataEntries';
import type { JiraProject } from 'types/savings'
import { useMemo } from 'react';
import { Select } from 'antd';

export interface JiraIssueTypeSelectProps {
  projectKey: string;
  value: string;
  onChange: (value: string) => void;
  store: () => DataEntry<JiraProject[], any>;
}

export const JiraIssueTypeSelect = (props: JiraIssueTypeSelectProps) => {
  const {
    projectKey,
    value,
    onChange,
    store
  } = props;

  const projects = store();

  const options = useMemo(() => {
    if (projects.status !== 'success') {
      return [];
    }

    const project = projects.data.find(({ key }) => key === projectKey);

    if (!project) {
      return [];
    }

    return project.issue_types.map(({ id, name }) => ({
      value: id,
      label: name
    }));
  }, [projects, projectKey]);

  return <Select
    className='w-full'
    disabled={options.length === 0}
    value={value}
    onChange={onChange}
    options={options}
    allowClear
  />;
}
