import type { ReactNode } from 'react';

export interface DialogFooterProps {
  className?: string;
  children?: ReactNode
}

export const DialogFooter = (props: DialogFooterProps) => {
  const {
    className = '',
    children
  } = props;

  return (
    <footer className={`flex gap-2 justify-end p-3 ${className}`}>
      {children}
    </footer>
  );
}
