import type { PartialState } from 'zustand';
import type { DataEntry } from 'types/dataEntries';

import { withKeyDep } from './decorators';

export const createSetEntry = <
  TEntry,
  TState extends {
    entries: { [key: number]: DataEntry<TEntry> }
  }
>({
  get,
  set
} : {
  get: () => TState;
  set: (state: PartialState<TState>) => void;
}) => (
  id: number,
  entry: DataEntry<TEntry>
) => {
  const { entries } = get();

  set({
    entries: {
      ...entries,
      [id]: entry
    }
  } as PartialState<TState>);
};

export const createGetEntry = <TEntry, TState extends {
  entries: { [key: number]: DataEntry<TEntry> };
}>({
  get,
  set,
  fetchEntry
}: {
  get: () => TState;
  set: (state: PartialState<TState>) => void;
  fetchEntry: (id: number) => Promise<{ data: TEntry }>;
}) => withKeyDep((id: number) => {
  const { entries } = get();

  let entry = entries[id];

  if (!entries[id]) {
    entry = { status: 'loading' };
    entries[id] = entry;

    fetchEntry(id)
      .then(({ data }) => ({
        status: 'success',
        data
      }))
      .catch((error) => ({
        error,
        status: 'error'
      }))
      .then((newEntry) => {
        const newState = {
          entries: {
            ...entries,
            [id]: newEntry
          }
        } as PartialState<TState>;

        set(newState);
      });
  }

  return entry;
});

export const createUpdateEntry = <
  TEntry,
  TDraft,
  TPayload,
  TActionType,
  TState extends {
    setEntry: (id: number, entry: DataEntry<TEntry>) => void;
    getDraft: (key: number | string) => DataEntry<TDraft>;
    startAction: (id: number, type: TActionType) => void;
    finishAction: (id: number) => void;
  }
>({
  get,
  updateActionType,
  updateEntry,
  serializeDraft
} : {
  get: () => TState;
  updateActionType: TActionType,
  updateEntry: (id: number, payload: TPayload) => Promise<{ data: TEntry }>
  serializeDraft: (draft: TDraft) => TPayload;
}) => (id: number) => {
  const {
    getDraft,
    setEntry,
    startAction,
    finishAction
  } = get();

  const draft = getDraft(id);

  if (draft.status !== 'success') {
    throw new Error('Draft not loaded');
  }

  startAction(id, updateActionType);

  const payload = serializeDraft(draft.data);

  return updateEntry(id, payload)
    .then(({ data }) => {
      const entry: DataEntry<TEntry> = {
        status: 'success',
        data
      };

      setEntry(id, entry);

      return data;
    })
    .catch((error) => {
      const entry: DataEntry<TEntry> = {
        status: 'error',
        error
      };

      setEntry(id, entry);

      return Promise.reject(error);
    })
    .finally(() => {
      finishAction(id);
    });
};
