import type { DataEntry } from 'types/dataEntries';
import type { DrillDownField, DrillDownData } from 'types/tagViews/drillDown';
import type { Moment } from 'moment';

import { IconButton } from 'shared/Button';
import { Tooltip } from 'antd';
import { useMemo } from 'react';
import { columnBuilder, Table } from 'shared/Table/Table';
import { ExportToCsvButton } from 'shared/ExportToCsvButton';
import { DateText } from 'shared/DateText';
import { Loading } from 'shared/Loading/Loading';
import { useGlobalState } from 'state/globalState';

const FIRST_COLUMN_LABEL: Record<string, string> = {
  accounts: 'Account ID',
  regions: 'Region',
  services: 'Service',
  usage_types: 'Usage Types',
  operations: 'Operations',
  resources: 'Resources',
}

export interface BreakdownTableProps {
  breakdown: DrillDownField;
  drillDown: DataEntry<DrillDownData>;
  canDrillDown: boolean;
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
  setCurrentStep: (value: string) => void;
}

export const BreakdownTable = (props: BreakdownTableProps) => {
  const {
    breakdown,
    drillDown,
    setCurrentStep,
    canDrillDown,
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment,
  } = props;

  //const logEvent = useGlobalState((state) => state.logEvent);

  const firstColumn = breakdown;
  const aliasColumn = 'alias';
  const percentChangeDisplayColumn = 'percent_change_display'

  const data = useMemo(() => drillDown.status !== 'success' ? 
    [] :
    drillDown.data.table_data.map((row: any) => (
    { 
      ...row,
      [percentChangeDisplayColumn]: row.previous_timeframe_cost === 0 ? 'n/a' : row.pop_change - 1,
      [aliasColumn]: breakdown === 'accounts' && row[breakdown] in drillDown.data.alias_map ? drillDown.data.alias_map[row[breakdown]] : row[breakdown],
    }
  )), [drillDown]);


  const columns = useMemo(() => {
    if (drillDown.status !== 'success') {
      return [];
    }

    let cols = columnBuilder()
      .sortable(aliasColumn, FIRST_COLUMN_LABEL[firstColumn])
      .sortable('current_timeframe_tagged_percent', 'Avg Daily Percent Tagged', 'percent_or_na', 'descend')
      .sortable('previous_timeframe_tagged_percent', 'Prev. Period Tagged', 'percent_or_na')
      .sortable(percentChangeDisplayColumn, '% Change', 'percent_or_na')

    if (canDrillDown) {
      cols = cols.custom(100, (row: Record<string, string>) => (
        <Tooltip title='Isolate and drill into this cost category'>
          <div className='inline-block'>
            <IconButton
              icon="arrow-down-on-off"
              type="tertiary"
              size="sm"
              onClick={() => { 
                setCurrentStep(row[firstColumn]);
                //logEvent('set_drilldown_current_step', { 'field': firstColumn, 'source': 'breakdown_pane' });
              }}
            />
          </div>
        </Tooltip>
      ), 'Drilldown')
    }

    return cols.columns();
  }, [breakdown, drillDown]);

  return (
    <div className="relative isolate">
      <div className='mb-5'>
        <div className='flex'>
          <DateText
            description={'Current Period'}
            startMoment={startMoment}
            endMoment={endMoment}
          />
          <div className='ml-5'>
            <DateText
              description={'Previous Period'}
              startMoment={prevStartMoment}
              endMoment={prevEndMoment}
            />
          </div>
          <div className='text-[11px] leading-[13px] ml-5'>
            <span className='text-silver-grey-900'>
              Graph only reflects current period data 
            </span>
          </div>
        </div>
      </div>
      <Table columns={columns} data={data} pageSize={10} idColumn={firstColumn} />
      <div className='mt-[20px] mb-[10px] flex'>
        <div className='ml-auto'>
          {drillDown.status === 'success' && 
            <ExportToCsvButton fileName='drilldown_table.csv' data={drillDown.data.table_data} />
          }
        </div>
      </div>
      {drillDown.status === 'loading' && <Loading />}
    </div>
  );
}
