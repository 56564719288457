import type { CostViewConstructor } from 'types/costViews';
import { GlobalFilterConstructor, GLOBAL_FILTER_SOURCES } from 'types/globalFilters';

import { FLAT_FIELDS_TO_RULE_NAMES } from './constants';

export const parseGlobalFilterConstructor = (costViewConstructor: CostViewConstructor): GlobalFilterConstructor => {
  const result: GlobalFilterConstructor = {
  };

  GLOBAL_FILTER_SOURCES.forEach((src) => {
    const srcConstructor = costViewConstructor[src];

    if (!srcConstructor) {
      return;
    }
    
    const fields = Array.from(Object.values(FLAT_FIELDS_TO_RULE_NAMES[src]));

    result[src] = {};

    fields.forEach((field) => {
      const rule = srcConstructor.rules[field];

      if (rule) {
        result[src]![field] = {
          ...rule,
          required: false,
          regexAllowed: false,
          exclusionAllowed: false,
          children: undefined
        };
      }
    });
  });

  return result;
}
