import create from 'zustand'
import { 
  CurrentUser,
  OrgUpdatePayload,
  UserUpdatePayload,
  UserUpdateEmailPayload,
  UserUpdatePasswordPayload,
  UserUpdateFeatureFlags,
 } from 'types/users'
import { 
  getProfile,
  updateCurrentUser,
  updateCurrentUserEmail,
  updateCurrentUserPassword,
  updateCurrentUserFeatureFlags,
  updateOrganization,
} from 'services/user'
import { login, logout, loginMarketplace } from 'services/auth'
import { message } from 'antd';
import amplitude from 'amplitude-js'

export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY

type SideMenuAmplitudeEventType = 'side_menu_click';

type UnitMetricAmplitudeEventType =
  'save_as_new' |
  'click_library_row' |
  'toggle_filter_mode' |
  'hit_filter_mode_dialog' |
  'close_filter_mode_dialog' |
  'confirm_filter_mode_dialog' |
  'set_numerator_cost' |
  'set_denominator_src' |
  'change_metric_name'
;

type DeleteDialogAmplitudeEventType = 
  'open_delete_dialog' | 
  'close_delete_dialog' | 
  'confirm_delete_dialog';

type SaveAsNewAmplitudeEventType = 
  'save_as_new' | 
  'open_save_as_new_dialog' | 
  'close_save_as_new_dialog' | 
  'confirm_save_as_new_dialog';

type DrillDownAmplitudeEventType = 
  'set_breakdown' |
  'open_drilldown' |
  'collapse_drilldown_chart' |
  'set_tag_breakdown' |
  'set_drilldown_current_step' |
  'swap_drilldown_step' |
  'swap_drilldown_step_value' |
  'clear_drilldown' |
  'rollback_drilldown';

type RightsizingAmplitudeEventType = 'set_rightsizing_type';
type DateFilterAmplitudeEventType = 'set_period' | 'set_granularity';

type FilterAmplitudeEventType = 'open_filters' |
  'apply_filter' |
  'clear_filter' |
  'reset_filter' |
  'add_filter_rule' |
  'remove_filter_rule' |
  'add_or_filter' |
  'change_filter_value' |
  'add_filter_value' |
  'clear_filter_value' |
  'adjust_value_type_filter_value' |
  'adjust_comparator_filter_value';

type AmplitudeEventType =
  SideMenuAmplitudeEventType |
  UnitMetricAmplitudeEventType |
  SaveAsNewAmplitudeEventType |
  DeleteDialogAmplitudeEventType |
  DrillDownAmplitudeEventType |
  RightsizingAmplitudeEventType |
  DateFilterAmplitudeEventType |
  FilterAmplitudeEventType;

export type availableDates = {
  cloudwatch?: {
    min_date: string | null,
    max_date: string | null,
  },
  cur?: {
    min_date: string | null,
    max_date: string | null,
  },
  k8s?: {
    min_date: string | null,
    max_date: string | null,
  },
  custom?: {
    min_date: string | null,
    max_date: string | null,
  }
}

interface State {
  email: string | null
  initialized: boolean | null
  user: CurrentUser | null
  accountReady: boolean
  sideMenuCollapsed: boolean
  availableDates: availableDates
  loginError: null | {
    error: string;
    message: {
      admin_email: string;
    };
  },
  init: () => Promise<void>
  updateUser: (payload: UserUpdatePayload) => void
  updateUserEmail: (payload: UserUpdateEmailPayload) => void
  updateUserPassword: (payload: UserUpdatePasswordPayload) => void
  updateUserFeatureFlags: (payload: UserUpdateFeatureFlags) => void
  updateOrg: (id: number, payload: OrgUpdatePayload) => void
  logout: () => void
  login: (email: string, password: string) => Promise<void>
  loginMarketplace: (email: string, password: string, token: string) => Promise<void>
  toggleSideMenu: () => void
  collapseSideMenu: () => void
  logEvent: (
    eventType: AmplitudeEventType,
    eventProperties?: { [key: string]: any }
  ) => void
  discardLoginError: () => void;
  setUserEmail: (email: string) => void;
}

export const useGlobalState = create<State>((set, get) => ({
  email: null,
  stack: null,
  user: null,
  loginError: null,
  accountReady: false,
  initialized: null,
  sideMenuCollapsed: false,
  availableDates: {},

  discardLoginError: () => {
    set({ loginError: null });
  },

  toggleSideMenu: () => {
    set({ sideMenuCollapsed: !get().sideMenuCollapsed })
  },

  collapseSideMenu: () => {
    set({ sideMenuCollapsed: true })
  },

  logEvent: (
    eventType: AmplitudeEventType,
    eventProperties: any = {},
  ) => {
    const { user }  = get()
    if (!user) {
      return
    }

    if (!AMPLITUDE_API_KEY) {
      console.error('no tracking api key set')
      return
    }

    try {
      const organization_id = user.org_id
      const platform = 'frontend'
      amplitude.getInstance(`instance-${user.id}`).init(AMPLITUDE_API_KEY, `${user.id}`)
      amplitude.getInstance(`instance-${user.id}`).logEvent(
        eventType, 
        { 
          ...eventProperties,
          organization_id,
          platform,
        },
      );
    } catch(error) {
      console.error(error)
    }
  },

  init: () => {
    return getProfile().then((user) => {
      set({
        user,
        initialized: true,
        accountReady: user && user.org_onboarding_complete,
        loginError: null,
        email: user.email,
      })
    }).catch((error) => {
      set({
        user: null,
        initialized: true,
        accountReady: false,
        loginError: error.response?.data,
        email: null,
      });
    })
  },

  updateUser: (payload: UserUpdatePayload) => {
    updateCurrentUser(payload)
      .then((user) => {
        set({ user })
      }).catch(() => {
        message.error('Cannot update user')
      })
  },

  updateUserEmail: (payload: UserUpdateEmailPayload) => {
    updateCurrentUserEmail(payload)
      .then((user) => {
        set({ user })
      }).catch(() => {
        message.error('Cannot update user email')
      })
  },

  updateUserPassword: (payload: UserUpdatePasswordPayload) => {
    updateCurrentUserPassword(payload)
      .then((user) => {
        set({ user })
      }).catch(() => {
        message.error('Cannot update user password')
      })
  },

  updateUserFeatureFlags: (payload: UserUpdateFeatureFlags) => {
    updateCurrentUserFeatureFlags(payload)
      .then((user) => {
        set({ user })
      }).catch(() => {
        message.error('Cannot update user feature flags')
      })
  },

  updateOrg: (id: number, payload: OrgUpdatePayload) => {
    updateOrganization(id, payload)
      .then(() => {
        getProfile().then((user) => {
          set({ user })
        })
      }).catch((error) => {
        message.error('Cannot update organization')

        return Promise.reject(error);
      })
  },

  logout: () => {
    logout()
      .then(() => {
        window.location.assign(`${window.location.origin}/login`)
      }).catch((error) => {
        message.error('Cannot logout')

        return Promise.reject(error);
      })
  },

  login: (email: string, password: string) => {
    return login(email, password)
      .then(() => {
        get().init()
      })
      .catch((error) => {
        message.error(error.response.data.message)

        return Promise.reject(error);
      })
  },

  loginMarketplace: (email: string, password: string, token: string) => {
    return loginMarketplace(email, password, token)
      .then(() => {
        get().init()
      })
      .catch((error) => {
        message.error(error.response.data.message)

        return Promise.reject(error);
      })
  },

  setUserEmail: (email: string) => {
    set({ email });
  },
}))
