import type { OpportunityDifficulty } from 'types/savings';
import { Select } from 'antd';
import { DifficultyBadge } from '../DifficultyBadge';

export interface DifficultySelectProps {
  value: OpportunityDifficulty;
  disabled?: boolean;
  onChange: (value: OpportunityDifficulty) => void;
}

export const DifficultySelect = (props: DifficultySelectProps) => {
  const {
    value,
    disabled,
    onChange
  } = props;

  return (
    <Select
      className='w-full'
      value={value}
      disabled={disabled}
      options={['easy', 'medium', 'hard'].map((difficulty) => ({
        value: difficulty,
        label: <DifficultyBadge difficulty={difficulty as OpportunityDifficulty} />
      }))}
      onChange={onChange}
    />
  );
};
