import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export type UseTagKeyResult = [
  string | null,
  (tagKey: string | null) => void
]

export const useTagKey = (): UseTagKeyResult => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tagKey: string | null = searchParams.get('tagKey');

  const setTagKey = useCallback((newTagKey: string | null) => {
    if (newTagKey === tagKey) {
      return;
    }

    const newSearchParams = new URLSearchParams(searchParams);

    if (newTagKey === null) {
      newSearchParams.delete('tagKey');
    } else {
      newSearchParams.set('tagKey', newTagKey);
    }

    setSearchParams(newSearchParams, { replace: true });
  }, [searchParams]);

  return [
    tagKey,
    setTagKey
  ];
};
