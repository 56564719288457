import type { ThreadStatusGroup } from 'types/savings';
import { RadioButton, RadioButtonGroup } from 'shared/RadioButtonGroup';

export interface StatusFilterProps {
  value: ThreadStatusGroup | null;
  onChange: (value: ThreadStatusGroup) => void;
}

export const ThreadStatusFilter = (props: StatusFilterProps) => {
  const {
    value,
    onChange
  } = props;

  return (
    <RadioButtonGroup
      name='radioFilter'
      value={value || 'active'}
      onChange={(_, { value }) => {
        onChange(value);
      }}
    >
      <RadioButton value='active'>Active</RadioButton>
      <RadioButton value='done'>Done</RadioButton>
      <RadioButton value='rejected'>Rejected</RadioButton>
      <RadioButton value='deleted'>Deleted</RadioButton>
      <RadioButton value='all'>All</RadioButton>
    </RadioButtonGroup>
  );
}
