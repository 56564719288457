import type { ReactNode } from 'react';
import { IconButton } from 'shared/Button';

export interface RuleRowProps {
  children?: ReactNode;
  disabled?: boolean;
  onRemove?: () => void;
}

export const RuleRow = (props: RuleRowProps) => {
  const {
    children,
    disabled,
    onRemove
  } = props;

  return (
    <div className='flex pt-[10px]'>
      {children}
      {onRemove && !disabled && (
        <IconButton
          className='p-[10px] shrink-0'
          icon='minus'
          size='sm'
          type='tertiary'
          onClick={onRemove}
        />
      )}
    </div>
  );
};
