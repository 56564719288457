import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import {
  InitRoute,
  AccountNotReadyRoute,
  AuthRoute,
  NonAuthRoute,
  AccountReadyRoute,
  DataReadyRoute,
  LoginErrorRoute,
  AdminRoute,
  NoTeamRoute
} from 'guards';

import { ShellRoute } from 'routes/ShellRoute';
import { InfoRoute } from 'routes/InfoRoute';
import { BillingRedirectRoute } from 'routes/BillingRedirectRoute';

import Login from 'pages/Login'
import LoginMarketplace from 'pages/LoginMarketplace'
import SignUp from 'pages/SignUp'
import SignUpMarketplace from 'pages/SignUpMarketplace'
import SignUpByInvite from 'pages/SignUpByInvite'
import ForgotPassword from 'pages/ForgotPassword'
import ResetPassword from 'pages/ResetPassword';
import ConfirmEmail from 'pages/ConfirmEmail'
import EmailConfirmed from 'pages/EmailConfirmed'
import { LoginError } from 'pages/LoginError/LoginError';

import CostsOverview from 'pages/CostsOverview'
import NotificationCenter from 'pages/NotificationCenter'
import Settings from 'pages/Settings'
import { ConnectCfnSync } from 'pages/Onboarding/ConnectCfnSync'
import { ConnectCfnStack } from 'pages/Onboarding/ConnectCfnStack'

import { Library } from './pages/CostsOverview/Library'
import { UnitMetricsLibrary } from 'pages/UnitMetricsLibrary'
import { UnitMetricPage } from 'pages/UnitMetric';
import { SummaryDashboard } from 'pages/SummaryDashboard';
import { Welcome } from 'pages/Welcome';
import { GithubCallback } from 'pages/Login/GithubCallback';
import { GoogleCallback } from 'pages/Login/GoogleCallback';
import { SSOCallback } from 'pages/Login/SSOCallback';
import { DashboardPage } from 'pages/Dashboard/Dashboard';
import { DashboardLibraryPage } from 'pages/DashboardLibrary/DashboardLibrary';
import TagsOverview from 'pages/TagsOverview';
import { TagCatalogs } from 'pages/TagCatalogs/TagCatalogs';
import { RulePage } from 'pages/Rule/RulePage';
import { RulesLibraryPage } from 'pages/RulesLibrary/RulesLibraryPage';

import {
  OpportunitiesPage,
  OpportunityPage,
  ThreadsPage,
  ThreadPage,
  TeamLeaderboardPage,
  SavingsDashboardPage,
  ThreadCallback
} from 'pages/savings';

const AppRoutes = () => (
  <Router>
    <Routes>
      <Route path='/' element={<InitRoute />}>
        <Route path='/' element={<AuthRoute />}>
          <Route path='/' element={<DataReadyRoute />}>
            <Route path='/' element={<Navigate to='/savings' />} />
          </Route>

          <Route path='/' element={<Navigate to='/welcome' />} />

          <Route path='/onboarding' element={<AccountNotReadyRoute />}>
            <Route path='' element={<ConnectCfnStack />} />
          </Route>

          <Route path='/onboarding/sync' element={<ConnectCfnSync />} />

          <Route path='/' element={<AccountReadyRoute />}>
            <Route path='/' element={<ShellRoute />}>
              <Route path='/welcome' element={<Welcome />} />

              <Route path='/' element={<DataReadyRoute />}>
                <Route path='/summary-dashboard' element = {<SummaryDashboard />} />
                <Route path='/costs-overview/library' element={<Library />} />
                <Route path='/costs-overview/:id' element={<CostsOverview />} />
                <Route path='/unit-metrics/library' element={<UnitMetricsLibrary />} />
                <Route path='/unit-metrics/:id' element={<UnitMetricPage />} />
                <Route path='/unit-metrics' element={<UnitMetricPage />} />
                <Route path='/dashboards/library' element={<DashboardLibraryPage />} />
                <Route path='/dashboards/:id' element={<DashboardPage />} />
                <Route path='/dashboards' element={<DashboardPage />} />
                <Route path='/tags-overview' element={<TagsOverview />} />
                <Route path='/rules/library' element={<RulesLibraryPage />} />
                <Route path='/rules/:id' element={<RulePage />} />
                <Route path='/rules' element={<RulePage />} />
              </Route>

              <Route path='/' element={<InfoRoute />}>
                <Route path='/costs-overview' element={<CostsOverview />} />
                <Route path='/tags/catalog' element={<TagCatalogs />} />
                <Route path='/savings/opportunities' element={<OpportunitiesPage />} />
                <Route path='/savings/opportunities/:id' element={<OpportunityPage />} />
                <Route path='/savings/threads' element={<ThreadsPage />} />
                <Route path='/savings/threads/:id' element={<ThreadPage />} />

                <Route path='/savings/teams' element={<AdminRoute />}>
                  <Route path='/savings/teams' element={<NoTeamRoute />}>
                    <Route path='/savings/teams/' element={<TeamLeaderboardPage />} />
                  </Route>
                </Route>

                <Route path='/savings' element={<SavingsDashboardPage />} />
                <Route path='/notification-center' element={<NotificationCenter />} />
                <Route path='/settings' element={<Settings />} />
                <Route path='/settings/:tab' element={<Settings />} />
              </Route>
            </Route>

            <Route path='/billing/return' element={<BillingRedirectRoute />} />
          </Route>

          <Route path='/savings/threads/callback' element={<ThreadCallback />} />
        </Route>

        <Route path='/' element={<NonAuthRoute />}>
          <Route path='/' element={<LoginErrorRoute />}>
            <Route path='/' element={<LoginError />} />
            <Route path='/sign-up' element={<SignUp />} />
            <Route path='/sign-up/marketplace' element={<SignUpMarketplace />} />
            <Route path='/sign-up/invite' element={<SignUpByInvite />} />
            <Route path='/sign-up/confirm-email' element={<ConfirmEmail />} />
            <Route path='/sign-up/email-confirmed' element={<EmailConfirmed />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/password-reset'  element={<ResetPassword />} />
            <Route path='/login' element={<Login />} />
            <Route path='/login/marketplace' element={<LoginMarketplace />} />
            <Route path='/signin/google/callback' element={<GoogleCallback />} />
            <Route path='/signin/github/callback' element={<GithubCallback />} />
            <Route path='/signin/sso/callback' element={<SSOCallback />} />
            <Route path='/savings/threads/callback' element={<ThreadCallback />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  </Router>
);

export default AppRoutes;
