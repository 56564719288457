import type { Opportunity } from 'types/savings';

import moment from 'moment';
import { Section } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { SimpleTable, SimpleTableRow, SimpleTableCell } from 'shared/SimpleTable';
import { DifficultyBadge } from '../DifficultyBadge';

import {
  renderMetaCategory,
  renderOpportunityTypeId,
  renderOpportunitySource,
  renderOpportunityPlatform,
  pickOpportunityCostImpact,
  pickOpportunityDifficulty
} from 'helpers/savings';
import { formatCurrency } from 'helpers/formatter';
import { capitalize } from 'helpers/string';
import { DIFFICULTY_DESCRIPTIONS } from '../constants';

import { useSavingsStore } from 'store/savings';

export interface OpportunityInfoSectionProps {
  className?: string;
  opportunityId: number;
}

const tables: [string, (opportunity: Opportunity) => React.ReactNode, boolean?][][] = [
  [
    ['Platform', renderOpportunityPlatform],
    ['Type', renderMetaCategory],
    ['Source', renderOpportunitySource],
    ['Category', renderOpportunityTypeId],
    ['Last detected', (opp) => moment(opp.last_detected_at).format('MMM DD, YYYY')],
  ], [
    ['Difficulty', (opp) => {
      const difficulty = pickOpportunityDifficulty(opp);

      return <>
        <DifficultyBadge difficulty={difficulty} tooltip={false} />
        <p className='mt-1 text-gray-500 text-caption-1'>
          {capitalize(DIFFICULTY_DESCRIPTIONS[difficulty])}
        </p>
      </>;
    }],
    ['Description', (opp) => opp.opportunity_details?.opportunity_description, true],
    ['Recommendation', (opp) => opp.opportunity_details?.opportunity_recommendation?.text, true]
  ]
];

export const OpportunityInfoSection = (props: OpportunityInfoSectionProps) => {
  const {
    className = '',
    opportunityId
  } = props;

  const opportunity = useSavingsStore((store) => store.getOpportunity(opportunityId));

  return (
    <Section className={`relative px-5 py-2 ${className}`}>
      <SimpleTable>
        <SimpleTableRow>
          <SimpleTableCell className='align-baseline whitespace-nowrap'>
            <b>Active Savings</b>
          </SimpleTableCell>

          <SimpleTableCell className='text-right align-baseline'>
            {opportunity.status === 'success' && <>
              <span className='font-medium text-title-2'>
                {formatCurrency(pickOpportunityCostImpact(opportunity.data) || 0)}
              </span>
              {' / month'}
            </>}
          </SimpleTableCell>
        </SimpleTableRow>
      </SimpleTable>

      {tables.map((rows) => 
        <SimpleTable className='mt-4'>
          <tbody>
            {rows.map(([title, render, wide]) => (
              <SimpleTableRow key={title} className='align-baseline'>
                <SimpleTableCell className='pr-2 w-[160px]' colSpan={wide ? 2 : undefined}>
                  <b className='block'>{title}</b>

                  {wide && opportunity.status === 'success' && 
                    <div className='mt-1'>
                      {render(opportunity.data) || '–'}
                    </div>
                  }
                </SimpleTableCell>

                {!wide && 
                  <SimpleTableCell>
                    {opportunity.status === 'success' && (render(opportunity.data) || '–')}
                  </SimpleTableCell>
                }
              </SimpleTableRow>
            ))}
          </tbody>
        </SimpleTable>
      )}

      <StatusOverlay status={opportunity.status} />
    </Section>
  );
}
