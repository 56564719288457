import { Table } from 'antd';
import { Section, SectionTitle } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { useGitHubPRsStore } from 'store/savings'

export interface GitHubPRsListProps {
  threadId: number;
  className?: string;
}

export const GitHubPRsList = (props: GitHubPRsListProps) => {
  const {
    threadId,
    className = ''
  } = props;

  const prs = useGitHubPRsStore((store) => store.getPRs(threadId));

  return (
    <Section
      className={`relative p-5 overflow-hidden ${className}`}
    >
      <SectionTitle className='mb-4'>
        GitHub PRs
      </SectionTitle>

      <Table
        size='small'
        dataSource={prs.status === 'success' ? prs.data : []}
        columns={[
          {
            title: 'Opportunity ID',
            key: 'opportunity_id',
            dataIndex: 'opportunity_id'
          },
          {
            title: 'Details',
            key: 'details',
            dataIndex: 'details'
          },
          {
            title: 'Status',
            key: 'status',
            dataIndex: 'status'
          },
          {
            title: 'Assignee',
            key: 'assignee',
            dataIndex: 'assigneee'
          }
        ]}
      />

      <StatusOverlay status={prs.status} />
    </Section>
  );
};
