import { useGlobalState } from 'state/globalState'
import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

export const AuthRoute = () => {
  const user = useGlobalState((state) => state.user);
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      window.sessionStorage.setItem('loginReturnPath', `${location.pathname}${location.search}`);
    } else {
      window.sessionStorage.removeItem('loginReturnPath');
    }
  }, [user]);

  return user ? <Outlet /> :
    <Navigate to='/login' />;
}
