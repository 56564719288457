import type { ReactNode } from 'react';
import type { Moment } from 'moment';
import type { CostViewFilter } from 'types/costViews';

import { useMemo } from 'react';
import { Loading } from 'shared/Loading';
import { DateText } from 'shared/DateText';
import { DiffBadge } from 'shared/DiffBadge';
import { formatCurrency } from 'helpers/formatter';
import { useCostViewChart } from 'store/costViewChart';

const EMPTY_FILTER: CostViewFilter = {
  src: 'cur',
  filter: { operator: 'or', condition: [] }
};

export interface NumberCardProps {
  description: ReactNode;
  startMoment: Moment;
  endMoment: Moment;
}

export const NumberCard = (props: NumberCardProps) => {
  const {
    startMoment,
    endMoment,
    description
  } = props;

  const entry = useCostViewChart({
    startDate: startMoment.format('YYYY-MM-DD'),
    endDate: endMoment.format('YYYY-MM-DD'),
    granularity: 'day',
    costType: 'unblended_cost',
    costDimensions: [],
    costAmortization: false,
    filter: EMPTY_FILTER
  });

  const [
    value,
    diff
  ] = useMemo(() => {
    if (entry.status !== 'success') {
      return [null, null];
    }

    let current = 0, previous = 0;

    entry.data.forEach(({ current_timeframe_cost, previous_timeframe_cost }) => {
      current += current_timeframe_cost ?? 0;
      previous += previous_timeframe_cost ?? 0;
    });

    let diff;
    if (previous != 0) {
      diff = (current / previous) - 1;
    } else {
      diff = null
    }

    return [current, diff];
  }, [entry])

    /*
  let startDateFormat = 'D';
  let endDateFormat = 'D MMM YYYY';

  if (startMoment.month() !== endMoment.month() || startMoment.year() !== endMoment.year()) {
    startDateFormat += ' MMM ';
    endDateFormat = ' ' + endDateFormat;
  }

  if (startMoment.year() !== endMoment.year()) {
    startDateFormat += 'YYYY ';
  }

  const startDateText = startMoment.format(startDateFormat);
  const endDateText = endMoment.format(endDateFormat);
     */

  return (
    <div className='relative border border-silver-grey-100 rounded-[10px] py-[10px] px-[15px] h-[65px]'>
      <DateText 
        description={description}
        startMoment={startMoment}
        endMoment={endMoment}
      />
      {entry.status === 'success' ?
        <div className='flex items-center text-[24px] leading-[24px] gap-[10px] mt-[6px]'>
          {formatCurrency(value as number)}
          {diff && <DiffBadge value={diff} />}
        </div> :
        <Loading />
      }
    </div>
  );
};
