import { Button } from 'shared/Button';

export interface SlugProps {
  slug: string | undefined;
}

export const Slug = (props: SlugProps) => {
  const {
    slug,
  } = props;

  return (
    <div className='p-[20px] border rounded self-start'>
      <div className='flex justify-between mb-[10px]'>
      <h2 className='font-medium text-subtitle-2'>
        {slug ? 
          'Your org slug is' :
          'Your org doesn\'t have a slug yet'
        }
      </h2>
      <Button
        className='inline-flex'
        icon='edit'
        size='sm'
        style='caladon'
        to='/settings'
        label={slug ?
          'Change in Profile' :
          'Set slug in profile'
        }
      />
      </div>

      {slug && 
        <div className='text-gray-400 text-[32px] font-bold my-[15px]'>{slug}</div>
      }

      <p className='my-[10px]'>
        You will need your organization slug to log into Cloudthread's platform via SSO
      </p>

    </div>
  );
};
