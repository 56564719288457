import type { Source } from 'types/unitMetrics';
import { Select } from 'antd';
import { getSourceTitle } from './constants';

export interface SourceControlProps {
  value: Source;
  options: (string | { key: string, value: string })[];
  disabled?: boolean;
  onChange: (value: Source) => void;
}

export const SourceControl = (props: SourceControlProps) => {
  const {
    value,
    options,
    disabled,
    onChange
  } = props;

  if (options.length === 0) {
    return null;
  }

  return (
    <div className={`flex items-center text-caption-1`}>
      Source:
      {options.length === 1 ?
        <b className='ml-1'>
          {typeof options[0] === 'string' ?
            getSourceTitle(value):
            options[0].value
          }
        </b> :

        <Select
          size='small'
          value={value}
          className='ml-1'
          disabled={disabled}
          options={options.map((key) => typeof key === 'string' ? 
            ({ value: key, label: getSourceTitle(key) }) :
            key
          )}
          onChange={onChange}
        />
      }
    </div>
  );
}
