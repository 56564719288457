import Dialog from 'shared/Dialog/Dialog';
import Button from 'shared/Button/Button';

export interface DeleteAlertDialogProps {
  name: string;
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const DeleteAlertDialog = (props: DeleteAlertDialogProps) => {
  const { name, open, onClose, onConfirm } = props;

  return (
    <Dialog className="w-[420px] pt-[40px] px-[30px] pb-[30px]" open={open} onClose={onClose}>
      <div className="font-semibold text-center text-subtitle-1">Are you sure you want to delete '{name}' permanently?</div>
      <Button className="justify-center mt-[30px] bg-negative" style="caladon" label="Yes, delete this alert" onClick={onConfirm} />
      <Button className="justify-center mt-[20px]" type="tertiary" label="Cancel" onClick={onClose} />
    </Dialog>
  );
};
