import { message } from 'antd';
import { useState } from 'react';
import { Button } from 'shared/Button';
import { Dialog } from 'shared/Dialog';

import { useBillingStore } from 'store/billing';

export const UnsubscribeButton = () => {
  const cancelSubscription = useBillingStore((store) => store.cancelSubscription);
  const [dialogOpened, setDialogOpened] = useState(false);

  return <>
    <Button
      className='!bg-negative mt-[40px]'
      label='Cancel Subscription'
      onClick={() => {
        setDialogOpened(true);
      }}
    />

    <Dialog
      className='w-[420px] pt-[40px] px-[30px] pb-[20px]'
      open={dialogOpened}
      onClose={() => {
        setDialogOpened(false);
      }}
    >
      <div className='font-semibold text-center text-subtitle-1'>
        Are you sure you want to cancel your Cloudthread subscription?
      </div>

      <Button
        className='justify-center mt-[30px] bg-negative'
        style='caladon'
        label='Yes, cancel subscription'
        onClick={() => {
          cancelSubscription()
            .then(() => {
              message.success('Subscription canceled');
            })
            .catch(() => {
              message.error('Unable to cancel subscription');
            });
            setDialogOpened(false);
        }}
      />

      <Button
        className='justify-center mt-[20px]'
        type='tertiary'
        label='Cancel'
        onClick={() => {
          setDialogOpened(false);
        }} />
    </Dialog>
  </>;
}
