import type { DataEntry } from 'types/dataEntries';
import type { UnitMetricConstructor } from 'types/unitMetrics';

import create from 'zustand';

import { fetchCostViewConstructor, fetchCostViewConstructorGCP } from 'services/costViews';
import { fetchUnitMetricConstructorCustom } from 'services/unitMetrics';

import { parseUnitMetricConstructor } from 'helpers/unitMetrics';

export interface UnitMetricConstructorState {
  entry: DataEntry<UnitMetricConstructor>;
}

export interface UnitMetricConstructorActions {
  getUnitMetricConstructor: () => DataEntry<UnitMetricConstructor>;
}

export interface UnitMetricConstructorStore extends UnitMetricConstructorState, UnitMetricConstructorActions {}

const DEFAULT_STATE: UnitMetricConstructorState = {
  entry: { status: 'idle' }
}

export const useUnitMetricConstructorStore = create<UnitMetricConstructorStore>((set, get) => ({
  ...DEFAULT_STATE,

  getUnitMetricConstructor: () => {
    if (get().entry.status === 'idle') {
      set({
        entry: { status: 'loading' }
      });

      Promise.all([
        fetchCostViewConstructor(),
        fetchUnitMetricConstructorCustom(),
        fetchCostViewConstructorGCP().catch(() => ({ data: undefined }))
      ])
        .then(([
          { data: constructorBackend },
          { data: constructorBackendCustom },
          { data: constructorBackendGCP }
        ]) => {
          const data = parseUnitMetricConstructor(
            constructorBackend,
            constructorBackendCustom,
            constructorBackendGCP
          );
        
        set({
          entry: {
            status: 'success',
            data
          }
        });
      }).catch((error: any) => {
        set({
          entry: {
            status: 'error',
            error
          }
        });
      });
    }

    return get().entry;
  }
}));

export const useUnitMetricConstructor = () => useUnitMetricConstructorStore((store) => store.getUnitMetricConstructor());
