import api from 'helpers/api';
import {RuleTag} from 'types/rules';

export const fetchDimensionValues = (tagKey: string) => api.get<{ tag_values: string[] }>('metrics/unit/custom-data/constructor/dimension-values', {
  params: { tag_key: tagKey }
})

export const fetchResourceTagValues = (tagKey: string) => api.get<{ tag_values: string[] }>('metrics/cost/constructor/resource-tag-values', {
  params: { tag_key: tagKey }
});

export const fetchAccountTagValues = (tagKey: string) => api.get<{ tag_values: string[] }>('metrics/cost/constructor/account-tag-values', {
  params: { tag_key: tagKey }
});

export const fetchOpportunityTagValues = (tagKey: string) => api.get<{ tag_values: string[] }>('metrics/cost/constructor/opportunity-tag-values', {
  params: { tag_key: tagKey }
});

export const fetchK8sLabels = (tagKey: string) => api.get<{ tag_values: string[] }>('metrics/cost/constructor/label-values', {
  params: { tag_key: tagKey }
});

export const fetchGCPLabels = (tagKey: string) => api.get<{ tag_values: string[] }>('metrics/cost/gcp/constructor/gcp-label-values', {
  params: { tag_key: tagKey }
});

export const fetchRuleTagValues = (tagKey: string, path: string[] = []) => api.get<RuleTag[]>(`metrics/rules/${path[0]}/tags/`, {
  params: { tag_key: tagKey }
}).then(({ data }) => {
  return {
    data: {
      tag_values: data.map(({ value }) => value)
    }
  };
});

export const fetchDictValues = ({ field, key, path }: { field: string, key: string, path?: string[] }) => {
  const fetchValues = {
    tags: fetchResourceTagValues,
    account_tags: fetchAccountTagValues,
    opportunity_tags: fetchOpportunityTagValues,
    bill_payer_account_tags: fetchAccountTagValues,
    k8s_labels: fetchK8sLabels,
    dimensions: fetchDimensionValues,
    gcp_labels: fetchGCPLabels,
    rule_tags: fetchRuleTagValues
  }[field];

  return (fetchValues as (key: string, path?: string[]) => Promise<{ data: { tag_values: string[] }}>)(key, path);
}
