import type { DataEntry } from 'types/dataEntries';
import type { CostViewChart } from 'types/costViews/charts';

import { ValueBox } from 'shared/ValueBox';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';

export interface PreviousTotalValueProps {
  chartData: DataEntry<CostViewChart>;
  format: (value: number) => string;
}

export const PreviousTotalValue = (props: PreviousTotalValueProps) => {
  const {
    chartData,
    format
  } = props

  const value = useDerivedDataEntry({
    chartData,
  }, ({ 
    chartData
  }) => chartData.reduce((st, next) => st + (next.previous_timeframe_cost ?? 0), 0));

  return (
    <ValueBox.SimpleValueBox
      label='Previous Total'
      text={value.status === 'success' ? format(value.data) : 'Loading'}
    />
  );
};
