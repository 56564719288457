import type { DataEntry, Action } from 'types/dataEntries';
import type { JiraIssue, JiraIssueCreatePayload } from 'types/savings';

import create from 'zustand';
import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';

import {
  fetchJiraIssues,
  createJiraIssue
} from 'services/savings';

type IssueAction = Action<'create'>;

export interface JiraIssuesStoreState {
  issues: ImmutableMap<number, DataEntry<JiraIssue[]>>;
  drafts: ImmutableMap<number, ImmutableMap<ImmutableSet<number>, JiraIssueCreatePayload>>;
  actions: ImmutableMap<number, ImmutableMap<ImmutableSet<number>, IssueAction>>;

  getIssues: (threadId: number) => DataEntry<JiraIssue[]>;
  createIssue: (threadId: number, selected: number[], provider: 'jira_cloud' | 'jira_server') => Promise<JiraIssue>;

  setDraft: (threadId: number, selected: number[], draft: JiraIssueCreatePayload) => void;
  getDraft: (threadId: number, selected: number[]) => JiraIssueCreatePayload;
  discardDraft: (threadId: number, selected: number[]) => void;

  getAction: (threadId: number, selected: number[]) => IssueAction;
}

export const useJiraIssuesStore = create<JiraIssuesStoreState>((set, get) => ({
  issues: ImmutableMap(),
  drafts: ImmutableMap(),
  actions: ImmutableMap(),

  getDraft: (threadId: number, selected: number[]) => {
    const { drafts } = get();

    let threadDrafts = drafts.get(threadId);

    if (!threadDrafts) {
      threadDrafts = ImmutableMap();

      set({
        drafts: drafts.set(threadId, threadDrafts)
      });
    }

    const selectedKey = ImmutableSet(selected)

    let draft = threadDrafts.get(selectedKey);

    if (!draft) {
      draft = {
        thread_id: threadId,
        opportunity_ids: selected,
        jira_project_key: '',
        jira_issue_type: '',
      };

      set({
        drafts: drafts.set(threadId, threadDrafts.set(selectedKey, draft))
      });
    }

    return draft;
  },

  setDraft: (threadId: number, selected: number[], draft: JiraIssueCreatePayload) => {
    const { drafts } = get();

    let threadDrafts = drafts.get(threadId);

    if (!threadDrafts) {
      threadDrafts = ImmutableMap();

      set({
        drafts: drafts.set(threadId, threadDrafts)
      });
    }

    set({
      drafts: drafts.set(threadId, threadDrafts.set(ImmutableSet(selected), draft))
    });
  },

  discardDraft: (threadId: number, selected: number[]) => {
    const { drafts } = get();

    let threadDrafts = drafts.get(threadId);

    if (threadDrafts) {
      set({ 
        drafts: drafts.set(threadId, threadDrafts.remove(ImmutableSet(selected)))
      });
    }
  },

  getIssues: (threadId: number) => {
    let {
      issues
    } = get();

    let threadIssues = issues.get(threadId);

    if (!threadIssues) {
      threadIssues = { status: 'loading' };

      set({
        issues: issues.set(threadId, threadIssues)
      });

      fetchJiraIssues(threadId)
        .then(({ data }) => {
          set({
            issues: get().issues.set(threadId, {
              status: 'success',
              data
            })
          });
        })
        .catch((error) => {
          set({
            issues: get().issues.set(threadId, {
              status: 'error',
              error
            })
          });
        });
    }

    return threadIssues;
  },

  createIssue: (threadId: number, selected: number[], provider: 'jira_cloud' | 'jira_server') => {
    const {
      actions,
      getDraft
    } = get();

    const draft = getDraft(threadId, selected);

    let threadActions = actions.get(threadId);

    if (!threadActions) {
      threadActions = ImmutableMap()

      set({ 
        actions: actions.set(threadId, threadActions)
      });
    }

    const selectedKey = ImmutableSet(selected)

    set({
      actions: actions.set(threadId, threadActions.set(selectedKey, {
        type: 'create',
        status: 'in-progress'
      }))
    });

    return createJiraIssue(provider, draft)
      .then((issue) => {
        const {
          issues,
          actions
        } = get();

        let threadActions = actions.get(threadId);

        if (threadActions) {
          set({
            actions: actions.set(threadId, threadActions.remove(selectedKey))
          });
        }

        const threadIssues = issues.get(threadId);

        if (threadIssues && threadIssues.status === 'success') {
          set({
            issues: issues.set(threadId, {
              ...threadIssues,
              data: [
                ...threadIssues.data,
                issue
              ]
            })
          });
        }

        return issue;
      })
      .catch((error) => {
        const { actions } = get();

        let threadActions = actions.get(threadId);

        if (!threadActions) {
          threadActions = ImmutableMap()
    
          set({ 
            actions: actions.set(threadId, threadActions)
          });
        }

        set({
          actions: actions.set(threadId, threadActions.set(selectedKey, {
            type: 'create',
            status: 'error',
            error,
          }))
        });

        throw error;
      });
  },

  getAction: (threadId: number, selected: number[]) => {
    const { actions } = get();

    let threadActions = actions.get(threadId);

    if (!threadActions) {
      threadActions = ImmutableMap();

      set({
        actions: actions.set(threadId, threadActions)
      });
    }

    const selectedKey = ImmutableSet(selected)

    let action = threadActions.get(selectedKey);

    if (!action) {
      action = {
        type: 'create',
        status: 'idle'
      };

      set({
        actions: actions.set(threadId, threadActions.set(selectedKey, action))
      });
    }

    return action;
  },
}));
