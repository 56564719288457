import type { StatusFilterValue } from 'types/savings';
import { RadioButton, RadioButtonGroup, RadioButtonProps } from 'shared/RadioButtonGroup';
import { ReactElement } from 'react';

export interface StatusFilterProps {
  value: StatusFilterValue | null;
  onChange: (value: StatusFilterValue) => void;
  extraStatusFilters?: ReactElement<RadioButtonProps<StatusFilterValue>>;
}

export const StatusFilter = (props: StatusFilterProps) => {
  const {
    value,
    onChange,
    extraStatusFilters,
  } = props;

  return (
    <RadioButtonGroup
      name='radioFilter'
      value={value || 'active'}
      onChange={(_, { value }) => {
        onChange(value);
      }}
    >
      <RadioButton value='active'>Active</RadioButton>
      <RadioButton value='in_progress'>In Progress</RadioButton>
      <RadioButton value='done'>Done</RadioButton>
      <RadioButton value='trash'>Archived</RadioButton>
      {extraStatusFilters ?? <div/>}
      <RadioButton value='all'>All</RadioButton>
    </RadioButtonGroup>
  );
}
