import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Tabs } from 'antd';
import { Page } from 'shared/Page';
import ApiKeyLibrary from './ApiKeys/ApiKeyLibrary'
import DataStreamConfigLibrary from './DataStreams/DataStreamsLIbrary'
import { ProfileTab } from './Profile'
import { SSOTab } from './SSOTab';
import { PullDataCollectionTab } from './PullDataCollectionTab';
import { TeamsTab } from './TeamsTab';
import { GlobalFiltersTab } from './GlobalFiltersTab';
import { UsersTab } from './UsersTab';
import { OrgSettingsTab } from './OrgSettingsTab';
import { PaymentTab } from './PaymentTab';
import { IntegrationsTab } from './IntegrationsTab';
import { ColumnsLibrary } from './CostColumnsTab'
import { SavingsOpportunitiesTab } from './SavingsOpportunitiesTab';
import { useGlobalState } from 'state/globalState';


const { TabPane } = Tabs

const Settings = () => {

  const user = useGlobalState((store) => store.user)
  
  const adminTabsAvailable = useMemo(() => user?.permissions.includes('admin'), [user]);

  /* TODO Antd Tabs Color Themed */

  /* TODO: use real links */
  const navigate = useNavigate();
  const activeKey = useParams().tab || 'profile';
  const setActiveKey = (key: string) => {
    navigate(`/settings/${key === 'profile' ? '' : key}`); 
  }

  return (
    <Page>
      <Page.Head title="Settings" />
      <div className="w-full bg-white pt-[20px] px-[30px] pb-[42px] rounded-[16px] shadow-metrics-block mb-[30px]">
        <Tabs activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab='Profile' key='profile'>
              <ProfileTab />
          </TabPane>

          {adminTabsAvailable && <>
            <TabPane tab='Savings Opportunities' key='savings-opportunities'>
              <SavingsOpportunitiesTab />
            </TabPane>
            <TabPane tab='User Management' key='user-management'>
              <UsersTab />
            </TabPane>
            <TabPane tab='Organization Settings' key='org-settings'>
              <OrgSettingsTab />
            </TabPane>
            <TabPane tab='Integrations' key='third-party-integrations'>
              <IntegrationsTab />
            </TabPane>
            <TabPane tab='SSO' key='sso'>
              <SSOTab slug={user?.org_slug} goToSettingsTab={setActiveKey} />
            </TabPane>
            <TabPane tab='Payment' key='payment'>
              <PaymentTab />
            </TabPane>
            <TabPane tab='Pull Data Collection' key='pull-data-collection'>
              <PullDataCollectionTab />
            </TabPane>
            <TabPane tab='Push Data Collection' key='push-data-collection'>
              <DataStreamConfigLibrary />
            </TabPane>
            <TabPane tab='API Keys' key='api-keys'>
              <ApiKeyLibrary />
            </TabPane>
            <TabPane tab='Teams' key='teams'>
              <TeamsTab />
            </TabPane>
            <TabPane tab='Global Filters' key='global-filters'>
              <GlobalFiltersTab />
            </TabPane>
            <TabPane tab='Cost Columns' key='cost-columns'>
              <ColumnsLibrary />
            </TabPane>
          </>}
        </Tabs>
      </div>
    </Page>
  )
}

export default Settings
