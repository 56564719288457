import type { ReactNode } from 'react';
import { createTypeGuardFromArray } from 'helpers/guards';

export type FetchStatus = 'idle' | 'loading' | 'success' | 'error';
export type Granularity = 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';

export const isGranularity = (value: any) : value is Granularity => new Set(['hour', 'day', 'week', 'month', 'quarter', 'year']).has(value);

/**
 * @deprecated use 'types/dataEntries'
 */
export interface DataEntry<T = unknown> {
  status: FetchStatus;
  data: T | null;
}

export const SORT_DIRS = ['ascend', 'descend'] as const;

export type SortDir = typeof SORT_DIRS[number];

export const isSortDir = createTypeGuardFromArray(SORT_DIRS);

export interface Sort<TField extends string> {
  dir: SortDir;
  field: TField;
}

export type QueryParamParser<T> = (param: string | null) => T;
export type QueryParamSerializer<T> = (value: T) => string | null;

export type Setter<T> = (value: T) => void;
export type ValueAndSetter<T> = [T, Setter<T>];
export type Renderer<T> = (value: T) => ReactNode;
