import type { StringRule } from 'types/filters';
import { Input } from 'antd';
import { getInputPlaceholder } from './constants';

export interface StringRegexRuleValueProps {
  rule: StringRule;
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

export const StringRegexRuleValue = (props: StringRegexRuleValueProps) => {
  const {
    rule,
    value,
    disabled,
    onChange
  } = props;

  return (
    <Input
      className='grow'
      type='secondary'
      placeholder={getInputPlaceholder(rule.name)}
      value={value}
      disabled={disabled}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    />
  );
};
