export type DataFilterFunction<TValue, TRecord> = (value: TValue) => (record: TRecord) => boolean;

import {
  NumberRangeDataFilterConfig,
  NumberRangeDataFilterValue,
  NumberRangeDataFilterOptions
} from './range';

export * from './range';

import {
  ListDataFilterConfig,
  ListDataFilterValue,
  ListDataFilterOptions
} from './list';

export * from './list';

import {
  QueryDataFilterConfig,
  QueryDataFilterValue
} from './query';

export * from './query';

export type DataFilterConfig = NumberRangeDataFilterConfig | ListDataFilterConfig | QueryDataFilterConfig;

export const isNumberRangeDataFilterConfig = (config: DataFilterConfig): config is NumberRangeDataFilterConfig => config.type === 'number-range';
export const isListDataFilterConfig = (config: DataFilterConfig): config is ListDataFilterConfig => config.type === 'list';

export type DataFilterConfigsMap<TKeys extends string> = {
  [Key in TKeys]: DataFilterConfig;
};

export type GetDataFilterValueType<TConfig extends DataFilterConfig> = 
  TConfig extends ListDataFilterConfig ? ListDataFilterValue :
  TConfig extends NumberRangeDataFilterConfig ? NumberRangeDataFilterValue :
  TConfig extends QueryDataFilterConfig ? QueryDataFilterValue :
  never;

export type GetDataFilterOptionsType<TConfig extends DataFilterConfig> = 
  TConfig extends ListDataFilterConfig ? ListDataFilterOptions :
  TConfig extends NumberRangeDataFilterConfig ? NumberRangeDataFilterOptions :
  never;

// Common
export type DataFilterValue = NumberRangeDataFilterValue | ListDataFilterValue | QueryDataFilterValue;
export type DataFilterOptions = NumberRangeDataFilterOptions | ListDataFilterOptions;

type FilterFlags<Base, Condition> = {
  [Key in keyof Base]:
    Base[Key] extends Condition ? Key : never
};

type AllowedNames<Base, Condition> =
  FilterFlags<Base, Condition>[keyof Base];

export type DataFilterValuesMap<
  TKeys extends string,
  TConfigs extends DataFilterConfigsMap<TKeys>
> = {
  [Key in TKeys]: GetDataFilterValueType<TConfigs[Key]>;
};

export type DataFilterOptionsMap<
  TKeys extends string,
  TConfigs extends DataFilterConfigsMap<TKeys>
> = {
  [Key in AllowedNames<TConfigs, NumberRangeDataFilterConfig | ListDataFilterConfig>]: GetDataFilterOptionsType<TConfigs[Key]>;
};
