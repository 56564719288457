import create from 'zustand';

import type { DataEntry, SuccessDataEntry } from 'types/dataEntries';
import type { Dashboard } from 'types/dashboards';

import {
  fetchDashboards,
  createDashboard,
  updateDashboard,
  deleteDashboard,
  fetchDashboard
} from 'services/dashboards';
import { DashboardLibraryEntry } from 'types/dashboards/library';

export interface DashboardsState {
  dashboards: Record<number, DataEntry<Dashboard>>;
  dashboardsLibrary: DataEntry<DashboardLibraryEntry[]>;
}

export interface DashboardsActions {
  getDashboardsLibraryEntry: () => DataEntry<DashboardLibraryEntry[]>;
  getDashboardEntry: (id: number | null) => DataEntry<Dashboard>;
  fetchDashboardsLibrary: () => void;
  createDashboard: (dashboard: Dashboard) => Promise<Dashboard>;
  updateDashboard: (dashboard: Dashboard) => Promise<void>;
  deleteDashboard: (id: number) => Promise<void>;
}

export interface DashboardsStore extends DashboardsState, DashboardsActions {}

const DEFAULT_STATE: DashboardsState = {
  dashboards: {},
  dashboardsLibrary: { status: 'idle' }
};

const NEW_DASHBOARD: SuccessDataEntry<Dashboard> = {
  status: 'success',
  data: {
    name: '',
    widgets: [],
  }
};

export const useDashboardsStore = create<DashboardsStore>((set, get) => ({
  ...DEFAULT_STATE,

  fetchDashboardsLibrary: () => {
    if (get().dashboardsLibrary.status !== 'idle') {
      return;
    }

    set({ dashboardsLibrary: { status: 'loading' }});

    fetchDashboards()
      .then(({ data }) => {
        set({ dashboardsLibrary: { data, status: 'success' }});
      })
      .catch((error: any) => {
        set({ dashboardsLibrary: { error, status: 'error' }});
      });
  },

  getDashboardsLibraryEntry: () => {
    if (get().dashboardsLibrary.status === 'idle') {
      get().fetchDashboardsLibrary();
    }

    return get().dashboardsLibrary;
  },

  getDashboardEntry: (id: number | null) => {
    if (id === null) {
      return NEW_DASHBOARD;
    }

    const { dashboards } = get();

    if (!dashboards[id]) {
      set({
        dashboards: {
          ...dashboards,
          [id]: { status: 'loading' }
        }
      });

      fetchDashboard(id)
        .then(({ data }) => {
          set({
            dashboards: {
              ...dashboards,
              [id]: {
                status: 'success',
                data
              }
            }
          })
        })
        .catch((error) => {
          set({
            dashboards: {
              ...dashboards,
              [id]: {
                status: 'error',
                error
              }
            }
          })
        });
    }

    return get().dashboards[id];
  },

  createDashboard: (dashboard: Dashboard) => {
    return createDashboard(dashboard)
      .then(({ data: newDashboard }) => {
        let { dashboards, dashboardsLibrary } = get();

        const id = newDashboard.id as number;

        dashboards = {
          ...dashboards,
          [id]: {
            status: 'success',
            data: newDashboard
          }
        };

        set({ dashboards });

        if (dashboardsLibrary.status === 'success') {
          set({
            dashboardsLibrary: {
              status: 'success',
              data: [
                ...dashboardsLibrary.data,
                {
                  id: newDashboard.id as number,
                  name: newDashboard.name,
                  team_id: dashboard.team_id
                }
              ]
            }
          });
        }

        return newDashboard;
      });
  },

  updateDashboard: (dashboard: Dashboard) => {
    return updateDashboard(dashboard)
      .then(() => {
        let { dashboards, dashboardsLibrary } = get();

        const id = dashboard.id as number;

        if (dashboards[id]) {
          dashboards = { ...dashboards };

          dashboards[id] = {
            status: 'success',
            data: dashboard
          };

          set({ dashboards });
        }

        if (dashboardsLibrary.status === 'success') {
          set({
            dashboardsLibrary: {
              status: 'success',
              data: dashboardsLibrary.data.map((db) => {
                if (db.id === dashboard.id) {
                  return {
                    ...db,
                    name: dashboard.name,
                    team_id: dashboard.team_id
                  };

                }

                return db;
              })
            }
          });
        }
      });
  },

  deleteDashboard: (id: number) => {
    return deleteDashboard(id)
      .then(() => {
        let { dashboards, dashboardsLibrary } = get();

        if (dashboards[id]) {
          dashboards = { ...dashboards };

          delete dashboards[id];

          set({ dashboards });
        }

        if (dashboardsLibrary.status === 'success') {
          set({
            dashboardsLibrary: {
              status: 'success',
              data: dashboardsLibrary.data.filter((db) => db.id !== id)
            }
          });
        }
      });
  }
}));
