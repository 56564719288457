import type { IconName } from 'shared/Icon';

export const ICONS: Record<string, IconName> = {
  regions: 'region',
  accounts: 'account',
  bill_payer_account_ids: 'account',
  account_tags: 'tag',
  opportunity_tags: 'tag',
  services: 'service',
  namespace: 'service',
  metric_name: 'metric',
  tags: 'tag',
  gcp_services: 'service', 
  gcp_locations: 'region',
  gcp_accounts: 'account',
  gcp_labels: 'tag'
};

export  const getIcon = (field: string): IconName => ICONS[field] || 'arrow-right';

export const SELECT_PLACEHOLDERS: Record<string, string> = {
  regions: 'Select a region',
  availability_zones: 'Select a zone',
  accounts: 'Select an account',
  bill_payer_account_ids: 'Select a payer account',
  account_tags: 'Select account tags',
  opportunity_tags: 'Select resource tags',
  services: 'Select a service',
  usage_types: 'Select a usage type',
  operations: 'Select an operation',
  bill_invoice_ids: 'Select an invoice',
  charge_categories: 'Select a category',
  purchase_options: 'Select a purchase option',
  ri_sp_arns: 'Select an ARN',
  rule_id: 'Select a rule',
  rule_tags: 'Select a rule tag',
  namespace: 'Select a namespace',
  metric_name: 'Select a metric',
  gcp_services: 'Select a service',
  gcp_locations: 'Select a location',
  gcp_accounts: 'Select an account',
  gcp_projects: 'Select a project'
};

export const getSelectPlaceholder = (field: string): string => SELECT_PLACEHOLDERS[field] || `Select a ${field}`;

export const TITLES_PLURAL: Record<string, string> = {
  regions: 'Regions',
  availability_zones: 'Availability Zones',
  accounts: 'Accounts',
  bill_payer_account_ids: 'Payer Accounts',
  account_tags: 'Account Tags',
  opportunity_tags: 'Resource Tags',
  services: 'Services',
  usage_types: 'Usage Types',
  operations: 'Operations',
  bill_invoice_ids: 'Invoice IDs',
  charge_categories: 'Charge Categories',
  purchase_options: 'Purchase Options',
  ri_sp_arns: 'RI / SP Arns',
  rule_id: 'Filter Rules',
  rule_tags: 'Rule Tags',
  namespace: 'Namespaces',
  metric_name: 'Metrics',
  tags: 'Resource Tags',
  gcp_services: 'Services',
  gcp_locations: 'Locations',
  gcp_accounts: 'Accounts',
  gcp_projects: 'Projects',
  gcp_labels: 'Labels'
};

export const getTitlePlural = (field: string): string => TITLES_PLURAL[field] || field.replace('_', ' ');

export const TITLES_SINGLE : Record<string, string> = {
  regions: 'region',
  availability_zones: 'availability zone',
  accounts: 'account',
  bill_payer_account_ids: 'payer account',
  account_tags: 'account tags',
  opportunity_tags: 'tags',
  services: 'service',
  usage_types: 'usage type',
  operations: 'operation',
  bill_invoice_ids: 'invoice id',
  charge_categories: 'charge category',
  purchase_options: 'purchase option',
  ri_sp_arns: 'ri / sp arn',
  rule_id: 'rule',
  rule_tags: 'rule tag',
  namespace: 'namespace',
  metric_name: 'metric',
  gcp_services: 'service',
  gcp_locations: 'location',
  gcp_accounts: 'account',
  gcp_projects: 'project',
  gcp_labels: 'label'
};

export const getTitleSingle = (field: string): string => TITLES_SINGLE[field] || field.replace('_', ' ');

export const getInputPlaceholder = (field: string): string => `LIKE clause to filter ${getTitlePlural(field).toLowerCase()}`;

export const SOURCE_TITLES: Record<string, string> = {
  cur: 'AWS Costs',
  custom_data: 'Custom Data',
  gcp: 'GCP Costs'
}

export const getSourceTitle = (src: string) => SOURCE_TITLES[src] || src;
