import type { Tags } from 'types/tags';
import { useState } from 'react';
import { Page } from 'shared/Page';
import { Button } from 'shared/Button';
import { Section } from 'shared/Section';
import { Loading } from 'shared/Loading';
import { SyncButton } from './SyncButton';
import { CatalogTable } from './CatalogTable';
import { useTagCatalogsStore } from 'store/tagCatalogs';
import { CreateCatalogDialog } from './CreateCatalogDialog';
import {TagCatalog} from 'types/tags';

export const TagCatalogs = () => {
  const tagCatalogs = useTagCatalogsStore((store) => store.getEntries());
  const newCatalog = useTagCatalogsStore((store) => store.getNewEntry());
  const setCreateEntry = useTagCatalogsStore((store) => store.setCreateEntry);
  const [createDialogOpened, setCreateDialogOpened] = useState(false);

  const cloneTagCatalog = (catalog: TagCatalog) => {
    setCreateEntry({
      ...newCatalog,
      draft: {
        name: '',
        catalog_key: '',
        tags: catalog.tags
      }
    });

    setCreateDialogOpened(true);
  };
  
  return (
    <Page>
      <Page.Head title='Tag Catalogs'>
        <div className='flex gap-[10px]'>
          <SyncButton />
          <Button
            size='sm'
            type='secondary'
            label='Create API Key for Tag Assistant'
            to='/settings/api-keys'
          />
          {tagCatalogs.status === 'success' && (
            <Button
              icon='circle-plus'
              size='sm'
              type='primary'
              style='caladon'
              label='Create New Catalog'
              onClick={() => {
                setCreateDialogOpened(true);
              }}
            />
          )}
        </div>
      </Page.Head>

      <Section className='relative min-h-[240px] p-[20px]'>
        {tagCatalogs.status === 'loading' && (
          <Loading />
        )}
        {tagCatalogs.status === 'error' && (
          <h2>Error</h2>
        )}
        {tagCatalogs.status === 'success' && (
          <CatalogTable
            catalogs={tagCatalogs.data}
            onClone={cloneTagCatalog}
          />
        )}
      </Section>

      <CreateCatalogDialog
        opened={createDialogOpened}
        onClose={() => { setCreateDialogOpened(false); }}
      />
    </Page>
  );
};
