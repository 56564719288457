import type { CostView } from 'types/costViews';
import type { ListEntry } from 'types/dataEntries';
import type { GeneralMember } from 'types/teams';

import create from 'zustand';
import { Map as ImmutableMap } from 'immutable';
import { fetchCostViews } from 'services/costViews';
import {
  fetchCostViewMembers,
  addCostView,
  removeCostView,
} from 'services/teams';

type MemberData = GeneralMember;
type TeamCostViewEntry = ListEntry<MemberData>;

interface TeamCostViewsIdle { status: 'idle' }
interface TeamCostViewsLoading { status: 'loading' }
interface TeamCostViewsLoadError { status: 'load-error'; error: any; }
interface TeamCostViewsLoaded {
  status: 'loaded';
  data: ImmutableMap<number, TeamCostViewEntry>;
}

type TeamCostViewsEntry = TeamCostViewsIdle | TeamCostViewsLoading | TeamCostViewsLoadError | TeamCostViewsLoaded;

type CostViewEntry = 
  { status: 'idle' } |
  { status: 'loading' } |
  { status: 'load-error'; error: any } |
  {
    status: 'loaded',
    data: CostView[]
  }

export interface TeamCostViewsState {
  allCostViews: CostViewEntry;
  entries: ImmutableMap<number, TeamCostViewsEntry>;
}

export interface TeamCostViewsActions {
  getAllCostViews: () => CostViewEntry;
  getEntry: (teamId: number) => TeamCostViewsEntry;
  setTeamCostView: (teamId: number, memberId: number, memberEntry: TeamCostViewEntry) => void;
  addTeamCostView: (teamId: number, memberId: number, memberData: MemberData) => void;
  confirmAddition: (teamId: number, memberId: number) => void;
  removeTeamCostView: (teamId: number, memberId: number) => void;
  confirmRemoval: (teamId: number, memberId: number) => void;
}

export interface TeamCostViewsStore extends TeamCostViewsState, TeamCostViewsActions {}

const DEFAULT_STATE: TeamCostViewsState = {
  allCostViews: { status: 'idle' },
  entries: ImmutableMap()
};

export const useTeamCostViewsStore = create<TeamCostViewsStore>((set, get) => ({
  ...DEFAULT_STATE,

  getAllCostViews: () => {
    let { allCostViews } = get();

    if (allCostViews.status === 'idle') {
      allCostViews = { status: 'loading' };

      set({ allCostViews });

      fetchCostViews()
        .then(({ data }) => {
          set({
            allCostViews: {
              status: 'loaded',
              data
            }
          });
        })
        .catch((error: any) => {
          set({
            allCostViews: {
              status: 'load-error',
              error
            }
          });
        });
    }

    return allCostViews;
  },

  setTeamCostView: (teamId: number, memberId: number, member: TeamCostViewEntry) => {
    const { entries } = get();

    const team = entries.get(teamId);

    if (!team || team.status !== 'loaded') {
      return;
    }

    set({
      entries: entries.set(teamId, {
        ...team,
        data: team.data.set(memberId, member)
      })
    });
  },

  getEntry: (teamId: number) => {
    let { entries } = get();
    
    let members = entries.get(teamId);

    if (members) {
      return members;
    }
    
    members = { status: 'loading' };
    entries = entries.set(teamId, members);
    set({ entries });

    fetchCostViewMembers(teamId)
      .then(({ data }) => {
        entries = get().entries;
        
        const memberEntries = data.reduce(
          (map: ImmutableMap<number, TeamCostViewEntry>, member) => map.set(member.id, {
            status: 'loaded',
            data: member
          }),
          ImmutableMap() as ImmutableMap<number, TeamCostViewEntry>
        );

        entries = entries.set(teamId, {
          status: 'loaded',
          data: memberEntries
        });

        set({ entries });
      })
      .catch((error: any) => {
        entries = get().entries;
        entries.set(teamId, {
          status: 'load-error',
          error
        });
      });

    return members;
  },

  removeTeamCostView(teamId: number, memberId: number) {
    let {
      getEntry,
      setTeamCostView
    } = get();

    let team = getEntry(teamId);

    if (team.status !== 'loaded') {
      return;
    }

    const member = team.data.get(memberId);

    if (!member || member.status !== 'loaded') {
      return;
    }
    
    const deletingMember: TeamCostViewEntry = {
      data: member.data,
      status: 'removing'
    };
    
    setTeamCostView(teamId, memberId, deletingMember);

    removeCostView(teamId, memberId)
      .then(() => {
        const deletedMember: TeamCostViewEntry = {
          ...deletingMember,
          status: 'removed'
        };

        setTeamCostView(teamId, memberId, deletedMember);
      })
      .catch((error) => {
        const deleteErrorMember: TeamCostViewEntry = {
          ...deletingMember,
          status: 'remove-error',
          error
        };

        setTeamCostView(teamId, memberId, deleteErrorMember);
      });
  },

  confirmRemoval: (teamId: number, memberId: number) => {
    let { entries } = get();

    let team = entries.get(teamId);

    if (!team || team.status === 'idle' || team.status === 'loading' || team.status === 'load-error') {
      return;
    }

    team = {
      ...team,
      data: team.data.delete(memberId)
    };

    set({
      entries: entries.set(teamId, team)
    });
  },

  addTeamCostView: (teamId: number, memberId: number, memberData: MemberData) => {
    const {
      entries,
      setTeamCostView
    } = get();

    const team = entries.get(teamId);
    
    if (!team || team.status !== 'loaded') {
      return;
    }

    const oldTeamKey = entries.findKey((team) => {
      if (team.status !== 'loaded') {
        return false;
      }

      return team.data.has(memberId);
    });

    if (oldTeamKey) {
      set({ 
        entries: entries.remove(memberId)
      });
    }

    const addingMember: TeamCostViewEntry = {
      status: 'adding',
      data: memberData
    };

    setTeamCostView(teamId, memberId, addingMember);

    addCostView(teamId, memberId)
      .then(() => {
        const addedMember: TeamCostViewEntry = {
          status: 'added',
          data: memberData
        };

        setTeamCostView(teamId, memberId, addedMember);
      })
      .catch((error: any) => {
        const addErrorMember: TeamCostViewEntry = {
          status: 'add-error',
          error,
          data: memberData
        };

        setTeamCostView(teamId, memberId, addErrorMember);
      });
  },

  confirmAddition: (teamId: number, memberId: number) => {
    let { entries } = get();

    let team = entries.get(teamId);

    if (!team || team.status === 'idle' || team.status === 'loading' || team.status === 'load-error') {
      return;
    }

    let member = team.data.get(memberId);

    if (!member) {
      return;
    }

    team = {
      ...team,
      data: team.data.set(memberId, {
        status: 'loaded',
        data: member.data
      })
    };

    set({
      entries: entries.set(teamId, team)
    });
  }
}));
