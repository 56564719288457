import { isBoolThresholdConfig, isNumberThresholdConfig, ThresholdConfig } from 'types/dataCollection';
import { BoolThresholdEditor } from './BoolThresholdEditor';
import {NumberThresholdEditor} from './NumberThresholdEditor';

export interface ThresholdEditorProps {
  config: ThresholdConfig;
  disabled?: boolean;
  onChange: (config: ThresholdConfig) => void;
}

export const ThresholdEditor = (props: ThresholdEditorProps) => {
  const {
    config,
    disabled,
    onChange
  } = props;

  return isBoolThresholdConfig(config) ?
    <BoolThresholdEditor
      config={config}
      disabled={disabled}
      onChange={onChange}
    /> : isNumberThresholdConfig(config) ?
    <NumberThresholdEditor
      config={config}
      disabled={disabled}
      onChange={onChange}
    /> : null;
  ;
}
