import { Dialog } from 'shared/Dialog';
import { Button } from 'shared/Button';
import { getSourceTitle } from 'shared/Filters';

export interface ConfirmSrcChangeDialogProps {
  src: string | null;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmSrcChangeDialog = (props: ConfirmSrcChangeDialogProps) => {
  const {
    src,
    onConfirm,
    onCancel
  } = props;

  const opened = src !== null;
  const srcTitle = src && getSourceTitle(src);

  return (
    <Dialog
      open={opened}
      onClose={onCancel}
      className='w-[420px] pt-[40px] px-[30px] pb-[30px]'
    >
      <p className='font-medium text-center text-subtitle-1'>
        Current rule will be overwritten if you change source to <b>{srcTitle}</b>. Are you sure you want to switch?
      </p>
      <Button
        className='justify-center mt-[30px]'
        style='caladon'
        label={`Yes, switch to ${srcTitle}`}
        onClick={onConfirm}
      />
      <Button
        className='justify-center mt-[15px]'
        type='secondary'
        label='Cancel'
        onClick={onCancel}
      />
    </Dialog>
  );
}
