import { Section } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { ACTIVE_OPPORTUNITY_STATUSES } from 'helpers/savings';
import { formatCurrency, pluralize } from 'helpers/formatter';
import { useCostImpactSummary } from 'store/savings/costImpactSummary';
import { usePageFilters } from '../hooks/pageFilters';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { identity } from 'helpers/utils';

export interface TotalActiveSavingsSectionProps {
  className?: string;
}

export const TotalActiveSavingsSection = (props: TotalActiveSavingsSectionProps) => {
  const {
    className = ''
  } = props;

  const pageFilters = usePageFilters();

  const params = useDerivedDataEntry({
    prefilter: {
      status: ACTIVE_OPPORTUNITY_STATUSES,
    },
    pageFilters,
    tableFilters: {},
  }, identity);

  const costImpactSummary = useCostImpactSummary(params);

  return (
    <Section className={`relative p-5 ${className}`}>
      <h3 className='font-medium'>Total Active Savings / Mo</h3>

      {costImpactSummary.status === 'success' && costImpactSummary.data.length > 0 && (
        <>
          <h3 className='text-large-title font-medium my-2.5'>
            {formatCurrency(costImpactSummary.data[0].cost_impact_sum * 30)}
            {' '}
            <span className='font-normal text-body whitespace-nowrap'>
              / month
            </span>
          </h3>

          <div className='text-caption-1'>
            <b>{costImpactSummary.data[0].opportunities_count}</b>
            {' active '}
            {pluralize(costImpactSummary.data[0].opportunities_count, { one: 'opportunity', other: 'opportunities' })}
          </div>
        </>
      )}

      <StatusOverlay status={costImpactSummary.status} />
    </Section>
  );
};
