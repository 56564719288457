import type { Granularity } from 'types/common';
import type { CostViewFilter } from 'types/costViews/filters';
import type { DrillDownStep, DrillDownField } from 'types/costViews/drillDown';

import { useMemo } from 'react';
import { Select } from 'antd';
import { FIELD_TITLES } from '../groupByOptions';
import { Icon } from 'shared/Icon/Icon';
import { useCostFilterValue } from 'hooks/costFilter';
import { useCostViewDrillDown } from 'store/costViewDrillDown';

export interface DrilldownStepProps {
  startDate: string;
  endDate: string;
  granularity: Granularity;
  filter: CostViewFilter;
  drillDownSteps: DrillDownStep[];
  field: DrillDownField;
  value: string | null;
  fieldOptions: DrillDownField[];
  onFieldChange: (field: DrillDownField) => void;
  onValueChange: (value: string) => void;
}


export const DrilldownStep = (props: DrilldownStepProps) => {
  const {
    startDate,
    endDate,
    granularity,
    filter,
    drillDownSteps,
    field,
    fieldOptions,
    value,
    onFieldChange,
    onValueChange
  } = props;

  const costFilterValue = useCostFilterValue();

  const drillDown = useCostViewDrillDown({
    ...costFilterValue,
    startDate,
    endDate,
    granularity,
    filter,
    breakdown: field,
    drillDownSteps
  });

  const valueOptions = useMemo(() => {
    if (drillDown.status !== 'success') {
      return value ?
        [{ label: value, value }] :
        [];
    }

    const valuesSet = new Set<string | undefined>(drillDown.data.table_data.map((row) => row[field]));
    
    return Array.from(valuesSet).map((value) => ({
      value,
      label: value !== undefined && drillDown.data.alias_map[value] || value || 'Undefined'
    }));
  }, [drillDown, field]);

  return (
    <div className="flex flex-col">
        <div className='my-[10px]'>
          <div className="flex flex-col h-[100px] px-[15px] py-[12px] border border-grey-transparent bg-white rounded">
          <div className="flex mb-[11px] items-center">
            {`where`}
            {<Icon className="mx-[5px]" icon={'arrow-right'} />}
            <Select
              value={field}
              options={fieldOptions.map((key) => ({ value, label: FIELD_TITLES[key] }))}
              disabled={fieldOptions.length <= 1}
              className="w-1/2"
              showSearch
              size='small'
              onChange={onFieldChange}
            />
            <div className="h-[24px] flex items-center mx-[8px] px-[8px] rounded bg-silver-grey">
              {'is'}
            </div>
          </div>
          <Select
            value={value}
            options={valueOptions}
            placeholder='Choose a dimension'
            showSearch
            disabled={!!value && valueOptions.length <= 1}
            onChange={onValueChange}
          />
        </div>
      </div>
    </div>
  )
}
