import { createKeyedStore } from './createKeyedStore';
import { createKeyedStoreSelector } from './createKeyedStoreSelector';
import { fetchJiraWebhook } from 'services/savings';

export const useJiraWebhookStore = createKeyedStore({
  fetchData: fetchJiraWebhook,
  paramsToKey: (provider: 'jira_cloud' | 'jira_server') => provider
});

export const useJiraWebhook = createKeyedStoreSelector(useJiraWebhookStore);
