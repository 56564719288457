import type { SortDir, QueryParamParser, QueryParamSerializer } from 'types/common';
import type { ThreadSortField } from 'types/savings';

import { isSortDir } from 'types/common';
import { DEFAULT_THREAD_SORT_DIRS } from 'types/savings';

import {
  DEFAULT_THREAD_SORT_FIELD, 
  isThreadSortField,
} from 'types/savings';

export const parseThreadSortField = (param: string | null): ThreadSortField => isThreadSortField(param) ? param : DEFAULT_THREAD_SORT_FIELD;
export const serializeThreadSortField = (field: ThreadSortField) => field === DEFAULT_THREAD_SORT_FIELD ? null : field;

const THREAD_SORT_DIR_PARSERS: Record<SortDir, QueryParamParser<SortDir>> = {
  ascend: (param) => isSortDir(param) ? param : 'ascend',
  descend: (param) => isSortDir(param) ? param : 'descend',
};

const THREAD_SORT_DIR_SERIALIZERS: Record<SortDir, QueryParamSerializer<SortDir>> = {
  ascend: (value) => value === 'ascend' ? null : value,
  descend: (value) => value === 'descend' ? null : value
};

export const getThreadSortDirParser = (field: ThreadSortField) => THREAD_SORT_DIR_PARSERS[DEFAULT_THREAD_SORT_DIRS[field]];
export const getThreadSortDirSerializer = (field: ThreadSortField) => THREAD_SORT_DIR_SERIALIZERS[DEFAULT_THREAD_SORT_DIRS[field]];
