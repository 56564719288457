import type { ReactElement, ChangeEvent } from 'react';
import { Children, cloneElement } from 'react';
import type { RadioButtonTheme, RadioButtonProps } from './RadioButton';

export interface RadioButtonGroupProps<TValue extends string = string> {
  name: string;
  value: TValue;
  onChange?: (event: ChangeEvent<HTMLInputElement>, params: { name: string, value: TValue }) => void;
  children?: ReactElement<RadioButtonProps<TValue>> | ReactElement<RadioButtonProps<TValue>>[];
}

export const RadioButtonGroup = <TValue extends string = string>(props: RadioButtonGroupProps<TValue>) => {
  const {
    name,
    value,
    children,
    onChange
  } = props;

  return (
    <div className='h-control-s p-[3px] rounded-[8px] bg-gray-100 inline-flex grow-0'>
      {Children.map(
        children,
        (child) => child && cloneElement(child, {
          key: child.props.value,
          name,
          checked: child.props.value === value,
          onChange: (event: ChangeEvent<HTMLInputElement>, params: { value: TValue }) => {
            onChange && onChange(event, {
              name,
              value: params.value
            });
          }
        })
      )}
    </div>
  );
}
