import { ReactNode } from 'react'
import { ClassNameProps } from 'props'

export interface ValueBoxProps extends ClassNameProps {
  // [TODO] Weird eslint error
  // eslint-disable-next-line react/no-unused-prop-types
  children?: ReactNode;
  label?: string;
  align?: 'center' | 'left'
}

const ValueBox = ({
  label,
  align,
  children,
  className,
}: ValueBoxProps) => (
  <div className={`flex-col ${className}`}>
    <div className='font-normal text-gray-500 text-caption-2 whitespace-nowrap'>{label}</div>
    {children}
  </div>
)

const Text = ({ children }: { children: ReactNode }) => (
  <div className='flex font-normal text-subtitle-1'>{children}</div>
)

const Sub = ({ children }: { children: ReactNode }) => (
  <div className=''>{children}</div>
)

const Badge = ({ children }: { children: ReactNode }) => (
  <div
    className='flex items-center content-center px-2 py-1 ml-2 text-xs rounded bg-silver-grey text-silver-grey-900'
  >
    {children}
  </div>
)

const SimpleValueBox = ({
  label,
  align,
  text,
  badge,
  className,
}: ValueBoxProps & { text: string | number, badge?: string }) => (
  <ValueBox label={label} align={align} className={className}>
    <Text>
      {text}
      {badge && <Badge>{badge}</Badge>}
    </Text>
  </ValueBox>
)

export default Object.assign(ValueBox, {
  Text,
  Sub,
  Badge,
  SimpleValueBox,
})
