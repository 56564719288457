import type { Setter } from 'types/common';
//import type { DataEntry } from 'types/dataEntries';

import type {
  OpportunityFilterValue,
  //OpportunityFilterOptions,
} from 'types/savings';

import { identity } from 'helpers/utils';
import { EMPTY_OPPORTUNITY_FILTER } from 'helpers/savings';

import {
  useQueryParam,
  useListQueryParam,
  //useRangeQueryParam
} from 'hooks/queryParams';
import { useSearchParams } from 'react-router-dom';

const listFields: (keyof OpportunityFilterValue)[] = [
  'difficulty',
  //'group',
  'category',
  'account',
  'region',
  'service',
  'tags',
  'account_tags',
  //'platform'
];

export const useOpportunityFiltersQuery = (/*options: DataEntry<OpportunityFilterOptions>*/): [OpportunityFilterValue, Setter<OpportunityFilterValue>] => {
  /*
  const [costImpact, setCostImpact] = useRangeQueryParam({
    name: 'costImpact',
    parse: (str: string) => isNaN(+str) ? null : +str,
    serialize: (num: number | null) => `${num}`
  }, options.status === 'success' ? options.data?.costImpact : undefined);

  const [priority, setPriority] = useRangeQueryParam({
    name: 'priority',
    parse: (str: string) => isNaN(+str) ? null : +str,
    serialize: (num: number | null) => `${num}`
  }, options.status === 'success' ? options.data?.priority : undefined);
  */

  const [text_search, setText] = useQueryParam({
    name: 'q',
    parse: (param: string | null) => param || '',
    serialize: identity
  });

  const filters: OpportunityFilterValue = {
    ...EMPTY_OPPORTUNITY_FILTER,
    text_search,
    /*
    costImpact,
    priority
    */
  };

  const setters: any = {
    text_search: setText,
    //costImpact: setCostImpact,
    //priority: setPriority
  };

  listFields.forEach((field) => {
    const [value, setValue] = useListQueryParam({
      name: field as string,
      parse: identity<string>,
      serialize: identity<string>,
    });

    filters[field] = value as any;
    setters[field] = setValue;
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const setFilters = (newFilters: OpportunityFilterValue) => {
    const newParams = new URLSearchParams(searchParams);

    Object.entries(newFilters).forEach(([field, value]) => {
      if (value !== filters[field as keyof OpportunityFilterValue] && setters[field]) {
        setters[field](value, newParams);
      }
    });

    setSearchParams(newParams);
  }

  return [filters, setFilters];
}
