import type { 
  JiraIssueCreatePayload,
  JiraProject,
  JiraUser,
} from 'types/savings';
import type { DataEntry } from 'types/dataEntries';
import type { KeyedStore } from 'types/stores';
import { Input } from 'antd';
import { JiraProjectSelect } from './JiraProjectSelect';
import { JiraIssueTypeSelect } from './JiraIssueTypeSelect';
import { JiraUserSelect } from './JiraUserSelect';

export interface JiraIssueFormProps {
  value: JiraIssueCreatePayload;
  onChange: (value: JiraIssueCreatePayload) => void;
  projectStore: () => DataEntry<JiraProject[], any>;
  userStore: KeyedStore<string, string, JiraUser[]>;
  provider: 'jira_cloud' | 'jira_server';
}

export const JiraIssueForm = (props: JiraIssueFormProps) => {
  const {
    value,
    onChange,
    projectStore,
    userStore,
    provider,
  } = props;


  return (
    <div>
      <div className='mb-1 font-medium'>
        Project
      </div>
      <JiraProjectSelect
        key={provider}
        store={projectStore}
        value={value.jira_project_key}
        onChange={(jira_project_key) => {
          onChange({
            ...value,
            jira_project_key
          });
        }}
      />

      <div className='mt-4 mb-1 font-medium'>
        Issue Type
      </div>
      <JiraIssueTypeSelect
        key={provider}
        store={projectStore}
        projectKey={value.jira_project_key}
        value={value.jira_issue_type}
        onChange={(jira_issue_type) => {
          onChange({
            ...value,
            jira_issue_type
          });
        }}
      />

      <div className='mt-4 mb-1 font-medium'>
        Assignee
      </div>
      <JiraUserSelect
        key={provider}
        store={userStore}
        projectKey={value.jira_project_key}
        value={value.jira_assignee_id || ''}
        onChange={(jira_assignee_id, name) => {
          onChange({
            ...value,
            jira_assignee_id,
            name,
          });
        }}
      />

      <div className='mt-4 mb-1 font-medium'>
        Description
      </div>
      <Input.TextArea
        value={value.jira_description || ''}
        onChange={(event) => {
          const jira_description = event.target.value;

          onChange({
            ...value,
            jira_description
          });
        }}
      />
    </div>
  );
};
