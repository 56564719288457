import { useCostViewConstructor } from 'store/costViewConstructor';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { parseGlobalFilterConstructor } from 'helpers/globalFilters';

export const useGlobalFilterConstructor = () => {
  const costViewConstructor = useCostViewConstructor(); 

  return useDerivedDataEntry(
    { costViewConstructor },
    ({ costViewConstructor }) => parseGlobalFilterConstructor(costViewConstructor)
  );
};
