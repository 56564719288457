import { Icon } from 'shared/Icon';
import { Overlay } from 'shared/Overlay';

/**
 * @deprecated in favor of StatusOverlay
 */

export const Loading = () => (
  <Overlay>
    <Icon className='w-[40px] h-[40px] text-cyanine-blue' icon='spinner' spin />
  </Overlay>
);
