import type { Opportunity } from 'types/savings';

import { message } from 'antd';
import { Button2 as Button } from 'shared/Button';
import { useSavingsStore } from 'store/savings';

export interface RestoreActionButtonProps {
  opportunity: Opportunity;
}

export const RestoreActionButton = (props: RestoreActionButtonProps) => {
  const {
    opportunity
  } = props;

  const opportunityActions = useSavingsStore((store) => store.opportunityActions);
  const restoreOpportunity = useSavingsStore((store) => store.restoreOpportunity);

  return (
    <Button
      size='s'
      theme='gray'
      layout='inline'
      icon='undo'
      disabled={!!opportunityActions.get(opportunity.id)}
      onClick={() => {
        restoreOpportunity(opportunity.id)
          .then(() => {
            message.success(`OPP-${opportunity.secondary_id} restored`);
          })
          .catch(() => {
            message.error(`Unable to restore OPP-${opportunity.secondary_id}`);
          });
      }}
    />
  );
}
