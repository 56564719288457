import { Input } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button } from 'shared/Button';
import FormField from 'shared/Form/FormField';
import { useGlobalState } from 'state/globalState';

export interface SaveAsNewDialogProps {
  name: string;
  metricIsNew: boolean;
  nameHasChanged: boolean;
  onNameChange: (name: string) => void;
  opened: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const SaveAsNewDialog = (props: SaveAsNewDialogProps) => {
  const logEvent = useGlobalState((state) => state.logEvent);
  const {
    name,
    metricIsNew,
    nameHasChanged,
    onNameChange,
    opened,
    onClose,
    onConfirm
  } = props;

  return (
    <Dialog className='w-[420px]' open={opened} onClose={onClose}>
      <Dialog.Title>Save as New Unit Metric</Dialog.Title>

      <Dialog.Body className='flex flex-col'>
        <FormField.SimpleField label='View Name'>
          <Input
            size='large'
            value={name}
            onFocus={() => {
              logEvent('change_metric_name', { source: 'dialog' });
            }}
            onChange={(e) => { onNameChange(e.target.value) }}
          />
        </FormField.SimpleField>
        <Button className='justify-center mt-[33px]' disabled={!name || !metricIsNew && !nameHasChanged} style='caladon' label='Save' onClick={onConfirm} />
      </Dialog.Body>
    </Dialog>
  );
};
