import type { Thread, ThreadStatus } from 'types/savings';

import moment from 'moment';
import { useMemo } from 'react';
import { createDatesComparator, createNumbersComparator, createStringsComparator } from 'helpers/sort';
import { THREAD_STATUS_ORDER, THREAD_STATUS_TEXTS } from './constants';

export const renderThreadSecondaryId = (thread: Thread) => `TRD-${thread.secondary_id}`;

export const compareThreadNames = createStringsComparator((thread: Thread) => thread.name);
export const compareThreadSecondaryIds = createNumbersComparator((thread: Thread) => thread.secondary_id);
export const compareThreadOpporutnityCounts = createNumbersComparator((thread: Thread) => thread.opportunities_count ?? 0);
export const compareThreadSavings = createNumbersComparator((thread: Thread) => thread.cost_impact_sum);

export const compareThreadStatuses = createNumbersComparator((thread: Thread) => THREAD_STATUS_ORDER[thread.status]);

export const useThreadStatusFilters = (threads: Thread[]) => useMemo(() => {
  const statusesPresent = threads.reduce((set, thread) => set.add(thread.status), new Set<ThreadStatus>());
  
  return Array.from(statusesPresent.values())
    .sort((statusA, statusB) => THREAD_STATUS_ORDER[statusA] - THREAD_STATUS_ORDER[statusB])
    .map((status) => ({
      value: status,
      text: THREAD_STATUS_TEXTS[status]
    }));
    
}, [threads]);

export const onThreadStatusFilter = (status: string | number | boolean, thread: Thread) => thread.status === status;
export const pickThreadCreatedAt = ({ created_at }: Thread) => created_at ? moment(created_at) : null;
export const compareThreadCreatedAt = createDatesComparator(pickThreadCreatedAt);
