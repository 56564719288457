import { createKeyedStore } from 'store/createKeyedStore';
import { createKeyedStoreSelector } from 'store/createKeyedStoreSelector';
import { fetchJiraUsers } from 'services/savings'
import { identity } from 'helpers/utils';

export const useJiraCloudUsersStore = createKeyedStore({
  fetchData: (jira_project_key: string) => fetchJiraUsers('jira_cloud', jira_project_key),
  paramsToKey: identity
});

export const useJiraCloudUsers = createKeyedStoreSelector(useJiraCloudUsersStore);
