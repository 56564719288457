import type { Thread } from 'types/savings';
import { useState } from 'react';
import { Button2 as Button } from 'shared/Button';
import { Dialog } from 'shared/Dialog';
import { ThreadsTable } from '../content/ThreadsTable';
import { useSavingsStore, useThreadSelectDialog } from 'store/savings';
import { message } from 'antd';

export const ThreadSelectDialog = () => {
  const { id: opportunityIds, close } = useThreadSelectDialog();
  const assignOpportunity = useSavingsStore((store) => store.assignOpportunity);

  const [assigning, setAssigning] = useState(false);
  const [threadId, setThreadId] = useState<number | null>(null);

  return (
    <Dialog
      className='max-w-[1280px]'
      open={!!opportunityIds}
      onClose={close}
    >
      <Dialog.Title>
        Select a Thread
      </Dialog.Title>

      <div className='px-8'>
        <ThreadsTable
          title={'Threads'}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: threadId ? [threadId] : [],
            onChange: ([newThreadId]) => {
              setThreadId(+newThreadId || null);
            },
          }}
        />
      </div>

      <div className='flex justify-end px-8 py-4'>
        <Button
          size='m'
          theme='filled'
          disabled={assigning || threadId === null}
          onClick={() => {
            if (!opportunityIds) {
              return;
            }
            setAssigning(true);
            Promise.all(
              opportunityIds.map((opportunityId) => assignOpportunity(opportunityId, threadId as number))
            )
              .then(() => {
                close();
                setThreadId(null);
                message.success('Opportunities assigned');
              })
              .catch(() => {
                message.error('Unable to assign opportunities');
              })
              .finally(() => {
                setAssigning(false);
              });
          }}
        >
          Assign
        </Button>
      </div>
    </Dialog>
  );
};
