import type { Usage } from 'types/savings';
import { useMemo } from 'react';
import { Section, SectionTitle } from 'shared/Section';
import { PeriodChart } from 'shared/PeriodChart';
import { formatNormal } from 'helpers/formatter';

export interface UsageChartSectionProps {
  usage: Usage;
  className?: string;
}

export const UsageChartSection = (props: UsageChartSectionProps) => {
  const {
    usage,
    className = ''
  } = props;

  const data = useMemo(() =>
    usage.data
      .map(([date, current]) => ({
        date,
        current,
      } as {
        date: string,
        current: number | null,
          previous: number | null
      }))
      .sort((a, b) => a.date > b.date ? 1 : -1),
    [usage]
  );

  return (
    <Section className={`p-5 ${className}`}>
      <SectionTitle>
        {usage.label}
      </SectionTitle>

      <PeriodChart
        granularity='day'
        currentKey='current'
        previousKey='previous'
        currentName={usage.label}
        formatter={({ value }) => formatNormal(value)}
        data={data}
      />
    </Section>
  );
}
