import create from 'zustand'

import type { FetchStatus } from 'types/common';
import type { Alert } from 'types/alerts';

import { getAlerts } from 'services/alert'

interface FetchParams {
  force?: boolean;
}

export interface AlertsState {
  status: FetchStatus;
  alerts: Alert[];
}

export interface AlertActions {
  fetchAlerts: (metricType: string, id: number, params?: FetchParams) => void;
}

export interface AlertStore extends AlertsState, AlertActions {}

const DEFAULT_STATE: AlertsState = {
  status: 'idle',
  alerts: [],
};

export const useAlertStore = create<AlertStore>((set, get) => {
  let requestCount = 0;

  return {
    ...DEFAULT_STATE,

    fetchAlerts: async (metricType: string, id: number, params = {}) => {
      const { force } = params;
      const { status } = get();

      /*
      if (!force && ['loading', 'success'].includes(status)) {
        return;
      }
      */

      try {
        if (status !== 'success') {
          set({ status: 'loading' });
        }

        const requestNumber = ++requestCount;
        
        const { data: alertObjects } = await getAlerts(metricType, id);
        const alerts = alertObjects.filter((data: any) =>  data.execution_details.last_period_value !== null).map((data: any) => {
          const newAlert: Alert = {
            id: data.id,
            alert_config_id: data.alert_config_id,
            executed_at: data.executed_at,
            is_violated: data.execution_details.is_violated,
            last_period_value: data.execution_details.last_period_value,
            prelast_period_value: data.execution_details.prelast_period_value,
            values_diff: data.execution_details.values_diff,
            created_at: data.created_at,
          }
          return newAlert
        })
        if (requestNumber === requestCount) {
          set({
            alerts,
            status: 'success'
          });
        }
      } catch(error) {
        set({ status: 'error' });
      }
    },
  };
});
