import type { UserUpdatePayload } from 'types/users';
import { useState } from 'react';
import { Input } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button } from 'shared/Button';
import { useGlobalState } from 'state/globalState';

export interface UpdateUserDialogProps {
  name: string;
  opened: boolean;
  close: () => void;
}

export const UpdateUserDialog = (props: UpdateUserDialogProps) => {
  const updateUser = useGlobalState((store) => store.updateUser); 

  const {
    name, 
    opened,
    close
  } = props;
  
  const [currentDraft, setCurrentDraft] = useState<UserUpdatePayload>({ 'name': name })
  const canSave = currentDraft.name !== '' && currentDraft.name !== name

  return (
    <Dialog
      className='w-[480px]'
      open={opened}
      onClose={() => {
        close();
      }}
    >
      <Dialog.Title>
        Update User
      </Dialog.Title>

      <div className='overflow-x-auto px-[30px] border-t'>
        <div className='mt-4 mb-2 font-medium text-subtitle-1'>
          User Name
        </div>
        <Input
          className='mb-[20px]'
          value={currentDraft.name}
          onChange={(event) => {
            const name = event.target.value;

            setCurrentDraft({
              ...currentDraft,
              name,
            });
          }}
        />
      </div>

      <div className='flex justify-end p-[15px] border-t'>
        <Button
          style='caladon'
          label='Update User'
          disabled={!canSave}
          onClick={() => {
            updateUser(currentDraft);
            close();
          }}
        />
      </div>
    </Dialog>
  );
};
