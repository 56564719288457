import type { TeamDraft, TeamPayload, TeamDeps } from 'types/teams';
import { serializeTeamFilterMap } from './filters';


export const serializeTeamDraft = (draft: TeamDraft, deps: TeamDeps): TeamPayload => {
  const {
    filters,
    ...rest
  } = draft;

  return {
    ...serializeTeamFilterMap(filters, deps.teamFilterConstructor),
    ...rest
  };
}
