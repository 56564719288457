import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { message } from 'antd'
import SpashPage from 'shared/Page/SplashPage'
import { ReactComponent as LogoLines } from 'assets/images/LogoLines.svg'
import { Section } from 'shared/Section'
import { threadCallback } from 'services/savings'


export const ThreadCallback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const validateToken = async () => {
    try {
      const token = searchParams.get('token')
      const action = searchParams.get('action')
      const issueType = searchParams.get('issueType')
      if (!token || !action || !issueType) {
        navigate('/')
        return
      }

      await threadCallback(issueType, action, token)

      navigate('/savings/threads')
    } catch (err) {
      message.error('Thread Callback Error');
      navigate('/')
    }
  }

  useEffect(() => {
    validateToken();
  }, [])

  return (
    <SpashPage>
      <Section className="flex flex-col items-center mt-[50px] md:px-[200px] py-[40px] px-[10px]">
        <LogoLines />
        <h5 className="text-center mt-[30px]">Thread callback processed</h5>
      </Section>
    </SpashPage>
  )
}
