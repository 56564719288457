import { useEffect, useState, useMemo } from 'react'
import type { ApiKey } from 'types/apiKey';
import { Section } from 'shared/Section';
import { Table, message, Input } from 'antd'
import { Icon } from 'shared/Icon/Icon';
import { Loading } from 'shared/Loading/Loading';
import { Button, IconButton } from 'shared/Button';
import { DeleteKey } from './DeleteKeyDialog';
import { useApiKeyStore } from 'store/apiKeys';
import CreateDialog from './CreateDialog'

const ApiKeyLibrary = () => {
  const { 
    status: keyStatus,
    apiKeys,
    fetchApiKeys,
    deleteApiKey,
  } = useApiKeyStore();
  const [searchText, setSearchText] = useState('');
  const [keyToDelete, setKeyToDelete] = useState<ApiKey | null>(null);
  const [createDialogOpened, setCreateDialogOpened] = useState(false);


  useEffect(() => {
    fetchApiKeys();
  }, [])

  const filteredKeys = useMemo(() => {
    return searchText ?
      apiKeys.filter((key) => key.name.toLowerCase().includes(searchText.toLowerCase())) :
      apiKeys; 
  }, [apiKeys, searchText]);

  return (
    <div className='pt-[35px]'>
      <Section className='p-[20px]'>           
        <div className='flex mb-[20px]'>
          <div className='flex items-center grow pl-[11px]'>
            <Icon icon='search' />
            <input
              type='text'
              className='w-full leading-[20px] text-[14px] my-[-5px] py-[10px] pl-[40px] ml-[-29px] bg-transparent outline-none border-b border-b-transparent focus:border-b-cyanine-blue'
              value={searchText} 
              placeholder='Search Key Name'
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
            />
          </div>
          <Button
            size='sm'
            type='primary'
            icon='circle-plus'
            style='caladon'
            label='Create New API Key'
            onClick={() => setCreateDialogOpened(true)}
          />
        </div>
        <Table
          rowKey={({ id }) => id}
          size='small'
          rowClassName='text-[12px] line-[14px]'
          onHeaderRow={() => ({ className: 'text-[12px] line-[14px]' })}
          dataSource={filteredKeys}
          columns={[
            {
              key: 'name',
              title: 'Name',
              dataIndex: 'name',
              sorter: (a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0,
              render: (name) => <div className='w-30 truncate'>{name}</div>
            },
            {
              key: 'token',
              title: 'Key',
              dataIndex: 'token',
              render: (token) => (
                <div><Input.Password value={token}/></div>
              )
            },     
            {
              key: 'delete',
              width: 32,
              render: (_, key) => <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='trash' onClick={() => setKeyToDelete(key)} />
            },
          ]}
        />
        {keyStatus === 'loading' && <Loading />}
        <CreateDialog
          opened={createDialogOpened}
          onClose={(name) => {
            setCreateDialogOpened(false);
            if (name) {
              setSearchText(name)
            }
          }}
        />
        <DeleteKey
          open={!!keyToDelete}
          name={keyToDelete?.name || ''}
          onClose={() => setKeyToDelete(null)}
          onConfirm={async () => {
            if (!keyToDelete) {
              return;
            }

            try {
              setKeyToDelete(null);
              await deleteApiKey(keyToDelete.id);
              message.success(`Key deleted`);
            } catch {
              message.error(`Unable to delete key`);
            }
          }}
        />
      </Section>
    </div>
  )
}

export default ApiKeyLibrary
