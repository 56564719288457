import React from 'react';

export interface FixedColumnLayoutProps {
  className?: string;
  column?: React.ReactNode;
  columnVisible?: boolean;
  columnFixed?: boolean;
  children?: React.ReactNode;
}

export const FixedColumnLayout = (props: FixedColumnLayoutProps) => {
  const {
    className = '',
    column,
    columnVisible,
    columnFixed,
    children
  } = props;

  return (
    <div className={`flex ${className}`}>
      <div className="min-w-0 grow shrink">
        {children}
      </div>
      <div className={`ml-[15px] w-[370px] shrink-0 relative ${columnVisible ? '' : 'hidden'}`}> 
        <div className={`flex flex-col w-[370px] ${columnFixed ? 'fixed top-[70px] bottom-[15px] right-[15px]' : 'absolute top-0 bottom-0 right-0' }`}>
          {column}
        </div>
      </div>
    </div>
  );
};
