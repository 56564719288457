import type { ReactNode } from 'react';

export interface DataFilterValueTextProps {
  children: ReactNode;
}

export const DataFilterValueText = (props: DataFilterValueTextProps) => {
  const {
    children
  } = props;
  
  return (
    <span
      className='inline-block max-w-[170px] ml-[5px] truncate align-middle '
    >
      {children}
    </span>
  );
};
