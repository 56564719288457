import type { DataEntry } from 'types/dataEntries';
//import type { CostColumn } from 'types/costColumns';
import type { ConstructorRules } from 'types/filters';
import { isMutualExclusivityError, TeamDraft, TEAM_FILTER_SOURCES } from 'types/teams';

import { useState, useEffect } from 'react';
import { Input, Select, message } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button } from 'shared/Button';
import { InfoIcon } from 'shared/InfoIcon';
import { Filter } from 'shared/Filters';

import { getSlackChannels } from 'services/user';
//import { getCostColumns } from 'services/costColumn'

import { useGlobalState } from 'state/globalState';
import { getSourceTitle } from 'shared/Filters/constants';
import { identity } from 'helpers/utils';

import { useTeamsStore } from 'store/teams';
import { useTeamFilterConstructor } from 'store/teamFilterConstructor';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { PortalRoot } from 'shared/Portal';
import {MutualExclusivityErrorMessage} from './MutualExclusivityErrorMessage';

interface CreateDialogProps {
  draftKey: string | number | null;
  onClose: () => void;
}

export const CreateDialog = (props: CreateDialogProps) => {
  const user = useGlobalState((state) => state.user);
  const {
    draftKey,
    onClose
  } = props;

  const [slackChannelsList, setSlackChannelsList] = useState<string[]>([]);
  //const [costColumnList, setCostColumnList] = useState<CostColumn[]>([]);

  const teamFilterConstructor = useTeamFilterConstructor();
  const teamDeps = useDerivedDataEntry({ teamFilterConstructor }, identity);

  const draft = useTeamsStore((state) => draftKey ? state.getDraft(draftKey, teamDeps) : { status: 'idle' } as DataEntry<TeamDraft>);
  const setDraft = useTeamsStore((store) => store.setDraft);
  const discardDraft = useTeamsStore((store) => store.discardDraft);
  const createTeam = useTeamsStore((state) => state.createEntry);
  const updateTeam = useTeamsStore((state) => state.updateEntry);
  const action = useTeamsStore((state) => draftKey && state.actions.get(draftKey));

  useEffect(() => {
    getSlackChannels().then(({ data }) => {
      setSlackChannelsList(data);
    });
  }, []);

  const isNew = typeof draftKey === 'string';

  const [newPriority, setNewPriority] = useState('');

  return (
    <Dialog
      open={draftKey !== null}
      onClose={onClose}
      className='w-[80vw] max-w-[1200px] h-[80vh] flex flex-col'
    >
      <Dialog.Title>
        {`${ isNew ? 'Create New' : 'Edit'} Team`}
      </Dialog.Title>

      {draft.status === 'success' && draftKey !== null && <>
        <PortalRoot className='border-t border-t-silver-grey grid grid-cols-3 gap-[20px] px-[30px] py-[15px]'>
          {action && action.status === 'error' && isMutualExclusivityError(action.error) && (
            <MutualExclusivityErrorMessage errorData={action.error.response?.data} />
          )}

          <div>
            <div className='mb-1 font-medium'>
              Team Name
            </div>

            <Input
              type='secondary'
              value={draft.data.name}
              onChange={(event) => {
                setDraft(draftKey, { 
                  ...draft.data,
                  name: event.target.value
                });
              }}
              disabled={user && user.team ? true : false}
            />
          </div>

          <div>
            <div className='flex items-center mb-1'>
              <div className='font-medium'>
                Slack Channel
              </div>

              <InfoIcon>
                All reports and alerts will be sent to this channel. All team members will also receive these communications via email.
              </InfoIcon>

              <Button 
                size='xs' 
                type='tertiary' 
                label='Clear' 
                onClick={() => {
                  draftKey && setDraft(draftKey, {
                    ...draft.data,
                    slack_channel: null
                  });
                }}
                className='ml-auto my-[-2px]'
              />
            </div>

            <Select
              value={draft.data.slack_channel}
              onChange={(slack_channel) => {
                draftKey && setDraft(draftKey, {
                  ...draft.data,
                  slack_channel
                });
              }}
              options={slackChannelsList.map((channel) => ({
                label: channel,
                value: channel
              }))}
            />
          </div>

          <div>
            <div className='flex items-center mb-1'>
              <div className='font-medium'>
                Opportunity Priorities
              </div>

              <InfoIcon>
                A list of exact priority values. If set, only opportunities of that priorities will be assigned to the team.
              </InfoIcon>
            </div>

            <Select
              className='w-full rounded-[3px]'
              mode='tags'
              open={false}
              options={[]}
              searchValue={newPriority}
              value={draft.data.opportunity_priorities}
              tokenSeparators={[',', ' ']}
              onSearch={(val: string) => {
                setNewPriority(val.replace(/\D/g, ''));
              }}
              onChange={(priorities) => {
                setNewPriority('');
                setDraft(draftKey, {
                  ...draft.data,
                  opportunity_priorities: Array.from(new Set(priorities.map((val) => +val)))
                });
              }}
            />
          </div>
        </PortalRoot>

        <div className='mb-1 font-medium px-[30px]'>
          Team Filters
        </div>

        <div className='min-h-0 grid grid-cols-3 grow shrink gap-[20px] px-[30px] pb-[30px]'>
          {TEAM_FILTER_SOURCES.map((src) => (
            <div className='flex flex-col min-h-0 border rounded shrink'>
              <div className='p-[15px] border-b font-medium'>
                {getSourceTitle(src)}
              </div>

              <PortalRoot className='overflow-y-auto shadow-border-l px-[15px] grow'>
                {teamFilterConstructor.status === 'success' && teamFilterConstructor.data[src] &&
                  <Filter
                    rules={teamFilterConstructor.data[src] as ConstructorRules}
                    value={draft.data.filters[src].filter}
                    onChange={(filter) => {
                      draftKey && setDraft(draftKey, {
                        ...draft.data,
                        filters: {
                          ...draft.data.filters,
                          [src]: {
                            src,
                            filter
                          }
                        }
                      });
                    }}
                    removeTeams={false}
                  />
                }
              </PortalRoot>
            </div>
          ))}
        </div>

        <footer className='flex justify-end p-[15px] border-t mt-auto'>
          <Button
            disabled={draft.data.name === ''}
            style='caladon'
            label='Save Team'
            onClick={() => {
              if (draftKey === null) {
                return;
              } 

              if (isNew) {
                createTeam(draftKey, teamDeps)
                  .then(() => {
                    message.success('Team created');
                    discardDraft(draftKey);
                    onClose();
                  })
                  .catch(() => {
                    message.error('Unable to create team');
                  });
              } else {
                updateTeam(draftKey, teamDeps)
                  .then(() => {
                    message.success('Team updated');
                    discardDraft(draftKey);
                    onClose();
                  })
                  .catch(() => {
                    message.error('Unable to update team');
                  });
              }
            }}
          />
        </footer>
      </>}
    </Dialog>
  );
};
