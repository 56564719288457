import type { DateFilterFetchParams } from 'types/dateFilter';
import type { CostFilterFetchParams } from 'types/costViews';
import type { TagListFilterFetchPararms } from 'types/tagViews';

export const serializeDateFilterFetchParams = ({
  startDate,
  endDate,
  granularity
}: DateFilterFetchParams) => ({
  start_date: startDate,
  end_date: endDate,
  granularity
});

export const serializeCostFilterFetchParams = ({
  costType,
  costDimensions,
  costAmortization
}: CostFilterFetchParams) => ({
  cost_type: costType,
  exclude: costDimensions.join(','),
  amortization: costAmortization
});

export const serializeTagListFilterFetchParams = ({
  tagList,
}: TagListFilterFetchPararms) => ({
  tag_list: tagList.join(','),
});
