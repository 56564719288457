import { Dialog } from 'shared/Dialog';
import { PortalRoot } from 'shared/Portal';
import { CreateCatalogForm } from './CreateCatalogForm';
import { useTagCatalogsStore } from 'store/tagCatalogs';

export interface CreateCatalogDialogProps {
  opened: boolean;
  onClose: () => void;
}

export const CreateCatalogDialog = (props: CreateCatalogDialogProps) => {
  const {
    opened,
    onClose
  } = props;

  const tagCatalog = useTagCatalogsStore((store) => store.getNewEntry());
  const setTagCatalog = useTagCatalogsStore((store) => store.setCreateEntry);

  return (
    <Dialog
      className='w-[640px] pb-[20px]'
      open={opened}
      onClose={() => {
        setTagCatalog({
          ...tagCatalog,
          draft: {
            name: '',
            catalog_key: '',
            tags: {}
          }
        });

        onClose();
      }}
    >
      <Dialog.Title>
        Create New Tag Catalog
      </Dialog.Title>
      <PortalRoot className='px-[30px] overflow-y-auto'>
        <CreateCatalogForm
          onCreate={onClose}
        />
      </PortalRoot>
    </Dialog>
  );
};
