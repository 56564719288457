import { useEffect } from 'react';
import { Loading } from 'shared/Loading';
import { ErrorMessage } from './ErrorMessage';
import { Slug } from './Slug';
import { ToggleEnabledControl } from './ToggleEnabledControl';
import { AllowedDomainsControl } from './AllowedDomainsControl';
import { ToggleProfilesOutsideAllowedControl } from './ToggleAllowProfilesOutsideControl';
import { SubmitButton } from './SubmitButton';
import { ManageButton } from './ManageButton';
import { useSSOSettingsStore } from 'store/ssoSettings';

export interface SSOTabProps {
  slug: string | undefined;
  goToSettingsTab: (tab: string) => void;
}

export const SSOTab = (props: SSOTabProps) => {
  const { slug } = props;
  const status = useSSOSettingsStore((store) => store.status);
  const settingsCreated = useSSOSettingsStore((store) => store.settingsCreated);
  const connectionCreated = useSSOSettingsStore((store) => store.connectionCreated);
  const fetchSSOSettings = useSSOSettingsStore((store) => store.fetchSSOSettings);

  useEffect(() => {
    fetchSSOSettings();
  }, []);

  return (
    <div className='relative'>
      <h1 className='text-subtitle-1 font-medium mt-[15px]'>Single Sign-On</h1>
      {status === 'loading' && <Loading />}
      {status === 'error' && <ErrorMessage />}
      {status === 'success' && <div className='grid grid-cols-2'>
        <div className='mt-[21px]'>
          <AllowedDomainsControl />
          <ToggleProfilesOutsideAllowedControl />
          {connectionCreated && <ToggleEnabledControl />}
          <SubmitButton />
        </div>

        <Slug slug={slug} />

        <ManageButton />
      </div>}
    </div>
  );
};
