import type { FetchStatus } from 'types/common';
import type { UnitMetric, UnitMetricFilter } from 'types/unitMetrics';

import create from 'zustand';

import {
  fetchUnitMetric,
  createUnitMetric,
  updateUnitMetric,
  deleteUnitMetric
} from 'services/unitMetrics';

import { cleanupUnitMetricFilter } from 'helpers/unitMetrics';

export interface UnitMetricState {
  status: FetchStatus;
  id: number | null;
  name: string | null;
  custom: boolean | null;
  filter: UnitMetricFilter | null;
  unitMetric: UnitMetric | null;
}

export interface UnitMetricActions {
  setName: (name: string) => void;
  setFilter: (filter: UnitMetricFilter) => void;
  initUnitMetric: () => void;
  fetchUnitMetric: (id: number) => Promise<void>;
  createUnitMetric: () => Promise<{ id: number, name: string } | null>;
  updateUnitMetric: () => Promise<void>;
  deleteUnitMetric: () => Promise<void>;
  cleanup: () => void;
}

export interface UnitMetricStore extends UnitMetricState, UnitMetricActions {};

const DEFAULT_STATE: UnitMetricState = {
  status: 'idle',
  id: null,
  name: null,
  custom: null,
  filter: null,
  unitMetric: null
};

const NEW_METRIC_STATE: UnitMetricState = {
  status: 'success',
  id: null,
  name: '',
  custom: true,
  filter: {
    numerator: {
      src: 'cur',
      filter: {
        operator: 'and',
        condition: []
      }
    },
    denominator: {
      src: 'cur',
      filter: {
        operator: 'and',
        condition: []
      }
    },
  },
  unitMetric: null
}

export const useUnitMetricStore = create<UnitMetricStore>((set, get) => {
  return {
    ...DEFAULT_STATE,
    
    setName: (name) => {
      set({ name });
    },

    setFilter: (filter) => {
      set({ filter });
    },

    initUnitMetric: () => {
      set(NEW_METRIC_STATE);
    },

    fetchUnitMetric: async (id: number) => {
      set({
        ...DEFAULT_STATE,
        id
      });

      try {
        const { data: unitMetric } = await fetchUnitMetric(id);

        const {
          name,
          is_custom: custom
        } = unitMetric;

        set({
          name,
          custom,
          unitMetric,
          status: 'success'
        });
      } catch {
        set({ status: 'error' });
      }
    },

    createUnitMetric: async () => {
      const { name, filter } = get();

      if (name === null || filter === null) {
        return null;
      }

      const { data: unitMetric } = await createUnitMetric({
        name,
        filter: cleanupUnitMetricFilter(filter)
      });

      const id = unitMetric.id as number;

      return { id, name };
    },

    updateUnitMetric: async () => {
      const {
        id,
        name,
        filter,
        custom
      } = get();

      if (id === null || filter === null || !name || !custom) {
        return;
      }

      await updateUnitMetric(id, {
        name,
        filter: cleanupUnitMetricFilter(filter)
      });
    },

    deleteUnitMetric: async () => {
      const { id } = get();

      if (id) {
        await deleteUnitMetric(id);
      }
    },

    cleanup: () => {
      set(DEFAULT_STATE);
    }
  };
});
