import {
  Set as ImmutableSet,
  Record as ImmutableRecord
} from 'immutable';

import type {
  Filter,
  FilterCondition,
  FilterGroupCondition,
  FilterValueCondition,
  FilterDictValueCondition,
  FilterStringValueCondition,

  ImmutableDictValue,
  ImmutableDictValueFactory,

  ImmutableDictCondition,
  ImmutableDictConditionProps,
  ImmutableDictConditionFactory,

  ImmutableStringCondition,
  ImmutableStringConditionProps,
  ImmutableStringConditionFactory,

  ImmutableGroupCondition,
  ImmutableGroupConditionProps,
  ImmutableGroupConditionFactory,

  ImmutableValueCondition,
  ImmutableFilterCondition,

  ImmutableFilter,
  ImmutableFilterProps,
  ImmutableFilterFactory
} from 'types/filters';

import {
  isFilterGroupCondition,
  isFilterStringValueCondition
} from 'types/filters';

export const createImmutableDictValue: ImmutableDictValueFactory = ImmutableRecord({ key: '', value: '' });

export const createImmutableDictCondition: ImmutableDictConditionFactory = ImmutableRecord<ImmutableDictConditionProps>({
  fieldName: '',
  valueType: 'dict',
  comparator: 'equals',
  values: ImmutableSet()
});

export const createImmutableStringCondition: ImmutableStringConditionFactory = ImmutableRecord<ImmutableStringConditionProps>({
  fieldName: '',
  valueType: 'string',
  comparator: 'equals',
  values: ImmutableSet()
});

export const createImmutableGroupCondition: ImmutableGroupConditionFactory = ImmutableRecord<ImmutableGroupConditionProps>({
  operator: 'or',
  condition: ImmutableSet()
});

const toImmutableStringCondition = (condition: FilterStringValueCondition): ImmutableStringCondition | null => {
  const {
    field_name: fieldName,
    value_type: valueType,
    comparator,
    values: valuesArray
  } = condition;

  const validValuesArray = valuesArray.filter(Boolean);

  if (validValuesArray.length === 0) {
    return null;
  }

  const values = ImmutableSet(validValuesArray);

  return createImmutableStringCondition({
    fieldName,
    valueType,
    comparator,
    values
  });
}

const toImmutableDictCondition = (condition: FilterDictValueCondition): ImmutableDictCondition | null => {
  const {
    field_name: fieldName,
    value_type: valueType,
    comparator,
    values: valuesArray
  } = condition;

  let values = ImmutableSet<ImmutableDictValue>();

  valuesArray.forEach((dict) => {
    if (!dict) {
      return;
    }

    const entries = Object.entries(dict);

    if (entries.length !== 1) {
      return;
    }

    const [key, value] = entries[0];

    if (!key || !value) {
      return;
    }

    values = values.add(createImmutableDictValue({ key, value }));
  });

  if (values.size === 0) {
    return null;
  }

  return createImmutableDictCondition({
    fieldName,
    valueType,
    comparator,
    values
  });
}

const toImmutableValueCondition = (condition: FilterValueCondition): ImmutableValueCondition | null => {
  if (isFilterStringValueCondition(condition)) {
    return toImmutableStringCondition(condition);
  }

  return toImmutableDictCondition(condition);
};

const toImmutableGroupCondition = (condition: FilterGroupCondition): ImmutableGroupCondition | null => {
  const childConditionsArray = condition.condition.map(toImmutableCondition).filter(Boolean) as ImmutableFilterCondition[];

  if (childConditionsArray.length === 0) {
    return null;
  }

  return createImmutableGroupCondition({
    operator: condition.operator,
    condition: ImmutableSet(childConditionsArray)
  });
};

export const toImmutableCondition = (condition: FilterCondition): ImmutableFilterCondition | null => {
  if (isFilterGroupCondition(condition)) {
    return toImmutableGroupCondition(condition);
  }

  return toImmutableValueCondition(condition);
};

export const createImmutableFilter: ImmutableFilterFactory = ImmutableRecord<ImmutableFilterProps>({
  src: '',
  filter: null
});

export const toImmutableFilter = (filter: Filter): ImmutableFilter => {
  return createImmutableFilter({
    src: filter.src,
    filter: toImmutableCondition(filter.filter)
  });
};
