import type { Granularity } from 'types/common'
import type { Budget } from 'types/costViews';

import { 
  createBudget,
  updateBudget,
  deleteBudget
} from 'services/budgets';

import { message, InputNumber } from 'antd';

import { Dialog, DialogBody } from 'shared/Dialog';
import { Button2 as Button } from 'shared/Button';

import { useBudgetDialog } from 'store/budgetDialog';
import { useCostOverviewStore } from './useCostOverviewStore';

export interface BudgetDialogProps {
  granularity: Granularity;
}

export const BudgetDialog = (props: BudgetDialogProps) => {
  const { granularity } = props;

  const {
    view,
    editViewBudget,
    deleteViewBudget,
    createViewBudget,
  } = useCostOverviewStore();

  const { id: budgetToEdit, open, close } = useBudgetDialog();

  const handleCreateClick = (budget: Budget) => {
    if (view) {
      createBudget(budget, view.id)
        .then(({ data: newBudget }) => {
          message.success('Budget created');
          createViewBudget(newBudget as Budget)
          close();
        }).catch((err) => {
          message.error(err);
        });
    }
  }

  const handleUpdateClick = (budget: Budget) => {
    updateBudget(budget)
      .then(({ data: newBudget }) => {
        message.success('Budget saved');
        editViewBudget(newBudget);
        close();
      }).catch((err) => {
        message.error(err);
      });
  }

  const handleValueChange = (value: number | null) => {
    if (budgetToEdit) {
      open({
        ...budgetToEdit,
        value: value || 0
      });
    }
  }

  const handleSaveClick = (budget: Budget) => {
    if (budget.id === -1) {
      handleCreateClick(budget);
    } else {
      handleUpdateClick(budget);
    }
  }

  const handleDeleteClick = (budget: Budget) => {
    deleteBudget(budget.id)
      .then(() => {
        message.success('Budget deleted');
        deleteViewBudget(budget.id)
        close();
      }).catch((err) => {
        message.error(err);
      });
  }

  const timeFrame = granularity === 'quarter' ? 'Quarterly' : 'Monthly'

  return (
    <Dialog
      className='w-[320px]'
      open={budgetToEdit !== null}
      onClose={close}
    >
      {budgetToEdit && <>
        <Dialog.Title>
          {budgetToEdit.id === -1 ? `Set ${timeFrame} Budget` : `Edit ${timeFrame} Budget`}
        </Dialog.Title>

        <DialogBody>
          <InputNumber
            className='w-full'
            type='number'
            size='large'
            prefix='$'
            value={budgetToEdit.value}
            onChange={handleValueChange}
          />
        </DialogBody>

        <div className='flex p-8 pt-5 gap-2'>
          {budgetToEdit.id !== -1 && 
            <Button
              size='m'
              className='!bg-red basis-0 !grow'
              onClick={() => handleDeleteClick(budgetToEdit)}
            >
              Delete
            </Button>
          }

          <Button
            className='basis-0 !grow'
            size='m'
            onClick={() => handleSaveClick(budgetToEdit)}
          >
            Save
          </Button>
        </div>
      </>}
    </Dialog>
  );
}
