export const H1 = ({ className = '', ...props }: React.AllHTMLAttributes<HTMLHeadingElement>) => (
  <h1 {...props} className={`text-subtitle-1 font-medium my-[20px] ${className}`} />
);

export const H2 = ({ className = '', ...props }: React.AllHTMLAttributes<HTMLHeadingElement>) => (
  <h2 {...props} className={`text-subtitle-2 font-medium mt-[20px] mb-[10px] ${className}`} />
);

export const P = ({ className = '', ...props }: React.AllHTMLAttributes<HTMLParagraphElement>) => (
  <p {...props} className={`my-[10px] ${className}`} />
);

export const A = ({ className = '', ...props }: React.AllHTMLAttributes<HTMLAnchorElement>) => (
  <a {...props} className={`!text-caladon-blue ${className}`} />
);
