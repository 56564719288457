import create from 'zustand'

import type { FetchStatus } from 'types/common';
import type { DataStreamConfig } from 'types/dataStreamConfigs';

import {
  getDataStreamConfigs,
  saveDataStreamConfig,
  deleteDataStreamConfig,
} from 'services/dataStreamConifgs'


interface FetchParams {
  force?: boolean;
}

export interface DataState {
  status: FetchStatus;
  dataStreamConfigs: DataStreamConfig[];
}

export interface DataActions {
  fetchDataStreamConfigs: (params?: FetchParams) => void;
  saveDataStreamConfig: (key: any) => void;
  deleteDataStreamConfig: (id: number) => Promise<void>;
}

export interface DataStreamConfigStore extends DataState, DataActions {}

const DEFAULT_STATE: DataState = {
  status: 'idle',
  dataStreamConfigs: [],
};

export const useDataStreamConfigStore = create<DataStreamConfigStore>((set, get) => {
  let requestCount = 0;

  return {
    ...DEFAULT_STATE,

    fetchDataStreamConfigs: async (params = {}) => {
      const { force } = params;
      const { status } = get();

      /*
      if (!force && ['loading', 'success'].includes(status)) {
        return;
      }
      */

      try {
        if (status !== 'success') {
          set({ status: 'loading' });
        }

        const requestNumber = ++requestCount;
        
        const { data: keys } = await getDataStreamConfigs();
        const dataStreamConfigs = keys.map((data: any) => {
          const newConfig: DataStreamConfig = {
            id: data.id,
            data_stream_id: data.data_stream_id,
            data_stream_token: data.data_stream_token,
            is_data_ready: data.is_data_ready,
            last_updated_at: data.last_updated_at,
          }
          return newConfig
        })

        if (requestNumber === requestCount) {
          set({
            dataStreamConfigs,
            status: 'success'
          });
        }
      } catch(error) {
        set({ status: 'error' });
      }
    },

    saveDataStreamConfig: async (key: any) => {
      const { dataStreamConfigs } = get();
      try {
        let data
        ({ data } = await saveDataStreamConfig(key))
        const newConfig: DataStreamConfig = {
          id: data.id,
            data_stream_id: data.data_stream_id,
            data_stream_token: data.data_stream_token,
            is_data_ready: data.is_data_ready,
            last_updated_at: data.last_updated_at,
        }
        const static_configs = dataStreamConfigs.filter((key) => key.id !== newConfig.id)
        set({ dataStreamConfigs: [newConfig, ...static_configs] })
      } catch(error) {
        set({ dataStreamConfigs });
        throw error;
      }
    },

    deleteDataStreamConfig: async (id: number) => {
      const { dataStreamConfigs } = get();
      set({ dataStreamConfigs: dataStreamConfigs.filter((key) => key.id !== id) });

      try {
        await deleteDataStreamConfig(id);
      } catch(error) {
        set({ dataStreamConfigs });
        throw error;
      }
    },
  };
});
