import { message } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button } from 'shared/Button';
import { jiraServerIntegration } from 'services/integrations';
import { JiraServerForm } from './JiraSeverForm';
import { validateServerCreatePayload } from 'helpers/integrations/jiraServer';
import { useJiraServerStore } from 'store/integrations/jiraServer';
import { useJiraServerCreateDialog } from 'store/integrations/jiraServerCreateDialog';

export interface JiraIssueCreateDialogProps {
  clientId: string;
}

export const JiraIssueCreateDialog = (props: JiraIssueCreateDialogProps) => {
  const { opened, close } = useJiraServerCreateDialog();

  const {
    clientId
  } = props;

  const draft = useJiraServerStore((store) => store.getDraft(clientId));
  const setDraft = useJiraServerStore((store) => store.setDraft);
  const discardDraft = useJiraServerStore((store) => store.discardDraft);

  return (
    <Dialog
      className='w-[480px]'
      open={opened}
      onClose={() => {
        close();
        discardDraft(clientId);
      }}
    >
      <Dialog.Title>
        Create JIRA Issue
      </Dialog.Title>

      <div className='px-[30px]'>
        <JiraServerForm
          value={draft}
          onChange={(value) => {
            setDraft(clientId, value);
          }}
        />
      </div>

      <div className='p-[15px] flex justify-end'>
        <Button
          style='caladon'
          label='Create JIRA Server Integration'
          disabled={!validateServerCreatePayload(draft)}
          onClick={() => {
            jiraServerIntegration(draft).then((data) =>
              window.location.href = data.authorize_uri
            ).catch(() => {
              message.error('Unable to create jira server authorization uri');
            });
          }}
        />
      </div>
    </Dialog>
  );
};
