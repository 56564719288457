import type { GCPDataConfigCreatePayload } from 'types/dataCollection';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Input, message } from 'antd'
import Dialog from 'shared/Dialog/Dialog';
import Button from 'shared/Button/Button'
import { StatusOverlay } from 'shared/Overlay';
import { useDataConfigWithThresholdsStore } from 'store/dataConfigs/dataConfigsWithThresholds';
import { useGCPConfigCreateDialog } from 'store/dataConfigs/gcpCreateDialog';

export interface GCPConfigCreateDialogProps {
  onboarding: boolean;
}

export const GCPConfigCreateDialog = (props: GCPConfigCreateDialogProps) => {
  const { onboarding } = props;
  const { opened, close } = useGCPConfigCreateDialog();
  const navigate = useNavigate()
  const dataConfigs = useDataConfigWithThresholdsStore((store) => store.getLibrary());
  const getGCPServiceAccount = useDataConfigWithThresholdsStore((store) => store.getGCPServiceAccount());
  const updateEntry = useDataConfigWithThresholdsStore((store) => store.updateEntry);
  const validateEntry = useDataConfigWithThresholdsStore((store) => store.validateEntry);
  const createEntry = useDataConfigWithThresholdsStore((store) => store.createEntry);
  const [recommenderProjectIds, setRecommenderProjectIds] = useState('')
  const [billingProjectId, setBillingProjectId] = useState('')
  const [table, setTable] = useState('')

  const handleSetRecommenderProjectIds = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecommenderProjectIds(e.target.value)
  }

  const handleProjectsClick = () => {
    window.open('https://console.cloud.google.com/cloud-resource-manager', '_blank')
  }

  const handleTableClick = () => {
    window.open('https://console.cloud.google.com/bigquery', '_blank')
  }

  const handleIAMClick = () => {
    window.open('https://console.cloud.google.com/iam-admin/iam', '_blank')
  }

  const handleBigQueryClick = () => {
    window.open('https://console.cloud.google.com/bigquery', '_blank')
  }

  const handleRecommenderAPIClick = () => {
    window.open('https://console.cloud.google.com/apis/library/recommender.googleapis.com', '_blank')
  }

  const handleBigQuqeryAPIClick = () => {
    window.open('https://console.cloud.google.com/apis/library/bigquery.googleapis.com', '_blank')
  }

  const handleSetBillingProjectId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingProjectId(e.target.value)
  }

  const handleSetTable = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTable(e.target.value)
  }

  useEffect(() => {
    if (!opened) {
      setRecommenderProjectIds('')
      setBillingProjectId('')
      setTable('')
    }
  }, [opened])

  return (
    <>
      <Dialog
        open={opened}
        onClose={() => close()}
        className='w-[710px] overflow-y-auto'
      >
        <Dialog.Title>
        {'Create GCP Collection Config'}
        </Dialog.Title>

        {getGCPServiceAccount.status === 'success' && dataConfigs.status === 'success' && (
          <div className='px-[30px] pb-[30px]'>
            <div className='mb-[30px]'>
              <div className="mt-[8px] mb-[30px]">
                <span className='font-bold'>Service Account</span>
                <br/>
                <Input disabled value={getGCPServiceAccount.data.email} />
              </div>
              <div className='mb-4 font-medium text-[16px]'>
                Permission Steps Savings Hub
              </div>
              <ul className='ml-5 list-decimal list-outside'>
                <li className="mt-[8px]">
                  Copy and paste the Service Account
                </li>
                <li className="mt-[8px]">
                  Go to <a className='font-bold underline' onClick={handleIAMClick}>IAM</a> in all 
                  the Projects where you'd like to collect savings opportunities
                </li>
                <li className="mt-[8px]">
                  For each Project, click <span className='px-2 bg-grey-transparent'>+ Grant Access</span>
                </li>
                <li className="mt-[8px]">
                  Paste the Service Account into 
                  the <span className='px-2 bg-grey-transparent'>New principals</span> field
                </li>
                <li className="mt-[8px]">
                  Add <span className='px-2 bg-grey-transparent'>Recommender Viewer</span> permissions
                </li>
                <li className="mt-[8px]">
                  <span className='font-bold'>Save</span> the access
                </li>
                <li className="mt-[8px]">
                  Ensure the <a className='font-bold underline' onClick={handleRecommenderAPIClick}>Recommender API</a> is enabled
                  for each Project
                </li>
                <br/>
              </ul>
              <div className='mb-[30px]'>
                <div className='mb-2 font-medium text-[16px]'>
                  Recommender Project IDs
                  <div className='mt-2 mb-2 text-[10px]'>
                    Comma-seperated list of Project IDs
                  </div>
                </div>
                <Input
                  className="w-full mt-2"
                  onChange={handleSetRecommenderProjectIds}
                  value={recommenderProjectIds}
                  type="secondary"
                />
                <div className='mb-2 mt-2 text-[12px]'>
                  To determine your Project IDs go to <a className='underline' onClick={handleProjectsClick}>GCP Resource Manager.</a>
                </div>
              </div> 
              <div className='mb-4 font-medium text-[16px]'>
                Permission Steps BigQuery
              </div>
              <ul className='ml-5 list-decimal list-outside'>
                <li className="mt-[8px]">
                  Copy and paste the Service Account
                </li>
                <li className="mt-[8px]">
                  Go to <a className='font-bold underline' onClick={handleIAMClick}>IAM</a> in your Billing Project
                </li>
                <li className="mt-[8px]">
                  Click <span className='px-2 bg-grey-transparent'>+ Grant Access</span>
                </li>
                <li className="mt-[8px]">
                  Paste the Service Account into 
                  the <span className='px-2 bg-grey-transparent'>New principals</span> field
                </li>
                <li className="mt-[8px]">
                  Add the <span className='px-2 bg-grey-transparent'>BigQuery Job User</span> permissions
                </li>
                <li className="mt-[8px]">
                  <span className='font-bold'>Save</span> the access
                </li>
                <li className="mt-[8px]">
                  Go to <a className='font-bold underline' onClick={handleBigQueryClick}>BigQuery</a> in your Billing Project
                </li>
                <li className="mt-[8px]">
                  Navigate to your Billing BigQuery Export Dataset and click the data set
                </li>
                <li className="mt-[8px]">
                  Click <span className='px-2 bg-grey-transparent'>+ Sharing</span> and then <span className='px-2 bg-grey-transparent'>Permissions</span>
                </li>
                <li className="mt-[8px]">
                  Click <span className='px-2 bg-grey-transparent'>+ Add Principal</span>
                </li>
                <li className="mt-[8px]">
                  Paste the Service Account into 
                  the <span className='px-2 bg-grey-transparent'>New principals</span> field
                </li>
                <li className="mt-[8px]">
                  Add the <span className='px-2 bg-grey-transparent'>BigQuery Data Viewer</span> permissions
                </li> 
                <li className="mt-[8px]">
                  <span className='font-bold'>Save</span> the access
                </li>
                <li className="mt-[8px]">
                  Ensure the <a className='font-bold underline' onClick={handleBigQuqeryAPIClick}>BigQuery API</a> is enabled
                  for your Billing Project
                </li>
              </ul>
            </div>
            <div className='mb-[30px]'>
              <div className='mb-2 font-medium text-[16px]'>
                Billing Data Project ID
              </div>
              <Input
                className="w-full mt-2"
                onChange={handleSetBillingProjectId}
                value={billingProjectId}
                type="secondary"
              />
              <div className='mb-2 mt-2 text-[12px]'>
                To determine your Project ID go to <a className='underline' onClick={handleProjectsClick}>GCP Resource Manager</a> and
                find your Billing Project.
              </div>
            </div> 
            <div className='mb-[30px]'>
              <div className='mb-2 font-medium text-[16px]'>
                Billing Data Table ID
              </div>
              <Input
                className="w-full mt-2"
                onChange={handleSetTable}
                value={table}
                type="secondary"
              />
              <div className='mb-2 mt-2 text-[12px]'>
                To determine your Table ID go to <a className='underline' onClick={handleTableClick}>GCP BigQuery</a> and
                select your Billing BigQuery Export Dataset and find the <span className='px-2 bg-grey-transparent'>Detailed usage cost</span> Table ID 
                in the table <span className='font-bold'>Details.</span>
              </div>
            </div> 
            <div className='flex justify-end'>
              <Button
                label='Cancel'
                type='tertiary'
                style='caladon'
                onClick={() => close()}
              />
              <Button
                label='Validate'
                type='tertiary'
                style='caladon'
                onClick={() => {
                  if (recommenderProjectIds === '') {
                    message.error("Recommender Project IDs not valid")
                    return
                  }
                  if (billingProjectId === '') {
                    message.error("Billing Project ID not valid")
                    return
                  }
                  if (table === '') {
                    message.error("Billing Data Table not valid")
                    return
                  }
                  recommenderProjectIds.split(',').forEach((projectId) => {
                    const project_id = projectId.trim()
                    validateEntry('gcp', {
                      service_account_id: getGCPServiceAccount.data.id,
                      collection_details: {
                        'project_id': project_id,
                      },
                      cadence: 'quaddaily',
                      data_source_id: 'GCPCOSTOPTIMIZATION',
                    }).then((valid) => {
                      if (valid) {
                        message.success(`Recommender config for project ${project_id} is valid`)
                      } else {
                        message.warning(
                          `Recommender config is ${project_id} invalid, if you proceed`
                          + ` with creation this config will not be created.`
                        )
                      }
                    }).catch(() => message.error('Recommender config validation failed'))
                  })
                  validateEntry('gcp', {
                    service_account_id: getGCPServiceAccount.data.id,
                    collection_details: {
                      'project_id': billingProjectId.trim(),
                      'table': table,
                    },
                    cadence: 'quaddaily',
                    data_source_id: 'GCPCUR',
                  }).then((valid) => {
                    if (valid) {
                      message.success('Billing config is valid')
                    } else {
                      message.warning(
                        `Billing config is invalid, if you proceed`
                        + ` with creation this config will not be created.`
                      )
                    }
                  }).catch(() => message.error('Billing config validation failed'))
                }}
              />
              <Button
                label='Save Config'
                type='primary'
                style='caladon'
                onClick={async () => {
                  if (recommenderProjectIds === '') {
                    message.error("Recommender Project IDs not valid")
                    return
                  }
                  if (billingProjectId === '') {
                    message.error("Billing Project ID not valid")
                    return
                  }
                  if (table === '') {
                    message.error("Billing Data Table not valid")
                    return
                  }
                  recommenderProjectIds.split(',').forEach((projectId) => {
                    const project_id = projectId.trim()
                    const existingRecommenderConfig = dataConfigs.data.find((config) => {
                      return config.data_source_id == 'GCPCOSTOPTIMIZATION' && 
                        config.collection_details['project_id'] == project_id
                    })
                    if (existingRecommenderConfig) {
                      return
                    }
                    createEntry('gcp', {
                      service_account_id: getGCPServiceAccount.data.id,
                      collection_details: {
                        'project_id': project_id,
                      },
                      cadence: 'daily',
                      data_source_id: 'GCPCOSTOPTIMIZATION',
                    })
                    .catch(() => message.error('Error creating Recommender config'));
                  })
                  const existingBillingConfig = dataConfigs.data.find((config) => {
                    return config.data_source_id == 'GCPCUR' && 
                      config.collection_details['project_id'] == billingProjectId.trim()
                  })
                  if (existingBillingConfig) {
                    updateEntry(
                      existingBillingConfig.id,
                      {'table': table}
                    )
                    .then(() => {
                      if (onboarding) {
                        navigate('sync')
                      }
                    })
                    .catch(() => message.error('Error creating Billing config'))
                  } else {
                    createEntry('gcp', {
                      service_account_id: getGCPServiceAccount.data.id,
                      collection_details: {
                        'project_id': billingProjectId.trim(),
                        'table': table,
                      },
                      cadence: 'quaddaily',
                      data_source_id: 'GCPCUR',
                    })
                    .then(() => {
                      if (onboarding) {
                        navigate('sync')
                      }
                    })
                    .catch(() => message.error('Error creating Billing config'))
                  }
                  close();
                }}
              />
            </div>
          </div>
        )}
        <div  className='mt-[30px]'>
          <StatusOverlay status={dataConfigs.status} />
          {dataConfigs.status === 'success' && <StatusOverlay status={getGCPServiceAccount.status} />}
        </div>
      </Dialog>
    </>
  )
}

export default GCPConfigCreateDialog
