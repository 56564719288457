import { message } from 'antd';
import {renderOpportunitySlug} from 'helpers/savings';
import { Button2 as Button } from 'shared/Button';
import {useGlobalState} from 'state/globalState';
import {
  useSavingsStore,
  useOpportunityTeamDialog
} from 'store/savings'

export interface OpportunityResolveButtonProps {
  opportunityId: number;
}

export const OpportunityResolveButton = (props: OpportunityResolveButtonProps) => {
  const { opportunityId } = props;

  const resolveOpportunity = useSavingsStore((store) => store.resolveOpportunity);
  const currentAction = useSavingsStore((store) => store.opportunityActions.get(opportunityId));

  const user = useGlobalState((store) => store.user);
  const openOpportunityTeamDialog = useOpportunityTeamDialog((store) => store.open);

  return (
    <Button
      className='text-green'
      size='xs'
      theme='link'
      layout='inline'
      iconLeft='check-filled'
      disabled={currentAction && currentAction.status === 'in-progress'}
      onClick={() => {
        resolveOpportunity(opportunityId)
          .then((opportunity) => {
            message.success(<><b>{renderOpportunitySlug(opportunity)}</b> resolved</>);

            const userCanAssign = ['owner', 'admin'].includes(user!.role) && !user?.team;

            if (userCanAssign && !opportunity.thread_id && !opportunity.team_id) {
              openOpportunityTeamDialog([opportunity]);
            }
          })
          .catch(() => {
            message.error('Unable to resolve opportunity');
          });
      }}
    >
      Resolve
    </Button>
  );
}
