import { Outlet } from 'react-router-dom';
import { useGlobalState } from 'state/globalState';
import { LoginError } from 'pages/LoginError/LoginError';

export const LoginErrorRoute = () => {
  const loginError = useGlobalState((state) => state.loginError);

  if (loginError && loginError.error === 'user_has_no_team') {
    return <LoginError />;
  }
  return <Outlet />;
}
