import type { Integration, IntegrationProvider, JiraSeverCreatePayload } from 'types/users';
import api, { API_HOST } from 'helpers/api';

export const getAuthorizeURL = (provider: IntegrationProvider) => `${API_HOST}/integrations/${provider}/authorize`;

export const fetchIntegrations = () => api.get<Integration[]>('/integrations/').then(({ data }) => data);

export const validateCode = (provider: IntegrationProvider, code: string) => api
  .get<Integration>(`/integrations/${provider}/callback`, { params: { code }})
  .then(({ data }) => data);

export const jiraServerIntegration = (draft: JiraSeverCreatePayload) => api.post(`/integrations/jira_server/authorize`, draft)
  .then(({ data }) => data);
