import type {
  UnitMetricChartKey,
  UnitMetricChartKeyProps,
  UnitMetricChartKeyFactory,
  UnitMetricChartFetchParams
} from 'types/unitMetrics';

import {
  Set as ImmutableSet,
  Record as ImmutableRecord
} from 'immutable';

import {
  defaultCostFilterKeyProps,
  defaultDateFilterKeyProps
} from './defaults';

import { toImmutableUnitMetricFilter } from 'helpers/unitMetrics';

const createUnitMetricChartKey: UnitMetricChartKeyFactory = ImmutableRecord<UnitMetricChartKeyProps>({
  ...defaultCostFilterKeyProps,
  ...defaultDateFilterKeyProps,
  teamId: undefined,
  globalFilterIds: undefined,
  filter: null,
});

export const unitMetricChartFetchParamsToKey = (params: UnitMetricChartFetchParams): UnitMetricChartKey => {
  const costDimensions = ImmutableSet(params.costDimensions);
  const globalFilterIds = ImmutableSet(params.globalFilterIds);
  const filter = toImmutableUnitMetricFilter(params.filter);

  return createUnitMetricChartKey({
    ...params,
    costDimensions,
    globalFilterIds,
    filter
  });
};
