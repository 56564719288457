import { Input } from 'antd';

export interface RecommendationInputProps {
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

export const RecommendationInput = (props: RecommendationInputProps) => {
  const {
    value,
    disabled,
    onChange
  } = props;

  return (
    <Input.TextArea
      placeholder='Describe how to address the opportunity'
      value={value}
      disabled={disabled}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    />
  );
}
