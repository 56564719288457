import { Button2 as Button } from 'shared/Button'
import GCPConfigCreateDialog from './GCPConfigCreateDialog';
import { useGCPConfigCreateDialog } from 'store/dataConfigs/gcpCreateDialog';


export interface GCPSelectionProps {
  onboarding: boolean;
}

export const GCPSelection = (props: GCPSelectionProps) => {
  const { onboarding } = props;
  const openGCPConfigCreateDialog = useGCPConfigCreateDialog((store) => store.open);

  const handleTemplateClick = () => {
    window.open('https://github.com/cloudthread/docs/blob/main/policy_cfn_cldthrd.yaml', '_blank')
  }

  const handleLearnMoreClick = () => {
    window.open('https://docs.cloudthread.io/fundamentals/data-collection', '_blank')
  }

  const handleGCPBillingAccount = () => {
    window.open('https://cloud.google.com/billing/docs/how-to/create-billing-account', '_blank')
  }

  const handleGCPBillingProject = () => {
    window.open('https://cloud.google.com/billing/docs/how-to/export-data-bigquery-setup#project', '_blank')
  }

  const handleLinkGCPProject = () => {
    window.open('https://cloud.google.com/billing/docs/how-to/modify-project#enable_billing_for_a_project', '_blank')
  }

  const handleGCPBigQueryPricingExport = () => {
    window.open('https://cloud.google.com/billing/docs/how-to/export-data-bigquery-setup#api', '_blank')
  }

  const handleGCPBigQueryExportDataset = () => {
    window.open('https://cloud.google.com/billing/docs/how-to/export-data-bigquery-setup#create-bq-dataset', '_blank')
  }

  const handleGCPBigQueryExportData = () => {
    window.open('https://cloud.google.com/billing/docs/how-to/export-data-bigquery-setup#enable-bq-export', '_blank')
  }

  return (
    <div>
      <div className='p-5'>
        {onboarding && (
          <div className='mb-5'>
            <div className="mb-5 text-xl font-semibold ">Connecting to GCP</div>
            <p>
              The easiest way to securely give Cloudthread access to your GCP
              cost and resource data is using a GCP Service Account.
            </p>
          </div>
        )}
        <div>
          <div>
            <div className='mb-5'>Please execute following steps in your GCP Organization:</div>
            <ul className='ml-5 list-decimal list-outside'>
              <li className="mt-[5px]">
                Create a <a className='font-bold underline' onClick={handleGCPBillingAccount}>Billing Account</a> (if applicable)
              </li>
              <li className="mt-[5px]">
                Create a <a className='font-bold underline' onClick={handleGCPBillingProject}>Billing Project</a> (if applicable)
              </li>
              <li className="mt-[5px]">
                <a className='font-bold underline' onClick={handleLinkGCPProject}>Link</a> the Billing Project 
                from <span className='font-bold'>Step 2</span> to the Billing Account 
                from <span className='font-bold'>Step 1</span> (if applicable)
              </li>
              <li className="mt-[5px]">
                <a className='font-bold underline' onClick={handleLinkGCPProject}>Link</a> the desired Resource Projects
                to the Billing Account from <span className='font-bold'>Step 1</span> (if applicable)
              </li>
              <li className="mt-[5px]">
                <a className='font-bold underline' onClick={handleGCPBigQueryExportDataset}>Create</a> the Billing BigQuery Export Dataset
              </li>
              <li className="mt-[5px]">
                <a className='font-bold underline' onClick={handleGCPBigQueryExportData}>Enable</a> the <span className='px-2 bg-grey-transparent'>Detailed usage cost</span> export for the Billing Account 
                from <span className='font-bold'>Step 1</span> using the Billing BigQuery Export Dataset
                from <span className='font-bold'>Step 3</span>
              </li>
            </ul>
            <p className="mt-[15px]">
              Once you've completed the steps above click the button below to complete the integration by providing permissions
              to a Cloudthread generated Service Acccount
            </p>
          </div>
          <div>
            <Button 
              size='l'
              theme='link'
              layout='inline'
              className='mt-[30px]'
              onClick={openGCPConfigCreateDialog} 
            >
              Setup GCP Service Account Permissions
            </Button>
          </div>
        </div>
      </div>
      <GCPConfigCreateDialog onboarding={onboarding}/>
    </div>
  )
}

export default GCPSelection
