import type {
  TagKey,
  TagValue,
  TagCatalog,
  TagCatalogCreatePayload,
  TagCatalogUpdatePayload
} from 'types/tags';

import api from 'helpers/api';

export const fetchTagCatalogs = () => api.get<TagCatalog[]>('/tag-catalogs');

export const fetchTagCatalog = (id: number) => api.get<TagCatalog>(`/tag-catalogs/${id}`);

export const createTagCatalog = (payload: TagCatalogCreatePayload) => api.post<TagCatalog>('/tag-catalogs', payload);

export const updateTagCatalog = (id: number, payload: TagCatalogUpdatePayload) => api.patch(`/tag-catalogs/${id}`, payload);

export const deleteTagCatalog = (id: number) => api.delete(`/tag-catalogs/${id}`);

export const fetchTagKeys = () => api.get<TagKey[]>('/tags/keys');
export const fetchTagValues = (id: number) => api.get<TagValue[]>('/tags/values', {
  params: {
    tag_key_id: id
  }
});
export const fetchTagAliases = () => api.get('/tags/keys/aliases');

export const createTagKey = () => api.post('/tags/keys');
export const createTagValue = () => api.post('/tags/values');

export const syncTags = () => api.post('/tags/sync');
