import type { 
  Thread,
  Opportunity,
 } from 'types/savings';

import moment from 'moment';
import { useMemo, useState } from 'react';
import { Page } from 'shared/Page';
import { Grid } from 'shared/Grid';
import { Button2 as Button } from 'shared/Button';
import { Section } from 'shared/Section';
import { SettingsPanel } from './SettingsPanel';
import { ThreadCreateDialog } from './dialogs/ThreadCreateDialog';
import { LastMonthSpendSection } from './content/LastMonthSpendSection';
import { TotalActiveSavingsSection } from './content/TotalActiveSavingsSection';
import { SavingPotentialSection } from './content/SavingPotentialSection';
import { ThreadsStatusSavingsSection } from './content/ThreadsStatusSavingsSection';
import { DifficultySavingsSection } from './content/DifficultySavingsSection';
import { SavingsChartSection } from './content/SavingsChartSection';
import { ThreadsTable } from './content/ThreadsTable';
import { OpportunitiesBlock } from './content/OpportunitiesBlock';
import { NonPayingBanner } from './NonPayingBanner';
import { formatRange } from 'helpers/formatter';
import { TotalSpendSection } from './content/TotalSpendSection';
import { CategorySavingsSection } from './content/CategorySavingsSection';
import { AssignSelectedButton } from './controls/AssignSelectedButton';
import { OpportunityActionButton } from './controls/OpportunityActionButton';
import { RadioButton } from 'shared/RadioButtonGroup';

const last7Days = moment().subtract(7, 'day');

const prefilterNew = (thread: Thread) => last7Days.isBefore(thread.created_at);
const prefilterStale = (thread: Thread) => last7Days.isAfter(
  moment.max(
    moment(thread.opportunities_activity.assigned ?? moment.utc('0001-01-01')),
    moment(thread.opportunities_activity.validation ?? moment.utc('0001-01-01')),
    moment(thread.opportunities_activity.implementation ?? moment.utc('0001-01-01')),
  )
)

export const SavingsDashboardPage = () => {
  const [selected, setSelected] = useState<Opportunity[]>([]);

  const prefilterNewOpportunities = useMemo(() => {
    const last7Days = moment().subtract(7, 'day');

    return {
      created_at_start_date: last7Days.format('YYYY-MM-DD')
    };
  }, []);

  const prefilterStaleOpportunities = useMemo(() => {
    const last8Days = moment().subtract(8, 'day');

    return {
      status: ['assigned', 'validation', 'implementation'],
      status_updated_end_date: last8Days.format('YYYY-MM-DD')
    };
  }, []);

  const prefilterRecentlyRejectedOpportunities = useMemo(() => {
    const last7Days = moment().subtract(7, 'day');

    return {
      status: ['rejected'],
      status_updated_start_date: last7Days.format('YYYY-MM-DD')
    };
  }, []);

  const threadsButton = (
    <Button
      to='/savings/threads'
      size='xs'
      theme='link'
      layout='inline'
      iconRight='arrow-right'
    >
      See All Threads
    </Button>
  );

  const opportunityButton = (
    <Button
      to='/savings/opportunities'
      size='xs'
      theme='link'
      layout='inline'
      iconRight='arrow-right'
    >
      See All Opportunities
    </Button>
  );

  return (
    <Page>
      <Page.Head
        title='Savings Dashboard'
        subtitle={formatRange(moment().startOf('month'), moment().subtract(1, 'day'), 'day')}
      >
        <SettingsPanel 
          selected={selected}
          onSelect={setSelected}
        />
      </Page.Head>

      <Grid className='pb-[60px]'>
        <NonPayingBanner className='col-span-12' />

        <TotalSpendSection className='col-span-3' />
        <LastMonthSpendSection className='col-span-3' />
        <TotalActiveSavingsSection className='col-span-3' />
        <SavingPotentialSection className='col-span-3' />

        <CategorySavingsSection className='col-span-12' />

        <ThreadsStatusSavingsSection className='col-span-6' />
        <DifficultySavingsSection className='col-span-6' />

        <SavingsChartSection className='col-span-12' />

        <Section className='col-span-12 p-[20px] relative'>
          <ThreadsTable
            title='New Threads'
            subtitle='Created within last 7 days'
            extraActions={threadsButton}
            prefilter={prefilterNew}
            pagination={{
              pageSize: 5,
              className: 'absolute bottom-[-10px] right-0'
            }}
          />
        </Section>

        <Section className='col-span-12 p-[20px] relative'>
          <ThreadsTable
            title='Stale Threads'
            subtitle='Threads with opportunities in progress with no update within last 7 days'
            extraActions={threadsButton}
            prefilter={prefilterStale}
            pagination={{
              pageSize: 5,
              className: 'absolute bottom-[-10px] right-0'
            }}
          />
        </Section>

        <Section className='col-span-12 p-[20px] relative'>
          <OpportunitiesBlock
            title='New Opportunities'
            subtitle='Opportunities detected in last 7 days'
            prefilter={prefilterNewOpportunities}
            selected={selected}
            onSelect={setSelected}
            extraHeaderActions={opportunityButton}
            extraOpportunityActionsLeft={
              <AssignSelectedButton
                selected={selected}
                onSelect={setSelected}
              />
            }
            extraStatusFilters={<RadioButton value='unassigned'>Unassigned</RadioButton>}
            extraTableActions={{
              title: 'Actions',
              key: 'actions',
              width: 70,
              fixed: 'right' as 'right',
              render: (opp: Opportunity) => <OpportunityActionButton opportunity={opp} />
            }}
          />
        </Section>

        <Section className='col-span-12 p-[20px] relative'>
          <OpportunitiesBlock
            title='Stale Opportunities'
            subtitle='Opportunities in progress with no updates in last 7 days'
            prefilter={prefilterStaleOpportunities}
            extraHeaderActions={opportunityButton}
          />
        </Section>
 
        <Section className='col-span-12 p-[20px] relative'>
          <OpportunitiesBlock
            title='Rejected Opportunities'
            subtitle='Opportunities rejected in last 7 days'
            prefilter={prefilterRecentlyRejectedOpportunities}
            extraHeaderActions={opportunityButton}
          />
        </Section>
      </Grid>

      <ThreadCreateDialog ignorePageFilters={false}/>
    </Page>
  );
};
