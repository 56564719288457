import type { DictRule } from 'types/filters';
import { useMemo, useCallback } from 'react';
import { Select } from 'antd';

export interface DictRuleValueProps {
  rule: DictRule;
  value: Record<string, string>;
  options: Record<string, string[]>;
  siblingValues: Record<string, string>[];
  disabled?: boolean;
  onChange: (value: Record<string, string>) => void;
}

export const DictRuleValue = (props: DictRuleValueProps) => {
  const {
    options,
    siblingValues,
    disabled,
    onChange
  } = props;

  const [key, value] = Object.entries(props.value)[0] || ['', ''];

  const keyOptions = useMemo(() => Object.keys(options).map((key) => ({ key, value: key })), [options]);

  const valueOptions = useMemo(() => {
    if (!key || !options[key]) {
      return [];
    }
    
    const sameKeySiblingValues = new Set(siblingValues
      .map((siblingValue) => Object.entries(siblingValue)[0])
      .filter(Boolean)
      .filter(([siblingKey]) => siblingKey == key)
      .map(([_, value]) => value));

    return options[key]
      .filter((opt) => !sameKeySiblingValues.has(opt))
      .map((opt) => ({
        key: opt,
        value: opt
      }));
  }, [key, options]);

  const handleKeyChange = useCallback((key: string) => {
    onChange({ [key]: '' });
  }, [onChange]);

  const handleValueChange = useCallback((value: string) => {
    onChange({ [key]: value });
  }, [key, onChange]);

  return (
    <div className='flex items-center min-w-0 grow shrink'>
      <div className='flex w-1/2 min-w-0 basis-1/2 shrink'>
        <Select
          value={key}
          options={keyOptions}
          onChange={handleKeyChange}
          disabled={disabled}
          className='grow'
          showSearch
          placeholder='Key'
        />
      </div>

      <span className='mx-[5px] text-[20px] font-bold'>:</span>

      <div className='flex w-1/2 min-w-0 basis-1/2 shrink'>
        <Select
          value={value}
          options={valueOptions}
          onChange={handleValueChange}
          disabled={!key || disabled}
          className='grow'
          showSearch
          placeholder='Value'
        />
      </div>
    </div>
  );
};
