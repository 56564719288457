import { message, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Button2 as Button } from 'shared/Button';
import { Dialog, DialogBody } from 'shared/Dialog';
import { FormRow, Label } from 'shared/Form';

import { useCostOverviewStore } from 'pages/CostsOverview/useCostOverviewStore';
import { useSaveViewDialog } from 'store/costLab';

export const SaveViewDialog = () => {
  const { opened, close } = useSaveViewDialog();

  const {
    newViewName,
    setNewViewName,
    saveView
  } = useCostOverviewStore();

  const navigate = useNavigate()

  const handleSaveClick = async () => {
    try {
      const view = await saveView();

      close();
      message.success(`Cost view '${view.name}' saved`);
      navigate(`/costs-overview/${view.id}`);
    } catch (error) {
      message.error(`Unable to save '${newViewName}'`);
    }
  }

  return (
    <Dialog
      className='w-[420px]'
      open={opened}
      onClose={close}
    >
      <Dialog.Title>
        Save as New View
      </Dialog.Title>

      <DialogBody className='mb-8'>
        <FormRow size='m'>
          <Label size='m'>
            View Name
          </Label>

          <Input 
            size='large'
            value={newViewName}
            onChange={(e) => {
              setNewViewName(e.target.value);
            }}
          />
        </FormRow>

        <Button
          className='w-full'
          size='m'
          disabled={!newViewName}
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </DialogBody>
    </Dialog>
  );
};
