import type { AllHTMLAttributes } from 'react';

export interface SimpleTableProps extends AllHTMLAttributes<HTMLTableElement> {}

export const SimpleTable = (props: SimpleTableProps) => {
  const {
    className = ''
  } = props;

  return <table
    {...props}
    className={`w-full ${className}`}
  />;
}

export interface SimpleTableRowProps extends AllHTMLAttributes<HTMLTableRowElement> {}

export const SimpleTableRow = (props: SimpleTableRowProps) => {
  const {
    className = ''
  } = props;

  return <tr
    {...props}
    className={`border-b last:border-b-0 border-black/10 hover:bg-gray-50 ${className}`}
  />
}

export interface SimpleTableCellProps extends AllHTMLAttributes<HTMLTableCellElement> {}

export const SimpleTableCell = (props: SimpleTableCellProps) => {
  const {
    className = ''
  } = props;

  return <td
    {...props}
    className={`py-2 ${className}`}
  />
}

export const SimpleTableHeadCell = (props: SimpleTableCellProps) => {
  const {
    className = ''
  } = props;

  return <th
    {...props}
    className={`text-caption-1 ${className}`}
  />
}
