import type { Setter } from 'types/common';
import type { NumberRangeDataFilterOptions, NumberRangeDataFilterConfig, NumberRangeDataFilterValue } from 'types/dataFilters';

import { useState, useEffect } from 'react';
import { InputNumber, Slider } from 'antd';
import { DropdownFooter } from './DropdownFooter';

export interface RangeDataFilterDropdownProps {
  opened: boolean;
  value: NumberRangeDataFilterValue;
  config: NumberRangeDataFilterConfig;
  options: NumberRangeDataFilterOptions;
  onChange: Setter<NumberRangeDataFilterValue>;
}

export const RangeDataFilterDropdown = (props: RangeDataFilterDropdownProps) => {
  const {
    opened,
    value,
    config,
    options,
    onChange
  } = props;

  const {
    step,
    prefix
  } = config;

  const [localValue, setLocalValue] = useState<NumberRangeDataFilterValue>(() => value || options);

  useEffect(() => {
    if (opened) {
      setLocalValue(value || options);
    }
  }, [opened]);

  return (
    <div className='w-[320px] mx-[-16px] my-[-12px]'>
      <div className='flex justify-between p-[8px]'>
        <div>
          <div className='font-medium'>
            From:
          </div>

          <InputNumber
            size='small'
            prefix={config.prefix}
            min={options.min}
            max={options.max}
            //step={config.step}
            value={localValue.min}
            onChange={(min) => {
              setLocalValue({
                ...localValue,
                min: min || 0
              });
            }}
          />
        </div>

        <div>
          <div className='text-[11px] font-medium'>
            To:
          </div>

          <InputNumber
            size='small'
            prefix={prefix}
            min={options.min}
            max={options.max}
            step={step}
            value={localValue.max}
            onChange={(max) => {
              setLocalValue({
                ...localValue,
                max: max || 0
              });
            }}
          />
        </div>
      </div>

      <div className='m-[8px] mb-[16px]'>
        <Slider
          range
          min={options.min}
          max={options.max}
          step={step}
          value={[localValue.min, localValue.max] as [number, number]}
          onChange={([min, max]) => {
            setLocalValue({ min, max });
          }}
        />
      </div>

      <DropdownFooter
        onReset={() => {
          onChange({ min: null, max: null });
        }}
        onApply={() => {
          onChange({
            min: localValue.min === options.min ? null : localValue.min,
            max: localValue.max === options.max ? null : localValue.max
          });
        }}
      />
    </div>
  );
}
