import type {
  FilterValueCondition,
  FilterGroupCondition,
  FilterNestedCondition,
  FilterCondition,
  FilterNestedConditionItem
} from 'types/filters';

import {
  isFilterNestedCondition,
  isFilterGroupCondition,
  isFilterValueCondition,
  isFilterStringValueCondition
} from 'types/filters'

export const cleanupValueCondition = <TCondition extends FilterValueCondition>(cond: TCondition): TCondition | null => {
  const newCond: TCondition = {
    ...cond,
    values: isFilterStringValueCondition(cond) ?
      cond.values.filter(Boolean) :
      cond.values.filter(Boolean).filter((val) => Object.keys(val).length && val[Object.keys(val)[0]])
  };

  return newCond.values.length ? newCond : null;
};

export const cleanupGroupCondition = <TCondition extends FilterGroupCondition>(condition: TCondition): TCondition | null => {
  const newCondition = {
    ...condition,
    condition: condition.condition.map(cleanupCondition).filter(Boolean)
  }

  return newCondition.condition.length ? newCondition : null;
}

export const cleanupNestedConditionItem = (item: FilterNestedConditionItem): FilterNestedConditionItem | null => {
  const [value, ...children] = item.condition

  const newValue = cleanupValueCondition(value);
  
  if (!newValue) {
    return null;
  }

  const newChildren = children.map(cleanupNestedCondition).filter(Boolean) as FilterNestedCondition[];

  const newItem: FilterNestedConditionItem = {
    ...item,
    condition: [
      newValue,
      ...newChildren
    ]
  };

  return newItem;
}

const cleanupNestedCondition = <TCondition extends FilterNestedCondition>(cond: TCondition): TCondition | null => {
  const newCondition: TCondition = {
    ...cond,
    condition: cond.condition.map(cleanupNestedConditionItem).filter(Boolean) as FilterNestedConditionItem[]
  }

  return newCondition.condition.length ? newCondition : null;
}

export const cleanupCondition = <TCondition extends FilterCondition>(condition: TCondition): TCondition | null => {
  /*
  if (isFilterNestedCondition(condition)) {
    return cleanupNestedCondition(condition);
  }
  */

  if (isFilterGroupCondition(condition)) {
    return cleanupGroupCondition(condition);
  }

  if (isFilterValueCondition(condition)) {
    return cleanupValueCondition(condition);
  }

  return null;
}
