import type { DataEntry } from 'types/dataEntries';
import type { BillingOverview } from 'types/billing';

import create from 'zustand';
import { fetchBillingOverview, cancelSubscription } from 'services/billing';

export interface BillingStore {
  overview: DataEntry<BillingOverview>;
  getOverview: () => DataEntry<BillingOverview>;
  cancelSubscription: () => Promise<void>;
}

export const useBillingStore = create<BillingStore>((set, get) => ({
  overview: { status: 'idle' },

  getOverview: () => {
    let { overview } = get();

    if (overview.status === 'idle') {
      fetchBillingOverview()
        .then(({ data }) => {
          set({
            overview: {
              status: 'success',
              data
            }
          });
        })
        .catch((error) => {
          set({
            overview: {
              status: 'error',
              error
            }
          });
        });

      overview = { status: 'loading' };
      set({ overview });
    }

    return overview;
  },

  cancelSubscription: () => {
    return cancelSubscription()
      .then(() => {
        set({ overview: { status: 'idle' }});
      });
  }
}));
