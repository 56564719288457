import { ClassNameProps } from 'props'
import { ReactComponent as InfoIcon } from 'assets/images/Info.svg'
import {
  ReactNode, useEffect, useRef, useState,
} from 'react'

export interface TooltipProps extends ClassNameProps {
  children: ReactNode
}

const Tooltip = ({ children, className }: TooltipProps) => {
  const [open, setOpen] = useState(false)
  const [style, setStyle] = useState({ left: '', top: '-1000px' })
  const tooltipRef = useRef<HTMLDivElement>(null)
  const iconRef = useRef<SVGSVGElement>(null)

  const handleMouseover = (el: HTMLElement) => {
    if (open) {
      return
    }

    setOpen(true)
  }

  useEffect(() => {
    const rect = tooltipRef.current?.getBoundingClientRect()
    if (rect) {
      const iconRect = iconRef.current!.getBoundingClientRect()
      const left = Math.max(0, iconRect.x - rect.width / 2)
      const top = Math.max(0, iconRect.y + 20)

      setStyle({
        left: `${left}px`,
        top: `${top}px`,
      })
    }
  }, [open])

  return (
    <>
      <InfoIcon
        className={className}
        onMouseEnter={(evt) => handleMouseover(evt.target as HTMLElement)}
        onMouseLeave={() => setOpen(false)}
        ref={iconRef}
      />
      {open && <div className="fixed p-2 rounded bg-caladon-blue/95 text-white z-50 text-[12px]" style={style} ref={tooltipRef}>{children}</div>}
    </>
  )
}

export default Tooltip
