import { createKeyedStore } from '../createKeyedStore';
import { fetchCostImpactSummary } from 'services/savings';
import { costImpactSummaryParamsToKey } from 'helpers/savings';
import { createKeyedStoreSelector } from 'store/createKeyedStoreSelector';

export const useCostImpactSummaryStore = createKeyedStore({
  fetchData: fetchCostImpactSummary,
  paramsToKey: costImpactSummaryParamsToKey
});

export const useCostImpactSummary = createKeyedStoreSelector(useCostImpactSummaryStore);
