import { useState } from 'react';
import type { 
  Opportunity,
  OpportunityPagination
} from 'types/savings';

import { Page } from 'shared/Page';
import { Button2 as Button } from 'shared/Button';
import { Section } from 'shared/Section';
import { RadioButton } from 'shared/RadioButtonGroup';

import { SettingsPanel } from './SettingsPanel';
import { NonPayingBanner } from './NonPayingBanner';
import { DataCollectionBanner } from './DataCollectionBanner';
import { OpportunitiesBlock } from './content/OpportunitiesBlock';
import { AssignSelectedButton } from './controls/AssignSelectedButton';
import { OpportunityActionButton } from './controls/OpportunityActionButton';
import { OpportunityCreateButton } from './controls/OpportunityCreateButton';

import {
  ThreadCreateDialog,
  ThreadSelectDialog,
  OpportunityTeamDialog,
  OpportunityAutomationDialog,
  CustomOpportunityDialog
} from './dialogs';

export const OpportunitiesPage = () => {
  const [selected, setSelected] = useState<Opportunity[]>([]);

  const [pagination, setPagination] = useState<OpportunityPagination>({
    ids: [],
    perPage: 10
  });

  return (
    <Page>
      <Page.Head title={
        <div className='flex items-center gap-2'>
          <span className='font-bold text-subtitle-2'>Opportunities Explorer</span>
        </div>
      }>
        <SettingsPanel 
          selected={selected}
          onSelect={setSelected}
        />
      </Page.Head>

      <NonPayingBanner className='mb-3' />

      <DataCollectionBanner />

      <Section className='py-4 px-5 mb-[60px]'>
        <OpportunitiesBlock
          title='Opportunities'
          selected={selected}
          onSelect={setSelected}
          useQuery
          pagination={pagination}
          onPagination={setPagination}
          extraOpportunityActionsLeft={
            <AssignSelectedButton
              selected={selected}
              onSelect={setSelected}
            />
          }
          extraStatusFilters={<RadioButton value='unassigned'>Unassigned</RadioButton>}
          extraTableActions={{
            title: 'Actions',
            key: 'actions',
            width: 70,
            fixed: 'right' as 'right',
            render: (opp: Opportunity) => <OpportunityActionButton opportunity={opp} />
          }}
          extraTableFooters={
            <div>
              <Button
                size='s'
                theme='black'
                layout='inline'
                iconLeft='settings'
                to='/settings/savings-opportunities'
              >
                Customize Savings Opportunities Thresholds
              </Button>

              <OpportunityCreateButton />
            </div>
          }
        />
      </Section>

      <ThreadCreateDialog ignorePageFilters={false}/>
      <ThreadSelectDialog />
      <CustomOpportunityDialog />
      <OpportunityTeamDialog />
      <OpportunityAutomationDialog />
    </Page>
  );
};
