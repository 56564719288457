import { Grid } from 'shared/Grid';
import { Section, SectionGroupTitle } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { JSONDump } from './JSONDump';
import { renderOpportunityDetails } from 'helpers/savings';
import { useSavingsStore } from 'store/savings';

export interface OpportunityFullMetadataSectionProps {
  opportunityId: number;
  className?: string;
}

export const OpportunityFullMetadataSection = (props: OpportunityFullMetadataSectionProps) => {
  const { opportunityId, className = '' } = props;

  const opportunity = useSavingsStore((store) => store.getOpportunity(opportunityId));

  return (
    <Grid className={className}>
      <SectionGroupTitle className='col-span-12'>
        Full Metadata
      </SectionGroupTitle>

      <Section className='relative p-5 col-span-12'>
        <JSONDump
          replacer={(key: string, value: any) => {
            if ([
              'usage_historical',
              'usage_historical_src',
              'cost_historical',
              'cost_historical_src',
              'flagged_resource'
            ].includes(key)) {
              return undefined;
            }

            return value;
          }}
        >
          {opportunity.status === 'success' && opportunity.data}
        </JSONDump>

        <StatusOverlay status={opportunity.status} />
      </Section>
    </Grid>
  );
};
