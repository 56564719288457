import type {
  Opportunity,
  SortDir,
  OpportunitySortField
} from 'types/savings';

import { isPaywalledOpportunity } from 'types/savings';

import {
  createNumbersComparator,
  createStringsComparator,
  createDatesComparator
} from 'helpers/sort';

import {
  pickOpportunityAccount,
  pickOpportunityCostImpact,
  pickOpportunityCreatedAt,
  pickOpportunityDifficultyWeight,
  pickOpportunityGroupWeight,
  pickOpportunityLastDetectedAt,
  pickOpportunityPriority,
  pickOpportunityRecordId,
  pickOpportunityRegion,
  pickOpportunityService,
  pickOpportunityStatusWeight,
  pickOpportunityTagsCount,
  pickOpportunityAccountTagsCount
} from './pick';

import {
  renderOpportunityTypeIdString
} from './render';

type Sorter = (oppA: Opportunity, oppB: Opportunity) => number;

const decorateSorter = (sorter: Sorter): Sorter => (oppA: Opportunity, oppB: Opportunity) => {
  if (isPaywalledOpportunity(oppA)) {
    return -1;
  }

  if (isPaywalledOpportunity(oppB)) {
    return 1;
  }

  return sorter(oppA, oppB);
}

export const compareSlug = createNumbersComparator((opp: Opportunity) => opp.secondary_id);
export const compareRecordId = decorateSorter(createStringsComparator(pickOpportunityRecordId));
export const compareCostImpact = createNumbersComparator(pickOpportunityCostImpact);
export const compareDifficulty = decorateSorter(createNumbersComparator(pickOpportunityDifficultyWeight));
export const comparePriority = decorateSorter(createNumbersComparator(pickOpportunityPriority));

export const compareGroup = createNumbersComparator(pickOpportunityGroupWeight, true);
export const compareCategory = decorateSorter(createStringsComparator(renderOpportunityTypeIdString, true));
export const compareStatus = decorateSorter(createNumbersComparator(pickOpportunityStatusWeight, true));

export const compareServices = decorateSorter(createStringsComparator(pickOpportunityService, true));
export const compareAccounts = decorateSorter(createStringsComparator(pickOpportunityAccount, true));
export const compareRegions = decorateSorter(createStringsComparator(pickOpportunityRegion, true));
export const compareTags = decorateSorter(createNumbersComparator(pickOpportunityTagsCount));
export const compareAccountTags = decorateSorter(createNumbersComparator(pickOpportunityAccountTagsCount));

export const compareFirstDetected = decorateSorter(createDatesComparator(pickOpportunityCreatedAt));
export const compareLastDetected = decorateSorter(createDatesComparator(pickOpportunityLastDetectedAt));

export const DEFAULT_SORT_DIRS: Record<OpportunitySortField, SortDir> = {
  id: 'ascend',
  recordId: 'ascend',
  costImpact: 'descend',
  difficulty: 'ascend',
  group: 'ascend',
  category: 'ascend',
  status: 'ascend',
  //account: 'ascend',
  //region: 'ascend',
  //service: 'ascend',
  //tags: 'descend',
  //account_tags: 'descend',
  //firstDetected: 'descend',
  //lastDetected: 'descend',
  priority: 'descend'
};
