import type { ReactNode, ComponentProps } from 'react';
import type { CostDimension } from 'types/costViews';

import { useCallback } from 'react';
import { Checkbox } from 'antd';

import { COST_DIMENSIONS } from 'types/costViews';
import { useCostDimensions, useCostTypeValue } from 'hooks/costFilter';

const COST_DIMENSION_LABELS: Record<CostDimension, ReactNode> = {
  Tax: 'Taxes',
  Credit: 'Credits',
  Refund: 'Refunds',
  Discount: 'Discounts',
  Fee: 'Fees',
};

export const CostDimensionsControl = () => {
  const [value, onChange] = useCostDimensions();
  const valueSet = new Set(value);

  const handleChange: ComponentProps<typeof Checkbox>['onChange'] = useCallback((event) => {
    const dimension = event.target.value;
    const excluded = !event.target.checked;

    if (excluded) {
      onChange([...value, dimension]);
    } else {
      onChange(value.filter((dim) => dim !== dimension));
    }
  }, [value, onChange]);

  return (
    <>
      {COST_DIMENSIONS.map((costDimension) => (
        <div
          key={costDimension}
          className='my-1'
        >
          <Checkbox
            value={costDimension}
            checked={!valueSet.has(costDimension)}
            onChange={handleChange}
          >
            {COST_DIMENSION_LABELS[costDimension]}
          </Checkbox>
        </div>
      ))}
    </>
  )
};
