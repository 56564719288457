import type { DataEntry } from 'types/dataEntries';
import type { Tags, TagValue } from 'types/tags';
import { useState } from 'react';
import { Button } from 'shared/Button';
import { TagPartSelect } from './TagPartSelect';
import { useTagsStore } from 'store/tags';

export interface AddTagControlProps {
  tags: Tags;
  onAdd: (key: string, value: string) => void;
  onKeyCreate: (key: string) => void;
  onValueCreate: (value: string) => void;
};

export const AddTagControl = (props: AddTagControlProps) => {
  const {
    tags,
    onAdd,
    onKeyCreate,
    onValueCreate
  } = props;

  const keys = useTagsStore((store) => store.getKeys());
  const [key, setKey] = useState<string>('');

  const keysOptions = keys.status === 'success' ?
    keys.data
      .map(({ key }) => key)
      .filter((key) => tags[key] === undefined) :
    []

  const values = useTagsStore((store) => {
    if (key && keys.status === 'success') {
      const id = keys.data.find((k) => k.key === key)?.id;

      if (id !== undefined) {
        return store.getValues(id);
      }
    }

    return { status: 'idle' } as DataEntry<TagValue[]>;
  });

  const [value, setValue] = useState<string>('');

  return (
    <div className='flex mb-[20px]'>
      <TagPartSelect
        value={key}
        disabled={keys.status !== 'success'}
        options={keysOptions}
        onChange={setKey}
        onCreate={onKeyCreate}
      />

      <div className='mx-[10px] font-medium text-[20px]'>
        :
      </div>

      <TagPartSelect
        value={value}
        disabled={values.status !== 'success'}
        options={values.status === 'success' ? values.data.map((value) => value.value) : []}
        onChange={setValue}
        onCreate={onValueCreate}
      />

      <Button
        label='Add Tag'
        size='sm'
        className='ml-[10px]'
        disabled={!key || !value}
        onClick={() => {
          onAdd(key, value);
          setKey('');
          setValue('');
        }}
      />
    </div>
  );
};
