import { Button2 as Button } from 'shared/Button';
import { useInviteDialogStore } from 'store/inviteDialogStore';

export const InviteDialogButton = () => {
  const open = useInviteDialogStore((store) => store.open);

  return <Button
    size='s'
    theme='link'
    iconLeft='circle-plus'
    onClick={open}
  >
    Invite User
  </Button>;
}
