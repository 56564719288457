import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'shared/Icon/Icon';

export const WelcomeBanner = () => {
  const [visible, setVisible] = useState(true);

  return visible ? (
    <div className='flex justify-center invisible'>
      <div className='visible mx-[100px] my-[12px] py-[8px] px-[12px] bg-cyanine-blue rounded-[4px] drop-shadow-xl text-white relative'>
        <div className='font-medium mb-[4px]'>
          <Link to='/welcome' className='hover:text-white hover:opacity-90'>
            Welcome to Cloudthread!
          </Link>
        </div>
        <Icon icon='close' className='absolute right-[12px] top-[12px] cursor-pointer' onClick={() => { setVisible(false); }} />
        Some of our features will be available when your CUR data is ready – come back in 24 hours!
      </div>
    </div>
  ) : null;
}
