import AppRoutes from 'Routes'
import { ConfigProvider } from 'antd';
import { Chat } from 'shared/Chat';

function App() {
  return (
    <ConfigProvider theme={{
      //algorithm: theme.compactAlgorithm,
      token: {
        fontFamily: 'Inter, sans-serif',
        colorText: '#14213d',
        colorBorder: '#D3DCE6',
        colorPrimary: '#3B7EA1',
        colorTextPlaceholder: '#9CA5AD',

        controlHeightLG: 40,
        controlHeight: 30,
        controlHeightSM: 22,
        controlHeightXS: 20,

        fontSize: 13,
        fontSizeLG: 15,
        fontSizeSM: 12
      },
      components: {
        Select: {
          
        }
      }
    }}>
      <AppRoutes />
      <Chat />
    </ConfigProvider>
  )
}

export default App
