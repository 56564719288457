import type { Setter } from 'types/common';

import type {
  Opportunity,
  OpportunityPagination,
  OpportunitySort,
  OpportunitySortField,
} from 'types/savings';

import type {
  ColumnType,
  SorterResult
} from 'antd/lib/table/interface';

import { ReactNode } from 'react';
import { Select, Table } from 'antd';
import { Dummy } from '../Dummy';
import { Button2 as Button } from 'shared/Button';
import { PaywallOverlay } from './PaywallOverlay';

import {
  DEFAULT_SORT_DIRS,
  renderOpportunityAccount,
  renderOpportunityCostImpact,
  renderOpportunityPriority,
  renderOpportunityCreatedAt,
  renderOpportunityDifficulty,
  renderOpportunityPlatform,
  renderOpportunityGroup,
  renderOpportunityLastDetectedAt,
  renderOpportunityLink,
  renderOpportunityRecordId,
  renderOpportunityRegion,
  renderOpportunityService,
  renderOpportunityStatus,
  renderOpportunityTags,
  renderOpportunityThread,
  renderOpportunityTypeId,
  renderOpportunityAccountTags,
} from 'helpers/savings';

export interface OpportunitiesTableProps {
  opportunities: Opportunity[];
  paywalled?: boolean;
  sort: OpportunitySort,
  onSort: (sort: OpportunitySort) => void;
  selected?: Opportunity[];
  onSelect?: (selected: Opportunity[]) => void;
  pagination: OpportunityPagination;
  onPagination: Setter<OpportunityPagination>;
  extraTableActions?: ColumnType<Opportunity>;
  extraTableFooters?: ReactNode;
}

export const OpportunitiesTable = (props: OpportunitiesTableProps) => {
  const {
    opportunities,
    paywalled,

    sort,
    onSort,

    selected,
    onSelect,

    pagination,
    onPagination,
    extraTableActions,
    extraTableFooters,
  } = props;

  const getColumnSort = (field: OpportunitySortField) => sort.field === field ? sort.dir : null;

  const columns: ColumnType<Opportunity>[] = [
    {
      title: 'ID',
      key: 'id',
      fixed: 'left',
      width: 100,
      render: renderOpportunityLink,
      sorter: true,
      sortOrder: getColumnSort('id')
    },
    {
      title: 'Category',
      key: 'category',
      width: 300,
      render: renderOpportunityTypeId,
      sorter: true,
      sortOrder: getColumnSort('category')
    },
    {
      title: 'Active Savings / Mo',
      key: 'costImpact',
      width: 160,
      align: 'right',
      render: renderOpportunityCostImpact,
      sorter: true,
      sortOrder: getColumnSort('costImpact')
    },
    {
      title: 'Difficulty',
      key: 'difficulty',
      width: 100,
      render: renderOpportunityDifficulty,
      sorter: true,
      sortOrder: getColumnSort('difficulty')
    },
    {
      title: 'Priority',
      key: 'priority',
      width: 80,
      align: 'right',
      render: renderOpportunityPriority,
      sorter: true,
      sortOrder: getColumnSort('priority'),
    },
    {
      title: 'Service',
      key: 'service',
      width: 200,
      render: renderOpportunityService
    },
    {
      title: 'Status',
      key: 'status',
      width: 120,
      render: renderOpportunityStatus,
      sorter: true,
      sortOrder: getColumnSort('status')
    },
    {
      title: 'Thread',
      key: 'thread',
      width: 240,
      render: renderOpportunityThread,
    },
    {
      title: 'Record ID',
      key: 'recordId',
      width: 240,
      className: 'z-1',
      render: renderOpportunityRecordId,
      sorter: true,
      sortOrder: getColumnSort('recordId')
    },
    {
      title: 'Group',
      key: 'group',
      width: 80,
      render: renderOpportunityGroup,
      sorter: true,
      sortOrder: getColumnSort('group')
    },
    {
      title: 'Resource Tags',
      key: 'tags',
      width: 120,
      render: renderOpportunityTags
    },
    {
      title: 'Account',
      key: 'account',
      width: 200,
      render: renderOpportunityAccount
    },
    {
      title: 'Account Tags',
      key: 'account_tags',
      width: 120,
      render: renderOpportunityAccountTags
    },
    {
      title: 'Region',
      key: 'region',
      width: 120,
      render: renderOpportunityRegion,
    },
    {
      title: 'Platform',
      key: 'platform',
      width: 160,
      render: renderOpportunityPlatform
    },
    {
      title: 'First Detected',
      key: 'firstDetected',
      width: 100,
      render: renderOpportunityCreatedAt
    },
    {
      title: 'Last Detected',
      key: 'lastDetected',
      width: 100,
      render: renderOpportunityLastDetectedAt
    },
  ];

  if (extraTableActions) {
    columns.push(extraTableActions)
  }

  /*
  const pagination = {
    ...(paywalled ? {
      pageSize: 5,
      pageSizeOptions: [5]
    } : {
      //pageSize: 10,
      pageSizeOptions: [10, 20, 50, 100]
    }),
    simple: true,
    className: '!absolute bottom-0 right-[10px] !my-[10px]'
  }
   */

  const rowSelection = selected && onSelect ? {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selected.map(({ id }) => id),
    onChange: (_: any, selected: any[]) => {
      onSelect(selected);
    }
  } : undefined;

  const summary = paywalled ? (records: readonly Opportunity[]) => {
    if (records.length === 0) {
      return null;
    }

    const rows: React.ReactElement[] = [];

    for (let i = 0; i < 5; i++) {
      const cells: React.ReactElement[] = [];

      let max = columns.length

      if (selected && onSelect) {
        max++;
      }

      for (let j = 0; j < max; j++) {
        cells.push(
          <Table.Summary.Cell index={j} key={j}>
            <Dummy height={14} />
          </Table.Summary.Cell>
        );
      }
      
      rows.push(
        <Table.Summary.Row key={i}>
          {cells}
        </Table.Summary.Row>
      );
    }

    return (
      <Table.Summary>
        {rows}
      </Table.Summary>
    );
  } : undefined;

  return (
    <div className='relative'>
      <Table
        className='w-full'
        size='small'
        rowKey={({ id }) => id}
        scroll={{ x: 3150 }}
        columns={columns}
        dataSource={opportunities}
        pagination={false}
        rowSelection={rowSelection}
        showSorterTooltip={false}
        summary={summary}
        footer={() =>
          <div className='flex'>
            {extraTableFooters}

            <div className='flex items-center ml-auto'>
              <Button
                size='xs'
                theme='black'
                icon='arrow-left'
                disabled={pagination.ids.length === 0}
                onClick={() => {
                  onPagination({
                    ...pagination,
                    ids: pagination.ids.slice(0, -1)
                  });
                }}
              />

              <Button
                size='xs'
                theme='black'
                icon='arrow-right'
                onClick={() => {
                  onPagination({
                    ...pagination,
                    ids: [...pagination.ids, opportunities[opportunities.length - 1].id]
                  });
                }}
              />
              
              {!paywalled &&
                <Select
                  className='ml-2'
                  size='small'
                  value={pagination.perPage}
                  options={[
                    { value: 10, label: '10 / page' },
                    { value: 20, label: '20 / page' },
                    { value: 50, label: '50 / page' },
                    { value: 100, label: '100 / page' }
                  ]}
                  onChange={(perPage) => {
                    onPagination({
                      ids: [],
                      perPage
                    });
                  }}
                />
              }
            </div>
          </div>
        }
        onChange={(_pagination, _tableFilters, tableSort, meta) => {
          if (meta.action === 'sort') {
            const field = (tableSort as SorterResult<Opportunity>).columnKey as OpportunitySortField;
            const dir = sort.field !== field ?
              DEFAULT_SORT_DIRS[field] :
              sort.dir === 'ascend' ? 'descend' : 'ascend';

            onSort({ field, dir });
          }
        }}
      />

      {opportunities.length > 0 && paywalled && 
        <PaywallOverlay />
      }
    </div>
  );
}
