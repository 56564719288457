import type {
  UnitMetricFilter,
  UnitMetricConstructor
} from 'types/unitMetrics';

import { Filter } from './Filter';
import { SourceControl } from './SourceControl';
import { TeamFilterDisplay } from './TeamFilterDisplay';
import { parseUnitMetricFilter } from 'helpers/unitMetrics';
import { TeamFilterConstructor, TeamFilterMap, TeamFilterSource } from 'types/teams';

export interface UnitMetricFiltersProps {
  value: UnitMetricFilter;
  unitMetricConstructor: UnitMetricConstructor;
  disabled?: boolean;
  onChange: (value: UnitMetricFilter) => void;
  teamFilters: TeamFilterMap;
  teamFilterConstructor: TeamFilterConstructor;
}

export const UnitMetricFilters = (props: UnitMetricFiltersProps) => {
  const {
    value,
    unitMetricConstructor,
    disabled,
    onChange,
    teamFilters,
    teamFilterConstructor
  } = props;

  const numeratorSources = Object.keys(unitMetricConstructor.numerator);
  const denominatorSources = Object.keys(unitMetricConstructor.denominator).map((key) => key === 'cur' ? { key: 'cur', value: 'AWS Usage' } : key);

  return(
    <div className='grow'>
      <div className='m-[15px] rounded border border-1 border-grey px-[15px] pt-[10px]'>
        <div className='flex items-center'>
          <div className='mr-auto font-medium text-subtitle-2'>
            Numerator
          </div>

          <SourceControl
            value={value.numerator.src}
            options={numeratorSources}
            disabled={disabled}
            onChange={(src) => {
              onChange(parseUnitMetricFilter({
                ...value,
                numerator: {
                  ...value.numerator,
                  src
                }
              }, unitMetricConstructor));
            }}
          />
        </div>
        
        <TeamFilterDisplay
          src={value.numerator.src as TeamFilterSource}
          filters={teamFilters}
          teamFilterConstructor={teamFilterConstructor}
        />

        <Filter
          value={value.numerator.filter}
          rules={unitMetricConstructor.numerator[value.numerator.src].rules}
          disabled={disabled}
          onChange={(filter) => {
            onChange({
              ...value,
              numerator: {
                ...value.numerator,
                filter
              }
            });
          }}
        />

      {/*costViewConstructor && costViewConstructor.status === 'success' &&
        numeratorTeamFilter && numeratorTeamFilter.status === 'success' && (
          <>
            <div
              className='flex items-center border-t mx-[-15px] px-[15px] py-[10px] font-medium'
            >
              {numeratorTeamFilter.data.name}
              <IconButton
                size='xs'
                type='tertiary'
                className='ml-auto'
                to={`/costs-overview/${numeratorTeamFilter.data.id}`}
                icon='edit'
                disabled={!editTeamFilter}
              />
              <IconButton
                size='xs'
                type='tertiary'
                icon='eye'
                onClick={() => {
                  setTeamFiltersVisible({ ...teamFiltersVisible, numerator: !teamFiltersVisible.numerator });
                }}
              />
            </div>

            {teamFiltersVisible.numerator && (
              <CostViewFilters
                value={numeratorTeamFilter.data.filter}
                costViewConstructor={costViewConstructor.data}
                disabled
                onChange={() => {}}
              />
            )}
          </>
        ) */}
      </div>

      <div className='m-[15px] rounded border border-1 border-grey px-[15px] pt-[10px]'>
        <div className='flex items-center'>
          <div className='mr-auto font-medium text-subtitle-2'>
            Denominator
          </div>

          <SourceControl
            value={value.denominator.src}
            options={denominatorSources}
            disabled={disabled}
            onChange={(src) => {
              onChange(parseUnitMetricFilter({
                ...value,
                denominator: {
                  ...value.denominator,
                  src
                }
              }, unitMetricConstructor));
            }}
          />
        </div>
        
        <TeamFilterDisplay
          src={value.denominator.src as TeamFilterSource}
          filters={teamFilters}
          teamFilterConstructor={teamFilterConstructor}
        />

        <Filter
          value={value.denominator.filter}
          rules={unitMetricConstructor.denominator[value.denominator.src].rules}
          disabled={disabled}
          onChange={(filter) => {
            onChange({
              ...value,
              denominator: {
                ...value.denominator,
                filter
              }
            });
          }}
        />

      {/*costViewConstructor && costViewConstructor.status === 'success' &&
        denominatorTeamFilter && denominatorTeamFilter.status === 'success' && (
          <>
            <div
              className='flex items-center border-t mx-[-15px] px-[15px] py-[10px] font-medium'
            >
              {denominatorTeamFilter.data.name}
              <IconButton
                size='xs'
                type='tertiary'
                className='ml-auto'
                to={`/costs-overview/${denominatorTeamFilter.data.id}`}
                icon='edit'
              />
              <IconButton
                size='xs'
                type='tertiary'
                icon='eye'
                onClick={() => {
                  setTeamFiltersVisible({ ...teamFiltersVisible, denominator: !teamFiltersVisible.denominator });
                }}
                disabled={!editTeamFilter}
              />
            </div>
            {teamFiltersVisible.denominator && (
              <CostViewFilters
                value={denominatorTeamFilter.data.filter}
                costViewConstructor={costViewConstructor.data}
                disabled
                onChange={() => {}}
              />
            )}
          </>
        )*/}
      </div>
    </div>
  );
};
