import type { DataEntry, Action } from 'types/dataEntries';
import { Team, TeamDraft, TeamDeps, TeamPayload, isMutualExclusivityError } from 'types/teams';

import create, { PartialState } from 'zustand';
import { Map as ImmutableMap } from 'immutable';

import {
  fetchTeams,
  createTeam,
  deleteTeam,
  updateTeam,
} from 'services/teams';

import {
  parseTeamDraft,
  serializeTeamDraft
} from 'helpers/teams';
import {withKeyDep} from './create/decorators';

export type CreateTeamAction = Action<'create'>;
export type UpdateTeamAction = Action<'update'>;
export type DeleteTeamAction = Action<'delete'>;

export type TeamAction = CreateTeamAction | UpdateTeamAction | DeleteTeamAction;

export interface TeamsState {
  library: DataEntry<Team[]>;
  entries: ImmutableMap<number, DataEntry<Team>>;
  actions: ImmutableMap<string | number, TeamAction>;
  drafts: ImmutableMap<string | number, DataEntry<TeamDraft>>;
}

export interface TeamsActions {
  getLibrary: () => DataEntry<Team[]>;
  fetchLibrary: () => void;

  setAction: (key: string | number, action: TeamAction) => void;
  discardAction: (key: string | number) => void;

  getEntry: (id: number | DataEntry<number>) => DataEntry<Team>;
  setEntry: (data: Team) => void;
  discardEntry: (id: number) => void;
  
  getDraft: (key: string | number, deps: DataEntry<TeamDeps>) => DataEntry<TeamDraft>;
  setDraft: (key: string | number, draft: TeamDraft) => void;
  discardDraft: (key: string | number) => void;

  getPayload: (key: string | number, deps: DataEntry<TeamDeps>) => DataEntry<TeamPayload>;

  createEntry: (key: string, deps: DataEntry<TeamDeps>) => Promise<Team>;
  updateEntry: (id: number, deps: DataEntry<TeamDeps>) => Promise<Team>;
  deleteEntry: (id: number) => Promise<void>;
}

export interface TeamsStore extends TeamsState, TeamsActions {}

const DEFAULT_STATE: TeamsState = {
  library: { status: 'idle' },
  entries: ImmutableMap(),
  actions: ImmutableMap(),
  drafts: ImmutableMap()
};

const createFetchLibrary = <TEntry, TState extends { library: DataEntry<TEntry[]> } = { library: DataEntry<TEntry[]> }>({
  fetchLibrary,
  get,
  set
} : {
  fetchLibrary: () => Promise<{ data: TEntry[] }>;
  get: () => TState;
  set: (p: PartialState<TState>) => void;
}) => () => {
  let { library } = get();

  if (library.status === 'idle') {
    library = { status: 'loading' };

    set({ library } as PartialState<TState>);

    fetchLibrary()
      .then(({ data }) => {
        set({
          library: {
            data,
            status: 'success'
          }
        } as PartialState<TState>);
      })
      .catch((error) => {
        set({
          library: {
            error,
            status: 'error'
          }
        } as PartialState<TState>);
      });
  }
}

const createGetLibrary = <TEntry, TState extends {
  library: DataEntry<TEntry[]>,
  fetchLibrary: () => void
}>({
  get,
} : {
  get: () => TState;
}) => () => {
  const { library, fetchLibrary } = get();

  if (library.status == 'idle') {
    fetchLibrary();

    return get().library;
  }

  return library;
}

export const useTeamsStore = create<TeamsStore>((set, get) => ({
  ...DEFAULT_STATE,

  fetchLibrary: createFetchLibrary({
    fetchLibrary: fetchTeams,
    get,
    set
  }),

  getLibrary: createGetLibrary({ get }),

  setAction: (key: string | number, action: TeamAction) => {
    set({
      actions: get().actions.set(key, action)
    });
  },

  discardAction: (key: string | number) => {
    set({
      actions: get().actions.remove(key)
    });
  },

  setEntry: (data: Team) => {
    let {
      entries,
      library
    } = get();

    if (library.status === 'success') {
      let index = library.data.findIndex((item) => item.id === data.id);

      if (index === -1) {
        index = library.data.length;
      }

      library = { ...library, data: [...library.data] };
      library.data[index] = data;

      set({ library });
    }

    entries = entries.set(data.id, { status: 'success', data })

    set({ entries });
  },

  discardEntry: (id: number) => {
    const {
      entries,
      library
    } = get();

    if (library.status === 'success') {
      set({
        library: {
          ...library,
          data: library.data.filter((item) => item.id !== id)
        }
      });
    }

    set({
      entries: entries.remove(id)
    });
  },

  getEntry: withKeyDep((id: number) => {
    const {
      entries,
      library
    } = get();

    let entry = entries.get(id);
    
    if (entry) {
      return entry;
    }

    if (library.status !== 'success') {
      return library;
    }

    const data = library.data.find((item) => item.id === id);

    if (data) {
      entry = {
        status: 'success',
        data
      };
    } else {
      entry = {
        status: 'error',
        error: null
      }
    }

    set({ entries: entries.set(id, entry) });

    return entry;
  }),

  getDraft: (key: string | number, deps: DataEntry<TeamDeps>) => {
    const {
      drafts,
      getEntry
    } = get();

    let draft = drafts.get(key);

    if (draft) {
      return draft;
    }

    if (deps.status !== 'success') {
      return deps;
    }

    if (typeof key === 'string') {
      draft = {
        status: 'success',
        data: parseTeamDraft({
          name: '',
          slack_channel: null,
          default_cost_column_id: null,
          opportunity_priorities: [] as number[]
        } as Team, deps.data)
      };
    } else {
      const entry = getEntry(key);

      if (entry.status !== 'success') {
        return entry;
      }

      draft = {
        status: 'success',
        data: parseTeamDraft(entry.data, deps.data)
      };
    }

    set({ drafts: drafts.set(key, draft) });

    return draft;
  },

  setDraft: (key: string | number, data: TeamDraft) => {
    const { drafts } = get();

    set({
      drafts: drafts.set(key, {
        status: 'success',
        data
      })
    });
  },

  discardDraft: (key: string | number) => {
    const { drafts } = get();

    set({
      drafts: drafts.remove(key)
    });
  },

  getPayload: (key: string | number, deps: DataEntry<TeamDeps>) => {
    const { getDraft } = get();

    const draft = getDraft(key, deps);

    if (draft.status !== 'success') {
      return draft;
    }

    if (deps.status !== 'success') {
      return deps;
    }

    return {
      status: 'success',
      data: serializeTeamDraft(draft.data, deps.data)
    };
  },

  createEntry: (key: string, deps: DataEntry<TeamDeps>) => {
    const {
      getPayload,
      setEntry,
      setAction,
      discardAction
    } = get();

    const payload = getPayload(key, deps);

    if (payload.status !== 'success') {
      throw new Error('');
    }

    setAction(key, {
      type: 'create',
      status: 'in-progress'
    });
    
    return createTeam(payload.data)
      .then(({ data }) => {
        setEntry(data);
        discardAction(key);

        return data;
      })
      .catch((error) => {
        setAction(key, {
          type: 'create',
          status: 'error',
          error
        });

        return Promise.reject(error);
      });

  },

  updateEntry: (id: number, deps: DataEntry<TeamDeps>) => {
    const {
      getPayload,
      setEntry,
      setAction,
      discardAction
    } = get();

    const payload = getPayload(id, deps);

    if (payload.status !== 'success') {
      throw new Error('');
    }

    setAction(id, {
      type: 'update',
      status: 'in-progress'
    });
  
    return updateTeam(id, payload.data)
      .then(({ data }) => {
        setEntry(data);
        discardAction(id);

        return data;
      })
      .catch((error) => {
        setAction(id, {
          type: 'update',
          status: 'error',
          error
        });

        return Promise.reject(error);
      })
  },

  deleteEntry: (id: number) => {
    return deleteTeam(id)
      .then(() => {
        get().discardEntry(id);
      })
      .finally(() => {
        get().discardAction(id);
      });
  }
}));
