import SplashPage from 'shared/Page/SplashPage';
import { Button } from 'shared/Button';
import { useGlobalState } from 'state/globalState';

export const LoginError = () => {
  const loginError = useGlobalState((state) => state.loginError);
  const logout = useGlobalState((state) => state.logout);

  return <SplashPage>
    <div className='text-center'>
      <h1 className='font-medium text-[40px]'>Error</h1>

      {loginError && <>
        <p className='my-[20px]'>
          Your organization has enabled enforced team assignment for members.
        </p>

        <p className='mt-[20px] mb-[40px]'>
          Please, contact your admin <a className='text-caladon-blue' href={`mailto:${loginError.message.admin_email}`}>
            {loginError.message.admin_email}
          </a>
        </p>
      </>}
      
      <Button
        size='sm'
        className='inline-flex'
        style='caladon'
        icon='sign-in'
        label='Sign in with another account'
        onClick={logout}
      />
    </div>
  </SplashPage>;
}
