import { useNavigate } from 'react-router-dom'
import { ReactComponent as CheckRounded } from 'assets/images/CheckRounded.svg'
import { Section } from 'shared/Section'
import Button from 'shared/Button/Button'

export const CfnDone = () => {
  const navigate = useNavigate()

  return (
    <Section className="flex flex-col items-center p-[50px]">
      <CheckRounded />
      <h5 className="text-center mt-[30px]">Your account has been created successfully</h5>
      <Button className="mt-[30px]" label="Continue" onClick={() => navigate('/')} style="caladon" />
    </Section>
  )
}
