import api from 'helpers/api';

import type {
  EventsParams
} from 'types/costViews/events'


export type GroupByType =
  'accounts'
  | 'regions'
  | 'services'
  | 'usage_types'
  | 'operations'
  | 'resources';

export type RightSizingType = 
  'ec2'
  | 'ec2-spot'
  | 'rds'
  | 'ecs-memory'
  | 'ecs-memory-spot'
  | 'ecs-cpu'
  | 'ecs-cpu-spot'

interface RightSizingArgs {
  endDate: string;
  filterParam: any;
}

export const getEC2Rightsizing = ({
  endDate, filterParam
}: RightSizingArgs) => api.post('/metrics/cost/rightsizing/ec2', filterParam || { operator: 'and', condition: [] }, {
  params: { end_date: endDate }
});

export const getRDSRightsizing = ({
  endDate, filterParam
}: RightSizingArgs) => api.post('/metrics/cost/rightsizing/rds', filterParam || { operator: 'and', condition: [] }, {
  params: { end_date: endDate }
});

export const getECSRightsizing = ({
  endDate, filterParam
}: RightSizingArgs) => api.post('/metrics/cost/rightsizing/ecs', filterParam || { operator: 'and', condition: [] }, {
  params: { end_date: endDate }
});

export const getCostViewLibrary = (startDate: string, endDate: string) => api.get('/metrics/cost/library', {
  params: {
    start_date: startDate,
    end_date: endDate,
  }
});

export const saveCostView = (name: string, filter: any) => api.post('/metrics/cost/save', { name, filter })
export const updateCostView = (id: number, name: string, filter: any) => api.patch(`/metrics/cost/${id}`, { name, filter })
export const deleteCostView = (id: number) => api.delete(`/metrics/cost/${id}`)

export const saveTagView = (name: string, filter: any) => api.post('/metrics/tag/save', { name, filter })
export const updateTagView = (id: number, name: string, filter: any) => api.patch(`/metrics/tag/${id}`, { name, filter })
export const deleteTagView = (id: number) => api.delete(`/metrics/tag/${id}`)

export const getEvents = ({
  startDate,
  endDate,
}: EventsParams) => api.get('/events', {
  params: {
    start_date: startDate,
    end_date: endDate,
  }
})

export const getEventTypes = ({
  startDate,
  endDate,
}: EventsParams) => api.get('/event-types', {
  params: {
    start_date: startDate,
    end_date: endDate,
  }
})
