import { identity } from 'helpers/utils';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useTeamFilterConstructor } from 'store/teamFilterConstructor';
import { useTeamsStore } from 'store/teams';

export const useTeamFilters = (teamId: number | null) => {
  const teamFilterConstructor = useTeamFilterConstructor();
  const teamDeps = useDerivedDataEntry({ teamFilterConstructor }, identity);
  const teamDraft = useTeamsStore((store) => store.getDraft(teamId || 'new', teamDeps));

  return useDerivedDataEntry(
    { teamDraft },
    ({ teamDraft }) => teamDraft.filters
  );
}
