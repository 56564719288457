import type { DataEntry } from 'types/dataEntries';
import type { Thread } from 'types/savings';

import { Link } from 'react-router-dom';
import { Button2 as Button } from 'shared/Button';
import { TeamRenderer } from 'shared/TeamRenderer';
import { SimpleTable, SimpleTableCell, SimpleTableRow } from 'shared/SimpleTable';

import {
  OpportunityAssignButton,
  OpportunityNewThreadButton,
  OpportunityRestoreButton
} from '../controls';
import { ThreadStatusBadge } from '../ThreadStatusBadge';

import { formatCurrency, pluralize } from 'helpers/formatter';
import { renderOpportunityStatus } from 'helpers/savings';

import { useOpportunityTeamDialog, useSavingsStore } from 'store/savings';

export interface OpportunityStatusTableProps {
  className?: string;
  opportunityId: number;
}

export const OpportunityStatusTable = (props: OpportunityStatusTableProps) => {
  const {
    className = '',
    opportunityId
  } = props;

  const opportunity = useSavingsStore((store) => store.getOpportunity(opportunityId));

  const thread: DataEntry<Thread | null> = useSavingsStore((store) => {
    if (opportunity.status !== 'success') {
      return opportunity;
    }

    return opportunity.data.thread_id ?
      store.getThread(opportunity.data.thread_id) :
      { status: 'success', data: null };
  });

  const openOpportunityTeamDialog = useOpportunityTeamDialog((store) => store.open);

  return (
    <SimpleTable className={className}>
      <tbody>
        <SimpleTableRow>
          <SimpleTableCell className='w-1/3'>
            <b>Status:</b>
          </SimpleTableCell>

          <SimpleTableCell>
            {opportunity.status === 'success' && renderOpportunityStatus(opportunity.data)}
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell>
            <b>Team:</b>
          </SimpleTableCell>

          <SimpleTableCell>
            {opportunity.status === 'success' && <>
              {opportunity.data.team_id ?
                <TeamRenderer id={opportunity.data.team_id} /> :
                'None'
              }

              {!opportunity.data.thread_id &&
                <Button
                  size='s'
                  theme='gray'
                  layout='inline'
                  icon='edit'
                  className='float-right'
                  onClick={() => {
                    openOpportunityTeamDialog([opportunity.data]);
                  }}
                />
              }
            </>}
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow className='align-baseline'>
          <SimpleTableCell>
            <b>Thread</b>
          </SimpleTableCell>

          <SimpleTableCell>
            {opportunity.status === 'success' && thread.status === 'success' && <>
              {thread.data ? (
                <>
                  <Link
                    className='mr-2 !text-blue hover:!text-blue-hover font-medium'
                    to={`/savings/threads/${thread.data.id}`}
                  >
                    [TRD-{thread.data.secondary_id}] {thread.data.name}
                  </Link>

                  {' '}
                  
                  <ThreadStatusBadge>{thread.data.status}</ThreadStatusBadge>
                </>
              ) : (
                'Unassigned'
              )}
            </>}

            {(
              opportunity.status === 'success' &&
              thread.status === 'success'
            ) && (
              <>
                {thread.data ? (
                  <div className='mt-1 text-gray-500 text-caption-1'>
                    <b>
                      {thread.data.opportunities_count ?? 0}
                    </b>
                    {' '}
                    {pluralize(thread.data.opportunities_count ?? 0, { one: 'opportunity', other: 'opportunities' })},
                    {' savings potential '}
                    <b>
                      {formatCurrency(thread.data.cost_impact_sum * 30)}
                    </b>
                    {' / month'}
                  </div>
                ) : opportunity.data.status === 'unassigned' ? (
                  <span className='inline-flex float-right gap-4'>
                    <OpportunityAssignButton opportunityId={opportunityId} />
                    <OpportunityNewThreadButton opportunityId={opportunityId} />
                  </span>
                ) : opportunity.data.status === 'done' ? (
                  <div className='mt-1 text-gray-500 text-caption-1'>
                    This opportunity has been done outside of thread workflow
                  </div>
                ) : (
                  <div className='flex items-center mt-1 text-gray-500 gap-1 text-caption-1'>
                    <OpportunityRestoreButton opportunityId={opportunityId} />
                    opportunity first to assign it to a thread
                  </div>
                )}
              </>
            )}
          </SimpleTableCell>
        </SimpleTableRow>
      </tbody>
    </SimpleTable>
  );
}
