import type { RuleTag } from 'types/rules';
import type { CostViewConstructor } from 'types/costViews';

import { Section } from 'shared/Section';
import { CostViewFilters, Branch } from 'shared/Filters';
import { parseCostViewFilter } from 'helpers/costViews';
import { Icon } from 'shared/Icon';
import { useTagCosts } from './useTagCosts';
import { TagCostsExport } from './TagCostsExport';
import {formatCurrency, formatPercent} from 'helpers/formatter';

export interface TagsSummaryProps {
  src: string;
  tagFilters: {
    [key: string]: {
      [value: string]: RuleTag;
    }
  };
  costViewConstructor: CostViewConstructor;
}

export const TagsSummary = (props: TagsSummaryProps) => {
  const {
    src,
    tagFilters,
    costViewConstructor
  } = props;

  const costs = useTagCosts({ src, tagFilters });
  
  return (
    <Section className='flex flex-col'>
      <Section.Head wfull className='shrink-0'>
        Tags Summary

        {costs.status === 'success' && 
          <TagCostsExport data={costs.data.tagCosts} />
        }
      </Section.Head>
      
      <div className='overflow-x-hidden overflow-y-auto grow px-[20px] pb-[10px] relative'>
        {Object.entries(tagFilters).map(([key, values]) => (
          <div key={key}>
            <div className='flex pt-[15px] text-[15px] font-medium items-center gap-1 sticky'>
              <Icon icon='tag' />
              {key}
            </div>

            {Object.entries(values).map(([value, tag], index, array) => (
              <Branch key={value} last={index === array.length - 1}>
                <div className='pt-[15px] pl-1 flex justify-between'>
                  <div className='font-medium'>
                    {value}
                  </div>

                  {costs.status === 'success' && (
                    <div>
                      {formatCurrency(costs.data.tagCosts[key][value].absolute)}
                      {' / '}
                      {formatPercent(costs.data.tagCosts[key][value].relative)}
                    </div>
                  )}
                </div>
                <CostViewFilters
                  value={parseCostViewFilter({
                    src,
                    filter: tag.filter
                  }, costViewConstructor)}
                  disabled
                  costViewConstructor={costViewConstructor}
                  onChange={() => {}}
                />
              </Branch>
            ))}
          </div>
        ))}
        
        {Object.keys(tagFilters).length === 0 && (
          <div className='text-center font-medium pt-[20px] pb-[10px]'>
            No rule tags added yet
          </div>
        )}
      </div>
    </Section>
  );
}
