import create from 'zustand'

import type { FetchStatus } from 'types/common';
import type { ReportConfig } from 'types/reports';
import { createChannels } from 'helpers/channels'

import {
  getReportConfigs,
  saveReportConfig,
  editReportConfig,
  deleteReportConfig,
} from 'services/report'

interface FetchParams {
  force?: boolean;
}

export interface ReportsState {
  status: FetchStatus;
  reportConfigs: ReportConfig[];
}

export interface ReportActions {
  fetchReportConfigs: (params?: FetchParams) => void;
  saveReportConfig: (config: any) => void;
  deleteReportConfig: (id: number) => Promise<void>;
  setIsActiveReportConfig: (id: number, is_active: boolean) => void;
}

export interface ReportStore extends ReportsState, ReportActions {}

const DEFAULT_STATE: ReportsState = {
  status: 'idle',
  reportConfigs: [],
};

export const useReportStore = create<ReportStore>((set, get) => {
  let requestCount = 0;

  return {
    ...DEFAULT_STATE,

    fetchReportConfigs: async (params = {}) => {
      const { force } = params;
      const { status } = get();

      /*
      if (!force && ['loading', 'success'].includes(status)) {
        return;
      }
      */

      try {
        if (status !== 'success') {
          set({ status: 'loading' });
        }

        const requestNumber = ++requestCount;
        
        const { data: configs } = await getReportConfigs();

        const reportConfigs = configs.map((data: any) => {
          const newReport: ReportConfig = {
            id: data.id,
            name: data.name,
            owner: data.owner.name,
            report_type_id: data.report_type_id,
            cost_metric_filter_id: data.cost_metric_filter_id,
            cost_metric_filter: data.cost_metric_filter,
            unit_metric_filter_ids: data.unit_metric_filters.map((metric: any) => metric.id),
            unit_metric_filters: data.unit_metric_filters,
            tag_keys: data.report_details.tag_keys,
            cadence: data.cadence,
            wow_change_spend_threshold: data.report_details.wow_change_spend_threshold,
            wow_change_delta_abs_threshold: data.report_details.wow_change_delta_abs_threshold,
            wow_change_delta_rel_threshold: data.report_details.wow_change_delta_rel_threshold,
            dod_change_spend_threshold: data.report_details.dod_change_spend_threshold,
            dod_change_delta_abs_threshold: data.report_details.dod_change_delta_abs_threshold,
            dod_change_delta_rel_threshold: data.report_details.dod_change_delta_rel_threshold,
            cost_column: data.report_details.cost_column,
            amortization: data.report_details.amortization,
            exclude: data.report_details.exclude,
            channels: createChannels(data.report_details),
            email_recepients: data.report_details.providers.email.recipients,
            slack_id: data.report_details.providers.slack.integration_id,
            slack_channels: data.report_details.providers.slack.recipients,
            daily_movers: data.report_details.daily_movers,
            is_active: data.is_active,
            created_at: data.created_at,
          }
          return newReport
        })

        if (requestNumber === requestCount) {
          set({
            reportConfigs,
            status: 'success'
          });
        }
      } catch(error) {
        set({ status: 'error' });
      }
    },

    saveReportConfig: async (config: any) => {
      const { reportConfigs } = get();
      try {
        let data
        if (config.id === -1) {
          ({ data } = await saveReportConfig(config))
        } else {
          ({ data } = await editReportConfig(config, config.id))
        }
        const newReport: ReportConfig = {
          id: data.id,
          name: data.name,
          owner: data.owner.name,
          report_type_id: data.report_type_id,
          cost_metric_filter_id: data.cost_metric_filter_id,
          cost_metric_filter: data.cost_metric_filter,
          unit_metric_filter_ids: data.unit_metric_filters.map((metric: any) => metric.id),
          unit_metric_filters: data.unit_metric_filters,
          tag_keys: data.report_details.tag_keys,
          cadence: data.cadence,
          wow_change_spend_threshold: data.report_details.wow_change_spend_threshold,
          wow_change_delta_abs_threshold: data.report_details.wow_change_delta_abs_threshold,
          wow_change_delta_rel_threshold: data.report_details.wow_change_delta_rel_threshold,
          dod_change_spend_threshold: data.report_details.dod_change_spend_threshold,
          dod_change_delta_abs_threshold: data.report_details.dod_change_delta_abs_threshold,
          dod_change_delta_rel_threshold: data.report_details.dod_change_delta_rel_threshold,
          cost_column: data.report_details.cost_column,
          amortization: data.report_details.amortization,
          exclude: data.report_details.exclude,
          channels: createChannels(data.report_details),
          email_recepients: data.report_details.providers.email.recipients,
          slack_id: data.report_details.providers.slack.integration_id,
          slack_channels: data.report_details.providers.slack.recipients,
          daily_movers: data.report_details.daily_movers,
          is_active: data.is_active,
          created_at: data.created_at,
        }
        const static_reports = reportConfigs.filter((report) => report.id !== newReport.id)
        set({ reportConfigs: [newReport, ...static_reports] })
      } catch(error) {
        set({ reportConfigs });
        throw error;
      }
    },

    deleteReportConfig: async (id: number) => {
      const { reportConfigs } = get();
      set({ reportConfigs: reportConfigs.filter((report) => report.id !== id) });

      try {
        await deleteReportConfig(id);
      } catch(error) {
        set({ reportConfigs });
        throw error;
      }
    },

    setIsActiveReportConfig: async (id: number, is_active: boolean) => {
      const { reportConfigs } = get();
      try {
        const config = {
          'is_active': is_active,
        }
        const { data } = await editReportConfig(config, id)
        const newReport: ReportConfig = {
          id: data.id,
          name: data.name,
          owner: data.owner.name,
          report_type_id: data.report_type_id,
          cost_metric_filter_id: data.cost_metric_filter_id,
          cost_metric_filter: data.cost_metric_filter,
          unit_metric_filter_ids: data.unit_metric_filters.map((metric: any) => metric.id),
          unit_metric_filters: data.unit_metric_filters,
          tag_keys: data.report_details.tag_keys,
          cadence: data.cadence,
          wow_change_spend_threshold: data.report_details.wow_change_spend_threshold,
          wow_change_delta_abs_threshold: data.report_details.wow_change_delta_abs_threshold,
          wow_change_delta_rel_threshold: data.report_details.wow_change_delta_rel_threshold,
          dod_change_spend_threshold: data.report_details.dod_change_spend_threshold,
          dod_change_delta_abs_threshold: data.report_details.dod_change_delta_abs_threshold,
          dod_change_delta_rel_threshold: data.report_details.dod_change_delta_rel_threshold,
          cost_column: data.report_details.cost_column,
          amortization: data.report_details.amortization,
          exclude: data.report_details.exclude,
          channels: createChannels(data.report_details),
          email_recepients: data.report_details.providers.email.recipients,
          slack_id: data.report_details.providers.slack.integration_id,
          slack_channels: data.report_details.providers.slack.recipients,
          daily_movers: data.report_details.daily_movers,
          is_active: data.is_active,
          created_at: data.created_at,
        }
        const static_reports = reportConfigs.filter((report) => report.id !== newReport.id)
        set({ reportConfigs: [newReport, ...static_reports] })
      } catch(error) {
        set({ reportConfigs });
        throw error;
      }
    },
  };
});
