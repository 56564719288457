import type { CostViewFilter } from 'types/costViews';
import type { ConstructorRules, Filter, FilterGroupCondition, FilterValueCondition } from 'types/filters';

export type GlobalFilterId = string;

export interface Tag {
  key: string;
  value: string;
}

export interface CURGlobalFilterFields {
  aws_account_ids?: string[];
  aws_bill_payer_account_ids?: string[];
  aws_account_org_ids?: string[];
  aws_region_names?: string[];
  aws_services?: string[];
  aws_tags?: Tag[];
  aws_account_tags?: Tag[];
}

export type CURGlobalFilterFieldKeys = keyof CURGlobalFilterFields;

export interface GCPGlobalFilterFields {
  gcp_project_names?: string[]
  gcp_billing_account_ids?: string[]
  gcp_locations?: string[]
  gcp_services?: string[]
  gcp_labels?: Tag[];
}

export type GCPGlobalFilterFieldKeys = keyof GCPGlobalFilterFields;

export interface CustomGlobalFilterFields {
  custom_stream_tokens?: string[];
  custom_metric_names?: string[];
  custom_dimensions?: Tag[];
}

export type CustomGlobalFilterFieldKeys = keyof CustomGlobalFilterFields;


export interface GlobalFilterFields extends
  CURGlobalFilterFields,
  GCPGlobalFilterFields,
  CustomGlobalFilterFields {}

export type GlobalFilterFieldKey = keyof GlobalFilterFields;

export interface GlobalFilterPayload extends GlobalFilterFields {
  name: string;
  is_reserved: boolean;
}

export interface GlobalFilter extends GlobalFilterPayload {
  id: GlobalFilterId;
  created_by: number;
}

export interface GlobalFilterDescription {
  enabled: boolean;
  filter: CostViewFilter;
}

export type GeneralMember = {
  id: GlobalFilterId
  name: string
}

export const GLOBAL_FILTER_SOURCES = ['cur', 'gcp', 'custom_data'] as const;

export type GlobalFilterSource = typeof GLOBAL_FILTER_SOURCES[number];

export interface GlobalFilterCondition extends FilterGroupCondition {
  operator: 'and',
  condition: FilterValueCondition[];
}

export interface GlobalFilterFilter<TSrc extends GlobalFilterSource> extends Filter {
  src: TSrc;
  filter: GlobalFilterCondition;
}

export type GlobalFilterMap = {
  [Src in GlobalFilterSource]: GlobalFilterFilter<Src>;
}

export type GlobalFilterConstructor = {
  [Src in GlobalFilterSource]?: ConstructorRules;
}

export interface GlobalFilterDraft {
  name: string;
  is_reserved: boolean;
  filters: GlobalFilterMap;
}

export interface GlobalFilterDeps {
  globalFilterConstructor: GlobalFilterConstructor;
}
