import type { FilterValueCondition } from 'types/filters';
import type {
  TeamFilterMap,
  TeamFilterFields,
  TeamFilterConstructor,
  Tag
} from 'types/teams';

import { FLAT_FIELDS_TO_RULE_NAMES, RULE_NAMES_TO_FLAT_FIELDS } from './constants';

import {
  TEAM_FILTER_SOURCES
} from 'types/teams';

export const oldTagsToNew = (oldTags: Record<string, string>[]): Tag[] => oldTags.map((tag) => {
  const [key, value] = Object.entries(tag)[0] || [];

  if (!key || !value) {
    return null;
  }

  return { key, value };
}).filter(Boolean) as Tag[];

export const newTagsToOld = (newTags: Tag[]): Record<string, string>[] => newTags.map((tag) => {
  const { key, value } = tag;

  return { [key]: value };
});

export const parseTeamFilterFields = (fields: TeamFilterFields, teamConstructor: TeamFilterConstructor): TeamFilterMap => {
  const result: TeamFilterMap = {
    cur: {
      src: 'cur',
      filter: {
        operator: 'and',
        condition: []
      }
    },
    gcp: {
      src: 'gcp',
      filter: {
        operator: 'and',
        condition: []
      }
    },
    custom_data: {
      src: 'custom_data',
      filter: {
        operator: 'and',
        condition: []
      }
    }
  };

  TEAM_FILTER_SOURCES.forEach((src) => {
    const fieldsToRuleNames = FLAT_FIELDS_TO_RULE_NAMES[src];

    const entries = Object.entries(fieldsToRuleNames) as [keyof TeamFilterFields, string][];
    const srcRules = teamConstructor[src];

    if (!srcRules) {
      return;
    }

    entries.forEach(([flatField, ruleName]) => {
      const rule = srcRules[ruleName];
      const values = fields[flatField] || [];

      if (!rule || values.length === 0) {
        return;
      }

      const valueCondition: FilterValueCondition = {
        comparator: 'equals',
        field_name: ruleName,
        value_type: rule.valueType,
        values: rule.valueType === 'string' ? values : newTagsToOld(values as Tag[])
      } as FilterValueCondition;

      result[src].filter.condition.push(valueCondition);
    });
  });
  
  return result;
};

export const serializeTeamFilterMap = (map: TeamFilterMap, teamFilterConstructor: TeamFilterConstructor): TeamFilterFields => {
  const result: TeamFilterFields = {
    aws_account_ids: [],
    aws_bill_payer_account_ids: [],
    aws_account_org_ids: [],
    aws_region_names: [],
    aws_services: [],
    aws_tags: [],
    aws_account_tags: [],
    gcp_project_names: [],
    gcp_billing_account_ids: [],
    gcp_locations: [],
    gcp_services: [],
    gcp_labels: [],
    custom_stream_tokens: [],
    custom_metric_names: [],
    custom_dimensions: []
  };

  TEAM_FILTER_SOURCES.forEach((src) => {
    const filter = map[src];
    const srcRules = teamFilterConstructor[src];

    if (!srcRules) {
      return;
    }

    filter.filter.condition.forEach((condition) => {
      const fieldName = RULE_NAMES_TO_FLAT_FIELDS[src][condition.field_name];
      const rule = srcRules[condition.field_name];

      if (!fieldName || !rule) {
        return;
      }

      const values = (condition.values as (string | Record<string, string>)[]).filter((value) => {
        if (typeof value === 'string') {
          return !!value && (rule.options as string[]).includes(value);
        }

        const [key, val] = Object.entries(value)[0] || [];

        return !!key &&
          !!val &&
          (rule.options as string[]).includes(key);
      });

      result[fieldName] = (rule.valueType === 'string' ? values : oldTagsToNew(values as any)) as any;
    });
  });

  return result;
}
