import type { ReactNode } from 'react'
import { useDocumentScrollThreshold } from 'hooks/useDocumentScrollThreshold';

export interface PageProps {
  isSubPage?: boolean,
  className?: string
  children: ReactNode,
}

const Page = (props: PageProps) => {
  const {
    children,
    isSubPage,
    className = ''
  } = props;

  if (isSubPage) {
    return (
      <div>
        {children}
      </div>
    )
  } else {
    return (
      <div className={`min-w-0 min-h-screen px-3 grow shrink ${className}`}>
        {children}
      </div>
    );
  }
};

const Title = ({ children }: { children: ReactNode }) => (
  <h1 className='flex items-center font-bold text-subtitle-2 whitespace-nowrap'>
    {children}
  </h1>
)

export interface HeadProps {
  children?: ReactNode,
  title?: ReactNode,
  subtitle?: ReactNode
}

const Head = (props: HeadProps) => {
  const {
    title,
    subtitle,
    children
  } = props;

  const scrolled = useDocumentScrollThreshold();

  return (
    <div className={`sticky top-0 z-10 flex justify-between items-center h-[60px] mb-2.5 px-5 bg-white rounded-b ${scrolled ? 'drop-shadow-md' : ''}`}>
      {title && (
        <div className='flex flex-col'>
          <Title>{title}</Title>
          {subtitle && (
            <p className='flex items-center text-caption-1 mt-0.5'>
              {subtitle}
            </p>
          )}
        </div>
      )}
      {children}
    </div>
  )
}

const Right = ({ children }: { children: ReactNode }) => (
  <div className='ml-auto'>{children}</div>
);

export default Object.assign(Page, { Head, Title, Right })
