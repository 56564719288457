import { Link, useParams } from 'react-router-dom';

import { Page } from 'shared/Page';
import { Grid } from 'shared/Grid';
import { Icon } from 'shared/Icon';

import { NonPayingBanner } from './NonPayingBanner';

import { 
  OpportunityAssignButton,
  OpportunityUnassignButton,
  OpportunityArchiveButton,
  OpportunityResolveButton,
  OpportunityRestoreButton,
  OpportunityNewThreadButton,
  OpportunityUpdateButton,
  OpportunityAutomationButton
 } from './controls';

import {
  CostAttributesSection,
  OpportunityInfoSection,
  OpportunityCostChartSection,
  OpportunityCostDescriptionSection,
  OpportunityCostDetailsSection,
  OpportunityRecordIdSection,
  OpportunityResourceDetailsSection,
  JobLogSection,
  UsageChartSection,
  OpportunityFullMetadataSection,
  RightsizingSection,
} from './content';

import {
  ThreadSelectDialog,
  ThreadCreateDialog,
  CustomOpportunityDialog,
  OpportunityTeamDialog,
  OpportunityAutomationDialog
} from './dialogs';

import { isAutomatableOpportunity, renderOpportunitySecondaryId } from 'helpers/savings'
import { useSavingsStore } from 'store/savings';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { SectionGroupTitle } from 'shared/Section';

export const OpportunityPage = () => {
  const id = +(useParams().id as string);

  const opportunity = useSavingsStore((store) => store.getOpportunity(id));
  const thread = useSavingsStore((store) => {
    const { getThread } = store;

    if (opportunity.status !== 'success') {
      return opportunity;
    }

    return opportunity.data.thread_id && getThread(opportunity.data.thread_id);
  });

  const usage = useDerivedDataEntry({ opportunity }, ({ opportunity }) => {
    return (opportunity.opportunity_details?.usage_historical || [])
      .filter((item) => item.data.length > 0)
      .map((item) => ({ 
        ...item,
        label: item.label.replace(opportunity.opportunity_details?.record_id + ' ', '')
      }));
  });
  
  return <Page>
    <Page.Head
      title={<div className='flex items-center gap-2'>
        <Link
          className='text-blue hover:!text-blue-hover'
          to='/savings/opportunities/'
        >
          Opportunity Explorer 
        </Link>

        <Icon icon='arrow-right' />

        {opportunity.status === 'success' ? renderOpportunitySecondaryId(opportunity.data) : 'Loading...'}
      </div>}
    >
      {opportunity.status === 'success' && <>
        {opportunity.data.status === 'assigned' && (
          <div className='bg-[#CFE1EE] py-1 px-2 text-caption-2 rounded ml-5'>
            Assigned to
            {' '}
            <Link
              className='text-blue'
              to={`/savings/threads/${opportunity.data.thread_id}`}
            >
              TRD-{opportunity.data.thread_id}
            </Link>
          </div>
        )}

        <div className='flex ml-auto gap-4'>
          {opportunity.data.source === 'custom' && (
            <OpportunityUpdateButton opportunityId={id} />
          )}

          <OpportunityArchiveButton opportunityId={id} />

          {thread && thread.status === 'success' && (
            <OpportunityUnassignButton opportunityId={id} />
          )}

          {opportunity.data.status === 'trash' ? (
            <OpportunityRestoreButton opportunityId={id} />
          ) : <>
            <OpportunityAssignButton opportunityId={id} />
            <OpportunityNewThreadButton opportunityId={id} />

            {opportunity.data.status !== 'done' &&
              <OpportunityResolveButton opportunityId={id} />
            }

            {isAutomatableOpportunity(opportunity.data) &&
              <OpportunityAutomationButton
                iconLeft='rocket'
                disabled={!!opportunity.data.in_progress_job_id}
                className='text-orange hover:!text-orange-hover disabled:!text-black/30'
                opportunity={opportunity.data}
              />
            }
          </>}
        </div>
      </>}
    </Page.Head>

    <Grid className='pb-[60px]'>
      <NonPayingBanner className='col-span-12' />

      <OpportunityInfoSection opportunityId={id} className='col-span-6' />
      <CostAttributesSection opportunityId={id} className='col-span-6' />

      <RightsizingSection opportunityId={id} className='col-span-12' />
      <OpportunityRecordIdSection opportunityId={id} className='col-span-12' />

      {(
        opportunity.status !== 'success' ||
        !!opportunity.data.opportunity_details?.cost_historical?.length ||
        !!opportunity.data.opportunity_details?.record?.flagged_resource_metadata ||
        !!opportunity.data.opportunity_details?.record
      ) && <>
        <SectionGroupTitle className='col-span-12'>
          Resource Context
        </SectionGroupTitle>

        <OpportunityCostChartSection opportunityId={id} className='col-span-12' />
        <OpportunityResourceDetailsSection opportunityId={id} className='col-span-12' />
      </>}

      {usage.status === 'success' && usage.data.length > 0 && <>
        <SectionGroupTitle className='col-span-12'>
          Usage Context
        </SectionGroupTitle>

        {usage.data.map((item) =>
          <UsageChartSection
            key={item.label}
            usage={item}
            className='col-span-6'
          />
        )}
      </>}

      {(
        opportunity.status !== 'success' ||
        !!opportunity.data?.opportunity_details?.cost_impact_description ||
        !!Object.keys(opportunity.data?.opportunity_details?.cost_impact_details || {}).length
      ) && <>
        <SectionGroupTitle className='col-span-12'>
            Estimated Savings Context
        </SectionGroupTitle>
        
        <OpportunityCostDescriptionSection opportunityId={id} className='col-span-12' />
        <OpportunityCostDetailsSection opportunityId={id} className='col-span-12' />
      </>}

      <JobLogSection opportunityId={id} className='col-span-12' />

      <OpportunityFullMetadataSection opportunityId={id} className='col-span-12' />
    </Grid>

    <ThreadCreateDialog />
    <ThreadSelectDialog />
    <OpportunityTeamDialog />
    <CustomOpportunityDialog />
    <OpportunityAutomationDialog />
  </Page>
};
