import type {
  RuleDraft,
  RuleBackend,
  RuleDraftBackend
} from 'types/rules';

import {
  createEditableStore,
  createDraftSelector,
  createLibrarySelector
} from 'store/createEditableStore';

import {
  fetchRules,
  fetchRule,
  createRule,
  updateRule,
  deleteRule
} from 'services/rules';

import {
  parseRuleDraft,
  serializeRuleDraft
} from 'helpers/rules';

export const useRulesStore = createEditableStore<RuleDraftBackend, RuleBackend, RuleBackend, RuleDraft>({
  fetchLibrary: fetchRules,
  fetchEntry: fetchRule,
  createEntry: createRule,
  updateEntry: updateRule,
  deleteEntry: deleteRule,

  backendEntryToDraft: parseRuleDraft,
  backendEntryToLibraryEntry: <T>(a: T) => a,
  draftToPayload: serializeRuleDraft,

  emptyDraft: {
    name: '',
    src: 'cur',
    root: {
      field: null,
      children: []
    }
  }
});

export const useRuleDraft = createDraftSelector(useRulesStore);
export const useRulesLibrary = createLibrarySelector(useRulesStore);
