import { useState, useMemo } from 'react';
import { Input } from 'antd';
import { Button } from 'shared/Button';

import { BtnSocialAuth } from 'components/Auth'
import GoogleIcon from 'assets/images/Google.png'
import GithubIcon from 'assets/images/Github.png'
import { UpdateOrgDialog } from './UpdateOrgDialog';
import { UpdateUserDialog } from './UpdateUserDialog';
import { UpdateUserEmailDialog } from './UpdateUserEmailDialog';
import { UpdateUserPasswordDialog } from './UpdateUserPasswordDialog';
import { useGlobalState } from 'state/globalState';

const API_HOST = process.env.REACT_APP_API_HOST

export const ProfileTab = () => {
  const logout = useGlobalState((store) => store.logout);
  const user = useGlobalState((store) => store.user);
  
  const orgEditAvailable = useMemo(() => user?.permissions.includes('admin'), [user]);

  const [updateUser, setUpdateUser] = useState(false)
  const [updateOrg, setUpdateOrg] = useState(false)
  const [updateUserEmail, setUpdateUserEmail] = useState(false)
  const [updateUserPassword, setUpdateUserPassword] = useState(false)
  
  const handleGoogleConnect = () => {
    window.location.href = `${API_HOST}/signin/google`
  }

  const handleGithubConnect = () => {
    window.location.href = `${API_HOST}/signin/github`
  }
  

  return (
    <div>
      <div className='flex items-center justify-between mt-[20px]'>
        <h2 className='font-medium text-subtitle-1'>
          {user ? 'Edit your profile' : 'Loading...'}
        </h2>

        <Button
          size='sm'
          icon='exit'
          onClick={logout}
          label='Logout'
        />
      </div>

      <div className='mt-[30px] w-[500px]'>
        <div className='mb-[30px]'>
          <span className='block mb-[10px] font-medium'>
            Organization Name
          </span>

          <div className='flex gap-3'>
            <Input
              className='w-full h-[46px] px-[12px] py-[15px] border-[1px] border-silver-grey-400 rounded-[8px] focus-visible:outline-none'
              value={user?.org_name || ''}
              disabled
            />
            {orgEditAvailable && (
              <Button
                type='secondary'
                onClick={() => setUpdateOrg(true)}
                label='Update'
              />
            )}
          </div>
        </div>

        <div className='mb-[30px]'>
          <span className='block mb-[10px] font-medium'>
            Organization Slug
          </span>

          <div className='flex gap-3'>
            <Input
              className='w-full h-[46px] px-[12px] py-[15px] border-[1px] border-silver-grey-400 rounded-[8px] focus-visible:outline-none'
              value={user?.org_slug || ''}
              disabled
              />
            {orgEditAvailable && (
              <Button
                type='secondary'
                onClick={() => setUpdateOrg(true)}
                label='Update'
              />
            )}
          </div>
        </div>

        <div className='mb-[30px]'>
          <span className='block mb-[10px] font-medium'>
            Your Name
          </span>

          <div className='flex gap-3'>
            <Input
              className='w-full h-[46px] px-[12px] py-[15px] border-[1px] border-silver-grey-400 rounded-[8px] focus-visible:outline-none'
              value={user?.name || ''}
              disabled
            />

            <Button
              type='secondary'
              onClick={() => setUpdateUser(true)}
              label='Update'
            />
          </div>
        </div>

        {user?.team && (
          <div className='mb-[30px]'>
            <span className='block mb-[10px] font-medium'>
              Your Team
            </span>

            <div className='flex gap-3'>
              <Input
                className='w-full h-[46px] px-[12px] py-[15px] border-[1px] border-silver-grey-400 rounded-[8px] focus-visible:outline-none'
                value={user?.team.name || ''}
                disabled
              />
            </div>
          </div>
        )}

        <div className='mb-[30px]'>
          <span className='block mb-[10px] font-medium'>
            Your Email
          </span>

          <div className='flex gap-3'>
            <Input
              className='w-full h-[46px] px-[12px] py-[15px] border-[1px] border-silver-grey-400 rounded-[8px] focus-visible:outline-none'
              value={user?.email || ''}
              disabled
            />
            <Button
              type='secondary'
              onClick={() => setUpdateUserEmail(true)}
              label='Update'
              disabled={!user?.has_password}
            />
          </div>
          {!user?.has_password && (
            <div className='mt-2'>To edit your email you must set a password</div>
          )}
        </div>
      </div>

      {user?.has_password && (
        <div className='mt-[40px]'>
          <Button
            type='secondary'
            onClick={() => setUpdateUserPassword(true)}
            label='Change Password'
          />
        </div>
      )}

      <div className='mt-[60px]'>
        <h3 className='font-medium text-subtitle-2 mb-[10px]'>Connect with Google or Github</h3>
        <p>Single connection per provider</p>

        <BtnSocialAuth classes='mt-[20px] !w-[250px]' icon={GoogleIcon} text='Connect Google' onClick={handleGoogleConnect} />
        <BtnSocialAuth classes='mt-[10px] !w-[250px]' icon={GithubIcon} text='Connect Github' onClick={handleGithubConnect} />
      </div>

      {user && (
        <>
          <UpdateOrgDialog 
            opened={updateOrg}
            close={() => setUpdateOrg(false)}
            id={user.org_id} 
            name={user.org_name}
            slug={user.org_slug}
          />
          <UpdateUserDialog 
            opened={updateUser}
            close={() => setUpdateUser(false)}
            name={user.name}
          />
          <UpdateUserEmailDialog
            opened={updateUserEmail}
            close={() => setUpdateUserEmail(false)}
            email={user.email}
          />
          <UpdateUserPasswordDialog 
            opened={updateUserPassword}
            close={() => setUpdateUserPassword(false)}
          />
        </>
      )}
    </div>
  );
}
