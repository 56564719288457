import { message } from 'antd'
import Logo from 'assets/images/Logo.svg'
import LogoSmall from 'assets/images/LogoSmall.svg'
import { ReactComponent as SendEmailIcon } from 'assets/images/SendEmail.svg'
import { resendConfirmation } from 'services/user'
import { useGlobalState } from 'state/globalState'


const ConfirmEmail = () => {
  const { email } = useGlobalState()

  return (
    <div className="relative w-full h-screen bg-white md:bg-silver-grey md:flex md:justify-center md:items-center">
      <img className="hidden md:block h-[56px] absolute top-0 left-0" src={Logo} alt="Logo" />
      <img className="visible md:invisible h-[40px] mt-[10px] ml-[10px]" src={LogoSmall} alt="Logo" />
      <div className="w-full md:w-[560px] bg-white md:rounded-[20px] p-[50px] md:shadow-light-shadow">
        <div className="flex justify-center items-center w-[80px] h-[80px] bg-cyanine-blue-5% rounded-full">
          <SendEmailIcon className="w-[34px] h-[34px]" />
        </div>
        <div className="mt-[30px] pr-[100px] text-oxford-blue">
          <h5 className="font-semibold text-[24px] leading-[34px]">
            Please confirm your email
          </h5>
          {email && (
            <div>
              <p className="mt-[15px] text-[16px] leading-[24px]">
              {`Click the link in the email we just sent to ${email ? email : 'unknown'} finish creating your account.`}
              </p>
              <p className="mt-[20px] text-[16px] leading-[24px]">
                Didn&apos;t get it? Please check your email address is correct, <a className='!text-caladon-blue' onClick={() => {resendConfirmation(email); message.info('Email sent');}}>send it again</a>, or email <a href='mailto:support@cloudthread.io' className='!text-caladon-blue'>support@cloudthread.io</a>. 
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ConfirmEmail
