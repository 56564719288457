import type { Moment } from 'moment';
import type {
  Opportunity,
  OpportunityTag,
  OpportunityDifficulty,
  OpportunityStatus,
  OpportunityTypeId,
  Platform
} from 'types/savings';

import moment from 'moment';
import {AUTOMATABLE_OPPORTUNITY_TYPE_IDS_SET} from './constants';

export const pickOpportunityThreadId = (opportunity: Opportunity): number | null => opportunity.thread_id || null;
export const pickOpportunityRecordId = (opportunity: Opportunity): string | null => opportunity.record_id || null;

export const pickOpportunityCostImpact = (opportunity: Opportunity): number | null => {
  const notCalculated = !opportunity.opportunity_details?.cost_impact_calculated && opportunity.source !== 'custom';

  return notCalculated ? null : opportunity.cost_impact * 30;
};

export const pickOpportunityGroup = (opportunity: Opportunity): 'usage' | 'rate' | 'custom' | null =>
  opportunity.source === 'custom' ?
    'custom' :
    opportunity.opportunity_details?.meta_category || null;

export const pickOpportunityGroupWeight = (opportunity: Opportunity): number => ({
  usage: 1,
  rate: 2,
  custom: 3,
  'null': 4,
}[pickOpportunityGroup(opportunity) + ''] || 5);


export const pickOpportunityCategory = (opportunity: Opportunity): OpportunityTypeId | null => opportunity.opportunity_type_id || null;

export const pickOpportunityDifficulty = (opportunity: Opportunity): OpportunityDifficulty => {
  const { difficulty } = opportunity.opportunity_details || {};

  if (!difficulty || difficulty === 'unknown') {
    return 'easy';
  }

  return difficulty;
};

export const getOpportunityDifficultyWeight = (difficulty: OpportunityDifficulty | null): number => difficulty ? {
  easy: 0,
  medium: 1,
  hard: 2
}[difficulty] : 3;

export const pickOpportunityDifficultyWeight = (opportunity: Opportunity): number => getOpportunityDifficultyWeight(pickOpportunityDifficulty(opportunity));

export const pickOpportunityStatus = (opportunity: Opportunity): OpportunityStatus => opportunity.status;


export const pickOpportunityStatusWeight = (opportunity: Opportunity): number => ({
  unassigned: 0,
  assigned: 1,
  validation: 2,
  implementation: 3,
  done: 4,
  rejected: 5,
  trash: 6
}[opportunity.status]);

export const pickOpportunityRegion = (opp: Opportunity): string | null => 
  opp.opportunity_details?.cost_attributes?.region_name || null;

export const pickOpportunityService = (opp: Opportunity): string | null =>
  opp.opportunity_details?.cost_attributes?.service || null;

export const pickOpportunityAccount = (opp: Opportunity): string | null => 
  opp.opportunity_details?.cost_attributes?.aws_account_id || null;

export const pickOpportunityAccountName = (opp: Opportunity): string | null => 
  opp.opportunity_details?.cost_attributes?.aws_account_name || null;

export const pickOpportunityBillPayerAccount = (opp: Opportunity): string | null => 
  opp.opportunity_details?.cost_attributes?.bill_payer_account_id || null;

const normalizeTags = (tags: OpportunityTag[] | undefined): OpportunityTag[] => 
  (Array.isArray(tags) ? tags : []).filter(({ value }) => !!value);

export const pickOpportunityTags = (opportunity: Opportunity): OpportunityTag[] => {
  let tags = normalizeTags(opportunity.opportunity_details?.cost_attributes?.tags);

  if (tags.length === 0) {
    tags = normalizeTags(opportunity.opportunity_details?.cost_attributes?.tags_historical);
  };

  return tags;
};

export const pickOpportunityAccountTags = (opportunity: Opportunity): OpportunityTag[] => {
  let tags = normalizeTags(opportunity.opportunity_details?.cost_attributes?.account_tags);

  if (tags.length === 0) {
    tags = normalizeTags(opportunity.opportunity_details?.cost_attributes?.account_tags_historical);
  };

  return tags;
};

export const pickOpportunityTagsCount = (opportunity: Opportunity): number => pickOpportunityTags(opportunity).length;
export const pickOpportunityAccountTagsCount = (opportunity: Opportunity): number => pickOpportunityAccountTags(opportunity).length;

export const pickOpportunityCreatedAt = (opp: Opportunity) : Moment | null => opp.created_at ? moment(opp.created_at) : null;
export const pickOpportunityLastDetectedAt = (opp: Opportunity) : Moment | null => opp.last_detected_at ? moment(opp.last_detected_at) : null;

export const pickOpportunityPlatform = (opp: Opportunity): Platform => {
  if (opp.platform) {
    return opp.platform;
  }

  if (opp.source === 'recommender') {
    return 'gcp';
  }

  return 'aws';
}

export const pickOpportunityPriority = (opp: Opportunity) => typeof opp.priority === 'number' ? opp.priority : null;

export const isAutomatableOpportunity = (opp: Opportunity) => AUTOMATABLE_OPPORTUNITY_TYPE_IDS_SET.has(pickOpportunityCategory(opp) as string) && !['done', 'trash'].includes(opp.status);

export const pickOpportunityRightsizing = (opp: Opportunity) => ({
  current_option: opp.opportunity_details?.cost_impact_details?.current_option || null,
  new_option: opp.opportunity_details?.cost_impact_details?.new_option || null
});
