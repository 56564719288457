import type { DataEntry } from 'types/dataEntries';
import type { UnitMetricFilter, UnitMetricFilterWithTeam } from 'types/unitMetrics';

import { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { cleanupUnitMetricFilter, parseUnitMetricFilter, toImmutableUnitMetricFilter, validateUnitMetricFilter } from 'helpers/unitMetrics';
import { message } from 'antd';

import { useDatesQuery } from 'hooks/useDatesQuery';
import { useCostFilterValue } from 'hooks/costFilter';
import { useUnitMetricStore } from 'store/unitMetric';
import { useUnitMetricChart } from 'store/unitMetricChart';
import { useUnitMetricConstructor } from 'store/unitMetricConstructor';
import { useEventStore } from 'store/events'
import { useGlobalState } from 'state/globalState';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useTeamId } from 'hooks/teamId';
import { useTeamFilters } from 'hooks/teamFilters';
import {useTeamFilterConstructor} from 'store/teamFilterConstructor';

export const useUnitMetricPage = () => {
  const params = useParams<{ id?: string }>();
  const idParam = params.id ? +params.id : null;

  const user = useGlobalState((store) => store.user);

  const {
    startDate,
    endDate,
    granularity,
    setDatesQuery
  } = useDatesQuery();
  
  const {
    events,
    eventTypes,
    selectedEventTypes,
    fetchEvents,
    fetchEventTypes,
    setEventTypes
  } = useEventStore();

  const costFilterValue = useCostFilterValue(); 

  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
  const [saveAsNewDialogOpened, setSaveAsNewDialogOpened] = useState(false);

  const navigate = useNavigate();
  const logEvent = useGlobalState((state) => state.logEvent);

  const {
    status: metricStatus,
    id,
    name,
    custom,
    filter,
    setName,
    setFilter,
    unitMetric,
    initUnitMetric,
    fetchUnitMetric,
    createUnitMetric,
    updateUnitMetric,
    deleteUnitMetric,
    cleanup
  } = useUnitMetricStore();

  const unitMetricConstructor = useUnitMetricConstructor();

  const baseFilterImmutable = useMemo(() => {
    if (!unitMetric || unitMetricConstructor.status !== 'success') {
      return null;
    }

    const result = toImmutableUnitMetricFilter(parseUnitMetricFilter(unitMetric.filter, unitMetricConstructor.data));

    return result;
  }, [unitMetric, unitMetricConstructor]);

  const currentFilterImmutable = useMemo(() => {
    const result = filter ? toImmutableUnitMetricFilter(filter) : null;

    return result;
  }, [filter]);


  const filterIsValid = useMemo(() => (
    !!filter &&
    unitMetricConstructor.status === 'success' &&
    validateUnitMetricFilter(filter, unitMetricConstructor.data)
  ), [
    filter,
    unitMetricConstructor.status // checking status is enough since constructor data is static
  ]);

  const filterEntry = (filterIsValid ? {
    status: metricStatus,
    data: filter
  } : { status: 'idle' }) as DataEntry<UnitMetricFilter>;

  const userTeamId = user && user.team && user.team.id || null;

  const [teamId, setTeamId] = useTeamId(userTeamId);
  const teamFilters = useTeamFilters(teamId);
  const teamFilterConstructor = useTeamFilterConstructor();

  const chartParams = useDerivedDataEntry({
    startDate,
    endDate,
    granularity,
    filterEntry,
    teamFilters,
    ...costFilterValue
  }, ({
    startDate,
    endDate,
    granularity,
    filterEntry,
    teamFilters,
    ...costFilterValue
  }) => {
    const numeratorTeamFilter = teamFilters[filterEntry.numerator.src];
    const denominatorTeamFilter = teamFilters[filterEntry.denominator.src];

    let filter: UnitMetricFilterWithTeam = cleanupUnitMetricFilter({
      numerator: { ...filterEntry.numerator },
      denominator: { ...filterEntry.denominator }
    });

    if (numeratorTeamFilter && numeratorTeamFilter.filter.condition.length) {
      filter.numerator = {
        ...filterEntry.numerator,
        filter: {
          operator: 'and',
          condition: [
            numeratorTeamFilter.filter,
            filterEntry.numerator.filter
          ]
        }
      };
    }

    if (denominatorTeamFilter && denominatorTeamFilter.filter.condition.length) {
      filter.denominator = {
        ...filterEntry.denominator,
        filter: {
          operator: 'and',
          condition: [
            denominatorTeamFilter.filter,
            filterEntry.denominator.filter
          ]
        }
      };
    }

    return {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      granularity,
      filter,
      ...costFilterValue
    };
  });

  const chart = useUnitMetricChart(chartParams);

  useEffect(() => {
    fetchEvents({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    });

    fetchEventTypes({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    })
  }, [startDate, endDate])

  useEffect(() => {
    if (idParam) {
      if (idParam !== id) {
        fetchUnitMetric(idParam);
      }
    } else {
      initUnitMetric();
    }

    return cleanup;
  }, [idParam]);

  useEffect(() => {
    const unparsedFilter = unitMetric ? unitMetric.filter : filter;

    if (
      unparsedFilter &&
      metricStatus === 'success' &&
      unitMetricConstructor.status === 'success'
    ) {
      const parsedFilter = parseUnitMetricFilter(unparsedFilter, unitMetricConstructor.data)
      setFilter(parsedFilter);
    }
  }, [unitMetric, metricStatus, unitMetricConstructor.status]);

  const setDates: typeof setDatesQuery = (params) => {
    setDatesQuery(params);

    if (params.granularity !== granularity) {
      logEvent('set_granularity', { view: 'unit_metric', granularity: params.granularity });
    }

    if (params.startDate !== startDate || params.endDate !== endDate) {
      logEvent('set_period', { view: 'unit_metric' });
    }
  };

  const openDeleteDialog = useCallback(() => {
    logEvent('open_delete_dialog', { view: 'unit_metric' });
    setDeleteDialogOpened(true);
  }, []);

  const closeDeleteDialog = useCallback(() => {
    logEvent('close_delete_dialog', { view: 'unit_metric' });
    setDeleteDialogOpened(false);
  }, []);

  const confirmDeleteDialog = useCallback(async () => {
    logEvent('confirm_delete_dialog', { view: 'unit_metric' });
    if (id) {
      try {
        await deleteUnitMetric();
        message.success(`Unit metric '${name}' deleted`);
        navigate('/unit-metrics/library', { replace: true });
      } catch {
        message.error(`Unable to delete '${name}'`);
      }
    }
  }, [name]);

  const openSaveAsNewDialog = useCallback(() => {
    logEvent('open_save_as_new_dialog', { view: 'unit_metric' });
    setSaveAsNewDialogOpened(true);
  }, []);

  const closeSaveAsNewDialog = useCallback(() => {
    logEvent('close_save_as_new_dialog', { view: 'unit_metric' });
    setSaveAsNewDialogOpened(false);
  }, []);

  const confirmSaveAsNewDialog = useCallback(async () => {
    setSaveAsNewDialogOpened(false);

    try {
      const newMetric = await createUnitMetric();
      if (newMetric) {
        message.success(`Unit metric '${newMetric.name}' saved`);
        navigate(`/unit-metrics/${newMetric.id}`);
      }
    } catch (error) {
      message.error(`Unable to save '${name}'`);
    }
  }, []);

  const saveAsNew = () => {
    logEvent('save_as_new', { view: 'unit_metric' });

    if (name && nameHasChanged) {
      confirmSaveAsNewDialog();
    } else {
      openSaveAsNewDialog();
    }
  }

  const saveUnitMetric = useCallback(async () => {
    try {
      await updateUnitMetric();
      message.success(`Unit metric '${name}' saved`);
    } catch {
      message.error(`Unable to save '${name}'`);
    }
  }, [name]);

  const nameHasChanged = !!unitMetric && name !== unitMetric.name;

  const filterHasChanged = useMemo(() => (
    !!unitMetric &&
    !!filter &&
    baseFilterImmutable !== currentFilterImmutable
  ), [filter, unitMetric, baseFilterImmutable, currentFilterImmutable]);

  const resetFilter = () => {
    if (unitMetricConstructor.status === 'success' && unitMetric && unitMetric.filter) {
      setFilter(parseUnitMetricFilter(unitMetric.filter, unitMetricConstructor.data));
    }
  };

  const clearFilter = () => {
    if (unitMetricConstructor.status === 'success') {
      setFilter(parseUnitMetricFilter({
        numerator: {
          src: filter ? filter.numerator.src : 'cur',
          filter: { operator: 'and', condition: [] }
        },
        denominator: {
          src:  filter ? filter.numerator.src : 'cur',
          filter: { operator: 'and', condition: [] }
        }
      }, unitMetricConstructor.data));
    }
  };

  return {
    startDate,
    endDate,
    granularity,
    setDates,

    name,
    nameHasChanged,
    setName,

    events,
    eventTypes,
    selectedEventTypes,
    setEventTypes,

    filter,
    filterIsValid,
    filterHasChanged,
    setFilter,
    resetFilter,
    clearFilter,

    id,
    custom,
    metricStatus,

    chart,
    unitMetricConstructor,

    deleteDialogOpened,
    openDeleteDialog,
    closeDeleteDialog,
    confirmDeleteDialog,

    saveAsNew,
    saveAsNewDialogOpened,
    openSaveAsNewDialog,
    closeSaveAsNewDialog,
    confirmSaveAsNewDialog,

    saveUnitMetric,
    teamId,
    setTeamId,
    teamFilters,
    teamFilterConstructor
  };
};
