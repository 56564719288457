import { message } from 'antd';
import { syncTags } from 'services/tags';
import { Button } from 'shared/Button';

export const SyncButton = () => {
  return <Button
    size='sm'
    type='secondary'
    onClick={() => {
      syncTags()
        .then(() => {
          message.success('Tag synchronization started');
        })
        .catch(() => {
          message.error('Unable to synchronize tags');
        });
    }}
    label='Synchronize tags'
  />;
}
