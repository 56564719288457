import { createKeyedStoreChunk } from 'store/createKeyedStoreChunk';
import { fetchSavedAndWastedSummaryChunk } from 'services/savings';
import { savedAndWastedParamsToKeyChunk } from 'helpers/savings';
import { createKeyedStoreSelector } from 'store/createKeyedStoreSelector';

export const useSavedAndWastedSummaryStoreChunk = createKeyedStoreChunk({
  fetchData: fetchSavedAndWastedSummaryChunk,
  paramsToKey: savedAndWastedParamsToKeyChunk
});

export const useSavedAndWastedSummaryChunk = createKeyedStoreSelector(useSavedAndWastedSummaryStoreChunk);
