import { 
  StatusFilterValue,
  type Opportunity,
  type OpportunityPagination
} from 'types/savings';

import { useState } from 'react';
import { message } from 'antd';
import { Button2 as Button } from 'shared/Button';
import { Dialog } from 'shared/Dialog';
import { OpportunitiesBlock } from '../content/OpportunitiesBlock';
import { useSavingsStore, useOpportunityAssignDialog } from 'store/savings';
import { RadioButton } from 'shared/RadioButtonGroup';

export interface OpportunityAssignDialogProps {
  threadId: number;
  teamId: number | null;
}

export const OpportunityAssignDialog = (props: OpportunityAssignDialogProps) => {
  const { threadId, teamId } = props;
  const { opened, close } = useOpportunityAssignDialog();
  const assignOpportunity = useSavingsStore((store) => store.assignOpportunity);

  const [assigning, setAssigning] = useState(false);
  const [selected, setSelected] = useState<Opportunity[]>([]);

  const [pagination, setPagination] = useState<OpportunityPagination>({
    ids: [],
    perPage: 10
  });

  const [statusFilter, setStatusFilter] = useState<StatusFilterValue>('unassigned')

  return (
    <Dialog
      className='w-[1200px] overflow-scroll'
      open={opened}
      onClose={close}
    >
      <Dialog.Title>
        Assign Opportunities
      </Dialog.Title>

      <div className='px-8'>
        <OpportunitiesBlock
          title='Opportunities'
          prefilter={teamId ? {team_id: teamId} : undefined}
          selected={selected}
          onSelect={setSelected}
          pagination={pagination}
          onPagination={setPagination}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          overrideOpportunityActions={<div/>}
          extraStatusFilters={<RadioButton value='unassigned'>Unassigned</RadioButton>}
          ignorePageFilters
        />
      </div>

      <div className='flex justify-end p-4'>
        <Button
          size='m'
          theme='filled'
          disabled={assigning || selected.length === 0}
          onClick={() => {
            setAssigning(true);
            Promise.all(
              selected.map(({ id }) => assignOpportunity(id, threadId))
            )
              .then(() => {
                close();
                setSelected([]);
                message.success('Opportunities assigned');
              })
              .catch(() => {
                message.error('Unable to assign opportunities');
              })
              .finally(() => {
                setAssigning(false);
              });
          }}
        >
          Assign opportunities{ selected.length > 0 ? ` (${selected.length})` : ''}
        </Button>
      </div>
    </Dialog>
  );
};
