import type {
  CostView,
  CostViewConstructorBackend,
  CostViewConstructorBackendGCP,
  CostViewFilter,
  CostViewChart,
  CostViewChartFetchParams,
  CostViewForecast,
  CostViewForecastFetchParams,
  DrillDownData,
  DrillDownFetchParams,
  MoversAndShakersFetchParams,
  MoversAndShakersData,
  CostViewForecastInfo
} from 'types/costViews';

import qs from 'qs';

import api from 'helpers/api';

import {
  serializeCostFilterFetchParams,
  serializeDateFilterFetchParams
} from 'helpers/fetchParams';

export const fetchCostViews = () => api.get<CostView[]>('/metrics/cost');

export const fetchCostViewChart = (params: CostViewChartFetchParams) => {
  const {
    filter,
    teamId,
    globalFilterIds,
  } = params;

  return api.post<{ data: CostViewChart }>('/metrics/cost', filter, {
    params: {
      team_id: teamId,
      global_filter_ids: globalFilterIds,
      ...serializeDateFilterFetchParams(params),
      ...serializeCostFilterFetchParams(params),
    },
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
  }).then(({ data }) => data);
};

export const fetchCostViewDrillDown = (params: DrillDownFetchParams) => {
  const {
    tag,
    filter,
    teamId,
    globalFilterIds,
    breakdown,
    drillDownSteps
  } = params;

  return api.post<DrillDownData>('/metrics/cost/drilldown', filter, {
    params: {
      group_by: breakdown,
      tag,
      team_id: teamId,
      global_filter_ids: globalFilterIds,
      ...serializeDateFilterFetchParams(params),
      ...serializeCostFilterFetchParams(params),
      ...drillDownSteps.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {} as Record<string, string>)
    },
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
  });
};

export const fetchCostViewMoversAndShakers = (params: MoversAndShakersFetchParams) => {
  const {
    filter,
    teamId,
    globalFilterIds,
    breakdown,
    minSpend,
    absoluteThreshold,
    relativeThreshold,
  } = params;

  return api.post<MoversAndShakersData>('/metrics/cost/movers', filter, {
    params: {
      team_id: teamId,
      global_filter_ids: globalFilterIds,
      group_by: breakdown,
      min_spend: minSpend,
      abs_threshold: absoluteThreshold,
      rel_threshold: relativeThreshold,
      ...serializeDateFilterFetchParams(params),
      ...serializeCostFilterFetchParams(params),
    },
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
  });
};

export const createCostView = (params: { name: string, filter: CostViewFilter }) => api.post('/metrics/cost/save', params)
export const fetchCostViewConstructor = () => api.get<CostViewConstructorBackend>('/metrics/cost/constructor');
export const fetchCostViewConstructorGCP = () => api.get<CostViewConstructorBackendGCP>('/metrics/cost/gcp/constructor');
export const fetchCostView = (id: number) => api.get<CostView>(`/metrics/cost/${id}`);

export const fetchCostViewForecast = (params: CostViewForecastFetchParams) => {
  const {
    filter,
    teamId,
    globalFilterIds,
    forecastStartDate,
    forecastEndDate
  } = params;

  return api.post<CostViewForecastInfo>('/metrics/cost/forecast', filter, {
    params: {
      team_id: teamId,
      global_filter_ids: globalFilterIds,
      ...serializeDateFilterFetchParams(params),
      ...serializeCostFilterFetchParams(params),
      horizon_start_date: forecastStartDate,
      horizon_end_date: forecastEndDate,
    },
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
  });
};
