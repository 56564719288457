import type {
  User,
  UserDraft
} from 'types/users';

import api from 'helpers/api'
import { 
  CurrentUser,
  OrgUpdatePayload,
  UserUpdatePayload,
  UserUpdateEmailPayload,
  UserUpdatePasswordPayload,
  UserUpdateFeatureFlags,
 } from 'types/users'

export const resendConfirmation = (email: string) => api.post('/user/email/resend-verification', {"email": email})
export const getProfile = () => api.get<CurrentUser>('/user').then((res) => res.data)
export const updateOrganization = (id: number, draft: OrgUpdatePayload) => 
  api.patch(`/organizations/${id}`, draft)
export const updateOrganizationFeatureFlags = (id: number, draft: OrgUpdatePayload) => 
  api.patch(`/organizations/${id}/feature-flags`, draft)
export const updateCurrentUser = (draft: UserUpdatePayload) => 
  api.patch<CurrentUser>('/user', draft).then((res) => res.data)
export const updateCurrentUserFeatureFlags = (draft: UserUpdateFeatureFlags) => 
  api.patch<CurrentUser>('/user/feature-flags', draft).then((res) => res.data)
export const updateCurrentUserEmail = (draft: UserUpdateEmailPayload) => 
  api.patch<CurrentUser>('/user/email', draft).then((res) => res.data)
export const updateCurrentUserPassword = (draft: UserUpdatePasswordPayload) => 
  api.patch<CurrentUser>('/user/password', draft).then((res) => res.data)
export const getSlackIntegration = () => api.get('/integrations?provider=slack')
export const getSlackChannels = () => api.get('/integrations/slack/list-channels')
export const getDataAvailability = () => api.get('/metrics/available-dates')

export const fetchUsers = () => api.get<User[]>('/users');
export const createUser = (params: UserDraft) => api.post('/users/invite', params);
export const updateUser = (id: number, params: Partial<UserDraft>) => api.patch(`/users/${id}`, params);
export const deleteUser = (id: number) => api.delete(`/users/${id}`);
export const recoverUser = (id: number) => api.post(`/users/${id}/recover`);
