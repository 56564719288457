import { fetchOpportunityFilters } from 'services/savings';
import { opportunityFiltersParamsToKey } from 'helpers/savings';
import { createKeyedStore } from 'store/createKeyedStore';
import { createKeyedStoreSelector } from 'store/createKeyedStoreSelector';

export const useOpportunityFiltersStore = createKeyedStore({
  paramsToKey: opportunityFiltersParamsToKey,
  fetchData: fetchOpportunityFilters
});

export const useOpportunityFilters = createKeyedStoreSelector(useOpportunityFiltersStore);
