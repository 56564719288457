import type { Moment } from 'moment';
import type { Granularity } from 'types/common';

import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { isGranularity } from 'types/common';

interface SetDatesParams {
  startDate: Moment,
  endDate: Moment,
  granularity: Granularity
}

export interface UseDatesQueryResult extends SetDatesParams {
  setDatesQuery: (params: SetDatesParams) => void;
};

moment.locale('en', {
  week: {
    dow: 1
  }
});

const FORMATS_BY_GRANULARITY = {
  hour: 'YYYY-MM-DD',
  day: 'YYYY-MM-DD',
  week: 'YYYY-[W]w',
  month: 'YYYY-MM',
  quarter: 'YYYY-[Q]Q',
  year: 'YYYY'
};

const FORMATS = Object.values(FORMATS_BY_GRANULARITY);

const DEFAULT_LENGTHS = {
  hour: 7,
  day: 7,
  week: 4,
  month: 12,
  quarter: 4,
  year: 3
};

const momentToQueryParam = (date: Moment, granularity: Granularity) => {
  return date.format(FORMATS_BY_GRANULARITY[granularity]);
}

const updateQueryParam = (searchParams: URLSearchParams, name: string, value: string | null) => {
  if (value) {
    searchParams.set(name, value);
  } else {
    searchParams.delete(name);
  }
}

const parseDateParam = (dateParam: string | null, defaultDate: Moment, granularity: Granularity, ending: boolean = false) =>{
  const set = !!dateParam;
  const dateMoment = moment(dateParam, FORMATS, true);
  const valid = dateMoment.isValid();

  return {
    set,
    valid,
    date: set && valid ? dateMoment[ending ? 'endOf' : 'startOf'](granularity): defaultDate
  };
};

export interface UseDatesQueryParams {
  defaultGranularity?: Granularity;
  defaultLength?: number;
}

export const useDatesQuery = (params: UseDatesQueryParams = {}): UseDatesQueryResult => {
  const {
    defaultGranularity = 'day'
  } = params;
  const [searchParams, setSearchParams] = useSearchParams();

  const granularityParam = searchParams.get('granularity');
  const granularity: Granularity = isGranularity(granularityParam) ? granularityParam : defaultGranularity;

  const {
    defaultLength = DEFAULT_LENGTHS[granularity]
  } = params;

  const [
    defaultStartDate,
    defaultEndDate
  ] = useMemo(() => {
    const start = moment().subtract(1, 'day').subtract(defaultLength, granularity).startOf(granularity);
    const end = moment().subtract(1, 'day').endOf(granularity);

    return [start, end];
  }, [granularity, defaultLength]);

  const startDateParam = searchParams.get('startDate');
  const endDateParam = searchParams.get('endDate');

  const start = useMemo(() => {
    return parseDateParam(startDateParam, defaultStartDate, granularity);
  }, [startDateParam, defaultStartDate, granularity]);

  const end = useMemo(() => {
    return parseDateParam(endDateParam, defaultEndDate, granularity, true);
  }, [endDateParam, defaultEndDate, granularity]);

  const [
    startDate,
    endDate
  ] = useMemo(() => {
    if (!start.date.isBefore(end.date)) {
      if (start.set && start.valid) {
        return [
          start.date,
          start.date.clone().endOf(granularity)
        ];
      }

      return [
        end.date.clone().startOf(granularity),
        end.date
      ];
    }

    return [start.date, end.date];
  }, [start, end, granularity]);

  const setDatesQuery = useCallback((value: SetDatesParams) => {
    const newSearchParams = new URLSearchParams(searchParams);

    const paramsToUpdate = {
      granularity: value.granularity === 'day' ? null : value.granularity,
      startDate: momentToQueryParam(value.startDate, value.granularity),
      endDate: momentToQueryParam(value.endDate, value.granularity)
    };

    const paramsChanged = Object.entries(paramsToUpdate).reduce((paramsChanged, [name, value]) => {
      if (searchParams.get(name) !== value) {
        updateQueryParam(newSearchParams, name, value);

        return true;
      }

      return paramsChanged;
    }, false);

    if (paramsChanged) {
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchParams]);

  return {
    startDate,
    endDate,
    granularity,
    setDatesQuery
  };
};
