import type {
  EC2OfferingClass,
  Lookback,
  OpportunitiesParams,
  OpportunitiesRequestBody,
  OpportunitiesRequestQuery,
  OpportunitiesRequestParams
} from 'types/savings';

import { serializeTags, buildTextSearch } from './requestUtils';

export const opportunitiesParamsToRequestParams = (params: OpportunitiesParams): OpportunitiesRequestParams => {
  const {
    prefilter,
    tableFilters: {
      difficulty,
      category,
      service,
      account,
      region,
      tags,
      account_tags,
    },
    sort,
    pageFilters,
    pagination
  } = params;

  const query: OpportunitiesRequestQuery = {
    ...prefilter,
    team_id: prefilter.team_id ? prefilter.team_id : pageFilters && pageFilters.teamId,
    global_filter_ids: pageFilters && pageFilters.globalFilterIds,
    meta_category: pageFilters?.group && {
      both: ['usage', 'rate', 'custom'],
      usage: ['usage', 'custom'],
      rate: ['rate', 'custom']
    }[pageFilters.group] as ('usage' | 'rate' | 'custom')[],
    platform: pageFilters?.platform && (pageFilters.platform === 'all' ? ['aws', 'gcp'] : [pageFilters.platform]),
    priority: pageFilters?.priority,

    text_search: buildTextSearch(params),
    difficulty,
    category,
    service,
    account,
    region,
    tags: tags ? serializeTags(tags) : undefined,
    account_tags: account_tags ? serializeTags(account_tags) : undefined,

    order_by: /*sort.field */{
      costImpact: 'cost_impact',
      service: 'service',
      id: 'id',
      status: 'status',
      region: 'region_name',
      difficulty: 'difficulty',
      recordId: 'record_id',
      priority: 'priority',
      category: 'category',
      firstDetected: 'first_detected_at',
      lastDetected: 'last_detected_at',
      account: 'aws_account_id',
      group: 'meta_category',
      tags: 'tags',
      account_tags: 'account_tags'
    }[sort.field] || 'cost_impact',
    order_desc: sort.dir === 'descend',

    paging_size: pagination.perPage,
    paging_last_id: pagination.ids.length ? pagination.ids[pagination.ids.length - 1] : undefined
  };

  const body: OpportunitiesRequestBody = {
    source: pageFilters?.source,
    savings_plans: pageFilters?.rateCommitments?.savingsPlans.enabled ? {
      term: pageFilters.rateCommitments.savingsPlans.term,
      payment_opt: pageFilters.rateCommitments.savingsPlans.payment,
      lookback_days: pageFilters.rateCommitments.savingsPlans.lookback as Lookback
    } : undefined,
    reserved_instances: pageFilters?.rateCommitments?.reservedInstances.enabled ? {
      term: pageFilters.rateCommitments.reservedInstances.term,
      payment_opt: pageFilters.rateCommitments.reservedInstances.payment as 'NO_UPFRONT' | 'PARTIAL_UPFRONT',
      offering_class: pageFilters.rateCommitments.reservedInstances.ec2OfferingClass as EC2OfferingClass
    } : undefined
  };

  return [query, body]
};
