import { message } from 'antd';

import { Button2 as Button } from 'shared/Button';
import { useSavingsStore } from 'store/savings';
import { isValidOpportunityUpdateDraft } from 'helpers/savings';

export interface OpportunityUpdateSubmitProps {
  opportunityKey: number;
  disabled?: boolean;
  onSubmit?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}

export const OpportunityUpdateSubmit = (props: OpportunityUpdateSubmitProps) => {
  const {
    opportunityKey,
    disabled,
    onSubmit,
    onSuccess,
    onError
  } = props;

  const draft = useSavingsStore((store) => store.getOpportunityDraft(opportunityKey));
  const updateOpportunity = useSavingsStore((store) => store.updateOpportunity);
  const resetOpportunityDraft = useSavingsStore((store) => store.resetOpportunityDraft);

  return (
    <Button
      size='m'
      theme='filled'
      disabled={!isValidOpportunityUpdateDraft(draft) || disabled}
      onClick={() => {
        onSubmit && onSubmit();

        updateOpportunity(opportunityKey)
          .then((opportunity) => {
            onSuccess && onSuccess();
            resetOpportunityDraft(opportunityKey);
            message.success(`Opportunity OPP-${opportunity.secondary_id} updated`);
          })
          .catch(() => {
            onError && onError();
            message.error('Unable to update opportunity');
          });
      }}
    >
      Update Opportunity
    </Button>
  );
}
