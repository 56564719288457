import type { Dashboard, Widget } from 'types/dashboards';

import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { message } from 'antd';

import { Page } from 'shared/Page';
import { Loading } from 'shared/Loading';
import { WidgetBlock } from './Widget';
import { AddWidgetButton } from './AddWidgetButton';
import { ActionButtons } from '../UnitMetric/ActionButtons';

import { useDashboardsStore } from 'store/dashboards';
import {EditableTitle} from 'shared/EditableTitle';

export const DashboardPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dashboardId: number | null = id === undefined ? null : +id;

  const dashboardEntry = useDashboardsStore((store) => store.getDashboardEntry(dashboardId));
  const createDashboard = useDashboardsStore((store) => store.createDashboard);
  const updateDashboard = useDashboardsStore((store) => store.updateDashboard);
  const deleteDashboard = useDashboardsStore((store) => store.deleteDashboard);

  const [baseDashboard, setBaseDashboard] = useState<Dashboard | null>(null);
  const [dashboard, setDashboard] = useState<Dashboard | null>(null);
  const [actionInProgress, setActionInProgress] = useState(false);

  useEffect(() => {
    if (dashboardEntry.status === 'success') {
      setBaseDashboard(dashboardEntry.data);
      setDashboard(dashboardEntry.data);
    }
  }, [dashboardEntry]);

  if (!dashboard || !baseDashboard) {
    return <Page className='relative'>
      <Loading />
    </Page>;
  }

  const { name, widgets } = dashboard;
  const setWidgets = (widgets: Widget[]) => {
    setDashboard({ ...dashboard, widgets });
  };

  const handleDelete = () => {
    setActionInProgress(true);
    deleteDashboard(dashboard.id as number)
      .then(() => {
        navigate(`/dashboards/library`);
        message.success(`Dashboard "${dashboard.name}" deleted`);
      })
      .catch(() => {
        message.error(`Error deleting dashboard "${dashboard.name}"`);
      })
      .finally(() => {
        setActionInProgress(false);
      });
  };

  const handleUpdate = () => {
    setActionInProgress(true);
    updateDashboard(dashboard)
      .then(() => {
        message.success(`Dashboard "${dashboard.name}" saved`);
      })
      .catch(() => {
        message.error(`Error saving dashboard "${dashboard.name}"`);
      })
      .finally(() => {
        setActionInProgress(false);
      });
  };

  const handleCreate = () => {
    setActionInProgress(true);
    createDashboard(dashboard)
      .then(({ id }) => {
        message.success(`Dashboard "${dashboard.name}" created`);
        navigate(`/dashboards/${id}`);
      })
      .catch(() => {
        message.error(`Error creating dashboard "${dashboard.name}"`);
      })
      .finally(() => {
        setActionInProgress(false);
      });
  }

  return (
    <Page>
      <Page.Head>
        <div className='min-w-[160px]'>
          <div className='text-[10px] font-medium line-[18px]'>
            <Link
              to='/dashboards/library'
              className='text-cyanine-blue hover:text-caladon-blue'
            >
              Dashbards
            </Link>
          </div>
          <EditableTitle
            value={name}
            placeholder='Enter dashboard name'
            onChange={(name) => { setDashboard({ ...dashboard, name }); }}
          />
        </div>
        <ActionButtons
          del={{
            disabled: actionInProgress,
            available: dashboard.id !== undefined,
            onClick: handleDelete
          }}
          save={{
            available: dashboard.id !== undefined,
              disabled: name === baseDashboard.name && widgets === baseDashboard.widgets ||
              widgets.length === 0 ||
              name.length === 0 ||
              actionInProgress,
            onClick: handleUpdate
          }}
          saveAsNew={{
            disabled: widgets.length === 0 ||
              name.length === 0 ||
              actionInProgress,
            onClick: handleCreate
          }}
          newAlert={{ available: false }}
          newReport={{ available: false }}
        />
      </Page.Head>
      <div className='grid grid-cols-2 gap-[15px]'>
        {widgets.map((widget, index) => (
          <WidgetBlock
            key={index}
            widget={widget}
            onChange={(newWidget) => {
              const newWidgets = [...widgets];

              newWidgets[index] = newWidget;

              setWidgets(newWidgets);
            }}
            onRemove={() => {
              const newWidgets = [...widgets];

              newWidgets.splice(index, 1);

              setWidgets(newWidgets);
            }}
          />
        ))}
        <AddWidgetButton
          onClick={() => {
            setWidgets([
              ...widgets,
              {
                type: null,
                settings: {}
              }
            ]);
          }}
        />
      </div>
    </Page>
  );
};
