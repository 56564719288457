import type { FetchStatus } from 'types/common';
import type { Budget, CostView, CostViewFilter } from 'types/costViews';

import create from 'zustand';
import {
  fetchCostView
} from 'services/costViews';

import {
  GroupByType,
  saveCostView,
  updateCostView,
  deleteCostView,
} from 'services/metric';

export interface RightSizingRDSAttr {
  product_instance_type: string;
  product_database_engine: string; 
  product_orginal_target_memory: string;
  product_utilization: string;
  product_type: string;
  product_utilization_type: string; 
  product_memory: string;
  product_usage_type: string;
}

export interface RightSizingECSMemoryAttr {
  product_task_count: string;
  product_task_definition_arn: string; 
  product_task_arn: string;
  product_orginal_target_memory: string;
  product_utilization: string; 
  product_utilization_type: string; 
  product_memory: string;
}

export interface RightSizingECSCPUAttr {
  product_task_count: string;
  product_task_definition_arn: string; 
  product_task_arn: string;
  product_orginal_target_cpu: string;
  product_utilization: string; 
  product_utilization_type: string; 
  product_cpu: string;
}

export interface RightSizingEC2Attr {
  product_instance_type: string;
  product_operating_system: string; 
  product_orginal_target_memory: string;
  product_utilization: string; 
  product_type: string;
  product_utilization_type: string; 
  product_memory: string;
  product_usage_type: string;
}

export interface RightSizingEC2OptionAttr {
  target_instance_type: string;
  actual_target_memory: string;
  target_usage_type: string;
}

export interface RightSizingECSMemoryOptionAttr {
  actual_target_memory: string;
}

export interface RightSizingECSCPUOptionAttr {
  actual_target_cpu: string;
}

export interface RightSizingRDSOptionAttr {
  target_instance_type: string;
  actual_target_memory: string;
  target_usage_type: string;
}

export interface RightSizingData {
  aws_account_id: string;
  region_name: string;
  usage_type: string;
  billed_operation: string;
  resource_id: string;
  type: string;
  product_attr: RightSizingEC2Attr | RightSizingRDSAttr | RightSizingECSCPUAttr | RightSizingECSMemoryAttr;
  option_attr: RightSizingEC2OptionAttr | RightSizingRDSOptionAttr | RightSizingECSCPUOptionAttr | RightSizingECSMemoryOptionAttr;
  product_rate: number;
  option_rate: number;
}

type DrillDownStep = [GroupByType, string];

interface CostOverviewState {
  id: number | null;
  view: CostView | null;
  viewStatus: FetchStatus;
  newViewName: string;
  drillDownOptions: Record<string, { label: string, value: string }>;
  drillDownOpened: boolean;
  chartCollapsed: boolean;
  filterParam: CostViewFilter;
  filterChanged: boolean;
}

interface CostOverviewActions {
  setNewViewName: (name: string) => void;
  setDrillDownOpened: (opened: boolean) => void;
  setChartCollapsed: (collapsed: boolean) => void;
  setDrillDown: (drillDownSteps: DrillDownStep[]) => void;
  isFiltersApplied: () => boolean;
  setFilter: (filter: CostViewFilter) => void;
  updateFilter: (filter: CostViewFilter) => void;
  saveView: () => Promise<CostView>;
  updateView: () => Promise<void>;
  deleteView: () => Promise<void>;
  fetchView: (id: number | null) => Promise<void>;
  editViewBudget: (budget: Budget) => void;
  deleteViewBudget: (budgetId: number) => void;
  createViewBudget: (budget: Budget) => void;
}

interface CostOverviewStore extends CostOverviewState, CostOverviewActions {};

export const EMPTY_FILTER: CostViewFilter = {
  src: 'cur',
  filter: { operator: 'or', condition: [] }
};

const DEFAULT_STATE: CostOverviewState = {
  id: null,
  view: null,
  viewStatus: 'loading',
  newViewName: '',
  drillDownOptions: {},
  drillDownOpened: false,
  chartCollapsed: false,
  filterParam: EMPTY_FILTER,
  filterChanged: false,
}

export const useCostOverviewStore = create<CostOverviewStore>((set, get) => {
  return ({
    ...DEFAULT_STATE,

    setDrillDownOpened: (drillDownOpened: boolean) => {
      set({ drillDownOpened })
    },

    setChartCollapsed: (chartCollapsed: boolean) => {
      set({ chartCollapsed })
    },

    isFiltersApplied: () => {
      const { filterParam } = get()
      return filterParam && !!filterParam.filter.condition.length
    },

    setDrillDown: async (drillDownSteps) => {
      set({
        drillDownOpened: drillDownSteps.length > 0
      });
    },

    saveView: async () => {
      const { newViewName, filterParam } = get();
      const { data: view } = await saveCostView(newViewName, filterParam);

      return view;
    },

    updateView: async () => {
      const { view, newViewName, filterParam } = get();

      if (!view) {
        return;
      }
      const name = newViewName !== '' && newViewName !== view.name ? newViewName : view.name
      const { data: { filter }} = await updateCostView(view.id, name, filterParam)

      set({ view: { ...view, filter } })
    },

    deleteView: async () => {
      const { view } = get();

      if (!view) {
        return;
      }

      try {
        await deleteCostView(view.id)
      } catch {
      }
    },

    setFilter: (filter: CostViewFilter) => {
      set({
        filterChanged: false,
        filterParam: filter
      })
    },

    updateFilter: (filter: CostViewFilter) => {
      set({
        filterChanged: true,
        filterParam: filter
      })
    },

    fetchView: async (id) => {
      set({ id });

      if (id === null) {
        set({
          view: null,
          viewStatus: 'success',
        });

      } else {
        try {
          set({ viewStatus: 'loading' });

          const { data: view } = await fetchCostView(id);

          set({
            view,
            viewStatus: 'success',
            filterParam: view.filter
          });
        } catch {
          set({ viewStatus: 'error' });
        }
      }
    },

    setNewViewName: (newViewName: string) => {
      set({ newViewName });
    },
    
    editViewBudget: (budget: Budget) => {
      const { view } = get();

      if (view) {
        set({
          view: {
            ...view,
            budgets: [
              ...view.budgets.filter((b) => b.id !== budget.id),
              budget
            ]
          }
        });
      }
    },

    deleteViewBudget: (budgetId: number) => {
      const { view } = get();
      if (view) {
        set({
          view: {
            ...view,
            budgets: [
              ...view.budgets.filter((b) => b.id !== budgetId),
            ]
          }
        });
      }
    },

    createViewBudget: (budget: Budget) => {
      const { view } = get();
      if (view) {
        set({
          view: {
            ...view,
            budgets: [
              ...view.budgets,
              budget
            ]
          }
        });
      }
    },
  });
});
