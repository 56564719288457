import type { UserDraft } from 'types/users';

import { message, Select } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button2 as Button } from 'shared/Button';
import { useUsersStore, useUserRoleDialogStore } from 'store/users';

export const RoleDialog = () => {
  const id = useUserRoleDialogStore((store) => store.id);
  const close = useUserRoleDialogStore((store) => store.close);

  const user = useUsersStore((store) => {
    return id ? store.getEntry(id) : null;
  });

  const userDraft = useUsersStore((store) => {
    return id ? store.getDraft(id) : null;
  });

  const setUserDraft = useUsersStore((store) => store.setDraft);
  const updateUser = useUsersStore((store) => store.updateEntry);

  return <Dialog
    open={id !== null}
    onClose={close}
    className='w-[420px] overflow-visible'
  >
    <Dialog.Title>
      Change {user && user.status === 'success' && user.data.name}'s role
    </Dialog.Title>

    <div className='p-8 pt-0'>
      <Select
        className='w-full'
        size='large'
        value={userDraft && userDraft.role}
        options={[
          { value: 'admin', label: 'Admin' },
          { value: 'member', label: 'Member' }
        ]}
        disabled={!id}
        onChange={(role) => {
          setUserDraft(id as number, { ...userDraft as UserDraft, role });
        }}
      />

      <Button
        className='w-full mt-5'
        theme='filled'
        size='m'
        disabled={!userDraft || !user || user.status !== 'success' || user.data.role === userDraft.role}
        onClick={() => {
          const name = user && user.status === 'success' && user.data.name || '';
          const role = userDraft && userDraft.role;

          close();
          updateUser(id as number)
            .then(() => {
              message.success(`${name}'s role changed to ${role}`);
            })
            .catch(() => {
              message.error(`Unable to change ${name}'s role`);
            });
        }}
      >
        Change Role
      </Button>
    </div>
  </Dialog>;
}
