import type { DataEntry } from 'types/dataEntries';
import type { CostViewConstructor } from 'types/costViews';

import create from 'zustand';
import { fetchCostViewConstructor, fetchCostViewConstructorGCP } from 'services/costViews';
import { fetchUnitMetricConstructorCustom } from 'services/unitMetrics';
import { parseCostViewConstructor } from 'helpers/costViews';

export interface CostViewConstructorState {
  costViewConstructor: DataEntry<CostViewConstructor>;
}

export interface CostViewConstructorActions {
  fetchCostViewConstructor: () => void;
  getCostViewConstructor: () => DataEntry<CostViewConstructor>;
}

export interface CostViewConstructorStore extends CostViewConstructorState, CostViewConstructorActions {}

const DEFAULT_STATE: CostViewConstructorState = {
  costViewConstructor: { status: 'idle' }
};

export const useCostViewConstructorStore = create<CostViewConstructorStore>((set, get) => ({
  ...DEFAULT_STATE,

  fetchCostViewConstructor: () => {
    set({ 
      costViewConstructor: {
        status: 'loading'
      }
    });

    Promise.all([
      fetchCostViewConstructor(),
      fetchUnitMetricConstructorCustom(),
      fetchCostViewConstructorGCP().catch(() => ({ data: undefined }))
    ])
      .then(([
        { data: constructorBackend },
        { data: constructorBackendCustom },
        { data: constructorBackendGCP }
      ]) => {
        const data = parseCostViewConstructor(
          constructorBackend,
          constructorBackendCustom,
          constructorBackendGCP
        );

        set({
          costViewConstructor: {
            status: 'success',
            data
          }
        });
      })
      .catch((error: any) => {
        set({
          costViewConstructor: {
            status: 'error',
            error
          }
        });
      })
  },

  getCostViewConstructor: () => {
    if (get().costViewConstructor.status === 'idle') {
      get().fetchCostViewConstructor();
    }

    return get().costViewConstructor;
  }
}));

export const useCostViewConstructor = () => useCostViewConstructorStore((store) => store.getCostViewConstructor());
