export const mergeArrays = <T = any>(...arrays: (T[] | undefined)[]): T[] => Array.from(
  new Set<T>(
    ([] as T[]).concat(...(arrays.filter(Boolean) as T[][]))
  )
);

export const createPickFilter = <T extends {}>(pickFuncs: ((obj: T) => React.ReactNode)[]) => (array: T[], query: string): T[] => {
  if (query.trim() === '') {
    return array;
  }

  let match: (str: string) => boolean;

  try {
    const regexp = new RegExp(query, 'i');

    match = (str: string) => regexp.test(str);
  } catch {
    const normalizedQuery = query.trim().toLowerCase();

    match = (str: string) => str.trim().toLowerCase().includes(normalizedQuery);
  }

  return array.filter((item) => {
    return pickFuncs.some((pick) => {
      const value = pick(item);

      return typeof value === 'string' && match(value);
    });
  });
}
