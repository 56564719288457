import { useEffect, useState } from 'react';

export const useDocumentScrollThreshold = (threshold: number = 0) => {
  const [pastThreshold, setPastThreshold] = useState(() => document.documentElement.scrollTop > threshold);

  useEffect(() => {
    const onScroll = () => {
      const newPast = document.documentElement.scrollTop > threshold;

      setPastThreshold(newPast);
    }

    document.addEventListener('scroll', onScroll);
    onScroll();

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [threshold]);

  return pastThreshold;
}
