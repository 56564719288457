import { Radio } from 'antd';
import { useMemo } from 'react';
import { COST_TYPES } from 'types/costViews';
import { COST_TYPE_LABELS } from './constants';
import { useCostType } from 'hooks/costFilter';
import { useCostColumnStore } from 'store/costColumns';
import { useGlobalState } from 'state/globalState';

export const CostTypeControl = () => {
  const [value, onChange] = useCostType();
  const { costColumns } = useCostColumnStore();
  const user = useGlobalState((state) => state.user);
  const costColumnsTabAvailable = useMemo(() => user?.permissions.includes('admin'), [user]);

  return (
    <div>
      <Radio.Group
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      >
        {COST_TYPES.map((costType) => (
          <div
            className='my-1'
            key={costType}
          >
            <Radio value={costType}>
              {COST_TYPE_LABELS[costType]}
            </Radio>
          </div>
        ))}

        {costColumnsTabAvailable && costColumns.map((column) => (
          <div
            className='my-1'
            key={`${column.id}`}
          >
            <Radio value={`${column.id}`}>
              {column.name}
            </Radio>
          </div>
        ))}
      </Radio.Group>
    </div>
  )
};
