import { Tooltip } from 'antd';
import { DifficultyIcon } from './DifficultyIcon';
import { DIFFICULTY_DESCRIPTIONS } from './constants';
import { capitalize } from 'helpers/string';

export interface DifficultyBadgeProps {
  className?: string;
  tooltip?: boolean;
  difficulty: 'easy' | 'medium' | 'hard';
}

export const DifficultyBadge = (props: DifficultyBadgeProps) => {
  const {
    className = '',
    tooltip = true,
    difficulty
  } = props;

  const content = (
    <span className={`inline-flex items-center gap-[5px] ${className}`}>
      <DifficultyIcon difficulty={difficulty} />

      {capitalize(difficulty)}
    </span>
  );

  if (!tooltip) {
    return content;
  }

  return (
    <Tooltip
      title={`${capitalize(difficulty)}: ${DIFFICULTY_DESCRIPTIONS[difficulty]}`}
    >
      {content}
    </Tooltip>
  );
}
