import type { ReactNode } from 'react';

export interface SectionFooterProps {
  className?: string;
  children?: ReactNode;
}

export const SectionFooter = (props: SectionFooterProps) => {
  const { className, children } = props;

  return (
    <div className={`flex items-center justify-between mx-[20px] min-h-[60px] shadow-border-t ${className}`}>
      {children}
    </div>
  );
};
