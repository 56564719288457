import type { ReportConfig } from 'types/reports'
import type { CostViewLibraryEntry } from 'types/costViews'
import type { UnitMetricsLibraryEntry } from 'types/unitMetrics'

import { useState, useEffect } from 'react'
import { Select, message, Checkbox, Input, InputNumber } from 'antd'
import { formatRounded } from 'helpers/formatter'
import { validateEmail } from 'helpers/validator'
import { Dialog } from 'shared/Dialog';
import { Section } from 'shared/Section';
import { Button } from 'shared/Button';
import { InfoIcon } from 'shared/InfoIcon';
import { useReportStore } from 'store/reportConfigs';
import { useSlackChannelsStore } from 'store/slackChannels'
import { useGlobalState } from 'state/globalState'

export interface CreateDialogProps {
  opened: boolean;
  reportToEdit: ReportConfig | null;
  onClose: (search?: string) => void;
  costViews: CostViewLibraryEntry[];
  unitMetrics: UnitMetricsLibraryEntry[];
  slackIntegraitonId: number;
  slackChannels: string[];
  tags: { key: string, value: string }[];
}

export const CreateDialog = (props: CreateDialogProps) => {
  const {
    opened,
    reportToEdit,
    onClose,
    costViews,
    unitMetrics,
    slackIntegraitonId,
    slackChannels,
    tags,
  } = props;
  const { user } = useGlobalState();
  const { saveReportConfig } = useReportStore();
  const [reportName, setReportName] = useState('')
  const [reportType, setReportType] = useState<'OPPORTUNITIES' | 'GENERALCOST'>('OPPORTUNITIES')
  const [dailyMovers, setDailyMovers] = useState(false)
  const [absoluteSpendThreshold, setAbsoluteSpendThreshold] = useState(0)
  const [absoluteChangeThreshold, setAbsoluteChangeThreshold] = useState(0)
  const [relativeChangeThreshold, setRelativeChangeThreshold] = useState(0)
  const [dailyAbsoluteSpendThreshold, setDailyAbsoluteSpendThreshold] = useState(0)
  const [dailyAbsoluteChangeThreshold, setDailyAbsoluteChangeThreshold] = useState(0)
  const [dailyRelativeChangeThreshold, setDailyRelativeChangeThreshold] = useState(0)
  const [recepientEmails, setRecepientEmails] = useState('')
  const [newSlackIntegraitonId, setNewSlackIntegraitonId] = useState(false)
  const [recepientSlackChannels, setRecepientSlackChannels] = useState<string[]>([])
  const [costFilterId, setCostFilterId] = useState<number | null>(null)
  const [unitMetriIdList, setUnitMetricIdList] = useState<number[]>([])
  const [tagKeyList, setTagKeyList] = useState<string[]>([])
  const { fetchSlackChannels } = useSlackChannelsStore();

  const handleSlackClick = () => {
    window.open('https://app.cloudthread.io/settings/third-party-integrations', '_blank')
  }

  const handlFetchSlackIntegration = () => {
    fetchSlackChannels();
  }

  const handleChangeReportName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReportName(e.target.value)
  }

  const handleChangeReportType = (value: 'OPPORTUNITIES' | 'GENERALCOST') => {
    setReportType(value)
  }

  const handleChangeAbsoluteSpendThreshold = (value: number | null) => {
    setAbsoluteSpendThreshold(value ? value : 0)
  }

  const handleChangeDailyAbsoluteSpendThreshold = (value: number | null) => {
    setDailyAbsoluteSpendThreshold(value ? value : 0)
  }

  const handleChangeAbsoluteChangeThreshold = (value: number | null) => {
    setAbsoluteChangeThreshold(value ? value : 0)
  }

  const handleChangeDailyAbsoluteChangeThreshold = (value: number | null) => {
    setDailyAbsoluteChangeThreshold(value ? value : 0)
  }

  const handleChangeRelativeChangeThreshold = (value: number | null) => {
    setRelativeChangeThreshold(value ? value : 0)
  }

  const handleChangeDailyRelativeChangeThreshold = (value: number | null) => {
    setDailyRelativeChangeThreshold(value ? value : 0)
  }

  const handleChangeRecipientEmails = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecepientEmails(e.target.value)
  }

  const handleChangeRecipientSlackChannels = (channelList: string[]) => {
    setRecepientSlackChannels(channelList)
  }

  const handleChangeCostFilterId = (id: number) => {
    setCostFilterId(id)
  }

  const handleChangeUnitMetricList = (unitMetricList: number[]) => {
    if (unitMetricList.length > 5) {
      message.error("Limit of 5 Unit Metrics per report")
      return
    }
    setUnitMetricIdList(unitMetricList)
  }

  const handleChangeTagsList = (tagList: string[]) => {
    if (tagList.length > 3) {
      message.error("Limit of 3 Tags per report")
      return
    }
    setTagKeyList(tagList)
  }

  const handleDailyMovers = () => {
    setDailyMovers(!dailyMovers)
  }

  const handleClose = (search?: string) => {
    onClose(search);
  };

  useEffect(() => {
    if (!opened) {
      setCostFilterId(null)
      setUnitMetricIdList([])
      setTagKeyList([])
      setAbsoluteSpendThreshold(0)
      setAbsoluteChangeThreshold(0)
      setRelativeChangeThreshold(0)
      setDailyAbsoluteSpendThreshold(0)
      setDailyAbsoluteChangeThreshold(0)
      setDailyRelativeChangeThreshold(0)
      setDailyMovers(false)
      setRecepientEmails('')
      setRecepientSlackChannels([])
      setReportName('')
    } else if (reportToEdit) {
      setCostFilterId(reportToEdit.cost_metric_filter_id)
      setUnitMetricIdList(reportToEdit.unit_metric_filter_ids)
      setTagKeyList(reportToEdit.tag_keys)
      setAbsoluteSpendThreshold(reportToEdit.wow_change_spend_threshold)
      setAbsoluteChangeThreshold(reportToEdit.wow_change_delta_abs_threshold)
      setRelativeChangeThreshold((reportToEdit.wow_change_delta_rel_threshold - 1) * 100)
      setDailyAbsoluteSpendThreshold(reportToEdit.dod_change_spend_threshold)
      setDailyAbsoluteChangeThreshold(reportToEdit.dod_change_delta_abs_threshold)
      setDailyRelativeChangeThreshold((reportToEdit.dod_change_delta_rel_threshold - 1) * 100)
      setDailyMovers(reportToEdit.daily_movers)
      setRecepientEmails(reportToEdit.email_recepients.join(', '))
      if (reportToEdit.slack_id !== slackIntegraitonId) {
        setNewSlackIntegraitonId(true)
      } else {
        setRecepientSlackChannels(reportToEdit.slack_channels)
      }
      setReportType(reportToEdit.report_type_id)
      setReportName(reportToEdit.name)
    }
  }, [opened, reportToEdit])

  return (
    <Dialog
      open={opened}
      onClose={() => handleClose()}
      className='w-[510px]'
    >
      <Dialog.Title>
      {`${!reportToEdit ? 'Create New' : 'Edit'} Report`}
      </Dialog.Title>

      <div className='px-[30px] pb-[30px] overflow-y-scroll'>
        <div className='mb-[30px]'>
          <div className='mb-2 font-medium text-medium'>
            Report name
          </div>
          <Input
            type='secondary'
            value={reportName}
            onChange={handleChangeReportName}
          />
        </div>
        <Select 
          value={reportType}
          className="w-full"
          onChange={handleChangeReportType}
          options={[
            { value: 'OPPORTUNITIES', label: 'Savings Report' },
            { value: 'GENERALCOST', label: 'Cost Report' },
          ]}
        />
        <div className='mt-2 ml-1'>
          <Section.InputDescription>
            {reportType == 'GENERALCOST' ? (
              <div>
                Detailed insights, overview summary, anomaly detection, unit economics, and tag cost analysis filtered by Custom Cost Views
              </div>
            ) : (
              <div>
                Overview of savings opportunities and savings activity.
              </div>
            )}
          </Section.InputDescription>
        </div>
        {reportType == 'GENERALCOST' && (
          <div>
            <div className='text-medium font-medium mt-[20px] mb-2'>
              Filtering
            </div>
            <div className='mb-[20px]'>
              <div className='flex mb-1'>
                Cost View
                <InfoIcon>
                  Filter the report using a Cost View you've saved
                </InfoIcon>
                {costFilterId && (
                    <Button 
                      size='xs' 
                      type='tertiary' 
                      label='Clear' 
                      onClick={() => setCostFilterId(null)}
                      className='ml-auto'
                    />
                )}
              </div>
              <Select 
                showSearch
                className="w-full"
                placeholder="No View (Total Spend)"
                onChange={handleChangeCostFilterId}
                value={costFilterId}
                disabled={user?.org_is_data_ready ? false : true}
                options={costViews.filter((view) => view.is_custom).map(
                  (filter) => {return { value: filter.id, label: filter.name }}
                )}
              />
            </div>
            <div className='text-medium font-medium mt-[20px] mb-2'>
              Detailed Insights
            </div>
            <div className='mb-[20px]'>
              <div className='mb-1'>
                Unit Metrics (max 5)
                <InfoIcon>
                  Select custom unit metrics that you would like to include in the report
                </InfoIcon>
              </div>
              <Select
                mode="multiple"
                showSearch
                className="w-full"
                placeholder="Choose unit metrics"
                onChange={handleChangeUnitMetricList}
                value={unitMetriIdList}
                disabled={user?.org_is_data_ready ? false : true}
                options={unitMetrics.filter((metric) => metric.is_custom).map(
                  (metric) => {return { value: metric.id, label: metric.name }}
                )}
              />
            </div>
            <div className='text-medium font-medium mt-[20px] mb-2'>
              Tags
            </div>
            <div className='mb-[20px]'>
              <div className='mb-1'>
                Tag Keys (max 3)
                <InfoIcon>
                  Choose up to 3x tag keys that you would like to 
                  include an analysis of in both the weekly overview report and the daily report.
                </InfoIcon>
              </div>
              <Select
                mode="multiple"
                showSearch
                className="w-full"
                placeholder='Choose tags to specifically report on'
                onChange={handleChangeTagsList}
                value={tagKeyList}
                disabled={user?.org_is_data_ready ? false : true}
                options={tags.map(({ key, value }) => ({ value: key, label: value }))}
              />
            </div>
            <div className='text-medium font-medium mt-[20px] mb-2'>
              Movers and Shakers
            </div>
            <div className='mb-[20px]'>
              <div className='mb-1'>
                Weekly minimum spend threshold
                <InfoIcon>
                  <p className='mb-2'>
                    You don't want dimensions with weekly spend below this absolute spend number to be flagged as a weekly mover and shaker. Must be positive.
                  </p>

                  <p>
                    e.g. if Absolute Spend Threshold is $10 and your Lambda pricing goes up by 500% but still only costs $9, Lambda won't show up in weekly movers and shakers.
                  </p>
                </InfoIcon>
              </div>
              <InputNumber
                addonBefore={'$'}
                min={0}
                precision={2}
                value={absoluteSpendThreshold}
                onChange={handleChangeAbsoluteSpendThreshold}
              />
            </div>
            <div className='mb-[20px]'>
              <div className='mb-1'>
                Weekly minimum spend change
              </div>
              <div className='flex justify-center'>
                <div className='w-full'>
                  <InputNumber
                    addonBefore={'$'}
                    min={0}
                    precision={2}
                    value={absoluteChangeThreshold}
                    onChange={handleChangeAbsoluteChangeThreshold}
                  />
                  <div className='text-[13px] text-silver-grey-800 mt-1'>
                    Absolute
                    <InfoIcon>
                      <p className='mb-2'>
                        You don't want cost movements below this absolute change to be flagged as a weekly mover and shaker. Must be positive.
                      </p>

                      <p>
                        e.g. if Absolute Change Threshold is $50 and your Lambda pricing goes up from $10,020 last week to $10,050 this week, Lambda has increased by only $30 and won't show in weekly movers and shakers.
                      </p>
                    </InfoIcon>
                  </div>
                </div>
                <div className='font-medium mx-5 mt-[5px]'>or</div>
                <div className='w-full'>
                  <InputNumber
                    addonAfter={'%'}
                    min={0}
                    precision={2}
                    value={relativeChangeThreshold}
                    onChange={handleChangeRelativeChangeThreshold}
                  />
                  <div className='text-[13px] text-silver-grey-800 mt-1'>
                    Relative
                    <InfoIcon>
                      <p className='mb-2'>
                        You don't want cost movements below this relative change to flagged as a weekly mover and shaker. Must be positive.
                      </p>

                      <p>
                        e.g. if Relative Change Threshold is 10% and your Lambda pricing goes up from $100 last week to $109 this week, Lambda has increased by only 9% and won't show up in weekly movers and shakers.
                      </p>
                    </InfoIcon>
                  </div>
                </div>
              </div>  
            </div>
            <div className='mt-[25px] mb-[20px]'>
              Daily Movers and Shakers
            </div>
            <Checkbox onChange={handleDailyMovers} checked={dailyMovers}>
              <Section.InputDescription>
                {`Receive daily reports if Cloudthread detects any week-over-week ${reportType == 'GENERALCOST' ? 'Movers and Shakers' : 'Opportunities'} for the previous day`}
              </Section.InputDescription>
            </Checkbox>
            {dailyMovers && (
              <div>
                <div className='mb-[20px]'>
                  <div className='mb-1'>
                    Daily minimum spend threshold
                    <InfoIcon>
                      <p className='mb-2'>
                        You don't want dimensions with daily spend below this absolute spend number to be flagged as a daily mover and shaker. Must be positive.
                      </p>

                      <p>
                        e.g. if Absolute Spend Threshold is $10 and your Lambda pricing goes up by 500% but still only costs $9, Lambda won't show up in daily movers and shakers.
                      </p>
                    </InfoIcon>
                  </div>
                  <InputNumber
                    addonBefore={'$'}
                    min={0}
                    precision={2}
                    value={dailyAbsoluteSpendThreshold}
                    onChange={handleChangeDailyAbsoluteSpendThreshold}
                  />
                </div>
                <div className='mb-[20px]'>
                  <div className='mb-1'>
                    Daily minimum spend change
                  </div>
                  <div className='flex justify-center'>
                    <div className='w-full'>
                      <InputNumber
                        addonBefore={'$'}
                        min={0}
                        precision={2}
                        value={dailyAbsoluteChangeThreshold}
                        onChange={handleChangeDailyAbsoluteChangeThreshold}
                      />
                      <div className='text-[13px] text-silver-grey-800 mt-1'>
                        Absolute
                        <InfoIcon>
                          <p className='mb-2'>
                            You don't want cost movements below this absolute change to be flagged as a daily mover and shaker. Must be positive.
                          </p>

                          <p>
                            e.g. if Absolute Change Threshold is $50 and your Lambda pricing goes up from $10,020 last week to $10,050 this week, Lambda has increased by only $30 and won't show up in daily movers and shakers.
                          </p>
                        </InfoIcon>
                      </div>
                    </div>
                    <div className='font-medium mx-5 mt-[5px]'>or</div>
                    <div className='w-full'>
                      <InputNumber
                        addonAfter={'%'}
                        min={0}
                        precision={2}
                        value={dailyRelativeChangeThreshold}
                        onChange={handleChangeDailyRelativeChangeThreshold}
                      />
                      <div className='text-[13px] text-silver-grey-800 mt-1'>
                        Relative
                        <InfoIcon>
                          <p className='mb-2'>
                            You don't want cost movements below this relative change to be flagged as a daily mover and shaker. Must be positive.
                          </p>

                          <p>
                            e.g. if Relative Change Threshold is 10% and your Lambda pricing goes up from $100 last week to $109 this week, Lambda has increased by only 9% and won't show up in daily movers and shakers.
                          </p>
                        </InfoIcon>
                      </div>
                    </div>
                  </div>  
                </div>
              </div>
            )}
          </div>
        )}
        <div className='mb-[40px]'>
          <div className='text-[16px] font-medium mb-[10px]'>
            Channels of Delivery
          </div>
          <div className='mb-2'>
            Emails
            <InfoIcon>
              <p className='mb-2'>
                Recipient emails (comma separated)
              </p>
              <p>
                Note: any emails will work, they don't need to have an associated Cloudthread user
              </p>
            </InfoIcon>
          </div>
          <Input
            type='secondary'
            value={recepientEmails}
            onChange={handleChangeRecipientEmails}
          />
          <div className='mb-2 mt-[20px]'>
            Slack Channels
            <InfoIcon>
              These are all your workspace's public, non-archived channels
            </InfoIcon>
          </div>
          {newSlackIntegraitonId && <div className='text-[13px] text-silver-grey-800'>New Slack Integration – please select new channels</div>}
          {slackChannels.length ? (
              <Select mode="multiple" className="w-full" placeholder="Choose slack channels" onChange={handleChangeRecipientSlackChannels} value={recepientSlackChannels}>
                {slackChannels.map(
                  (channel) => (
                    <Select.Option value={channel}>{channel}</Select.Option>
                  ),
                )}
              </Select>
          ) : (
            <div className='flex'>
              <a onClick={handleSlackClick} className='text-cyanine-blue hover:text-caladon-blue'>Integrate Slack</a>
              <div className='ml-5'>|</div>
              <a onClick={handlFetchSlackIntegration} className='ml-5 text-cyanine-blue hover:text-caladon-blue'>Fetch Slack Channels</a>
            </div>
          )}
        </div>
        <div className='flex'>
          <Button
            label='Clear All'
            className='mr-auto'
            type='tertiary'
            style='caladon'
            onClick={() => {
              setCostFilterId(null)
              setUnitMetricIdList([])
              setTagKeyList([])
              setAbsoluteSpendThreshold(0)
              setAbsoluteChangeThreshold(0)
              setRelativeChangeThreshold(0)
              setDailyAbsoluteSpendThreshold(0)
              setDailyAbsoluteChangeThreshold(0)
              setDailyRelativeChangeThreshold(0)
              setDailyMovers(false)
              setRecepientEmails('')
              setRecepientSlackChannels([])
              setReportName('')
            }}
          />
          <Button
            label='Cancel'
            type='tertiary'
            style='caladon'
            onClick={() => handleClose()}
          />
          <Button
            label='Save Report'
            type='primary'
            style='caladon'
            disabled={!reportName || (!recepientEmails && recepientSlackChannels.length === 0)}
            onClick={async () => {
              const emails = recepientEmails.split(',').map((email) => email.trim()).filter((email) => email !== '')
              if (emails.filter((email) => !validateEmail(email)).length > 0) {
                message.error('At least one email is invalid')
                return
              }
              if (costFilterId) {
                const targetView = costViews.filter((view) => view.id === costFilterId)[0]
                if (targetView.src !== 'cur') {
                  message.error('Only AWS CUR views are supported for filtering reports')
                  return
                }
                if (targetView.id >= 4294967296 ) {
                  message.error('Only non-Base views are supported for filtering reports')
                  return
                }
              }
              if (unitMetriIdList.length > 0) {
                if (Math.max(...unitMetriIdList) >= 4294967296 ) {
                  message.error('Only non-Base unit metrics are supported for filtering reports')
                  return
                }
              }
              let baseDetails = {
                providers: {
                  email: {
                    recipients: emails,
                  },
                  slack: {
                    integration_id: slackIntegraitonId,
                    recipients: recepientSlackChannels,
                  },
                },
                daily_movers: dailyMovers,
                tag_keys: tagKeyList,
              }
              let config = {
                id: reportToEdit && reportToEdit.id > 0 ? reportToEdit.id : -1,
                report_type_id: reportType,
                cadence: 'weekly_tuesday', // this will be replaced when we have more report type ids
                name: reportName,
                report_details: baseDetails,
              }
              if (reportType == 'GENERALCOST') {
                const extraParams = {
                  cost_metric_filter_id: costFilterId,
                  unit_metric_filter_ids: unitMetriIdList,
                }
                const extraReportDetailParams = {
                  wow_change_spend_threshold: absoluteSpendThreshold,
                  wow_change_delta_abs_threshold: absoluteChangeThreshold,
                  wow_change_delta_rel_threshold: 1 + formatRounded(relativeChangeThreshold / 100, 2),
                  dod_change_spend_threshold: dailyAbsoluteSpendThreshold,
                  dod_change_delta_abs_threshold: dailyAbsoluteChangeThreshold,
                  dod_change_delta_rel_threshold: 1 + formatRounded(dailyRelativeChangeThreshold / 100, 2),
                  cost_column: 'unblended_cost',
                  amortization: false,
                  exclude: '',
                }
                config = {
                  ...config,
                  ...extraParams,
                  report_details: {
                    ...config.report_details,
                    ...extraReportDetailParams,
                  },
                }
              }

              saveReportConfig(config);
              handleClose(reportName);
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default CreateDialog
