import { UsersTable } from './UsersTable';
import { InviteDialog } from './InviteDialog';
import { RoleDialog } from './RoleDialog'
import { DeleteDialog } from './DeleteDialog';

export const UsersTab = () => {
  return (
    <div className='pt-[35px]'>
      <UsersTable />
      <RoleDialog />
      <InviteDialog />
      <DeleteDialog />
    </div>
  );
};
