import type { TeamFilterConstructor, TeamFilterMap, TeamFilterSource } from 'types/teams';

import { useState } from 'react';
import { Filter } from './Filter';
import { noop } from 'helpers/utils';

export interface TeamFilterDisplayProps {
  src: TeamFilterSource;
  filters: TeamFilterMap | null;
  teamFilterConstructor: TeamFilterConstructor;
}

export const TeamFilterDisplay = (props: TeamFilterDisplayProps) => {
  const {
    src,
    filters,
    teamFilterConstructor
  } = props;

  const filter = filters && filters[src];
  const [expanded, setExpanded] = useState(false)

  if (!filter || filter.filter.condition.length === 0) {
    return (
      <div className='text-caption-1 mb-[10px]'>
        No team filter
      </div>
    );
  }

  const rules = teamFilterConstructor[src];

  return (
    <div className='mb-[10px]'>
      <button
        className='text-caption-1 text-caladon-blue hover:text-cyanine-blue'
        onClick={() => { setExpanded(!expanded); }}
      >
        {expanded ? 'Hide team filter ' : 'Show team filter '}
        ({filter.filter.condition.length})
      </button>

      {expanded && rules &&
        <div className='border-b relative pb-[10px] mb-[20px]'>
          <Filter
            rules={rules}
            value={filter.filter}
            disabled
            onChange={noop}
          />
          <span className='absolute bottom-0 px-2 font-medium bg-white right-1/2 translate-x-1/2 translate-y-1/2'>
            AND
          </span>
        </div>
      }
    </div>
  );
}
