import type { DataEntry, IdleDataEntry, LoadingDataEntry } from 'types/dataEntries';

import { useMemo } from 'react';
import { isDataEntry } from 'types/dataEntries';

type ResolvedMap<M extends {}> = {
  [K in keyof M]: M[K] extends DataEntry<infer D> ? D :
    M[K] extends (infer D | DataEntry<infer D>) ? D :
    M[K];
};

export const useDerivedDataEntry = <M extends {}, R>(dataEntries: M, callback: (resolvedMap: ResolvedMap<M>) => R): DataEntry<R> => useMemo(() => {
  let loadingEntry: LoadingDataEntry | null = null;
  let idleEntry: IdleDataEntry | null = null;

  const resolved: Partial<ResolvedMap<M>> = {};

  for (const [key, entry] of Object.entries(dataEntries)) {
    if (!isDataEntry(entry)) {
      resolved[key as keyof M] = entry as any;
    } else {
      if (entry.status === 'error') {
        return entry;
      }

      if (entry.status === 'loading') {
        loadingEntry = entry;
      } else if (entry.status === 'idle') {
        idleEntry = entry;
      } else {
        resolved[key as keyof M] = entry.data;
      }
    }
  }

  if (loadingEntry) {
    return loadingEntry;
  }

  if (idleEntry) {
    return idleEntry;
  }

  return {
    status: 'success',
    data: callback(resolved as ResolvedMap<M>)
  };
}, Object.values(dataEntries));
