import type { HiddenFeature } from 'types/orgs'
import { useGlobalState } from 'state/globalState';
import { useOrgSettingsStore } from 'store/orgs';
import { ReactComponent as LogoLong } from 'assets/images/Logo.svg';
import { ReactComponent as LogoSmall } from 'assets/images/LogoSmall.svg';
import { Item, MenuItem } from './Item';
import { Icon } from 'shared/Icon';
import { UserTeam } from 'shared/UserTeam';
import { SideBar } from 'shared/SideBar';
import { TeamAssumption } from 'shared/TeamAssumption';

const SideMenu = () => {
  const { sideMenuCollapsed, toggleSideMenu } = useGlobalState()

  const { user } = useGlobalState()
  const orgSettings = user && useOrgSettingsStore((store) => store.getEntry(user.org_id));

  const userIsAdmin = !!user && ['admin', 'owner'].includes(user.role);
  const userHasTeam = !!user && !!user.team;
  const dataNotReady = !user?.org_is_data_ready;

  const handleDocumentationClick = () => {
    window.open('https://docs.cloudthread.io/', '_blank')
  }

  let menuItems: MenuItem[] = [
    {
      icon: 'dollar-filled',
      route: '/savings',
      title: 'Cost Savings',
      subItems: [
        { title: 'Savings Dashboard', route: '/savings' },
        { title: 'Savings Threads', route: '/savings/threads', matches: '/savings/threads(/?\\d*/?)?$' },
        { title: 'Opportunities Explorer', route: '/savings/opportunities', matches: '/savings/opportunities(/?\\d*/?)?$' },
        ...(userIsAdmin && !userHasTeam ? [
          { title: 'Team Leaderboard', route: '/savings/teams', disabled: dataNotReady }
        ] : [])
      ]
    },
    {
      icon: 'chart-pie',
      route: '/summary-dashboard',
      title: 'Costs Transparency',
      subItems: [
        { title: 'Summary Dashboard', route: '/summary-dashboard', disabled: dataNotReady },
        { title: 'Cost Lab', route: '/costs-overview', matches: '/costs-overview(/?\\d*/?)?$' },
        { title: 'Cost Library', route: '/costs-overview/library', disabled: dataNotReady },
        { title: 'Dashboard Lab', route: '/dashboards', matches: '/dashboards(/?\\d*/?)?$', disabled: dataNotReady },
        { title: 'Dashboard Library', route: '/dashboards/library', disabled: dataNotReady },
        { title: 'Rule Lab', route: '/rules', matches: '/rules(/?\\d*/?)?$', disabled: dataNotReady },
        { title: 'Rule Library', route: '/rules/library', disabled: dataNotReady },
      ],
    },
    {        
      icon: 'tag-filled',
      route: '/tags-overview',
      title: 'Tagging',
      subItems: [
        { title: 'Tag Overview', route: '/tags-overview', matches: '/tags-overview(/?\\d*/?)?$', disabled: dataNotReady },
        { title: 'Tag Catalog', route: '/tags/catalog' },
      ],
    },
    {
      icon: 'chart-column',
      route: '/unit-metrics/library',
      title: 'Unit Economics',
      subItems: [
        { title: 'Unit Metric Lab', route: '/unit-metrics', matches: '/unit-metrics(/?\\d*/?)?$', disabled: dataNotReady },
        { title: 'Unit Metric Library', route: '/unit-metrics/library', disabled: dataNotReady },
      ],
    },
  ]

  menuItems = menuItems.filter(
    item => (
      !user
      || !(user.role == 'member')
      || !orgSettings
      || !(orgSettings.status == 'success')
      || !(orgSettings.data.hidden_features)
      || !orgSettings.data.hidden_features.includes(item.title as HiddenFeature)
    )
  )

  return (
    <SideBar collapsed={sideMenuCollapsed}>
      <button
        className='absolute right-[-28px] top-[18px] w-[24px] h-[24px] flex items-center justify-center bg-white shadow-light-shadow cursor-pointer rounded-md'
        onClick={toggleSideMenu}
        type='button'
      >
        <Icon className={`${sideMenuCollapsed && 'rotate-180'} transition-all`} icon='arrow-left' />
      </button>

      <div className='mb-[10px]'>
        {sideMenuCollapsed ?
          <LogoSmall /> :
          <LogoLong />
        }
      </div>

      {!sideMenuCollapsed && user && (
        userIsAdmin ? <TeamAssumption /> : <UserTeam />
      )}

      <div className={`grow flex flex-col mt-[10px] ${sideMenuCollapsed ? '' : 'overflow-x-auto no-scrollbar'}`}>
        <div className='flex flex-col gap-[12px] mb-[12px]'>
          {menuItems.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Item key={index} item={item} />
          ))}
        </div>

        <div className='flex flex-col mt-auto gap-[4px] mb-[10px]'>
          <Item item={{ icon: 'document-filled', route: '', title: 'Documentation' }} onClick={handleDocumentationClick} />
          <Item item={{ icon: 'alert-filled', route: '/notification-center', title: 'Notification Center' }} />
          <Item item={{ icon: 'settings', route: '/settings', title: 'Settings' }} />
        </div>
      </div>
    </SideBar>
  );
}

export default SideMenu
