import type { Moment } from 'moment';
import type { Granularity } from 'types/dateFilter';
import type { DataEntry } from 'types/dataEntries';
import type { TagViewChart } from 'types/tagViews';
import type { EventsData } from 'types/costViews/events';
import type { ChartType } from 'types/chart';
import type { CostViewFilter, CostViewFilterWithTeam } from 'types/costViews';

import { Legend } from 'shared/Legend';
import { Loading } from 'shared/Loading';
import { PeriodChart } from 'shared/PeriodChart';
import { PeriodBarChart } from 'shared/PeriodBarChart';
import { SectionFooter } from 'shared/Section';
import { ExportToCsvButton } from 'shared/ExportToCsvButton';
import { formatRange } from 'helpers/formatter';

export interface TotalChartProps {
  chartDataEntry: DataEntry<TagViewChart>;
  chartType: ChartType;
  events: EventsData[];
  setEventsToDisplay: (targetEvents: EventsData[]) => void;
  format: (value: number) => string;
  granularity: Granularity;
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
  requestFilter: CostViewFilter | CostViewFilterWithTeam;
}

export const TotalChart = (props: TotalChartProps) => {
  const {
    chartDataEntry,
    chartType,
    events,
    setEventsToDisplay,
    format,
    granularity,
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment,
  } = props;

  const data = chartDataEntry.status === 'success' ? chartDataEntry.data : [];

  const formatter = ({ value }: { value: number }) => format(value);

  return (
    <div className='relative'>
      {chartType === 'bar' ? (
        <PeriodBarChart
          events={events}
          setEventsToDisplay={setEventsToDisplay}
          data={data}
          currentKey='current_timeframe_tagged_percent'
          previousKey='previous_timeframe_tagged_percent'
          granularity={granularity}
          formatter={formatter}
        />
      ) : (
        <PeriodChart
          events={events}
          setEventsToDisplay={setEventsToDisplay}
          data={data}
          currentKey='current_timeframe_tagged_percent'
          previousKey='previous_timeframe_tagged_percent'
          granularity={granularity}
          formatter={formatter}
        />
      )}
      <SectionFooter>
        <Legend series={[
          {
            name: 'Current period',
            color: '#00b4d8',
            subtitle: formatRange(startMoment, endMoment, granularity)
          },
          {
            name: 'Previous period',
            color: '#DA5665',
            subtitle: formatRange(prevStartMoment, prevEndMoment, granularity)
          },
        ]} />
        <ExportToCsvButton fileName='total_chart.csv' data={data} />
      </SectionFooter>
      {chartDataEntry.status === 'idle' && (
        <div className='absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center rounded-inherit bg-white/80'>
          <div className='font-medium text-[24px] text-gray-300'>
            Please fill required fields
          </div>
        </div>
      )}
      {chartDataEntry.status === 'loading' && <Loading />}
    </div>
  )
};
