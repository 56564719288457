import type { DataEntry } from 'types/dataEntries';
import type { OpportunitiesParams, Opportunity } from 'types/savings';

import { useMemo, useState } from 'react';
import { message, Select, Tooltip } from 'antd';

import { Link } from 'react-router-dom';
import { Icon } from 'shared/Icon';
import { Button2 as Button } from 'shared/Button';
import { Dialog, DialogBody, DialogFooter } from 'shared/Dialog';

import { pluralize } from 'helpers/formatter';
import { renderOpportunitySlug, renderThreadSecondaryId } from 'helpers/savings';

import { useTeamsStore } from 'store/teams';
import { useSavingsStore, useThreadTeamDialog } from 'store/savings';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { FormRow, Label } from 'shared/Form';

export const ThreadTeamDialog = () => {
  const { close, id: threadId } = useThreadTeamDialog();
  const [newTeamId, setNewTeamId] = useState<number | null>(null)

  const threadIdEntry: DataEntry<number> = useMemo(
    () => threadId === null ?
      { status: 'idle' } :
      { 
        status: 'success',
        data: threadId
      },
    [threadId]
  );

  const newTeamIdEntry: DataEntry<number> = useMemo(
    () => newTeamId === null ?
      { status: 'idle' } :
      {
        status: 'success',
        data: newTeamId
      },
    [newTeamId]
  );

  const thread = useSavingsStore((store) => store.getThread(threadIdEntry));
  const flushOpportunities = useSavingsStore((store) => store.flushOpportunities);
  const newTeam = useTeamsStore((store) => store.getEntry(newTeamIdEntry));

  const oldOpportunitiesParams: DataEntry<OpportunitiesParams> = useDerivedDataEntry({
    threadIdEntry
  }, ({
    threadIdEntry
  }) => {
    const params: OpportunitiesParams = {
      pageFilters: {},
      prefilter: {
        thread_id: threadIdEntry
      },
      pagination: {
        ids: [],
        perPage: 1000
      },
      sort: { field: 'costImpact', dir: 'ascend' },
      tableFilters: {}
    }

    return params;
  });

  const newOpportunitiesParams: DataEntry<OpportunitiesParams> = useDerivedDataEntry({
    threadIdEntry,
    newTeamIdEntry,
  }, ({
    threadIdEntry,
    newTeamIdEntry,
  }) => {
    const params: OpportunitiesParams = {
      pageFilters: {
        teamId: newTeamIdEntry
      },
      prefilter: {
        thread_id: threadIdEntry
      },
      pagination: {
        ids: [],
        perPage: 1000
      },
      sort: { field: 'costImpact', dir: 'ascend' },
      tableFilters: {}
    }

    return params;
  });

  const oldThreadOpportunities = useSavingsStore((store) => store.getOpportunities(oldOpportunitiesParams));
  const newThreadOpportunities = useSavingsStore((store) => store.getOpportunities(newOpportunitiesParams));

  const teams = useTeamsStore((store) => store.getLibrary());
  const assignThreadTeam = useSavingsStore((store) => store.assignThreadTeam);

  const assignmentResult = useDerivedDataEntry({
    oldThreadOpportunities,
    newThreadOpportunities
  }, ({
    oldThreadOpportunities,
    newThreadOpportunities
  }) => {
    const newOpportunitiesSet = new Set(newThreadOpportunities.map((opp) => opp.id));

    const assigned: Opportunity[] = [];
    const unassigned: Opportunity[] = [];

    oldThreadOpportunities.forEach((opp) => {
      (newOpportunitiesSet.has(opp.id) ? assigned : unassigned).push(opp);
    });

    return {
      assigned,
      unassigned
    };
  });

  return <Dialog
    className='w-dialog-s'
    open={threadId !== null}
    onClose={close}
  >
    <Dialog.Title>
      Assign {thread.status === 'success' ? renderThreadSecondaryId(thread.data) : 'thread'} to a new team
    </Dialog.Title>

    <DialogBody>
      <FormRow size='m'>
        <Label size='m'>
          Select a team
        </Label>

        <Select
          size='large'
          className='w-full'
          value={newTeamId}
          disabled={teams.status !== 'success'}
          options={teams.status !== 'success' ? [] : teams.data.map((team) => ({
            value: team.id,
            label: team.name
          }))}
          onChange={setNewTeamId}
        />
      </FormRow>

      {assignmentResult.status === 'success' && 
        <div className='mb-5 text-body'>
          {assignmentResult.data.unassigned.length > 0 ? <div className='flex p-2 border bg-yellow/10 border-yellow rounded-1.5'>
            <Icon
              icon='close-circle'
              width={14}
              height={14}
              className='m-0.5 mr-2 text-yellow'
            />
            {assignmentResult.data.assigned.length > 0 ? (
              <span>
                Warning!
                {' '}
                <Tooltip
                  className='font-medium'
                  title={<ul>
                    {assignmentResult.data.unassigned.map((opp) => (
                      <li>
                        <Link
                          to={`/savings/opportunities/${opp.id}`}
                        >
                          {renderOpportunitySlug(opp)}
                        </Link>
                      </li>
                    ))}
                  </ul>}
                >
                  {assignmentResult.data.unassigned.length}
                  {' '}
                  {pluralize(assignmentResult.data.unassigned.length, { one: 'opportunity', other: 'opportunities' })}
                </Tooltip>

                {' '}
                {pluralize(assignmentResult.data.unassigned.length, { one: 'doesn\'t ', other: 'don\'t ' })}
                {' '}
                match the selected team's filter and will be unassigned from a thread
              </span>
            ) : (
              'Warning! None of the thread\'s opportunities can be transferred to a new team'
            )}
          </div> : <div className='flex p-2 border bg-green/10 border-green rounded-1.5'>
            <Icon
              icon='check-filled'
              width={14}
              height={14}
              className='m-0.5 mr-2 text-green'
            />

            {assignmentResult.data.assigned.length > 0 ? (
              'All of the thread\'s opportunities can be transferred to a new team'
            ) : (
              'The thread is empty and can be freely assigned to any team'
            )}
          </div>}
        </div>
      }

    </DialogBody>

    <DialogFooter>
      <Button
        size='m'
        theme='link'
        onClick={close}
      >
        Cancel
      </Button>

      <Button
        size='m'
        theme='filled'
        disabled={
          thread.status !== 'success' ||
          newTeam.status !== 'success'
        }
        onClick={() => {
          if (thread.status === 'success' && newTeam.status === 'success') {
            assignThreadTeam(thread.data.id, newTeam.data.id)
              .then(() => {
                message.success(<>
                <b>{renderThreadSecondaryId(thread.data)}</b> assigned to team <b>{newTeam.data.name}</b>
                </>);
                flushOpportunities();
                close();
              })
              .catch(() => {
                message.error(<>
                  Unable to assign <b>{renderThreadSecondaryId(thread.data)}</b> to team <b>{newTeam.data.name}</b>
                </>);
                close();
              });
          }
        }}
      >
        Assign
      </Button>
    </DialogFooter>
  </Dialog>
}
