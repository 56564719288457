import type { RuleNodeTag } from 'types/rules';
import { RuleHeader } from 'shared/Filters';
import { TagSelectorRow } from './TagSelectorRow';

export interface TagSelectorProps {
  value: RuleNodeTag[];
  tags: { [key: string]: string[] };
  branchTagKeys: string[];
  onChange: (value: RuleNodeTag[]) => void;
  onRemove: () => void;
}

export const TagSelector = (props: TagSelectorProps) => {
  const {
    value,
    tags,
    branchTagKeys,
    onChange,
    onRemove
  } = props;

  return <div>
    <RuleHeader
      icon='tags-filled'
      title='Rule tags'
      onAdd={() => {
        onChange([...value, [null, null]]);
      }}
      onRemove={onRemove}
    />
    {value.map((tag, index) => (
      <TagSelectorRow
        value={tag}
        tags={tags}
        branchTagKeys={branchTagKeys}
        onChange={(newTag) => {
          const newValue = [...value];

          newValue[index] = newTag;

          onChange(newValue);
        }}
        onRemove={() => {
          const newValue = [...value];

          newValue.splice(index, 1);

          onChange(newValue);
        }}
      />
    ))}
  </div>
}
