import { PortalRoot, PortalRootProps } from 'shared/Portal';

export interface SideBarProps extends PortalRootProps {
  collapsed?: boolean;
}

export const SideBar = (props: SideBarProps) => {
  const {
    collapsed,
    className = '',
    ...rest
  } = props;

  return (
    <PortalRoot
      className={`!sticky z-20 top-0 left-0 shrink-0 flex flex-col h-screen pt-[10px] pl-[10px] ${collapsed ? 'w-[50px]' : 'w-[240px]'} ${className}`}
      {...rest}
    />
  );
};
