import { useBillingStore } from 'store/billing';
import { TrialMessage } from './TrialMessage';
import { PayingMessage } from './PayingMessage';
import { CanceledMessage } from './CanceledMessage';
import { NonPayingMessage } from './NonPayingMessage';
import { CustomMessage } from './CustomMessage';
import { StatusOverlay } from 'shared/Overlay';

export const PaymentTab = () => {
  const overview = useBillingStore((store) => store.getOverview());

  return (
    <div className='min-h-[240px] relative'>
      {overview.status === 'success' && (
        overview.data.subscribe_override ?
          <CustomMessage /> :

        !overview.data.current_subscription ?
          <TrialMessage overview={overview.data} /> :

        overview.data.current_subscription.status !== 'active' ?
          <NonPayingMessage overview={overview.data} /> :

        overview.data.current_subscription.cancel_at ?

          <CanceledMessage overview={overview.data} /> :

          <PayingMessage overview={overview.data} />
      )}
      <StatusOverlay status={overview.status} />
    </div>
  );
};
