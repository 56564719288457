import type { Set as ImmutableSet } from 'immutable';

export type CostType = 'unblended_cost' | 'public_cost';

export const COST_TYPES: CostType[] = ['unblended_cost', 'public_cost'];
export const COST_TYPES_SET = new Set(COST_TYPES);
export const DEFAULT_COST_TYPE: CostType = 'unblended_cost';
export const isCostType = (value: any) : value is CostType => value && (COST_TYPES_SET.has(value) || !isNaN(+value));

export type CostDimension = 'Tax' | 'Credit' | 'Refund' | 'Discount' | 'Fee';

export const COST_DIMENSIONS: CostDimension[] = ['Tax', 'Credit', 'Refund', 'Discount', 'Fee'];
export const COST_DIMENSIONS_SET = new Set(COST_DIMENSIONS);
export const isCostDimension = (value: any) : value is CostDimension => COST_DIMENSIONS_SET.has(value);

export interface CostFilterFetchParams {
  costType: CostType;
  costDimensions: CostDimension[];
  costAmortization: boolean;
}

export interface CostFilterKeyProps {
  costType: CostType;
  costDimensions: ImmutableSet<CostDimension>;
  costAmortization: boolean;
}
