import { Size } from 'shared/size'

export type DesignType = 'primary' | 'secondary' | 'tertiary'
export type DesignStyle = 'caladon' | 'oxford'

export const TYPE_CSS: Record<DesignType, Record<DesignStyle, string>> = {
  primary: {
    oxford: 'bg-oxford-blue text-white hover:text-white',
    caladon: 'bg-caladon-blue text-white hover:text-white',
  },
  secondary: {
    oxford: 'border border-silver-grey-700/40 text-oxford-blue',
    caladon: 'border border-silver-grey-700/40 text-caladon-blue',
  },
  tertiary: {
    oxford: 'text-oxford-blue hover:text-cyanine-blue',
    caladon: 'text-caladon-blue',
  },
}

export const SIZE_ICON_CSS: Record<Size, string> = {
  lg: 'w-[12px] h-[12px]',
  md: 'w-[12px] h-[12px]',
  sm: 'w-[10px] h-[10px]',
  xs: 'w-[8px] h-[8px]',
  xxs: 'w-[8px] h-[8px]',
}

export const SIZE_CSS: Record<Size, string> = {
  lg: 'h-[46px] px-[15px] rounded-[10px]',
  md: 'h-[40px] px-[12px] rounded-[8px]',
  sm: 'h-[30px] px-[8px] rounded-[6px]',
  xs: 'h-[24px] px-[5px] rounded-[6px]',
  xxs: '',
}

export const SIZE_CSS_MIN_HEIGHT: Record<Size, string> = {
  lg: 'min-h-[46px] px-[15px] rounded-[10px]',
  md: 'min-h-[40px] px-[12px] rounded-[8px]',
  sm: 'min-h-[30px] px-[8px] rounded-[6px]',
  xs: 'min-h-[24px] px-[5px] rounded-[6px]',
  xxs: '',
}

export const TEXT_CSS: Record<Size, string> = {
  lg: 'text-[15px] font-[500]',
  md: 'text-[15px] font-[500]',
  sm: 'text-[13px] font-[500]',
  xs: 'text-[11px] font-[500]',
  xxs: '',
}

export const OFFSET_ICON_CSS: Record<Size, string> = {
  lg: 'mx-[5px]',
  md: 'mx-[4px]',
  sm: 'mx-[4px]',
  xs: 'mx-[3px]',
  xxs: '',
}
