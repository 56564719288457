import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useGlobalState } from 'state/globalState'

export const InitRoute = () => {
  const { initialized, init } = useGlobalState();

  useEffect(() => {
    init()
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return !initialized ? <></> : <Outlet />;
};
