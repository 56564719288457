import create from 'zustand'

import type { FetchStatus } from 'types/common';
import { getSlackIntegration, getSlackChannels } from 'services/user'


interface FetchParams {
  force?: boolean;
}

export interface SlackChannelsyState {
  status: FetchStatus;
  slackIntegraitonId: number;
  slackChannels: string[];
}

export interface SlackChannelsyActions {
  fetchSlackChannels: (params?: FetchParams) => void;
}

export interface SlackChannelsyStore extends SlackChannelsyState, SlackChannelsyActions {}

const DEFAULT_STATE: SlackChannelsyState = {
  status: 'idle',
  slackIntegraitonId: -1,
  slackChannels: [],
};

export const useSlackChannelsStore = create<SlackChannelsyStore>((set, get) => {
  let requestCount = 0;

  return {
    ...DEFAULT_STATE,

    fetchSlackChannels: async (params = {}) => {
      const { force } = params;
      const { status } = get();

      /*
      if (!force && ['loading', 'success'].includes(status)) {
        return;
      }
      */

      try {
        if (status !== 'success') {
          set({ status: 'loading' });
        }

        const requestNumber = ++requestCount;

        const { data: integrations }  = await getSlackIntegration()
        const slackIntegraitonId: number = integrations.length ? integrations[0].id : -1
        if (slackIntegraitonId !== -1) {
          const { data: slackChannels} = await getSlackChannels()
          set({ 
            slackIntegraitonId,
            slackChannels,
            status: 'success'
          })
        }
      } catch(error) {
        set({ status: 'error' });
      }
    },
  };
});
