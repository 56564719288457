import type { ReactNode } from 'react';
import type { RuleNode, RuleValueCondition } from 'types/rules';
import type { ConstructorRules, FilterValueType } from 'types/filters';

import {
  isRuleParentNode
} from 'types/rules';

import {
  addChild,
  updateChild,
  removeChild,
  splitRule,
  unsplitRule,
  getSiblingValues,
  getChildTagKeys
} from 'helpers/rules';
import { createValueCondition } from 'helpers/filters';

import { Branch, TextToggle } from 'shared/Filters';
import { Button } from 'shared/Button';
import { Section } from 'shared/Section';
import { FieldSelector } from './FieldSelector';
import { ValueSelector } from './ValueSelector';
import { TagSelector } from './TagSelector';
import { NodeSpend } from './NodeSpend';

export interface RuleTreeEditorProps {
  value: RuleNode;
  onChange: (value: RuleNode) => void;
  onRemove?: () => void;
  disabled?: boolean;
  parentField?: string | null;
  parentConditions?: RuleValueCondition[];
  siblingValues?: string[] | Record<string, string>[];
  tags: { [key: string]: string[] };
  parentTagKeys?: string[];
  src: string;
  constructorRules: ConstructorRules;
}

export const RuleTreeEditor = (props: RuleTreeEditorProps) => {
  const {
    src,
    value,
    onChange,
    onRemove,
    parentField,
    parentConditions = [],
    tags,
    parentTagKeys = [],
    siblingValues,
    constructorRules
  } = props;

  const nextParentConditions: RuleValueCondition[] = value.condition ? [
    ...parentConditions,
    value.condition
  ] : (value.rest && parentField && siblingValues) ? [
    ...parentConditions,
    {
      field_name: parentField,
      value_type: constructorRules[parentField].valueType,
      comparator: 'not equals',
      values: siblingValues
    } as RuleValueCondition
  ] : parentConditions;

  const baseValueType: 'string' | 'dict' | null = value.field && constructorRules[value.field].valueType || null;
  const regexValueType: 'string-regex' | 'dict-regex' | null = baseValueType && `${baseValueType}-regex`;
  const valueType: FilterValueType | null = value.children && value.children[0]?.condition?.value_type || null;

  const childTagKeys = getChildTagKeys(value);

  return (
    <div className='flex gap-[20px] items-start'>
      {parentField && constructorRules[parentField] &&
        <Section className='w-[240px] border border-gray-300 rounded-[10px!important] hover:!drop-shadow-lg relative !overflow-visible'>
          <div className='px-[10px]'>
            {(value.condition ? (
              <ValueSelector
                value={value.condition}
                field={parentField}
                siblingValues={siblingValues}
                constructorRules={constructorRules}
                parentConditions={parentConditions}
                onChange={(condition) => {
                  onChange({ ...value, condition });
                }}
                onRemove={onRemove}
              />
            ) : (
              <div className='font-medium my-[15px]'>
                Other {parentField}
              </div>
            ))}
          </div>

          <NodeSpend
            src={src}
            condition={nextParentConditions}
          />

          <div className='border-t mt-[15px] bg-gray-100 rounded-b-[9px]'>
            {value.tags ? (
              <div className='px-[10px] pb-[15px]'>
                <TagSelector
                  value={value.tags}
                  tags={tags}
                  branchTagKeys={[...parentTagKeys, ...childTagKeys]}
                  onChange={(tags) => {
                    onChange({ ...value, tags });
                  }}
                  onRemove={() => {
                    const newValue = { ...value };

                    delete newValue.tags;

                    onChange(newValue);
                  }}
                />
              </div>
            ) : (
              <Button
                className='m-[5px] inline-flex'
                size='sm'
                icon='tag'
                style='caladon'
                type='tertiary'
                label='Add rule tags'
                onClick={() => {
                  onChange({ ...value, tags: [['', '']] });
                }}
              />
            )}
          </div>

        </Section>
      }

      <div className='mb-[20px] relative'>
        {parentField && (
          <div className='absolute w-[20px] top-[20px] right-[100%] h-[1px] bg-gray-300' />
        )}
        {true && (
          <>
            {value.field === undefined ? (
              <Button
                type='secondary'
                label='Split'
                onClick={() => {
                  onChange(splitRule(value));
                }}
              />
            ) : (
              <Section className='w-[256px] px-[10px] py-[5px] flex border border-gray-300 rounded-[10px!important] items-center'>
                <FieldSelector
                  value={value.field || null}
                  parentConditions={nextParentConditions}
                  constructorRules={constructorRules}
                  onChange={(newField) => {
                    onChange(splitRule(value, newField, constructorRules[newField as string].valueType));
                  }}
                />

                {baseValueType && regexValueType && valueType && (
                  <TextToggle
                    value={valueType}
                    onChange={(newValueType) => {
                      onChange(splitRule(value, value.field, newValueType));
                    }}
                  >
                    {{
                      [baseValueType]: 'in',
                      [regexValueType]: 'like'
                    } as Record<FilterValueType, ReactNode>}
                  </TextToggle>
                )}

                <Button
                  className='ml-auto'
                  size='sm'
                  icon='trash'
                  type='tertiary'
                  onClick={() => {
                    onChange(unsplitRule(value));
                  }}
                />
              </Section>
            )}
          </>
        )}

        {isRuleParentNode(value) && (
          <>
            {valueType && !valueType.endsWith('-regex') && (
              <Branch theme='dark'>
                <div className='pt-[10px]'>
                  <Button
                    size='sm'
                    type='secondary'
                    style='caladon'
                    label='Add child'
                    icon='circle-plus'
                    onClick={() => {
                      const newChild: RuleNode = {
                        condition: createValueCondition(constructorRules[value.field]) as RuleValueCondition
                      };

                      onChange(addChild(value, 0, newChild));
                    }}
                  />
                </div>
              </Branch>
            )}

            {value.children.map((child, index, children) => (
              <Branch
                theme='dark'
                key={index}
                last={index === children.length - 1}
              >
                <div className='pt-[10px]'>
                  <RuleTreeEditor
                    src={src}
                    value={child}
                    parentField={value.field}
                    siblingValues={getSiblingValues(children, index)}
                    parentConditions={nextParentConditions}
                    constructorRules={constructorRules}
                    tags={tags}
                    parentTagKeys={value.tags ? [
                      ...parentTagKeys,
                      ...value.tags.map(([key]) => key).filter(Boolean) as string[]
                    ] : parentTagKeys}
                    onChange={(newChild) => {
                      onChange(updateChild(value, index, newChild));
                    }}
                    onRemove={children.length > 2 ? () => {
                      onChange(removeChild(value, index));
                    } : undefined}
                  />
                </div>
              </Branch>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
