import create from 'zustand'

import type { FetchStatus } from 'types/common';
import type { UnitMetricsLibraryEntry } from 'types/unitMetrics';

import { fetchUnitMetricsLibrary } from 'services/unitMetrics';
import moment from 'moment';
import { SERVER_DATE_FORMAT } from 'helpers/constants'

interface FetchParams {
  force?: boolean;
}

export interface UnitMetricsLibraryState {
  status: FetchStatus;
  unitMetrics: UnitMetricsLibraryEntry[];
  startDate: string;
  endDate: string;
}

export interface UnitMetricsLibraryActions {
  fetchUnitMetricsLibrary: (params?: FetchParams) => void;
}

export interface UnitMetricsLibraryStore extends UnitMetricsLibraryState, UnitMetricsLibraryActions {}

const DEFAULT_STATE: UnitMetricsLibraryState = {
  status: 'idle',
  unitMetrics: [],
  startDate: moment().subtract(8, 'days').format(SERVER_DATE_FORMAT),
  endDate: moment().subtract(1, 'days').format(SERVER_DATE_FORMAT),
};

export const useUnitMetricsLibraryStore = create<UnitMetricsLibraryStore>((set, get) => {
  let requestCount = 0;

  return {
    ...DEFAULT_STATE,

    fetchUnitMetricsLibrary: async (params = {}) => {
      const { force } = params;
      const { status, startDate, endDate } = get();

      if (!force && ['loading', 'success'].includes(status)) {
        return;
      }

      try {
        if (status !== 'success') {
          set({ status: 'loading' });
        }

        const requestNumber = ++requestCount;
        
        const { data: unitMetrics } = await fetchUnitMetricsLibrary(
          startDate,
          endDate,
        );

        if (requestNumber === requestCount) {
          set({
            unitMetrics,
            status: 'success'
          });
        }
      } catch(error) {
        set({ status: 'error' });
      }
    }
  };
});
