/**
 * TODO: think of untangling thresholds from configs
 */
import create from 'zustand'

import type { DataEntry, Action } from 'types/dataEntries';
import type { 
  DataConfigSource,
  DataConfig, 
  DetailedDataConfig,
  DataConfigUpdatePayload,
  DataConfigCreatePayload,
  GCPServiceAccount,
  DataConfigsParamUpdatePayload,
  ThresholdsMap,
} from 'types/dataCollection';

import { isSavingsDataConfig } from 'types/dataCollection';

import { Map as ImmutableMap } from 'immutable';
import { mapThresholds } from 'helpers/dataCollection'
import {
  fetchDataConfigs,
  fetchDataConfig,
  saveDataConfig,
  updateDataConfig,
  deleteDataConfig,
  viewDataConfig,
  getGCPServiceAccount,
  validateDataConfig,
  updateConfigsParams,
  getConfigsParams,
} from 'services/dataCollection'

type DataConfigAction = Action<'update'> | Action<'view'>;

interface DataState {
  library: DataEntry<DataConfig[]>;
  gcpServiceAccount: DataEntry<GCPServiceAccount>;
  entries: ImmutableMap<number, DataEntry<DataConfig>>;
  detailedEntries: ImmutableMap<number, DataEntry<DetailedDataConfig>>;
  drafts: ImmutableMap<number, DataConfigUpdatePayload>;
  actions: ImmutableMap<number, DataConfigAction>;
  thresholdsMap: DataEntry<ThresholdsMap>;
}

interface DataActions {
  getLibrary: () => DataEntry<DataConfig[]>;
  getEntry: (id: number) => DataEntry<DataConfig>;
  getDetailedEntry: (id: number) => DataEntry<DetailedDataConfig>;
  setEntry: (config: DataConfig) => void;
  setDetailedEntry: (config: DetailedDataConfig) => void;
  deleteEntry: (id: number) => Promise<void>;
  updateEntry: (id: number, payload?: DataConfigUpdatePayload) => Promise<DataConfig>;
  createEntry: (source: DataConfigSource, payload: DataConfigCreatePayload) => Promise<DataConfig>;
  viewEntry: (id: number) => Promise<DetailedDataConfig>;
  getGCPServiceAccount: () => DataEntry<GCPServiceAccount>;
  getThresholdsMap: () => DataEntry<ThresholdsMap>;
  validateEntry: (source: DataConfigSource, payload: DataConfigCreatePayload) => Promise<boolean>;
  getDraft: (id: number) => DataConfigUpdatePayload;
  discardDraft: (id: number) => void;
  discardAllDrafts: () => void;
  setDraft: (id: number, draft: DataConfigUpdatePayload) => void;
  updateParams: (payload: DataConfigsParamUpdatePayload) => Promise<void>;
}

interface DataConfigStore extends DataState, DataActions {}

const DEFAULT_STATE: DataState = {
  entries: ImmutableMap(),
  detailedEntries: ImmutableMap(),
  drafts: ImmutableMap(),
  actions: ImmutableMap(),
  library: { status: 'idle' },
  gcpServiceAccount: { status: 'idle' },
  thresholdsMap: { status: 'idle' },
};

export const useDataConfigWithThresholdsStore = create<DataConfigStore>((set, get) => ({
  ...DEFAULT_STATE,

  getLibrary: () => {
    let {
      library
    } = get();
    
    if (library.status === 'idle') {
      library = { status: 'loading' };

      set({ library });

      fetchDataConfigs()
        .then(({ data }) => {
          set({
            library: {
              status: 'success',
              data
            }
          });
        })
        .catch((error) => {
          set({
            library: {
              status: 'error',
              error
            }
          });
        });
    }

    return library;
  },

  getEntry: (id: number) => {
    const {
      entries,
      getLibrary
    } = get();

    const library = getLibrary();

    if (library.status !== 'success') {
      return library;
    }

    let entry = entries.get(id);

    if (!entry) {
      const data = library.data.find((item) => item.id === id);

      if (data) {
        entry = {
          status: 'success',
          data
        };
      } else {
        entry = {
          status: 'error',
          error: null
        };
      }

      set({
        entries: entries.set(id, entry)
      });
    }

    return entry;
  },

  getDetailedEntry: (id: number) => {
    const {
      detailedEntries,
    } = get();

    let entry = detailedEntries.get(id);

    if (!entry) {
      entry = { status: 'loading' };

      set({
        detailedEntries: detailedEntries.set(id, entry)
      });

      fetchDataConfig(id)
        .then(({ data }) => {
          entry = {
            status: 'success',
            data
          }
          set({
            detailedEntries: detailedEntries.set(id, entry)
          });
        })
        .catch((error) => {
          entry = {
            status: 'error',
            error
          }
          set({
            detailedEntries: detailedEntries.set(id, entry)
          });
        })
    }

    return entry;
  },

  deleteEntry: (id: number) => {
    return deleteDataConfig(id)
      .then(() => {
        const { library } = get();

        if (library.status !== 'success') {
          return;
        }

        set({
          library: {
            ...library,
            data: library.data.filter((item) => item.id !== id)
          }
        });
      });
  },

  createEntry: (source: DataConfigSource, payload: DataConfigCreatePayload) => {
    return saveDataConfig(source, payload)
      .then(({ data: newDataConfig }) => {
        const { setEntry } = get();

        setEntry(newDataConfig);

        return newDataConfig;
      });
  },

  setEntry: (config: DataConfig) => {
    let { library } = get();

    set({
      entries: get().entries.set(config.id, { status: 'success', data: config }),
      drafts: get().drafts.remove(config.id)
    });

    if (library.status !== 'success') {
      return;
    }

    let index = library.data.findIndex((item) => item.id === config.id);

    if (index === -1) {
      index = library.data.length;
    }

    library = { ...library, data: [...library.data] };
    library.data[index] = config;

    set({
      library
    });
  },

  setDetailedEntry: (config: DetailedDataConfig) => {
    set({
      detailedEntries: get().detailedEntries.set(config.id, { status: 'success', data: config }),
    });
  },

  updateEntry: (id: number, payload?: DataConfigUpdatePayload) => {
    if (!payload) {
      payload = get().getDraft(id)
    }

    // Remove threshold fields
    payload = {
      ...payload,
      thresholds: undefined,
    }

    const { actions } = get();

    set({
      actions: actions.set(id, { type: 'update', status: 'in-progress' })
    });

    return updateDataConfig(id, payload)
      .then(({ data: newDataConfig }) => {
        const { setEntry } = get();

        setEntry(newDataConfig);

        return newDataConfig;
      })
      .finally(() => {
        const { actions } = get();

        set({
          actions: actions.remove(id)
        });
      });
  },

  viewEntry: (id: number) => {
    const { actions } = get();

    set({
      actions: actions.set(id, { type: 'view', status: 'in-progress' })
    });

    return viewDataConfig(id)
      .then(({ data }) => {
        const { setDetailedEntry } = get();

        setDetailedEntry(data);

        return data;
      })
      .finally(() => {
        const { actions } = get();

        set({
          actions: actions.remove(id)
        });
      });
  },

  getDraft: (id: number) => {
    const {
      drafts,
      getThresholdsMap,
      getEntry 
    } = get();

    let draft = drafts.get(id);

    if (!draft) {
      const entry = getEntry(id);
      
      if (entry.status !== 'success') {
        throw new Error();
      }

      draft = {
        ...entry.data
      };

      
      delete (draft as Partial<DataConfig>).collection_details;

      if (isSavingsDataConfig(entry.data)) {
        const thresholdsMap = getThresholdsMap();

        if (thresholdsMap.status !== 'success') {
          throw new Error();
        }

        draft.thresholds = thresholdsMap.data[id]
      }

      set({
        drafts: drafts.set(id, draft)
      });
    }

    return draft;
  },

  getGCPServiceAccount: () => {
    let {
      gcpServiceAccount
    } = get();

    if (gcpServiceAccount.status === 'idle') {
      gcpServiceAccount = { status: 'loading' };

      set({ gcpServiceAccount });

      getGCPServiceAccount()
        .then(({ data }) => {
          set({
            gcpServiceAccount: {
              status: 'success',
              data
            }
          });
        })
        .catch((error) => {
          set({
            gcpServiceAccount: {
              status: 'error',
              error
            }
          });
        });
    }

    return gcpServiceAccount;
  },

  getThresholdsMap: () => {
    let {
      thresholdsMap
    } = get();

    if (thresholdsMap.status === 'idle') {
      thresholdsMap = { status: 'loading' };

      set({ thresholdsMap });

      getConfigsParams()
        .then(({ data }) => {
          set({
            thresholdsMap: {
              status: 'success',
              data: mapThresholds(data)
            },
          });
        })
        .catch((error) => {
          set({
            thresholdsMap: {
              status: 'error',
              error
            }
          });
        });
    }

    return thresholdsMap;
  },
  
  validateEntry: (source: DataConfigSource, payload: DataConfigCreatePayload) => {
    return validateDataConfig(source, payload)
      .then(() => true)
      .catch(() => false)
  },

  setDraft: (id: number, draft: DataConfigUpdatePayload) => {
    const {
      drafts
    } = get();

    set({
      drafts: drafts.set(id, draft)
    });
  },

  discardDraft: (id: number) => {
    const {
      drafts
    } = get();

    set({
      drafts: drafts.remove(id)
    });
  },

  discardAllDrafts: () => {
    set({
      drafts: ImmutableMap()
    });
  },

  updateParams: (payload: DataConfigsParamUpdatePayload) => {
    return updateConfigsParams(payload)
      .then(({ data }) => {
        set({
          thresholdsMap: {
            status: 'success',
            data: mapThresholds(data)
          },
        });
      })
  },
}));
