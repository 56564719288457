import type {
  TagViewChartKey,
  TagViewChartKeyProps,
  TagViewChartKeyFactory,
  TagViewChartFetchParams
} from 'types/tagViews';

import {
  Set as ImmutableSet,
  Record as ImmutableRecord
} from 'immutable';

import {
  defaultDateFilterKeyProps,
  defaultTagListFilterKeyProps,
} from './defaults';

import { toImmutableFilter } from 'helpers/filters';

const createTagViewChartKey: TagViewChartKeyFactory = ImmutableRecord<TagViewChartKeyProps>({
  ...defaultDateFilterKeyProps,
  ...defaultTagListFilterKeyProps,
  filter: null
});

export const tagViewChartFetchParamsToKey = (params: TagViewChartFetchParams): TagViewChartKey => {
  const tagList = ImmutableSet(params.tagList);
  const filter = toImmutableFilter(params.filter);

  return createTagViewChartKey({
    ...params,
    tagList,
    filter
  });
};
