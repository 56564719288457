import type { IdleDataEntry } from 'types/dataEntries';

import { useEffect, useState } from 'react';

import { message, Select } from 'antd';
import { Dialog, DialogBody } from 'shared/Dialog';
import { Button2 as Button } from 'shared/Button';
import { FormRow, Label } from 'shared/Form';

import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { renderThreadSecondaryId } from 'helpers/savings';

import { useGlobalState } from 'state/globalState';
import { useUsersStore } from 'store/users';
import { useSavingsStore, useThreadAssigneeDialog } from 'store/savings';
import {findPortalExit} from 'shared/Portal';

const idle: IdleDataEntry = { status: 'idle' };

export const ThreadAssigneeDialog = () => {
  const { id, close } = useThreadAssigneeDialog();

  const user = useGlobalState((state) => state.user);
  const allUsers = useUsersStore((store) => store.getLibrary());
  const thread = useSavingsStore((store) => id === null ? idle : store.getThread(id));
  const assignThreadUser = useSavingsStore((store) => store.assignThreadUser);

  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    if (thread.status === 'success') {
      setValue(thread.data.assignee?.id || null);
    }
  }, [thread]);

  const userOptions = useDerivedDataEntry({
    user,
    allUsers,
    thread
  },({
    user,
    allUsers,
    thread
  }) => {
    const eligibleTeamIds = new Set<number | null>([
      user?.team?.id || null,
      thread.team_id
    ]);

    const eligibleUsers = allUsers.filter((user) => eligibleTeamIds.has(user.team?.id || null));

    const options: { value: number | null, label: string }[] = eligibleUsers.map((user) => ({
      value: user.id,
      label: `${user.name} (${user.email})`
    }));

    if (!thread.assignee) {
      options.unshift({
        value: null,
        label: '-- None --'
      });
    }

    return options;
  });

  return (
    <Dialog
      className={'w-[480px]'}
      open={id !== null}
      onClose={close}
    >
      <Dialog.Title>
        Assign thread to a user
      </Dialog.Title>

      <DialogBody>
        <FormRow size='m'>
          <Label size='m'>
            User
          </Label>

          <Select
            size='large'
            className='w-full'
            value={value}
            onChange={setValue}
            getPopupContainer={findPortalExit}
            disabled={userOptions.status !== 'success'}
            options={userOptions.status === 'success' ? userOptions.data : []}
          />
        </FormRow>

        <div className='flex justify-end mb-8'>
          <Button
            size='m'
            theme='filled'
            disabled={
              thread.status !== 'success' ||
              value === thread.data.assignee?.id ||
              value === null
            }
            onClick={() => {
              if (thread.status === 'success' && userOptions.status === 'success') {
                const slug = <b>{renderThreadSecondaryId(thread.data)}</b>;
                const option = userOptions.data.find((opt) => opt.value === value);

                assignThreadUser(thread.data.id, value as number)
                  .then(() => {
                    message.success(<>
                      {slug} assigned to <b>{option!.label}</b>
                    </>);

                    close();
                  })
                  .catch(() => {
                    message.error(<>
                      Unable to assign {slug} to <b>{option!.label}</b>
                    </>);
                  });
              }
            }}
          >
            Assign
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  );
}
