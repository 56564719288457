import Dialog from 'shared/Dialog/Dialog';
import Button from 'shared/Button/Button';

export interface DeleteCFNStackProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const DeleteCFNStack = (props: DeleteCFNStackProps) => {
  const { open, onClose, onConfirm } = props;

  return (
    <Dialog className="w-[420px] pt-[40px] px-[30px] pb-[30px]" open={open} onClose={onClose}>
      <div className="font-semibold text-center text-subtitle-1">
        Are you sure you want to delete this stack permanently? Any deployment of this stack in AWS
        WILL NOT work once you delete this stack. If you are removing an integration, first delete
        the deployed stack in AWS, then delete the stack on Cloudthread.
      </div>
      <Button className="justify-center mt-[30px] bg-negative" style="caladon" label="Yes, delete this stack" onClick={onConfirm} />
      <Button className="justify-center mt-[20px]" type="tertiary" label="Cancel" onClick={onClose} />
    </Dialog>
  );
};
