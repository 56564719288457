import type {
  UnitMetric,
  UnitMetricFilter,
  UnitMetricChart,
  UnitMetricChartFetchParams,
  UnitMetricConstructorBackendCustom,
  UnitMetricsLibraryEntry
} from 'types/unitMetrics';

import api from 'helpers/api';

export const fetchUnitMetrics = () => api.get<UnitMetric[]>('/metrics/unit');

type UnitMetricConfig = { name: string, filter: UnitMetricFilter };

export const fetchUnitMetric = (id: number) => api.get<UnitMetric>(`/metrics/unit/${id}`);

export const createUnitMetric = (config: UnitMetricConfig) => api.post<UnitMetric>('/metrics/unit/save', config);

export const updateUnitMetric = (id: number, config: UnitMetricConfig) => api.patch(`/metrics/unit/${id}`, config);

export const deleteUnitMetric = (id: number) => api.delete(`/metrics/unit/${id}`);

export const fetchUnitMetricConstructorCustom = () =>
  api.get<UnitMetricConstructorBackendCustom>('/metrics/unit/custom-data/constructor');

export const fetchUnitMetricChart = ({
  startDate,
  endDate,
  granularity,
  filter,
  costType,
  costDimensions,
  costAmortization,
}: UnitMetricChartFetchParams) => api.post<{ data: UnitMetricChart }>('/metrics/unit', filter, {
  params: {
    start_date: startDate,
    end_date: endDate,
    granularity,
    cost_type: costType,
    exclude: costDimensions.join(','),
    amortization: costAmortization,
  }
}).then(({ data }) => data);

export const fetchUnitMetricsLibrary = (startDate: string, endDate: string) =>
  api.get<UnitMetricsLibraryEntry[]>('/metrics/unit/library', {
    params: {
      start_date: startDate,
      end_date: endDate
    }
  });
