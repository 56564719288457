import type { ReactNode } from 'react';
import type { Opportunity, OpportunityTag, OpportunityTypeId } from 'types/savings';

import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { Dummy } from 'pages/savings/Dummy';
import { ThreadLink } from 'pages/savings/content/ThreadLink';
import { DifficultyBadge } from 'pages/savings/DifficultyBadge';

import {
  pickOpportunityRecordId,
  pickOpportunityDifficulty,
  pickOpportunityGroup,
  pickOpportunityAccount,
  pickOpportunityAccountName,
  pickOpportunityCreatedAt,
  pickOpportunityLastDetectedAt,
  pickOpportunityRegion,
  pickOpportunityService,
  pickOpportunityTags,
  pickOpportunityCostImpact,
  pickOpportunityStatus,
  pickOpportunityThreadId,
  pickOpportunityPlatform,
  pickOpportunityPriority,
  pickOpportunityAccountTags,
} from './pick';

import {
  isPaywalledOpportunity, 
  isTrustedAdvisorOpportunityTypeId
} from 'types/savings';

import { formatCurrency, pluralize } from 'helpers/formatter';


type Renderer = (opp: Opportunity) => React.ReactNode;

export const decorateRenderer = (
  renderer: Renderer,
  {
    height = 14,
    minWidth = 20,
    maxWidth = 80
  } : {
    height?: number,
    minWidth?: number,
    maxWidth?: number
  } = {}
): Renderer =>
  (opp: Opportunity) => isPaywalledOpportunity(opp) ? (
    <Dummy
      height={height}
      minWidth={minWidth}
      maxWidth={maxWidth}
    />
  ) : renderer(opp);

export const renderOpportunitySlug = (opp: Opportunity) => `OPP-${opp.secondary_id}`;

export const renderOpportunityLink = decorateRenderer((opp: Opportunity): ReactNode => 
  <Link
    to={`/savings/opportunities/${opp.id}`}
    className='!text-caladon-blue font-medium'
  >
    {renderOpportunitySlug(opp)}
  </Link>
);

export const renderOpportunityThread = decorateRenderer((opp: Opportunity): ReactNode => {
  const threadId = pickOpportunityThreadId(opp);

  if (threadId === null) {
    return '-';
  }

  return <ThreadLink threadId={threadId} />;
});

export const renderOpportunityRecordId = decorateRenderer((opp: Opportunity): ReactNode => {
  const recordId = pickOpportunityRecordId(opp);

  return (
    recordId ? 
      <div
        className='relative overflow-hidden whitespace-nowrap text-ellipsis hover:overflow-visible'
      >
        <span className='hover:bg-white my-[-4px] py-[3px] mx-[-8px] px-[7px] border border-transparent hover:border-gray-300 rounded-[3px] hover:drop-shadow-md'>
          {recordId}
        </span>
      </div> :
      '-'
  );
});

export const renderOpportunityCostImpact = (opp: Opportunity): ReactNode => {
  const costImpact = pickOpportunityCostImpact(opp);

  return costImpact === null ? 'Calculating' : formatCurrency(costImpact);
};

export const renderOpportunityDifficulty = decorateRenderer((opp: Opportunity): ReactNode => {
  const difficulty = pickOpportunityDifficulty(opp);

  return <DifficultyBadge difficulty={difficulty} />;
});

export const renderOpportunityGroup = decorateRenderer((opp: Opportunity): ReactNode => {
  const group = pickOpportunityGroup(opp);

  if (!group) {
    return '-';
  }

  return {
    custom: 'Manual',
    usage: 'Usage',
    rate: 'Rate'
  }[group];
});

// Opportunity types
const OPPORTUNITY_TYPE_TITLES: Record<OpportunityTypeId, string> = {
  '0001_unattached_ebs_volumes': 'Unattached EBS Volumes',
  '0003_standardstorage_ebs_snapshots': 'Standard Storage EBS Snapshots',
  '0004_unused_elastic_ips': 'Unused Elastic IPs',
  '0005_unused_classic_loadbalancers': 'Unused Classic Load Balancers',
  '0006_rightsizing_ec2': 'Compute Optimizer EC2 Rightsizing',
  '0007_rightsizing_ecs': 'Compute Optimizer ECS Rightsizing',
  '0008_rightsizing_lambda': 'Compute Optimizer Lambda Rightsizing',
  '0009_rightsizing_ebs': 'Compute Optimizer EBS Rightsizing',
  '0010_ce_ri_ec2': 'Cost Explorer EC2 Rerserved Instance',
  '0011_ce_ri_rds': 'Cost Explorer RDS Rerserved Instance',
  '0012_ce_ri_redshift': 'Cost Explorer Redshift Rerserved Instance',
  '0013_ce_ri_elasticache': 'Cost Explorer ElastiCache Rerserved Instance',
  '0014_ce_ri_elasticsearch': 'Cost Explorer ElasticSearch Rerserved Instance',
  '0015_ce_ri_opensearch': 'Cost Explorer OpenSearch Rerserved Instance',
  '0016_rds_rightsizing': 'RDS Righsizing',
  '0017_opensearch_rightsizing': 'OpenSearch Righsizing',
  '0018_redis_rightsizing': 'Redis Righsizing',
  '0019_memcached_rightsizing': 'MemCached Righsizing',
  '0020_dynamodb_rightsizing': 'DynamoDB Righsizing',
  '0021_ce_sp_compute': 'Cost Explorer Savings Plan Compute',
  '0022_ce_sp_ec2': 'Cost Explorer Savings Plan EC2',
  '0023_ce_sp_sagemaker': 'Cost Explorer Savings Plan SageMaker',
  '0024_rightsizing_asg': 'Compute Optimizer ASG Rightsizing',
  '0100_unused_cw_alarms_ec2': 'Unused EC2 CloudWatch Alarms',
  '0101_unused_cw_alarms_rds': 'Unused RDS CloudWatch Alarms',
  '0102_unused_cw_dashboards': 'Excess CloudWatch Dashboards',
  '0103_cw_log_retention': 'Excess CloudWatch Log Retention',
  '0104_stopped_instance_ebs_volumes': 'EBS Volumes Attached to Stopped Instances',
  '0105_unused_machine_images': 'Unused Machine Images',
  '0106_old_machine_images': 'Old Machine Images',
  '0107_old_archivestorage_ebs_snapshots': 'Old Archived Storage EBS Snapshots',
  '0108_efs_lifecycle_policy': 'Missing EFS Intelligent Tiering',
  '0109_s3_intelligent_tiering': 'Missing S3 Intelligent Tiering',
  '0110_idle_nat_gateways': 'Idle NAT Gateways',
  '0111_auroa_instances_to_graviton': 'Graviton-eligible RDS Instances',
  '0112_ddb_infrequent_access': 'DynamoDB Standard / Infrequent Access Switch',
  '0113_opensearch_instances_to_graviton': 'Graviton-eligible OpenSearch Instances',
  '0114_idle_vpc_endpoints': 'Idle VPC Endpoints',
  '0115_duplicate_cloudtrail': 'Duplicate CloudTrail Trails',
  'manual_opportunity': 'Manual Opportunity'
}

export const renderOpportunityTypeIdString = (opportunity: Opportunity) =>
  opportunity.opportunity_details?.opportunity_title || 
  (isTrustedAdvisorOpportunityTypeId(opportunity.opportunity_type_id) ? 
    opportunity.opportunity_details?.record?.check_name :
    OPPORTUNITY_TYPE_TITLES[opportunity.opportunity_type_id]
  ) || opportunity.opportunity_type_id;

export const renderOpportunityTypeId = decorateRenderer((opp: Opportunity) => {
  const text = renderOpportunityTypeIdString(opp);

  return <Tooltip
    title={<>
      <b>{renderOpportunityService(opp)}</b>
      <p>{opp.opportunity_details?.opportunity_description}</p>
    </>}
  >
    {text}
  </Tooltip>
});

export const renderOpportunityStatus = decorateRenderer((opp: Opportunity): ReactNode => {
  const status = pickOpportunityStatus(opp);
  const expired = opp.is_expired;
  
  return <span>
    {{
      unassigned: 'Unassigned',
      assigned: 'Assigned',
      validation: 'Validation',
      implementation: 'Implementation',
      done: 'Done',
      rejected: 'Rejected',
      trash: 'Archived'
    }[status]}
    {expired && ' (expired)'}
  </span>
});

export const renderOpportunityAccount = decorateRenderer((opp: Opportunity): ReactNode => {
  const accountId = pickOpportunityAccount(opp);
  const accountName = pickOpportunityAccountName(opp);

  return (accountName && `${accountName} (${accountId})`) || accountId || '-';
});

export const renderOpportunityService = decorateRenderer((opp: Opportunity): ReactNode => {
  return pickOpportunityService(opp) || '-';
});

export const renderOpportunityRegion = decorateRenderer((opp: Opportunity): ReactNode => {
  return pickOpportunityRegion(opp) || '-';
});

export const createTagsRenderer = (picker: (opp: Opportunity) => OpportunityTag[]) => decorateRenderer((opp: Opportunity): ReactNode => {
  const tags = picker(opp);

  if (tags.length === 0) {
    return '-';
  }

  return (
    <Tooltip
      title={tags.map(({ key, value }) =>
        <div key={key} className='mb-2'>
          <b>{key}:</b> {value}
        </div>
      )}
    >
      {tags.length} {pluralize(tags.length, { one: 'tag', other: 'tags' })}
    </Tooltip>
  );
});

export const renderOpportunityTags = createTagsRenderer(pickOpportunityTags);
export const renderOpportunityAccountTags = createTagsRenderer(pickOpportunityAccountTags);

export const renderOpportunityCreatedAt = decorateRenderer((opp: Opportunity): ReactNode => {
  const createdAt = pickOpportunityCreatedAt(opp);

  return createdAt ? createdAt.format('YYYY/MM/DD') : '-';
});

export const renderOpportunityLastDetectedAt = decorateRenderer((opp: Opportunity): ReactNode => {
  const lastDetectedAt = pickOpportunityLastDetectedAt(opp);

  return lastDetectedAt ? lastDetectedAt.format('YYYY/MM/DD') : '-';
});

export const renderOpportunitySource = decorateRenderer((opp: Opportunity) => ({
  trustedadvisor: 'Trusted Advisor',
  costexplorer: 'Cost Explorer',
  computeoptimizer: 'Compute Optimizer',
  custom: 'Manual',
  aws: 'AWS'
})[opp.source] || opp.source);

export const renderOpportunityPlatform = (opp: Opportunity) => {
  const platform = pickOpportunityPlatform(opp);

  return {
    aws: 'AWS',
    gcp: 'Google Cloud'
  }[platform];
}

export const renderOpportunityPriority = decorateRenderer(pickOpportunityPriority);
