import { Button2 as Button } from 'shared/Button';

export interface DropdownFooterProps {
  onApply: () => void;
  onReset: () => void;
}

export const DropdownFooter = (props: DropdownFooterProps) => {
  const {
    onApply,
    onReset
  } = props;

  return (
    <div className='flex justify-between p-2 border-t'>
      <Button
        size='xs'
        theme='gray'
        className='text-silver-grey-700'
        onClick={onReset}
      >
        Reset
      </Button>
      
      <Button
        size='xs'
        theme='filled'
        onClick={onApply}
      >
        OK
      </Button>
    </div>
  );
}
