import { MutableRefObject, useEffect } from 'react'

export interface Options {
  scrollOffset?: number
  className?: string | string[]
}

type Ref = MutableRefObject<HTMLElement | null>

export const useClassifyOnScroll = (reference: Ref, scrollable: Ref, options?: Options) => {
  const opts = { scrollOffset: 10, className: '', ...options }
  const className = Array.isArray(opts.className) ? opts.className : [opts.className]
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (scrollable.current && reference.current) {
      const elem = scrollable.current
      const onScroll = () => {
        if (elem?.scrollTop > opts.scrollOffset) {
          reference.current?.classList.add(...className)
        } else {
          reference.current?.classList.remove(...className)
        }
      }

      if (elem === document.documentElement) {
        document.addEventListener('scroll', onScroll)
      } else {
        elem.addEventListener('scroll', onScroll)
      }

      onScroll()

      return () => elem.removeEventListener('scroll', onScroll)
    }
  }, [reference, scrollable])
}
