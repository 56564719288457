import type { ReactNode } from 'react';
import type { TeamFilterFieldKey, MutualExclusivityErrorData } from 'types/teams';

import { Tooltip } from 'antd';
import { getSourceTitle, getTitlePlural } from 'shared/Filters';
import { FLAT_FIELDS_TO_RULE_NAMES }  from 'helpers/teams';
export interface MutualExclusivityErrorMessageProps {
  errorData: MutualExclusivityErrorData;
}

export const MutualExclusivityErrorMessage = (props: MutualExclusivityErrorMessageProps) => {
  const {
    errorData
  } = props;
  
  return (
    <div className='col-span-3 border border-negative rounded p-[15px]'>
      <span className='font-medium text-negative'>
        Team filters are overlapping with existing teams
        {errorData.message.length > 0 && ': '}
      </span>

      {errorData.message.map(({ team_name, conflicts }, index) => {
        const separator = index < errorData.message.length - 1 ? ', ' : '';

        if (Object.keys(conflicts).length === 0) {
          return (
            <span key={index}>
              {team_name}
              {separator}
            </span>
          );
        }

        const content: ReactNode[] = [];

        Object.entries(FLAT_FIELDS_TO_RULE_NAMES).forEach(([src, fieldToRule]) => {
          const fieldValues: ReactNode[] = [];

          Object.entries(fieldToRule).forEach(([fieldName, ruleName]) => {
            const values = conflicts[fieldName as TeamFilterFieldKey];
            if (values) {
              fieldValues.push(
                <div>
                  <b>{getTitlePlural(ruleName)}: </b>
                  {values.length ?
                    values.map((value) => typeof value === 'string' ? value : `${value.key}: ${value.value}`).join(', '):
                    <i>all</i>
                  } 
                </div>
              );
            }
          });

          if (fieldValues.length) {
            content.push(
              <div key={src} className='mb-[10px]'>
                <b>{getSourceTitle(src)}</b>
                <div className='ml-[5px]'>
                  {fieldValues}
                </div>
              </div>
            );
          }
        });

        return (
          <span key={index}>
            <Tooltip
              title={content}
            >
              {team_name}
            </Tooltip>
            {separator}
          </span>
        );
      })}
    </div>
  );
};
