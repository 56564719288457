import type {
  CURGlobalFilterFields,
  GCPGlobalFilterFields,
  CustomGlobalFilterFields,
} from 'types/globalFilters';

export const FLAT_FIELDS_TO_RULE_NAMES: {
  cur: Record<keyof CURGlobalFilterFields, string>;
  gcp: Record<keyof GCPGlobalFilterFields, string>;
  custom_data: Record<keyof CustomGlobalFilterFields, string>;
} = {
  cur: {
    aws_account_ids: 'accounts',
    aws_bill_payer_account_ids: 'bill_payer_account_ids',
    aws_account_org_ids: '',
    aws_region_names: 'regions',
    aws_services: 'services',
    aws_tags: 'opportunity_tags',
    aws_account_tags: 'account_tags'
  },
  gcp: {
    gcp_project_names: 'gcp_projects',
    gcp_billing_account_ids: 'gcp_accounts',
    gcp_locations: 'gcp_locations',
    gcp_services: 'gcp_services',
    gcp_labels: 'gcp_labels'
  },
  custom_data: {
    custom_stream_tokens: 'stream_token',
    custom_metric_names: 'metric_name',
    custom_dimensions: 'dimensions'
  }
};

export const RULE_NAMES_TO_FLAT_FIELDS: {
  cur: Record<string, keyof CURGlobalFilterFields>;
  gcp: Record<string, keyof GCPGlobalFilterFields>;
  custom_data: Record<string, keyof CustomGlobalFilterFields>;
} = {
  cur: {
    accounts: 'aws_account_ids',
    bill_payer_account_ids: 'aws_bill_payer_account_ids',
    regions: 'aws_region_names',
    services: 'aws_services',
    opportunity_tags: 'aws_tags',
    account_tags: 'aws_account_tags'
  },
  gcp: {
    gcp_projects: 'gcp_project_names',
    gcp_accounts: 'gcp_billing_account_ids',
    gcp_locations: 'gcp_locations',
    gcp_services: 'gcp_services',
    gcp_labels: 'gcp_labels'
  },
  custom_data: {
    stream_token: 'custom_stream_tokens',
    metric_name: 'custom_metric_names',
    dimensions: 'custom_dimensions'
  }
};
