import type {
  OpportunityPageFilters,
  GroupSetting,
  SourceSetting,
  RateCommitmentType,
  Term,
  Payment,
  EC2OfferingClass,
  Lookback,
  PlatformSetting,
  TeamIdSetting,
  GlobalFilterIdsSetting
} from 'types/savings';

import create from 'zustand';

export interface SavingsSettingsStore extends OpportunityPageFilters {
  setTeamId: (teamId: TeamIdSetting | null) => void;
  setGlobalFilterIds: (globalFilterIds: GlobalFilterIdsSetting | null) => void;
  setPlatform: (platform: PlatformSetting) => void;
  setGroup: (group: GroupSetting) => void;
  setSource: (source: SourceSetting) => void;
  setPriority: (priority: number[] | null) => void;

  setCommitmentEnabled: (type: RateCommitmentType, enabled: boolean) => void;
  setCommitmentTerm: (type: RateCommitmentType, term: Term) => void;
  setCommitmentPayment: (type: RateCommitmentType, payment: Payment) => void;
  setCommitmentEC2OfferingClass: (type: RateCommitmentType, ec2OfferingClass: EC2OfferingClass) => void;
  setCommitmentLookback: (type: RateCommitmentType, lookback: Lookback) => void;
}

export const useSavingsSettingsStore = create<SavingsSettingsStore>((set, get) => ({
  teamId: undefined,
  setTeamId: (teamId: TeamIdSetting | null) => {
    set({ teamId: teamId ?? undefined });
  },

  globalFilterIds: undefined,
  setGlobalFilterIds: (globalFilterIds: GlobalFilterIdsSetting | null) => {
    set({ globalFilterIds: globalFilterIds ?? undefined });
  },

  platform: 'all',
  setPlatform: (platform: PlatformSetting) => {
    set({ platform });
  },

  group: 'both',
  setGroup: (group: GroupSetting) => {
    set({ group });
  },

  source: 'costexplorer',
  setSource: (source: SourceSetting) => {
    const {
      rateCommitments,
      setCommitmentPayment
    } = get();

    set({ source });

    if (source === 'trustedadvisor') {
      const commitmentTypes: RateCommitmentType[] = ['savingsPlans', 'reservedInstances'];

      commitmentTypes.forEach((commitmentType: RateCommitmentType) => {
        if (rateCommitments[commitmentType].payment === 'NO_UPFRONT') {
          setCommitmentPayment(commitmentType, 'PARTIAL_UPFRONT');
        }
      });
    }
  },

  priority: undefined,
  setPriority: (priority: number[] | null) => {
    set({ priority: priority ?? undefined });
  },

  rateCommitments: {
    savingsPlans: {
      enabled: true,
      term: 'ONE_YEAR',
      payment: 'NO_UPFRONT',
      lookback: 'THIRTY_DAYS'
    },
    reservedInstances: {
      enabled: true,
      term: 'ONE_YEAR',
      payment: 'NO_UPFRONT',
      ec2OfferingClass: 'STANDARD'
    },
  },

  setCommitmentEnabled: (type: RateCommitmentType, enabled: boolean) => {
    const { rateCommitments } = get();

    set({
      rateCommitments: {
        ...rateCommitments,
        [type]: { ...rateCommitments[type], enabled }
      }
    });
  },

  setCommitmentTerm: (type: RateCommitmentType, term: Term) => {
    const { rateCommitments } = get();

    set({
      rateCommitments: {
        ...rateCommitments,
        [type]: { ...rateCommitments[type], term }
      }
    });
  },

  setCommitmentPayment: (type: RateCommitmentType, payment: Payment) => {
    const { rateCommitments } = get();

    set({
      rateCommitments: {
        ...rateCommitments,
        [type]: { ...rateCommitments[type], payment }
      }
    });
  },

  setCommitmentEC2OfferingClass: (type: RateCommitmentType, ec2OfferingClass: EC2OfferingClass) => {
    const { rateCommitments } = get();

    set({
      rateCommitments: {
        ...rateCommitments,
        [type]: { ...rateCommitments[type], ec2OfferingClass }
      }
    });
  },

  setCommitmentLookback: (type: RateCommitmentType, lookback: Lookback) => {
    const { rateCommitments } = get();

    set({
      rateCommitments: {
        ...rateCommitments,
        [type]: { ...rateCommitments[type], lookback }
      }
    });
  }
}));
