import type { Tags } from 'types/tags';

import { message, Input } from 'antd';
import { Button } from 'shared/Button';
import { InfoIcon } from 'shared/InfoIcon';
import { TagTable } from './TagTable';
import { AddTagControl } from './AddTagControl';
import { useTagCatalogsStore } from 'store/tagCatalogs';

export interface CreateCatalogFormProps {
  onCreate?: () => void;
}

export const CreateCatalogForm = (props: CreateCatalogFormProps) => {
  const { onCreate } = props;
  const tagCatalog = useTagCatalogsStore((store) => store.getNewEntry());

  const setTagCatalog = useTagCatalogsStore((store) => store.setCreateEntry);
  const createTagCatalog = useTagCatalogsStore((store) => store.createEntry);
  const confirmCreateSuccess = useTagCatalogsStore((store) => store.confirmCreateSuccess);
  const discardCreateError = useTagCatalogsStore((store) => store.discardCreateError);

  if (!tagCatalog) {
    return null;
  }

  const {
    draft
  } = tagCatalog;

  const name = draft.name;
  const catalogKey = draft.catalog_key;
  const tags = draft.tags;

  const setCatalogKey = (newCatalogKey: string) => {
    const newCatalog = {
      ...tagCatalog,
      draft: {
        ...draft,
        name: newCatalogKey,
        catalog_key: newCatalogKey
      }
    };

    setTagCatalog(newCatalog);
  };

  const setTags = (newTags: Tags) => {
    const newCatalog = {
      ...tagCatalog,
      draft: {
        ...draft,
        tags: newTags
      }
    };

    setTagCatalog(newCatalog);
  };

  const valid = name.trim() !== '' &&
    catalogKey.trim() !== '' &&
    Object.values(tags).every((tagValue) => tagValue !== null);

  const actionInProgress = !!tagCatalog.action;
  const canSave = !actionInProgress && valid && Object.keys(tagCatalog.draft.tags).length > 0;

  return <div className='gap-[15px]'>
    <div className='mb-[20px]'>
      <div className='mb-2 font-medium'>
        Catalog key
      </div>

      <Input
        size='large'
        disabled={actionInProgress}
        value={catalogKey}
        onChange={(event) => {
          setCatalogKey(event.target.value);
        }}
      />
    </div>

    <div className='mb-2 font-medium'>
      Tag key/value pairs
      <InfoIcon>
        To add a new key to a catalog entry, you must first add this key to your AWS Cost Allocation tags and tagged a 
        resource with it. If you do not see any tag values, try Synchronize Tags.
      </InfoIcon>
    </div>

    <div className='grow'>
      <AddTagControl
        tags={tags}
        onAdd={(key, value) => {
          setTagCatalog({
            ...tagCatalog,
            draft: {
              ...draft,
              tags: {
                ...tags,
                [key]: value
              }
            }
          });
        }}
        onKeyCreate={() => {}}
        onValueCreate={() => {}}
      />

      <TagTable
        disabled={actionInProgress}
        tags={tags}
        onChange={setTags}
      />

      <div className='flex justify-end gap-[10px] mt-[20px]'>
        <Button
          size='md'
          type='secondary'
          onClick={() => {
            setTagCatalog({
              ...tagCatalog,
              draft: {
                name: '',
                catalog_key: '',
                tags: {}
              }
            });
          }}
          label='Reset'
        />

        <Button
          size='md'
          disabled={!canSave}
          onClick={() => {
            createTagCatalog(tagCatalog.key)
              .then(() => {
                message.success(`Tag catalog '${tagCatalog.draft.name}' created`);
                confirmCreateSuccess(tagCatalog.key);
              })
              .catch(() => {
                message.error(<>
                  {`Unable to save '${tagCatalog.draft.name}' `}
                  <button
                    onClick={() => {
                      createTagCatalog(tagCatalog.key);
                    }}
                  >
                    Retry
                  </button>
                </>);
                discardCreateError(tagCatalog.key);
              });

            onCreate && onCreate();
          }}
          label='Create Catalog'
        />
      </div>
    </div>
  </div>;
};
