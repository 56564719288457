import {OpportunityFilterOptions} from "types/savings";

export const serializeTags = (tags: string[]) => tags.map((item) => {
  const split = item.split(':');

  if (split.length !== 2) {
    return;
  }

  const [key, value] = split;

  return `{"Key":"${key}","Value":"${value}"}`
}).filter(Boolean) as string[];

export const buildTextSearch = (params: {
  tableFilters: {
    text_search?: string
  },
  filterOptions?: OpportunityFilterOptions
}): string | undefined => {
  const {
    tableFilters: {
      text_search
    },
    filterOptions
  } = params;

  if (!filterOptions || !text_search || !text_search.trim()) {
    return text_search || undefined;
  }

  const {
    account
  } = filterOptions

  const matchingAccounts = account.filter(({ text }) => typeof text === 'string' && text.toLowerCase().includes(text_search.trim().toLowerCase()));

  return [
    text_search,
    ...matchingAccounts.map(({ value }) => value)
  ].join(' ');
};
