import type { Team } from 'types/teams'

import { useState, useEffect } from 'react';
import { message } from 'antd'
import { Button2 as Button } from 'shared/Button';
import { TeamBlock } from './TeamBlock';
import { CreateDialog } from './CreateDialog';
import { useTeamsStore } from 'store/teams';
import { useGlobalState } from 'state/globalState';
import { useCostColumnStore } from 'store/costColumns';
import { DeleteTeamDialog } from './DeleteTeamDialog';
import { OrgTeamSettings } from './OrgTeamSettings';
import { StatusOverlay } from 'shared/Overlay';
import { SectionTitle } from 'shared/Section';

export const TeamsTab = () => {
  const user = useGlobalState((state) => state.user);
  const teams = useTeamsStore((state) => state.getLibrary());

  const deleteTeam = useTeamsStore((state) => state.deleteEntry);

  const [editKey, setEditKey] = useState<string | number | null>(null);
  const [teamToDelete, setTeamToDelete] = useState<Team | null>(null);

  const { 
    costColumnsMap,
    fetchCostColumns,
  } = useCostColumnStore();

  useEffect(() => {
    fetchCostColumns();
  }, [])

  const handleEditTeam = (team: Team) => {
    setEditKey(team.id);
  }

  return (
    <div className='pt-[35px]'>
      {user && !user.team && <OrgTeamSettings />}

      <div className='min-h-[200px]'>
        {user && <div className='flex mb-5'>
          <SectionTitle>
            {user.team ? 'Current Team' : 'Teams'}
          </SectionTitle>

          {!user.team && (
            <Button
              className='ml-auto'
              size='s'
              theme='link'
              layout='inline'
              iconLeft='circle-plus'
              onClick={() => {
                setEditKey('new');
              }}
            >
              Create New Team
            </Button>
          )}
        </div>}

        {teams.status === 'success' && teams.data.filter((team) => {
          let keep = true
          if (!user || (user.team && !team.id) || (user.team && team.id && user.team.id !== team.id)) {
            keep = false
          }
          return keep
        }).map((team) => (
          <TeamBlock
            team={team}
            editTeam={handleEditTeam}
            deleteTeam={setTeamToDelete}
            costColumnsMap={costColumnsMap}
          />
        ))}

        <StatusOverlay status={teams.status }/>

        <CreateDialog
          draftKey={editKey}
          onClose={() => {
            setEditKey(null);
          }}
        />

        <DeleteTeamDialog
          open={!!teamToDelete}
          name={teamToDelete?.name || ''}
          onClose={() => setTeamToDelete(null)}
          onConfirm={() => {
            if (!teamToDelete) {
              return;
            }

            deleteTeam(teamToDelete.id)
              .then(() => {
                setTeamToDelete(null);
                message.success(`Team '${teamToDelete.name}' deleted`);
              })
              .catch(() => {
                message.error(`Unable to delete '${teamToDelete.name}'`);
              });
          }}
        />
      </div>
    </div>
  );
};
