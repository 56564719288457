import { Section, SectionTitle } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { useSavingsStore } from 'store/savings';

export interface OpportunityCostDescriptionSectionProps {
  className?: string;
  opportunityId: number;
}

export const OpportunityCostDescriptionSection = (props: OpportunityCostDescriptionSectionProps) => {
  const {
    className = '',
    opportunityId
  } = props;

  const opportunity = useSavingsStore((store) => store.getOpportunity(opportunityId));

  if (opportunity.status === 'success' && !opportunity.data.opportunity_details?.cost_impact_description) {
    return null;
  }

  return (
    <Section className={`p-5 relative ${className}`}>
      <SectionTitle className='mb-2'>
        Cost Impact Description
      </SectionTitle>

      {opportunity.status === 'success' && (
        <p>
          {opportunity.data.opportunity_details?.cost_impact_description}
        </p>
      )}
      
      <StatusOverlay status={opportunity.status} />
    </Section>
  );
}
