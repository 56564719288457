import type { DataEntry } from 'types/dataEntries';

import { Button2 as Button } from 'shared/Button';
import { Section, SectionTitle } from 'shared/Section';
import { InfoIcon } from 'shared/InfoIcon';
import { StatusOverlay } from 'shared/Overlay';
import { SimpleTable, SimpleTableCell, SimpleTableHeadCell, SimpleTableRow } from 'shared/SimpleTable';
import { ThreadStatusIcon } from '../ThreadStatusIcon';

import { formatCurrency } from 'helpers/formatter';
import { useSavingsStore } from 'store/savings';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';

export interface ThreadsStatusSavingsSectionProps {
  className?: string;
}

export const ThreadsStatusSavingsSection = (props: ThreadsStatusSavingsSectionProps) => {
  const {
    className = ''
  } = props;

  const threads = useSavingsStore((store) => store.getThreads());

  const summary: DataEntry<Record<
    'open' | 'active' | 'rejected' | 'total',
    { count: number, savings: number }
  >> = useDerivedDataEntry({ threads }, ({ threads }) => {
    const result = {
      open: { count: 0, savings: 0 },
      active: { count: 0, savings: 0 },
      rejected: { count: 0, savings: 0 },
      total: { count: 0, savings: 0 }
    };

    threads.forEach((thread) => {
      const statusType = thread.status === 'open' ?
        'open' :
        thread.status === 'rejected' ?
          'rejected' :
          ['implementation', 'validation'].includes(thread.status) ?
            'active' :
            null;

      if (!statusType) {
        return;
      }

      result.total.count++;
      result[statusType].count++;
      result.total.savings += 30 * thread.cost_impact_sum;
      result[statusType].savings += 30 * thread.cost_impact_sum;
    });

    return result;
  });

  return (
    <Section className={`p-5 relative ${className}`}>
      <div className='flex items-center mb-5'>
        <SectionTitle>
          Threads Active Savings / Mo
        </SectionTitle>

        <InfoIcon>
          Threads are opportunities grouped together for action and jira workflows. 
        </InfoIcon>

        <Button
          to='/savings/threads'
          size='xs'
          theme='link'
          layout='inline'
          iconRight='arrow-right'
          className='ml-auto'
        >
          See All Threads
        </Button>
      </div>

      {summary.status === 'success' && (
        <SimpleTable className='text-right align-middle'>
          <thead>
            <SimpleTableHeadCell className='text-left'>
              Status
            </SimpleTableHeadCell>

            <SimpleTableHeadCell>
              # of Threads
            </SimpleTableHeadCell>

            <SimpleTableHeadCell>
              Active Savings / Mo
            </SimpleTableHeadCell>
          </thead>

          <tbody>
            {Object.entries(summary.data).map(([statusType, { count, savings }]) => (
              <SimpleTableRow className='capitalize last-child:font-medium'>
                <SimpleTableCell className='text-left'>
                  <ThreadStatusIcon
                    className='mr-2'
                    status={statusType === 'active' ? 'implementation' : statusType as any}
                  />

                  {statusType}
                </SimpleTableCell>

                <SimpleTableCell>
                  {count}
                </SimpleTableCell>

                <SimpleTableCell>
                  {formatCurrency(savings)}
                </SimpleTableCell>
              </SimpleTableRow>
            ))}
          </tbody>
        </SimpleTable>
      )}
      
      <StatusOverlay status={summary.status} />
    </Section>
  );
}
