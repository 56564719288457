import { Button } from 'shared/Button';

export interface TagCostsExportProps {
  data: {}
}

export const TagCostsExport = (props: TagCostsExportProps) => {
  const { data } = props;

  return <Button
    className='ml-auto'
    size='sm'
    style='caladon'
    label='Export costs'
    download='tag_costs.json'
    href={`data:text/plain;charset=utf-8,${encodeURIComponent(JSON.stringify(data, null, 2))}`}
  />;
}
