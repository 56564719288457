import { useEffect, useRef } from 'react'

export const useInterval = (callback: () => void, interval: number | null) => {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (!interval && interval !== 0) {
      return
    }

    const func = async () => {
      await savedCallback.current()
      // TODO: Recheck setTimeout
      // id = setTimeout(func, interval)
    }
    const id = setInterval(func, interval)
    // eslint-disable-next-line consistent-return
    return () => clearInterval(id)
  }, [interval])
}
