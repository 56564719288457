import type { BillingOverview } from 'types/billing';

import moment from 'moment';
import { useMemo } from 'react';
import { H1, H2, P } from './Typography';
import { PlanText } from './PlanText';
import { BillingText } from './BillingText';
import { SubscribeButton } from './SubscribeButton';
import { pluralize } from 'helpers/formatter';

export interface TrialMessageProps {
  overview: BillingOverview;
}

export const TrialMessage = (props: TrialMessageProps) => {
  const {
    overview
  } = props;

  const {
    last_calculated_total_spend,
    last_calculated_start_date,
    last_calculated_end_date,
    last_calculated_plan
  } = overview;

  const today = useMemo(() => moment().startOf('day'), []);
  const trialDaysLeft = -today.diff(overview.trial_end_date, 'day');

  return (
    <div>
      {trialDaysLeft > 0 ? (
        <>
          <H1>
            You’re currently on your 14 day free trial of Cloudthread. Enjoy!
          </H1>

          <P>
            {trialDaysLeft} free trial {pluralize(trialDaysLeft, { one: 'day', other: 'days' })} remaining
          </P>
        </>
      ) : (
        <H1>
          Your free trial is over and your Cloudthread subscription is <strong className='text-negative'>inactive</strong>.
        </H1>
      )}
      
      <PlanText
        totalSpend={last_calculated_total_spend}
        startDate={last_calculated_start_date}
        endDate={last_calculated_end_date}
        plan={last_calculated_plan}
      />

      <P>
        Cloudthread computes your <b>last 31 days of spend</b> to determine your Tier.
      </P>

      <H2>
        Billing
      </H2>

      <BillingText />

      <SubscribeButton>
        Upgrade
      </SubscribeButton>
    </div>
  );
}
