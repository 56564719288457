import type { AlertConfig } from 'types/alerts'
import type { CostViewLibraryEntry } from 'types/costViews'
import type { UnitMetricsLibraryEntry } from 'types/unitMetrics'

import { useState, useEffect } from 'react'
import { Input, InputNumber, Select, message, Radio } from 'antd'
import { formatRounded } from 'helpers/formatter'
import { validateEmail } from 'helpers/validator'
import { Button } from 'shared/Button'
import { Dialog } from 'shared/Dialog';
import { InfoIcon } from 'shared/InfoIcon';
import { useAlertConfigStore } from 'store/alertConfigs';
import { useSlackChannelsStore } from 'store/slackChannels'

export interface CreateDialogProps {
  opened: boolean;
  alertToEdit: AlertConfig | null;
  onClose: (search?: string) => void;
  costViews: CostViewLibraryEntry[];
  unitMetrics: UnitMetricsLibraryEntry[];
  slackIntegraitonId: number;
  slackChannels: string[];
}

export const CreateDialog = (props: CreateDialogProps) => {
  const {
    opened,
    alertToEdit,
    onClose,
    costViews,
    unitMetrics,
    slackIntegraitonId,
    slackChannels,
  } = props;
  const { saveAlertConfig } = useAlertConfigStore();
  const [alertMetricType, setAlertMetricType] = useState('cost')
  const [costViewId, setCostViewId] = useState(-1)
  const [unitMetricId, setUnitMetricId] = useState(-1)
  const [alertName, setAlertName] = useState('')
  const [alertType, setAlertType] = useState('Absolute')
  const [periord, setPeriod] = useState(1)
  const [absoluteThreshold, setAbsoluteThreshold] = useState(0)
  const [relativeThreshold, setRelativeThreshold] = useState(0)
  const [recepientEmails, setRecepientEmails] = useState('')
  const [newSlackIntegraitonId, setNewSlackIntegraitonId] = useState(false)
  const [recepientSlackChannels, setRecepientSlackChannels] = useState<string[]>([])
  const { fetchSlackChannels } = useSlackChannelsStore();

  const handleSlackClick = () => {
    window.open('https://app.cloudthread.io/settings/third-party-integrations', '_blank')
  }

  const handlFetchSlackIntegration = () => {
    fetchSlackChannels();
  }

  const handleChangeAlertName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAlertName(e.target.value)
  }

  const handleChangeCostViewId = (id: number) => {
    setCostViewId(id)
  }

  const handleChangeUnitMetricId = (id: number) => {
    setUnitMetricId(id)
  }

  const handleChangePeriod = (value: number | null) => {
    setPeriod(value ? value : 0)
  }

  const handleChangeAbsoluteThreshold = (value: number | null) => {
    setAbsoluteThreshold(value ? value : 0)
  }

  const handleChangeRelativeThreshold = (value: number | null) => {
    setRelativeThreshold(value ? value : 0)
  }

  const handleChangeRecipientEmails = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecepientEmails(e.target.value)
  }

  const handleChangeRecipientSlackChannels = (channelList: string[]) => {
    setRecepientSlackChannels(channelList)
  }

  const handleClose = (search?: string) => {
    onClose(search);
  };

  useEffect(() => {
    if (!opened) {
      setAlertMetricType('cost')
      setCostViewId(-1)
      setUnitMetricId(-1)
      setAlertType('Absolute')
      setPeriod(1)
      setAbsoluteThreshold(0)
      setRelativeThreshold(0)
      setRecepientEmails('')
      setRecepientSlackChannels([])
      setAlertName('')
    } else if (alertToEdit) {
      setAlertMetricType(alertToEdit.metric_type)
      if (alertToEdit.metric_type === 'cost') {
        setCostViewId(alertToEdit.filter_id)
      } else {
        setUnitMetricId(alertToEdit.filter_id)
      }
      setAlertType(alertToEdit.alert_type)
      setPeriod(alertToEdit.period)
      setAbsoluteThreshold(alertToEdit.alert_type === 'Absolute' ? alertToEdit.threshold : (alertToEdit.threshold - 1) * 100)
      setRelativeThreshold(alertToEdit.alert_type === 'Absolute' ? alertToEdit.threshold : (alertToEdit.threshold - 1) * 100)
      setRecepientEmails(alertToEdit.email_recepients.join(', '))
      if (alertToEdit.slack_id !== slackIntegraitonId) {
        setNewSlackIntegraitonId(true)
      } else {
        setRecepientSlackChannels(alertToEdit.slack_channels)
      }
      setAlertName(alertToEdit.name)
    }
  }, [opened, alertToEdit])

  return (
    <Dialog
      open={opened}
      onClose={() => handleClose()}
      className='w-[510px]'
    >
      <Dialog.Title>
      {`${!alertToEdit ? 'Create New' : 'Edit'} Alert`}
      </Dialog.Title>

      <div className='px-[30px] pb-[30px] overflow-y-scroll'>
        <div className='mb-[30px]'>
          <div className='text-[16px] font-medium mb-[10px]'>
            Alert name
          </div>
          <Input
            type='secondary'
            value={alertName}
            onChange={handleChangeAlertName}
          />
        </div>
        <div className='mb-[40px]'>
          <div className='text-[16px] font-medium mb-[10px]'>
            Target
          </div>
          <div className='mb-[10px]'>
            <Radio.Group value={alertMetricType} onChange={(e) => {
              setAlertMetricType(e.target.value);
              setCostViewId(-1);
              setUnitMetricId(-1);
            }}>
              <Radio value='cost'>Cost View</Radio>
              <Radio value='unit'>Unit Metric</Radio>
            </Radio.Group>
          </div>
          {alertMetricType === 'cost' && 
            <Select
              showSearch
              filterOption
              optionFilterProp='label'
              className="w-full mt-2"
              placeholder="Choose cost fiter"
              onChange={handleChangeCostViewId}
              value={costViewId === -1 ? null : costViewId}
            >
              {costViews.filter((view) => view.is_custom).map(
                (filter) => (
                  <option value={filter.id}>{filter.name}</option>
                ),
              )}
            </Select>
          }
          {alertMetricType === 'unit' && 
            <Select
              showSearch
              filterOption
              optionFilterProp='label'
              className='w-full mt-2'
              placeholder='Choose unit metric'
              onChange={handleChangeUnitMetricId}
              value={unitMetricId === -1 ? null : unitMetricId}
            >
              {unitMetrics.filter((metric) => metric.is_custom).map(
                (metric) => (
                  <Select.Option value={metric.id} label={metric.name}>{metric.name}</Select.Option>
                ),
              )}
            </Select>
          }
        </div>
        <div className='mb-[40px]'>
          <div className='text-[16px] font-medium mb-[10px]'>
            Type
            <InfoIcon>
              <p className='mb-2'>
                Absolute thresholds are associated with a specific, absolute number (e.g. alert when costs go above $10). Must be positive.
              </p>

              <p>
                Relative thresholds are associated with a percentage increase Week over Week (e.g. alert when costs increase by more than 5% compared to last week). Must be positive.
              </p>
            </InfoIcon>
          </div>
          <div className='mb-[10px]'>
            <Radio.Group value={alertType} onChange={(e) => {
              setAlertType(e.target.value);
            }}>
              <Radio value='Absolute'>Absolute</Radio>
              <Radio value='Relative'>Relative</Radio>
            </Radio.Group>
          </div>
          {alertType === 'Absolute' && 
            <div className='mb-[10px]'>
              <InputNumber
                addonBefore={"$"}
                min={0}
                precision={2}
                value={absoluteThreshold}
                onChange={handleChangeAbsoluteThreshold}
              />
            </div>
          }
          {alertType === 'Relative' && 
            <div className='mb-[10px]'>
              <InputNumber
                addonAfter='%'
                min={0}
                precision={2}
                value={relativeThreshold}
                onChange={handleChangeRelativeThreshold}
              />
            </div>
          }
          <div>
            <div className='mb-[10px]'>
              Period Length
              <InfoIcon>
                <p className='mb-2'>
                  Number of trailing days to calculate the value. Must be greater than 0.
                </p>
              </InfoIcon>
            </div>

            <InputNumber
              addonAfter='days'
              min={1}
              precision={0}
              value={periord}
              onChange={handleChangePeriod}
            />
          </div>
        </div>
        <div className='mb-[40px]'>
          <div className='text-[16px] font-medium mb-[10px]'>
            Channels of Delivery
          </div>
          <div className='mb-2'>
            Emails
            <InfoIcon>
              <p className='mb-2'>
                Recipient emails (comma separated)
              </p>
              <p>
                Note: any emails will work, they don't need to have an associated Cloudthread user
              </p>
            </InfoIcon>
          </div>
          <Input
            type='secondary'
            value={recepientEmails}
            onChange={handleChangeRecipientEmails}
          />
          <div className='mb-2 mt-[20px]'>
            Slack Channels
            <InfoIcon>
              These are all your workspace's public, non-archived channels
            </InfoIcon>
          </div>
          {newSlackIntegraitonId && <div className='text-[13px] text-silver-grey-800'>New Slack Integration – please select new channels</div>}
          {slackChannels.length ? (
              <Select mode="multiple" className="w-full" placeholder="Choose slack channels" onChange={handleChangeRecipientSlackChannels} value={recepientSlackChannels}>
                {slackChannels.map(
                  (channel) => (
                    <Select.Option value={channel}>{channel}</Select.Option>
                  ),
                )}
              </Select>
          ) : (
            <div className='flex'>
              <a onClick={handleSlackClick} className='text-cyanine-blue hover:text-caladon-blue'>Integrate Slack</a>
              <div className='ml-5'>|</div>
              <a onClick={handlFetchSlackIntegration} className='ml-5 text-cyanine-blue hover:text-caladon-blue'>Fetch Slack Channels</a>
            </div>
          )}
        </div>
        <div className='flex'>
          <Button
            label='Clear All'
            className='mr-auto'
            type='tertiary'
            style='caladon'
            onClick={() => {
              setAlertMetricType('cost')
              setCostViewId(-1)
              setUnitMetricId(-1)
              setAlertType('Absolute')
              setPeriod(0)
              setAbsoluteThreshold(0)
              setRelativeThreshold(0)
              setRecepientEmails('')
              setRecepientSlackChannels([])
              setAlertName('')
            }}
          />
          <Button
            label='Cancel'
            type='tertiary'
            style='caladon'
            onClick={() => handleClose()}
          />
          <Button
            label='Save Alert'
            type='primary'
            style='caladon'
            disabled={!alertName || (!recepientEmails && recepientSlackChannels.length === 0)}
            onClick={async () => {
              if (periord <= 0) {
                message.error('Period Length must be 1 or greater')
                return
              }
              if ((alertMetricType === 'cost' && costViewId === -1) || (alertMetricType === 'unit' && unitMetricId === -1)) {
                message.error('No target set')
                return
              }
              const emails = recepientEmails.split(',').map((email) => email.trim()).filter((email) => email !== '')
              if (emails.filter((email) => !validateEmail(email)).length > 0) {
                message.error('At least one email is invalid')
                return
              }
              if (costViewId !== -1) {
                const targetView = costViews.filter((view) => view.id === costViewId)[0]
                if (targetView.src === 'gcp') {
                  message.error('GCP views not supported for alerts')
                  return
                }
                if (targetView.id >= 4294967296 ) {
                  message.error('Only non-Base views are supported for alerts')
                  return
                }
              }
              if (unitMetricId !== -1) {
                const targetMetric = unitMetrics.filter((metric) => metric.id === unitMetricId)[0]
                if (targetMetric.id >= 4294967296 ) {
                  message.error('Only non-Base metrics are supported for alerts')
                  return
                }
              }
              const threshold = alertType === 'Absolute' ? absoluteThreshold : 1 + formatRounded(relativeThreshold / 100, 2)
              let config
              if (alertType === 'Absolute') {
                config = {
                  id: alertToEdit ? alertToEdit.id : -1,
                  filter_id: alertMetricType === 'cost' ? costViewId : unitMetricId,
                  alert_details: {
                    period: {
                      unit: 'day',
                      length: periord,
                    },
                    threshold,
                    comparison_function: 'greater-than',
                    aggregation_function: 'SUM',
                    providers: {
                      email: {
                        recipients: emails,
                      },
                      slack: {
                        integration_id: slackIntegraitonId,
                        recipients: recepientSlackChannels,
                      },
                    },
                    cost_column: 'unblended_cost',
                    amortization: false,
                    exclude: '',
                  },
                  name: alertName,
                }
              } else {
                config = {
                  id: alertToEdit ? alertToEdit.id : -1,
                  filter_id: alertMetricType === 'cost' ? costViewId : unitMetricId,
                  alert_details: {
                    period: {
                      unit: 'day',
                      length: periord,
                    },
                    threshold,
                    compare_to: {
                      difference_function: 'divide',
                      previous_period_shift: {
                        unit: 'day',
                        length: periord,
                      },
                    },
                    comparison_function: 'greater-than',
                    aggregation_function: 'SUM',
                    providers: {
                      email: {
                        recipients: emails,
                      },
                      slack: {
                        integration_id: slackIntegraitonId,
                        recipients: recepientSlackChannels,
                      },
                    },
                    cost_column: 'unblended_cost',
                    amortization: false,
                    exclude: '',
                  },
                  name: alertName,
                }
              }
              saveAlertConfig(alertMetricType, config);
              handleClose(alertName);
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default CreateDialog
