import { useNavigate } from 'react-router-dom';

import { message, Input } from 'antd';
import { Button } from 'shared/Button';
import { Dialog } from 'shared/Dialog';
import FormField from 'shared/Form/FormField';

import {
  useRulesStore,
  useRuleSaveAsNewDialogStore
} from 'store/rules';

export const SaveAsNewDialog = () => {
  const navigate = useNavigate();
  const { id, close } = useRuleSaveAsNewDialogStore();

  const draft = useRulesStore((store) => id !== null && store.getDraft(id));
  const name = draft && draft.status === 'success' ? draft.data.name : '';

  const ruleIsNew = typeof id === 'string';
  const nameHasChanged = useRulesStore((store) => {
    const entry = !ruleIsNew && store.getEntry(id as number);

    return (
      entry && entry.status === 'success' &&
      draft && draft.status === 'success' &&
      entry.data.name !== draft.data.name
    );
  });

  const setDraft = useRulesStore((store) => store.setDraft);
  const createRule = useRulesStore((store) => store.createEntry);

  return (
    <Dialog
      className='w-[420px]'
      open={id !== null}
      onClose={close}
    >
      <Dialog.Title>Save as New Rule</Dialog.Title>

      <Dialog.Body className='flex flex-col'>
        <FormField.SimpleField label='View Name'>
          <Input
            size='large'
            value={name}
            onChange={(event) => {
              if (id && draft && draft.status === 'success') {
                setDraft(id, {
                  ...draft.data,
                  name: event.target.value
                });
              }
            }}
            type='secondary'
          />
        </FormField.SimpleField>

        <Button
          className='justify-center mt-[33px]'
          disabled={!name || !ruleIsNew && !nameHasChanged}
          style='caladon'
          label='Save'
          onClick={() => {
            close();
            createRule(id as number | string)
              .then((newRule) => {
                navigate(`/rules/${newRule.id}`);
                message.success(`Rule ${newRule.name} created`);
              })
              .catch(() => {
                message.error(`Unable to create rule`);
              });
          }}
        />
      </Dialog.Body>
    </Dialog>
  );
}
