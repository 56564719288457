/*eslint-disable */
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD'

export const LAMBDA_METRIC: { [index: string]: any } = {
  'Invocations': {
    eventRounding: 1,
  },
  'Errors': {
    eventRounding: 1,
  },
  'Throttles': {
    eventRounding: 1,
  },
  'Duration': {
    eventRounding: 1,
  },
}

export const ALB_METRIC: { [index: string]: any } = {
  'RequestCount': {
    eventRounding: 1,
  },
  'ProcessedBytes': {
    eventRounding: 1,
  },
  'TargetResponseTime': {
    eventRounding: 1,
  },
  'HTTP_Redirect_Count': {
    eventRounding: 1,
  },
  'HTTPCode_ELB_3XX_Count': {
    eventRounding: 1,
  },
  'HTTPCode_ELB_4XX_Count': {
    eventRounding: 1,
  },
  'HTTPCode_ELB_5XX_Count': {
    eventRounding: 1,
  },
}

export const S3_METRIC: { [index: string]: any } = {
  'NumberOfObjects': {
    eventRounding: 1,
  },
  'BucketSizeBytes': {
    eventRounding: 1,
  },
}

export const STATEMACHINE_METRIC: { [index: string]: any } = {
  'ExecutionTime': {
    eventRounding: 1,
  },
  'ExecutionThrottled': {
    eventRounding: 1,
  },
  'ExecutionsAborted': {
    eventRounding: 1,
  },
  'ExecutionsFailed': {
    eventRounding: 1,
  },
  'ExecutionsStarted': {
    eventRounding: 1,
  },
  'ExecutionsSucceeded': {
    eventRounding: 1,
  },
  'ExecutionsTimedOut': {
    eventRounding: 1,
  },
}

export const DYNAMODB_METRIC: { [index: string]: any } = {
  'ConsumedReadCapacityUnits': {
    eventRounding: 1,
  },
  'ConsumedWriteCapacityUnits': {
    eventRounding: 1,
  },
  'ReadThrottleEvents': {
    eventRounding: 1,
  },
  'ReturnedBytes': {
    eventRounding: 1,
  },
  'ReturnedItemCount': {
    eventRounding: 1,
  },
  'SystemErrors': {
    eventRounding: 1,
  },
  'ThrottledRequests': {
    eventRounding: 1,
  },
  'UserErrors': {
    eventRounding: 1,
  },
  'WriteThrottleEvents': {
    eventRounding: 1,
  },
}

export const EVENTBRIDGE_METRIC: { [index: string]: any } = {
  'DeadLetterInvocations': {
    eventRounding: 1,
  },
  'FailedInvocations': {
    eventRounding: 1,
  },
  'Invocations': {
    eventRounding: 1,
  },
  'InvocationsFailedToBeSentToDlq': {
    eventRounding: 1,
  },
  'InvocationsSentToDlq': {
    eventRounding: 1,
  },
}

export const CLOUDWATCHLOGS_METRIC: { [index: string]: any } = {
  'CallCount': {
    eventRounding: 1,
  },
  'DeliveryErrors': {
    eventRounding: 1,
  },
  'DeliveryThrottling': {
    eventRounding: 1,
  },
  'ErrorCount': {
    eventRounding: 1,
  },
  'ForwardedBytes': {
    eventRounding: 1,
  },
  'ForwardedLogEvents': {
    eventRounding: 1,
  },
  'IncomingBytes': {
    eventRounding: 1,
  },
  'IncomingLogEvents': {
    eventRounding: 1,
  },
  'ThrottleCount': {
    eventRounding: 1,
  },
}

export const APIGATEWAY_METRIC: { [index: string]: any } = {
  '4XXError': {
    eventRounding: 1,
  },
  '5XXError': {
    eventRounding: 1,
  },
  'CacheHitCount': {
    eventRounding: 1,
  },
  'CacheMissCount': {
    eventRounding: 1,
  },
  'Count': {
    eventRounding: 1,
  },
}

export const AWSTRANSFER_METRIC: { [index: string]: any } = {
  'BytesIn': {
    eventRounding: 1,
  },
  'BytesOut': {
    eventRounding: 1,
  },
  'FilesIn': {
    eventRounding: 1,
  },
  'FilesOut': {
    eventRounding: 1,
  },
  'OnUploadExecutionsStarted': {
    eventRounding: 1,
  },
  'OnUploadExecutionsSuccess': {
    eventRounding: 1,
  },
  'OnUploadExecutionsFailed': {
    eventRounding: 1,
  },
}

export const EBS_METRIC: { [index: string]: any } = {
  'VolumeReadBytes': {
    eventRounding: 1,
  },
  'VolumeWriteBytes': {
    eventRounding: 1,
  },
  'VolumeReadOps': {
    eventRounding: 1,
  },
  'VolumeWriteOps': {
    eventRounding: 1,
  },
  'VolumeTotalReadTime': {
    eventRounding: 1,
  },
  'VolumeTotalWriteTime': {
    eventRounding: 1,
  },
  'VolumeConsumedReadWriteOps': {
    eventRounding: 1,
  },
}

export const EC2_METRIC: { [index: string]: any } = {
  'DiskReadOps': {
    eventRounding: 1,
  },
  'DiskWriteOps': {
    eventRounding: 1,
  },
  'DiskReadBytes': {
    eventRounding: 1,
  },
  'DiskWriteBytes': {
    eventRounding: 1,
  },
  'NetworkIn': {
    eventRounding: 1,
  },
  'NetworkOut': {
    eventRounding: 1,
  },
}

export const CW_METRIC_EVENT_COUNT: { [index: string]: any } = {
  'AWS/Lambda': LAMBDA_METRIC,
  'AWS/ApplicationELB': ALB_METRIC,
  'AWS/S3': S3_METRIC,
  'AWS/States': STATEMACHINE_METRIC,
  'AWS/DynamoDB': DYNAMODB_METRIC,
  'AWS/Events': EVENTBRIDGE_METRIC,
  'AWS/Logs': CLOUDWATCHLOGS_METRIC,
  'AWS/Transfer': AWSTRANSFER_METRIC,
  'AWS/EBS': EBS_METRIC,
  'AWS/EC2': EC2_METRIC,
}
