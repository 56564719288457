import moment from 'moment';
import { useMemo } from 'react';
import { formatRange } from 'helpers/formatter';
import { SpendSection } from './SpendSection';

export interface TotalSpendSectionProps {
  className?: string;
}

export const TotalSpendSection = (props: TotalSpendSectionProps) => {
  const { className } = props;
  const yesterday = useMemo(() => moment().subtract(1, 'day').startOf('day'), []);
  const thisMonthStart = useMemo(() => yesterday.clone().startOf('month'), []);
  const lastMonthStart = useMemo(() => thisMonthStart.clone().subtract(2, 'day').startOf('month'), []);
  const monthAgo = useMemo(() => lastMonthStart.clone().date(yesterday.date()), []);

  return <SpendSection
    className={className}
    title='This Month Spend'
    currentPeriodText={formatRange(thisMonthStart, yesterday, 'day')}
    currentPeriodStart={thisMonthStart}
    currentPeriodEnd={yesterday}
    previousPeriodText={formatRange(lastMonthStart, monthAgo, 'day')}
    previousPeriodStart={lastMonthStart}
    previousPeriodEnd={monthAgo}
  />;
};
