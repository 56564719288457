import { CostTypeControl } from './CostTypeControl';
import { CostDimensionsControl } from './CostDimensionsControl';
import { CostAmortizationControl } from './CostAmortizationControl';
import { useGlobalState } from 'state/globalState';
import { useTeamsStore } from 'store/teams';

export const CostFilterDropdownContent = () => {
  const user = useGlobalState((state) => state.user);
  const team = useTeamsStore((store) => user?.team ? store.getEntry(user.team.id) : null);

  const default_cost_column_name = team?.status === 'success' ? team.data.default_cost_column_name : null;

  return (
    <>
      <div className='px-4 py-2'>
        <div className='font-medium'>
          Cost type
        </div>

        {default_cost_column_name ? (
          default_cost_column_name
        ) : (
          <CostTypeControl />
        )}
      </div>

      <hr />

      <div className='px-4 py-2'>
        <div className='font-medium'>
          Dimensions
        </div>

        <CostDimensionsControl />
      </div>

      {!default_cost_column_name && (
        <>
          <hr />
          <div className='px-4 py-2'>
            <CostAmortizationControl />
          </div>
        </>
      )}
    </>
  );
};
