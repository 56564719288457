import { OpportunityTypeId } from './savings';

export type DataCollectionStatusType = 'success' | 'access' | 'error';
export type DataConfigSource = 'aws' | 'gcp';

export type StackSetType = 
  'co_stack_set_new_cur' |
  'co_new_cur' |
  'co_stack_set_existing_cur' |
  'co_existing_cur' |
  'co_stack_set' |
  'co_base' |
  'analytics_new_cur' |
  'analytics_existing_cur' |
  'ta_new_cur' |
  'ta_existing_cur' |
  'ta_base' | 
  'automation_stack_set' | 
  'automation'

export type AccountFilterType = 
  'UNION' |
  'INTERSECTION' |
  'DIFFERENCE' |
  'NONE'

export interface DataCollectionStatus {
  collection_id: string;
  created_at: string;
  status: string;
  status_type: DataCollectionStatusType;
}

export interface DataConfig {
  id: number
  data_source_id: string
  cadence: string
  is_data_ready: boolean
  is_active: boolean
  created_at: string
  statuses: DataCollectionStatus[]
  status_last_viewed_at: string
  collection_details: any
}

export interface DetailedDataConfig extends DataConfig {
  collection_details: {
    aws_account_id: string;
    aws_account_name: string | null;
    awscur_report_name?: string;
    awscur_report_prefix?: string;
    _opportunity_thresholds: OpportunityThresholds;
    _opportunity_thresholds_titles: Record<string, string>;
  }
}

export const isSavingsDataConfig = (config: DataConfig) => config.data_source_id === 'AWSCOSTOPTIMIZATION';

export interface DataConfigUpdatePayload extends Partial<
  DataConfig
> {
  thresholds?: OpportunityThresholds;
  project_id?: string;
  table?: string;
}

export interface DataConfigsParamUpdatePayload {
  data_collection_config_ids: number[]
  parameter_group: string
  name: string
  value: string
}

export interface AWSDataConfigCreatePayload extends Partial<Pick<
  DataConfig,
  'data_source_id' |
  'cadence' |
  'collection_details'
>> {};

export interface GCPDataConfigCreatePayload extends AWSDataConfigCreatePayload {
  service_account_id: number
};

export type DataConfigCreatePayload = AWSDataConfigCreatePayload | GCPDataConfigCreatePayload;

export interface GCPServiceAccount {
  id: number
  email: string
}

export interface CFNStack {
  id: number
  stack_token: string
  stack_key: string
  stack_bucket: string
  stack_type: string;
  aws_org_root_id: string | null
  aws_ou_ids: string[] | null
  aws_accounts: string[] | null
  aws_account_filter_type: AccountFilterType | null
  aws_org_existing_cur_report_name: string | null
  aws_org_existing_cur_report_prefix: string | null
  aws_org_existing_cur_report_bucket: string | null
  status: string
  link: string
  created_at: string
}

export interface ParameterGroupDict {
  type: 'int' | 'float' | 'bool';
  min_value: number;
  max_value: number;
  default_value: number | string;
  title: string;
}

export interface ParameterGroup {
  parameter_group: string,
  parameter_group_title: string,
  name: string,
  value: string,
  param_dict: ParameterGroupDict,
  data_collection_config_ids: number[],
}

export interface ThresholdConfig {
  parameter_group_title: string;
  title: string;
  type: 'int' | 'float' | 'bool';
  value: number | string;
  min_value: number;
  max_value: number;
  default_value: number | string;
  isUpdated?: boolean;
}

export interface NumberThresholdConfig extends ThresholdConfig {
  type: 'int' | 'float';
  value: number;
  default_value: number;
}

export interface BoolThresholdConfig extends ThresholdConfig {
  type: 'bool';
  value: string;
  default_value: string;
}

export const isBoolThresholdConfig = (config: ThresholdConfig): config is BoolThresholdConfig => config.type === 'bool';
export const isNumberThresholdConfig = (config: ThresholdConfig): config is NumberThresholdConfig => config.type === 'int' || config.type === 'float';

export interface ThresholdConfigSummary extends ThresholdConfig {
  values: {
    [key: string]:  number[]
  }
}

export interface NumberThresholdConfigSummary extends NumberThresholdConfig {
  values: { [key: number]:  number[] }
}

export interface BoolThresholdConfigSummary extends BoolThresholdConfig {
  values: { 
    true?: number[];
    false?: number[]
  }
}


export const isThresholdConfigSummary = (config: ThresholdConfig): config is ThresholdConfigSummary => !!(config as ThresholdConfigSummary).values;

export const isBoolThresholdConfigSummary = (config: ThresholdConfig): config is BoolThresholdConfigSummary => 
  isThresholdConfigSummary(config) &&
  isBoolThresholdConfig(config);

export const isNumberThresholdConfigSummary = (config: ThresholdConfig): config is NumberThresholdConfigSummary => 
  isThresholdConfigSummary(config) &&
  isNumberThresholdConfig(config);

export type ThresholdConfigs = Record<string, ThresholdConfig>;
export type OpportunityThresholds = Record<OpportunityTypeId, ThresholdConfigs>;

export type ThresholdsSummary = {
  ids: number[];
  thresholds: Record<OpportunityTypeId, Record<string, ThresholdConfigSummary>>;
  thresholdsTitles: Record<string, string>;
}

export type ThresholdsMap = Record<number, Record<OpportunityTypeId, Record<string, ThresholdConfig>>>
