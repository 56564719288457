import { Button2 as Button } from 'shared/Button';
import { useSavingsStore, useThreadSelectDialog } from 'store/savings';

export interface OpportunityAssignButtonProps {
  opportunityId: number;
}

export const OpportunityAssignButton = (props: OpportunityAssignButtonProps) => {
  const { opportunityId } = props;
  const openThreadSelectDialog = useThreadSelectDialog((store) => store.open);
  const currentAction = useSavingsStore((store) => store.opportunityActions.get(opportunityId));

  return (
    <Button
      size='xs'
      theme='link'
      layout='inline'
      iconLeft='arrow-right-filled'
      disabled={currentAction && currentAction.status === 'in-progress'}
      onClick={() => {
        openThreadSelectDialog([opportunityId]);
      }}
    >
      Assign To Thread
    </Button>
  );
}
