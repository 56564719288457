import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { message } from 'antd'
import Logo from 'assets/images/Logo.svg'
import LogoSmall from 'assets/images/LogoSmall.svg'
import { ReactComponent as CheckRounded } from 'assets/images/CheckRounded.svg'
import apiService from 'helpers/api'

const EmailConfirmed = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  
  const emailParam = searchParams.get('email')

  const validateToken = async () => {
    const token = searchParams.get('token')
    if (!token) {
      message.error('Email confirmation unsuccessful')
      navigate('/login')
      return
    }

    try {
      await apiService.get(`/user/email/verify?token=${token}`)

      localStorage.setItem('isAuthenticated', 'true')
      localStorage.setItem('isSignedOut', 'false')
      
      message.success('Email confirmation successful')
      navigate(`/login${emailParam ? `?email=${emailParam}` : ''}`)
    } catch (err: any) {
      message.error(err.response.data.message)
      navigate('/login')
    }
  }

  useEffect(() => {
    validateToken()
  }, [])

  return (
    <div className="relative w-full h-screen bg-white md:bg-silver-grey md:flex md:justify-center md:items-center">
      <img className="hidden md:block h-[56px] absolute top-0 left-0" src={Logo} alt="Logo" />
      <img className="visible md:invisible h-[40px] mt-[10px] ml-[10px]" src={LogoSmall} alt="Logo" />
      <div className="w-full md:w-[560px] bg-white md:rounded-[20px] p-[50px] md:shadow-light-shadow">
        <div className="flex justify-center items-center w-[80px] h-[80px] bg-green/10 rounded-full">
          <CheckRounded className="w-[34px] h-[34px]" />
        </div>
        <div className="mt-[30px] pr-[100px] text-oxford-blue">
          <h5 className="font-semibold text-[24px] leading-[34px]">
            Your email address has been successfully confirmed.
          </h5>
        </div>
      </div>
    </div>
  )
}

export default EmailConfirmed
