import type { Opportunity } from 'types/savings';

import { noop } from 'helpers/utils';
import { Button2 as Button } from 'shared/Button';
import { useSavingsStore, useThreadCreateDialog } from 'store/savings';

export interface ThreadFromSelectedButtonProps {
  selected?: Opportunity[];
  onSelect?: (selected: Opportunity[]) => void;
}

export const ThreadFromSelectedButton = (props: ThreadFromSelectedButtonProps) => {
  const {
    selected = [],
    onSelect = noop
  } = props;

  const newThread = useSavingsStore((store) => store.newThread);
  const setNewThread = useSavingsStore((store) => store.setNewThread);
  const openThreadCreateDialog = useThreadCreateDialog((store) => store.open);
  
  return (
    <Button
      size='xs'
      theme='black'
      layout='inline'
      iconLeft='circle-plus'
      onClick={() => {
        setNewThread({ ...newThread, opportunities: selected });
        openThreadCreateDialog();
        onSelect([]);
      }}
    >
      Create New Thread
    </Button>
  );
}
