import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { Button } from 'shared/Button'
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useCostViewConstructor } from 'store/costViewConstructor';
import { useDataConfigWithThresholdsStore, useThresholdEditDialog } from 'store/dataConfigs';

export interface SummaryValueProps {
  value: string;
  configIds: number[];
  category: string;
  title: string,
}

export const SummaryValue = (props: SummaryValueProps) => {
  const {
    value,
    configIds,
    category,
    title,
  } = props;  

  const openThresholdEditDialog = useThresholdEditDialog((store) => store.open);

  const configs = useDataConfigWithThresholdsStore((store) => store.getLibrary());
  const costViewConstructor = useCostViewConstructor();

  const accounts = useDerivedDataEntry(
    { configs, costViewConstructor, configIds },
    ({ configs, costViewConstructor, configIds }) => {
      const aliases = costViewConstructor.cur?.rules.accounts?.aliases || {};

      return configs
        .filter((config) => configIds.includes(config.id))
        .map((config) => {
          const accId = config.collection_details.aws_account_id;
          const alias = aliases[accId];
          const accountTitle = alias ? `${alias} (${accId})` : accId;

          return {
            configId: config.id,
            accountTitle
          };
        });
    }
  );

  const content = (
    <>
      <b>{value + ''}</b>
      {' '}
      ({configIds.length})
    </>
  );

  if (accounts.status !== 'success') {
    return content;
  }

  return (
    <Tooltip
      className='underline decoration-dotted decoration-1 underline-offset-4'
      title={<>
        {accounts.data.map(({ accountTitle, configId }) =>
          <div key={configId} className='mb-2'>
            <Link
              to={`/settings/savings-opportunities?configId=${configId}`}
            >
              {accountTitle}
            </Link>
          </div>
        )}
        <Button
          type='secondary'
          icon='edit'
          label='Edit these accounts'
          size='sm'
          className='!text-inherit w-full justify-center border-current mt-3 mb-1'
          onClick={() => {
            openThresholdEditDialog({
              category,
              title,
              ids: configIds
            });
          }}
        />
      </>}
    >
      {content}
    </Tooltip>
  );
}
