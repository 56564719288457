import type { Granularity } from 'types/common';

import { useMemo } from 'react';
import { Icon } from 'shared/Icon';
import { ValueBox } from 'shared/ValueBox';
import { Button2 as Button } from 'shared/Button';
import { formatCurrency } from 'helpers/formatter';

import { useCostOverviewStore } from './useCostOverviewStore';
import { useBudgetDialog } from 'store/budgetDialog';
import {useSaveViewDialog} from 'store/costLab';
import {useCumulative} from './hooks/cumulative';

export interface BudgetEditorProps {
  costViewId: number | null;
  granularity: Granularity;
}

export const BudgetEditor = (props: BudgetEditorProps) => {
  const {
    costViewId,
    granularity
  } = props;

  const openSaveViewDialog = useSaveViewDialog((store) => store.open);
  const openBudgetEditDialog = useBudgetDialog((store) => store.open);

  const {
    view
  } = useCostOverviewStore();

  const [cumulative] = useCumulative();

  const baseBudget = useMemo(() => {
    const budgetPeriod = granularity === 'quarter' ? 'quarterly' : 'monthly';
    const budgets = view?.budgets.filter((budget) => budget.period === budgetPeriod);

    return budgets?.length ? budgets[0] : null
  }, [view, granularity]);


  const timeFrame = granularity === 'quarter' ? 'Quarterly' : 'Monthly'

  return cumulative ? (
    <ValueBox
      label={`${timeFrame} Budget`}
    >
      {!baseBudget ? 
        <Button
          className='mt-1'
          iconLeft='circle-plus'
          theme='black'
          size='xs'
          layout='inline'
          onClick={() => {
            if (costViewId === null) {
              openSaveViewDialog();
            } else {
              openBudgetEditDialog({
                id: -1,
                name: 'budget',
                value: 0,
                period: granularity === 'quarter' ? 'quarterly' : 'monthly'
              });
            }
          }}
        >
          {costViewId === null ? 'Save View to Set Budget' : 'Set Budget'}
        </Button>
        :
        <Button
          className='!font-normal'
          layout='inline'
          theme='black'
          size='l'
          onClick={() => {
            openBudgetEditDialog(baseBudget);
          }}
        >
          {formatCurrency(baseBudget.value)}
          <Icon icon='edit' />
        </Button>
      }
    </ValueBox>
  ) : null;
};
