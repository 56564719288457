import type { RuleNodeTag } from 'types/rules';

import { useState } from 'react';
import { Select } from 'antd';
import { RuleRow } from 'shared/Filters';

export interface TagSelectorRowProps {
  value: RuleNodeTag;
  tags: { [key: string]: string[] };
  branchTagKeys: string[];
  onChange: (value: RuleNodeTag) => void;
  onRemove?: () => void;
}

export const TagSelectorRow = (props: TagSelectorRowProps) => {
  const {
    value,
    tags,
    branchTagKeys,
    onChange,
    onRemove
  } = props;

  const [tagKey, tagValue] = value;
  const [keySearchValue, setKeySearchValue] = useState(tagKey || '');
  const [valueSearchValue, setValueSearchValue] = useState(tagValue || '');

  const availableKeys = Object.keys(tags).filter((key) => !branchTagKeys.includes(key)) // || key && key === tagKey);

  const keyOptions: { label: string, value: string }[] = [
    ...(keySearchValue.trim() && !tags[keySearchValue] && !branchTagKeys.includes(keySearchValue) ? [{
      value: keySearchValue,
      label: `Add new key '${keySearchValue}'`
    }] : []),
    ...availableKeys.map((key) => ({
      label: key,
      value: key
    }))
  ];

  const valueOptions: { label: string, value: string }[] = tagKey && tags[tagKey] ? 
    [
      ...(valueSearchValue.trim() && !tags[tagKey].includes(valueSearchValue) ? [{
        value: valueSearchValue,
        label: `Add new value '${valueSearchValue}'`
      }] : []),
      ...tags[tagKey].map((value) => ({ label: value, value }))
    ] :
    [];

  return (
    <RuleRow
      onRemove={onRemove}
    >
      <div className='flex items-center gap-1 grow'>
        <Select
          value={tagKey}
          style={{
            flexBasis: '50%',
            flexShrink: 1,
            borderRadius: 4
          }}
          dropdownMatchSelectWidth={200}
          options={keyOptions}
          showSearch
          onSearch={setKeySearchValue}
          onChange={(newTagKey: string) => {
            onChange([newTagKey, null]);
          }}
        />

        <span className='font-medium text-[20px]'>:</span>

        <Select
          disabled={!tagKey}
          value={tagValue}
          style={{
            flexBasis: '50%',
            flexShrink: 1,
            borderRadius: 4
          }}
          dropdownMatchSelectWidth={200}
          options={valueOptions}
          showSearch
          onSearch={setValueSearchValue}
          onChange={(newTagValue: string) => {
            onChange([tagKey, newTagValue]);
          }}
        />
      </div>
    </RuleRow>
  );
}
