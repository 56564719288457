import type { HTMLAttributes } from 'react';

export interface PaneProps<TValue extends string = string> extends HTMLAttributes<HTMLDivElement> {
  value: TValue;
  selected?: boolean;
}

export const Pane = <TValue extends string = string>(props: PaneProps<TValue>) => {
  const {
    value,
    selected,
    className = '',
    ...rest
  } = props;

  return (
    <div
      className={`${className} ${selected ? '' : 'hidden'}`}
      {...rest}
    />
  );
};
