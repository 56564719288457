import type { Team } from 'types/teams';
import type { Action } from 'types/dataEntries';

export type Role = 'owner' | 'admin' | 'member';

export const JIRA_PROVIDERS = ['jira_cloud', 'jira_server'] as const;
export const INTEGRATION_PROVIDERS = ['slack', ...JIRA_PROVIDERS] as const;

export type JiraProvider = typeof JIRA_PROVIDERS[number];
export type IntegrationProvider = typeof INTEGRATION_PROVIDERS[number];

export const isJiraProvider = (provider: any): provider is JiraProvider => JIRA_PROVIDERS.includes(provider);
export const isIntegrationProvider = (provider: any): provider is IntegrationProvider => INTEGRATION_PROVIDERS.includes(provider);

export interface Integration {
  id: number;
  provider: IntegrationProvider;
  status: string;
}

export interface JiraSeverCreatePayload {
  jira_server_url: string;
  client_id: string;
  client_secret: string;
}


export interface User {
  id: number
  name: string
  email: string
  role: Role
  is_verified: boolean
  is_active: boolean
  is_deleted: boolean
  team: {
    id: number;
    name: string;
  } | null;
}

/*
export type CurrentOrgFF = {
  orgff_0001_can_switch_experience?: boolean
}

export type CurrentUserFF = {
  userff_0001_current_experience?: 'visibility_suite' | 'cost_savings'
}
*/
export type CurrentUser = {
  id: number
  name: string
  email: string
  is_verified: boolean
  has_password: boolean
  org_name: string
  is_org_owner: boolean
  org_details: object
  org_id: number
  org_is_data_ready: boolean
  org_is_enterprise: boolean
  org_onboarding_complete: boolean
  org_slug: string
  permissions: string[]
  role: Role
  team: Team | null
  //org_feature_flags: CurrentOrgFF
  //user_feature_flags: CurrentUserFF
  billing_details: {
    is_paying: false;
    trial_end_date: string;
  }
}

export interface UserUpdatePayload {
  name: string;
}

export interface UserUpdateEmailPayload {
  email: string;
  current_password: string;
}

export interface UserUpdatePasswordPayload {
  new_password: string;
  current_password: string;
}

export interface FeatureFlag {
  feature_flag_name: string;
  feature_flag_value: any;
}

export interface UserUpdateFeatureFlags {
  feature_flags: FeatureFlag[];
}

export interface OrgUpdatePayload {
  name?: string;
  slug?: string;
}

export interface UserDraft {
  name: string;
  email: string;
  role: Role;
  team_id: number | null;
  is_deleted?: boolean;
}

export type UserShort = Pick<User, 'id' | 'name' | 'email'>;

export type UserCreateAction = Action<'create'>;
export type UserUpdateAction = Action<'update'>;
export type UserDeleteAction = Action<'delete'>;
export type UserRecoverAction = Action<'recover'>;
export type UserAction = UserCreateAction | UserUpdateAction | UserDeleteAction | UserRecoverAction;
