import type { FilterValueType } from 'types/filters';
import type { RuleNode, RuleParentNode } from 'types/rules';

export const addChild = (parent: RuleParentNode, index: number = 0, child: RuleNode = {
  condition: {
    field_name: parent.field,
    comparator: 'equals',
    value_type: 'string',
    values: ['']
  }
}): RuleParentNode => {
  const children = [...parent.children];

  children.splice(index, 0, child);

  return {
    ...parent,
    children
  };
}

export const updateChild = (parent: RuleParentNode, index: number, child: RuleNode): RuleParentNode => {
  const children = [...parent.children];

  children.splice(index, 1, child);

  return {
    ...parent,
    children
  };
}

export const removeChild = (parent: RuleParentNode, index: number): RuleParentNode => {
  const children = [...parent.children];

  children.splice(index, 1);

  return {
    ...parent,
    children
  };
}

export const splitRule = (
  parent: RuleNode,
  field: string | null = null,
  valueType?: FilterValueType
): RuleNode => {
  const newRule = {
    ...parent,
    field
  };

  if (field !== null && valueType) {
    newRule.children = [
      {
        condition: {
          field_name: field,
          comparator: 'equals',
          ...(valueType === 'string' || valueType === 'string-regex' ? {
            value_type: valueType,
            values: ['']
          } : {
            value_type: valueType,
            values: [{}]
          })
        }
      },
      { rest: true }
    ]
  }

  return newRule;
}

export const unsplitRule = (parent: RuleNode): RuleNode => {
  const newRule = { ...parent };

  delete newRule.field;
  delete newRule.children;

  return newRule;
}
