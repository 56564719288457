import type { Opportunity } from 'types/savings';

import { message } from 'antd';
import { Button2 as Button } from 'shared/Button';
import { useSavingsStore } from 'store/savings';
import {renderOpportunitySlug} from 'helpers/savings';

export interface ArchiveActionButtonProps {
  opportunity: Opportunity;
}

export const ArchiveActionButton = (props: ArchiveActionButtonProps) => {
  const {
    opportunity
  } = props;

  const opportunityActions = useSavingsStore((store) => store.opportunityActions);
  const archiveOpportunity = useSavingsStore((store) => store.archiveOpportunity);

  return (
    <Button
      size='s'
      theme='gray'
      layout='inline'
      icon='archive'
      className='hover:!text-red'
      disabled={!!opportunityActions.get(opportunity.id)}
      onClick={() => {
        archiveOpportunity(opportunity.id)
          .then(() => {
            message.success(`OPP-${opportunity.secondary_id} archived`);
          })
          .catch(() => {
            message.error(`Unable to archive OPP-${opportunity.secondary_id}`);
          });
      }}
    >
      Archive {renderOpportunitySlug(opportunity)}
    </Button>
  );
}
