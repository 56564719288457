import qs from 'qs';

import {
  JiraProvider
} from 'types/users';

import {
  Thread,
  ThreadCreatePayload,
  ThreadUpdatePayload,

  Opportunity,
  OpportunityCreatePayload,
  OpportunityUpdatePayload,

  JiraProject,
  JiraIssue,
  JiraIssueCreatePayload,
  JiraUser,
  GitHubPR,
  CostImpactSummaryParams,
  CostImpactSummaryParamsChunk,
  CostImpactSummary,
  SavedAndWastedParams,
  SavedAndWastedParamsChunk,
  SavedAndWasted,

  OpportunityFiltersParams,
  OpportunityFiltersResponse,
  OpportunitiesParams,
  AutomationPayload,
  JobLogEntry
} from 'types/savings';

import api from 'helpers/api';

import {
  costImpactSummaryParamsToRequestParams,
  costImpactSummaryParamsToRequestParamsChunk,
  savedAndWastedParamsToRequestParams,
  savedAndWastedParamsToRequestParamsChunk,
} from 'helpers/savings/opportunities/summaryRequest';

import {
  opportunityFiltersParamsToRequestParams
} from 'helpers/savings/opportunities/filterRequest';
import {opportunitiesParamsToRequestParams} from 'helpers/savings/opportunities/opportunitiesRequest';

// Opportunities
export const fetchOpportunities = (params: { [key: string]: string | number } = {}) =>
  api.post<Opportunity[]>('/cost-optimization/opportunities/list', null, { params }).then(({ data }) => data);

export const fetchOpportunity = (id: number) => api.get<Opportunity>(`/cost-optimization/opportunities/${id}`);

export const createOpportunity = (payload: OpportunityCreatePayload) =>
  api.post<Opportunity>('/cost-optimization/opportunities', payload);

export const updateOpportunity = (id: number, payload: OpportunityUpdatePayload) =>
  api.patch<Opportunity>(`/cost-optimization/opportunities/${id}`, payload);

export const updateOpportunityPriorities = (params: {
  opportunity_type_id: string
}, payload: OpportunityUpdatePayload) => api.patch<Opportunity[]>('/cost-optimization/opportunities/priority', payload, {
  params
});

// Threads
export const fetchThreads = () => api.get<Thread[]>('/cost-optimization/threads').then(({ data }) => data);

export const createThread = (payload: ThreadCreatePayload) => api.post<Thread>('/cost-optimization/threads', payload).then(({ data }) => data);

export const updateThread = (id: number, payload: ThreadUpdatePayload) => api.patch<Thread>(`/cost-optimization/threads/${id}`, payload).then(({ data }) => data);

export const deleteThread = (id: number) => api.delete(`/cost-optimization/threads/${id}`) as Promise<void>;

export const recoverThread = (id: number) => api.post<Thread>(`/cost-optimization/threads/${id}/recover`).then(({ data }) => data);

export const assignThreadTeam = (threadId: number, teamId: number) => api.post<Thread>(`/cost-optimization/threads/${threadId}/team/assign`, {
  assigned_team_id: teamId
});

export const assignThreadUser = (threadId: number, userId: number) => api.post<Thread>(`/cost-optimization/threads/${threadId}/user/assign`, {
  assigned_user_id: userId
});

export const threadCallback = (issueType: string, action: string, token: string) => api
  .get(`/cost-optimization/threads/${issueType}/${action}?token=${encodeURIComponent(token)}`) as Promise<void>;

// JIRA
export const fetchJiraProjects = (provider: JiraProvider) => api.get<JiraProject[]>(`/cost-optimization/${provider}/projects`).then(({ data }) => data);

export const fetchJiraUsers = (provider: JiraProvider, jira_project_key: string) => api.get<JiraUser[]>(`/cost-optimization/${provider}/users`, {
  params: {
    jira_project_key
  }
});

export const fetchJiraWebhook = (provider: JiraProvider) => api.get<{ webhook_url: string }>(`/cost-optimization/${provider}/webhook`);

export const createJiraIssue = (provider: JiraProvider, payload: JiraIssueCreatePayload) => api.post<JiraIssue>(`/cost-optimization/${provider}/issues`, payload).then(({ data }) => data);

export const fetchJiraIssues = (thread_id: number) => api.get<JiraIssue[]>('/cost-optimization/issues', {
  params: { thread_id }
});

// GitHub
export const fetchGitHubPRs = (thread_id: number) => api.get<GitHubPR[]>('/cost-optimization/github-prs', { params: { thread_id }}).then(({ data }) => data);

// Summary
export const fetchCostImpactSummary = (params: CostImpactSummaryParams) => {
  const [query, body] = costImpactSummaryParamsToRequestParams(params);

  return api.post<CostImpactSummary[]>('/cost-optimization/opportunities/savings', body, {
    params: query,
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
  });
}

export const fetchCostImpactSummaryChunk = (params: CostImpactSummaryParamsChunk) => {
  const queries = costImpactSummaryParamsToRequestParamsChunk(params);
  const requests = queries.map(([query, body]) => {
    return api.post<CostImpactSummary[]>('/cost-optimization/opportunities/savings', body, {
      params: query,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
    })
  })
  return Promise.all(requests);
}

export const fetchSavedAndWastedSummary = (params: SavedAndWastedParams) => {
  const [query, body] = savedAndWastedParamsToRequestParams(params);

  return api.post<SavedAndWasted[]>('/cost-optimization/opportunities/computed-savings', body, {
    params: query,
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
  });
}

export const fetchSavedAndWastedSummaryChunk = (params: SavedAndWastedParamsChunk) => {
  const queries = savedAndWastedParamsToRequestParamsChunk(params);
  const requests = queries.map(([query, body]) => {
    return api.post<SavedAndWasted[]>('/cost-optimization/opportunities/computed-savings', body, {
      params: query,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
    })
  })
  return Promise.all(requests);
}

// Filters

export const fetchOpportunityFilters = (params: OpportunityFiltersParams) => {
  const [query, body] = opportunityFiltersParamsToRequestParams(params);

  return api.post<OpportunityFiltersResponse>('/cost-optimization/opportunities/constructor', body, {
    params: query,
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
  });
}

// New opportunities

export const fetchOpportunitiesList = (params: OpportunitiesParams) => {
  const [query, body] = opportunitiesParamsToRequestParams(params);

  return api.post<Opportunity[]>('/cost-optimization/opportunities/list', body, {
    params: query,
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' })
  });
}

export const assignOpportunityTeam = (oppId: number, teamId: number) => api.post<Opportunity>(`/cost-optimization/opportunities/${oppId}/team/assign`, {
  assigned_team_id: teamId
});

export const automateOpportunity = (payload: AutomationPayload) => api.post('/opportunities/automation/batch', payload);

export const fetchJobLog = (opportunityId: number | null) => api.get<JobLogEntry[]>(`/opportunities/automation/job/log/`, {
  params: {
    opportunity_id: opportunityId
  }
});
