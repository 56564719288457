import type { CostFilterKeyProps } from 'types/costViews';
import type { DateFilterKeyProps } from 'types/dateFilter';
import { TagListFilterKeyProps } from 'types/tagViews';
import { Set as ImmutableSet } from 'immutable';

export const defaultDateFilterKeyProps: DateFilterKeyProps = {
  startDate: '',
  endDate: '',
  granularity: 'day'
};

export const defaultCostFilterKeyProps: CostFilterKeyProps = {
  costType: 'unblended_cost',
  costDimensions: ImmutableSet(),
  costAmortization: false
};

export const defaultTagListFilterKeyProps: TagListFilterKeyProps = {
  tagList: ImmutableSet(),
}
