import { useMemo, useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { formatCurrency, formatPercent, formatRange } from 'helpers/formatter';
import { createNullableNumbersComparator } from 'helpers/sort';

import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { Icon } from 'shared/Icon/Icon';
import { Page } from 'shared/Page';
import { Button } from 'shared/Button';
import { Legend } from 'shared/Legend';
import { Loading } from 'shared/Loading';
import { DateText } from 'shared/DateText'
import { PeriodChart } from 'shared/PeriodChart';
import { NumberCard } from './NumberCard';
import { ArrowButton } from './ArrowButton';
import { LinkSection } from './LinkSection';
import { DiagramSection } from './DiagramSection';
import { Section, SectionFooter } from 'shared/Section';
import { ScrollPanes, ScrollPane, ScrollPanesRef } from 'shared/ScrollPanes';

import { useUnitMetricsStore } from 'store/unitMetrics';
import { useUnitMetricsLibraryStore } from 'store/unitMetricsLibrary';
import { useCostViewsStore } from 'store/costViews';
import { useCostViewChart } from 'store/costViewChart';
import { useCostViewLibraryStore } from 'store/costViewLibrary';

const compareUnitMetrics = createNullableNumbersComparator('wow_change');
const compareCostViews = createNullableNumbersComparator('wow_change');

const INDEXES = [0, 1, 2];

type TabValue = 'overview' | 'cost' | 'unit';

export const SummaryDashboard = () => {
  const {
    unitMetrics: unitMetricsLibrary,
    fetchUnitMetricsLibrary,
    status: unitMetricsLibraryStatus,
  } = useUnitMetricsLibraryStore();

  const DEFAULT_GRANULARITY = 'day'
  const DEFAULT_COSTTYPE = 'public_cost'

  const {
    costViews: costViewLibrary,
    fetchCostViewLibrary,
    status: costViewLibraryStatus
  } = useCostViewLibraryStore();

  const startMoment =  moment().add(-8, 'days')
  const endMoment = moment().add(-1, 'days')


  const [startDate, endDate] = useMemo(() => [
    startMoment.format('YYYY-MM-DD'),
    endMoment.format('YYYY-MM-DD'),
  ], []);

  const prevStartMoment = startMoment.clone().subtract(endMoment.diff(startMoment, 'days') + 1, 'days')
  const prevEndDMoment = endMoment.clone().subtract(endMoment.diff(startMoment, 'days') + 1, 'days')

  const last30DaysTotalChart = useCostViewChart({
    startDate: moment().add(-30, 'days').format('YYYY-MM-DD'),
    endDate: moment().add(-1, 'days').format('YYYY-MM-DD'),
    granularity: 'day',
    costType: 'unblended_cost',
    costDimensions: [],
    costAmortization: false,
    filter: {
      src: 'cur',
      filter: { operator: 'or', condition: [] }
    }
  });

  const last30DaysStart = moment().add(-30, 'days');
  const last30DaysEnd = moment().add(-1, 'days');
  const prevLast30DaysStart = last30DaysStart.clone().subtract(last30DaysEnd.diff(last30DaysStart, 'days') + 1, 'days')
  const prevLast30DaysEnd = last30DaysEnd.clone().subtract(last30DaysEnd.diff(last30DaysStart, 'days') + 1, 'days')

  const thisMonthStart = moment().startOf('month');
  const thisMonthEnd = moment().endOf('month');

  const lastMonthStart = moment().add(-1, 'month').startOf('month');
  const lastMonthEnd = moment().add(-1, 'month').endOf('month');

  const {
    getChartDataEntry,
    fetchChartData: fetchUnitChartData
  } = useUnitMetricsStore();

  const {
    getChartDataEntry: getCostChartDataEntry,
    fetchChartData: fetchCostChartData
  } = useCostViewsStore();

  const topUnitMetrics = useMemo(
    () => [...unitMetricsLibrary]
      .sort(compareUnitMetrics)
      .reverse()
      .slice(0, 5),
    [unitMetricsLibrary]
  );

  const topCostViews = useMemo(
    () => [...costViewLibrary]
      .sort(compareCostViews)
      .reverse()
      .slice(0, 5),
    [costViewLibrary]
  );

  useEffect(() => {
    fetchUnitMetricsLibrary();
    fetchCostViewLibrary();
  }, []);

  useEffect(() => {
    topUnitMetrics.forEach(({ id }) => {
      fetchUnitChartData(id, startDate, endDate, DEFAULT_GRANULARITY);
    });
  }, [topUnitMetrics, startDate, endDate]);

  useEffect(() => {
    topCostViews.forEach(({ id }) => {
      fetchCostChartData(id, startDate, endDate, DEFAULT_GRANULARITY, DEFAULT_COSTTYPE);
    });
  }, [topCostViews, startDate, endDate]);

  const chartDataEntries = topUnitMetrics.map(({ id }) => getChartDataEntry(id, startDate, endDate));
  const costChartDataEntries = topCostViews.map(({ id }) => getCostChartDataEntry(id, startDate, endDate));

  const [currentTab, setCurrentTab] = useState<TabValue>('overview');
  const scrollPanesRef = useRef<ScrollPanesRef<string> | null>(null);

  const handleTabsChange = (newCurrentTab: 'overview' | 'unit' | 'cost') => {
    scrollPanesRef.current?.scrollToPane(newCurrentTab);
  }

  return (
    <Page>
      <Page.Head>
        <h1 className='font-medium'>Summary Dashboard</h1>

        <div className='flex'>
          {(['overview', 'cost', 'unit'] as TabValue[]).map((tab, index, array) => (
            <Button
              key={tab}
              size='sm'
              type={tab === currentTab ? 'primary' : 'secondary'}
              className={`ml-[-1px] ${index > 0 ? 'rounded-l-[0]' : ''} ${index < array.length - 1 ? 'rounded-r-[0]' : ''}`}
              label={{
                overview: 'Overview',
                cost: 'Cost Views',
                unit: 'Unit Metrics'
              }[tab]}
              onClick={() => {
                handleTabsChange(tab);
              }}
            />
          ))}
        </div>
    </Page.Head>

      <ScrollPanes value={currentTab} onChange={setCurrentTab as (value: string) => void} ref={scrollPanesRef}>
        <ScrollPane value='overview' offset={-65}>
          <h2 className='mb-[20px] ml-[15px] text-[22px] font-semibold'>
            Overview
          </h2>

          <Section className='mb-[15px]'>
            <div className='grid grid-cols-3 gap-[15px] p-[15px] max-w-[900px]'>
              <NumberCard
                description='Last 30 days'
                startMoment={last30DaysStart}
                endMoment={last30DaysEnd}
              />
              <NumberCard
                description='This month'
                startMoment={thisMonthStart}
                endMoment={thisMonthEnd}
              />
              <NumberCard
                description='Last month'
                startMoment={lastMonthStart}
                endMoment={lastMonthEnd}
              />
            </div>
          </Section>

          <Section className='relative mb-[15px]'>
            <div className='text-[16px] px-[20px] h-[50px] flex items-center font-medium'>
              <Link to={`/costs-overview`}>
                Total Spend for Last 30 Days
              </Link>
              <Button
                type='tertiary'
                size='sm'
                to='/costs-overview'
                className='ml-auto mr-[-20px]'
                label={
                  <span className='flex items-center'>
                    View All
                    <Icon icon='arrow-right' className='w-[14px] h-[14px] ml-[4px]' />
                  </span>
                }
              />
            </div>

            <div className='h-[250px] mt-[-4px]'>
              {last30DaysTotalChart.status === 'success' && (
                <PeriodChart
                  data={last30DaysTotalChart.data}
                  granularity='day'
                  currentKey='current_timeframe_cost'
                  previousKey='previous_timeframe_cost'
                />
              )}
            </div>

            <SectionFooter>
              <Legend
                series={[
                  {
                    name: 'Current',
                    color: '#00b4d8',
                    subtitle: formatRange(last30DaysStart, last30DaysEnd, 'day')
                  },
                  {
                    name: 'Previous',
                    color: '#DA5665',
                    subtitle: formatRange(prevLast30DaysStart, prevLast30DaysEnd, 'day')
                  }
                ]}
              />
            </SectionFooter>
            {last30DaysTotalChart.status === 'loading' && <Loading />}
          </Section>

          <div className='grid grid-cols-3 gap-[15px]'>
            <DiagramSection
              icon='region'
              title='Regions'
              breakdown='regions'
            />

            <DiagramSection
              icon='account'
              title='Accounts'
              breakdown='accounts'
            />

            <DiagramSection
              icon='service'
              title='Services'
              breakdown='services'
            />
          </div>
        </ScrollPane>

        <ScrollPane value='cost' offset={-40}>
          <div className='flex pt-[40px] mb-[20px]'>
            <h2 className='mr-auto ml-[15px] text-[22px] font-semibold'>Cost Views</h2>
            <Button
              type='tertiary'
              size='sm'
              to='/costs-overview/library'
              label={
                <span className='flex items-center'>
                  View All
                  <Icon icon='arrow-right' className='w-[14px] h-[14px] ml-[4px]' />
                </span>
              }
            />
          </div>

          <div className='relative grid grid-cols-2 gap-[15px]'>
            {INDEXES.map((index) => {
              const costView = topCostViews[index];
              const chartDataEntry = costChartDataEntries[index];

              const libraryLoaded = costViewLibraryStatus === 'success';

              if (libraryLoaded && index >= topCostViews.length) {
                return null;
              }

              const chartLoaded = chartDataEntry?.status === 'success';

              const loading = !libraryLoaded || !chartLoaded;

              return (
                <Section className='relative h-[290px]' key={index}>
                  <div className='text-[16px] px-[20px] h-[50px] flex items-center font-medium mb-[-4px]'>
                    {costView && <>
                      {costView.name}
                      <ArrowButton to={`/costs-overview/${costView.id}`} />
                    </>}
                  </div>
                  {chartLoaded && (
                    <PeriodChart
                      height={220}
                      data={chartDataEntry.chartData}
                      granularity={DEFAULT_GRANULARITY}
                      currentKey='current_timeframe_cost'
                      previousKey='previous_timeframe_cost'
                    />
                  )}
                  {loading && <Loading />}
                  <div className='mb-5 ml-5'>
                    <div className='flex'>
                      <DateText
                        description={'Current Period'}
                        startMoment={startMoment}
                        endMoment={endMoment}
                      />
                      <div  className='ml-5'>
                        <DateText
                          description={'Previous Period'}
                          startMoment={prevStartMoment}
                          endMoment={prevEndDMoment}
                        />
                      </div>
                    </div>
                  </div>
                </Section>
              );
            })}
            
            <LinkSection
              to='/costs-overview?filtersOpened=true'
            >
              Create New Cost View
            </LinkSection>

            <Section className='relative col-span-2'>
              <div className='text-[16px] px-[20px] h-[50px] flex items-center font-medium'>
                <div className='mr-auto'>
                  Top {topCostViews.length || ''} Cost Views
                </div>
              </div>

              <div className='px-[20px] pb-[20px] relative'>
                <Table
                  size='small'
                  dataSource={topCostViews}
                  rowKey={({ id }) => id}
                  pagination={false}
                  columns={[
                    {
                      key: 'name',
                      dataIndex: 'name',
                      title: 'View Name',
                      render: (name: string, { id }: { id: number }) => <Link to={`/costs-overview/${id}`}>{name}</Link>
                    },
                    {
                      key: 'total',
                      title: 'Last 8 days',
                      dataIndex: 'total',
                      render: (total: null | number) => total === null ? 'n/a' : formatCurrency(total)
                    },
                    {
                      key: 'prev_total',
                      title: 'Previous Last 8 days',
                      dataIndex: 'prev_total',
                      render: (prevTotal: null | number) => prevTotal === null ? 'n/a' : formatCurrency(prevTotal)
                    },
                    {
                      key: 'wow_change',
                      title: 'Period-over-period Change %',
                      dataIndex: 'wow_change',
                      render: (_, metric) => metric.prev_total === 0 || !metric.wow_change ? 'n/a' : formatPercent(metric.wow_change - 1)
                    },
                  ]}
                />
              </div>
              {costViewLibraryStatus === 'loading' && <Loading />}
            </Section>
          </div>
        </ScrollPane>

        <ScrollPane value='unit' offset={-40}>
          <div className='flex pt-[40px] mb-[20px]'>
            <h2 className='mr-auto ml-[15px] text-[22px] font-semibold'>Unit Metrics</h2>
            <Button
              type='tertiary'
              size='sm'
              to='/unit-metrics/library'
              label={
                <span className='flex items-center'>
                  View All
                  <Icon icon='arrow-right' className='w-[14px] h-[14px] ml-[4px]' />
                </span>
              }
            />
          </div>

          <div className='relative grid grid-cols-2 gap-[15px]'>
            {INDEXES.map((index) => {
              const unitMetric = topUnitMetrics[index];
              const chartDataEntry = chartDataEntries[index];

              const libraryLoaded = unitMetricsLibraryStatus === 'success';

              if (libraryLoaded && index >= topUnitMetrics.length) {
                return null;
              }

              const chartLoaded = chartDataEntry?.status === 'success';

              const loading = !libraryLoaded || !chartLoaded;

              return (
                <Section className='relative h-[290px]' key={index}>
                  <div className='text-[16px] px-[20px] h-[50px] flex items-center font-medium mb-[-4px]'>
                    {unitMetric && (
                      <>
                        {unitMetric.name}
                        <ArrowButton to={`/unit-metrics/${unitMetric.id}`} />
                      </>
                    )}
                  </div>
                  {chartLoaded && (
                    <PeriodChart
                      height={220}
                      data={chartDataEntry.chartData}
                      granularity={DEFAULT_GRANULARITY}
                      currentKey='current_timeframe_unit_metric'
                      previousKey='previous_timeframe_unit_metric'
                    />
                  )}
                  {loading && <Loading />}
                  <div className='mb-5 ml-5'>
                    <div className='flex'>
                      <DateText
                        description={'Current Period'}
                        startMoment={startMoment}
                        endMoment={endMoment}
                      />
                      <div  className='ml-5'>
                        <DateText
                          description={'Previous Period'}
                          startMoment={prevStartMoment}
                          endMoment={prevEndDMoment}
                        />
                      </div>
                    </div>
                  </div>
                </Section>
              );
            })}
            
              <LinkSection
                to='/unit-metrics'
              >
                Create New Unit Metric
              </LinkSection>

            <Section className='relative col-span-2 mb-[15px]'>
              <div className='text-[16px] px-[20px] h-[50px] flex items-center font-medium'>
                <div className='mr-auto'>
                  Top {topUnitMetrics.length} Unit Metrics
                </div>
              </div>

              <div className='px-[20px] pb-[20px]'>
                <Table
                  size='small'
                  dataSource={topUnitMetrics}
                  rowKey={({ id }) => id}
                  pagination={false}
                  columns={[
                    {
                      key: 'name',
                      dataIndex: 'name',
                      title: 'Metric Name',
                      render: (name: string, { id }: { id: number }) => <Link to={`/unit-metrics/${id}`}>{name}</Link>
                    },
                    {
                      key: 'daily_average',
                      title: 'Last 8 Days',
                      dataIndex: 'daily_average',
                      sorter:  createNullableNumbersComparator('daily_average'),
                      render: (dailyAverage, metric) => <div className='truncate'>{!metric.library_data ? 'New or edited library entries take up to 6 hours to update' : !dailyAverage ? '' : formatCurrency(dailyAverage)}</div>
                    },
                    {
                      key: 'prev_daily_average',
                      title: 'Previous Last 8 days',
                      dataIndex: 'prev_daily_average',
                      sorter:  createNullableNumbersComparator('prev_daily_average'),
                      render: (prevDailyAverage, metric) => !metric.library_data || !prevDailyAverage ? '' : formatCurrency(prevDailyAverage)
                    },
                    {
                      key: 'wow_change_daily',
                      title: 'Period-over-period Change %',
                      dataIndex: 'wow_change',
                      sorter: createNullableNumbersComparator('wow_change_daily'),
                      render: (wowChange, metric) => <div className='truncate'>{metric.prev_daily_average === 0 ? 'Undefined (divide by 0)' : !wowChange ? '' : formatPercent(wowChange)}</div>
                    },
                  ]}
                />
              </div>
              {unitMetricsLibraryStatus === 'loading' && <Loading />}
            </Section>
          </div>
        </ScrollPane>
      </ScrollPanes>
    </Page>
  );
};
