import { useSSOSettingsStore } from 'store/ssoSettings';
import { API_HOST } from 'helpers/api';
import { Button } from 'shared/Button';

export const ManageButton = () => {
  const settingsCreated = useSSOSettingsStore((state) => state.settingsCreated);
  const connectionCreated = useSSOSettingsStore((state) => state.connectionCreated);

  return (
    <div className='flex pt-[30px] mt-[30px] mb-[-12px] shadow-border-t justify-center col-span-2'>
      {settingsCreated ? (
        <Button
          size='lg'
          style='caladon'
          href={`${API_HOST}/settings/sso/manage`}
          label={connectionCreated ? 'Manage Connection' : 'Set Up Connection'}
        />
      ) : (
        <p className='text-subtitle-2'>
          Provision SSO settings in order to set up a connection
        </p>
      )}
    </div>
  );
};
