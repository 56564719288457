import { memo } from 'react';
import { ExportToCsv } from 'export-to-csv';
import { Button2 as Button } from 'shared/Button';
import { ReactComponent as ShareIcon } from 'assets/images/Share.svg'

export interface ExportToCsvButtonProps {
  fileName: string;
  data: any;
}

export const ExportToCsvButton = memo((props: ExportToCsvButtonProps) => {
  const {
    fileName,
    data
  } = props;

  const handleClick = () => {
    const options = {
      title: fileName,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    }

    const csvExporter = new ExportToCsv(options)

    csvExporter.generateCsv(data)
  }

  return (
    <Button
      size='s'
      theme='link'
      layout='inline'
      onClick={handleClick}
    >
      <ShareIcon />
      Export CSV
    </Button>
  );
});
