import { CostViewFilter } from 'types/costViews';

import moment from 'moment';
import { useMemo } from 'react';
import { Section } from 'shared/Section';
import { BarChart } from 'shared/Chart';
import { StatusOverlay } from 'shared/Overlay';
import { useCostViewChart } from 'store/costViewChart';
import { useSavedAndWastedSummary } from 'store/savings';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { formatCurrency } from 'helpers/formatter';
import {usePageFilters} from '../hooks/pageFilters';
import {identity} from 'helpers/utils';

const EMPTY_FILTER: CostViewFilter = {
  src: 'cur',
  filter: {
    operator: 'or',
    condition: []
  }
};

export interface SavingsChartSectionProps {
  className?: string;
}

export const SavingsChartSection = (props: SavingsChartSectionProps) => {
  const {
    className = ''
  } = props;

  const endDate = useMemo(() => moment().add(-1, 'day').startOf('day'), []);
  const startDate = useMemo(() => endDate.clone().add(-5, 'month').startOf('month'), []);

  const costViewChart = useCostViewChart({
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
    granularity: 'month',
    filter: EMPTY_FILTER,
    costType: 'unblended_cost',
    costDimensions: [],
    costAmortization: false
  });

  const pageFilters = usePageFilters();
  
  const savedAndWasted = useSavedAndWastedSummary(useDerivedDataEntry({
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
    granularity: 'monthly' as const,
    prefilter: {},
    pageFilters,
    tableFilters: {},
  }, identity));

  const chartData = useDerivedDataEntry({
    costViewChart,
    savedAndWasted,
  }, ({
    costViewChart,
    savedAndWasted,
  }) => {
    return costViewChart
      .filter((item) => item.current_timeframe_cost !== null)
      .map((item) => {
        const {
          current_timeframe_cost: spend,
          date: date
        } = item;

        const monthStart = moment(date).startOf('month');

        const month = savedAndWasted.find((data) => 
          data.start_date === monthStart.format('YYYY-MM-DD')
        );

        if (!month) {
          return { 
            date, 
            spend: 0, 
            wasted: 0, 
            saved: 0,
          }
        }

        return {
          date,
          spend,
          waste: month.wasted,
          savings: month.saved,
        };
      });
    });

  return (
    <Section className={`p-[20px] relative ${className}`}>
      <h2 className='text-subtitle-1 font-medium mb-[20px]'>
        Total Spend + Waste/Savings Chart
      </h2>

      {chartData.status === 'success' && (
        <div className='h-[240px]'>
          <BarChart
            data={{
              datasets: [
                {
                  label: 'Spend',
                  backgroundColor: '#F5D9D3',
                  data: chartData.data,
                  parsing: {
                    xAxisKey: 'date',
                    yAxisKey: 'spend'
                  }
                },
                {
                  label: 'Waste',
                  backgroundColor: '#F2D8C2',
                  data: chartData.data,
                  parsing: {
                    xAxisKey: 'date',
                    yAxisKey: 'waste'
                  }
                },
                {
                  label: 'Savings',
                  backgroundColor: '#D4E8D1',
                  data: chartData.data,
                  parsing: {
                    xAxisKey: 'date',
                    yAxisKey: 'savings'
                  },
                },
              ]
            }}
            options={{
              layout: {
                padding: 0,
              },
              elements: {
                bar: {
                  borderColor: 'rgba(0,0,0,0)',
                  borderWidth: 6,
                  inflateAmount: 3
                }
              },
              scales: {
                x: {
                  type: 'time',
                  grid: { display: false },
                  time: {
                    unit: 'month',
                    tooltipFormat: 'MMMM YYYY'
                  },
                  stacked: true
                },
                y: {
                  grid: { display: false },
                  ticks: { display: false },
                  stacked: true
                }
              },
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom'
                },
                tooltip: {
                  callbacks: {
                    label: (ctx) => `${ctx.dataset.label}: ${formatCurrency(ctx.parsed.y)}`
                  }
                }
              }
            }}
          />
        </div>
      )}

      <StatusOverlay status={chartData.status} />
    </Section>
  );
}
