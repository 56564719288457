import type { Sort, SortDir } from 'types/common';
import { createTypeGuardFromArray } from 'helpers/guards';
import { ListDataFilterValue, ListDataFilterOptions, RangeDataFilterOptions, RangeDataFilterValue } from 'types/dataFilters';
import {ThreadStatus} from './threads';

export const THREAD_SORT_FIELDS = [
  'id',
  'name',
  'costImpactSum',
  'opportunitiesCount',
  'status',
  'createdAt'
] as const;

export type ThreadSortField = typeof THREAD_SORT_FIELDS[number];

export const DEFAULT_THREAD_SORT_FIELD: ThreadSortField = 'costImpactSum';

export const isThreadSortField = createTypeGuardFromArray(THREAD_SORT_FIELDS);

export type ThreadSort = Sort<ThreadSortField>;

export const DEFAULT_THREAD_SORT_DIRS: Record<ThreadSortField, SortDir> = {
  id: 'ascend',
  name: 'ascend',
  costImpactSum: 'descend',
  opportunitiesCount: 'descend',
  status: 'ascend',
  createdAt: 'descend'
};

export const DEFAULT_THREAD_SORT = {
  field: DEFAULT_THREAD_SORT_FIELD,
  dir: DEFAULT_THREAD_SORT_DIRS[DEFAULT_THREAD_SORT_FIELD]
};

export interface ThreadFilterValue {
  estimatedSavings: RangeDataFilterValue<number>; 
  savingsPotential: RangeDataFilterValue<number>;
  opportunities: RangeDataFilterValue<number>;
  status: ListDataFilterValue<ThreadStatus>;
}

export interface ThreadFilterOptions {
  estimatedSavings: RangeDataFilterOptions<number>; 
  savingsPotential: RangeDataFilterOptions<number>;
  opportunities: RangeDataFilterOptions<number>;
  status: ListDataFilterOptions<ThreadStatus>;
}

export const THREAD_STATUS_GROUPS = ['active', 'done', 'rejected', 'deleted', 'all'] as const;

export type ThreadStatusGroup = typeof THREAD_STATUS_GROUPS[number];

export const DEFAULT_THREAD_STATUS_GROUP = 'active';

export const isThreadStatusGroup = createTypeGuardFromArray(THREAD_STATUS_GROUPS);
