import type { DataEntry } from 'types/dataEntries';
import type { TagKey, TagValue } from 'types/tags';

import create from 'zustand';

import { fetchTagKeys, fetchTagValues } from 'services/tags';

export interface TagsState {
  keys: DataEntry<TagKey[]>;
  values: Record<number, DataEntry<TagValue[]>>;
}

const DEFAULT_STATE: TagsState = {
  keys: { status: 'idle' },
  values: {}
};

export interface TagsActions {
  fetchKeys: () => void;
  getKeys: () => DataEntry<TagKey[]>;

  fetchValues: (id: number) => void;
  getValues: (id: number) => DataEntry<TagValue[]>;
}

export interface TagsStore extends TagsState, TagsActions {
}

export const useTagsStore = create<TagsStore>((set, get) => ({
  ...DEFAULT_STATE,

  fetchKeys: () => {
    let { keys } = get();
    if (keys.status === 'idle') {
      set({ keys: { status: 'loading' }});
      fetchTagKeys().then(({ data }) => {
        set({
          keys: {
            status: 'success',
            data
          }
        });
      });
    }
  },

  getKeys: () => {
    const {
      keys,
      fetchKeys
    } = get();

    if (keys.status === 'idle') {
      fetchKeys();
    }

    return get().keys;
  },

  fetchValues: (id: number) => {
    let { values } = get();

    if (!values[id]) {
      set({
        values: {
          ...values,
          [id]: { status: 'loading' }
        }
      });

      fetchTagValues(id).then(({ data }) => {
        set({
          values: {
            ...values,
            [id]: {
              status: 'success',
              data
            }
          }
        });
      });
    }
  },

  getValues: (id: number) => {
    const {
      values,
      fetchValues
    } = get();

    if (!values[id]) {
      fetchValues(id);
    }

    return get().values[id];
  },
}));
