import { ReactComponent as DownloadIcon } from 'assets/images/Download.svg'
import { ReactComponent as NotesIcon } from 'assets/images/Notes.svg'
import { ReactComponent as CaretDown } from 'assets/images/CaretDown.svg'
import { ReactComponent as CaretUp } from 'assets/images/CaretUp.svg'
import { ReactComponent as Check } from 'assets/images/Checkmark.svg'
import { ReactComponent as Close } from 'assets/images/Close.svg'
import { ReactComponent as CloseCircle } from 'assets/images/CloseCircle.svg'
import { ReactComponent as Account } from 'assets/images/Account.svg'
import { ReactComponent as Tag } from 'assets/images/Tag.svg'
import { ReactComponent as TagFilled } from 'assets/images/TagFilled.svg'
import { ReactComponent as Region } from 'assets/images/Region.svg'
import { ReactComponent as Service } from 'assets/images/Service.svg'
import { ReactComponent as Delete } from 'assets/images/Delete.svg'
import { ReactComponent as Edit } from 'assets/images/Edit.svg'
import { ReactComponent as PieChart } from 'assets/images/PieChart.svg'
import { ReactComponent as BarChart } from 'assets/images/BarChart.svg'
import { ReactComponent as BarsFilter } from 'assets/images/Filter.svg'
import { ReactComponent as CurlyArrow } from 'assets/images/CurlyArrow.svg'
import { ReactComponent as CheckCircle } from 'assets/images/CheckCircle.svg'
import { ReactComponent as Arrows } from 'assets/images/Arrows.svg'
import { ReactComponent as ArrowLeft } from 'assets/images/arrow-left.svg'
import { ReactComponent as ArrowRight } from 'assets/images/arrow-right.svg'
import { ReactComponent as ArrowRightCircle } from 'assets/images/arrow-right-filled.svg'
import { ReactComponent as ArrowDown } from 'assets/images/ArrowDown.svg'
import { ReactComponent as ArrowDownCurve } from 'assets/images/ArrowDownCurve.svg'
import { ReactComponent as ArrowBack } from 'assets/images/ArrowBack.svg'
import { ReactComponent as Logout } from 'assets/images/Logout.svg'
import { ReactComponent as Document } from 'assets/images/Document.svg'
import { ReactComponent as DocumentFilled } from 'assets/images/DocumentFilled.svg'
import { ReactComponent as Alert } from 'assets/images/Alert.svg'
import { ReactComponent as AlertFilled } from 'assets/images/AlertFilled.svg'
import { ReactComponent as CirclePlus } from 'assets/images/CirclePlus.svg'
import { ReactComponent as AsideLayout } from 'assets/images/AsideLayout.svg'
import { ReactComponent as ArrowDownOnOff } from 'assets/images/ArrowDownOnOff.svg'
import { ReactComponent as DrillDown } from 'assets/images/DrillDown.svg'
import { ReactComponent as Metric } from 'assets/images/Metric.svg'
import { ReactComponent as Search } from 'assets/images/Search.svg'
import { ReactComponent as Minus } from 'assets/images/Minus.svg'
import { ReactComponent as SummaryDashboard } from 'assets/images/SummaryDashboard.svg'
import { ReactComponent as Slack } from 'assets/images/Slack.svg'
import { ReactComponent as Jira } from 'assets/images/Jira.svg'
import { ReactComponent as Info } from 'assets/images/Info.svg';
import { ReactComponent as Tree } from 'assets/images/Tree.svg';
import { ReactComponent as Settings } from 'assets/images/Settings.svg';
import { ReactComponent as SquaresFilled } from 'assets/images/SquaresFilled.svg';
import { ReactComponent as DollarFilled } from 'assets/images/DollarFilled.svg';
import { ReactComponent as Undo } from 'assets/images/Undo.svg';
import { ReactComponent as AWS } from 'assets/images/Amazon_Web_Services_Logo.svg';
import { ReactComponent as GCP } from 'assets/images/google_cloud.svg';
import { ReactComponent as GCPHorizontal } from 'assets/images/GoogleCloudHorizontal.svg';
import { ReactComponent as GCPNoText } from 'assets/images/GoogleCloudNoText.svg';
import { ReactComponent as CheckFilled } from 'assets/images/check-filled.svg';
import { ReactComponent as Rocket } from 'assets/images/rocket.svg';
import { ReactComponent as Archive } from 'assets/images/archive.svg';
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IconProp, library } from '@fortawesome/fontawesome-svg-core'

import {
  faAngleLeft,
  faAngleRight,
  faArrowTurnDown,
  faChartLine,
  faTrashCan,
  faPlus,
  faGauge,
  faSave,
  faEye,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faTrashCan,
  faAngleRight,
  faAngleLeft,
  faArrowTurnDown,
  faChartLine,
  faPlus,
  faGauge,
  faSave,
  faEye,
  faSpinner,
)

export interface IconProps {
  className?: string
  icon: IconName,
  spin?: boolean,
  width?: number,
  height?: number,
  onClick?: (evt: React.MouseEvent<SVGSVGElement>) => void
}

export type ReactSvgComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement> &
  { title?: string }>

// Predefined own icons
const ICONS: Record<string, ReactSvgComponent> = {
  download: DownloadIcon,
  notes: NotesIcon,
  'caret-down': CaretDown,
  'caret-up': CaretUp,

  check: Check,
  'check-circle': CheckCircle,
  'check-filled': CheckFilled,

  close: Close,
  'close-circle': CloseCircle,

  edit: Edit,
  trash: Delete,
  'chart-pie': PieChart,
  'chart-column': BarChart,
  account: Account,

  tag: Tag,
  'tag-filled': TagFilled,

  region: Region,
  service: Service,
  usage_type: ArrowRight,
  operation: ArrowRight,
  'bars-filter': BarsFilter,
  'curly-arrow': CurlyArrow,
  arrows: Arrows,
  exit: Logout,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-right-filled': ArrowRightCircle,
  'arrow-down': ArrowDown,
  'arrow-down-curve': ArrowDownCurve,
  'arrow-back': ArrowBack,
  document: Document,
  'document-filled': DocumentFilled,
  alert: Alert,
  'alert-filled': AlertFilled,
  'circle-plus': CirclePlus,
  'aside-layout': AsideLayout,
  drilldown: DrillDown,
  metric: Metric,
  search: Search,
  minus: Minus,
  'summary-dashboard': SummaryDashboard,
  'arrow-down-on-off': ArrowDownOnOff,
  'slack': Slack,
  'jira_cloud': Jira,
  'jira_server': Jira,
  info: Info,
  tree: Tree,
  settings: Settings,
  'squares-filled': SquaresFilled,
  'dollar-filled': DollarFilled,
  'undo': Undo,
  'rocket': Rocket,
  'aws': AWS,
  'archive': Archive,

  'gcp': GCP,
  'gcp-horiz': GCPHorizontal,
  'gcp-no-text': GCPNoText
}

export type IconName = keyof typeof ICONS;

export const Icon = ({
  icon,
  className,
  width,
  height,
  onClick,
  spin = false,
}: IconProps) => (ICONS[icon] ?
  React.createElement(
    ICONS[icon],
    {
      className: `${className} fill-current`,
      onClick,
      ...(width && height ? {
        width,
        height
      } : {})
    }
  ) : (
    <FontAwesomeIcon
      icon={icon as IconProp}
      className={className}
      spin={spin}
    />
  )
);
