import type { DataEntry } from 'types/dataEntries';
import type { OpportunityPageFilters } from 'types/savings';

import { useGlobalState } from 'state/globalState';
import { useOrgSettingsStore } from 'store/orgs';
import { useSavingsSettingsStore } from 'store/savings';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';

export const usePageFilters = (): DataEntry<OpportunityPageFilters> => {
  const user = useGlobalState((state) => state.user);
  const orgId = user?.org_id as number;
  const orgSettings = useOrgSettingsStore((store) => store.getEntry(orgId));
  const savingsSettings = useSavingsSettingsStore();

  return useDerivedDataEntry({
    user,
    orgSettings,
    savingsSettings
  }, ({
    user,
    orgSettings,
    savingsSettings
  }) => {
    const {
      teamId,
      globalFilterIds,
      priority,
      platform,
      group,
      source,
      rateCommitments
    } = savingsSettings;

    const pageFilters = {
      teamId,
      globalFilterIds,
      priority,
      platform,
      group,
      source,
      rateCommitments
    };

    if (user?.team) {
      pageFilters.teamId = undefined;
    }

    if (!orgSettings.rate_optimization_allowed) {
      pageFilters.group = 'usage';
    }

    return pageFilters;
  });
}
