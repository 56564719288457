import type { Team, TeamDraft, TeamDeps } from 'types/teams';
import { parseTeamFilterFields } from './filters';

export const parseTeamDraft = (team: Team, deps: TeamDeps): TeamDraft => {
  return {
    name: team.name,
    slack_channel: team.slack_channel,
    default_cost_column_id: team.default_cost_column_id,
    opportunity_priorities: team.opportunity_priorities || [],
    filters: parseTeamFilterFields(team, deps.teamFilterConstructor)
  };
}
