import type {
  RuleTag,
  RuleNode,
  RuleFilterBranch,
  RuleDraft,
  RuleDraftBackend,
  RuleValueCondition
} from 'types/rules';

export const nodeToQueue = (node: RuleNode): RuleNode[][] => {
  const result: RuleNode[][] = [];

  if (node.tags) {
    node.tags.forEach((tag) => {
      result.push([{
        ...node,
        tags: [tag]
      }]);
    });
  }

  if (node.children) {
    node.children.forEach((child) => {
      nodeToQueue(child).forEach((queueItem) => {
        result.push([node, ...queueItem])
      });
    });
  }

  return result;
}

export const serializeRuleTree = (tree: RuleNode): RuleTag[] => {
  const list: RuleTag[] = [];

  const queue = nodeToQueue(tree);

  queue.forEach((queueItem, index) => {
    const leaf = queueItem[queueItem.length - 1];

    if (!leaf.tags || !leaf.tags.length || !leaf.tags[0][0] || !leaf.tags[0][1]) {
      return;
    }

    const [[key, value]] = leaf.tags as [key: string, value: string][];

    const branch: RuleFilterBranch = {
      operator: 'and',
      condition: []
    };

    for (let i = 1; i < queueItem.length; i++) {
      const parent = queueItem[i - 1];
      const child = queueItem[i];

      if (child.condition !== undefined) {
        branch.condition.push(child.condition);
      } else {
        const children = parent.children as RuleNode[];
        const values = children.reduce((values, sibling) => (sibling === child || !sibling.condition) ? values : [
          ...values,
          ...sibling.condition.values
        ], [] as any[]);

        branch.condition.push({
          ...children[0].condition,
          values,
          comparator: 'not equals'
        } as RuleValueCondition);
      }
    }

    let ruleTag: RuleTag | undefined = list.find((tag) => tag.key === key && tag.value === value);

    if (!ruleTag) {
      ruleTag = {
        key,
        value,
        weight: [],
        filter: {
          operator: 'or',
          condition: []
        }
      };

      list.push(ruleTag);
    }
    
    ruleTag.weight.push(index);
    ruleTag.filter.condition.push(branch);
  });

  return list;
}

export const serializeRuleDraft = (draft: RuleDraft): RuleDraftBackend => {
  const {
    name,
    src,
    root
  } = draft;

  const tags = serializeRuleTree(root);

  return {
    name,
    metric_source: src,
    tags
  };
}
