import { ReactNode } from 'react';

export interface FormRowProps {
  size: 'xs' | 's' | 'm' | 'l';
  className?: string;
  children?: ReactNode
}

const MARGIN = {
  xs: 'mb-3',
  s: 'mb-4',
  m: 'mb-5',
  l: 'mb-6'
}

export const FormRow = (props: FormRowProps) => {
  const {
    size = 'm',
    className = '',
    children
  } = props;

  return (
    <div className={`${MARGIN[size]} ${className}`}>
      {children}
    </div>
  );
}
