import type { Moment } from 'moment';
import type { Granularity } from 'types/common';

const NORMAL_FORMATTER = Intl.NumberFormat('en-US', { notation: 'standard' })
const COMPACT_FORMATTER = Intl.NumberFormat('en-US', { notation: 'compact' })
const CURRENCY_FORMATTER = Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency', notation: 'compact' })
const PERCENT_FORMATTER = new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 })
const DATE_FORMATTER = new Intl.DateTimeFormat('en-US', {
  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
})

const FOOTPRINT_FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'unit',
  unit: 'kilogram'
});

export const formatNormal = (value: number) => NORMAL_FORMATTER.format(value)
export const formatCompact = (value: number) => COMPACT_FORMATTER.format(value)
export const formatCurrency = (value: number) => CURRENCY_FORMATTER.format(value)
export const formatPercent = (value: number) => PERCENT_FORMATTER.format(value)
export const formatDate = (value: number) => DATE_FORMATTER.format(value)
export const formatFootprint = (value: number) => FOOTPRINT_FORMATTER.format(value);
export const formatRounded = (value: number, sigDigits: number) => Math.round(
  (value + Number.EPSILON) * 10 ** sigDigits,
) / 10 ** sigDigits

export { Granularity };

export const granularityTooltip = (granularity: Granularity) => ({
  hour: 'MMM DD hA',
  day: 'MMM DD, YYYY',
  week: '[Week] w, YYYY',
  month: 'MMMM YYYY',
  quarter: '[Q]Q YYYY',
  year: 'YYYY'
}[granularity]);

const PARTS: Record<Granularity, [string, string][]> = {
  day: [
    ['year', ', YYYY'],
    ['month', ' MMM'],
    ['date', 'DD']
  ],
  month: [
    ['year', ', YYYY'],
    ['month', 'MMMM'],
  ],
  year: [
    ['year', ', YYYY']
  ],
  week: [
    ['year', ', YYYY'],
    ['week', '[Week] w'],
  ],
  quarter: [
    ['year', ', YYYY'],
    ['quarter', '[Q]Q'],
  ],
  hour: [
    ['year', ', YYYY'],
    ['month', ' MMM'],
    ['day', ' DD'],
    ['hour', 'HH:mm']
  ],
};

export const formatRange = (start: Moment, end: Moment, granularity: Granularity) => {
  const parts = PARTS[granularity];

  let index = 0;
  let [part, format] = parts[index];

  let startFormat = '';
  let endFormat = '';

  while (index < parts.length && start.get(part as any) === end.get(part as any)) {
    endFormat = format + endFormat;
    index++;

    if (index < parts.length) {
      part = parts[index][0];
      format = parts[index][1];
    }
  }

  if (index === parts.length) {
    return end.format(endFormat);
  }

  while (index < parts.length) {
    endFormat = format + endFormat;
    startFormat = format + startFormat;
    index++;
    if (index < parts.length) {
      part = parts[index][0];
      format = parts[index][1];
    }
  }

  return `${start.format(startFormat)} – ${end.format(endFormat)}`;
}

const pluralRules = new Intl.PluralRules('en-US');

export const pluralize = (count: number, inflections: { one: string, other: string, zero?: string, two?: string, few?: string, many?: string }) => inflections[pluralRules.select(count)];
