import type { UnitMetricChartWidget } from 'types/dashboards';
import type { LoadingDataEntry } from 'types/dataEntries';
import type { UnitMetric } from 'types/unitMetrics';

import moment from 'moment';
import { Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Section } from 'shared/Section';
import { PeriodChart } from 'shared/PeriodChart';
import { useUnitMetricChartStore } from 'store/unitMetricChart';
import { useUnitMetricsStore, UnitMetricEntry } from 'store/unitMetrics';
import { useUnitMetricsLibraryStore } from 'store/unitMetricsLibrary';
import { Loading } from 'shared/Loading';
import { Button, IconButton } from 'shared/Button';

export interface UnitMetricChartWidgetBlockProps {
  widget: UnitMetricChartWidget;
  onChange: (widget: UnitMetricChartWidget) => void;
  onRemove: () => void;
}

export const UnitMetricChartWidgetBlock = (props: UnitMetricChartWidgetBlockProps) => {
  const {
    widget,
    onChange,
    onRemove
  } = props;

  const {
    metricId,
    duration,
    granularity
  } = widget.settings;

  const [editing, setEditing] = useState(!metricId);

  const fetchUnitMetric = useUnitMetricsStore((store) => store.fetchUnitMetric);
  const metricEntry = useUnitMetricsStore((store) => metricId ?
    store.getUnitMetricEntry(metricId) :
    { status: 'idle' } as UnitMetricEntry
  );

  useEffect(() => {
    if (metricId !== null) {
      fetchUnitMetric(metricId);
    }
  }, [metricId]);

  const chart = useUnitMetricChartStore((store) => metricEntry.status === 'success' ? store.getEntry({
    endDate: moment().format('YYYY-MM-DD'),
    startDate: moment().subtract(duration, granularity).format('YYYY-MM-DD'),
    granularity,
    costType: 'unblended_cost',
    costDimensions: [],
    costAmortization: false,
    filter: (metricEntry.unitMetric as UnitMetric).filter
  }) : { status: 'loading' } as LoadingDataEntry);

  const { status: libraryStatus, unitMetrics, fetchUnitMetricsLibrary } = useUnitMetricsLibraryStore();

  useEffect(() => {
    if (libraryStatus === 'idle') {
      fetchUnitMetricsLibrary();
    }
  }, []);

  return (
    <Section className='relative h-[320px]'>
      {metricEntry.unitMetric && chart.status === 'success' ? (
        <>
          <div className='font-medium p-[15px] font-[16px]'>
            {metricEntry.unitMetric.name}
          </div>
          <PeriodChart
            granularity={granularity}
            currentKey='current_timeframe_unit_metric'
            previousKey='previous_timeframe_unit_metric'
            data={chart.data}
          />
        </>
      ) : metricId ? (
        <div className={editing ? 'w-1/2 pt-[320px] absolute' : ''}><Loading /></div>
      ) : (
        <div className='text-center mr-[50%] pt-[150px] font-medium text-silver-grey-500 text-[24px]'>Select metric</div>
      )}
      {editing && <div className='absolute top-0 right-0 bottom-0 w-[50%] bg-silver-grey/90 z-10 px-[15px] pt-[30px]'>
        <div className='mt-2 font-medium'>
          Unit Metric
        </div>
        <Select
          value={metricId + ''}
          loading={libraryStatus === 'loading'}
          options={unitMetrics.map((metric) => ({
            value: '' + metric.id,
            label: metric.name
          }))}
          onChange={(newViewId) => {
            onChange({
              ...widget,
              settings: {
                ...widget.settings,
                metricId: +newViewId
              }
            });
          }}
        />

        <div className='mt-2 font-medium'>
          {`Duration (${granularity})`}
        </div>
        <Input
          value={''+ duration}
          onChange={(event) => {
            onChange({
              ...widget,
              settings: {
                ...widget.settings,
                duration: +event.target.value
              }
            });
          }}
        />

        <div className='mt-2 font-medium'>
          Granularity
        </div>
        <Select
          value={granularity}
          options={[
            { value: 'hour', label: 'Hour' },
            { value: 'day', label: 'Day' },
            { value: 'week', label: 'Week' },
            { value: 'month', label: 'Month' },
            { value: 'quarter', label: 'Quarter' },
            { value: 'year', label: 'Year' }
          ]}
          onChange={(newGranularity) => {
            onChange({
              ...widget,
              settings: {
                ...widget.settings,
                granularity: newGranularity
              }
            });
          }}
        />
        <div className='mt-5'> 
          <Button
            type='primary'
            size='sm'
            label='Save Widget'
            onClick={() => { setEditing(!editing); }}
            disabled={!metricId}
          />
        </div>
      </div>}
      <div className={'absolute z-[30] right-0 top-0 m-[10px] flex'}>
        {!editing && (
          <IconButton
            type='tertiary'
            size='sm'
            icon='edit'
            onClick={() => { setEditing(!editing); }}
          />
        )}
        <IconButton
          type='tertiary'
          size='sm'
          icon='trash'
          onClick={onRemove}
        />
      </div>
    </Section>
  );
}
