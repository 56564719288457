import type { ThreadStatus } from 'types/savings';

import { Tooltip } from 'antd';
import { ThreadStatusIcon } from './ThreadStatusIcon';
import { THREAD_STATUS_TEXTS } from 'helpers/savings';

export interface ThreadStatusTooltipProps {
  status: ThreadStatus;
  className?: string;
}

export const ThreadStatusTooltip = (props: ThreadStatusTooltipProps) => {
  const {
    status,
    className
  } = props;

  return (
    <Tooltip title={THREAD_STATUS_TEXTS[status]} className={className} zIndex={100}>
      <span>
        <ThreadStatusIcon status={status} />
      </span>
    </Tooltip>
  );
}
