import { Link, useLocation, matchPath } from 'react-router-dom'
import { useGlobalState } from '../../state/globalState'

export interface SubItemProps {
  title: string
  route: string
  matches?: string
  disabled?: boolean
}

export const SubItem = ({ title, route, matches, disabled }: SubItemProps) => {
  const location = useLocation().pathname
  const { sideMenuCollapsed, logEvent } = useGlobalState()

  const isActive = new RegExp(matches || `^${route}$`).test(location);

  const linkClassName = [
    isActive && 'text-blue',
    sideMenuCollapsed ? '' : 'ml-[38px]',
    disabled && 'opacity-50 pointer-events-none',
    'whitespace-nowrap mx-2.5 py-[5px] text-body rounded font-medium hover:text-blue'
  ].filter(Boolean).join(' ');

  return (
    <Link
      to={route}
      className={linkClassName}
      onClick={() => { logEvent('side_menu_click', { route, title }); }}
    >
      {title}
    </Link>
  );
};
