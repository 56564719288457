import { Button } from 'shared/Button';
import { Section } from 'shared/Section';

export interface OnPageBannerProps {
  className?: string;
  type: 'info' | 'error' | 'warning';
  opened: boolean;
  onClose: () => void;
    title?: React.ReactNode;
  children?: React.ReactNode;
}

export const OnPageBanner = (props: OnPageBannerProps) => {
  const {
    type,
    opened,
    onClose,
    className = '',
    title,
    children
  } = props;

  if (!opened) {
    return null;
  }

  const borderColor = {
    info: 'border-cyanine-blue',
    warning: 'border-yellow-600',
    error: 'border-negative'
  }[type];

  return (
    <Section className={`border-[5px] p-[15px] ${borderColor} ${className}`}>
      <Button
        size='sm'
        icon='close'
        type='tertiary'
        className='float-right ml-[20px]'
        onClick={onClose}
      />

      {title && <h3 className='font-medium text-subtitle-1'>
        {title}
      </h3>}
      {children}
    </Section>
  );
}
