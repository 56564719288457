import type { ReactNode } from 'react';

import type {
  ListDataFilterOption,
  ListDataFilterOptions,
  RangeDataFilterOptions
} from 'types/dataFilters';

import type {
  Opportunity,
  OpportunityTag,
  OpportunityTypeId
} from 'types/savings';

import { createNumbersComparator } from 'helpers/sort';

import {
  pickOpportunityDifficulty,
  pickOpportunityAccount,
  pickOpportunityRegion,
  pickOpportunityService,
  pickOpportunityCostImpact,
  pickOpportunityGroup,
  pickOpportunityCategory,
  pickOpportunityTags,
  getOpportunityDifficultyWeight,
  pickOpportunityPlatform,
  pickOpportunityPriority,
  pickOpportunityAccountTags
} from './pick';

import {
  renderOpportunityDifficulty,
  renderOpportunityAccount,
  renderOpportunityRegion,
  renderOpportunityService,
  renderOpportunityTypeIdString,
  renderOpportunityGroup,
  renderOpportunityPlatform
} from './render';

export const createListFilterPicker = <TRecord, TValue extends string = string>(
  pick: (record: TRecord) => TValue | null,
  render: (record: TRecord) => ReactNode,
  weight?: (val: TValue | null) => number
) => (records: TRecord[]): ListDataFilterOptions<TValue> => {
  const itemsMap: Record<string, ListDataFilterOption<TValue>> = {};

  records.forEach((record) => {
    const value = pick(record);

    if (value === null) {
      return;
    }

    if (itemsMap[value]) {
      return;
    }

    const text = render(record);

    itemsMap[value] = { value, text };
  });

  let result = Object.values(itemsMap);

  if (weight) {
    result = result.sort(createNumbersComparator(({ value }) => weight(value)));
  }

  return result;
};

export const createRangeFilterPicker = <TRecord, TValue>(
  pick: (record: TRecord) => TValue | null,
  compare: (valA: TValue, valB: TValue) => number,
  defaultValue: TValue
) => (records: TRecord[]): RangeDataFilterOptions<TValue> => {
  let min: TValue = defaultValue;
  let max: TValue = defaultValue;

  records.forEach((record) => {
    const value = pick(record);

    if (value === null) {
      return;
    }

    if (compare(value, min) < 0) {
      min = value;
    }

    if (compare(value, max) > 0) {
      max = value;
    }
  });

  return { min, max };
};

export const pickCostImpactOptions = createRangeFilterPicker(pickOpportunityCostImpact, (a: number, b: number) => a - b, 0);
export const pickPriorityOptions = createRangeFilterPicker(pickOpportunityPriority, (a: number, b: number) => a - b, 1);
export const pickGroupOptions = createListFilterPicker(pickOpportunityGroup, renderOpportunityGroup);
export const pickDifficultyOptions = createListFilterPicker(pickOpportunityDifficulty, renderOpportunityDifficulty, getOpportunityDifficultyWeight);
export const pickAccountOptions = createListFilterPicker(pickOpportunityAccount, renderOpportunityAccount);
export const pickRegionOptions = createListFilterPicker(pickOpportunityRegion, renderOpportunityRegion);
export const pickServiceOptions = createListFilterPicker(pickOpportunityService, renderOpportunityService);
export const pickPlatformOptions = createListFilterPicker(pickOpportunityPlatform, renderOpportunityPlatform);

export const createTagsFilterPicker = (picker: (opp: Opportunity) => OpportunityTag[]) => (opportunities: Opportunity[]) => {
  const result: ListDataFilterOptions = [];

  opportunities.forEach((opportunity) => {
    picker(opportunity).forEach((tag) => {
      const { key, value } = tag;

      let keyNode = result.find((filterItem) => filterItem.value === key);

      if (!keyNode) {
        keyNode = {
          text: key,
          value: key,
          children: []
        };
        result.push(keyNode);
      }

      let valueNode = keyNode.children!.find((filterItem) => filterItem.value === `${key}:${value}`);

      if (!valueNode) {
        valueNode = {
          text: value || 'No value',
          value: `${key}:${value}`
        };
        keyNode.children!.push(valueNode);
      }
    });
  });

  return result;
};

export const pickTagsOptions = createTagsFilterPicker(pickOpportunityTags);
export const pickAccountTagsOptions = createTagsFilterPicker(pickOpportunityAccountTags);

export const pickCategoryOptions = (opportunities: Opportunity[]) => {
  const typesEncountered = new Set<OpportunityTypeId>();

  const filters: ListDataFilterOptions<OpportunityTypeId> = [];
  const trustedAdvisorFilters: ListDataFilterOptions<OpportunityTypeId> = [];
  const computeOptimizerFilters: ListDataFilterOptions<OpportunityTypeId> = [];
  const costExplorerFilters: ListDataFilterOptions<OpportunityTypeId> = [];
  const recommenderFilters: ListDataFilterOptions<OpportunityTypeId> = [];

  opportunities.forEach((opportunity) => {
    const typeId = pickOpportunityCategory(opportunity);

    if (typeId === null) {
      return;
    }

    if (!typesEncountered.has(typeId)) {
      typesEncountered.add(typeId);

      const item = {
        value: typeId,
        text: renderOpportunityTypeIdString(opportunity)
      };

      if (opportunity.source === 'trustedadvisor') {
        trustedAdvisorFilters.push(item);
      } else if (opportunity.source === 'computeoptimizer') {
        computeOptimizerFilters.push(item);
      } else if (opportunity.source === 'costexplorer') {
        costExplorerFilters.push(item);
      } else if (opportunity.source === 'recommender') {
        recommenderFilters.push(item)
      } else {
        filters.push(item);
      }
    }
  });

  if (trustedAdvisorFilters.length) {
    filters.push({
      text: 'Trusted Advisor',
      value: '0002',
      children: trustedAdvisorFilters
    });
  }
  if (computeOptimizerFilters.length) {
    filters.push({
      text: 'Compute Optimizer',
      value: '0006_0009',
      children: computeOptimizerFilters
    });
  }
  if (costExplorerFilters.length) {
    filters.push({
      text: 'Cost Explorer',
      value: '0010_0015',
      children: costExplorerFilters
    });
  }
  if (recommenderFilters.length) {
    filters.push({
      text: 'Recommender',
      value: '0116',
      children: recommenderFilters
    });
  }

  return filters;
};
