import { Button2 as Button } from 'shared/Button';
import { useUserRoleDialogStore } from 'store/users';

export interface RoleButtonProps {
  id: number;
}

export const RoleButton = (props: RoleButtonProps) => {
  const {
    id
  } = props;

  const open = useUserRoleDialogStore((store) => store.open);

  return <Button
    className='ml-2'
    icon='edit'
    size='xs'
    theme='gray'
    layout='inline'
    onClick={() => {
      open(id)
    }}
  />
}
