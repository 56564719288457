import { Select } from 'antd';
import { Section } from 'shared/Section';
import { useTagList } from 'hooks/tagList';
import { useCostViewConstructor } from 'store/costViewConstructor';
  
export const TagList = () => {
  const costViewConstructor = useCostViewConstructor()
  let options: string[] = []

  if (costViewConstructor.status === 'success') {
    options = costViewConstructor.data.cur.rules.tags.options as string[]
  }

  const [
    tagList,
    setTagList,
   ] = useTagList();

  return (
    <Section className='mb-[15px] p-[20px] flex'>
      <div className='font-medium whitespace-nowrap text-subtitle-1 mr-[10px] mt-[2px]'>
        Tag Keys:
      </div>

      <Select 
        mode='multiple'
        className='w-full'
        options={options.map((option) => {
            return {label: option, value: option}
        })}
        value={tagList.filter((tagKey) => tagKey != '')}
        onChange={(value) => {
            setTagList(value)
        }}
      />
    </Section>
  )
};
