import type { DataEntry } from 'types/dataEntries';
import type { User, UserDraft, UserAction } from 'types/users';

import create from 'zustand';
import { Map as ImmutableMap } from 'immutable';
import {
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
  recoverUser
} from 'services/user';

export interface UsersState {
  library: DataEntry<User[]>;
  entries: ImmutableMap<number, DataEntry<User>>;
  drafts: ImmutableMap<string | number, UserDraft>;
  actions: ImmutableMap<string | number, UserAction>;
}

export interface UsersActions {
  fetchLibrary: () => void;
  getLibrary: () => DataEntry<User[]>;

  getDraft: (key: string | number) => UserDraft;
  setDraft: (key: string | number, draft: UserDraft) => void;
  discardDraft: (key: string | number) => void;

  getAction: (key: string | number) => UserAction | null;
  setAction: (key: string | number, action: UserAction) => void;
  discardAction: (key: string | number) => void;

  getEntry: (id: number) => DataEntry<User>;
  setEntry: (id: number, data: User) => void;

  createEntry: (key: string) => Promise<User>;
  updateEntry: (id: number) => Promise<void>;
  deleteEntry: (id: number) => Promise<void>;
  recoverEntry: (id: number) => Promise<void>;
}

export interface UsersStore extends UsersState, UsersActions {};

const DEFAULT_STATE: UsersState = {
  library: { status: 'idle' },
  entries: ImmutableMap(),
  drafts: ImmutableMap(),
  actions: ImmutableMap()
};

const getEntryDraft = ({ name, email, role }: User): UserDraft => ({
  name,
  email,
  role,
  team_id: null
});

export const useUsersStore = create<UsersStore>((set, get) => ({
  ...DEFAULT_STATE,

  fetchLibrary: () => {
    set({
      library: { status: 'loading' }
    });

    fetchUsers()
      .then(({ data }) => {
        set({
          library: { status: 'success', data }
        });
      })
      .catch((error) => {
        set({
          library: { status: 'error', error }
        });
      });
  },

  getLibrary: () => {
    if (get().library.status === 'idle') {
      get().fetchLibrary();
    }

    return get().library;
  },

  getEntry: (id: number) => {
    const {
      entries,
      getLibrary
    } = get();

    let entry = entries.get(id);

    if (!entry) {
      const library = getLibrary();

      if (library.status !== 'success') {
        return library;
      }

      const data = library.data.find((data) => data.id === id);

      if (!data) {
        entry = {
          status: 'error',
          error: null
        };
      } else {
        entry = {
          status: 'success',
          data
        };
      }

    }
    return entry;
  },

  setEntry: (id: number, data: User) => {
    const {
      entries,
      getLibrary
    } = get();
    const library = getLibrary();

    if (library.status !== 'success') {
      throw new Error('');
    }

    const index = library.data.findIndex((item) => item.id === id);
    const libraryData = [...library.data];

    if (index === -1) {
      libraryData.push(data);
    } else {
      libraryData[index] = data
    }

    set({
      entries: entries.set(id, { status: 'success', data }),
      library: {
        status: 'success',
        data: libraryData
      }
    });
  },

  getDraft: (key: number | string) => {
    const {
      drafts,
      setDraft,
      getEntry
    } = get();

    let draft = drafts.get(key);

    if (!draft) {
      if (typeof key === 'number') {
        const entry = getEntry(key);

        if (entry.status !== 'success') {
          throw new Error('');
        }

        draft = getEntryDraft(entry.data);
      } else {
        draft = { name: '', email: '', role: 'member', team_id: null };
      }

      setDraft(key, draft);
    }

    return draft;
  },

  setDraft: (key: string | number, draft: UserDraft) => {
    const { drafts } = get();

    set({
      drafts: drafts.set(key, draft)
    });
  },

  discardDraft: (key: string | number) => {
    const { drafts } = get();

    set({
      drafts: drafts.remove(key)
    });
  },

  createEntry: (key: string) => {
    const {
      setEntry,

      getDraft,
      discardDraft,

      getAction,
      setAction,
      discardAction
    } = get();

    const draft = getDraft(key);

    if (getAction(key)) {
      throw new Error('');
    }

    return createUser(draft)
      .then(({ data }) => {
        setEntry(data.id, data);
        discardDraft(key);
        discardAction(key);

        return data;
    })
    .catch((error) => {
      setAction(key, {
        type: 'create',
        status: 'error',
        error
      });

      return Promise.reject(error);
    });
  },

  updateEntry: (id: number) => {
    const {
      getEntry,
      setEntry,

      getDraft,
      discardDraft,

      getAction,
      setAction,
      discardAction
    } = get();

    const entry = getEntry(id);
    const draft = getDraft(id);
    const action = getAction(id);

    if (entry.status !== 'success' || action && action.status === 'in-progress') {
      throw new Error('');
    }

    return updateUser(id, draft)
      .then(({ data }) => {
        discardDraft(id);
        discardAction(id);
        setEntry(id, data);

        return data;
      })
      .catch((error) => {
        setAction(id, {
          type: 'update',
          status: 'error',
          error
        });

        return Promise.reject(error);
      });
  },

  deleteEntry: (id: number) => {
    const {
      getEntry,
      setEntry,

      getAction,
      setAction,
      discardAction
    } = get();

    const entry = getEntry(id);
    const action = getAction(id);

    if (entry.status !== 'success' || action && action.status === 'in-progress') {
      throw new Error('');
    }

    setAction(id, {
      type: 'delete',
      status: 'in-progress'
    });

    return deleteUser(id)
      .then(() => {
        const entry = getEntry(id);

        if (entry.status === 'success') {
          setEntry(id, {
            ...entry.data,
            is_deleted: true
          });
        }
      })
      .finally(() => {
        discardAction(id);
      });
  },

  recoverEntry: (id: number) => {
    const {
      getEntry,
      setEntry,
      getAction,
      setAction,
      discardAction
    } = get();

    const entry = getEntry(id);
    const action = getAction(id);

    if (entry.status !== 'success' || action && action.status === 'in-progress') {
      throw new Error('');
    }

    setAction(id, {
      type: 'recover',
      status: 'in-progress'
    });

    return recoverUser(id)
      .then(() => {
        const entry = getEntry(id);

        if (entry.status === 'success') {
          setEntry(id, {
            ...entry.data,
            is_deleted: false
          });
        }
      })
      .finally(() => {
        discardAction(id);
      });
  },

  getAction: (key: string | number) => {
    const { actions } = get();

    return actions.get(key) || null;
  },

  setAction: (key: string | number, action: UserAction) => {
    const { actions } = get();
    
    set({
      actions: actions.set(key, action)
    })
  },

  discardAction: (key: string | number) => {
    const { actions } = get();

    set({
      actions: actions.remove(key)
    });
  }
}));
