import { Link } from 'react-router-dom';

export const PaywallOverlay = () => (
  <div
    className='absolute left-0 right-0 bottom-[56px] h-[195px] flex items-center justify-center text-[24px] font-medium text-gray-400 backdrop-blur-sm'
    style={{ zIndex: 1000 }}
  >
    <div>
      <Link
        to='/settings/payment'
        className='!text-cyanine-blue'
      >
        Subscribe
      </Link>
      {' '}
      to unlock all of the opportunities
    </div>
  </div>
);
