import type { Moment } from 'moment';

export const createNullableNumbersComparator = <
  K extends string = string,
  R extends { [key in K]?: number | null | undefined } = Record<K, number | null | undefined>
>(key: K) =>
  (a: R, b: R) => {
    const valA: number | null | undefined = a[key];
    const valB: number | null | undefined = b[key];

    const aIsNull = valA === null || valA === undefined;
    const bIsNull = valB === null || valB === undefined;

    if (aIsNull && bIsNull) {
      return 0;
    }

    if (aIsNull) {
      return -Infinity;
    }

    if (bIsNull) {
      return Infinity;
    }

    return valA - valB;
  }

export const createDatesComparator = <T>(pick: (val: T) => Moment | null | undefined, emptyLast = false) =>
  (a: T, b: T): number => {
    const valA = pick(a);
    const valB = pick(b);

    const emptyA = valA === null || valA === undefined;
    const emptyB = valB === null || valB === undefined;

    if (emptyA && emptyB) {
      return 0;
    }

    if (emptyA) {
      return emptyLast ? Infinity : -Infinity;
    }

    if (emptyB) {
      return emptyLast ? - Infinity : Infinity;
    }

    if (valA.isSame(valB)) {
      return 0;
    }

    return valA.isAfter(valB) ? 1 : -1;
  }

export const createStringsComparator = <T>(pick: (val: T) => string | null | undefined, emptyLast = false) =>
  (a: T, b: T): number => {
    const valA = pick(a);
    const valB = pick(b);

    const emptyA = valA === null || valA === undefined;
    const emptyB = valB === null || valB === undefined;

    if (emptyA && emptyB) {
      return 0;
    }

    if (emptyA) {
      return emptyLast ? Infinity : -Infinity;
    }

    if (emptyB) {
      return emptyLast ? - Infinity : Infinity;
    }

    return valA > valB ? 1 : -1;
  }

export const createNumbersComparator = <T>(pick: (val: T) => number | null | undefined, emptyLast = false) =>
  (a: T, b: T): number => {
    const valA = pick(a);
    const valB = pick(b);

    const emptyA = valA === null || valA === undefined;
    const emptyB = valB === null || valB === undefined;

    if (emptyA && emptyB) {
      return 0;
    }

    if (emptyA) {
      return emptyLast ? Infinity : -Infinity;
    }

    if (emptyB) {
      return emptyLast ? - Infinity : Infinity;
    }

    return valA - valB;
  }

export const createNullableStringsComparator = <K extends string = string>(key: K) =>
  createStringsComparator((val: Record<K, string | null>) => val[key]);
