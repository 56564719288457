import type { Action, DataEntry } from 'types/dataEntries';
import type { OrgSettings } from 'types/orgs';

import create from 'zustand';

import {
  fetchOrgSettings,
  updateOrgSettings
} from 'services/orgs';
import {
  createStartAction,
  createFinishAction,

  createGetDraft,
  createSetDraft,

  createGetEntry,
  createUpdateEntry,
  createSetEntry
} from 'store/create';

import { identity } from 'helpers/utils';

export interface OrgSettingsStore {
  entries: { [key: number]: DataEntry<OrgSettings> };
  drafts: { [key: number | string]: DataEntry<OrgSettings> };
  actions: { [key: number | string]: Action<'update'> };

  startAction: (id: number, type: 'update') => void;
  finishAction: (id: number) => void;

  getEntry: (id: number | DataEntry<number>) => DataEntry<OrgSettings>;
  setEntry: (id: number, entry: DataEntry<OrgSettings>) => void;
  updateEntry: (id: number) => Promise<OrgSettings>;

  getDraft: (id: number | string | DataEntry<number | string>) => DataEntry<OrgSettings>;
  setDraft: (id: number | string, draft: OrgSettings) => void;
}

export const useOrgSettingsStore = create<OrgSettingsStore>((set, get) => ({
  entries: {},
  drafts: {},
  actions: {},

  startAction: createStartAction({ get, set }),
  finishAction: createFinishAction({ get, set }),

  getDraft: createGetDraft({ get, parseDraft: identity<OrgSettings> }),
  setDraft: createSetDraft({ get, set }),

  setEntry: createSetEntry({ get, set }),
  getEntry: createGetEntry({ get, set, fetchEntry: fetchOrgSettings }),

  updateEntry: createUpdateEntry({
    get,
    updateActionType: 'update' as 'update',
    serializeDraft: identity<OrgSettings>,
    updateEntry: updateOrgSettings
  })
}));
