import type { Setter } from 'types/common';
import {
  DataFilterConfig,
  ListDataFilterValue,
  ListDataFilterOptions,
  NumberRangeDataFilterValue,
  NumberRangeDataFilterOptions,
  GetDataFilterValueType,
  GetDataFilterOptionsType,
  isListDataFilterConfig,
  isNumberRangeDataFilterConfig
} from 'types/dataFilters';

import { ListDataFilterValueDisplay } from './ListDataFilterValueDisplay';
import { RangeDataFilterValueDisplay } from './RangeDataFilterValueDisplay';

export interface DataFilterValueDisplayProps<TConfig extends DataFilterConfig> {
  config: TConfig;
  value: GetDataFilterValueType<TConfig>;
  options: GetDataFilterOptionsType<TConfig>;
  onChange: Setter<GetDataFilterValueType<TConfig>>;
}

export const DataFilterValueDisplay = <TConfig extends DataFilterConfig>(props: DataFilterValueDisplayProps<TConfig>) => {
  const {
    value,
    config,
    options,
    onChange
  } = props;

  if (isListDataFilterConfig(config)) {
    return <ListDataFilterValueDisplay 
      value={value as ListDataFilterValue}
      config={config}
      options={options as ListDataFilterOptions}
      onChange={onChange as Setter<ListDataFilterValue>}
    />;
  }

  if (isNumberRangeDataFilterConfig(config)) {
    return <RangeDataFilterValueDisplay
      value={value as NumberRangeDataFilterValue}
      config={config}
      options={options as NumberRangeDataFilterOptions}
      onChange={onChange as Setter<NumberRangeDataFilterValue>}
    />;
  }

  return null;
};
