import type { Integration } from 'types/users'
import { Icon } from 'shared/Icon';
import { Dialog } from 'shared/Dialog';
import { useIntegrationSelectDialog } from 'store/integrations/integrationSelectDialog';
import { TITLES } from 'pages/Settings/IntegrationsTab/IntegrationsTab';

export interface IntegrationSelectDialogProps {
  setSelectedIntegration: (provider: string | null) => void;
  integrations: Integration[];
}

export const IntegrationSelectDialog = (props: IntegrationSelectDialogProps) => {
  const { opened, close } = useIntegrationSelectDialog();

  const {
    setSelectedIntegration,
    integrations
  } = props;

  return (
    <Dialog
      className='w-[480px]'
      open={opened}
      onClose={() => {
        setSelectedIntegration(null);
        close();
      }}
    >
      <Dialog.Title>
        Select workflow integration
      </Dialog.Title>
      <div className='mx-[15px] mb-[20px]'>
        {integrations.map((integration) => {
          return (
            <li
              key={integration.provider}
              className='flex p-4 mt-4 border-2 border-dotted hover:border-silver-grey-800 rounded-[15px] text-[15px] font-medium h-[80px] items-center'
              onClick={() => {
                setSelectedIntegration(integration.provider);
                close();
              }}
            >
              <Icon icon={integration.provider} className={['jira_cloud', 'jira_server'].includes(integration.provider) ? 'w-[54px] h-[54px] m-[-4px]' : 'w-[46px] h-[46px]'} />
              <span className='ml-4 mr-auto'>
                {TITLES[integration.provider]}
              </span>
            </li>
          )
        })}
      </div>
    </Dialog>
  );
};
