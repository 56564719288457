import type { Team, TeamPayload, GeneralMember } from 'types/teams';
import type { User } from 'types/users';

import api from 'helpers/api';

export const fetchTeams = () => api.get<Team[]>('/teams');
export const createTeam = (payload: TeamPayload) => api.post('/teams', payload);
export const updateTeam = (id: number, payload: TeamPayload) => api.patch(`/teams/${id}`, payload);
export const deleteTeam = (id: number) => api.delete<void>(`/teams/${id}`);

export const fetchTeamMembers = (teamId: number) => api.get<User[]>(`/teams/${teamId}/members`);
export const addTeamMembers = (teamId: number, userIds: number[]) => api.post(`/teams/${teamId}/members/assign`, { users_ids: userIds });
export const removeTeamMembers = (teamId: number, userIds: number[]) => api.post(`/teams/${teamId}/members/remove`, { users_ids: userIds });

export const fetchCostViewMembers = (teamId: number) => api.get<GeneralMember[]>(`/teams/${teamId}/metrics/cost`);
export const addCostView = (teamId: number, costViewId: number) => api.patch(`/metrics/cost/${costViewId}/teams/assign`, { team_id: teamId });
export const removeCostView = (teamId: number, costViewId: number) => api.patch(`/metrics/cost/${costViewId}/teams/remove`, { team_id: teamId });

export const fetchUnitMetricMembers = (teamId: number) => api.get<GeneralMember[]>(`/teams/${teamId}/metrics/unit`);
export const addUnitMetric = (teamId: number, unitMetricId: number) => api.patch(`/metrics/unit/${unitMetricId}/teams/assign`, { team_id: teamId });
export const removeUnitMetric = (teamId: number, unitMetricId: number) => api.patch(`/metrics/unit/${unitMetricId}/teams/remove`, { team_id: teamId });

export const fetchReportConfigMembers = (teamId: number) => api.get<GeneralMember[]>(`/teams/${teamId}/configs/report`);
export const addReportConfig = (teamId: number, reportId: number) => api.patch(`/configs/report/${reportId}/teams/assign`, { team_id: teamId });
export const removeReportConfig = (teamId: number, reportId: number) => api.patch(`/configs/report/${reportId}/teams/remove`, { team_id: teamId });
