import create from 'zustand';
import type { Granularity } from 'helpers/formatter';
import type { FetchStatus } from 'types/common';
import type { UnitMetric, UnitMetricChartData } from 'types/unitMetrics';

import {
  fetchUnitMetric,
  fetchUnitMetricChart
} from 'services/unitMetrics';

type ChartDataRequest = Promise<any>;

interface ChartDataEntry {
  status: FetchStatus;
  request: ChartDataRequest | null;
  chartData: UnitMetricChartData[];
}

type UnitMetricRequest = Promise<any>;

export interface UnitMetricEntry {
  status: FetchStatus;
  request: UnitMetricRequest | null;
  unitMetric: UnitMetric | null;
  charts: Record<string, ChartDataEntry>;
}

interface UnitMetricsState {
  unitMetrics: Record<number, UnitMetricEntry>;
}

interface UnitMetricsActions {
  setUnitMetricEntry: (id: number, entry: UnitMetricEntry) => void;
  getUnitMetricEntry: (id: number) => UnitMetricEntry;
  fetchUnitMetric: (id: number) => UnitMetricRequest;

  setChartDataEntry: (id: number, startDate: string, endDate: string, entry: ChartDataEntry) => void;
  getChartDataEntry: (id: number, startDate: string, endDate: string) => ChartDataEntry;
  fetchChartData: (id: number, startDate: string, endDate: string, granularity: Granularity) => ChartDataRequest;
}

interface UnitMetricsStore extends UnitMetricsState, UnitMetricsActions { [key: string]: any }

const DEFAULT_STATE: UnitMetricsState = {
  unitMetrics: {}
};

export const useUnitMetricsStore = create<UnitMetricsStore>((set, get) => ({
  ...DEFAULT_STATE,

  setUnitMetricEntry: (id, entry) => {
    const { unitMetrics } = get();

    set({
      unitMetrics: {
        ...unitMetrics,
        [id]: entry
      }
    });
  },

  getUnitMetricEntry: (id) => {
    const { unitMetrics, setUnitMetricEntry } = get();
    
    let entry = unitMetrics[id];

    if (!entry) {
      entry = {
        status: 'idle',
        request: null,
        unitMetric: null,
        charts: {}
      };

      setUnitMetricEntry(id, entry);
    }

    return entry;
  },

  setChartDataEntry: (id, startDate, endDate, entry) => {
    const { getUnitMetricEntry, setUnitMetricEntry } = get();

    const unitMetricEntry = getUnitMetricEntry(id);

    setUnitMetricEntry(id, {
      ...unitMetricEntry,
      charts: {
        ...unitMetricEntry.charts,
        [`${startDate}/${endDate}`]: entry
      }
    });
  },

  getChartDataEntry: (id, startDate, endDate) => {
    const {
      getUnitMetricEntry,
      setChartDataEntry
    } = get();

    const unitMetricEntry = getUnitMetricEntry(id);
    const dateKey = `${startDate}/${endDate}`;

    let chartDataEntry = unitMetricEntry.charts[dateKey];
    
    if (!chartDataEntry) {
      chartDataEntry = {
        status: 'idle',
        request: null,
        chartData: []
      };

      setChartDataEntry(id, startDate, endDate, chartDataEntry);
    }

    return chartDataEntry;
  },

  fetchUnitMetric: (id: number) => {
    const {
      getUnitMetricEntry,
      setUnitMetricEntry
    } = get();

    const unitMetricEntry = getUnitMetricEntry(id);
    
    if (unitMetricEntry.request) {
      return unitMetricEntry.request;
    }

    const request = fetchUnitMetric(id)
      .then(({ data: unitMetric }) => {
        setUnitMetricEntry(id, { 
          ...unitMetricEntry, 
          unitMetric,
          status: 'success'
        });
      })
      .catch((err) => {
        setUnitMetricEntry(id, { 
          ...unitMetricEntry, 
          status: 'error'
        });

        return Promise.reject(err);
      });

    setUnitMetricEntry(id, { 
      ...unitMetricEntry, 
      status: 'loading',
      request
    });

    return request;
  },

  fetchChartData: (id: number, startDate: string, endDate: string, granularity: Granularity) => {
    const {
      getChartDataEntry,
      setChartDataEntry,
      getUnitMetricEntry,
      fetchUnitMetric
    } = get();

    const chartDataEntry = getChartDataEntry(id, startDate, endDate);

    if (chartDataEntry.request) {
      return chartDataEntry.request;
    }

    const request = fetchUnitMetric(id)
      .then(() => {
        const { unitMetric } = getUnitMetricEntry(id);

        return fetchUnitMetricChart({
          startDate,
          endDate,
          granularity,
          filter: (unitMetric as UnitMetric).filter,
          costType: 'unblended_cost',
          costDimensions: [],
          costAmortization: false,
        })
      })
      .then(({ data: chartData }) => {
        setChartDataEntry(id, startDate, endDate, {
          ...chartDataEntry,
          status: 'success',
          chartData
        });
      })
      .catch((err) => {
        setChartDataEntry(id, startDate, endDate, { 
          ...chartDataEntry, 
          status: 'error'
        });

        return Promise.reject(err);
      });

    setChartDataEntry(id, startDate, endDate, { 
      ...chartDataEntry, 
      status: 'loading',
      request
    });

    return request;
  }
}));
