import { Section, SectionTitle } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { useSavingsStore } from 'store/savings';

export interface OpportunityRecordIdSectionProps {
  className?: string;
  opportunityId: number;
}

export const OpportunityRecordIdSection = (props: OpportunityRecordIdSectionProps) => {
  const {
    className = '',
    opportunityId
  } = props;

  const opportunity = useSavingsStore((store) => store.getOpportunity(opportunityId));

  return (
    <Section className={`p-5 relative ${className}`}>
      <SectionTitle>
        Record ID
      </SectionTitle>

      {opportunity.status === 'success' && (
        <pre className='p-3 mt-4 bg-gray-100 rounded'>
          {opportunity.data.record_id}
        </pre>
      )}
      
      <StatusOverlay status={opportunity.status} />
    </Section>
  );
}
