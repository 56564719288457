import type { 
  TagViewChartFetchParams,
  TagView,
  TagViewChart,
  DrillDownData,
  DrillDownFetchParams,
} from 'types/tagViews'

import api from 'helpers/api';


import {
  serializeDateFilterFetchParams,
  serializeTagListFilterFetchParams,
} from 'helpers/fetchParams';

export const fetchTagViews = () => api.get<TagView[]>('/metrics/tag');

export const fetchTagViewChart = (params: TagViewChartFetchParams) => {
  const {
    filter
  } = params;

  return api.post<{ data: TagViewChart }>('/metrics/tag', filter, {
    params: {
      ...serializeDateFilterFetchParams(params),
      ...serializeTagListFilterFetchParams(params),
    }
  }).then(({ data }) => data);
};

export const fetchTagViewDrillDown = (params: DrillDownFetchParams) => {
  const {
    tag,
    filter,
    breakdown,
    drillDownSteps
  } = params;

  return api.post<DrillDownData>('/metrics/tag/drilldown', filter, {
    params: {
      group_by: breakdown,
      tag,
      ...serializeDateFilterFetchParams(params),
      ...serializeTagListFilterFetchParams(params),
      ...drillDownSteps.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {} as Record<string, string>),
    }
  });
};

export const fetchTagView = (id: number) => api.get<TagView>(`/metrics/tag/${id}`);
