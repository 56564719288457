import {
  FilterCondition,
  FilterGroupCondition,
  FilterValueCondition,
  FilterStringCondition,
  FilterNestedCondition,
  FilterNestedConditionItem,
  NestedRule,
  ChildRule,
  ConstructorRule,
  ConstructorRules,
  isFilterGroupCondition,
  FilterStringValueCondition,
  isFilterDictValueCondition,
  isFilterRegexStringCondition
} from 'types/filters';

import {
  isFilterNestedCondition,
  isFilterValueCondition,
  isSingleRule,
  isRootRule,
  isLeafRule,
  isNestedRule
} from 'types/filters';

import {
  createValueCondition,
  createWildcardCondition,
  createNestedFromValueCondition,
  getNestedFieldName,
  getNestedItemValueCondition
} from './editing';

import { getOptions } from './options';

const cleanupNestedCondition = (condition: FilterNestedCondition): FilterNestedCondition => {
  return {
    ...condition,
    condition: condition.condition.map((orItem: FilterNestedConditionItem) => {
      const rulesMet: Record<string, boolean> = {};
      const [valueNode, ...childNodes] = orItem.condition;

      const result: FilterNestedConditionItem = {
        ...orItem,
        condition: [
          valueNode,
          ...childNodes.filter((andItem) => {
            const fieldName = getNestedFieldName(andItem);

            if (!rulesMet[fieldName]) {
              rulesMet[fieldName] = true;

              return true;
            }

            return false;
          })
        ]
      };

      return result;
    })
  };
}

const getAvailableValues = (valueCondition: FilterValueCondition, options: string[]) => {
  if (isFilterDictValueCondition(valueCondition)) {
    return valueCondition.values.filter((value) => options.includes(Object.keys(value)[0]));
  }

  if (isFilterRegexStringCondition(valueCondition)) {
    return valueCondition.values;
  }
  
  return valueCondition.values.filter((value) => options.includes(value));
};

const getAllParentChains = (nested: FilterNestedCondition): FilterNestedConditionItem[][] => {
  const result: FilterNestedConditionItem[][] = [];

  nested.condition.forEach((item) => {
    const [_, ...children] = item.condition;

    children.forEach((child) => {
      const childChains = getAllParentChains(child);

      childChains.forEach((childChain) => {
        result.push([item, ...childChain]);
      });
    });
    result.push([item]);
  });

  return result;
}

const filterNestedCondition = (nested: FilterNestedCondition, check: (item: FilterNestedConditionItem) => boolean): FilterNestedCondition => {
  return {
    ...nested,
    condition: nested.condition.filter(check).map((item) => {
      const [value, ...children] = item.condition;

      return {
        ...item,
        condition: [
          value,
          ...children.map((child) => filterNestedCondition(child, check))
        ]
      };
    })
  };
}

const appendToNested = (nested: FilterNestedCondition, value: FilterValueCondition, rules: ConstructorRules): FilterNestedCondition => {
  const valueRule = rules[value.field_name] as ChildRule;
  const allParentChains = getAllParentChains(nested);

  const parentChains = allParentChains.filter((chain) => {
    return chain.length === valueRule.parents.length &&
      valueRule.parents.every((fieldName, index) => fieldName === getNestedItemValueCondition(chain[index]).field_name);
  });

  const itemsToKeep = new Set<FilterNestedConditionItem>();
  const fieldsToCheck = new Set<string>([...valueRule.parents, valueRule.name]);

  parentChains.forEach((parentChain) => {
    const parentValueChain = parentChain.map(getNestedItemValueCondition) as FilterStringValueCondition[];
    const options = getOptions(parentValueChain, valueRule);
    const availableValues = getAvailableValues(value, options);
    
    if (!availableValues.length) {
      return;
    }

    parentChain.forEach((parentItem) => {
      itemsToKeep.add(parentItem);
    });

    const filteredValue = {
      ...value,
      values: availableValues
    } as FilterValueCondition;

    const nestedFromFiltered = createNestedFromValueCondition(filteredValue);
    const closestParent = parentChain[parentChain.length - 1];

    closestParent.condition.push(nestedFromFiltered);
    nestedFromFiltered.condition.forEach((item) => {
      itemsToKeep.add(item);
    });
  });

  nested = filterNestedCondition(nested, (item) => itemsToKeep.has(item) || !fieldsToCheck.has(item.condition[0].field_name));

  return nested;
}

const parseNestedConditions = (conditions: FilterCondition[], rules: Record<string, ConstructorRule>): [FilterNestedCondition[], FilterCondition[]] => {
  let nestedConditions: FilterNestedCondition[] = [];
  const otherConditions: FilterCondition[] = [];
  const nestedParts: Record<string, FilterValueCondition> = {};
  const rootRules = Object.values(rules).filter(isRootRule);
  const leafRules = Object.values(rules).filter(isLeafRule);
  const nestedRules = Object.values(rules).filter(isNestedRule)

  conditions.forEach((condition) => {
    if (isFilterNestedCondition(condition)) {
      const rootRule = rootRules.find((rootRule) => rootRule.name === getNestedFieldName(condition));

      if (rootRule) {
        nestedConditions.push(condition);
      }

      return;
    }

    if (isFilterValueCondition(condition)) {
      const nestedRule = nestedRules.find((nestedRule) => nestedRule.name === condition.field_name);
        
      if (nestedRule) {
        nestedParts[nestedRule.name] = condition;
        return;
      }
    }

    otherConditions.push(condition);
  });

  leafRules.forEach((leafRule) => {
    const fieldsChain = [...leafRule.parents, leafRule.name].reverse();

    let chainStarted = false;

    fieldsChain.forEach((fieldName) => {
      if (nestedParts[fieldName]) {
        chainStarted = true;
      } else if (chainStarted) {
        nestedParts[fieldName] = createWildcardCondition(rules[fieldName]);
      }
    });
  });

  rootRules.forEach((rootRule) => {
    const rootValueCondition = nestedParts[rootRule.name];

    if (!rootValueCondition) {
      return;
    }

    let rootNestedCondition = createNestedFromValueCondition(rootValueCondition);

    const queue = [...rootRule.children];

    while (queue.length > 0) {
      const fieldName = queue.shift() as string;
      const rule = rules[fieldName];
      const valueCondition = nestedParts[fieldName];

      if (valueCondition) {
        rootNestedCondition = appendToNested(rootNestedCondition, valueCondition, rules);

        if (rule.children) {
          queue.push(...rule.children);
        }
      }
    }

    nestedConditions.push(rootNestedCondition);
  });

  nestedConditions = nestedConditions.map(cleanupNestedCondition);

  return [nestedConditions, otherConditions];
}

const parseValueConditions = (conditions: FilterCondition[], rules: Record<string, ConstructorRule>): [FilterValueCondition[], FilterCondition[]] => {
  const valueConditions: FilterValueCondition[] = [];
  const otherConditions: FilterCondition[] = [];

  conditions.forEach((condition) => {
    if (isFilterValueCondition(condition)) {
      const rule = rules[condition.field_name];

      if (!rule) {
        return;
      }

      if (isSingleRule(rule)) {
        valueConditions.push(condition);
        return;
      } else {
        otherConditions.push(condition);
        return;
      }
    }
    
    otherConditions.push(condition);
  });

  return [
    valueConditions,
    otherConditions
  ];
};

const fillNestedCondition = (parentCondition: FilterNestedCondition, rule: NestedRule, rules: ConstructorRules) => {
  rule.children?.forEach((childRuleName) => {
    const childRule = rules[childRuleName];

    if (!childRule.required) {
      return;
    }

    for (let parentValueBlock of parentCondition.condition) {
      let childCondition: FilterNestedCondition | undefined;

      for (let childGroup of parentValueBlock.condition) {
        if (
          !childCondition &&
          isFilterGroupCondition(childGroup) && // or
          childGroup.condition[0] && 
          isFilterGroupCondition(childGroup.condition[0]) && // and
          childGroup.condition[0].condition[0].field_name === childRule.name
        ) {
          childCondition = childGroup;
        }
      }

      if (!childCondition) {
        childCondition = {
          operator: 'or',
          condition: [{
            operator: 'and',
            condition: [createValueCondition(childRule) as FilterStringCondition]
          }]
        };

        (parentValueBlock as FilterNestedConditionItem).condition.push(childCondition);
      }

      fillNestedCondition(childCondition, childRule as NestedRule, rules);
    }
  });
};

const checkRequiredConditions = (conditions: FilterCondition[], rules: ConstructorRules) => {
  const requiredRules = Object.values(rules).filter((rule) => rule.required && (isSingleRule(rule) || isRootRule(rule)));
  const result = [...conditions];
  
  requiredRules.forEach((rule) => {
    if (isSingleRule(rule)) {
      const condition = conditions.find((condition) => isFilterValueCondition(condition) && condition.field_name === rule.name);

      if (!condition) {
        result.push(createValueCondition(rule));
      }

      return;
    }

    let condition = conditions.find((condition) =>
      isFilterNestedCondition(condition) &&
      condition.condition[0].condition[0].field_name === rule.name
    ) as FilterNestedCondition | undefined;

    if (!condition) {
      condition = {
        operator: 'or',
        condition: [
          {
            operator: 'and',
            condition: [
              createValueCondition(rule)
            ]
          }
        ]
      };

      result.push(condition);
    }

    fillNestedCondition(condition, rule as NestedRule, rules);
  });

  return result;
}

export const parseFilter = <TFilter extends FilterGroupCondition>(filter: TFilter, rules: Record<string, ConstructorRule>): TFilter => {
  const [valueConditions, otherConditions] = parseValueConditions(filter.condition, rules);
  const [nestedConditions] = parseNestedConditions(otherConditions, rules);

  const withRequired = checkRequiredConditions([
      ...nestedConditions,
      ...valueConditions
    ], rules);

  return {
    ...filter,
    condition: withRequired
  };
}
