import type { Granularity } from 'types/common';
import type { DataEntry } from 'types/dataEntries';
import type { TagViewChart } from 'types/tagViews';
import type { EventsData } from 'types/costViews/events';
import type { CostViewFilter, CostViewFilterWithTeam } from 'types/costViews';
import type { ChartType } from 'types/chart'
import type { Moment } from 'moment';

import { useMemo, useState } from 'react';
import { Section } from 'shared/Section';
import { TotalChart } from './TotalChart/TotalChart';
import { HeaderStatus } from './HeaderStatus';
import { Select as AntdSelect } from 'antd'
import { Table } from 'antd'
import { Checkbox } from 'antd';
import { formatPercent } from 'helpers/formatter';

export interface TotalProps {
  viewId: number | null;
  className?: string;
  chartDataEntry: DataEntry<TagViewChart>;
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
  events: EventsData[];
  eventTypes: string[];
  selectedEventTypes: string[];
  setEventTypes: (types: string[]) => void;
  granularity: Granularity;
  requestFilter: CostViewFilter | CostViewFilterWithTeam;
}

export const Total = (props: TotalProps) => {
  const {
    className,
    chartDataEntry,
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment,
    events,
    eventTypes,
    selectedEventTypes,
    setEventTypes,
    granularity,
    requestFilter
  } = props;

  const [chartType, setChartType] = useState<ChartType>('line')
  const [evnetsOverlay, setEventsOverlay] = useState<boolean>(false)
  const [eventsToDisplay, setEventsToDisplay] = useState<EventsData[]>([])

  const handleSetEventsToDisplay = (targetEvents: EventsData[]) => {
    if (targetEvents.length || eventsToDisplay.length) {
      setEventsToDisplay(targetEvents)
    }
  }

  const eventClick = (url: string) => {
    window.open(url, '_blank')
  }

  const filteredEvents = useMemo(() => {
    return events.filter((event) => selectedEventTypes.includes(event.payload.type))
  }, [selectedEventTypes])

  const filteredEventsToDisplay = useMemo(() => {
    return eventsToDisplay.filter((event) => selectedEventTypes.includes(event.payload.type))
  }, [selectedEventTypes, eventsToDisplay])

  const format = formatPercent;

  return (
    <Section className={className}>
      <Section.Head>
        <div className='overflow-hidden font-medium text-ellipsis'>Tag Coverage - Overview</div>
        <div className="flex mx-4">
          {/*
          <Select
            className="min-w-[80px]"
            options={[
              {key: 'line', value: 'Line'},
              {key: 'bar', value: 'Bar'},
            ]}
            onChange={(key) => {
              setChartType(key);
            }}
            value={chartType}
            type="secondary"
            size="sm"
            enableFilter={false}
          />
          */}
        </div>
        <Checkbox
          checked={evnetsOverlay}
          onChange={() => {
            setEventsOverlay(!evnetsOverlay);
          }}
        >
          Events
        </Checkbox>
        <Section.Right>
          <HeaderStatus chartDataEntry={chartDataEntry} granularity={granularity} format={format} />
        </Section.Right>
      </Section.Head>
      <TotalChart 
        chartDataEntry={chartDataEntry}
        chartType={chartType}
        events={evnetsOverlay ? filteredEvents : []}
        setEventsToDisplay={handleSetEventsToDisplay}
        format={format}
        granularity={granularity}
        startMoment={startMoment}
        endMoment={endMoment}
        prevStartMoment={prevStartMoment}
        prevEndMoment={prevEndMoment}
        requestFilter={requestFilter}
      />
      {evnetsOverlay && (
        <div className='mx-5 mb-5'>
          <div className='text-[11px] text-silver-grey-900 mb-2'>Event Types</div>
          <div  className='mb-2 w-[200[x]'>
            <AntdSelect 
              mode="multiple"
              className='w-[200px]'
              onChange={setEventTypes}
              options={eventTypes.map((type) => {
                return { label: type, value: type }
              })}
              filterOption={true}
              defaultValue={selectedEventTypes}
            />
          </div>
          {eventsToDisplay.length > 0 && (
            <Table 
              rowKey={({ id }) => id}
              size='small'
              rowClassName='text-[12px] line-[14px]'
              onHeaderRow={() => ({ className: 'text-[12px] line-[14px]' })}
              columns={[
                {
                  key: 'type',
                  title: 'Type',
                  dataIndex: 'type',
                  render: (type) => <span className='truncate w-[200px]'>{type}</span>
                },
                {
                  key: 'description',
                  title: 'Description',
                  dataIndex: 'description',
                  render: (description) => <span className='truncate w-[200px]'>{description}</span>
                },
                {
                  key: 'event_url',
                  title: 'Link',
                  dataIndex: 'event_url',
                  render: (event_url) => <a onClick={() => eventClick(event_url)} className='truncate w-[200px]'>{event_url}</a>
                }
              ]}
              dataSource={filteredEventsToDisplay.map((e) => {
                return { id: e.id, ...e.payload }
              })}
            />
          )}
        </div>
      )}
    </Section>
  );
};
