import { useGlobalState } from 'state/globalState';
import { useTeamsStore } from 'store/teams';
import { ConfigProvider, Select } from 'antd';
import { Button2 as Button } from 'shared/Button'
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"

import {
  addTeamMembers,
  removeTeamMembers,
} from 'services/teams';

export const TeamAssumption = () => {
  const navigate = useNavigate()
  const user = useGlobalState((state) => state.user);
  const teams = useTeamsStore((state) => state.getLibrary());
  const [currentTeam, setCurrentTeam] = useState<number | null>(null)

  useEffect(() => {
    if (user && user.team) {
      setCurrentTeam(user.team.id)
    }
  }, [user])

  if (user && teams.status === 'success') {
    const teamOptions = teams.data.map((team) => { return { value: `${team.id}`, label: team.name}})
    const userTeam = teamOptions.find((option) => option.value === `${currentTeam}`)

    return (
      <div className='m-2.5 mr-7'>
        <div className='mb-1 font-medium text-caption-2'>Admin Team Assumption</div>
        <div className='flex w-full text-ellipsis'>
          <ConfigProvider
            theme={{
              token: {
                fontSize: 11,
              }
            }}
          >
            <Select
              size='small'
              className='w-full grow'
              options={teamOptions}
              value={userTeam ? userTeam.value : null}
              placeholder='Select a Team'
              onChange={(value: string) => {
                const newTeam = teamOptions.find((option) => option.value === value)

                if (newTeam) {
                  const newTeamId = parseInt(newTeam.value)

                  if (currentTeam) {
                    removeTeamMembers(currentTeam, [user.id]).then(() => addTeamMembers(newTeamId, [user.id]).then(() => {
                      navigate('/costs-overview');
                      window.location.reload();
                    }))
                  } else {
                    addTeamMembers(newTeamId, [user.id]).then(() => {
                      navigate('/costs-overview');
                      window.location.reload();
                    })
                  }
                }
              }}
            />
          </ConfigProvider>

          {currentTeam && (
            <Button 
              onClick={() => removeTeamMembers(currentTeam, [user.id]).then(() => {
                navigate('/costs-overview');
                window.location.reload();
              })}
              size='xs'
              icon='close-circle'
              theme='gray'
            />
          )}
        </div>
      </div>
    )
  }

  return null;
}
