import { Select } from 'antd';
import { useSavingsStore } from 'store/savings';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';

export interface ThreadSelectProps {
  value: number | null;
  disabled?: boolean;
  onChange: (value: number | null) => void;
}

export const ThreadSelect = (props: ThreadSelectProps) => {
  const {
    value,
    disabled,
    onChange
  } = props;

  const threads = useSavingsStore((store) => store.getThreads());
  const options = useDerivedDataEntry({ threads }, ({ threads }) => {
    const options = threads
      .filter((thread) => thread.status === 'open' || thread.id === value)
      .map((thread) => ({
        value: thread.id,
        label: `[TRD-${thread.secondary_id}] ${thread.name}`
      }));

    return [
      {
        value: '',
        label: 'No Thread'
      },
      ...options
    ];
  });

  return (
    <Select
      className='w-full'
      value={value || ''}
      disabled={disabled || options.status !== 'success' || options.data.length <= 1}
      loading={options.status === 'loading'}
      options={options.status === 'success' ? options.data : []}
      onChange={(value) => { onChange(value as number || null); }}
    />
  );
};
