import type { ConstructorRules } from 'types/filters';
import type { RuleValueCondition } from 'types/rules';

import { Select } from 'antd';
import { getTitlePlural } from 'shared/Filters';

const getAvailableFields = (branch: RuleValueCondition[], constructorRules: ConstructorRules): string[] => {
  const presentRules = new Set(branch.map((cond) => cond.field_name));

  return Object.values(constructorRules)
    .filter((constructorRule) => {
      const ruleConditions = branch.filter((cond) => cond.field_name === constructorRule.name);

      return (
        ruleConditions.every((cond) => cond.value_type.endsWith('-regex'))
      ) && (
        !constructorRule.parents || 
        constructorRule.parents.every((parent) => presentRules.has(parent))
      ) && (
        !constructorRule.children ||
        constructorRule.children.every((child) => !presentRules.has(child))
      );
    })
    .map(({ name }) => name)
    .filter((name) => name !== 'rule_id' && name !== 'rule_tag');
}

export interface FieldSelectorProps {
  value: string | null;
  parentConditions: RuleValueCondition[];
  constructorRules: ConstructorRules;
  onChange: (value: string | null) => void;
}

export const FieldSelector = (props: FieldSelectorProps) => {
  const {
    value,
    parentConditions,
    constructorRules,
    onChange
  } = props;

  const fields = getAvailableFields(parentConditions, constructorRules);
  const options = fields.map((field) => ({
    value: field,
    label: getTitlePlural(field)
  }));

  return (
    <div className='flex items-center min-w-0 gap-2 whitespace-nowrap text-[13px]'>
      Split by:
      <Select
        value={value}
        placeholder='Select field'
        options={options}
        onChange={onChange}
      />
    </div>
  );
};
