import type {
  RuleBackend,
  RuleInfo,
  Rule,
  RuleDraft,
  RuleTag,
  RuleNode,
  RuleFilterBranch,
  RuleValueCondition,
  RuleRoot
} from 'types/rules';

import { toImmutableCondition } from 'helpers/filters';

export const matchNodeAndCondition = (node: RuleNode, condition: RuleValueCondition) => {
  if (!node.condition) {
    return false;
  }

  if (node.rest && condition.comparator === 'not equals') {
    return false;
  }

  const immutableNodeCondition = toImmutableCondition(node.condition);
  const immutableCondition = toImmutableCondition(condition);
  
  return !!immutableNodeCondition &&
    !!immutableCondition &&
    immutableNodeCondition.toString() === immutableCondition.toString();
}

export const parseRuleTree = (list: RuleTag[]): RuleRoot => {
  const tree: RuleRoot = {
    field: null,
    children: []
  };

  const queue: {
    branch: RuleFilterBranch;
    weight: number;
    tag: [key: string, value: string];
  }[] = [];

  list.forEach((tag) => {
    tag.filter.condition.forEach((branch, index) => {
      const weight = tag.weight[index];
      queue.push({
        branch,
        weight,
        tag: [tag.key, tag.value]
      });
    });
  });

  queue.sort((itemA, itemB) => itemA.weight - itemB.weight);

  queue.forEach(({ branch, tag }) => {
    let node: RuleNode = tree;

    for (const valueCondition of branch.condition) {
      if (!node.field) {
        node.field = valueCondition.field_name;
      }

      if (!node.children) {
        node.children = [];
      }

      let nextNode = node.children.find((child) => matchNodeAndCondition(child, valueCondition));

      if (!nextNode) {
        nextNode = valueCondition.comparator === 'equals' ? {
          condition: valueCondition
        } : {
          rest: true
        };
        node.children.push(nextNode);
      }

      node = nextNode;
    }
    
    if (!node.tags) {
      node.tags = [];
    }

    node.tags.push(tag);
  });

  return tree;
};

export const pickRuleInfo = (rule: RuleInfo): RuleInfo => {
  const {
    id,
    organization_id,
    created_by
  } = rule;

  return {
    id,
    organization_id,
    created_by
  };
}

export const parseRuleDraft = (ruleBackend: RuleBackend): RuleDraft => {
  const { name } = ruleBackend;

  const root = parseRuleTree(ruleBackend.tags);
  const src = ruleBackend.metric_source;

  return {
    name,
    root,
    src
  };
};

export const parseRule = (ruleBackend: RuleBackend): Rule => {
  const { name } = ruleBackend;

  const root = parseRuleTree(ruleBackend.tags);
  const info = pickRuleInfo(ruleBackend);
  const src = ruleBackend.metric_source;

  return {
    name,
    root,
    src,
    ...info
  };
};
