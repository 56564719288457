import { useGlobalState } from 'state/globalState';
import { Navigate, Outlet } from 'react-router-dom';

export const NonAuthRoute = () => {
  const user = useGlobalState((state) => state.user);
  const returnPath = window.sessionStorage.getItem('loginReturnPath') || '/';

  if (user) {
    return <Navigate to={returnPath} />;
  }

  return <Outlet />;
}
