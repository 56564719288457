export type ChildValues = { [key: string]: string[] } | { [key: string]: ChildValues };

export interface ConstructorRoleBase {
  name: string;
  valueType: 'string' | 'dict';
  single?: boolean;
  required?: boolean;
  regexAllowed?: boolean;
  exclusionAllowed?: boolean;
  options: string[] | ChildValues;
  aliases?: Record<string, string>;
  parents?: string[];
  children?: string[];
  requiredChildren?: string[];
  exactParentRequired?: boolean;
  teamsOnly?: boolean;
}

export interface StringRule extends ConstructorRoleBase {
  valueType: 'string';
}

export interface SingleStringRule extends StringRule {
  options: string[];
  parents?: never;
  children?: never;
}

export interface RootStringRule extends StringRule {
  parents?: never;
  options: string[];
  children: string[];
}

export interface ChildStringRule extends StringRule {
  parents: string[];
}

export interface LeafStringRule extends ChildStringRule {
  children?: never;
}

export interface DictRule extends ConstructorRoleBase {
  valueType: 'dict';
  children?: never;
}

export interface SingleDictRule extends DictRule {
  options: string[];
  parents?: never;
  children?: never;
}

export interface ChildDictRule extends DictRule {
  parents: string[];
}

export interface LeafDictRule extends ChildDictRule {
  children?: never;
}

export type SingleRule = SingleStringRule | SingleDictRule;

export type RootRule = RootStringRule;
export type LeafRule = LeafDictRule | LeafStringRule;
export type ChildRule = LeafRule | ChildDictRule | ChildStringRule;
export type NestedRule = RootRule | ChildRule;

export type ConstructorRule = SingleRule | NestedRule;

export const isChildRule = (rule: ConstructorRoleBase): rule is ChildRule => !!rule.parents;
export const isRootRule = (rule: ConstructorRoleBase): rule is RootRule => !rule.parents && !!rule.children;
export const isLeafRule = (rule: ConstructorRoleBase): rule is LeafRule => !rule.children && !!rule.parents;
export const isNestedRule = (rule: ConstructorRoleBase): rule is NestedRule => !!rule.children || !!rule.parents;
export const isSingleRule = (rule: ConstructorRoleBase): rule is SingleRule => !rule.children && !rule.parents;
export const isStringRule = (rule: ConstructorRoleBase): rule is StringRule => rule.valueType === 'string';
export const isDictRule = (rule: ConstructorRoleBase): rule is DictRule => rule.valueType === 'dict';

export type ConstructorRules = Record<string, ConstructorRule>;
export type FilterConstructor = Record<string, ConstructorRules>;
