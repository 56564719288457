import { useMemo } from 'react';

export interface DummyProps {
  minWidth?: number;
  maxWidth?: number;
  height: number;
}

export const Dummy = (props: DummyProps) => {
  const {
    minWidth = 0,
    maxWidth = 100,
    height,
  } = props;

  const width = useMemo(() => `${minWidth + Math.random() * (maxWidth - minWidth)}%`, [minWidth, maxWidth]);

  return <div
    className='inline-block bg-gray-200 rounded'
    style={{ width, height, verticalAlign: 'middle' }}
  />;
}
