import type {
  CostType,
  CostDimension,
  CostFilterFetchParams
} from 'types/costViews';

import { useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DEFAULT_COST_TYPE, isCostType, isCostDimension } from 'types/costViews';

export const useCostTypeValue = (): CostType => {
  const [searchParams] = useSearchParams();
  const costTypeParam = searchParams.get('costType');

  return useMemo(() => {
    const costType: CostType = isCostType(costTypeParam) ? costTypeParam : DEFAULT_COST_TYPE;

    return costType;
  }, [costTypeParam]);
};

export const useCostDimensionsValue = (): CostDimension[] => {
  const [searchParams] = useSearchParams();
  const costDimensionsParam = searchParams.get('excludedDimensions');

  return useMemo(() => {
    const costDimensions: CostDimension[] = (costDimensionsParam || '').split(',').filter(isCostDimension);

    return costDimensions;
  }, [costDimensionsParam]);
}

export const useCostAmortizationValue = (): boolean => {
  const [searchParams] = useSearchParams();
  const costAmortizationParam = searchParams.get('amortization');

  return costAmortizationParam === 'true';
}

type Setter<T> = (searchParams: URLSearchParams, value: T) => void;

export const useCostFilterValue = (): CostFilterFetchParams => ({
  costType: useCostTypeValue(),
  costDimensions: useCostDimensionsValue(),
  costAmortization: useCostAmortizationValue()
});

const setCostType: Setter<CostType> = (searchParams, costType) => {
  if (costType === DEFAULT_COST_TYPE) {
    searchParams.delete('costType');
  } else {
    searchParams.set('costType', costType);
  }
}

const setCostDimensions: Setter<CostDimension[]> = (searchParams, costDimensions) => {
  if (costDimensions.length === 0) {
    searchParams.delete('excludedDimensions');
  } else {
    searchParams.set('excludedDimensions', costDimensions.join(','));
  }
}

const setCostAmortization: Setter<boolean> = (searchParams, costAmortization) => {
  if (costAmortization) {
    searchParams.set('amortization', 'true');
  } else {
    searchParams.delete('amortization');
  }
}

const setCostFilter: Setter<CostFilterFetchParams> = (searchParams, costFilter) => {
  setCostType(searchParams, costFilter.costType);
  setCostDimensions(searchParams, costFilter.costDimensions);
  setCostAmortization(searchParams, costFilter.costAmortization);
}

const createSetterHook = <T>(setter: ((searchParams: URLSearchParams, value: T) => void)) =>
  (): ((value: T) => void) => {
    const [searchParams, setSearchParams] = useSearchParams();
    
    return useCallback((newValue: T) => {
      const newSearchParams = new URLSearchParams(searchParams);

      setter(newSearchParams, newValue);

      setSearchParams(newSearchParams);
    }, [searchParams]);
  }

export const useCostTypeSetter = createSetterHook(setCostType);
export const useCostDimensionsSetter = createSetterHook(setCostDimensions);
export const useCostAmortizationSetter = createSetterHook(setCostAmortization);
export const useCostFilterSetter = createSetterHook(setCostFilter);

const combine = <T>(getter: () => T, setter: () => ((value: T) => void)):
  (() => [T, (value: T) => void]) =>
    () => [getter(), setter()];

export const useCostType = combine(useCostTypeValue, useCostTypeSetter);
export const useCostDimensions = combine(useCostDimensionsValue, useCostDimensionsSetter);
export const useCostAmortization = combine(useCostAmortizationValue, useCostAmortizationSetter);
export const useCostFilter = combine(useCostFilterValue, useCostFilterSetter);
