import type { StatusFilterValue } from 'types/savings';
import { isStatusFilterValue } from 'types/savings';

import { useTextQueryParam } from 'hooks/queryParams';

const parse = (param: string | null) => isStatusFilterValue(param) ? param : 'active';
const serialize = (value: StatusFilterValue | null) => value === 'active' ? null : value;

export const useOpportunityStatusQuery = () => {
  return useTextQueryParam('status', {
    parse,
    serialize
  });
}
