import type { Moment } from 'moment';
import type { Granularity } from 'types/common';
import type {
  UnitMetricChart,
  UnitMetricChartDataKey
} from 'types/unitMetrics';

import { memo } from 'react';
import { formatRange } from 'helpers/formatter';
import { ValueBox } from 'shared/ValueBox';

export interface ChartInfoProps {
  chart: UnitMetricChart;
  currentKey: UnitMetricChartDataKey;
  previousKey: UnitMetricChartDataKey;
  format: (value: number) => string;
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
  granularity: Granularity;
}

export const ChartInfo = memo((props: ChartInfoProps) => {
  const {
    chart,
    currentKey,
    previousKey,
    format,
    granularity,
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment
  } = props;
    
  const currentTotal = chart.map((item) => item[currentKey]).reduce((a, b) => a + b, 0);
  const currentAverage = currentTotal / chart.length;
  const previousTotal = chart.map((item) => item[previousKey]).reduce((a, b) => a + b, 0);
  const previousAverage = previousTotal / chart.length;

  return <>
    <div className='flex items-center text-[#00b4d8] font-medium'>
      <div className='w-[8px] h-[8px] rounded bg-[#00b4d8] mr-2'></div>
      Current Period
    </div>

    <div className='text-[12px] ml-[16px] mb-1 text-silver-grey-900'>
      {formatRange(startMoment, endMoment, granularity)}
    </div>

    <div className='font-medium ml-[16px] flex'>
      <ValueBox label='Total'>{format(currentTotal)}</ValueBox>
      <ValueBox label='Average' className='ml-4'>{format(currentAverage)}</ValueBox>
    </div>

    <div className='flex items-center text-[#DA5665] font-medium mt-[15px]'>
      <div className='w-[8px] h-[8px] rounded bg-[#DA5665] mr-2'></div>
      Previous Period
    </div>

    <div className='text-[12px] ml-[16px] mb-1 text-silver-grey-900'>
      {formatRange(prevStartMoment, prevEndMoment, granularity)}
    </div>

    <div className='font-medium ml-[16px] flex'>
      <ValueBox label='Total'>{format(previousTotal)}</ValueBox>
      <ValueBox label='Average' className='ml-4'>{format(previousAverage)}</ValueBox>
    </div>
  </>;
});
