import { ThreadStatus } from 'types/savings';
import { THREAD_STATUS_TEXTS } from 'helpers/savings';
import {ThreadStatusIcon} from './ThreadStatusIcon';

export interface ThreadStatusBadgeProps {
  children: ThreadStatus;
  className?: string;
}

export const ThreadStatusBadge = (props: ThreadStatusBadgeProps) => {
  const {
    className = '',
    children: status
  } = props;

  return <span
    className={className}
  >
    <ThreadStatusIcon
      className='mr-1'
      status={status}
    />

    {THREAD_STATUS_TEXTS[status]}
  </span>;
}
