import { UserShort } from 'types/users';
import { DataFilterConfigsMap, DataFilterOptionsMap, DataFilterValuesMap, ListDataFilterConfig } from 'types/dataFilters';

export interface AutomationPayload {
  run_at: string | null;
  opportunity_filter: {
    id: number;
  };
  comment: string | null;
}

export const JOB_STATUSES = [
  'submitted',
  'started',
  'successful',
  'rollback_started',
  'rollback_successful',
  'failed',
  'cancelled'
] as const;

export const JOB_STATUS_TITLES: Record<JobStatus, string> = {
  submitted: 'Submitted',
  started: 'Started',
  successful: 'Successful',
  rollback_started: 'Rollback started',
  rollback_successful: 'Rollback successful',
  failed: 'Failed',
  cancelled: 'Cancelled'
}

export type JobStatus = typeof JOB_STATUSES[number];

export interface JobLogEntry {
  id: number;
  new_id: string;
  opportunity_id: number;
  opportunity_secondary_id: number;
  created_at: string;
  owner: UserShort | null;
  job_id: number;
  status: JobStatus;
  message: any;
}

export interface JobLogTableProps {
  opportunityId?: number | null;
}

export type JobLogFilterKeys = 'opportunity_id' | 'status' | 'job_id';

export interface JobLogFilterConfig extends DataFilterConfigsMap<JobLogFilterKeys> {
  opportunity_id: ListDataFilterConfig;
  status: ListDataFilterConfig;
  job_id: ListDataFilterConfig;
}

export interface JobLogFilterValue extends DataFilterValuesMap<JobLogFilterKeys, JobLogFilterConfig> {}
export interface JobLogFilterOptions extends DataFilterOptionsMap<JobLogFilterKeys, JobLogFilterConfig> {}

export const JOB_LOG_FILTER_CONFIG: JobLogFilterConfig = {
  opportunity_id: {
    type: 'list',
    title: 'Opportunity'
  },
  status: {
    type: 'list',
    title: 'Status'
  },
  job_id: {
    type: 'list',
    title: 'Job ID'
  }
}
