import type {
  MoversAndShakersFetchParams,
  MoversAndShakersStoreKey,
  MoversAndShakersStoreKeyProps,
  MoversAndShakersStoreKeyFactory
} from 'types/costViews/moversAndShakers';

import {
  Set as ImmutableSet,
  Record as ImmutableRecord
} from 'immutable';

import {
  defaultCostFilterKeyProps,
  defaultDateFilterKeyProps
} from './defaults';

import { toImmutableFilter } from 'helpers/filters';

const createMoversAndShakersStoreKey: MoversAndShakersStoreKeyFactory = ImmutableRecord<MoversAndShakersStoreKeyProps>({
  ...defaultCostFilterKeyProps,
  ...defaultDateFilterKeyProps,
  breakdown: 'accounts',
  minSpend: undefined,
  absoluteThreshold: undefined,
  relativeThreshold: undefined,
  teamId: undefined,
  globalFilterIds: undefined,
  filter: null,
});

export const moversAndShakersFetchParamsToKey = (params: MoversAndShakersFetchParams): MoversAndShakersStoreKey => {
  const filter = toImmutableFilter(params.filter);
  const globalFilterIds = ImmutableSet(params.globalFilterIds);
  const costDimensions = ImmutableSet(params.costDimensions);

  return createMoversAndShakersStoreKey({
    ...params,
    costDimensions,
    globalFilterIds,
    filter,
  });
};
