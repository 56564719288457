import { Button2 as Button } from 'shared/Button';
import { useUserDeleteDialogStore } from 'store/users';

export interface DeleteButtonProps {
  id: number;
}

export const DeleteButton = (props: DeleteButtonProps) => {
  const {
    id
  } = props;

  const open = useUserDeleteDialogStore((store) => store.open);

  return <Button
    className='hover:!text-red !flex'
    icon='trash'
    size='s'
    theme='black'
    layout='inline'
    onClick={() => {
      open(id)
    }}
  >
    Delete User
  </Button>
}
