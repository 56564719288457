import type { BillingOverview } from 'types/billing';

import moment from 'moment';
import { H1, H2, P } from './Typography';
import { PlanText } from './PlanText';
import { BillingText } from './BillingText';
import { UnsubscribeButton } from './UnsubscribeButton';
import { formatNormal } from 'helpers/formatter';

export interface PayingMessageProps {
  overview: BillingOverview;
}

export const PayingMessage = (props: PayingMessageProps) => {
  const {
    overview
  } = props;

  const {
    current_subscription,
    current_total_spend,
    current_start_date,
    current_end_date,
    current_plan,

    next_total_spend,
    next_start_date,
    next_end_date,
    next_plan
  } = overview;

  const tierChange = current_plan && next_plan && current_plan.id !== next_plan.id;

  return (
    <div>
      <H1>
        Your Cloudthread subscription is <strong className='text-positive'>active</strong>.
      </H1>

      <PlanText
        totalSpend={current_total_spend}
        startDate={current_start_date}
        endDate={current_end_date}
        plan={current_plan}
      />

      <P>
        Cloudthread computes your <b>last 31 days of spend</b> to determine your Tier.
      </P>

      {(
        tierChange &&
        next_total_spend &&
        next_start_date &&
        next_end_date &&
        next_plan
      ) && (
        <P>
          ATTENTION: Based on your upcoming cloud spend of <b>${formatNormal(next_total_spend)}</b> per month
          calculated from <b>{moment(next_start_date).format('YYYY/MM/DD')}</b>
          to <b>{moment(next_end_date).format('YYYY/MM/DD')}</b>
          your organization will move to the <b>{next_plan.name.toUpperCase()}</b> Tier.
       </P>
      )}

      <H2>
        Billing
      </H2>

      {current_subscription && (
        <P>
          Your Cloudthread Subscription will renew on <b>{moment(current_subscription.period_end).format('YYYY/MM/DD')}</b>.
        </P>
      )}

      <P>
        Cloudthread will email you seven days ahead of your renewal subscription if your tier has changed.
      </P>

      <BillingText />

      <UnsubscribeButton />
    </div>
  );
}
