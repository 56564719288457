import type { Opportunity } from 'types/savings';

import { noop } from 'helpers/utils';
import { Button2 as Button } from 'shared/Button';
import { useThreadSelectDialog } from 'store/savings';

export interface AssignSelectedButtonProps {
  selected: Opportunity[];
  onSelect: (selected: Opportunity[]) => void;
}

export const AssignSelectedButton = (props: AssignSelectedButtonProps) => {
  const {
    selected,
    onSelect = noop
  } = props;

  const openThreadSelectDialog = useThreadSelectDialog((store) => store.open);
  
  return (
    <Button
      size='xs'
      theme='link'
      layout='inline'
      iconLeft='arrow-right'
      disabled={selected.length === 0}
      onClick={() => {
        openThreadSelectDialog(selected.map(({ id }) => id));
        onSelect([]);
      }}
    >
      Assign To Thread
    </Button>
  );
}
