import type {
  Opportunity,
  OpportunityDraft,
  ValidOpportunityCreateDraft,
  ValidOpportunityUpdateDraft,
  OpportunityCreatePayload,
  OpportunityUpdatePayload,
  OpportunityDifficulty
} from 'types/savings';

export const opportunityToDraft = (opportunity: Opportunity): OpportunityDraft => {
 const {
    opportunity_type_id: opportunityTypeId,
    cost_impact: costImpact,
    record_id: recordId,
    thread_id: threadId,
  } = opportunity;

  const {
    opportunity_description: description,
  } = opportunity.opportunity_details || {};

  const recommendation = opportunity.opportunity_details?.opportunity_recommendation?.text || '';
  const difficulty: OpportunityDifficulty = (opportunity.opportunity_details?.difficulty as OpportunityDifficulty | undefined) || 'easy';

  const {
    aws_account_id: account,
    region_name: region,
    service
  } = opportunity.opportunity_details?.cost_attributes || {};

  return {
    opportunityTypeId,
    costImpact,
    recordId,
    threadId,
    difficulty,
    description,
    recommendation,
    account,
    region,
    service
  };
}

export const opportunityDraftToCreatePayload = (draft: ValidOpportunityCreateDraft): OpportunityCreatePayload => {
  const {
    costImpact,
    recordId,
    threadId,
    difficulty,
    description,
    recommendation,
    account,
    region,
    service
  } = draft;

  return {
    opportunity_type_id: 'manual_opportunity',
    cost_impact: costImpact,
    record_id: recordId,
    thread_id: threadId || null,

    opportunity_details: {
      difficulty,
      opportunity_description: description,
      opportunity_recommendation: { text: recommendation || '' },

      ...(account || region || service ? {
        cost_attributes : {
          ...(account ? { aws_account_id: account } : {}),
          ...(region ? { region_name: region } : {}),
          ...(service ? { service } : {})
        }
      } : {})
    }
  };
}

export const opportunityDraftToUpdatePayload = (draft: ValidOpportunityUpdateDraft): OpportunityUpdatePayload => {
  const {
    costImpact,
    threadId,
    difficulty,
    description,
    recommendation,
    account,
    region,
    service
  } = draft;

  return {
    ...(costImpact !== undefined ? { cost_impact: costImpact } : {}),
    ...(threadId !== undefined ? { thread_id: threadId } : {}),
    ...((
      difficulty ||
      description ||
      recommendation ||
      account ||
      region ||
      service
    ) ? {
      opportunity_details: {
        ...(difficulty ? { difficulty } : {}),
        ...(description ? { opportunity_description: description } : {}),
        ...(recommendation ? { opportunity_recommendation: { text: recommendation }} : {}),

        ...(account || region || service ? {
          cost_attributes : {
            ...(account ? { aws_account_id: account } : {}),
            ...(region ? { region_name: region } : {}),
            ...(service ? { service } : {})
          }
        } : {})
      }
    } : {})
  };
}

interface ValidationIssue<TField = string, TType = string> {
  fields: TField[];
  type: TType;
}

const withWeakMapCache = <P extends Object, R>(func: (param: P) => R): ((param: P) => R) => {
  const cache = new WeakMap<P, R>();
  
  return (param: P) => {
    const cachedResult = cache.get(param);

    if (cachedResult !== undefined) {
      return cachedResult;
    }

    const result = func(param);

    cache.set(param, result);

    return result;
  }
}

export const validateOpportunityCreateDraft = withWeakMapCache((draft: OpportunityDraft): ValidationIssue[] => {
  const issues: ValidationIssue[] = [];

  const requiredFields: (keyof OpportunityDraft)[] = [
    'recordId',
    'description'
  ];

  requiredFields.forEach((field) => {
    const value = draft[field];

    if (typeof value !== 'string' || value.match(/^\s*$/g)) {
      issues.push({ fields: [field], type: 'required' });
    }
  });

  return issues;
});

export const isValidOpportunityCreateDraft = (draft: OpportunityDraft): draft is ValidOpportunityCreateDraft => {
  const validationIssues = validateOpportunityCreateDraft(draft);

  return validationIssues.length === 0;
}

export const validateOpportunityUpdateDraft = withWeakMapCache((draft: OpportunityDraft): ValidationIssue[] => {
  const issues: ValidationIssue[] = [];

  const requiredFields: (keyof OpportunityDraft)[] = [
    'description'
  ];

  requiredFields.forEach((field) => {
    const value = draft[field];

    if (typeof value !== 'string' || value.match(/^\s*$/g)) {
      issues.push({ fields: [field], type: 'required' });
    }
  });

  return issues;
});

export const isValidOpportunityUpdateDraft = (draft: OpportunityDraft): draft is ValidOpportunityUpdateDraft => {
  const validationIssues = validateOpportunityUpdateDraft(draft);

  return validationIssues.length === 0;
}
