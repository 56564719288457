import type { EventsData } from 'types/costViews/events';
import type { ChartType } from 'types/chart';
import type { SuccessDataEntry } from 'types/dataEntries';
import type { UnitMetricChart, UnitMetricConstructor } from 'types/unitMetrics';

import { useMemo, useState } from 'react';

import { formatCompact, formatCurrency } from 'helpers/formatter';
import { validateUnitMetricFilter } from 'helpers/unitMetrics';

import { Link } from 'react-router-dom';
import { Page } from 'shared/Page';
import { Select } from 'antd';
import { DateFilter } from 'shared/DateFilter';
import { CostFilter } from 'shared/CostFilter';
import { Checkbox } from 'antd';
import { ActionButtons } from './ActionButtons';
import { FixedColumnLayout } from 'shared/FixedColumnLayout';
import { EditableTitle } from 'shared/EditableTitle';
import { ChartSection } from './ChartSection';
import { SettingsSection } from './SettingsSection';
import { DeleteDialog } from './DeleteDialog';
import { SaveAsNewDialog } from './SaveAsNewDialog';

import { useUnitMetricPage } from './useUnitMetricPage';
import { getSourceTitle } from 'shared/Filters/constants';
import { useGlobalState } from 'state/globalState';

export const UnitMetricPage = () => {
  const {
    name,
    filter,
    chart,
    id,
    custom,
    unitMetricConstructor,

    events,
    eventTypes,
    selectedEventTypes,
    setEventTypes,

    nameHasChanged,
    filterHasChanged,

    metricStatus,

    startDate,
    endDate,
    granularity,
    setDates,

    setName,
    setFilter,
    resetFilter,
    clearFilter,

    deleteDialogOpened,
    openDeleteDialog,
    closeDeleteDialog,
    confirmDeleteDialog,

    saveAsNew,
    saveAsNewDialogOpened,
    closeSaveAsNewDialog,
    confirmSaveAsNewDialog,

    saveUnitMetric,

    teamId,
    setTeamId,
    teamFilters,
    teamFilterConstructor
  } = useUnitMetricPage();

  const { user } = useGlobalState();

  const [chartType, setChartType] = useState<ChartType>('line')
  const [evnetsOverlay, setEventsOverlay] = useState<boolean>(false)
  const [eventsToDisplay, setEventsToDisplay] = useState<EventsData[]>([])

  const handleSetEventsToDisplay = (targetEvents: EventsData[]) => {
    if (targetEvents.length || eventsToDisplay.length) {
      setEventsToDisplay(targetEvents)
    }
  }

  const metricIsNew = id === null;

  const chartSectionStatus: 'empty' | 'error' | 'loading' | 'success' = useMemo(() => {
    for (const status of ['error', 'loading']) {
      if (metricStatus === status || chart.status === status || unitMetricConstructor.status === status) {
        return status as 'error' | 'loading';
      }
    }

    if (!filter || !validateUnitMetricFilter(filter, (unitMetricConstructor as SuccessDataEntry<UnitMetricConstructor>).data)) {
      return 'empty';
    }

    return 'success';
  }, [metricStatus, chart.status, filter, unitMetricConstructor.status]);

  const chartData = chartSectionStatus === 'success' ? (chart as SuccessDataEntry<UnitMetricChart>).data : null;

  const settingsSectionStatus = useMemo(() => {
    const statuses = ['error', 'loading', 'idle'];

    for (const status of statuses) {
      if (metricStatus === status || unitMetricConstructor.status === status) {
        return status;
      }
    }

    return 'success';
  }, [metricStatus, unitMetricConstructor.status]);

  const pageTitle = useMemo(() => {
    if (metricStatus === 'loading') {
      return 'Loading';
    }

    return <div className='flex flex-col items-start min-w-[160px]'>
      <EditableTitle value={name || ''} onChange={setName} />
      {filter &&
        <span className='text-[10px] line-[18px] text-black/50 font-medium'>
          Source: {getSourceTitle(filter.numerator.src)} / {filter.denominator.src === 'cur' ? 'AWS Usage' : getSourceTitle(filter.denominator.src)}
        </span>  
      }
    </div>;
  }, [metricStatus, name, setName, filter]);

  const savingAsNewDisabled = !filter ||
    unitMetricConstructor.status !== 'success' ||
    !validateUnitMetricFilter(filter, unitMetricConstructor.data) ||
    (!metricIsNew && !filterHasChanged && !nameHasChanged);

  const savingDisabled = !name || savingAsNewDisabled;

  const prevStartDate = startDate.clone().subtract(endDate.diff(startDate, 'days') + 1, 'days')
  const prevEndDate = endDate.clone().subtract(endDate.diff(startDate, 'days') + 1, 'days')

  const format = filter && ['cur', 'gcp'].includes(filter.numerator.src) ? formatCurrency : formatCompact;

  return (
    <Page>
      <Page.Head>
        <div>
          {pageTitle ? (
            <div className='text-[10px] font-medium line-[18px]'>
              <Link
                to='/unit-metrics/library'
                className='text-cyanine-blue hover:text-caladon-blue'
              >
                Unit Metrics
              </Link>
            </div>
          ) : null}
          {pageTitle}
        </div>
        <ActionButtons
          del={{
            available: !metricIsNew && !!custom,
            onClick: openDeleteDialog
          }} 
          save={{
            available: !metricIsNew && !!custom,
            disabled: savingDisabled,
            onClick: saveUnitMetric
          }} 
          saveAsNew={{
            disabled: savingAsNewDisabled,
            onClick: saveAsNew
          }}
          newAlert={{ 
            available: !metricIsNew && !!custom,
            to: `/notification-center?new_metric_alert=${id}`
          }}
          newReport={{ 
            available: !metricIsNew && !!custom,
            to: `/notification-center?new_metric_report=${id}`
          }}
        />
        <div className='flex items-center mx-4'>
          <Select
            className="min-w-[80px]"
            options={[
              { value: 'line', label: 'Line'},
              { value: 'bar', label: 'Bar'},
            ]}
            onChange={(key) => {
              setChartType(key);
            }}
            value={chartType}
          />
          <div className='ml-6'>
            <Checkbox
              checked={evnetsOverlay}
              onChange={() => {
                setEventsOverlay(!evnetsOverlay);
              }}
              disabled={!(!metricIsNew && !!custom)}
            >
              Events
          </Checkbox>
          </div>
        </div>
        <CostFilter />
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          granularity={granularity}
          onChange={setDates}
        />
      </Page.Head>

      <FixedColumnLayout
        columnVisible
        columnFixed
        column={(
          <SettingsSection
            status={settingsSectionStatus}
            filter={filter}
            setFilter={setFilter}
            resetFilter={resetFilter}
            clearFilter={clearFilter}
            filterHasChanged={filterHasChanged}
            unitMetricConstructor={unitMetricConstructor}
            teamId={teamId}
            setTeamId={setTeamId}
            teamFilters={teamFilters}
            teamFilterConstructor={teamFilterConstructor}
          />
        )}
      >
        <div className='grid grid-cols-1 gap-[15px] mb-[15px]'>
          <ChartSection
            chart={chartData}
            status={chartSectionStatus}
            type='unit_metric'
            chartType={chartType}
            granularity={granularity}
            startMoment={startDate}
            endMoment={endDate}
            prevStartMoment={prevStartDate}
            prevEndMoment={prevEndDate}
            events={events}
            setEventsToDisplay={handleSetEventsToDisplay}
            eventsToDisplay={eventsToDisplay}
            evnetsOverlay={evnetsOverlay}
            eventTypes={eventTypes}
            selectedEventTypes={selectedEventTypes}
            setEventTypes={setEventTypes}
            format={format}
          />
          <ChartSection
            chart={chartData}
            status={chartSectionStatus}
            granularity={granularity}
            type='numerator'
            chartType={chartType}
            startMoment={startDate}
            endMoment={endDate}
            prevStartMoment={prevStartDate}
            prevEndMoment={prevEndDate}
            format={format}
          />
          <ChartSection
            chart={chartData}
            status={chartSectionStatus}
            granularity={granularity}
            type='denominator'
            format={formatCompact}
            chartType={chartType}
            startMoment={startDate}
            endMoment={endDate}
            prevStartMoment={prevStartDate}
            prevEndMoment={prevEndDate}
          />
        </div>
      </FixedColumnLayout>

      {name !== null && 
        <SaveAsNewDialog
          name={name}
          metricIsNew={metricIsNew}
          nameHasChanged={nameHasChanged}
          onNameChange={setName}
          opened={saveAsNewDialogOpened}
          onClose={closeSaveAsNewDialog}
          onConfirm={confirmSaveAsNewDialog}
        />
      }

      <DeleteDialog
        opened={deleteDialogOpened}
        onClose={closeDeleteDialog}
        onConfirm={confirmDeleteDialog}
      />
    </Page>
  );
}
