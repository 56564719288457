import type { DataEntry } from 'types/dataEntries';
import type { Budget } from 'types/costViews';
import type { CostViewChart, CostViewForecastInfo } from 'types/costViews/charts';

import { Icon } from 'shared/Icon';
import { Branch } from 'shared/Filters';
import { ValueBox } from 'shared/ValueBox';
import { Button2 as Button } from 'shared/Button';

import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { formatPercent, Granularity } from 'helpers/formatter';
import { useForecastDialog } from 'store/forecastDialog';

export interface EstTotalValueProps {
  granularity: Granularity;
  chartData: DataEntry<CostViewChart>;
  forecast: DataEntry<CostViewForecastInfo> | null;
  budget: Budget | null;
  format: (value: number) => string;
}

export const EstTotalValue = (props: EstTotalValueProps) => {
  const {
    granularity,
    chartData,
    forecast,
    budget,
    format
  } = props

  const value = useDerivedDataEntry({
    chartData,
    forecast,
    budget,
  }, ({ 
    chartData,
    forecast,
    budget
  }) => {
    const forecastTotal = forecast ? forecast.data.reduce((sum, item) => sum + item.forecast, 0) : 0;
    const currentTotal = chartData.reduce((sum, item) => sum + (item.current_timeframe_cost ?? 0), 0);
    const previousTotal = chartData.reduce((sum, item) => sum + (item.previous_timeframe_cost ?? 0), 0);

    const estTotal = forecastTotal + currentTotal;
    const estChange = estTotal / previousTotal - 1;
    const estOverBudget = budget ? estTotal / budget.value - 1 : null;

    return {
      estTotal,
      estChange,
      estOverBudget,
      currentTotal,
      forecastTotal,
      previousTotal,
      hasForecast: !!forecast?.data.length
    };
  });

  const openForecastDialog = useForecastDialog((store) => store.open);

  return value.status === 'success' ? (
    <div className='-ml-6'>
      <ValueBox
        label={value.data.hasForecast ? 'Estimated Total' : 'Current total'}
      >
        <div className='flex items-center gap-3'>
          <div className='my-1 text-large-title'>
            {format(value.data.estTotal)}
          </div>
          
          <div className='flex flex-col text-caption-2 gap-1.5'>
            {value.data.previousTotal !== 0 && 
              <div>
                <span className={`p-0.5 rounded-1 ${value.data.estChange > 0 ? 'bg-red/40' : 'bg-green/50'}`}>
                  {value.data.estChange > 0 && '+'}
                  {formatPercent(value.data.estChange)}
                </span> from previous
              </div>
            }

            {value.data.estOverBudget !== null && <div>
              <span className={`p-0.5 rounded-1 ${value.data.estOverBudget > 0 ? 'bg-red/40' : 'bg-green/50'}`}>
                {value.data.estOverBudget > 0 && '+'}
                {formatPercent(value.data.estOverBudget)}
              </span> from budget
            </div>}
          </div>
        </div>
      </ValueBox>

      {value.data.hasForecast ? 
        <Branch last theme='dark'>
          <div className='flex items-end pt-1 pl-2 gap-2 text-subtitle-1'>
            <ValueBox
              label={`${granularity === 'quarter' ? 'Quarter' : 'Month'} to Date`}
            >
              {format(value.data.currentTotal)}
            </ValueBox>

            <div className='font-medium'>+</div>

            <ValueBox
              label='Forecast'
            >
              <Button
                className='!font-normal'
                layout='inline'
                theme='black'
                size='l'
                onClick={openForecastDialog}
              >
                {format(value.data.forecastTotal)}
                <Icon icon='edit' />
              </Button>
            </ValueBox>
          </div>
        </Branch> :
        null
      }
    </div>
  ) : null;
};
