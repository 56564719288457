import { 
  OpportunityStatus, 
  OpportunityFilterConfigs, 
  OpportunityFilterValue,
  OpportunityTypeId, 
} from 'types/savings';

export const EMPTY_OPPORTUNITY_FILTER: OpportunityFilterValue = {
  text_search: '',
  //costImpact: { min: null, max: null },
  //priority: { min: null, max: null },
  difficulty: [],
  //group: [],
  category: [],
  account: [],
  region: [],
  service: [],
  tags: [],
  account_tags: [],
  //platform: []
};

export const OPPORTUNITY_FILTER_CONFIGS: OpportunityFilterConfigs = {
  text_search: {
    type: 'query',
    title: ''
  },
  category: {
    type: 'list',
    title: 'Category',
    searchable: true
  },
  /*
  costImpact: {
    type: 'number-range',
    title: 'Est. Savings / Mo',
    prefix: '$',
    format: ({ min, max }) =>
      max === null ? `≥ $${min}` :
      min === null ? `≤ $${max}` :
      `$${min}–${max}`
  },
  */
  difficulty: {
    type: 'list',
    title: 'Difficulty'
  },
  /*
  priority: {
    type: 'number-range',
    title: 'Priority',
    step: 1,
    format: ({ min, max }) => `Priority: ${
      max === null ? `≥ ${min}` :
      min === null ? `≤ ${max}` :
      `${min}–${max}`
    }`
  },
  */
  service: {
    type: 'list',
    title: 'Service',
    searchable: true
  },
  /*
  group: {
    type: 'list',
    title: 'Group'
  },

  */
  tags: {
    type: 'list',
    title: 'Resource Tags',
    tags: true,
    searchable: true
  },
  account: {
    type: 'list',
    title: 'Account',
    searchable: true
  },
  account_tags: {
    type: 'list',
    title: 'Account Tags',
    tags: true,
    searchable: true
  },
  region: {
    type: 'list',
    title: 'Region',
    searchable: true
  },
  /*
  platform: {
    type: 'list',
    title: 'Platform'
  }
  */
};

export const ACTIVE_OPPORTUNITY_STATUSES: OpportunityStatus[] = [
  'unassigned',
  'assigned', 
  'validation',
  'implementation',
  'rejected',
];

export const INPROGRESS_OPPORTUNITY_STATUSES: OpportunityStatus[] = [
  'assigned', 
  'validation',
  'implementation',
];

export const AUTOMATABLE_OPPORTUNITY_TYPE_IDS: readonly OpportunityTypeId[] = [
  '0004_unused_elastic_ips',
  '0037_rds_idle_instances',
  '0109_s3_intelligent_tiering',
  '0110_idle_nat_gateways'
] as const;

export const AUTOMATABLE_OPPORTUNITY_TYPE_IDS_SET = new Set(AUTOMATABLE_OPPORTUNITY_TYPE_IDS);

export const AUTOMATION_DESCRIPTIONS: Record<typeof AUTOMATABLE_OPPORTUNITY_TYPE_IDS[number], string> = {
  '0004_unused_elastic_ips': 'Cloudthread will delete the unused Elastic IP. This action cannot be rolled back.',
  '0037_rds_idle_instances': 'Cloudthread will delete the unused NAT Gateway. This action cannot be rolled back.',
  '0109_s3_intelligent_tiering': 'Cloudthread will turn on S3 Intelligent Tiering for the target bucket. This action can be rolled back up turning S3 Intelligent Tiering off.',
  '0110_idle_nat_gateways': 'Cloudthread will make a final snapshot of the database, then delete the database. This action cannot be rolled back once deletion is triggered.'
};
