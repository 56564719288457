import type { GlobalFilter } from 'types/globalFilters';

import { useState, ReactNode } from 'react';

import { IconButton } from 'shared/Button';

export interface GlobalFilterBlockProps {
  filter: GlobalFilter;
  editGlobalFilter: (data: GlobalFilter) => void;
  deleteGlobalFilter: (data: GlobalFilter) => void;
}

export const GlobalFilterBlock = (props: GlobalFilterBlockProps) => {
  const { filter, editGlobalFilter, deleteGlobalFilter } = props;

  let filtersTitle: ReactNode | null = null;
  
  return (
    <div className='border rounded border-silver-grey my-[15px] p-[15px]'>
      <div className='flex'>
        <div className='mr-auto font-medium'>
          {filter.name}
          {filter.id &&
            <span className='ml-2 font-normal text-[12px] text-black/50'>
              ID: {filter.id}
            </span>
          }
        </div>

        <div className='flex items-center'>
          {filtersTitle}
          <IconButton
            size='sm'
            type='tertiary'
            icon='edit'
            onClick={() => {
              editGlobalFilter(filter);
            }}
          />

          <IconButton
            size='sm'
            type='tertiary'
            icon='trash'
            onClick={() => {
              deleteGlobalFilter(filter);
            }}
          />
        </div>
      </div>
    </div>
  );
}
