import type {
  OpportunityDifficulty,
  OpportunityStatus,
} from './opportunities';

import type {
  ListDataFilterValue,
  ListDataFilterOptions,
  QueryDataFilterConfig,
  ListDataFilterConfig,
  DataFilterValuesMap,
  DataFilterOptionsMap,
  DataFilterConfigsMap
} from 'types/dataFilters';

export type StatusFilterValue = OpportunityStatus | 'active' | 'in_progress' | 'all';

export const STATUS_FILTER_VALUES: StatusFilterValue[] = ['active', 'in_progress', 'all', 'unassigned', 'assigned', 'validation', 'implementation', 'done', 'trash'];

export const isStatusFilterValue = (value: any): value is StatusFilterValue => STATUS_FILTER_VALUES.includes(value);

export interface OpportunityFilterConfigs extends DataFilterConfigsMap<
  'text_search' |
  'difficulty' |
  'category' |
  'account' |
  'region' |
  'service' |
  'tags' |
  'account_tags'
>{
  text_search: QueryDataFilterConfig;
  //costImpact: NumberRangeDataFilterConfig;
  //priority: NumberRangeDataFilterConfig;
  difficulty: ListDataFilterConfig;
  //group: ListDataFilterConfig;
  category: ListDataFilterConfig;
  account: ListDataFilterConfig;
  region: ListDataFilterConfig;
  service: ListDataFilterConfig;
  tags: ListDataFilterConfig;
  account_tags: ListDataFilterConfig;
  //platform: ListDataFilterConfig;
}

export interface OpportunityFilterValue extends DataFilterValuesMap<keyof OpportunityFilterConfigs, OpportunityFilterConfigs> {
  text_search: string;
  //costImpact: RangeDataFilterValue<number>;
  //priority: RangeDataFilterValue<number>;
  difficulty: ListDataFilterValue<OpportunityDifficulty>;
  //group: ListDataFilterValue<'usage' | 'rate' | 'custom'>;
  category: ListDataFilterValue<string>;
  account: ListDataFilterValue<string>;
  region: ListDataFilterValue<string>;
  service: ListDataFilterValue<string>;
  tags: ListDataFilterValue<string>;
  account_tags: ListDataFilterValue<string>;
  //platform: ListDataFilterValue<Platform>;
}

export interface OpportunityFilterOptions extends DataFilterOptionsMap<keyof OpportunityFilterConfigs, OpportunityFilterConfigs> {
  //costImpact: RangeDataFilterOptions<number>;
  //priority: RangeDataFilterOptions<number>;
  difficulty: ListDataFilterOptions<OpportunityDifficulty>;
  //group: ListDataFilterOptions<'usage' | 'rate' | 'custom'>;
  category: ListDataFilterOptions<string>;
  account: ListDataFilterOptions<string>;
  region: ListDataFilterOptions<string>;
  service: ListDataFilterOptions<string>;
  tags: ListDataFilterOptions<string>;
  //account_tags: ListDataFilterOptions<string>;
  //platform: ListDataFilterOptions<Platform>;
}

export const OPPORTUNITY_SORT_FIELDS = [
  'id',
  'recordId',
  'costImpact',
  'difficulty',
  'group',
  'category',
  'status',
  //'account',
  //'region',
  //'service',
  //'tags',
  //'account_tags',
  //'firstDetected',
  //'lastDetected',
  'priority'
] as const;

export type OpportunitySortField = typeof OPPORTUNITY_SORT_FIELDS[number];

export const isOpportunitySortField = (value: any): value is OpportunitySortField => OPPORTUNITY_SORT_FIELDS.includes(value);

export type SortDir = 'ascend' | 'descend';

export const isSortDir = (value: any): value is SortDir => value === 'ascend' || value === 'descend';

export interface OpportunitySort {
  field: OpportunitySortField;
  dir: SortDir;
}

export interface OpportunityPaging {
  perPage: number;
  currentPage: number;
}

export interface OpportunityPagination {
  ids: number[];
  perPage: number;
}
