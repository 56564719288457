export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Grid = (props: GridProps) => {
  const {
    className = ''
  } = props;

  return (
    <div
      {...props}
      className={`grid grid-cols-12 gap-[10px] ${className}`}
    />
  );
};
