import type { HTMLAttributes } from 'react';

export interface PortalRootProps extends HTMLAttributes<HTMLDivElement> {}

export const PortalRoot = (props: PortalRootProps) => {
  const { children, className = '', ...rest } = props;

  return (
    <div className={`relative ${className}`} {...rest} data-portal-root='true'>
      {children}
      <div className='absolute t-0 l-0 r-0' data-portal-exit='true'></div>
    </div>
  );
};
