import { StatusOverlay } from 'shared/Overlay';

import { useEffect, useState } from 'react';
import { Icon } from 'shared/Icon';
import { Select } from 'antd';
import { ThresholdsTable } from './ThresholdsTable';
import { ThresholdEditDialog } from './ThresholdEditDialog';

import { isSavingsDataConfig } from 'types/dataCollection';
import { mergeThresholds } from 'helpers/dataCollection';

import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useTextQueryParam } from 'hooks/queryParams';
import { useDataConfigWithThresholdsStore } from 'store/dataConfigs';
import {JobLogTable} from 'shared/JobLogTable';
import {useLocation} from 'react-router-dom';

export const SavingsOpportunitiesTab = () => {
  const dataConfigs = useDataConfigWithThresholdsStore((store) => store.getLibrary());
  const thresholdsMap = useDataConfigWithThresholdsStore((store) => store.getThresholdsMap());
  const getDetailedEntry = useDataConfigWithThresholdsStore((store) => store.getDetailedEntry);
  const detailedEntries = useDataConfigWithThresholdsStore((store) => store.detailedEntries);

  const [configId, setConfigId] = useState<'all' | number>('all');
  const detailedConfig = configId !== 'all' ? detailedEntries.get(configId) : null

  const hash = useLocation().hash;

  const savings = useDerivedDataEntry({ 
    dataConfigs, 
    thresholdsMap, 
    detailedConfig 
  }, ({ dataConfigs, thresholdsMap, detailedConfig }) => {
    const configs = dataConfigs.filter(isSavingsDataConfig);
    const summary = mergeThresholds(thresholdsMap);

    return {
      configs,
      summary,
      detailedConfig
    }
  });

  const accountOptions = useDerivedDataEntry(
    { savings },
    ({ savings }) => [
      {
        value: 'all',
        label: 'All'
      },
      ...savings.configs.map((config) => {
        const awsAccountId = config.collection_details.aws_account_id;
        const awsAccountName = config.collection_details.aws_account_name;

        const label = awsAccountName ? `${awsAccountName} (${awsAccountId})` : awsAccountId;

        return {
          value: config.id,
          label
        };
      })
    ]
  );

  useEffect(() => {
    if (hash === '#automation-log' && savings.status === 'success') {
      const el = document.getElementById('automation-log');

      if (el) {
        window.scrollTo(0, el.offsetTop);
      }
    }
  }, [hash, savings.status])


  return (
    <div className='relative mt-5'>
      <h2 className='mb-5 font-bold text-subtitle-1'>
        Cost Savings Opportunities
      </h2>
      <p>View and customize all Cloudthread computed opportunities</p>


      <div className='flex items-center mt-10 mb-5'>
        <Icon icon='aws' className='h-[24px] mr-[10px]'/>

        <h3 className='font-bold text-subtitle-2'>
          Amazon Web Services
        </h3>

        <div className='ml-auto'>
          <b className='mr-2'>
            Account:
          </b>

          <Select
            className='min-w-[200px]'
            disabled={accountOptions.status !== 'success'}
            options={accountOptions.status === 'success' ? accountOptions.data : []}
            value={configId}
            onChange={(value) => {
              if (typeof value === 'number') {
                getDetailedEntry(value)
              }
              setConfigId(value)
            }}
          />
        </div>
      </div>

      <ThresholdsTable
        dataConfig={savings.status === 'success' && savings.data.configs.length > 0 ? (
          configId === 'all' ? 
            savings.data.summary :
            savings.data.detailedConfig ?? null
        ) : null}
      />

      <StatusOverlay status={savings.status} />

      <h3 className='font-bold text-subtitle-2' id='automation-log'>
        Opportunities Automation Log
      </h3>

      <JobLogTable />

      <ThresholdEditDialog />
    </div>
  );
}
