import type { DataEntry } from 'types/dataEntries';
import type { OpportunityFilterOptions, OpportunityPrefilterQuery } from 'types/savings';

import {
  renderOpportunityDifficulty,
} from 'helpers/savings';

import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useSavingsSettingsStore } from 'store/savings';
import { useOpportunityFilters } from 'store/savings/opportunityFilters';

const AUTOMATION_CATEGORIES = [
  'Unused Elastic IPs',
  'RDS Idle Instances',
  'Missing S3 Intelligent Tiering',
  'Idle NAT Gateways'
];

const withAutomation = (options: { value: string, text: string }[]) => options.map((opt) => {
  if (AUTOMATION_CATEGORIES.includes(opt.value)) {
    return {
      ...opt,
      text: `${opt.text} (automation)`
    }
  }

  return opt;
});

const toFilterOptions = <T,>(array: T[], aliases: { [key: string]: string } = {}) => array
  .sort((a, b) => a > b ? 1 : -1)
  .map((item) => ({
    value: item,
    text: aliases && aliases[item as any] ? `${aliases[item as any]} (${item})` : item
  }));

const toTagOptions = (tags: Record<string, string[]>) => 
  Object.entries(tags)
    .map(([key, values]) => ({
      text: key,
      value: key,
      children: values.map((value) => ({
        text: value,
        value: `${key}:${value}`
      }))
    }));

export const useOpportunityFilterOptions = (prefilter: OpportunityPrefilterQuery): DataEntry<OpportunityFilterOptions> => {
  const pageFilters = useSavingsSettingsStore();
  const backendFilters = useOpportunityFilters({
    prefilter,
    pageFilters,
  });

  return useDerivedDataEntry(
    { backendFilters },
    ({ backendFilters }) => {
      const {
        aws_account_id,
        aws_account_aliases,
        //status,
        difficulty,
        //priority,
        category,
        //bill_payer_account_id,
        region_name,
        service,
        tags,
        account_tags
      } = backendFilters;

      return {
        difficulty: ['easy', 'medium', 'hard']
          .filter((value) => difficulty.includes(value as any))
          .map((value) => ({
            value,
            text: renderOpportunityDifficulty({ opportunity_details: { difficulty: value }, record_id: ' '} as any)
          })) as any,
        account: toFilterOptions(aws_account_id, aws_account_aliases),
        service: toFilterOptions(service),
        region: toFilterOptions(region_name),
        category: withAutomation(toFilterOptions(category)),
        tags: toTagOptions(tags),
        account_tags: toTagOptions(account_tags)
        /*
          costImpact: 
          priority: 
          group: 
          category: 
          tags: 
          account_tags: 
          platform: 
        */
      }
    }
  );
  /*
  useDerivedDataEntry(
    { opportunities },
    ({ opportunities }) => ({
      costImpact: pickCostImpactOptions(opportunities),
      priority: pickPriorityOptions(opportunities),
      difficulty: pickDifficultyOptions(opportunities),
      group: pickGroupOptions(opportunities),
      category: pickCategoryOptions(opportunities),
      account: pickAccountOptions(opportunities),
      region: pickRegionOptions(opportunities),
      service: pickServiceOptions(opportunities),
      tags: pickTagsOptions(opportunities),
      account_tags: pickAccountTagsOptions(opportunities),
      platform: pickPlatformOptions(opportunities)
    })
  );
  */
}
