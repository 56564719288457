import { Section, SectionTitle } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { JSONDump } from './JSONDump';
import { useSavingsStore } from 'store/savings';

export interface OpportunityCostDetailsSectionProps {
  opportunityId: number;
  className?: string;
}

export const OpportunityCostDetailsSection = (props: OpportunityCostDetailsSectionProps) => {
  const { opportunityId, className = '' } = props;

  const opportunity = useSavingsStore((store) => store.getOpportunity(opportunityId));

  if (opportunity.status == 'success' && Object.keys(opportunity.data.opportunity_details?.cost_impact_details || {}).length === 0) {
    return null;
  }

  return (
    <Section className={`p-5 relative ${className}`}>
      <SectionTitle className='mb-4'>
        Cost Impact Details
      </SectionTitle>

      <JSONDump>
        {opportunity.status === 'success' ? opportunity.data.opportunity_details?.cost_impact_details || {} : ''}
      </JSONDump>

      <StatusOverlay status={opportunity.status} />
    </Section>
  );
};
