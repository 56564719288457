import create from 'zustand';
import { message } from 'antd';
import {
  fetchUnitMetricsLibrary,
  deleteUnitMetric
} from 'services/unitMetrics';
import type { UnitMetricsLibraryEntry } from 'types/unitMetrics';
import moment from 'moment';
import { SERVER_DATE_FORMAT } from 'helpers/constants'

export type Source = 'cur' | 'cloudwatch';

export interface UnitMetric {
  id: number;
  src: Source;
  name: string;
  numerator: 'service' | 'total';
  denominator: string;
  service: string;
  numerator_sum: number | null;
  prev_numerator_sum: number | null;
  denominator_sum: number | null;
  prev_denominator_sum: number | null;
  weighted_average: number | null;
  prev_weighted_average: number | null;
  daily_average: number | null;
  pre_daily_average: number | null;
  wow_change: number | null;
  is_custom: boolean;
  default_filters?: {
    default_cost_metric_filter_id: number | null;
    team_id: number | null;
    team_name: string | null;
  }
}

interface State {
  loading: boolean;
  startDate: string;
  endDate: string;
  unitMetrics: UnitMetricsLibraryEntry[];
  fetchUnitMetrics: () => void;
  metricToDelete: UnitMetricsLibraryEntry | null;
  unsetMetricToDelete: () => void;
  setMetricToDelete: (unitMetric: UnitMetricsLibraryEntry) => void;
  deleteMetric: () => void;
  setPeriod: (
    startDate: string,
    endDate: string,
  ) => void;
}

export const useUnitMetricsLibraryStore = create<State>((set, get) => ({
  loading: true,
  startDate: moment().subtract(8, 'days').format(SERVER_DATE_FORMAT),
  endDate: moment().subtract(1, 'days').format(SERVER_DATE_FORMAT),
  unitMetrics: [],
  fetchUnitMetrics: () => {
      const { startDate, endDate } = get()
      fetchUnitMetricsLibrary(startDate, endDate).then((res) => {
        set({
          loading: false,
          unitMetrics: res.data
        })
      })
  },

  setPeriod: async (
    startDate: string,
    endDate: string,
  ) => {
    set({ startDate, endDate, loading: true })

    get().fetchUnitMetrics()
  },

  metricToDelete: null,
  setMetricToDelete: (metricToDelete) => {
    set({ metricToDelete });
  },

  unsetMetricToDelete: () => {
    set({ metricToDelete: null });
  },

  deleteMetric: async () => {
    const { metricToDelete, unsetMetricToDelete, unitMetrics } = get();

    if (metricToDelete === null) {
      return;
    }

    set({
      unitMetrics: unitMetrics.filter((metric) => metric.id !== metricToDelete.id)
    });

    try {
      await deleteUnitMetric(metricToDelete.id);
      message.success(`Unit metric '${metricToDelete.name}' deleted`);
      unsetMetricToDelete();
    } catch {
      message.error(`Unable to delete '${metricToDelete.name}'`);

      set({ unitMetrics });
      unsetMetricToDelete();
    }
  }
}));
