import { fetchJobLog } from 'services/savings';
import { createKeyedStore } from '../createKeyedStore';
import { createKeyedStoreSelector } from 'store/createKeyedStoreSelector';
import { identity } from 'helpers/utils';

export const useJobLogsStore = createKeyedStore({
  fetchData: fetchJobLog,
  paramsToKey: identity
});

export const useJobLogs = createKeyedStoreSelector(useJobLogsStore);
