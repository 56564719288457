export const ENFORCE_TEAM_ASSIGNMENT_ROLES = ['member'] as const;
export type EnforceTeamAssignmentRole = typeof ENFORCE_TEAM_ASSIGNMENT_ROLES[number];
export const HIDDEN_FEATURES = ['Costs Transparency', 'Unit Economics', 'Tagging'] as const;
export type HiddenFeature = typeof HIDDEN_FEATURES[number];
  
export interface OrgSettings {
  teams_mutually_exclusive_aws?: boolean;
  teams_mutually_exclusive_gcp?: boolean;
  teams_mutually_exclusive_custom_data?: boolean;
  enforce_team_assignment?: EnforceTeamAssignmentRole[];
  hidden_features?: HiddenFeature[];
  rate_optimization_allowed?: boolean;
}

export interface Org {
  id: number;
  settings: OrgSettings;
}
