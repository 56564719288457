import type { UserUpdateEmailPayload } from 'types/users';
import { useState } from 'react';
import { message, Input } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button } from 'shared/Button';
import { validateEmail } from 'helpers/validator'
import { useGlobalState } from 'state/globalState';

export interface UpdateUserEmailDialogProps {
  email: string;
  opened: boolean;
  close: () => void;
}

export const UpdateUserEmailDialog = (props: UpdateUserEmailDialogProps) => {
  const updateUserEmail = useGlobalState((store) => store.updateUserEmail); 

  const { 
    email,
    opened,
    close
  } = props;
  
  const [currentDraft, setCurrentDraft] = useState<UserUpdateEmailPayload>(
    {'current_password': '', 'email': email}
  )
  const canSave = currentDraft.current_password !== '' && currentDraft.email !== '' 
    && currentDraft.email !== email

  return (
    <Dialog
      className='w-[480px]'
      open={opened}
      onClose={() => {
        close();
      }}
    >
      <Dialog.Title>
        Update User Password
      </Dialog.Title>

      <div className='overflow-x-auto px-[30px] border-t'>
        <div className='mt-4 mb-2 font-medium text-subtitle-1'>
          New Email
        </div>
        <Input
          className='mb-[20px]'
          value={currentDraft.email || email}
          onChange={(event) => {
            const email = event.target.value;

            setCurrentDraft({
              ...currentDraft,
              email,
            });
          }}
        />

        <div className='mb-2 font-medium text-subtitle-1'>
          Current Password
        </div>
        <Input
          className='mb-[20px]'
          value={currentDraft.current_password}
          onChange={(event) => {
            const current_password = event.target.value;

            setCurrentDraft({
              ...currentDraft,
              current_password,
            });
          }}
        />
      </div>

      <div className='flex justify-end p-[15px] border-t'>
        <Button
          style='caladon'
          label='Update User'
          disabled={!canSave}
          onClick={() => {
            if (!validateEmail(currentDraft.email)) {
              message.error('Invalid email format')
              return
            }
            updateUserEmail(currentDraft);
            close();
          }}
        />
      </div>
    </Dialog>
  );
};
