import { message } from 'antd';

import { Button2 as Button } from 'shared/Button';
import { useNavigate } from 'react-router-dom';
import { useSavingsStore } from 'store/savings';
import { isValidOpportunityCreateDraft } from 'helpers/savings';

export interface OpportunityCreateSubmitProps {
  opportunityKey: string;
  disabled?: boolean;
  onSubmit?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}

export const OpportunityCreateSubmit = (props: OpportunityCreateSubmitProps) => {
  const navigate = useNavigate();

  const {
    opportunityKey,
    disabled,
    onSubmit,
    onSuccess,
    onError
  } = props;

  const draft = useSavingsStore((store) => store.getOpportunityDraft(opportunityKey));
  const createOpportunity = useSavingsStore((store) => store.createOpportunity);
  const resetOpportunityDraft = useSavingsStore((store) => store.resetOpportunityDraft);

  return (
    <Button
      size='m'
      theme='filled'
      disabled={!isValidOpportunityCreateDraft(draft) || disabled}
      onClick={() => {
        onSubmit && onSubmit();
        createOpportunity(opportunityKey)
          .then((opportunity) => {
            onSuccess && onSuccess();
            resetOpportunityDraft(opportunityKey);
            message.success(`Opportunity OPP-${opportunity.secondary_id} created`);

            return opportunity;
          })
          .then((opportunity) => {
            navigate(`/savings/opportunities/${opportunity.id}`);
          })
          .catch(() => {
            message.error('Unable to create opportunity');
            onError && onError();
          });
      }}
    >
      Create Opportunity
    </Button>
  );
}
