import {
  useRulesLibrary,
  useRuleDeleteDialogStore
} from 'store/rules';

import { Table } from 'antd';
import { Page } from 'shared/Page';
import { Button2 as Button } from 'shared/Button';
import { Section } from 'shared/Section';
import { Loading } from 'shared/Loading';
import { DeleteDialog } from '../Rule/DeleteDialog';
import { getSourceTitle } from 'shared/Filters';
import { Link } from 'react-router-dom';

export const RulesLibraryPage = () => {
  const library = useRulesLibrary();
  const openDeleteDialog = useRuleDeleteDialogStore((store) => store.open);
  
  return (
    <Page>
      <Page.Head title='Rules Library'>
        <Button
          size='xs'
          theme='black'
          layout='inline'
          iconLeft='circle-plus'
          to='/rules'
        >
          Create New Rule
        </Button>
      </Page.Head>
      
      <Section className='relative min-h-[320px] p-[20px]'>
        {library.status === 'success' && (
          <Table
            size='small'
            dataSource={library.data}
            rowKey='id'
            columns={[
              {
                key: 'name',
                dataIndex: 'name',
                title: 'Name',
                render: (name, { id }) => <Link to={`/rules/${id}`}>{name}</Link>
              },
              {
                key: 'src',
                dataIndex: 'metric_source',
                title: 'Source',
                render: getSourceTitle
              },
              {
                key: 'delete',
                dataIndex: 'id',
                className: 'w-[24px]',
                render: (id) => (
                  <Button
                    size='s'
                    theme='gray'
                    layout='inline'
                    icon='trash'
                    className='hover:!text-red'
                    onClick={() => {
                      openDeleteDialog(id);
                    }}
                  />
                )
              }
            ]}
          />
        )}
        {library.status === 'loading' && (
          <Loading />
        )}
      </Section>

      <DeleteDialog />
    </Page>
  );
}
