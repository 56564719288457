import type { Setter } from 'types/common';

import { useMemo } from 'react';
import { Select } from 'antd';
import { useTeamsStore } from 'store/teams';

export interface TeamFilterSelectProps {
  value: number | null;
  onChange?: Setter<number | null> | null;
}

export const TeamFilterSelect = (props: TeamFilterSelectProps) => {
  const {
    value,
    onChange
  } = props;

  const teams = useTeamsStore((store) => store.getLibrary());

  const currentTeam = useMemo(() => {
    if (value === null || teams.status !== 'success') {
      return null;
    }

    return teams.data.find((team) => team.id === value) || null;
  }, [teams, value])

  if (
    teams.status !== 'success' ||
    currentTeam === null && !onChange
  ) {
    return null;
  }

  return (
    <div className='flex items-center border-b px-[20px] text-[13px]'>
      Team:

      {onChange ? (
        <Select
          bordered={false}
          value={value ? `${value}` : 'none'}
          className='w-full'
          options={[
            {
              value: 'none',
              label: 'None'
            },
            ...teams.data.map((team) => ({
              value: `${team.id}`,
              label: team.name
            }))
          ]}
          onChange={(value: string) => {
            onChange(value === 'none' ? null : +value);
          }}
        />
      ): (
        <span className='ml-2 font-medium'>
          {currentTeam!.name}
        </span>
      )}
    </div>
  );
}
