import { createKeyedStore } from 'store/createKeyedStore';
import { fetchSavedAndWastedSummary } from 'services/savings';
import { savedAndWastedParamsToKey } from 'helpers/savings';
import { createKeyedStoreSelector } from 'store/createKeyedStoreSelector';

export const useSavedAndWastedSummaryStore = createKeyedStore({
  fetchData: fetchSavedAndWastedSummary,
  paramsToKey: savedAndWastedParamsToKey
});

export const useSavedAndWastedSummary = createKeyedStoreSelector(useSavedAndWastedSummaryStore);
