import {
  FilterCondition,
  FilterGroupCondition,
  FilterGroupOperator,
  FilterNestedCondition,
  FilterNestedConditionItem,
  FilterValueCondition,
  FilterRegexStringCondition,
  NestedRule,
  ConstructorRule,
  FilterDictCondition,
  FilterValueType
} from 'types/filters';

import {
  isFilterGroupCondition,
  isFilterDictCondition,
  isFilterStringCondition,
  isFilterRegexDictCondition,
  isFilterRegexStringCondition,
} from 'types/filters';

export const removeValue = <TCondition extends FilterValueCondition>(condition: TCondition, index: number): TCondition => {
  const values = [...condition.values];

  values.splice(index, 1);

  return {
    ...condition,
    values
  };
};

type GetValueType<TCondition extends FilterValueCondition> = TCondition extends FilterDictCondition ? Record<string, string> : string;

export const addValue = <TCondition extends FilterValueCondition>(
  condition: TCondition,
  index: number = condition.values.length,
  value: GetValueType<TCondition> = (isFilterDictCondition(condition) ? {} : '') as (TCondition extends FilterDictCondition ? Record<string, string> : string)
): TCondition => {
  const values = [...condition.values];

  values.splice(index, 0, value);

  return {
    ...condition,
    values
  };
};

export const setCondition = <TFilter extends FilterGroupCondition>(
  filter: TFilter,
  path: number[],
  condition: FilterCondition
): TFilter => {
  const index = path[0];

  if (path.length === 1) {
    const newFilter = { ...filter, condition: [...filter.condition] };

    newFilter.condition[index] = condition;

    return newFilter;
  }

  const childCondition = filter.condition[index];

  if (!isFilterGroupCondition(childCondition)) {
    throw new Error();
  }

  const newChildCondition = setCondition(childCondition, path.slice(1), condition);

  return setCondition(filter, [index], newChildCondition);
};

export const addCondition = <TFilter extends FilterGroupCondition>(filter: TFilter, path: number[], condition: FilterCondition): TFilter => {
  const index = path[0];

  if (path.length === 0) {
    const newFilter = { ...filter, condition: [...filter.condition] };

    newFilter.condition.push(condition);

    return newFilter;
  }

  const childFilter = filter.condition[index];
  
  if (!isFilterGroupCondition(childFilter)) {
    throw new Error();
  }

  const newChildFilter = addCondition(childFilter, path.slice(1), condition);
  
  return setCondition(filter, [index], newChildFilter);
}

export const removeCondition = <TCondition extends FilterGroupCondition>(filter: TCondition, path: number[]): TCondition => {
  const index = path[0];

  if (path.length === 1) {
    const newFilter = { ...filter, condition: [...filter.condition] };

    newFilter.condition.splice(index, 1);

    return newFilter;
  }

  const childFilter = filter.condition[index];
  
  if (!isFilterGroupCondition(childFilter)) {
    throw new Error();
  }

  const newChildFilter = removeCondition(childFilter, path.slice(1));

  return setCondition(filter, [index], newChildFilter);
}

export const createValueCondition = (rule: ConstructorRule, valueType: FilterValueType = rule.valueType): FilterValueCondition => ({
  field_name: rule.name,
  comparator: 'equals',
  ...(valueType === 'string' || valueType === 'string-regex' ? {
    value_type: valueType,
    values: ['']
  } : {
    value_type: valueType,
    values: [{}]
  })
});

export const createNestedConditionItem = (rule: NestedRule, valueType: FilterValueType = rule.valueType): FilterNestedConditionItem => ({
  operator: 'and',
  condition: [createValueCondition(rule, valueType)]
});

export const createNestedConditon = (rule: NestedRule, valueType: FilterValueType = rule.valueType): FilterNestedCondition => ({
  operator: 'or',
  condition: [createNestedConditionItem(rule, valueType)]
});

export const createGroupCondition = (operator: FilterGroupOperator): FilterGroupCondition => ({
  operator,
  condition: []
});

export const toggleRegex = <TCondition extends FilterValueCondition>(condition: TCondition, options: string[]): TCondition => {
  if (isFilterStringCondition(condition)) {
    return {
      ...condition,
      value_type: 'string-regex'
    };
  }

  if (isFilterRegexStringCondition(condition)) {
    return {
      ...condition,
      value_type: 'string',
      values: condition.values.map((value) => options.includes(value) ? value : '')
    };
  }

  if (isFilterDictCondition(condition)) {
    return {
      ...condition,
      value_type: 'dict-regex'
    };
  }

  if (isFilterRegexDictCondition(condition)) {
    return {
      ...condition,
      value_type: 'dict',
      values: condition.values.map((value) => {
        const entry = Object.entries(value)[0];

        if (!entry) {
          return value;
        }

        const [key] = entry;

        return { [key]: '' };
      })
    }
  }

  return condition;
}

export const getNestedFieldName = (condition: FilterNestedCondition) => condition.condition[0].condition[0].field_name;
export const getNestedValueType = (condition: FilterNestedCondition) => condition.condition[0].condition[0].value_type;
export const getNestedComparator = (condition: FilterNestedCondition) => condition.condition[0].condition[0].comparator;
export const getNestedValueCondition = (condition: FilterNestedCondition) => condition.condition[0].condition[0];
export const getNestedItemValueCondition = (condition: FilterNestedConditionItem) => condition.condition[0];

export const setNestedNotEquals = (condition: FilterNestedCondition): FilterNestedCondition => {
  return {
    operator: 'or',
    condition: [{
      operator: 'and',
      condition: [
        {
          field_name: getNestedFieldName(condition),
          value_type: getNestedValueType(condition),
          comparator: 'not equals',
          values: condition.condition.map((item) => {
            const [value] = item.condition;

            return value.values[0] as any;
          })
        },
      ]
    }]
  };
};

export const setNestedEquals = (nested: FilterNestedCondition): FilterNestedCondition => {
  return {
    operator: 'or',
    condition: nested.condition[0].condition[0].values.map((value) => ({
      operator: 'and',
      condition: [{
        field_name: getNestedFieldName(nested),
        value_type: getNestedValueType(nested),
        comparator: 'equals',
        values: [value as any]
      }]
    }))
  };
};

export const toggleNestedComparator = (nested: FilterNestedCondition): FilterNestedCondition => getNestedComparator(nested) === 'equals' ?
  setNestedNotEquals(nested) :
  setNestedEquals(nested);

export const toggleNestedRegex = (nested: FilterNestedCondition, options: string[]): FilterNestedCondition => {
  return {
    ...nested,
    condition: nested.condition.map((item) => {
      const valueCondition = toggleRegex(item.condition[0], options);
      
      return {
        ...item,
        condition: [valueCondition]
      };
    })
  };
}

export const createNestedFromValueEquals = (valueCondition: FilterValueCondition): FilterNestedCondition => ({
  operator: 'or',
  condition: valueCondition.values.map((value) => ({
    operator: 'and',
    condition: [{
      ...valueCondition,
      values: [value]
    } as FilterValueCondition]
  }))
});

export const createNestedFromValueNotEquals = (valueCondition: FilterValueCondition): FilterNestedCondition => ({
  operator: 'or',
  condition: [{
    operator: 'and',
    condition: [{
      ...valueCondition,
    }]
  }]
});

export const createNestedFromValueCondition = (value: FilterValueCondition): FilterNestedCondition => value.comparator === 'equals' ?
  createNestedFromValueEquals(value) :
  createNestedFromValueNotEquals(value);

export const createWildcardCondition = (rule: ConstructorRule): FilterRegexStringCondition => ({
  field_name: rule.name,
  value_type: 'string-regex',
  comparator: 'equals',
  values: ['%']
});

