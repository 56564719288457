import { ClassNameProps } from 'props'
import * as React from 'react'
import { Table as AntdTable, TableColumnProps } from 'antd'
import { TablePaginationConfig } from 'antd/es'
import { get } from 'object-path'
import { useEffect, useState } from 'react'
import { formatCurrency, formatDate, formatPercent, formatFootprint } from '../../helpers/formatter'
import { ReactComponent as FilterIcon } from '../../assets/images/Filter.svg'
// import {get} from 'object-path'

type Format = 'string' | 'date' | 'currency' | 'percent' | 'percent_or_na' | 'co2'
type Formatter = (value: string | number) => string
type defaultSort = 'ascend' | 'descend' | undefined

type Renderer = <T>(value: any, record: T) => React.ReactNode

export interface Column<T = Record<string, unknown>> {
  title?: string
  name?: string | string[]
  format?: Format,
  sortable?: boolean
  defaultSort?: defaultSort
  filterable?: boolean
  render?: Renderer,
  width?: number,
}

export interface TableProps<T = any> extends ClassNameProps {
  data: T[] | Record<string, unknown>[],
  columns: Column[]
  idColumn?: string
  pageSize?: number
  scrollBodyHeight?: number
}

const getFormatter = (format: Format | undefined): Formatter => {
  if (format === 'date') {
    return (value: unknown) => formatDate(new Date(value as string).valueOf())
  }

  if (format === 'percent') {
    return (value) => formatPercent(value as number)
  }

  if (format === 'percent_or_na') {
    return (value) => (value === 'n/a' ? value : formatPercent(value as number))
  }

  if (format === 'currency') {
    return (value) => formatCurrency(value as number)
  }

  if (format === 'co2') {
    return (value) => formatFootprint(value as number)
  }

  return (value) => value as string
}

const createRenderer = (renderer?: Renderer, format?: Format): Renderer | undefined => {
  if (renderer) {
    return renderer
  }

  // if (format === 'date') {
  //   return (value: unknown) => formatDate(new Date(value as string).valueOf())
  // }
  //
  // if (format === 'percent') {
  //   return (value) => formatPercent(value - 1)
  // }
  //
  // if (format === 'currency') {
  //   return (value) => formatCurrency(value)
  // }
  //
  // return undefined
  return format ? getFormatter(format) : undefined
}

const createSorter = (col: Column) => (a: Record<string, unknown>, b: Record<string, unknown>) => {
  const normalize = (v: string) => (col.format === 'date' ? new Date(v as string).valueOf() : v)
  const av = normalize(get(a, col.name!))
  const bv = normalize(get(b, col.name!))

  return av > bv ? 1 : av === bv ? 0 : -1
}

const toAntdColumns = (columns: Column[], data: Record<string, unknown>[]): TableColumnProps<any>[] => columns.map((col) => ({
  title: col.title,
  dataIndex: col.name,
  defaultSortOrder: col.defaultSort,
  render: createRenderer(col.render, col.format),
  sorter: col.sortable && col.name ? createSorter(col) : undefined,
  width: col.width,
  onFilter: col.filterable ? (value: any, record: any) => get(record, col.name!) === value : undefined,
  filterIcon: col.filterable ? <FilterIcon className="fill-oxford-blue opacity-20" /> : <div/>,
  filterSearch: col.filterable,
  filters: col.filterable ? Array.from(new Set(data.map((row) => get(row, col.name!)))).map((value) => ({ text: getFormatter(col.format)(value), value })) : [],
}))

export interface ColumnBuilder {
  simple(name: string | string[], title: string, format?: Format): ColumnBuilder

  sortable(name: string, title: string, format?: Format, defaultSort?: defaultSort): ColumnBuilder

  custom(renderer: Renderer): ColumnBuilder
  custom(width: number, renderer: Renderer, title?: string): ColumnBuilder

  columns(): Column[]
}

export const columnBuilder = (): ColumnBuilder => {
  const columns: Column[] = []
  const makeName = (name: string | string[]) => ((typeof name === 'string' && name.indexOf('.') > -1) ? name.split('.') : name)

  return {
    simple(name: string | string[], title: string, format = 'string') {
      columns.push({
        name: makeName(name),
        title,
        format,
      })
      return this
    },

    sortable(name: string, title: string, format = 'string', defaultSort = undefined) {
      columns.push({
        name: makeName(name),
        title,
        format,
        sortable: true,
        filterable: true,
        defaultSort,
      })
      return this
    },

    custom(widthOrRender: number | Renderer, render?: Renderer, title?: string): ColumnBuilder {
      if (typeof widthOrRender === 'function') {
        columns.push({ render: widthOrRender, title})
      } else {
        columns.push({ render, width: widthOrRender, title })
      }
      return this
    },

    columns: () => columns,
  }
}

export const Table = ({
  className,
  columns,
  data,
  pageSize,
  idColumn
}: TableProps) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    if (pageSize && data) {
      setCurrentPage(0)
      setTotalPages(Math.ceil(data.length / pageSize))
    }
  }, [data])

  const hasPagination = typeof currentPage !== 'undefined' && typeof totalPages !== 'undefined' && totalPages > 1
  const pagination = hasPagination ? {
    showSizeChanger: false,
    pageSize
  } as TablePaginationConfig : false

  return (
    <AntdTable
      size='small'
      onHeaderRow={() => ({ className: 'text-caption-1' })}
      className={className}
      rowKey={idColumn || 'id'}
      columns={toAntdColumns(columns, data)}
      dataSource={data}
      pagination={pagination}
    />
  )
}
