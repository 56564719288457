import { Button2 as Button } from 'shared/Button';
import { useThreadDeleteDialog } from 'store/savings';

export interface ThreadDeleteButtonProps {
  threadId: number;
}

export const ThreadDeleteButton = (props: ThreadDeleteButtonProps) => {
  const {
    threadId
  } = props;

  const open = useThreadDeleteDialog((state) => state.open);

  return (
    <Button
      size='xs'
      theme='gray'
      layout='inline'
      iconLeft='trash'
      onClick={() => {
        open(threadId);
      }}
    >
      Delete
    </Button>
  );
}
