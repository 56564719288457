import type { CostViewChartWidget } from 'types/dashboards';
import type { LoadingDataEntry } from 'types/dataEntries';
import type { CostView } from 'types/costViews';

import moment from 'moment';
import { Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Section } from 'shared/Section';
import { PeriodChart } from 'shared/PeriodChart';
import { useCostViewChartStore } from 'store/costViewChart';
import { useCostViewsStore, CostViewEntry } from 'store/costViews';
import { useCostViewLibraryStore } from 'store/costViewLibrary';
import { Loading } from 'shared/Loading';
import { Button, IconButton } from 'shared/Button';

export interface CostViewChartWidgetBlockProps {
  widget: CostViewChartWidget;
  onChange: (widget: CostViewChartWidget) => void;
  onRemove: () => void;
}

export const CostViewChartWidgetBlock = (props: CostViewChartWidgetBlockProps) => {
  const {
    widget,
    onChange,
    onRemove
  } = props;

  const {
    viewId,
    duration,
    granularity
  } = widget.settings;

  const [editing, setEditing] = useState(!viewId);

  const fetchCostView = useCostViewsStore((store) => store.fetchCostView);
  const viewEntry = useCostViewsStore((store) => viewId ?
    store.getCostViewEntry(viewId) :
    { status: 'idle' } as CostViewEntry
  );

  useEffect(() => {
    if (viewId !== null && viewEntry.status === 'idle') {
      fetchCostView(viewId);
    }
  }, [viewId]);

  const chart = useCostViewChartStore((store) => viewEntry.status === 'success' ? store.getEntry({
    endDate: moment().startOf('day').format('YYYY-MM-DD'),
    startDate: moment().startOf('day').subtract(duration, granularity).format('YYYY-MM-DD'),
    granularity,
    costType: 'unblended_cost',
    costDimensions: [],
    costAmortization: false,
    filter: (viewEntry.costView as CostView).filter
  }) : { status: 'loading' } as LoadingDataEntry);

  const { status: libraryStatus, costViews, fetchCostViewLibrary } = useCostViewLibraryStore();

  useEffect(() => {
    if (libraryStatus === 'idle') {
      fetchCostViewLibrary();
    }
  }, []);

  return (
    <Section className='relative h-[320px]' onClick={() => setEditing(false)}>
      {viewEntry.costView && chart.status === 'success' ? (
        <>
          <div className='font-medium p-[15px] font-[16px]'>
            {viewEntry.costView.name}
          </div>
          <PeriodChart
            granularity={granularity}
            currentKey='current_timeframe_cost'
            previousKey='previous_timeframe_cost'
            data={chart.data}
          />
        </>
      ) : viewId ? (
        <div className={editing ? 'w-1/2 pt-[320px] absolute' : ''}><Loading /></div>
      ) : (
        <div className='text-center mr-[50%] pt-[150px] font-medium text-silver-grey-500 text-[24px]'>Select view</div>
      )}
      {editing && <div className='absolute top-0 right-0 bottom-0 w-[50%] bg-silver-grey/90 z-10 px-[15px] pt-[30px]' onClick={(e) => e.stopPropagation()}>
        <div className='mt-2 font-medium'>
          Cost view
        </div>

        <Select
          value={viewId + ''}
          loading={libraryStatus === 'loading'}
          options={costViews.map((view) => ({
            value: '' + view.id,
            label: view.name
          }))}
          onChange={(newViewId) => {
            onChange({
              ...widget,
              settings: {
                ...widget.settings,
                viewId: +newViewId
              }
            });
          }}
        />
        <div className='mt-2 font-medium'>
          {`Duration (${granularity})`}
        </div>
        <Input
          value={''+ duration}
          onChange={(event) => {
            onChange({
              ...widget,
              settings: {
                ...widget.settings,
                duration: +event.target.value
              }
            });
          }}
        />

        <div className='mt-2 font-medium'>
          Granularity
        </div>

        <Select
          value={granularity}
          options={[
            { value: 'day', label: 'Day' },
            { value: 'week', label: 'Week' },
            { value: 'month', label: 'Month' },
            { value: 'quarter', label: 'Quarter' },
            { value: 'year', label: 'Year' }
          ]}
          onChange={(newGranularity) => {
            onChange({
              ...widget,
              settings: {
                ...widget.settings,
                granularity: newGranularity
              }
            });
          }}
        />
        <div className='mt-5'> 
          <Button
            type='primary'
            size='sm'
            label='Save Widget'
            onClick={() => { setEditing(!editing); }}
            disabled={!viewId}
          />
        </div>
      </div>}
      <div className={'absolute z-[30] right-0 top-0 m-[10px] flex'} onClick={(e) => e.stopPropagation()}>
        {!editing && (
          <IconButton
            type='tertiary'
            size='sm'
            icon='edit'
            onClick={() => { setEditing(!editing); }}
          />
        )}
        <IconButton
          type='tertiary'
          size='sm'
          icon='trash'
          onClick={onRemove}
        />
      </div>
    </Section>
  );
}
