import type { DictRule } from 'types/filters';
import { useMemo, useCallback, ChangeEvent } from 'react';
import { Select, Input } from 'antd';

export interface DictRegexRuleValueProps {
  rule: DictRule;
  value: Record<string, string>;
  options: string[];
  siblingValues: Record<string, string>[];
  disabled?: boolean;
  onChange: (value: Record<string, string>) => void;
}

export const DictRegexRuleValue = (props: DictRegexRuleValueProps) => {
  const {
    value,
    options,
    siblingValues,
    disabled,
    onChange
  } = props;
  
  const keyOptions = useMemo(() => {
    const siblingKeysSet = new Set(siblingValues.map((val) => Object.keys(val)[0]).filter(Boolean));

    return options
      .filter((key) => !siblingKeysSet.has(key))
      .map((key) => ({ label: key, value: key }));
  }, [options, siblingValues]);

  const [key, regex] = Object.entries(value)[0] || ['', ''];

  const handleKeyChange = useCallback((key: string) => {
    onChange({ [key]: '' });
  }, [onChange]);

  const handleRegexChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange({ [key]: event.target.value });
  }, [key, onChange]);
  
  return (
    <div className='flex min-w-0 grow shrink'>
      <div className='flex items-center w-1/2 basis-1/2 shrink-0'>
        <Select
          value={key}
          options={keyOptions}
          onChange={handleKeyChange}
          disabled={disabled}
          className='grow'
          showSearch
          placeholder='Select key'
        />
        <span className='mx-[5px]'>is</span>
      </div>

      <div className='flex overflow-hidden basis-1/2 grow-0'>
        <Input
          value={regex}
          onChange={handleRegexChange}
          disabled={!key || disabled}
          className='grow'
          type='secondary'
          placeholder='Input LIKE clause'
        />
      </div>
    </div>
  );
};
