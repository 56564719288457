import { useMemo } from 'react';
import { Section, SectionTitle } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { formatCurrency } from 'helpers/formatter';
import { useSavingsStore } from 'store/savings';
import { ThreadStatusIcon } from './ThreadStatusIcon';
import { SimpleTable, SimpleTableCell, SimpleTableHeadCell, SimpleTableRow } from 'shared/SimpleTable';

const STATUSES: React.ComponentProps<typeof ThreadStatusIcon>['status'][] = ['open', 'validation', 'implementation', 'done', 'rejected'];

export interface ThreadsSummaryProps {
  className?: string;
}

export const ThreadsSummary = (props: ThreadsSummaryProps) => {
  const {
    className = ''
  } = props;

  const threads = useSavingsStore((store) => store.getThreads());

  const counts = useMemo(() => {
    const counts: Record<string, number> = {};

    if (threads.status === 'success') {
      threads.data.forEach((thread) => {
        const status = thread.status.endsWith('rejected') ? 'rejected' : thread.status;

        if (!counts[status]) {
          counts[status] = 0;
        }

        counts[status]++;
      });
    }

    return counts;
  }, [threads]);

  const savings = useMemo(() => {
    const savings: Record<string, number> = {};

    if (threads.status === 'success') {
      threads.data.forEach((thread) => {
        const status = thread.status.endsWith('rejected') ? 'rejected' : thread.status;

        if (!savings[status]) {
          savings[status] = 0;
        }

        savings[status] += thread.cost_impact_sum * 30;
      });
    }

    return savings;
  }, [threads]);

  return (
    <Section
      className={`p-5 ${className}`}
    >
      <SectionTitle className='mb-5'>
        Threads Summary
      </SectionTitle>

      <div className='relative'>
        <SimpleTable className='text-right capitalize'>
          <thead>
            <SimpleTableHeadCell className='text-left'>
              Status
            </SimpleTableHeadCell>

            <SimpleTableHeadCell>
              # of Threads
            </SimpleTableHeadCell>

            <SimpleTableHeadCell>
              Active Savings / Mo
            </SimpleTableHeadCell>
          </thead>

          <tbody>
            {STATUSES.map((status) => (
              <SimpleTableRow>
                <SimpleTableCell className='text-left'>
                  <ThreadStatusIcon status={status} className='mr-2' />

                  {status}
                </SimpleTableCell>

                <SimpleTableCell>
                  {counts[status] || 0}
                </SimpleTableCell>

                <SimpleTableCell>
                  {savings[status] && formatCurrency(savings[status])}
                </SimpleTableCell>
              </SimpleTableRow>
            ))}

            <SimpleTableRow className='font-medium'>
              <SimpleTableCell className='text-left'>
                <ThreadStatusIcon status='total' className='mr-2' />

                Total
              </SimpleTableCell>

              <SimpleTableCell>
                {threads.status === 'success' ? threads.data.length : 0}
              </SimpleTableCell>

              <SimpleTableCell>
                {formatCurrency(STATUSES.reduce((sum, status) => {
                  if (savings[status]) {
                    return sum + savings[status];
                  }

                  return sum
                }, 0))}
              </SimpleTableCell>
            </SimpleTableRow>
          </tbody>
        </SimpleTable>

        <StatusOverlay status={threads.status} />
      </div>
    </Section>
  );
};
