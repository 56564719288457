import { Table } from 'antd';
import { EventsData } from 'types/costViews';

export interface EventsTableProps {
  events: EventsData[]
}

export const EventsTable = (props: EventsTableProps) => {
  const {
    events
  } = props;

  return (
    <Table 
      size='small'
      rowKey={({ id }) => id}
      dataSource={events}
      pagination={false}
      columns={[
        {
          key: 'type',
          title: 'Type',
          render: ({ payload: { type }}) => type
        },
        {
          key: 'description',
          title: 'Description',
          render: ({ payload: { description }}) => description || '-'
        },
        {
          key: 'event_url',
          title: 'Link',
          dataIndex: 'event_url',
          render: ({ payload: { event_url }}) => event_url ? <a href={event_url} target='_blank' >{event_url}</a> : '-'
        }
      ]}
    />
  );
}
