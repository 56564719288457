import { message } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button2 as Button } from 'shared/Button';
import {
  useSavingsStore,
  useThreadDeleteDialog
} from 'store/savings';
import {renderThreadSecondaryId} from 'helpers/savings';

export interface ThreadDeleteDialogProps {
  onConfirm?: () => void;
}

export const ThreadDeleteDialog = (props: ThreadDeleteDialogProps) => {
  const { onConfirm } = props;
  const { id, close } = useThreadDeleteDialog();

  const thread = useSavingsStore((state) => id !== null && state.getThread(id));
  const updateThread = useSavingsStore((state) => state.updateThread);

  const name = thread && thread.status === 'success' && renderThreadSecondaryId(thread.data);

  return (
    <Dialog
      className='w-[420px] p-8'
      open={id !== null}
      onClose={close}
    >
      <div className='mb-8 font-semibold text-center text-subtitle-1'>
        Are you sure you want to delete {name} permanently?
      </div>

      <Button
        size='m'
        theme='filled'
        className='justify-center !bg-red !border-red'
        onClick={() => {
          onConfirm && onConfirm();
          close();
          updateThread(id as number, { status: 'deleted' })
            .then(() => {
              message.success(`${name} deleted`);
            })
            .catch(() => {
              message.error(`Unable to delete ${name}`);
            })
        }}
      >
        <span>
          Yes, delete <b>{name}</b>
        </span>
      </Button>

      <Button
        size='m'
        theme='bordered'
        className='!w-full mt-4'
        onClick={close}
      >
        Cancel
      </Button>
    </Dialog>
  )
}
