import { Table, Collapse } from 'antd';
import moment from 'moment';
import { useMemo, Fragment } from 'react';
import type { DataConfig, DataCollectionStatus, DataCollectionStatusType } from 'types/dataCollection';

export interface StatusTypeBadgeProps {
  children: DataCollectionStatusType;
}

export const StatusTypeBadge = (props: StatusTypeBadgeProps) => {
  const {
    children
  } = props;

  return (
    <span className={`capitalize ${{
      success: 'text-positive',
      error: 'text-negative',
      access: 'text-yellow-500'
    }[children]}`}>
      {children}
    </span>
  );
}

export interface StatusesListProps {
  statuses: DataCollectionStatus[];
}

const STATUSES_ORDER = {
  success: 0,
  access: 1,
  error: 2
};

const StatusesList = (props: StatusesListProps) => {
  const { 
    statuses
  } = props;

  return (
    <Collapse ghost>
      {statuses.map((status) => (
        <Collapse.Panel
          key={status.created_at}
          header={
            <div className='flex'>
              <div className='w-[240px]'>
                <StatusTypeBadge>{status.status_type}</StatusTypeBadge>
              </div>
              
              <div className=''>
                {moment(status.created_at).format('YYYY/MM/DD HH:mm:ss')}
              </div>
            </div>
          }
        >
          {status.status}
        </Collapse.Panel>
      ))}
    </Collapse>

  );
}

export interface StatusesTableProps {
  dataConfig: DataConfig;
}

export const StatusesTable = (props: StatusesTableProps) => {
  const {
    dataConfig
  } = props;

  const [
    data,
    collectionIdFilter
  ] = useMemo(() => {
    const statusesByCollectionId = dataConfig.statuses.reduce((acc, status) => {
      acc[status.collection_id] ??= [];
      acc[status.collection_id].push(status);

      return acc;
    }, {} as Record<string, DataCollectionStatus[]>);

    return [
      Object.values(statusesByCollectionId)
        .map((statuses) => statuses.sort((a, b) => moment(a.created_at).isBefore(b.created_at) ? 1 : -1))
        .map((statuses) => ({
          ...statuses[0],
          statuses,
          statusTypes: new Set(statuses.map((status) => status.status_type))
        })),
      Object.keys(statusesByCollectionId)
        .map((collectionId) => ({
          value: collectionId,
          text: collectionId,
        }))
    ];
  }, [dataConfig.statuses]);


  return (
    <Table
      size='small'
      dataSource={data}
      rowKey={(status) => status.collection_id}
      expandable={{
        expandedRowRender: (status) => <StatusesList statuses={status.statuses} />
      }}
      columns={[
        {
          key: 'collection_id',
          dataIndex: 'collection_id',
          title: 'Collection ID',
          filters: collectionIdFilter,
          onFilter: (collectionId, status) => collectionId === status.collection_id,
          sorter: (idA, idB) => idA > idB ? 1 : -1
        },
        {
          key: 'status_type',
          title: 'Last Status',
          sorter: (stA, stB) => STATUSES_ORDER[stA.status_type] - STATUSES_ORDER[stB.status_type],
          render: (status) => <StatusTypeBadge>{status.status_type}</StatusTypeBadge>
        },
        {
          key: 'created_at',
          dataIndex: 'created_at',
          title: 'Last Status Created At',
          sorter: (stA, stB) => moment(stA.created_at).isAfter(stB.created_at) ? 1 : -1,
          render: (date) => moment(date).format('YYYY/MM/DD HH:mm')
        },
      ]}
    />
  );
}
