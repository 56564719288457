import type { DataEntry, Action } from 'types/dataEntries';
import type {
  Thread,
  ThreadUpdatePayload,
  Opportunity,
  OpportunityDraft,
  OpportunityAction,
  OpportunitiesParams,
  OpportunitiesStoreKey,
  ThreadDraft,
  AutomationPayload
} from 'types/savings';

import create from 'zustand';
import { Map as ImmutableMap } from 'immutable';

import {
  fetchThreads,
  createThread,
  updateThread,
  assignThreadTeam,
  assignThreadUser,

  createOpportunity,
  fetchOpportunity,
  updateOpportunity,
  updateOpportunityPriorities,
  assignOpportunityTeam,

  fetchOpportunitiesList,
  automateOpportunity
} from 'services/savings';

import {
  opportunityToDraft,
  opportunityDraftToCreatePayload,
  opportunityDraftToUpdatePayload,
  isValidOpportunityCreateDraft,
  isValidOpportunityUpdateDraft
} from 'helpers/savings';

import { opportunitiesParamsToKey } from 'helpers/savings/opportunities/opportunitiesStoreKey';

type ThreadCreateAction = Action<'thread-create'>;
type ThreadDeleteAction = Action<'thread-delete'>;

type ThreadAction = ThreadCreateAction | ThreadDeleteAction;

export interface SavingsStore {
  threads: DataEntry<Thread[]>;
  threadsMap: ImmutableMap<number, DataEntry<Thread>>;
  newThread: ThreadDraft;
  threadActions: ImmutableMap<number, ThreadAction>;

  getThreads: () => DataEntry<Thread[]>;
  getThread: (id: number | DataEntry<number>) => DataEntry<Thread>;
  setThread: (thread: Thread) => void;
  setNewThread: (draft: ThreadDraft) => void;
  resetNewThread: () => void;
  createThread: () => Promise<Thread>;
  updateThread: (id: number, payload: ThreadUpdatePayload) => Promise<Thread>;
  assignThreadTeam: (threadId: number, teamId: number) => Promise<Thread>;
  assignThreadUser: (threadId: number, userId: number) => Promise<Thread>;

  opportunities: ImmutableMap<OpportunitiesStoreKey, DataEntry<Opportunity[]>>;
  opportunitiesMap: ImmutableMap<number, DataEntry<Opportunity>>;
  opportunityActions: ImmutableMap<number, OpportunityAction>;
  opportunityDrafts: ImmutableMap<string | number, OpportunityDraft>;

  getOpportunities: (params: OpportunitiesParams | DataEntry<OpportunitiesParams>) => DataEntry<Opportunity[]>;
  setOpportunity: (opportunity: Opportunity, update?: boolean) => Opportunity;
  getOpportunity: (id: number) => DataEntry<Opportunity>;

  startOpportunityAction: (id: number, type: OpportunityAction['type']) => void;
  finishOpportunityAction: (id: number) => void;

  createOpportunity: (key: string) => Promise<Opportunity>;
  updateOpportunity: (id: number) => Promise<Opportunity>;
  assignOpportunity: (id: number, threadId: number) => Promise<Opportunity>;
  unassignOpportunity: (id: number) => Promise<Opportunity>;
  archiveOpportunity: (id: number) => Promise<Opportunity>;
  restoreOpportunity: (id: number) => Promise<Opportunity>;
  resolveOpportunity: (id: number) => Promise<Opportunity>;
  rejectOpportunity: (id: number) => Promise<Opportunity>;
  validateOpportunity: (id: number) => Promise<Opportunity>;
  implementOpportunity: (id: number) => Promise<Opportunity>;
  assignOpportunityTeam: (oppId: number, teamId: number) => Promise<Opportunity>;
  automateOpportunity: (payload: AutomationPayload) => Promise<void>;

  updateOpportunityPriorities: (typeId: string, priority: number) => Promise<void>;
  flushOpportunities: () => void;
  
  getOpportunityDraft: (key: string | number) => OpportunityDraft;
  setOpportunityDraft: (key: string | number, draft: OpportunityDraft) => void;
  resetOpportunityDraft: (key: string | number) => void;
};

import { withKeyDep } from 'store/create/decorators';

const EMPTY_THREAD_DRAFT: ThreadDraft = { name: '', body: '', opportunities: [] };
const EMPTY_OPPORTUNITY_DRAFT: OpportunityDraft = {
  costImpact: 0,
  difficulty: 'easy'
};

export const useSavingsStore = create<SavingsStore>((set, get) => ({
  threads: { status: 'idle' },
  newThread: EMPTY_THREAD_DRAFT,
  threadsMap: ImmutableMap(),
  threadActions: ImmutableMap(),

  opportunities: ImmutableMap(),
  opportunitiesMap: ImmutableMap(),
  opportunityActions: ImmutableMap(),
  opportunityDrafts: ImmutableMap(),

  getThreads: () => {
    let { threads } = get();

    if (threads.status !== 'idle') {
      return threads;
    }

    threads = { status: 'loading' };
    set({ threads });

    fetchThreads()
      .then((data) => {
        set({
          threads: {
            status: 'success',
            data
          }
        });
      })
      .catch((error) => {
        set({
          threads: {
            status: 'error',
            error
          }
        });
      });
    
    return threads;
  },

  setThread: (thread: Thread) => {
    const {
      threads,
      threadsMap
    } = get();

    set({
      threadsMap: threadsMap.set(thread.id, { status: 'success', data: thread })
    });

    if (threads.status === 'success') {
      set({
        threads: {
          ...threads,
          data: [
            ...threads.data.filter((item) => item.id !== thread.id),
            thread
          ]
        }
      });
    }
  },
  
  getThread: withKeyDep((id: number) => {
    const {
      threadsMap,
      getThreads
    } = get();

    let thread = threadsMap.get(id);
    
    if (thread) {
      return thread;
    }

    const threads = getThreads();

    if (threads.status !== 'success') {
      return threads;
    }

    const data = threads.data.find((thread) => thread.id === id);

    thread = data ? {
      status: 'success',
      data
    } : {
      status: 'error',
      error: null
    };

    set({
      threadsMap: threadsMap.set(id, thread) 
    });

    return thread;
  }),

  setNewThread: (newThread: ThreadDraft) => {
    set({ newThread });
  },

  resetNewThread: () => {
    set({ newThread: EMPTY_THREAD_DRAFT });
  },

  createThread: () => {
    const {
      newThread: {
        name,
        body,
        opportunities
      }
    } = get();
    
    const payload = {
      name,
      body,
      opportunity_ids: opportunities.map(({ id }) => id)
    };

    return createThread(payload)
      .then((thread) => {
        set({
          newThread: EMPTY_THREAD_DRAFT
        });

        const {
          setThread,
          flushOpportunities
        } = get();

        setThread(thread);

        if (opportunities.length > 0) {
          flushOpportunities();
        }

        return thread;
      });
  },

  updateThread: (id: number, payload: ThreadUpdatePayload) => {
    return updateThread(id, payload)
      .then((thread) => {
        const { setThread, flushOpportunities } = get();

        setThread(thread);
        flushOpportunities();

        return thread;
      });
  },

  assignThreadTeam: (threadId: number, teamId: number) => {
    return assignThreadTeam(threadId, teamId)
      .then(({ data }) => {
        const { setThread } = get();

        setThread(data);

        return data;
      });
  },

  assignThreadUser: (threadId: number, userId: number) => {
    return assignThreadUser(threadId, userId)
      .then(({ data }) => {
        const { setThread } = get();

        setThread(data);

        return data;
      });
  },

  getOpportunityDraft: (key: number | string) => {
    const {
      opportunityDrafts,
      getOpportunity,
      setOpportunityDraft
    } = get();

    let draft = opportunityDrafts.get(key);

    if (draft) {
      return draft;
    }

    if (typeof key === 'string') {
      draft = EMPTY_OPPORTUNITY_DRAFT;
    } else {
      const opportunity = getOpportunity(key);

      if (opportunity.status !== 'success') {
        throw new Error();
      }

      draft = opportunityToDraft(opportunity.data);
    }

    setOpportunityDraft(key, draft);
    
    return draft;
  },

  setOpportunityDraft: (key: number | string, draft: OpportunityDraft) => {
    let { opportunityDrafts } = get();

    opportunityDrafts = opportunityDrafts.set(key, draft);

    set({ opportunityDrafts });
  },

  resetOpportunityDraft: (key: number | string) => {
    let { opportunityDrafts } = get();

    opportunityDrafts = opportunityDrafts.remove(key);

    set({ opportunityDrafts });
  },

  getOpportunities: withKeyDep((params: OpportunitiesParams) => {
    let { opportunities: map } = get();

    const key = opportunitiesParamsToKey(params);
    let opportunities = map.get(key);

    if (!opportunities) {
      opportunities = { status: 'loading' };

      set({ opportunities: map.set(key, opportunities) });

      fetchOpportunitiesList(params)
        .then(({ data }) => {
          set({
            opportunities: get().opportunities.set(key, {
              status: 'success',
              data
            })
          });
        })
        .catch((error) => {
          set({
            opportunities: get().opportunities.set(key, {
              status: 'error',
              error
            })
          });
        });
    }

    return opportunities;
  }),

  setOpportunity: (opportunity: Opportunity, update = true) => {
    const {
      flushOpportunities,
      opportunitiesMap
    } = get();

    set({
      opportunitiesMap: opportunitiesMap.set(opportunity.id, {
        status: 'success',
        data: opportunity
      })
    });

    if (update) {
      flushOpportunities();
    }


    return opportunity;
  },

  getOpportunity: (opportunityId: number) => {
    let {
      setOpportunity,
      opportunitiesMap
    } = get();

    let opportunity = opportunitiesMap.get(opportunityId);

    if (!opportunity) {
      opportunity = { status: 'loading' };
      opportunitiesMap = opportunitiesMap.set(opportunityId, opportunity);

      set({ opportunitiesMap });
      
      fetchOpportunity(opportunityId)
        .then(({ data }) => {
          setOpportunity(data, false);
        })
        .catch((error) => {
          let { opportunitiesMap } = get();

          opportunitiesMap = opportunitiesMap.set(opportunityId, {
            status: 'error',
            error
          });

          set({ opportunitiesMap });
        });
    }

    return opportunity;
  },

  startOpportunityAction: (id: number, type: OpportunityAction['type']) => {
    const { opportunityActions } = get();

    const action = opportunityActions.get(id);

    if (action && action.status === 'in-progress') {
      throw new Error();
    }

    set({
      opportunityActions: opportunityActions.set(id, { type, status: 'in-progress' })
    });
  },

  finishOpportunityAction: (id: number) => {
    set({
      opportunityActions: get().opportunityActions.remove(id)
    });
  },

  createOpportunity: (key: string) => {
    const {
      getOpportunityDraft
    } = get();

    const draft = getOpportunityDraft(key);

    if (!draft || !isValidOpportunityCreateDraft(draft)) {
      throw new Error();
    }

    const payload = opportunityDraftToCreatePayload(draft);

    return createOpportunity(payload)
      .then(({ data }) => {
        const { setOpportunity } = get();

        setOpportunity(data, false);

        return data;
      });
  },

  updateOpportunity: (id: number) => {
    const {
      setOpportunity,
      getOpportunityDraft,
      startOpportunityAction,
      finishOpportunityAction
    } = get();

    const draft = getOpportunityDraft(id);

    if (!draft || !isValidOpportunityUpdateDraft(draft)) {
      throw new Error();
    }

    const payload = opportunityDraftToUpdatePayload(draft);

    startOpportunityAction(id, 'update');

    return updateOpportunity(id, payload)
      .then(({ data }) => setOpportunity(data))
      .finally(() => finishOpportunityAction(id));
  },

  assignOpportunity: (opportunityId: number, threadId: number) => {
    const {
      setOpportunity,
      startOpportunityAction,
      finishOpportunityAction
    } = get();

    startOpportunityAction(opportunityId, 'assign');

    return updateOpportunity(opportunityId, { thread_id: threadId })
      .then(({ data }) => setOpportunity(data))
      .finally(() => finishOpportunityAction(opportunityId));
  },

  unassignOpportunity: (opportunityId: number) => {
    const {
      setOpportunity,
      startOpportunityAction,
      finishOpportunityAction
    } = get();

    startOpportunityAction(opportunityId, 'unassign');

    return updateOpportunity(opportunityId, { thread_id: null })
      .then(({ data }) => setOpportunity(data))
      .finally(() => finishOpportunityAction(opportunityId));
  },

  archiveOpportunity: (opportunityId: number) => {
    const {
      setOpportunity,
      startOpportunityAction,
      finishOpportunityAction
    } = get();

    startOpportunityAction(opportunityId, 'archive');

    return updateOpportunity(opportunityId, { status: 'trash', thread_id: null })
      .then(({ data }) => setOpportunity(data))
      .finally(() => finishOpportunityAction(opportunityId));
  },

  resolveOpportunity: (opportunityId: number) => {
    const {
      setOpportunity,
      startOpportunityAction,
      finishOpportunityAction
    } = get();

    startOpportunityAction(opportunityId, 'resolve');

    return updateOpportunity(opportunityId, { status: 'done' })
      .then(({ data }) => setOpportunity(data))
      .finally(() => finishOpportunityAction(opportunityId));
  },

  rejectOpportunity: (opportunityId: number) => {
    const {
      setOpportunity,
      startOpportunityAction,
      finishOpportunityAction
    } = get();

    startOpportunityAction(opportunityId, 'reject');

    return updateOpportunity(opportunityId, { status: 'rejected' })
      .then(({ data }) => setOpportunity(data))
      .finally(() => finishOpportunityAction(opportunityId));
  },

  validateOpportunity: (opportunityId: number) => {
    const {
      setOpportunity,
      startOpportunityAction,
      finishOpportunityAction
    } = get();

    startOpportunityAction(opportunityId, 'validate');

    return updateOpportunity(opportunityId, { status: 'validation' })
      .then(({ data }) => setOpportunity(data))
      .finally(() => finishOpportunityAction(opportunityId));
  },

  implementOpportunity: (opportunityId: number) => {
    const {
      setOpportunity,
      startOpportunityAction,
      finishOpportunityAction
    } = get();

    startOpportunityAction(opportunityId, 'implement');

    return updateOpportunity(opportunityId, { status: 'implementation' })
      .then(({ data }) => setOpportunity(data))
      .finally(() => finishOpportunityAction(opportunityId));
  },

  restoreOpportunity: (opportunityId: number) => {
    const {
      setOpportunity,
      startOpportunityAction,
      finishOpportunityAction
    } = get();

    startOpportunityAction(opportunityId, 'restore');

    return updateOpportunity(opportunityId, { status: 'unassigned' })
      .then(({ data }) => setOpportunity(data))
      .finally(() => finishOpportunityAction(opportunityId));
  },

  updateOpportunityPriorities: (opportunity_type_id: string, priority: number) => {
    return updateOpportunityPriorities({ opportunity_type_id }, { priority })
      .then(() => {
        get().flushOpportunities();
      });
  },

  assignOpportunityTeam: (oppId: number, teamId: number) => {
    return assignOpportunityTeam(oppId, teamId)
      .then(({ data }) => {
        const { setOpportunity } = get();

        setOpportunity(data);

        return data;
      });
  },

  automateOpportunity: (payload: AutomationPayload) => {
    return automateOpportunity(payload) as any as Promise<void>;
  },

  flushOpportunities: () => {
    set({
      opportunities: ImmutableMap(),
    });
  },
}));
