import create from 'zustand';

export interface DialogWithIdStore<TId = string | number> {
  id: TId | null;
  open: (id: TId) => void;
  close: () => void;
}

export const createDialogWithIdStore = <TId = string | number>() => create<DialogWithIdStore<TId>>((set) => ({
  id: null,
  open: (id) => {
    set({ id });
  },
  close: () => {
    set({ id: null });
  }
}));
