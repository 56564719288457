import create from 'zustand';

export interface UserRoleDialogStore {
  id: number | null;
  open: (id: number) => void;
  close: () => void;
}

export const useUserRoleDialogStore = create<UserRoleDialogStore>((set) => ({
  id: null,
  open: (id) => {
    set({ id });
  },
  close: () => {
    set({ id: null });
  }
}));
