import moment from 'moment';
import { useMemo } from 'react';
import { SpendSection } from './SpendSection';

export interface LastMonthSpendSectionProps {
  className?: string;
}

export const LastMonthSpendSection = (props: LastMonthSpendSectionProps) => {
  const { className } = props;
  const yesterday = useMemo(() => moment().subtract(1, 'day').startOf('day'), []);
  const lastMonthStart = useMemo(() => yesterday.clone().subtract(1, 'month').startOf('month'), []);
  const lastMonthEnd = useMemo(() => lastMonthStart.clone().endOf('month'), []);

  const beforeLastMonthStart = useMemo(() => yesterday.clone().subtract(2, 'month').startOf('month'), []);
  const beforeLastMonthEnd = useMemo(() => beforeLastMonthStart.clone().endOf('month'), []);

  return <SpendSection
    className={className}
    title='Last Month Spend'
    currentPeriodText={lastMonthStart.format('MMMM YYYY')}
    currentPeriodStart={lastMonthStart}
    currentPeriodEnd={lastMonthEnd}
    previousPeriodText={beforeLastMonthStart.format('MMMM YYYY')}
    previousPeriodStart={beforeLastMonthStart}
    previousPeriodEnd={beforeLastMonthEnd}
  />;
};

