import create from 'zustand'

import type { FetchStatus } from 'types/common';
import type { CostColumn } from 'types/costColumns';

import {
  getCostColumns,
  saveCostColumn,
  editCostColumn,
  deleteCostColumn,
} from 'services/costColumn'

interface FetchParams {
  force?: boolean;
}

export interface CostColumnsState {
  status: FetchStatus;
  costColumns: CostColumn[];
  costColumnsMap: Record<number, string>;
}

export interface CostColumnActions {
  fetchCostColumns: (params?: FetchParams) => void;
  saveCostColumn: (column: any) => void;
  deleteCostColumn: (id: number) => Promise<void>;
}

export interface CostColumnStore extends CostColumnsState, CostColumnActions {}

const DEFAULT_STATE: CostColumnsState = {
  status: 'idle',
  costColumns: [],
  costColumnsMap: {},
};

export const useCostColumnStore = create<CostColumnStore>((set, get) => {
  let requestCount = 0;

  return {
    ...DEFAULT_STATE,

    fetchCostColumns: async (params = {}) => {
      const { force } = params;
      const { status } = get();

      /*
      if (!force && ['loading', 'success'].includes(status)) {
        return;
      }
      */

      try {
        if (status !== 'success') {
          set({ status: 'loading' });
        }

        const requestNumber = ++requestCount;
        
        const { data: columns } = await getCostColumns();
        const costColumns = columns.map((data: any) => {
          const newCostColumn: CostColumn = {
            id: data.id,
            name: data.name,
            definition: data.definition,
          }
          return newCostColumn
        })
        const costColumnsMap = costColumns.reduce((a: any, v: CostColumn) => ({ ...a, [v.id]: v.name}), {})
        if (requestNumber === requestCount) {
          set({
            costColumns: costColumns,
            costColumnsMap: costColumnsMap,
            status: 'success'
          });
        }
      } catch(error) {
        set({ status: 'error' });
      }
    },

    saveCostColumn: async (column: any) => {
      const { costColumns } = get();
      try {
        let data
        if (column.id === -1) {
          ({ data } = await saveCostColumn(column))
        } else {
          ({ data } = await editCostColumn(column, column.id))
        }
        const newCostColumn: CostColumn = {
          id: data.id,
          name: data.name,
          definition: data.definition,
        }
        const static_columns = costColumns.filter((column) => column.id !== newCostColumn.id)
        set({ costColumns: [newCostColumn, ...static_columns] })
      } catch(error) {
        set({ costColumns });
        throw error;
      }
    },

    deleteCostColumn: async (id: number) => {
      const { costColumns } = get();
      set({ costColumns: costColumns.filter((column) => column.id !== id) });

      try {
        await deleteCostColumn(id);
      } catch(error) {
        set({ costColumns });
        throw error;
      }
    },
  };
});
