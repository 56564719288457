import { useEffect, useState, useMemo } from 'react'
import type { DataStreamConfig } from 'types/dataStreamConfigs';
import { Table, message } from 'antd'
import { Icon } from 'shared/Icon/Icon';
import { Loading } from 'shared/Loading/Loading';
import { Button, IconButton } from 'shared/Button';
import { DeleteKey } from './DeleteStream';
import { useDataStreamConfigStore } from 'store/dataStreamConfigs';
import CreateDialog from './CreateDialog'

const DataStreamConfigLibrary = () => {
  const { 
    status: keyStatus,
    dataStreamConfigs,
    fetchDataStreamConfigs,
    deleteDataStreamConfig,
  } = useDataStreamConfigStore();
  const [searchText, setSearchText] = useState('');
  const [configToDelete, setConfigToDelete] = useState<DataStreamConfig | null>(null);
  const [createDialogOpened, setCreateDialogOpened] = useState(false);


  useEffect(() => {
    fetchDataStreamConfigs();
  }, [])

  const filteredKeys = useMemo(() => {
    return searchText ?
      dataStreamConfigs.filter((key) => key.data_stream_token.toLowerCase().includes(searchText.toLowerCase())) :
      dataStreamConfigs; 
  }, [dataStreamConfigs, searchText]);

  return (
    <div>
      <h2 className='mt-[20px] mb-[20px] text-subtitle-1 font-medium'>
        Data Streams
      </h2>

      <div className='flex mb-[20px]'>
        <div className='flex items-center grow pl-[11px]'>
          <Icon icon='search' />
          <input
            type='text'
            className='w-full leading-[20px] text-[14px] my-[-5px] py-[10px] pl-[40px] ml-[-29px] bg-transparent outline-none border-b border-b-transparent focus:border-b-cyanine-blue'
            value={searchText} 
            placeholder='Search Stream Token'
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
          />
        </div>

        <Button
          size='sm'
          type='primary'
          icon='circle-plus'
          style='caladon'
          label='Create New Data Stream'
          onClick={() => setCreateDialogOpened(true)}
        />
      </div>

      <Table
        rowKey={({ id }) => id}
        size='small'
        rowClassName='text-[12px] line-[14px]'
        onHeaderRow={() => ({ className: 'text-[12px] line-[14px]' })}
        dataSource={filteredKeys}
        columns={[
          {
            key: 'data_stream_id',
            title: 'Stream Type',
            dataIndex: 'data_stream_id',
            sorter: (a, b) => a.data_stream_id > b.data_stream_id ? 1 : a.data_stream_id < b.data_stream_id ? -1 : 0,
            render: (data_stream_id) => <div className='truncate w-30'>{data_stream_id}</div>
          },
          {
            key: 'data_stream_token',
            title: 'Stream Token',
            dataIndex: 'data_stream_token',
            sorter: (a, b) => a.data_stream_token > b.data_stream_token ? 1 : a.data_stream_token < b.data_stream_token ? -1 : 0,
            render: (data_stream_token) => <div className='truncate w-30'>{data_stream_token}</div>
          },     
          {
            key: 'delete',
            width: 32,
            render: (_, config) => <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='trash' onClick={() => setConfigToDelete(config)} />
          },
        ]}
      />

      {keyStatus === 'loading' && <Loading />}

      <CreateDialog
        opened={createDialogOpened}
        onClose={() => setCreateDialogOpened(false)}
      />

      <DeleteKey
        open={!!configToDelete}
        onClose={() => setConfigToDelete(null)}
        onConfirm={async () => {
          if (!configToDelete) {
            return;
          }

          try {
            setConfigToDelete(null);
            await deleteDataStreamConfig(configToDelete.id);
            message.success(`Stream deleted`);
          } catch {
            message.error(`Unable to delete stream`);
          }
        }}
      />
    </div>
  )
}

export default DataStreamConfigLibrary
