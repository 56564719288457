import type { CostViewLibraryEntry } from 'types/costViews';

import { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Page } from 'shared/Page'
import { DateText } from 'shared/DateText'
import { Section } from 'shared/Section';
import { DateFilter } from 'shared/DateFilter';
import { SERVER_DATE_FORMAT } from 'helpers/constants'
import { Table, message, Tooltip } from 'antd'
import { Icon } from 'shared/Icon/Icon';
import { Loading } from 'shared/Loading/Loading';
import { DeleteViewDialog } from './DeleteViewDialog';
import { RadioButtonGroup, RadioButton } from 'shared/RadioButtonGroup';
import { Button2 as Button } from 'shared/Button';
import { useCostViewLibraryStore } from 'store/costViewLibrary';
import { useGlobalState } from 'state/globalState';
import { useDatesQuery } from 'hooks/useDatesQuery';
import { formatPercent, formatCurrency } from 'helpers/formatter';
import { createNullableNumbersComparator } from 'helpers/sort';
import { useSearchTerm } from 'hooks/useSearchTerm';

const Dot = ({ className = '', color }: { className?: string, color: string }) => <span className={`inline-block scale-[1.5] ${className}`} style={{ color }}>•</span>;
const sortStrings = (a: string, b: string) => a > b ? 1 : a < b ? -1 : 0;
export type src = 'custom_data' | 'cur' | 'gcp' | 'cloudwatch'
const srcMap: Record<string, string> = { custom_data: 'Custom', cur: 'AWS CUR', gcp: 'GCP CUR', cloudwatch: 'Cloudwatch' }

export type filterType = 'all' | 'base' | 'globa_team_filter' | 'custom'
export interface LibraryProps {
  fixedType?: filterType;
}

// TODO: determine whether view is a team view
const isTeamView = (view: CostViewLibraryEntry) => !!view.global_filter_team_id;

export const Library = (props: LibraryProps) => {
  const { fixedType } = props;
  const { user } = useGlobalState()
  const [
    searchTerm,
    setSearchTerm,
  ] = useSearchTerm();

  const {
    startDate,
    endDate,
    setDatesQuery
  } = useDatesQuery();

  const { 
    status, 
    costViews, 
    setPeriod: setDatesToStore,
    fetchCostViewLibrary,
    deleteCostView,
  } = useCostViewLibraryStore();
  const [viewType, setViewType] = useState<filterType>('all');
  const [viewToDelete, setViewToDelete] = useState<CostViewLibraryEntry | null>(null);

  useEffect(() => {
    fetchCostViewLibrary();
  }, [])

  useEffect(() => {
    setDatesToStore(
      startDate.format(SERVER_DATE_FORMAT),
      endDate.format(SERVER_DATE_FORMAT),
    );
  }, [startDate, endDate])

  const filteredViews = useMemo(() => {
    let filteredByType;
    const type = fixedType || viewType;

    filteredByType = type === 'all' ? costViews : 
      type === 'custom' ? costViews.filter((view) => view.is_custom && !isTeamView(view)) :
      type === 'base' ? costViews.filter((view) => !view.is_custom && !isTeamView(view)) :
      costViews.filter(isTeamView);

    if (user && user.role !== 'admin' && user.role !== 'owner') {
      filteredByType = filteredByType.filter((view) => !isTeamView(view))
    }

    const searchTextNormalized = searchTerm.trim().toLowerCase();

    return searchTextNormalized ?
      filteredByType.filter(({ name, default_filters, src }) => [
        name,
        default_filters?.team_name,
        srcMap[src]
      ].some((str) => str && str.toLowerCase().includes(searchTextNormalized))) :
      filteredByType; 
  }, [costViews, searchTerm, viewType, fixedType]);

  return (
    <Page isSubPage={fixedType ? true : false}>
      {!fixedType && (
        <>
        <Page.Head title='Cost Library'>
          <Page.Right>
            <div className='flex items-center ml-auto gap-5'>
              <DateFilter
                withCumulative
                startDate={startDate}
                endDate={endDate}
                granularity={'day'}
                onChange={(params) => {
                  setDatesQuery(params);
                }}
              />
              <Button
                size='xs'
                theme='black'
                layout='inline'
                iconLeft='circle-plus'
                to='/costs-overview?filtersOpened=true'
              >
                Create New View
              </Button>
            </div>
          </Page.Right>
        </Page.Head>
      </>
      )}
      <Section className='p-[20px] relative'>
        <div className='flex mb-[20px]'>
          <div className='flex items-center grow pl-[11px]'>
            <Icon icon='search' />
            <input
              type='text'
              className='w-full leading-[20px] text-[14px] my-[-5px] py-[10px] pl-[40px] ml-[-29px] bg-transparent outline-none border-b border-b-transparent focus:border-b-cyanine-blue'
              value={searchTerm} 
              placeholder='Search'
              onChange={(e) => {
                setSearchTerm(e.target.value)
              }}
            />
          </div>
          {!fixedType && (
            <div className='ml-[20px] grow-0'>
              <RadioButtonGroup
                name='metric-type'
                value={viewType}
                onChange={(_, params) => { setViewType(params.value); }}
              >
                <RadioButton value='all'>
                  All Views
                </RadioButton>
                <RadioButton value='base'>
                  <Tooltip title='Out of the box Views to analyze, report on, and use as starting point to customize'>
                    <Dot color='#2A7BE4' className='mr-[6px]' />
                    Base Views
                  </Tooltip>
                </RadioButton>
                <RadioButton value='custom'>
                  <Tooltip title='Views filtered and saved by you and your teammates'>
                    <Dot color='#EABB56' className='mr-[6px]' />
                    Custom Views
                  </Tooltip>
                </RadioButton>
              </RadioButtonGroup>
            </div>
          )}
        </div>
        <div className='mb-5'>
          <div className='flex'>
            <DateText
              description={'Current Period'}
              startMoment={startDate}
              endMoment={endDate}
            />
            <div className='ml-5'>
              <DateText
                description={'Previous Period'}
                startMoment={startDate.clone().subtract(endDate.diff(startDate, 'days') + 1, 'days')}
                endMoment={endDate.clone().subtract(endDate.diff(startDate, 'days') + 1, 'days')}
              />
            </div>
          </div>
        </div>
        <Table
          rowKey={({ id }) => id}
          size='small'
          rowClassName='text-[12px] line-[14px]'
          onHeaderRow={() => ({ className: 'text-[12px] line-[14px]' })}
          dataSource={filteredViews}
          columns={[
            {
              key: 'type',
              dataIndex: 'type',
              render: (_, metric) => (
                <Dot color={metric.is_custom && !metric.global_filter_team_id ? '#EABB56' : !metric.is_custom && !metric.global_filter_team_id ? '#2A7BE4' : '#0fab07'} />
              )
            },
            {
              key: 'name',
              title: 'Name',
              dataIndex: 'name',
              sorter: (a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0,
              render: (name, {id}) => <Link className='w-auto' to={`/costs-overview/${id}`}>{name}</Link>
            },
            {
              key: 'team',
              title: 'Team',
              render: (_, metric) => <div className='w-auto'>{metric.default_filters?.team_name}</div>
            },
            {
              key: 'budget',
              title: 'Monthly Budget',
              render: (_, metric) => <div className='w-auto'>{
                metric.budgets && metric.budgets.filter((budget) => budget.period === 'monthly').length ? `$${metric.budgets.filter((budget) => budget.period === 'monthly')[0].value}` : ''
              }</div>
            },
            {
              key: 'src',
              title: 'Source',
              dataIndex: 'src',
              sorter: (a, b) => sortStrings(a.src, b.src),
              render: (src: src) => srcMap[src]
            },
            {
              key: 'total',
              title: 'Current Period',
              dataIndex: 'total',
              sorter: createNullableNumbersComparator('total'),
              render: (total: null | number, metric) => <div className='truncate'>{!metric.library_data ? 'New or edited library entries take up to 6 hours to update' : !total ? '': formatCurrency(total)}</div>
            },
            {
              key: 'prev_total',
              title: 'Previous Period',
              dataIndex: 'prev_total',
              sorter: createNullableNumbersComparator('prev_total'),
              render: (prevTotal: null | number, metric) => !metric.library_data || !prevTotal ? '' : formatCurrency(prevTotal)
            },
            {
              key: 'wow_change',
              title: 'Period-over-period Change %',
              dataIndex: 'wow_change',
              sorter: createNullableNumbersComparator('wow_change'),
              render: (wowChange, metric) =>  <div className='truncate'>{metric.prev_total === 0 ? 'Undefined (divide by 0)' : !metric.library_data || !wowChange ? '' : formatPercent(wowChange -1)}</div>
            },
            {
              key: 'wow_change',
              title: 'Period-over-period Change $',
              dataIndex: 'wow_diff',
              sorter: createNullableNumbersComparator('wow_diff'),
              render: (wowDiff, metric) => !metric.library_data || !wowDiff ? '' : formatCurrency(wowDiff -1)
            },
            {
              key: 'delete',
              width: 32,
              render: (_, view) => view.is_custom ? (
                <Button
                  size='s'
                  theme='gray'
                  layout='inline'
                  icon='trash'
                  className='hover:!text-red'
                  onClick={() => {
                    setViewToDelete(view);
                  }}
                />
               ) : ''
            }
          ]}
        />

        {status === 'loading' && <Loading />}

        <DeleteViewDialog
          open={!!viewToDelete}
          name={viewToDelete?.name || ''}
          onClose={() => setViewToDelete(null)}
          onConfirm={async () => {
            if (!viewToDelete) {
              return;
            }

            try {
              setViewToDelete(null);
              await deleteCostView(viewToDelete.id);
              message.success(`Cost View '${viewToDelete.name}' deleted`);
            } catch {
              message.error(`Unable to delete '${viewToDelete.name}'`);
            }
          }}
        />
      </Section>
    </Page>
  )
}
