import type { BillingOverview } from 'types/billing';

import { H1, H2, P } from './Typography';
import { PlanText } from './PlanText';
import { BillingText } from './BillingText';
import { SubscribeButton } from './SubscribeButton';

export interface NonPayingMessageProps {
  overview: BillingOverview;
}

export const NonPayingMessage = (props: NonPayingMessageProps) => {
  const {
    overview
  } = props;

  const {
    last_calculated_total_spend,
    last_calculated_start_date,
    last_calculated_end_date,
    last_calculated_plan
  } = overview;

  return (
    <div>
      <H1>
        Your Cloudthread subscription is <strong className='text-negative'>inactive</strong> due to cancellation or payment issues 
      </H1>

      <PlanText
        totalSpend={last_calculated_total_spend}
        startDate={last_calculated_start_date}
        endDate={last_calculated_end_date}
        plan={last_calculated_plan}
      />

      <P>
        Cloudthread computes your <b>last 31 days of spend</b> to determine your Tier.
      </P>

      <H2>
        Billing
      </H2>

      <BillingText />

      <SubscribeButton>
        Re-subscribe
      </SubscribeButton>
    </div>
  );
}
