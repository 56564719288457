import { Outlet } from 'react-router-dom';
import { WelcomeBanner } from 'shared/WelcomeBanner';
import { MaintenanceBanner } from 'shared/MaintenanceBanner';
import { useGlobalState } from 'state/globalState';

export const InfoRoute = () => {
  const { user } = useGlobalState();

  return (
    <>
      <Outlet />
      <div className='fixed bottom-0 left-0 right-0 z-50 invisible'>
        {!user?.org_is_data_ready && <WelcomeBanner />}
        {false && <MaintenanceBanner />}
      </div>
    </>
  );
}
