import { Popover } from '@headlessui/react';
import { CostFilterButton } from './CostFilterButton';
import { CostFilterDropdownContent } from './CostFilterDropdownContent';

export const CostFilter = () => {
  return (
    <Popover
      className='flex relative shrink-1 w-[220px] min-w-[20px]'
    >
      <CostFilterButton />

      <Popover.Panel
        className='absolute top-[100%] bg-white mt-2 drop-shadow-lg z-100'
      >
        <CostFilterDropdownContent />
      </Popover.Panel>
    </Popover>
  )
};
