import type { IssueType } from 'types/savings';

import { Table } from 'antd';
import { Section, SectionTitle } from 'shared/Section';
import { useJiraIssuesStore } from 'store/savings';

export interface JiraIssuesListProps {
  threadId: number;
  className?: string;
}

export const JiraIssuesList = (props: JiraIssuesListProps) => {
  const {
    threadId,
    className = ''
  } = props;

  const issues = useJiraIssuesStore((store) => store.getIssues(threadId));

  return (
    <Section className={`p-[20px] ${className}`}>
      <SectionTitle className='mb-4'>
        JIRA Issues
      </SectionTitle>
      {issues.status === 'success' && (
        <Table
          size='small'
          dataSource={issues.data}
          pagination={false}
          columns={[
            {
              title: 'Key',
              key: 'jira_issue_key',
              render: (issue) => issue.jira_issue_url ?
                <a href={issue.jira_issue_url} className='!text-caladon-blue' target='_blank'>{issue.jira_issue_key}</a> :
                issue.jira_issue_key
            },
            {
              title: 'Type',
              key: 'issue_type',
              dataIndex: 'issue_type',
              render: (issue_type: IssueType) => ({
                cost_optimization_validation: 'Validation',
                cost_optimization_implementation: 'Implementation'
              }[issue_type] || issue_type)
            },
            {
              title: 'Status',
              key: 'jira_issue_status',
              dataIndex: 'jira_issue_status'
            },
          ]}
        />
      )}
    </Section>
  );
};
