import type {
  DashboardLibraryEntry,
  Dashboard
} from 'types/dashboards';
import api from 'helpers/api';

export const fetchDashboards = () => api.get<DashboardLibraryEntry[]>('/dashboards');
export const fetchDashboard = (id: number) => api.get<Dashboard>(`/dashboards/${id}`);
export const createDashboard = (dashboard: Dashboard) => api.post<Dashboard>(`/dashboards/`, dashboard);
export const updateDashboard = (dashboard: Dashboard) => api.patch<Dashboard>(`/dashboards/${dashboard.id}`, dashboard);
export const deleteDashboard = (id: number) => api.delete<void>(`/dashboards/${id}`);
