import { Widget, EmptyWidget, WidgetType } from 'types/dashboards';

import { useState } from 'react';
import { Select } from 'antd';
import { Button } from 'shared/Button';
import { Section } from 'shared/Section';

export interface EmptyWidgetBlockProps {
  widget: EmptyWidget;
  onChange: (widget: Widget) => void;
  onRemove: () => void;
}

const DEFAULT_WIDGETS: Record<WidgetType, Widget> = {
  'cost-view-chart': {
    type: 'cost-view-chart',
    settings: {
      viewId: null,
      granularity: 'day',
      duration: 7,
      costType: 'unblended_cost',
      costDimensions: [],
      costAmortization: false
    }
  },
  'unit-metric-chart': {
    type: 'unit-metric-chart',
    settings: {
      metricId: null,
      granularity: 'day',
      duration: 7,
      costType: 'unblended_cost',
      costDimensions: [],
      costAmortization: false
    }
  }
}

export const EmptyWidgetBlock = (props: EmptyWidgetBlockProps) => {
  const {
    widget,
    onChange,
    onRemove
  } = props;

  const [widgetType, setWidgetType] = useState<WidgetType | null>(widget.type);

  return (
    <Section
      className='flex items-center justify-center h-[320px] py-[30px] gap-[10px]'     
    >
      <Select
        className='grow ml-[30px]'
        value={widgetType}
        onChange={setWidgetType}
        placeholder='Widget Type'
        options={[
          {
            value: 'cost-view-chart',
            label: 'Cost View Chart'
          },
          {
            value: 'unit-metric-chart',
            label: 'Unit Metric Chart'
          }
        ]}
      />
      <Button
        label='Add'
        disabled={widgetType === null}
        onClick={() => {
          if (widgetType !== null) {
            onChange(DEFAULT_WIDGETS[widgetType]);
          }
        }}
      />
      <Button
        label='Cancel'
        type='secondary'
        className='mr-[30px]'
        onClick={onRemove}
      />
    </Section>
  );
};
