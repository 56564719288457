import type { Opportunity } from 'types/savings';
import type { ButtonProps } from 'shared/Button';

import { Button2 as Button } from 'shared/Button';
import { useOpportunityAutomationDialog } from 'store/savings';

export interface OpportunityAutomationButtonProps extends Omit<ButtonProps, 'onClick'> {
  opportunity: Opportunity;
}

export const OpportunityAutomationButton = (props: OpportunityAutomationButtonProps) => {
  const {
    opportunity,
    disabled = false,
    size = 's',
    theme ='link',
    layout = 'inline',
    children = 'Automate',
    ...rest
  } = props;

  const openOpportunityAutomationDialog = useOpportunityAutomationDialog((store) => store.open);

  return (
    <Button
      {...rest}
      size={size}
      theme={theme}
      layout={layout}
      disabled={disabled}
      onClick={() => {
        openOpportunityAutomationDialog([opportunity]);
      }}
    >
      {children}
    </Button>
  );
};
