import type { SingleEntryStore, SingleEntryStoreState } from 'types/stores';

import create from 'zustand';

interface CreateSingleEntryStoreParams<TData> {
  fetchData: () => Promise<TData>;
}

export const createSingleEntryStore = <TData>(params: CreateSingleEntryStoreParams<TData>): SingleEntryStore<TData> => {
  const {
    fetchData
  } = params;

  return create<SingleEntryStoreState<TData>>((set, get) => ({
    entry: { status: 'idle' },
    getEntry: () => {
      let { entry } = get();

      if (entry.status === 'idle') {
        entry = { status: 'loading' };
        set({ entry });

        fetchData()
          .then((data) => {

            set({
              entry: { status: 'success', data }
            });
          })
          .catch((error) => {
            set({
              entry: { status: 'error', error }
            });
          });
      }

      return entry;
    }
  }));
};

export const createSingleEntryStoreSelector = <TData>(useStore: SingleEntryStore<TData>) => () => useStore((store) => store.getEntry());
