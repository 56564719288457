import type { MouseEvent } from 'react';
import { createElement } from 'react';
import { Link } from 'react-router-dom';
import { ClassNameProps } from 'props'
import { Icon, IconName } from 'shared/Icon/Icon'
import { Size } from 'shared/size'
import { DesignStyle, DesignType, TYPE_CSS } from 'shared/types'
import { SIZE_ICON_CSS } from './types'

type HoverStyle = 'positive' | 'negative' | 'normal' | 'none'

export interface IconButtonProps extends ClassNameProps{
  icon: IconName;
  geometry?: 'rect' | 'circle'
  type?: DesignType;
  style?: DesignStyle;
  size?: Size;
  title?: string
  hoverStyle?: HoverStyle
  direction?: 'inline' | 'column'
  disabled?: boolean;
  children?: React.ReactNode
  onClick?: (event: MouseEvent) => void;
  to?: string;
  href?: string;
}

export const SIZE_CSS = (hasTittle: boolean): Record<Size, string> => (hasTittle ? {
  lg: 'min-h-[46px] min-w-[46px]',
  md: 'min-h-[40px] min-w-[40px]',
  sm: 'min-h-[30px] min-w-[30px]',
  xs: 'min-h-[24px] min-w-[24px]',
  xxs: 'min-h-[18px] min-w-[16px]',
} : {
  lg: 'h-[46px] w-[46px]',
  md: 'h-[40px] w-[40px]',
  sm: 'h-[30px] w-[30px]',
  xs: 'h-[24px] w-[24px]',
  xxs: 'h-[18px] w-[16px]',
})

const HOVER_CSS: Record<HoverStyle, any> = {
  negative: 'hover:text-negative',
  positive: 'hover:text-positive',
  normal: 'hover:text-cyanine-blue',
  none: '',
}

const IconButton = ({
  icon,
  className,
  type = 'primary',
  size = 'lg',
  style = 'oxford',
  geometry = 'rect',
  title = '',
  children,
  direction = 'inline',
  hoverStyle = 'none',
  disabled = false,
  onClick,
  href,
  to
}: IconButtonProps) => {
  const content = children || title;
  const buttonClassName = `${className} flex items-center justify-center cursor-pointer transition-all 
  ${TYPE_CSS[type][style]} ${SIZE_CSS(!!content)[size]} ${disabled && 'opacity-25 pointer-events-none'} ${geometry === 'circle' ? 'rounded-full' : 'rounded'}
  ${content && direction === 'column' && 'flex-col'}
  ${content && 'w-auto'}
  ${HOVER_CSS[hoverStyle]}
  `
  const inline = direction === 'inline'

  // TODO: remove `title` in favor of `children`
  //
  const [Root, rootProps] = to ?
    [Link, { to }] : href ?
      ['a', { href }] :
      ['button', { type: 'button', disabled }];

  return createElement(
    Root,
    {
      ...rootProps as any,
      className: buttonClassName,
      onClick
    }, 
    <Icon className={`${SIZE_ICON_CSS[size]}`} icon={icon} />,
    content && <div className={`whitespace-nowrap ${inline ? 'ml-[6px]' : 'mt-[6px]'}  font-medium`}>{content}</div>
  );
}

export default IconButton
