import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { message } from 'antd'
import Logo from 'assets/images/Logo.svg'
import LogoSmall from 'assets/images/LogoSmall.svg'
import { InputField } from 'components/Auth'
import apiService from 'helpers/api'

const ResetPassword = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [password, setPassword] = useState('')
  const [onceSubmitted, setOnceSubmitted] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const isDisabledSubmit = !password || submitting;

  const emailParam = searchParams.get('email')

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    if (e.target.value.length === 0 && onceSubmitted) {
      setPasswordError('Required Field')
    } else {
      setPasswordError('')
    }
  }

  const handleSetPassword = async () => {
    if (isDisabledSubmit) return
    if (!onceSubmitted) {
      setOnceSubmitted(true)
    }

    if (password.length <= 7) {
      setPasswordError('Password should be more than 7 characters long')
      return
    }

    if (password.length > 20) {
      setPasswordError('Password should be less than 20 characters long')
      return
    }

    const regularExpression = /^(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@\^_`{|}~])[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@\^_`{|}~]{8,20}$/;
    if (!regularExpression.test(password)) {
      setPasswordError('Password should contain at least one number and one special character')
      return
    }

    const token = searchParams.get('token')
    if (!token) return

    try {
      setSubmitting(true)
      await apiService.post(`/user/password/reset?token=${token}`, {
        password,
      })

      message.success('Password reset successful')
      navigate(`/login${emailParam ? `?email=${emailParam}` : ''}`)
    } catch (err: any) {
      setSubmitting(false);
      message.error(err.response.data.message)
    }
  }

  return (
    <div className="relative w-full h-screen bg-white md:bg-silver-grey md:flex md:justify-center md:items-center">
      <img className="hidden md:block h-[56px] absolute top-0 left-0" src={Logo} alt="Logo" />
      <img className="visible md:invisible h-[40px] mt-[10px] ml-[10px]" src={LogoSmall} alt="Logo" />
      <div className="w-full md:w-[560px] bg-white md:rounded-[20px] p-[50px] md:shadow-light-shadow">
        <h1 className="font-semibold text-[24px] leading-[34px] color-oxford-blue mb-[30px]">
          Set your new password
        </h1>
        <InputField label="Email" value={emailParam ? emailParam : 'Unknown'} disabled={true} onChange={() => {}} />
        <InputField type="password" placeholder="More than 7 characters" label="Password" value={password} error={passwordError} onChange={handleChangePassword} />
        <button
          type="button"
          disabled={isDisabledSubmit}
          className="w-full h-[46px] mt-[30px] bg-caladon-blue rounded-[10px] font-medium text-[15px] leading-[22px] tracking-[-0.24px] text-white disabled:opacity-25"
          onClick={handleSetPassword}
        >
          Set Password
        </button>
      </div>
    </div>
  )
}

export default ResetPassword
