import type { BoolThresholdConfig } from 'types/dataCollection';

import { Checkbox } from 'antd'

export interface BoolThresholdEditorProps {
  config: BoolThresholdConfig;
  disabled?: boolean;
  onChange: (config: BoolThresholdConfig) => void;
}

export const BoolThresholdEditor = (props: BoolThresholdEditorProps) => {
  const {
    config,
    disabled,
    onChange
  } = props;

  const {
    title,
    value
  } = config;

  const boolValue = value === 'True'

  return (
    <div className='my-[20px] font-medium'>
      <Checkbox
        checked={boolValue}
        disabled={disabled}
        onChange={(event) => {
          onChange({
            ...config,
            value: (event.target.checked + '').charAt(0).toUpperCase() + (event.target.checked + '').slice(1)
          });
        }}
      >
        {title}
      </Checkbox>
    </div>
  );
}
