import { useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import type { ReactNode } from 'react';

export interface PortalProps {
  children?: ReactNode;
}

export const findPortalExit = (el: HTMLElement) => {
  while (el && el !== document.body && !el?.dataset?.portalRoot) {
    el = el.parentNode as HTMLElement; 
  }

  if (el?.dataset?.portalRoot) {
    el = el.querySelector('[data-portal-exit="true"]') as HTMLElement;
  }

  return el;
}

export const Portal = (props: PortalProps) => {
  const { children } = props;
  const [exit, setExit] = useState<HTMLElement>();

  const anchorRefCallback = useCallback((anchor: HTMLSpanElement | null) => {
    if (anchor === null) {
      return;
    }

    const exit = findPortalExit(anchor);

    setExit(exit);
  }, []);

  return exit ? 
    createPortal(children, exit) : 
    <span className='hidden' ref={anchorRefCallback} />;
};
