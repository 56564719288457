import type { ReactNode } from 'react';
import Tooltip from '../Tooltip/Tooltip'

export interface FormFieldProps {
  className?: string;
  children?: ReactNode
}

const FormField = ({ className, children }: FormFieldProps) => (
  <div className={`flex-col ${className}`}>
    {children}
  </div>
)

export interface LabelProps {
  className?: string;
  label?: ReactNode;
  tip?: ReactNode;
  required?: boolean;
  children?: ReactNode;
}

const Label = ({
  className = '',
  label,
  tip,
  required,
  children
}: LabelProps) => (
  <div className={`flex items-center ${className}`}>
    <label className='text-[13px] font-medium]'>
      {label}
      {required && <b className='text-negative'>*</b>}
    </label>
    {tip && <Tooltip className='ml-1'>{tip}</Tooltip>}
    {children}
  </div>
)

export interface ControlProps {
  className?: string;
  children?: ReactNode
}

const Control = ({ className = '', children }: ControlProps) => (
  <div className={`${className} mt-[10px]`}>
    {children}
  </div>
)

export interface DescProps {
  className?: string;
  children: ReactNode;
  type?: 'error' | 'normal'
}

const Desc = ({ className, children, type = 'normal' }: DescProps) => (
  <div className={`${className} mt-[7px] text-[13px] ${type === 'normal' ? 'text-silver-grey-800' : 'text-negative'}`}>
    {children}
  </div>
)

export interface SimpleFieldProps {
  className?: string;
  label?: ReactNode;
  tip?: ReactNode;
  error?: ReactNode;
  required?: boolean;
  children: ReactNode;
}

const SimpleField = ({
  className,
  label,
  tip,
  error,
  required,
  children
}: SimpleFieldProps) => (
  <FormField className={className}>
    <Label
      label={label}
      tip={tip}
      required={required}
    />

    <Control>{children}</Control>
    {error && <Desc type='error'>{error}</Desc>}
  </FormField>
)

export default Object.assign(FormField, {
  Label,
  Control,
  Desc,
  SimpleField,
})
