import type { OrgUpdatePayload } from 'types/users'
import { useState } from 'react';
import { Input } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button } from 'shared/Button';
import { useGlobalState } from 'state/globalState';

export interface UpdateOrgDialogProps {
  id: number;
  name: string;
  slug: string;
  opened: boolean;
  close: () => void;
}

export const UpdateOrgDialog = (props: UpdateOrgDialogProps) => {
  const updateOrg = useGlobalState((store) => store.updateOrg); 

  const {
    id,
    name,
    slug,
    opened,
    close
  } = props;

  const [currentDraft, setCurrentDraft] = useState<OrgUpdatePayload>({})
  const canSave = (currentDraft.name && currentDraft.name !== '' && currentDraft.name !== name) ||
    (currentDraft.slug && currentDraft.slug !== '' && currentDraft.slug !== slug)

  return (
    <Dialog
      className='w-[480px]'
      open={opened}
      onClose={() => {
        close();
      }}
    >
      <Dialog.Title>
        Update Organization
      </Dialog.Title>

      <div className='overflow-x-auto px-[30px] border-t'>
        <div className='mt-4 mb-2 font-medium text-subtitle-1'>
          Organization Name
        </div>
        <Input
          className='mb-[20px]'
          value={currentDraft.name || name}
          onChange={(event) => {
            const name = event.target.value;

            setCurrentDraft({
              ...currentDraft,
              name,
            });
          }}
        />

        <div className='mb-2 font-medium text-subtitle-1'>
          Organization Slug
        </div>
        <Input
          className='mb-[20px]'
          value={currentDraft.slug || slug}
          onChange={(event) => {
            const slug = event.target.value;

            setCurrentDraft({
              ...currentDraft,
              slug,
            });
          }}
        />
      </div>

      <div className='flex justify-end p-[15px] border-t'>
        <Button
          style='caladon'
          label='Update Organization'
          disabled={!canSave}
          onClick={() => {
            updateOrg(id, currentDraft);
            close();
          }}
        />
      </div>
    </Dialog>
  );
};
