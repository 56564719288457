import type { CostColumn } from 'types/costColumns'

import React, { useState, useEffect } from 'react'
import Button from 'shared/Button/Button'
import Dialog from 'shared/Dialog/Dialog';
import { Input, InputNumber, Select } from 'antd'
import { IconButton } from 'shared/Button';
import { useCostColumnStore } from 'store/costColumns';


export const COST_COLUMNS: Record<string, string> = {
  'unblended_cost': 'Unblended Cost',
  'public_cost': 'Public Cost',
}

export interface CreateDialogProps {
  opened: boolean;
  columnToEdit: CostColumn | null;
  onClose: (search?: string) => void;
}

export const CreateDialog = (props: CreateDialogProps) => {
  const {
    opened,
    columnToEdit,
    onClose,
  } = props;
  const { saveCostColumn } = useCostColumnStore();
  const [columnName, setColumnName] = useState('')
  const [columnDefinition, setColumnDefinition] = useState<Record<string, number>>({})
  const [columnDefinitionOptions, setColumnDefinitionOptions] = useState(
    Object.keys(COST_COLUMNS).map((key) => ({ value: key, label: COST_COLUMNS[key] }))
  )

  const handleChangeColumnName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColumnName(e.target.value)
  }

  const handleChangeCostDefinition = (value: number | null, column: string) => {
    const newCostColumnDefiniton = {...columnDefinition}
    newCostColumnDefiniton[column] = value ? value : 0
    setColumnDefinition(newCostColumnDefiniton)
  }

  const handleDelete = (column: string) => {
    const newCostColumnDefiniton = {...columnDefinition}
    delete newCostColumnDefiniton[column]
    setColumnDefinitionOptions([...columnDefinitionOptions, { value: column, label: COST_COLUMNS[column]}])
    setColumnDefinition(newCostColumnDefiniton)
  }

  const handleAddFilterClick = () => {
    const newCostColumnDefiniton = {...columnDefinition}
    newCostColumnDefiniton[''] = 0
    setColumnDefinition(newCostColumnDefiniton)
  }

  const handleSelectColumn = (column: string) => {
    const newCostColumnDefiniton = {...columnDefinition}
    delete newCostColumnDefiniton['']
    newCostColumnDefiniton[column] = 0
    setColumnDefinition(newCostColumnDefiniton)
  }

  const handleClose = (search?: string) => {
    onClose(search);
  };

  useEffect(() => {
    if (!opened) {
      setColumnName('');
      setColumnDefinition({})
      setColumnDefinitionOptions(
        Object.keys(COST_COLUMNS).map((key) => ({ value: key, label: COST_COLUMNS[key] }))
      )
    } else if (columnToEdit) {
      setColumnName(columnToEdit.name)
      setColumnDefinition(columnToEdit.definition)
      setColumnDefinitionOptions(
        Object.keys(COST_COLUMNS).filter((key) => !Object.keys(columnToEdit.definition).includes(key)).map((key) => (
          { value: key, label: COST_COLUMNS[key]}
        ))
      )
    }
  }, [opened, columnToEdit])

  return (
    <Dialog
      open={opened}
      onClose={() => handleClose()}
      className='w-[510px] overflow-y-auto'
    >
      <Dialog.Title>
      {`${!columnToEdit ? 'Create New' : 'Edit'} Cost Column`}
      </Dialog.Title>

      <div className='px-[30px] pb-[30px] overflow-y-scroll'>
        <div className='mb-[30px]'>
          <div className='text-[16px] font-medium mb-[10px]'>
            Column name
          </div>
          <Input
            size='large'
            type='secondary'
            value={columnName}
            onChange={handleChangeColumnName}
          />
        </div>

        <div className='mb-[40px]'>
          <div>
            {Object.keys(columnDefinition).map((key: string) => {
              if (key === '') {
                return (
                  <div className='flex mb-[20px]'>
                    <Select
                      options={columnDefinitionOptions} 
                      onChange={handleSelectColumn}
                      value={key}
                      placeholder='Select a cost column'
                      className='w-full'
                    />
                  </div>
                )
              } else {
                return (
                  <div className='flex mb-[20px]'>
                    <InputNumber
                      precision={2}
                      addonAfter={` x ${COST_COLUMNS[key] ? COST_COLUMNS[key] : 'Unknown'} + ...`}
                      value={columnDefinition[key]}
                      onChange={(e) => handleChangeCostDefinition(e, key)}
                    />
                    <div>
                      <IconButton className='ml-[10px]' size='sm' type='tertiary' icon='trash' onClick={() => handleDelete(key)} />
                    </div>
                  </div>
                )
              }
            })}
          </div>
          {!Object.keys(columnDefinition).includes('') && (
            <Button 
              className="mx-auto mt-[10px] bg-grey-transparent-dashboard w-full justify-center"
              type="tertiary"
              icon="circle-plus"
              label="Add column"
              onClick={handleAddFilterClick}
            />
          )}
        </div>
        <div className='flex'>
          <Button
            label='Clear All'
            className='mr-auto'
            type='tertiary'
            style='caladon'
            onClick={() => {
              setColumnDefinition({})
              setColumnName('')
            }}
          />
          <Button
            label='Cancel'
            type='tertiary'
            style='caladon'
            onClick={() => handleClose()}
          />
          <Button
            label='Save Column'
            type='primary'
            style='caladon'
            disabled={!columnName || Object.keys(columnDefinition).length === 0}
            onClick={async () => {
              const column = {
                id: columnToEdit ? columnToEdit.id : -1,
                name: columnName,
                definition: columnDefinition,
              }
              saveCostColumn(column);
              handleClose(columnName);
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default CreateDialog
