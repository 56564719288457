import type { AllHTMLAttributes, ComponentProps, ReactNode } from 'react';
import type { IconName } from 'shared/Icon';
import { Link } from 'react-router-dom';
import { Icon } from 'shared/Icon';
import { Tooltip } from 'antd';

type NativeButtonProps = AllHTMLAttributes<HTMLButtonElement>;
type NativeLinkProps = AllHTMLAttributes<HTMLAnchorElement>;
type RouterLinkProps = ComponentProps<typeof Link>;

type BaseProps = 
  NativeButtonProps |
  NativeLinkProps |
  RouterLinkProps;

export interface ButtonProps {
  className?: string;

  to?: string;
  href?: string;
  disabled?: boolean;

  onClick?: React.MouseEventHandler;

  size?: 'l' | 'm' | 's' | 'xs';
  theme?: 'filled' | 'bordered' | 'link' | 'gray' | 'black';
  layout?: 'default' | 'inline';

  icon?: IconName | ReactNode;
  iconLeft?: IconName | ReactNode;
  iconRight?: ReactNode;

  children?: ReactNode;
}

const FONT_SIZE = {
  xs: 'text-caption-1',
  s: 'text-body',
  m: 'text-subtitle-2',
  l: 'text-subtitle-1'
};

const HEIGHT = {
  xs: 'h-control-xs',
  s: 'h-control-s',
  m: 'h-control-m',
  l: 'h-control-l'
};

const WIDTH = {
  xs: 'w-control-xs',
  s: 'w-control-s',
  m: 'w-control-m',
  l: 'w-control-l'
};

const PADDING = {
  xs: 'px-button-p-xs',
  s: 'px-button-p-s',
  m: 'px-button-p-m',
  l: 'px-button-p-l'
};

const GAP = {
  xs: 'gap-1',
  s: 'gap-1.5',
  m: 'gap-2',
  l: 'gap-2'
}

const RADIUS = {
  xs: 'rounded-1.5',
  s: 'rounded-2',
  m: 'rounded-2.5',
  l: 'rounded-3'
};

const COLORS = {
  filled: 'bg-blue border-transparent text-white hover:bg-blue-hover disabled:bg-black/5 disabled:text-black/30 focus:shadow-focus',
  bordered: 'bg-white border-black/10 text-black hover:border-black/20 disabled:text-black/30 focus:border-blue focus:shadow-focus focus:hover:border-blue-hover',
  link: 'bg-transparent border-transparent text-blue hover:text-blue-hover disabled:text-black/30 focus:shadow-focus',
  gray: 'bg-transparent border-transparent text-black/50 hover:text-black/70 disabled:text-black/30 focus:shadow-focus',
  black: 'bg-transparent border-transparent text-black hover:text-blue disabled:text-black/30 focus:shadow-focus'
};

const ICON_SIZE = {
  xs: 12,
  s: 14,
  m: 16,
  l: 18
};

export const Button = (props: ButtonProps) => {
  const {
    className,
    size = 's',
    theme = 'filled',
    layout = 'default',
    icon,
    iconLeft,
    iconRight,
    children,
    ...attrs
  } = props;

  const iconOnly = !!icon;
  const renderIcon = (icon?: IconName | ReactNode) => (
    typeof icon === 'string' ?
      (
        <Icon
          icon={icon}
          width={ICON_SIZE[size]}
          height={ICON_SIZE[size]}
        />
      ) :
      icon
  );

  const cn = [
    'inline-flex items-center justify-center grow-0 shrink-0 cursor-pointer disabled:cursor-default font-medium outline-0',
    COLORS[theme],
    FONT_SIZE[size],
    GAP[size],
    ...(layout === 'default' ? [
      'border',
      HEIGHT[size],
      RADIUS[size],

      ...(iconOnly ? [
        WIDTH[size]
      ] : [
        PADDING[size]
      ])
    ] : [
      'rounded-0.5'
    ]),
    className
  ].join(' ');

  const content = iconOnly ? 
    renderIcon(icon) :
    <>
      {renderIcon(iconLeft)}
      {children}
      {renderIcon(iconRight)}
    </>;

  const tooltipContent = iconOnly && children;

  const Root = attrs.to ? Link :
    (attrs as NativeLinkProps).href ? 'a' :
    'button'

  const button = <Root
    {...attrs as any}
    className={cn}
  >
    {content}
  </Root>;

  if (!tooltipContent) {
    return button;
  }

  return <Tooltip title={tooltipContent}>{button}</Tooltip>;
}
