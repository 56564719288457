import { isEmptyDataFilterValue } from 'helpers/dataFilters';
import type { ReactNode } from 'react';
import type { Setter } from 'types/common';
import {
  GetDataFilterValueType,
  GetDataFilterOptionsType,
  DataFilterConfigsMap,
  DataFilterValuesMap,
  DataFilterOptionsMap
} from 'types/dataFilters';

import { ClearButton } from './ClearButton';

import { DataFilterRow } from './DataFilterRow';
import { DataFilterSelect } from './DataFilterSelect';
import { DataFilterValueDisplay } from './DataFilterValueDisplay';
import { isNumberRangeDataFilterConfig, isListDataFilterConfig } from 'types/dataFilters';

export interface DataFiltersControlProps<
  TKeys extends string,
  TConfigs extends DataFilterConfigsMap<TKeys>,
  TValue extends DataFilterValuesMap<TKeys, TConfigs>,
  TOptions extends DataFilterOptionsMap<TKeys, TConfigs>
> {
  configs: DataFilterConfigsMap<TKeys>;
  value: TValue;
  options: TOptions;
  onChange: Setter<TValue>;
}

export const DataFiltersControl = <
  TKeys extends string,
  TConfigs extends DataFilterConfigsMap<TKeys>,
  TValue extends DataFilterValuesMap<TKeys, TConfigs>,
  TOptions extends DataFilterOptionsMap<TKeys, TConfigs>
>(props: DataFiltersControlProps<TKeys, TConfigs, TValue, TOptions>) => {
  const {
    value,
    configs,
    options,
    onChange
  } = props;

  const keys = Object.keys(configs) as TKeys[];

  const controlElements: ReactNode [] = keys.map((key) => {
    const config = configs[key];

    if (config.type === 'query') {
      return;
    }

    const val: GetDataFilterValueType<typeof config> = value[key];
    const opts: GetDataFilterOptionsType<typeof config> = (options as any)[key];

    if (!opts) {
      return;
    }

    return (
      <DataFilterSelect
        key={key as string}
        value={val}
        config={config}
        options={opts}
        onChange={(newVal: typeof val) => {
          onChange({
            ...value,
            [key]: newVal
          });
        }}
      />
    );
  });

  const valueElements  = keys.reduce((acc, key) => {
    const config = configs[key];
    const val: GetDataFilterValueType<typeof config> = value[key];
    const opts: GetDataFilterOptionsType<typeof config> = (options as any)[key];

    if (opts && !isEmptyDataFilterValue(val)) {
      acc.push(
        <DataFilterValueDisplay
          key={key as string}
          value={val}
          config={config}
          options={opts}
          onChange={(newVal: any) => {
            onChange({
              ...value,
              [key]: newVal
            });
          }}
        />
      );
    }

    return acc;
  }, [] as ReactNode[]);

  return (
    <div className='my-[20px] flex flex-col gap-y-[13px]'>
      <DataFilterRow>
        {controlElements}
      </DataFilterRow>

      {valueElements.length > 0 && (
        <DataFilterRow>
          {valueElements}

          <ClearButton
            onClick={() => {
              onChange(keys.reduce((acc, key) => ({
                ...acc,
                [key]:
                  isNumberRangeDataFilterConfig(configs[key]) ? { min: null, max: null } :
                  isListDataFilterConfig(configs[key]) ? [] :
                  ''
              }), {} as any));
            }}
          />
        </DataFilterRow>
      )}
    </div>
  );
};
