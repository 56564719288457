import { Button } from 'shared/Button';
import { useSSOSettingsStore } from 'store/ssoSettings';

export const ErrorMessage = () => {
  const fetchSSOSettings = useSSOSettingsStore((store) => store.fetchSSOSettings);

  return (
    <>
      <p className='mb-[20px]'>
        Unable to load SSO settings
      </p>
      <Button onClick={fetchSSOSettings} label='Try again' style='oxford' />
    </>
  );
};
