import type { CostViewRequestFilter } from 'types/costViews';
import type { Granularity } from 'types/common';
import type { DataEntry } from 'types/dataEntries';
import type { DrillDownData } from 'types/costViews/drillDown';
import type { GroupByType } from 'services/metric';
import type { ChartType } from 'types/chart'
import type { Moment } from 'moment';

import { useState, useMemo } from 'react';
import { Section, SectionTitle } from 'shared/Section';
import { Select } from 'antd';
import { useCostOverviewStore } from '../useCostOverviewStore';
import { AbsoluteCostsChart } from './AbsoluteCostsChart';
import { FIELD_TITLES_PLURAL } from '../groupByOptions';
import { BreakdownTable } from './BreakdownTable';
import { TagBreakdownTable } from './TagBreakdownTable';
import { HeaderStatus } from './HeaderStatus';
import { Button2 as Button } from 'shared/Button';
import { useTagKey } from 'hooks/useTagKey';
import { useGlobalState } from 'state/globalState';
import { useCostViewConstructor } from 'store/costViewConstructor';
import {ChartTypeControl} from 'shared/ChartTypeControl';
import {Toggle} from 'shared/Toggle';

export interface BreakdownProps {
  startDate: string;
  endDate: string;
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
  granularity: Granularity;
  className?: string;
  breakdown: GroupByType;
  drillDown: DataEntry<DrillDownData>;
  drillDownEnabled: boolean;
  availableBreakdowns: GroupByType[];
  requestFilter: DataEntry<CostViewRequestFilter>;
  setBreakdown: (breakdown: GroupByType) => void;
  setCurrentStep: (value: string) => void;
}

export const Breakdown = (props: BreakdownProps) => {
  const {
    startDate,
    endDate,
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment,
    granularity,
    className = '',
    breakdown,
    drillDown,
    drillDownEnabled,
    availableBreakdowns,
    setBreakdown,
    setCurrentStep,
    requestFilter
  } = props;

  const [
    tagKey,
    setTagKey,
  ] = useTagKey();

  const costViewConstructor = useCostViewConstructor();

  const tags = useMemo(() => {
    if (costViewConstructor.status === 'success') {
      return ((costViewConstructor.data.cur?.rules.tags?.options || []) as string[]).map((value) => ({ value, label: value }));
    }

    return [];
  }, [costViewConstructor]);

  const {
    drillDownOpened,
    chartCollapsed,
    setDrillDownOpened,
    setChartCollapsed,
  } = useCostOverviewStore()

  const logEvent = useGlobalState((state) => state.logEvent);

  const [chartType, setChartType] = useState<ChartType>('line')

  const options = availableBreakdowns.length ? 
    availableBreakdowns.map((value) => ({ value, label: FIELD_TITLES_PLURAL[value] })) :
    [{ value: breakdown, label: FIELD_TITLES_PLURAL[breakdown]}];

  return (
    <div className={`overflow-hidden ${className}`}>
      <Section className='mb-4'>
        <div className='flex items-center min-h-[60px] px-5 gap-5 -mb-4'>
          <SectionTitle className='overflow-hidden text-ellipsis'>Absolute Costs - Breakdown</SectionTitle>

          <Select
            className="min-w-[130px]"
            options={options}
            onChange={(key) => {
              setBreakdown(key);
              logEvent('set_breakdown', { 'breakdown': key, 'source': 'breakdown_pane' });
            }}
            value={breakdown}
            disabled={availableBreakdowns.length <= 1}
          />

          <div className='flex items-center font-medium text-caption-1 gap-2'>
            <Toggle
              checked={!chartCollapsed}
              onChange={() => {
                setChartCollapsed(!chartCollapsed);
                logEvent('collapse_drilldown_chart', { 'state': !chartCollapsed })
              }}
            />
            Chart
          </div>

          {!chartCollapsed &&
            <ChartTypeControl value={chartType} onChange={setChartType} />
          }

          {!drillDownOpened && drillDownEnabled && (
            <Button 
              theme='black'
              iconLeft='aside-layout'
              onClick={() => {
                setDrillDownOpened(true);
                logEvent('open_drilldown');
              }}
            >
              Show
            </Button>
          )}

    {/*
          <Button 
            theme='black'
            iconLeft={chartCollapsed ? 'show' : 'hide'}
            onClick={() => {
              setChartCollapsed(!chartCollapsed);
              logEvent('collapse_drilldown_chart', { 'state': !chartCollapsed })
            }}
          >
            {chartCollapsed ? 'Show Chart' : 'Hide Chart'}
          </Button>
      */}

          <div className='ml-auto'>
            <HeaderStatus drillDown={drillDown} />
          </div>
        </div>

        {!chartCollapsed && (
          <AbsoluteCostsChart 
            drillDown={drillDown} 
            chartType={chartType}
            setCurrentStep={setCurrentStep} 
          />
        )}

        <Section.Content className='mt-5'>
          <BreakdownTable
            breakdown={breakdown}
            drillDown={drillDown}
            canDrillDown={availableBreakdowns.length > 0}
            setCurrentStep={setCurrentStep}
            startMoment={startMoment}
            endMoment={endMoment}
            prevStartMoment={prevStartMoment}
            prevEndMoment={prevEndMoment}
          />
        </Section.Content>
      </Section>

      <Section>
        <div className='h-[60px] mx-5 flex gap-4 items-center'>
          <SectionTitle>Absolute Costs - Tag Analyzer</SectionTitle>

          <Select
            className='w-[340px]'
            options={tags}
            onChange={(key) => {
              setTagKey(key);
              logEvent('set_tag_breakdown')
            }}
            value={tagKey}
            placeholder='Pick a tag key'
          />
        </div>

        <Section.Content>
          {tagKey && (
            <TagBreakdownTable
              startDate={startDate}
              endDate={endDate}
              startMoment={startMoment}
              endMoment={endMoment}
              prevStartMoment={prevStartMoment}
              prevEndMoment={prevEndMoment}
              granularity={granularity}
              requestFilter={requestFilter}
            />
          )}
        </Section.Content>
      </Section>
    </div>
  )
};
