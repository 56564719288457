import type { UserUpdatePasswordPayload } from 'types/users';
import { useState } from 'react';
import { message, Input } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button } from 'shared/Button';
import { useGlobalState } from 'state/globalState';

export interface UpdateUserPasswordDialogProps {
  opened: boolean;
  close: () => void;
}

export const UpdateUserPasswordDialog = (props: UpdateUserPasswordDialogProps) => {
  const updateUserPassword = useGlobalState((store) => store.updateUserPassword); 

  const { 
    opened,
    close
  } = props;
  
  const [currentDraft, setCurrentDraft] = useState<UserUpdatePasswordPayload>(
    {'current_password': '', 'new_password': ''}
  )
  const canSave = currentDraft.current_password !== '' && currentDraft.new_password !== ''

  return (
    <Dialog
      className='w-[480px]'
      open={opened}
      onClose={() => {
        close();
      }}
    >
      <Dialog.Title>
        Update User Password
      </Dialog.Title>

      <div className='overflow-x-auto px-[30px] border-t'>
        <div className='mt-4 mb-2 font-medium text-subtitle-1'>
          New Password
        </div>
        <Input
          className='mb-[20px]'
          value={currentDraft.new_password}
          onChange={(event) => {
            const new_password = event.target.value;

            setCurrentDraft({
              ...currentDraft,
              new_password,
            });
          }}
        />

        <div className='mb-2 font-medium text-subtitle-1'>
          Current Password
        </div>
        <Input
          className='mb-[20px]'
          value={currentDraft.current_password}
          onChange={(event) => {
            const current_password = event.target.value;

            setCurrentDraft({
              ...currentDraft,
              current_password,
            });
          }}
        />
      </div>

      <div className='flex justify-end p-[15px] border-t'>
        <Button
          style='caladon'
          label='Update User'
          disabled={!canSave}
          onClick={() => {
            if (currentDraft.new_password.length <= 7) {
              message.error('Password should be more than 7 characters long')
              return
            }
            if (currentDraft.new_password.length > 20) {
              message.error('Password should be less than 20 characters long')
              return
            }
            const regularExpression = /^(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@\^_`{|}~])[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@\^_`{|}~]{8,20}$/;
            if (!regularExpression.test(currentDraft.new_password)) {
              message.error('Password should contain at least one number and one special character')
              return
            }
            updateUserPassword(currentDraft);
            close();
          }}
        />
      </div>
    </Dialog>
  );
};
