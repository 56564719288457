import type { ReactNode } from 'react';
import type { Opportunity, OpportunityTag } from 'types/savings';

import { Section } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { SimpleTable, SimpleTableRow, SimpleTableCell } from 'shared/SimpleTable';

import {
  renderOpportunityAccount,
  renderOpportunityService,
  renderOpportunityRegion,
  pickOpportunityTags,
  pickOpportunityAccountTags
} from 'helpers/savings';

import { useSavingsStore } from 'store/savings';
import { Icon } from 'shared/Icon';
import {OpportunityStatusTable} from './OpportunityStatusTable';

export interface CostAttributesSectionProps {
  className?: string;
  opportunityId: number;
}

const renderTags = (tags: OpportunityTag[]) => tags.length ? <>
  {tags.map(({key, value}) => 
    <div className='my-0.5 font-mono text-caption-1'>
      <b>{key}:</b> '{value}',
    </div>
  )}
</> : null;

const rows: [string, string, (opportunity: Opportunity) => ReactNode][] = [
  ['account', 'Account', renderOpportunityAccount],
  ['service', 'Service', renderOpportunityService],
  ['region', 'Region', renderOpportunityRegion],

  ['tag', 'Account Tags', (opp: Opportunity) => {
    const tags = pickOpportunityAccountTags(opp);

    return renderTags(tags);
  }],

  ['tag', 'Resource Tags', (opp: Opportunity) => {
    const tags = pickOpportunityTags(opp);

    return renderTags(tags);
  }]
];

export const CostAttributesSection = (props: CostAttributesSectionProps) => {
  const {
    className = '',
    opportunityId
  } = props;

  const opportunity = useSavingsStore((store) => store.getOpportunity(opportunityId));

  return (
    <Section className={`relative px-5 py-2 ${className}`}>
      <OpportunityStatusTable opportunityId={opportunityId} />

      <SimpleTable className='mt-4'>
        <tbody>
          {rows.map(([icon, title, render]) => (
            <SimpleTableRow key={title} className='align-top'>
              <SimpleTableCell className='w-1/3'>
                <Icon icon={icon} width={14} height={14} className='inline-block mr-2 text-gray-500'/>
                <b>{title}</b>
              </SimpleTableCell>

              <SimpleTableCell>
                {opportunity.status === 'success' && (render(opportunity.data) || '–')}
              </SimpleTableCell>
            </SimpleTableRow>
          ))}
        </tbody>
      </SimpleTable>

      <StatusOverlay status={opportunity.status} />
    </Section>
  );
}
