import { ReactNode } from 'react';
import type { Moment } from 'moment';

export interface DateTextProps {
  description: ReactNode;
  startMoment?: Moment;
  endMoment: Moment;
}

export const DateText = (props: DateTextProps) => {
  const {
    startMoment,
    endMoment,
    description
  } = props;

  let startDateFormat = 'D';
  let endDateFormat = 'D MMM YYYY';

  if (startMoment) {
    if (startMoment.month() !== endMoment.month() || startMoment.year() !== endMoment.year()) {
      startDateFormat += ' MMM ';
      endDateFormat = ' ' + endDateFormat;
    }

    if (startMoment.year() !== endMoment.year()) {
      startDateFormat += 'YYYY ';
    }
  }

  const startDateText = startMoment?.format(startDateFormat);
  const endDateText = endMoment.format(endDateFormat);

  return (
    <div className='text-[11px] leading-[13px]'>
      {description}
      <span className='text-silver-grey-900'>
        {` • ${startMoment ? `${startDateText}-` : ''}${endDateText}`}
      </span>
    </div>
  );
};
