import { useState } from 'react'
import { Input } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button } from 'shared/Button'
import { useApiKeyStore } from 'store/apiKeys';

export interface CreateDialogProps {
  opened: boolean;
  onClose: (search?: string) => void;
}

export const CreateDialog = (props: CreateDialogProps) => {
  const {
    opened,
    onClose,
  } = props;
  const { saveApiKey } = useApiKeyStore();
  const [apiKeyName, setApiKeyName] = useState('')

  const handleChangeApiKeyName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApiKeyName(e.target.value)
  }

  const handleClose = (search?: string) => {
    onClose(search);
  };

  return (
    <Dialog
      open={opened}
      onClose={() => handleClose()}
      className='w-[510px] overflow-y-auto'
    >
      <Dialog.Title>
      {'Create New API Key'}
      </Dialog.Title>

      <div className='px-[30px] pb-[30px]'>
        <div className='mb-[30px]'>
          <div className='mb-2 font-medium text-[16px]'>
            Name
          </div>

          <Input
            size='large'
            value={apiKeyName}
            onChange={handleChangeApiKeyName}
          />
        </div>
        <div className='flex justify-end'>
          <Button
            label='Cancel'
            type='tertiary'
            style='caladon'
            onClick={() => handleClose()}
          />
          <Button
            label='Save API Key'
            type='primary'
            style='caladon'
            disabled={!apiKeyName}
            onClick={async () => {
              const config = {
                name: apiKeyName,
              }

              saveApiKey(config);
              handleClose(apiKeyName);
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default CreateDialog
