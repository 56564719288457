import { message } from 'antd';
import { Button2 as Button } from 'shared/Button';
import { useSavingsStore } from 'store/savings';

export interface OpportunityArchiveButtonProps {
  opportunityId: number;
}

export const OpportunityArchiveButton = (props: OpportunityArchiveButtonProps) => {
  const { opportunityId } = props;
  const archiveOpportunity = useSavingsStore((store) => store.archiveOpportunity);
  const currentAction = useSavingsStore((store) => store.opportunityActions.get(opportunityId));

  return (
    <Button
      size='xs'
      theme='gray'
      layout='inline'
      iconLeft='archive'
      disabled={currentAction && currentAction.status === 'in-progress'}
      onClick={() => {
        archiveOpportunity(opportunityId)
          .then(() => {
            message.success('Opportunity archived');
          })
          .catch(() => {
            message.error('Unable to archive opportunity');
          });
      }}
    >
      Archive
    </Button>
  );
}
