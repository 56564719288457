import create from 'zustand'

import type { FetchStatus } from 'types/common';
import type { ApiKey } from 'types/apiKey';

import {
  getApiKeys,
  saveApiKey,
  deleteApiKey,
} from 'services/apiKeys'


interface FetchParams {
  force?: boolean;
}

export interface DataState {
  status: FetchStatus;
  apiKeys: ApiKey[];
}

export interface DataActions {
  fetchApiKeys: (params?: FetchParams) => void;
  saveApiKey: (key: any) => void;
  deleteApiKey: (id: number) => Promise<void>;
}

export interface ApiKeyStore extends DataState, DataActions {}

const DEFAULT_STATE: DataState = {
  status: 'idle',
  apiKeys: [],
};

export const useApiKeyStore = create<ApiKeyStore>((set, get) => {
  let requestCount = 0;

  return {
    ...DEFAULT_STATE,

    fetchApiKeys: async (params = {}) => {
      const { force } = params;
      const { status } = get();

      /*
      if (!force && ['loading', 'success'].includes(status)) {
        return;
      }
      */

      try {
        if (status !== 'success') {
          set({ status: 'loading' });
        }

        const requestNumber = ++requestCount;
        
        const { data: keys } = await getApiKeys();
        const apiKeys = keys.map((data: any) => {
          const newKey: ApiKey = {
            id: data.id,
            name: data.name,
            token: data.token,
          }
          return newKey
        })

        if (requestNumber === requestCount) {
          set({
            apiKeys,
            status: 'success'
          });
        }
      } catch(error) {
        set({ status: 'error' });
      }
    },

    saveApiKey: async (key: any) => {
      const { apiKeys } = get();
      try {
        let data
        ({ data } = await saveApiKey(key))
        const newKey: ApiKey = {
          id: data.id,
          name: data.name,
          token: data.token,
        }
        const static_keys = apiKeys.filter((key) => key.id !== newKey.id)
        set({ apiKeys: [newKey, ...static_keys] })
      } catch(error) {
        set({ apiKeys });
        throw error;
      }
    },

    deleteApiKey: async (id: number) => {
      const { apiKeys } = get();
      set({ apiKeys: apiKeys.filter((key) => key.id !== id) });

      try {
        await deleteApiKey(id);
      } catch(error) {
        set({ apiKeys });
        throw error;
      }
    },
  };
});
