import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import { Header, InputField } from 'components/Auth'
import { validateEmail } from 'helpers/validator'
import apiService from 'helpers/api'


const ForgotPassword = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [onceSubmitted, setOnceSubmitted] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const isDisabledSubmit = !email || submitting;

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    if (e.target.value.length === 0 && onceSubmitted) {
      setEmailError('Required Field')
    } else {
      setEmailError('')
    }
  }

  const handleResetPassword = async () => {
    if (isDisabledSubmit) return
    if (!onceSubmitted) {
      setOnceSubmitted(true)
    }

    if (!validateEmail(email)) {
      setEmailError('Invalid email format')
      return
    }

    try {
      setSubmitting(true)
      await apiService.post('/user/password/reset-request', {
        email,
      })

      message.info('Check your email for the forgot password link')
      navigate(`/login?email=${email}`)
    } catch (err: any) {
      setSubmitting(false);
      message.error(err.response.data.message)
    }
  }

  return (
    <div className="w-full min-h-screen bg-white md:bg-silver-grey pb-[100px]">
      <Header type="signup" />
      <div className="mt-[30px] w-full md:w-[560px] mx-auto bg-white md:rounded-[20px] p-[50px] md:shadow-light-shadow">
        <h1 className="font-semibold text-[24px] leading-[34px] color-oxford-blue mb-[30px]">
          Enter your account email
        </h1>
        <InputField label="Email" value={email} error={emailError} onChange={handleChangeEmail} />
        <button
          type="button"
          disabled={isDisabledSubmit}
          className='w-full h-[46px] mt-[30px] bg-caladon-blue rounded-[10px] font-medium text-[15px] leading-[22px] tracking-[-0.24px] text-white disabled:opacity-25'
          onClick={handleResetPassword}
        >
          Request Forgot Password Link
        </button>
      </div>
    </div>
  )
}

export default ForgotPassword
