export const createChannels = (reportDetails: any) => {
    const channels = []
    if (reportDetails.providers.email.recipients.length > 0) {
      channels.push('Email')
    }
    if (reportDetails.providers.slack.recipients.length > 0) {
      channels.push('Slack')
    }
    return channels.join(', ')
}
