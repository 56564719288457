import type { ReactNode } from 'react';
import type {
  DataFilterValue,
  ListDataFilterValue,
  RangeDataFilterValue,
  QueryDataFilterValue
} from 'types/dataFilters';

import { returnTrue } from 'helpers/utils';

export const isEmptyQueryDataFilterValue = (value: QueryDataFilterValue) => value.trim() === '';
export const isEmptyListDataFilterValue = (value: ListDataFilterValue) => value.length === 0;
export const isEmptyRangeDataFilterValue = (value: RangeDataFilterValue) => value.min === null && value.max === null;

export const isEmptyDataFilterValue = (value: DataFilterValue) => !value || (value as any).length === 0 || (value as any).min === null && (value as any).max === null;

export const createListDataFilterFunction = <TRecord, TValue extends string>(pick: (record: TRecord) => TValue | null) =>
  (filter: ListDataFilterValue<TValue>) => {
    if (!filter || filter.length === 0) {
      return returnTrue;
    }

    const filterSet = new Set(filter);

    return (record: TRecord) => filterSet.has(pick(record) as TValue);
  };

export const createRangeDataFilterFunction = <TRecord, TValue>(
  pick: (record: TRecord) => TValue | null,
  compare: (valA: TValue, valB: TValue) => number
) =>
  (filter: RangeDataFilterValue<TValue>) => {
    if (filter === null || filter.min === null && filter.max === null) {
      return returnTrue;
    }

    return (record: TRecord) => {
      const value = pick(record);

      return value !== null &&
        (filter.min === null || compare(value, filter.min) >= 0) &&
        (filter.max === null || compare(value, filter.max) <= 0);
    };
  }

export const createQueryDataFilterFunction = <TRecord>(
  picks: ((record: TRecord) => ReactNode)[],
) => 
  (query: string | null) => {
    if (!query || query.trim() === '') {
      return returnTrue;
    }

    let match: (str: string) => boolean;

    try {
      const regexp = new RegExp(query, 'i');

      match = (str: string) => regexp.test(str);
    } catch {
      const normalizedQuery = query.trim().toLowerCase();

      match = (str: string) => str.trim().toLowerCase().includes(normalizedQuery);
    }

    return (record: TRecord) => picks.some((pick) => {
      const value = pick(record);

      return typeof value === 'string' && match(value);
    });
  }
