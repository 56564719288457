import type { Ref, MouseEvent, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { forwardRef, createElement } from 'react'
import { Icon, IconName } from 'shared/Icon/Icon'
import { Size } from 'shared/size'
import {
  DesignStyle, DesignType, SIZE_CSS, TEXT_CSS, TYPE_CSS,
} from 'shared/types'

export interface ButtonProps {
  className?: string;
  type?: DesignType;
  //TODO: remove label from props as well as from the markup, set content through `children`
  label?: ReactNode;
  icon?: IconName;
  iconAlign?: 'left' | 'right';
  style?: DesignStyle;
  size?: Size;
  to?: string;
  href?: string;
  disabled?: boolean;
  title?: string;
  children?: ReactNode
  download?: string;
  onClick?: (event: MouseEvent) => void;
}

const OFFSET_ICON_CSS: Record<Size, string> = {
  lg: 'mx-[5px]',
  md: 'mx-[4px]',
  sm: 'mx-[4px]',
  xs: 'mx-[3px]',
  xxs: '',
}

const SIZE_ICON_CSS: Record<Size, string> = {
  lg: 'w-[24px] h-[24px]',
  md: 'w-[20px] h-[20px]',
  sm: 'w-[16px] h-[16px]',
  xs: 'w-[14px] h-[14px]',
  xxs: '',
}

const Button = forwardRef((props: ButtonProps, forwardedRef: Ref<HTMLButtonElement | HTMLAnchorElement>) => {
  const {
    to,
    href,
    label,
    title,
    className,
    icon,
    iconAlign = 'left',
    type = 'primary',
    size = 'lg',
    style = 'oxford',
    disabled = false,
    download,
    onClick,
    children,
  } = props;

  const isIconRight = iconAlign === 'right'
  const buttonClassName = `flex items-center rounded-[10px] outline-0 disabled:opacity-25 disabled:pointer-events-none cursor-pointer ${TYPE_CSS[type][style]} ${SIZE_CSS[size]} ${TEXT_CSS[size]} ${isIconRight && 'flex-row-reverse'} ${className}`

  const [Root, rootProps] = to ?
    [Link, { to }] : href ?
      ['a', { href, download }] :
      ['button', { type: 'button', disabled }];

    return createElement(
      Root,
      {
        ...rootProps as any,
        title,
        ref: forwardedRef as any,
        className: buttonClassName,
        onClick
      }, 
      icon && <Icon className={`${OFFSET_ICON_CSS[size]} ${SIZE_ICON_CSS[size]}`} icon={icon} />,
      label && (
        <label className={`cursor-pointer ${OFFSET_ICON_CSS[size]}`}>
          <span className="whitespace-nowrap">{label}</span>
          {' '}
          {children}
        </label>
      )
  );
});

export default Button
