import { message } from 'antd';
import { Button2 as Button } from 'shared/Button';
import { useSavingsStore } from 'store/savings';

export interface OpportunityRestoreButtonProps {
  opportunityId: number;
}

export const OpportunityRestoreButton = (props: OpportunityRestoreButtonProps) => {
  const { opportunityId } = props;

  const restoreOpportunity = useSavingsStore((store) => store.restoreOpportunity);
  const currentAction = useSavingsStore((store) => store.opportunityActions.get(opportunityId));

  return (
    <Button
      size='xs'
      theme='link'
      layout='inline'
      iconLeft='undo'
      disabled={currentAction && currentAction.status === 'in-progress'}
      onClick={() => {
        restoreOpportunity(opportunityId)
          .then(() => {
            message.success('Opportunity restored');
          })
          .catch(() => {
            message.error('Unable to restore opportunity');
          });
      }}
    >
      Restore
    </Button>
  );
}
