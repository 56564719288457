import type { DataEntry } from 'types/dataEntries';
import type { GitHubPR } from 'types/savings';

import create from 'zustand';
import { Map as ImmutableMap } from 'immutable';

import { fetchGitHubPRs } from 'services/savings';

export interface GitHubPRsState {
  prs: ImmutableMap<number, DataEntry<GitHubPR[]>>;
  getPRs: (threadId: number) => DataEntry<GitHubPR[]>;
}

export const useGitHubPRsStore = create<GitHubPRsState>((set, get) => ({
  prs: ImmutableMap(),

  getPRs: (threadId: number) => {
    let { prs } = get();

    let threadPRs = prs.get(threadId); 

    if (!threadPRs) {
      threadPRs = { status: 'loading' };

      set({ prs: prs.set(threadId, threadPRs) });

      fetchGitHubPRs(threadId)
        .then((data) => {
          set({
            prs: get().prs.set(threadId, {
              status: 'success',
              data
            })
          });
        })
        .catch((error) => {
          set({
            prs: get().prs.set(threadId, {
              status: 'error',
              error
            })
          });
        });
    }

    return threadPRs;
  }
}));
