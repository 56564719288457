import type { AllHTMLAttributes, ReactNode } from 'react'
import { ReactComponent as Arrow } from 'assets/images/ArrowDown.svg';

export interface DataFilterSelectButtonProps extends AllHTMLAttributes<HTMLButtonElement> {
  empty?: boolean;
  opened?: boolean;
  children?: ReactNode;
}

export const DataFilterSelectButton = (props: DataFilterSelectButtonProps) => {
  const {
    empty,
    opened,
    children,
    ...rest
  } = props;

  return (
    <button
      {...rest}
      type='button'
      className={
        `inline-flex items-center border py-[4px] px-[9px] rounded-[12px] hover:bg-[#e5ebf2]/[0.4] bg-clip-padding ${
          empty ? 'border-dashed' : 'border-black'
        } ${
          opened ? 'bg-[#e5ebf2]/[0.4]' : ''
        }`
      }
    >
      <span
        className='text-[11px] leading-[13px] font-medium mr-[5px]'
      >
        {children}
      </span>

      <Arrow
        className={`w-[7px] ${opened ? 'rotate-180' : ''}`}
      />
    </button>
  );
};
