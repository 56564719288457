import { DataEntry, isDataEntry } from 'types/dataEntries';

export const withKeyDep = <TKey, TData>(cb: (key: TKey) => TData) => (key: TKey | DataEntry<TKey>) => {
  if (!isDataEntry(key)) {
    return cb(key);
  }

  if (key.status !== 'success') {
    return key;
  }

  return cb(key.data);
};
