import { Input } from 'antd';

export interface DescriptionInputProps {
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

export const DescriptionInput = (props: DescriptionInputProps) => {
  const {
    value,
    disabled,
    onChange
  } = props;

  return (
    <Input.TextArea
      placeholder='Describe the opportunity'
      value={value}
      disabled={disabled}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    />
  );
}
