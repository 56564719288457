import { Checkbox } from 'antd';

import {
  useCostAmortization,
  useCostTypeValue
} from 'hooks/costFilter';

export const CostAmortizationControl = () => {
  const [value, onChange] = useCostAmortization();
  const costType = useCostTypeValue();
  const disabled = costType !== 'unblended_cost';

  return (
    <Checkbox
      checked={value} 
      disabled={disabled}
      onChange={(event) => {
        onChange(event.target.checked);
      }}
    >
      Amortization
    </Checkbox>
  );
};
