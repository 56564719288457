import type {
  DrillDownField,
  DrillDownFetchParams,
  DrillDownStoreKey,
  DrillDownStoreKeyProps,
  DrillDownStoreKeyFactory
} from 'types/tagViews/drillDown';

import {
  Set as ImmutableSet,
  Record as ImmutableRecord,
  OrderedMap
} from 'immutable';

import {
  defaultDateFilterKeyProps,
  defaultTagListFilterKeyProps,
} from './defaults';

import { toImmutableFilter } from 'helpers/filters';

const createDrillTagViewDownStoreKey: DrillDownStoreKeyFactory = ImmutableRecord<DrillDownStoreKeyProps>({
  ...defaultDateFilterKeyProps,
  ...defaultTagListFilterKeyProps,
  breakdown: 'accounts',
  filter: null,
  drillDownSteps: OrderedMap(),
  tag: undefined
});

export const drillDownTagViewFetchParamsToKey = (params: DrillDownFetchParams): DrillDownStoreKey => {
  const filter = toImmutableFilter(params.filter);

  const tagList = ImmutableSet(params.tagList);

  const drillDownSteps = params.drillDownSteps.reduce(
    (map, [key, value]) => map.set(key, value),
    OrderedMap() as OrderedMap<DrillDownField, string>
  );

  return createDrillTagViewDownStoreKey({
    ...params,
    filter,
    tagList,
    drillDownSteps
  });
};
