import DataConfigsLibrary from './DataConfigsLibrary';
import CFNStacksLibrary from './CFNStacksLibrary';
import ProviderSelection from './ProviderSelection';

export const PullDataCollectionTab = () => {
  return (
    <div>
      <DataConfigsLibrary />
      <ProviderSelection onboarding={false} />
      <CFNStacksLibrary />
    </div>
  );
}
