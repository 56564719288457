import type { ReactNode } from 'react';

import { Link } from 'react-router-dom';
import { Icon } from 'shared/Icon';

export interface LinkSectionProps {
  to: string;
  children: ReactNode;
}

export const LinkSection = (props: LinkSectionProps) => {
  const {
    to,
    children
  } = props;

  return (
    <Link
      to={to}
      className='flex grow items-center justify-center text-[15px] font-medium rounded-lg text-cyanine-blue hover:text-cyanine-blue hover:bg-white hover:shadow-metrics-block'
    >
      <Icon
        icon='circle-plus'
        className='h-[24px] w-[24px] mr-[8px]'
      />
      {children}
    </Link>
  );
};
