import { Button2 as Button } from 'shared/Button';
import { useCustomOpportunityDialog } from 'store/savings';

export interface OpportunityUpdateButtonProps {
  opportunityId: number;
}

export const OpportunityUpdateButton = (props: OpportunityUpdateButtonProps) => {
  const {
    opportunityId
  } = props;

  const openOpportunityUpdateDialog = useCustomOpportunityDialog((store) => store.open);

  return (
    <Button
      size='xs'
      theme='link'
      layout='inline'
      iconLeft='edit'
      onClick={() => {
        openOpportunityUpdateDialog(opportunityId);
      }}
    >
      Edit
    </Button>
  );
};
