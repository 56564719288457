import { fetchTagViewChart  } from 'services/tagViews';
import { tagViewChartFetchParamsToKey } from 'helpers/storeKeys/tagViewChart';
import { createKeyedStore } from './createKeyedStore';
import { createKeyedStoreSelector } from './createKeyedStoreSelector';

export const useTagViewChartStore = createKeyedStore({
  paramsToKey: tagViewChartFetchParamsToKey,
  fetchData: fetchTagViewChart
});

export const useTagViewChart = createKeyedStoreSelector(useTagViewChartStore);
