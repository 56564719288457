import type { ReactNode, ReactElement } from 'react';
import type { Setter, Sort, ValueAndSetter } from 'types/common';
import { 
  Opportunity, 
  OpportunityFilterValue, 
  OpportunityPagination, 
  OpportunityPrefilterQuery,
  OpportunitySortField,
  OPPORTUNITY_STATUSES,
  StatusFilterValue,
} from 'types/savings';

import {
  StatusFilter,
  ArchiveSelectedButton,
  RestoreSelectedButton,
  ResolveSelectedButton,
  ValidateSelectedButton,
  ImplementSelectedButton,
  RejectSelectedButton,
  AutomateSelectedButton,
  TextSearch
} from '../controls';

import type { ColumnType } from 'antd/lib/table/interface';

import { useOpportunityStatusQuery } from '../hooks/opportunityStatusQuery';

import moment from 'moment';
import { useState, useMemo, useEffect } from 'react';
import { Select } from 'antd';
import { RadioButtonProps } from 'shared/RadioButtonGroup';
import { Button2 as Button } from 'shared/Button';
import { SectionCaption, SectionTitle } from 'shared/Section';
import { OpportunitiesTable } from './OpportunitiesTable';
import { OpportunitiesTableSummary } from './OpportunitiesTableSummary';
import { DataFiltersControl } from 'shared/DataFilters';
import { StatusOverlay } from 'shared/Overlay';
import { SelectionSummary } from './SelectionSummary';

import { 
  EMPTY_OPPORTUNITY_FILTER,
  OPPORTUNITY_FILTER_CONFIGS,
  ACTIVE_OPPORTUNITY_STATUSES,
  INPROGRESS_OPPORTUNITY_STATUSES,
} from 'helpers/savings';

import { useGlobalState } from 'state/globalState';
import { useOpportunitySortQuery } from '../hooks/opportunitySortQuery';
import { useOpportunityFiltersQuery } from '../hooks/opportunityFiltersQuery';
import { useOpportunityFilterOptions } from '../hooks/opportunityFilterOptions';
import { useSavingsStore } from 'store/savings';
import { useLocation } from 'react-router-dom';
import { usePageFilters } from '../hooks/pageFilters';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { identity } from 'helpers/utils';

export interface OpportunitiesBlockProps {
  prefilter?: OpportunityPrefilterQuery;
  title: ReactNode;
  subtitle?: ReactNode;
  selected?: Opportunity[];
  onSelect?: (opportunities: Opportunity[]) => void;
  pagination?: OpportunityPagination;
  onPagination?: Setter<OpportunityPagination>;
  statusFilter?: StatusFilterValue;
  setStatusFilter?: (status: StatusFilterValue) => void;
  useQuery?: boolean;
  extraHeaderActions?: ReactNode;
  overrideOpportunityActions?: ReactNode;
  extraOpportunityActionsLeft?: ReactNode;
  extraOpportunityActionsRight?: ReactNode;
  extraStatusFilters?: ReactElement<RadioButtonProps<StatusFilterValue>>;
  extraTableActions?: ColumnType<Opportunity>;
  extraTableFooters?: ReactNode;
  ignorePageFilters?: boolean;
}

export const OpportunitiesBlock = (props: OpportunitiesBlockProps) => {
  const {
    prefilter = {},
    title,
    subtitle,
    useQuery,
    extraHeaderActions,
    overrideOpportunityActions,
    extraOpportunityActionsLeft,
    extraOpportunityActionsRight,
    extraStatusFilters,
    extraTableActions,
    extraTableFooters,
    ignorePageFilters,
  } = props;

  const { search } = useLocation();

  const selectionState = useState<Opportunity[]>([]);

  const [selected, onSelect] = props.selected && props.onSelect ? 
    [props.selected, props.onSelect] : 
    selectionState;

  const paginationState = useState<OpportunityPagination>({
    ids: [],
    perPage: 10
  });

  const [pagination, onPagination] = props.pagination && props.onPagination ?
    [props.pagination, props.onPagination] :
    paginationState;

  const statusState = useOpportunityStatusQuery();
  const [statusFilter, setStatusFilter] = props.statusFilter && props.setStatusFilter ? 
    [props.statusFilter, props.setStatusFilter] : 
    statusState;

  const [quickFilter, setQuickfilter] = useState<boolean>(true);

  const prefilterWithStatus = useMemo(() => {
    if ('status' in prefilter) {
      return prefilter
    }

    if (statusFilter === 'active') {
      return {
        ...prefilter,
        status: ACTIVE_OPPORTUNITY_STATUSES
      };
    } else if (statusFilter === 'in_progress') {
      return {
        ...prefilter,
        status: INPROGRESS_OPPORTUNITY_STATUSES
      };
    } else if (statusFilter === 'all') {
      setQuickfilter(false)
      return {
        ...prefilter,
      };
    }

    return {
      ...prefilter,
      status: [statusFilter] as string[]
    };
  }, [statusFilter]);


  const filterOptions = useOpportunityFilterOptions(prefilterWithStatus);
  const [tableFilters, setFilters]: ValueAndSetter<OpportunityFilterValue> = useQuery ?
    useOpportunityFiltersQuery(/*filterOptions*/) :
    useState(EMPTY_OPPORTUNITY_FILTER);

  const onFiltersChange: Setter<OpportunityFilterValue> = (value) => {
    setFilters(value);
    onSelect([]);
    onPagination({ ...pagination, ids: [] });
  }

  const [sort, setSort] = useOpportunitySortQuery();

  const onSort: Setter<Sort<OpportunitySortField>> = (sort) => {
    setSort(sort);
    onSelect([]);
    onPagination({ ...pagination, ids: [] });
  }

  const pageFilters = usePageFilters();
  const opportunitiesParams = useDerivedDataEntry({
    prefilter: prefilterWithStatus,
    pageFilters: ignorePageFilters ? undefined : pageFilters,
    tableFilters,
    sort,
    pagination,
    filterOptions
  }, identity);

  const opportunities = useSavingsStore((store) => store.getOpportunities(opportunitiesParams));

  const user = useGlobalState((state) => state.user);
  const paywalled = useMemo(() => {
    return !user?.billing_details.is_paying && moment().isAfter(user?.billing_details.trial_end_date);
  }, [user]);

  useEffect(() => {
    if (useQuery) {
      onSelect([]);
      onPagination({ ...pagination, ids: [] });
    }
  }, [search]);

  const defaultOpportunityActions = statusFilter !== 'trash' ? (
    <div className='flex mx-5 gap-5'>
      <ValidateSelectedButton 
        selected={selected} 
        onSelect={onSelect}
      />

      <ImplementSelectedButton 
        selected={selected} 
        onSelect={onSelect}
      />

      <ResolveSelectedButton 
        selected={selected} 
        onSelect={onSelect}
      />

      <RejectSelectedButton 
        selected={selected} 
        onSelect={onSelect}
      />

      <AutomateSelectedButton
        selected={selected}
      />

      <ArchiveSelectedButton 
        selected={selected} 
        onSelect={onSelect}
      />
    </div>
  ) : (
    <RestoreSelectedButton 
      selected={selected} 
      onSelect={onSelect}
    />
  )

  return (
    <div className='relative'>

      <div className='flex'>
        <div>
          <div className='flex mb-0.5 gap-4'>
            <SectionTitle>
              {title}
            </SectionTitle>

            <TextSearch
              value={tableFilters.text_search || ''}
              onChange={(text_search) => {
                setFilters({ ...tableFilters, text_search });
              }}
            />
          </div>
          <SectionCaption>
            <OpportunitiesTableSummary
              opportunitiesParams={opportunitiesParams}
            />
          </SectionCaption>
          {subtitle && (
            <SectionCaption>
              <div className='my-2'>
                {subtitle}
              </div>
            </SectionCaption>
          )}
          <div className='my-2'>
            {extraHeaderActions}
          </div>
        </div>

        {!('status' in prefilter) && (
          <div className='flex items-center mb-auto ml-auto'>
            {quickFilter ?  (
              <StatusFilter 
                value={statusFilter} 
                onChange={setStatusFilter} 
                extraStatusFilters={extraStatusFilters}
              />
            ) : (
              <div className='flex w-[300px] gap-5'>
                <Select
                  size='small'
                  className='w-full'
                  options={[...OPPORTUNITY_STATUSES, 'all'].map((s) => {return {value: s, label: s.charAt(0).toUpperCase() + s.slice(1)}})}
                  value={statusFilter}
                  onChange={(value) => setStatusFilter(value)}
                />
                <Button
                  size='xs'
                  theme='black'
                  layout='inline'
                  iconRight='arrow-right'
                  onClick={() => {
                    setQuickfilter(true)
                    setStatusFilter('active')
                  }}
                >
                  Back to quick filter
                </Button>
              </div>
            )}
          </div>
        )}
      </div>

      {filterOptions.status === 'success' && 
        <DataFiltersControl
          configs={OPPORTUNITY_FILTER_CONFIGS}
          value={tableFilters}
          options={filterOptions.data}
          onChange={onFiltersChange}
        />
      }

      {selected && selected.length > 0 &&
        <div className='flex my-5 text-caption-1'>
          <SelectionSummary
            opportunities={selected}
            onReset={() => {
              onSelect([]);
            }}
          />

          <div className='flex ml-auto'>
            {overrideOpportunityActions ? (
              <div>
                {overrideOpportunityActions}
              </div>
            ) : (
              <div className='flex'>
                {extraOpportunityActionsLeft}
                {defaultOpportunityActions}
                {extraOpportunityActionsRight}
              </div>
            )}
            
          </div>
        </div>
      }

      <OpportunitiesTable
        paywalled={paywalled}
        opportunities={opportunities.status === 'success' ? opportunities.data : []}
        sort={sort}
        onSort={onSort}
        selected={selected}
        onSelect={onSelect}
        pagination={pagination}
        onPagination={onPagination}
        extraTableActions={extraTableActions}
        extraTableFooters={extraTableFooters}
      />

      <StatusOverlay status={opportunities.status} />
    </div>
  );
};
