import type { OpportunityDraft } from 'types/savings';

import { Dialog } from 'shared/Dialog';
import {
  CostImpactInput,
  DifficultySelect,
  ThreadSelect,
  AccountSelect,
  RegionSelect,
  ServiceSelect,
  DescriptionInput,
  RecommendationInput,
  OpportunityCreateSubmit,
  OpportunityUpdateSubmit,
  RecordIdInput,
  FormField
} from 'pages/savings/controls';

import { noop } from 'helpers/utils';

import {
  useSavingsStore,
  useCustomOpportunityDialog
} from 'store/savings';
import { useState } from 'react';
import { StatusOverlay } from 'shared/Overlay';

const fields: (keyof OpportunityDraft)[] = [
  'costImpact',
  'difficulty',
  'threadId',
  'recordId',
  'opportunityTypeId',
  'account',
  'service',
  'region',
  'description',
  'recommendation'
];

const useSetter = <TField extends keyof OpportunityDraft,>(id: string | number | null, field: TField) => {
  const draft = useSavingsStore((store) => id !== null && store.getOpportunityDraft(id));
  const setOpportunityDraft = useSavingsStore((store) => store.setOpportunityDraft);

  if (id === null) {
    return noop;
  }

  return (value: OpportunityDraft[TField]) => {
    setOpportunityDraft(id, { ...draft, [field]: value });
  };
}

export const CustomOpportunityDialog = () => {
  const { id, close } = useCustomOpportunityDialog();

  const draft = useSavingsStore((store) => id === null ?
    {} as OpportunityDraft :
    store.getOpportunityDraft(id)
  );

  const resetOpportunityDraft = useSavingsStore((store) => store.resetOpportunityDraft);

  const isCreating = typeof id === 'string';
  const isUpdating = typeof id === 'number';

  const [submitting, setSubmitting] = useState(false);
  const formDisabled = id === null || submitting;


  const setters = fields.reduce((acc, field) => ({
    ...acc,
    [field]: useSetter(id, field)
  }), {} as {
    [Key in keyof OpportunityDraft]-?: (value: OpportunityDraft[Key]) => void
  });

  return (
    <Dialog
      className='w-[900px]'
      open={id !== null}
      onClose={() => {
        close();
        if (id !== null) {
          resetOpportunityDraft(id);
        }
      }}
    >
      <Dialog.Title>
        {isCreating ?
          'Create New Opportunity' :
          'Edit Opportunity'
        }
      </Dialog.Title>

      <div className='overflow-x-auto px-[30px] border-t pb-[20px] relative'>
        <FormField
          label='Record ID'
          required
        >
          <RecordIdInput
            value={draft.recordId || ''}
            disabled={formDisabled || isUpdating}
            onChange={setters.recordId}
          />
        </FormField>

        <div className='grid grid-cols-6 gap-x-[20px]'>
          <FormField
            className='col-span-2'
            label='Est. Savings (daily)'
          >
            <CostImpactInput
              value={draft.costImpact || 0}
              disabled={formDisabled}
              onChange={setters.costImpact}
            />
          </FormField>

          <FormField
            label='Difficulty'
          >
            <DifficultySelect
              value={draft.difficulty || 'easy'}
              disabled={formDisabled}
              onChange={setters.difficulty}
            />
          </FormField>

          <FormField
            className='col-span-3'
            label='Thread'
          >
            <ThreadSelect
              value={draft.threadId || null}
              disabled={id === null}
              onChange={setters.threadId}
            />
          </FormField>

          <FormField
            className='col-span-2'
            label='Account'
          >
            <AccountSelect
              value={draft.account || null}
              disabled={formDisabled}
              onChange={setters.account}
            />
          </FormField>

          <FormField
            className='col-span-2'
            label='Service'
          >
            <ServiceSelect
              value={draft.service || null}
              disabled={formDisabled}
              onChange={setters.service}
            />
          </FormField>

          <FormField
            className='col-span-2'
            label='Region'
          >
            <RegionSelect
              value={draft.region || null}
              disabled={formDisabled}
              onChange={setters.region}
            />
          </FormField>
        </div>

        <FormField
          label='Description'
          required
        >
          <DescriptionInput
            value={draft.description || ''}
            disabled={formDisabled}
            onChange={setters.description}
          />
        </FormField>

        <FormField
          label='Recommendation'
        >
          <RecommendationInput
            value={draft.recommendation || ''}
            disabled={formDisabled}
            onChange={setters.recommendation}
          />
        </FormField>

        {submitting && <StatusOverlay status='loading' />}
      </div>

      <div className='flex justify-end p-[15px] border-t'>
        {isCreating ? 
          <OpportunityCreateSubmit
            opportunityKey={id}
            disabled={formDisabled}
            onSubmit={() => {
              setSubmitting(true);
            }}
            onSuccess={() => {
              setSubmitting(false);
              close();
            }}
            onError={() => {
              setSubmitting(false);
            }}
          /> :
        isUpdating ?
          <OpportunityUpdateSubmit
            opportunityKey={id}
            disabled={formDisabled}
            onSubmit={() => {
              setSubmitting(true);
            }}
            onSuccess={() => {
              setSubmitting(false);
              close();
            }}
            onError={() => {
              setSubmitting(false);
            }}
          /> :
          null
        }
      </div>
    </Dialog>
  );
}
