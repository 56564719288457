import { createKeyedStore } from './createKeyedStore';
import { createKeyedStoreSelector } from './createKeyedStoreSelector';

import { fetchCostViewMoversAndShakers } from 'services/costViews';
import { moversAndShakersFetchParamsToKey } from 'helpers/storeKeys/moversAndShakers';

export const useMoversAndShakersStore = createKeyedStore({
  fetchData: fetchCostViewMoversAndShakers,
  paramsToKey: moversAndShakersFetchParamsToKey
});

export const useMoversAndShakers = createKeyedStoreSelector(useMoversAndShakersStore);
