import type {
  CostViewForecastKey,
  CostViewForecastKeyProps,
  CostViewForecastKeyFactory,
  CostViewForecastFetchParams
} from 'types/costViews';

import {
  Set as ImmutableSet,
  Record as ImmutableRecord
} from 'immutable';

import {
  defaultCostFilterKeyProps,
  defaultDateFilterKeyProps
} from './defaults';

import { toImmutableFilter } from 'helpers/filters';

const createCostViewForecastKey: CostViewForecastKeyFactory = ImmutableRecord<CostViewForecastKeyProps>({
  ...defaultCostFilterKeyProps,
  ...defaultDateFilterKeyProps,
  forecastStartDate: '',
  forecastEndDate: '',
  teamId: undefined,
  globalFilterIds: undefined,
  filter: null,
});

export const costViewForecastFetchParamsToKey = (params: CostViewForecastFetchParams): CostViewForecastKey => {
  const costDimensions = ImmutableSet(params.costDimensions);
  const globalFilterIds = ImmutableSet(params.globalFilterIds);
  const filter = toImmutableFilter(params.filter);

  return createCostViewForecastKey({
    ...params,
    costDimensions,
    globalFilterIds,
    filter
  });
};
