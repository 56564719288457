import type { ThreadStatus } from 'types/savings';

export const THREAD_STATUS_COLORS: Record<ThreadStatus, string> = {
  open: '#CFE1EE',
  validation: '#F2D8C2',
  implementation: '#F2EDC2',
  done: '#D4E8D1',
  rejected: '#F5D9D3',
  deleted: '#F5D9D3'
}

export const THREAD_STATUS_TEXTS: Record<ThreadStatus, string> = {
  open: 'Open',
  validation: 'Validation',
  implementation: 'Implementation',
  done: 'Done',
  rejected: 'Rejected',
  deleted: 'Deleted'
}

export const THREAD_STATUS_ORDER: Record<ThreadStatus, number> = {
  open: 0,
  validation: 1,
  implementation: 2,
  done: 3,
  rejected: 4,
  deleted: 5
};
