import type { DataEntry } from 'types/dataEntries';
import type {
  KeyedStore,
  KeyedStoreState
} from 'types/stores';

import create from 'zustand';
import { Map as ImmutableMap } from 'immutable';
import { isDataEntry } from 'types/dataEntries';

export type Chunk = {
  id: number
}

interface CreateKeyedStoreParamsChunk<TParams, TKey, TData> {
  paramsToKey: (params: TParams) => TKey;
  fetchData: (params: TParams) => Promise<{ data: TData }[]>;
}

export const createKeyedStoreChunk = <TParams, TKey, TData>(params: CreateKeyedStoreParamsChunk<TParams, TKey, TData>): KeyedStore<TParams, TKey, TData[]> => {
  const {
    paramsToKey,
    fetchData,
  } = params;

  return create<KeyedStoreState<TParams, TKey, TData[]>>((set, get) => ({
    entries: ImmutableMap(),
    getEntry: (params: TParams | DataEntry<TParams>) => {
      if (isDataEntry(params)) {
        if (params.status !== 'success') {
          return params;
        }

        params = params.data;
      }

      let { entries } = get();
      const key = paramsToKey(params);

      let entry = entries.get(key);

      if (!entry) {
        entry = { status: 'loading' };
        entries = entries.set(key, entry);
        set({ entries });

        fetchData(params)
          .then((data) => {
            let { entries } = get();

            entries = entries.set(key, { data: data.map(({ data }) => data), status: 'success' });
            set({ entries });
          })
          .catch((error) => {
            let { entries } = get();
              
            entries = entries.set(key, { error, status: 'error' });
            set({ entries });
          });
      }

      return entry;
    }
  }));
};
