import type { Budget } from 'types/costViews/budgets';

import api from 'helpers/api';

export const createBudget = (params: Budget, costViewId: number) => {
  const { 
    id,
    ...payload
  } = params;

 return api.post<Budget>('/budgets', { ...payload, cost_metric_filter_id: costViewId });
}

export const updateBudget = (params: Budget) => {
  const { 
    id,
    ...payload
  } = params;

  return api.patch<Budget>(`/budgets/${id}`, payload);
};

export const deleteBudget = (id: number) => api.delete(`/budgets/${id}`);
