import { createKeyedStore } from 'store/createKeyedStore';
import { fetchCostViewDrillDown } from 'services/costViews';
import { drillDownFetchParamsToKey } from 'helpers/storeKeys/drillDown';
import { createKeyedStoreSelector } from 'store/createKeyedStoreSelector';


export const useTotalSpendSummaryStore = createKeyedStore({
  paramsToKey: drillDownFetchParamsToKey,
  fetchData: fetchCostViewDrillDown
});

export const useTotalSpendSummary = createKeyedStoreSelector(useTotalSpendSummaryStore);
