import { useEffect, useState, useMemo } from 'react'
import type { CostColumn } from 'types/costColumns';
import { Link } from 'react-router-dom'
import { Section } from 'shared/Section';
import { Table, message } from 'antd'
import { Icon } from 'shared/Icon/Icon';
import { Loading } from 'shared/Loading/Loading';
import { DeleteColumnDialog } from './DeleteColumnDialog';
import Button from 'shared/Button/Button';
import IconButton from 'shared/Button/IconButton';
import { useCostColumnStore } from 'store/costColumns';
import { useGlobalState } from 'state/globalState'
import { createNullableStringsComparator, createNullableNumbersComparator } from 'helpers/sort';
import { formatNormal } from 'helpers/formatter'
import moment from 'moment';
import CreateDialog from './CreateDialog'
import { COST_COLUMNS } from './CreateDialog'

export interface LibraryProps {}

const ColumnsLibrary = (props: LibraryProps) => {
  const { 
    status: configStatus,
    costColumns,
    fetchCostColumns,
    deleteCostColumn,
  } = useCostColumnStore();
  const { user } = useGlobalState();
  const [searchText, setSearchText] = useState('');
  const [columnToDelete, setColumnToDelete] = useState<CostColumn | null>(null);
  const [columnToEdit, setColumnToEdit] = useState<CostColumn | null>(null);
  const [createDialogOpened, setCreateDialogOpened] = useState(false);

  useEffect(() => {
    fetchCostColumns();
  }, [])

  const handleEditColumn = (column: CostColumn) => {
    setColumnToEdit(column)
    setCreateDialogOpened(true)
  }

  const filteredColumns = useMemo(() => {
    return searchText ?
      costColumns.filter((column) => column.name.toLowerCase().includes(searchText.toLowerCase())) :
      costColumns; 
  }, [costColumns, searchText]);

  return (
    <div className='pt-[35px]'>
      <Section className='p-[20px]'>
        <div className='flex mb-[20px]'>
          <div className='flex items-center grow pl-[11px]'>
            <Icon icon='search' />
            <input
              type='text'
              className='w-full leading-[20px] text-[14px] my-[-5px] py-[10px] pl-[40px] ml-[-29px] bg-transparent outline-none border-b border-b-transparent focus:border-b-cyanine-blue'
              value={searchText} 
              placeholder='Search Column Name'
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
            />
          </div>
          <Button
            size='sm'
            type='primary'
            icon='circle-plus'
            style='caladon'
            label='Create New Column'
            disabled={user?.org_is_data_ready ? false : true}
            onClick={() => setCreateDialogOpened(true)}
          />
        </div>
        <Table
          rowKey={({ id }) => id}
          size='small'
          rowClassName='text-[12px] line-[14px]'
          onHeaderRow={() => ({ className: 'text-[12px] line-[14px]' })}
          dataSource={filteredColumns}
          columns={[
            {
              key: 'name',
              title: 'Name',
              dataIndex: 'name',
              sorter: (a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0,
              render: (name, column) => <a className='truncate w-[200px]'>{name}</a>
            },
            {
              key: 'definition',
              title: 'Definition',
              dataIndex: 'definition',
              render: (_, column) => (
                <div className='truncate'>
                  <div>{Object.keys(column.definition).map((key) => `(${column.definition[key]} * ${COST_COLUMNS[key]})`).join(' + ')}</div>
                </div>
              )  
            },
            {
              key: 'edit',
              width: 32,
              render: (_, column) => <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='edit' onClick={() => handleEditColumn(column)} />
            },     
            {
              key: 'delete',
              width: 32,
              render: (_, column) => <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='trash' onClick={() => setColumnToDelete(column)} />
            },
          ]}
        />
        {configStatus === 'loading' && <Loading />}
        <CreateDialog
          opened={createDialogOpened}
          onClose={(search) => {
            setCreateDialogOpened(false);
            setColumnToEdit(null);
            if (search) {
              setSearchText(search)
            }
          }}
          columnToEdit={columnToEdit}
        />
        <DeleteColumnDialog
          open={!!columnToDelete}
          name={columnToDelete?.name || ''}
          onClose={() => setColumnToDelete(null)}
          onConfirm={async () => {
            if (!columnToDelete) {
              return;
            }

            try {
              await deleteCostColumn(columnToDelete.id);
              setColumnToDelete(null);
              message.success(`Column '${columnToDelete.name}' deleted`);
            } catch {
              message.error(`Unable to delete '${columnToDelete.name}'`);
            }
          }}
        />
      </Section>
    </div>
  )
}

export default ColumnsLibrary
