import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { message } from 'antd'
import { Header, BtnSocialAuth, InputField } from 'components/Auth'
import { validateEmail } from 'helpers/validator'
import { ReactComponent as Checkmark } from 'assets/images/Checkmark.svg'
import { API_HOST } from 'helpers/api'
import { useGlobalState } from 'state/globalState'
import apiService from 'helpers/api'
import GoogleIcon from 'assets/images/Google.png'
import GithubIcon from 'assets/images/Github.png'

const SignUp = () => {
  const { init, setUserEmail } = useGlobalState()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [fullName, setFullName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isAgreedTerms, setIsAgreedTerms] = useState(false)
  const [onceSubmitted, setOnceSubmitted] = useState(false)
  const [fullNameError, setFullNameError] = useState('')
  const [companyNameError, setCompanyNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const isDisabledSubmit = !fullName || !companyName || !email || !password || !isAgreedTerms || submitting;

  const handleGoogleSignUp = () => {
    window.location.href = `${API_HOST}/signin/google`
  }

  const handleGithubSignUp = () => {
    window.location.href = `${API_HOST}/signin/github`
  }

  const handlePrivacy = () => {
    window.open('https://www.cloudthread.io/privacy-policy', '_blank')
  }

  const handleTerms = () => {
    window.open('https://www.cloudthread.io/terms-of-use', '_blank')
  }

  const handleChangeFullName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value)
    if (e.target.value.length === 0 && onceSubmitted) {
      setFullNameError('Required Field')
    } else {
      setFullNameError('')
    }
  }

  const handleChangeCompanyName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value)
    if (e.target.value.length === 0 && onceSubmitted) {
      setCompanyNameError('Required Field')
    } else {
      setCompanyNameError('')
    }
  }

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    if (e.target.value.length === 0 && onceSubmitted) {
      setEmailError('Required Field')
    } else {
      setEmailError('')
    }
  }

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    if (e.target.value.length === 0 && onceSubmitted) {
      setPasswordError('Required Field')
    } else {
      setPasswordError('')
    }
  }

  const toggleAgreeTerms = () => {
    setIsAgreedTerms(!isAgreedTerms)
  }

  const handleCreateAccount = async () => {
    if (isDisabledSubmit) return
    if (!onceSubmitted) {
      setOnceSubmitted(true)
    }

    if (!validateEmail(email)) {
      setEmailError('Invalid email format')
      return
    }

    if (password.length <= 7) {
      setPasswordError('Password should be more than 7 characters long')
      return
    }

    if (password.length > 20) {
      setPasswordError('Password should be less than 20 characters long')
      return
    }

    const regularExpression = /^(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@\^_`{|}~])[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@\^_`{|}~]{8,20}$/;
    if (!regularExpression.test(password)) {
      setPasswordError('Password should contain at least one number and one special character')
      return
    }

    try {
      setSubmitting(true);
      await apiService.post('/signup', {
        email,
        password,
        name: fullName,
        company_name: companyName,
      })

      setUserEmail(email)
      message.success('Sign up successful')
      navigate('/sign-up/confirm-email')
    } catch (err: any) {
      setSubmitting(false);
      message.error(err.response.data.message)
    }
  }

  const validateCodeFromSocial = async () => {
    const code = searchParams.get('code')
    const scope = searchParams.get('scope')
    if (!code) return

    try {
      const apiUrl = scope ? `/signin/google/callback?code=${code}` : `/signin/github/callback?code=${code}`
      await apiService.get(apiUrl)
      await init()

      message.success('Sign up successful')
      navigate('/')
    } catch (err: any) {
      message.error(err.response.data.message)
    }
  }

  useEffect(() => {
    validateCodeFromSocial()
  }, [])

  return (
    <div className="w-full min-h-screen bg-white md:bg-silver-grey pb-[100px]">
      <Header type="signup" />
      <div className="mt-[30px] w-full md:w-[560px] mx-auto bg-white md:rounded-[20px] p-[50px] md:shadow-light-shadow">
        <h1 className="font-semibold text-[24px] leading-[34px] color-oxford-blue mb-[30px]">
          Create your Cloudthread account
        </h1>
        <BtnSocialAuth classes="mb-[10px]" icon={GoogleIcon} text="Sign up with Google" onClick={handleGoogleSignUp} />
        <BtnSocialAuth classes="mb-[30px]" icon={GithubIcon} text="Sign up with Github" onClick={handleGithubSignUp} />
        <div className="flex items-center justify-between">
          <div className="grow h-[1px] bg-silver-grey-600" />
          <span className="grow-0 mx-[15px] uppercase text-silver-grey-800 text-[12px] leading-[16px] tracking-[1px]">
            Or Sign up with email
          </span>
          <div className="grow h-[1px] bg-silver-grey-600" />
        </div>
        <InputField label="Full name" value={fullName} error={fullNameError} onChange={handleChangeFullName} />
        <InputField label="Company name" value={companyName} error={companyNameError} onChange={handleChangeCompanyName} />
        <InputField label="Email" value={email} error={emailError} onChange={handleChangeEmail} />
        <InputField type="password" placeholder="More than 7 characters" label="Password" value={password} error={passwordError} onChange={handleChangePassword} />
        <div className="flex items-center mt-[36px]">
          <button
            type="button"
            className={`flex justify-center items-center w-[18px] h-[18px] p-[4.5px] ml-[6px] border-[1.2px] border-silver-grey-500 rounded-[5px] ${isAgreedTerms && 'bg-cyanine-blue border-none'}`}
            onClick={toggleAgreeTerms}
          >
            {isAgreedTerms && <Checkmark className="w-full" />}
          </button>
          <span className="text-[12px] leading-[16px] text-oxford-blue ml-[8px]">
            I agree to Cloudthread&apos;s <a onClick={handlePrivacy}>Privacy Policy</a> and <a onClick={handleTerms}>Terms of Use</a>
          </span>
        </div>
        <button
          type="button"
          disabled={true}
          className="w-full h-[46px] mt-[30px] bg-caladon-blue rounded-[10px] font-medium text-[15px] leading-[22px] tracking-[-0.24px] text-white disabled:opacity-25"
          onClick={handleCreateAccount}
        >
          Create an Account
        </button>
        <div className="flex justify-center font-medium text-[11px] leading-[13px] tracking-[0.07px] mt-[11.5px]">
          <span className="text-oxford-blue">
            Have an account?&nbsp;
          </span>
          <Link className="text-caladon-blue hover:text-caladon-blue" to="/login">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SignUp
