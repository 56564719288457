import { Fragment, ReactNode } from 'react';
import {
  ConstructorRule,
  ConstructorRules,
  FilterGroupCondition,
  FilterValueCondition,
  FilterNestedCondition,
  FilterNestedConditionItem,
  isRootRule,
  isChildRule,
  isSingleRule,
  isStringRule,
  isDictRule,
  isFilterValueCondition,
  isFilterNestedCondition
} from 'types/filters';

import { useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { Menu } from '@headlessui/react';
import { Icon } from 'shared/Icon';
import { Button }from 'shared/Button';
import { Portal } from 'shared/Portal';
import { getIcon, getTitlePlural } from './constants';

export interface AddRuleMenuProps<TValue extends FilterGroupCondition = FilterGroupCondition> {
  value: TValue;
  rules: ConstructorRules;
  onChange: (value: TValue) => void;
  onRuleAdd?: (rule: ConstructorRule) => void;
  removeTeams?: boolean;
}

const noop = () => {};

export const AddRuleMenu = <TValue extends FilterGroupCondition = FilterGroupCondition>(props: AddRuleMenuProps<TValue>) => {
  const {
    value,
    rules,
    onChange,
    onRuleAdd = noop,
    removeTeams = true,
  } = props;

  const [button, setButton] = useState<HTMLElement | null>();
  const [list, setList] = useState<HTMLElement | null>();
  const { styles, attributes } = usePopper(button, list);

  const handleAddRuleClick = (rule: ConstructorRule) => {
    let newCondition: FilterValueCondition | FilterNestedCondition | undefined;

    if (isRootRule(rule)) {
      newCondition = {
        operator: 'or',
        condition: [
          {
            operator: 'and',
            condition: [
              {
                field_name: rule.name,
                comparator: 'equals',
                value_type: 'string',
                values: ['']
              }
            ]
          }
        ]
      };
    } else if (isSingleRule(rule)) {
      if (isStringRule(rule)) {
        newCondition = {
          field_name: rule.name,
          comparator: 'equals',
          value_type: 'string',
          values: ['']
        };
      } else if (isDictRule(rule)) {
        newCondition = {
          field_name: rule.name,
          comparator: 'equals',
          value_type: 'dict',
          values: [{}]
        }
      }
    }

    if (newCondition) {
      onChange({
        ...value,
        condition: [...value.condition, newCondition]
      });

      onRuleAdd(rule);
    }
  };

  const availableRules = useMemo(() => {
    const valueSet = new Set([
      ...value.condition
        .filter(isFilterValueCondition)
        .map((condition) => condition.field_name),
      ...value.condition
        .filter(isFilterNestedCondition)
        .map((condition) => (condition.condition[0] as FilterNestedConditionItem).condition[0].field_name)
    ]);

    return Object.entries(rules)
      .map(([_, rule]) => rule)
      .filter((rule) => !isChildRule(rule) && !valueSet.has(rule.name));
  }, [value, rules]);

  return (
    <Menu>
      <Menu.Button as='div' className='mt-[10px]'>
        <Button  ref={setButton} label='Add Filter Rule' size='sm' style='caladon' icon='plus' type='tertiary' />
      </Menu.Button>
      <Portal>
        <Menu.Items
          ref={setList}
          style={styles.popper}
          className='absolute z-10 py-[10px] border border-grey-transparent bg-white rounded outline-0'
          {...attributes.popper}
        >
          {availableRules.filter((rule) => removeTeams ? !rule.teamsOnly : true).map((rule) => (
            <Menu.Item key={rule.name}>
              {({ active }) => (
                <button
                  className={'block w-full text-left cursor-pointer whitespace-nowrap ' + (active ? 'bg-cyanine-blue-20%' : '')}
                  onClick={() => {
                    handleAddRuleClick(rule)
                  }}
                >
                  <div className='flex px-[10px] items-center h-[30px]'>
                    <Icon className='mr-[8px]' icon={getIcon(rule.name)} />
                    {getTitlePlural(rule.name)}
                  </div>
                  {isRootRule(rule) && (
                    <div className='text-[10px] pb-2 px-[8px]'>
                      {rule.children.map((childRuleName) => {
                        const result: ReactNode[] = [];
                        
                        let childRule: ConstructorRule | undefined = rules[childRuleName];
                        
                        while (childRule) {
                          result.push(<span key={childRule.name} className='mx-1'>{getTitlePlural(childRule.name)}</span>);

                          childRule = childRule.children && rules[childRule.children[0]];
                          
                          if (childRule) {
                            //result.push('/');
                          }
                        }

                        return <div>
                          {result}
                        </div>;
                      })}
                    </div>
                  )}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Portal>
    </Menu>
  );
};
