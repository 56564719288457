import { Checkbox, message } from 'antd';
import { Button2 as Button } from 'shared/Button';
import { SectionTitle } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { useGlobalState } from 'state/globalState';
import { useOrgSettingsStore } from 'store/orgs';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';

const exclusivityToBool = (val: boolean | undefined) => !!val;
const assignmentToBool = (val: 'member'[] | undefined) => !!(val && val.length === 1 && val[0] === 'member');

export const OrgTeamSettings = () => {
  const user = useGlobalState((state) => state.user);

  const orgId = user?.org_id as number;
  const oldOrgSettings = useOrgSettingsStore((store) => store.getEntry(orgId));
  const newOrgSettings = useOrgSettingsStore((store) => store.getDraft(orgId));
  const setOrgSettings = useOrgSettingsStore((store) => store.setDraft);
  const updateOrgSettings = useOrgSettingsStore((store) => store.updateEntry);
  const updateAction = useOrgSettingsStore((store) => store.actions[orgId] || null);

  const settings = useDerivedDataEntry(
    { oldOrgSettings, newOrgSettings, updateAction },
    ({ oldOrgSettings, newOrgSettings, updateAction }) => {
      const submitting = updateAction && updateAction.status === 'in-progress';

      const exclusivity_aws = {
        disabled: submitting,
        checked: exclusivityToBool(newOrgSettings.teams_mutually_exclusive_aws),
        onChange: (event: { target: { checked: boolean }}) => {
          setOrgSettings(orgId, {
            ...newOrgSettings,
            teams_mutually_exclusive_aws: event.target.checked
          });
        }
      };

      const exclusivity_gcp = {
        disabled: submitting,
        checked: exclusivityToBool(newOrgSettings.teams_mutually_exclusive_gcp),
        onChange: (event: { target: { checked: boolean }}) => {
          setOrgSettings(orgId, {
            ...newOrgSettings,
            teams_mutually_exclusive_gcp: event.target.checked
          });
        }
      };

      const exclusivity_custom_data = {
        disabled: submitting,
        checked: exclusivityToBool(newOrgSettings.teams_mutually_exclusive_custom_data),
        onChange: (event: { target: { checked: boolean }}) => {
          setOrgSettings(orgId, {
            ...newOrgSettings,
            teams_mutually_exclusive_custom_data: event.target.checked
          });
        }
      };

      const assignment = {
        disabled: submitting,
        checked: assignmentToBool(newOrgSettings.enforce_team_assignment),
        onChange: (event: { target: { checked: boolean }}) => {
          setOrgSettings(orgId, {
            ...newOrgSettings,
            enforce_team_assignment: event.target.checked ? ['member'] : []
          });
        }
      };

      const submit = {
        children: submitting ? 'Saving' : 'Save',
        disabled:
          submitting ||
          exclusivity_aws.checked === exclusivityToBool(oldOrgSettings.teams_mutually_exclusive_aws) &&
          exclusivity_gcp.checked === exclusivityToBool(oldOrgSettings.teams_mutually_exclusive_gcp) &&
          exclusivity_custom_data.checked === exclusivityToBool(oldOrgSettings.teams_mutually_exclusive_custom_data) &&
          oldOrgSettings.rate_optimization_allowed === newOrgSettings.rate_optimization_allowed &&
          assignment.checked === assignmentToBool(oldOrgSettings.enforce_team_assignment),
        onClick: () => {
          updateOrgSettings(orgId)
            .then(() => {
              message.success('Organization team settings updated');
            })
            .catch(() => {
              message.error('Cannot update organization team settings');
            });
        }
      };

      return {
        exclusivity_aws,
        exclusivity_gcp,
        exclusivity_custom_data,
        assignment,
        submit
      };
    }
  );

  const {
    exclusivity_aws,
    exclusivity_gcp,
    exclusivity_custom_data,
    assignment,
    submit
  } = settings.status === 'success' ? settings.data : {
    exclusivity_aws: { disabled: true, checked: false },
    exclusivity_gcp: { disabled: true, checked: false },
    exclusivity_custom_data: { disabled: true, checked: false },
    assignment: { disabled: true, checked: false },
    submit: { disabled: true, label: 'Save' }
  };


  return (
    <div className='relative mb-10'>
      <SectionTitle className='mb-4'>
        Team Settings
      </SectionTitle>

      <div className='mb-3'>
        <div>
          <Checkbox {...exclusivity_aws}>
            Mutually exclusive AWS dimenions
          </Checkbox>
        </div>
        <div>
          <Checkbox {...exclusivity_gcp}>
            Mutually exclusive GCP dimenions
          </Checkbox>
        </div>
        <div>
          <Checkbox {...exclusivity_custom_data}>
            Mutually exclusive Custom Data dimenions
          </Checkbox>
        </div>

        <p className='text-gray-400 text-caption-1 ml-[24px]'>
          Enabling mutual exclusivity won't affect currently existing teams 
        </p>
      </div>

      <div>
        <Checkbox {...assignment}>
          Enforce team assignment for members
        </Checkbox>
      </div>

      <Button
        className='mt-5'
        size='m'
        {...submit}
      />

      <StatusOverlay status={newOrgSettings.status} />
    </div>
  );
}
