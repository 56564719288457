import { Section } from 'shared/Section';
import SplashPage from 'shared/Page/SplashPage'
import ProviderSelection from '../Settings/PullDataCollectionTab/ProviderSelection';
import { useGlobalState } from 'state/globalState'


export const ConnectCfnStack = () => {
  const logout = useGlobalState((store) => store.logout);

  return (
    <SplashPage>
      <Section className="mx-auto p-[40px]">
        <div className="text-xl mb-5 font-semibold ">Get Connected</div>
        <p className='font-semibold opacity-60 mb-2'>Select the cloud computing provider you want to integrate with Cloudthread to get started.</p> 
        <p className='font-semibold opacity-60 mb-2'>If you use multiple cloud computing providers, you will be able to do the integration from Cloudthread Settings in the future.</p> 
        <Section className='mx-auto p-[30px]'>
          <ProviderSelection onboarding={true}/>
        </Section>
        <div className='flex justify-end mt-5 cursor-pointer w-1/1 mr-1' onClick={logout}>Logout</div>
      </Section>
    </SplashPage>
  )
}
