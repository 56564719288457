import type { ReactNode } from 'react';

export interface FormFieldProps {
  className?: string;
  label?: ReactNode;
  required?: boolean;
  children: ReactNode
}

export const FormField = (props: FormFieldProps) => {
  const {
    className = '',
    label,
    required,
    children
  } = props;

  return (
    <div className={`mt-5 ${className}`}>
      {label && 
        <label className='block mb-1 font-medium'>
          {label}
          {' '}
          {required && <b className='text-red'>*</b>}
        </label>
      }
      {children}
    </div>
  );
}
