import moment from 'moment';
import { useMemo } from 'react';
import { Section } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { useCostImpactSummary } from 'store/savings';
import { useSavedAndWastedSummary } from 'store/savings';
import { ACTIVE_OPPORTUNITY_STATUSES } from 'helpers/savings';
import { formatCurrency } from 'helpers/formatter';
import { usePageFilters } from '../hooks/pageFilters';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { identity } from 'helpers/utils';

export interface SavingPotentialSectionProps {
  className?: string;
}

export const SavingPotentialSection = (props: SavingPotentialSectionProps) => {
  const {
    className = ''
  } = props;

  const today = useMemo(() => moment().subtract(1, 'day').startOf('day'), []);
  const thisMonthStart = useMemo(() => moment().startOf('month'), []);
  const thisMonthEnd = useMemo(() => moment().endOf('month'), []);
  const lastMonthStart = useMemo(() => thisMonthStart.clone().subtract(2, 'day').startOf('month'), []);
  const lastMonthEnd = useMemo(() => lastMonthStart.clone().endOf('month'), []);

  const daysLeft = useMemo(() => thisMonthEnd.diff(today, 'day'), [today, thisMonthEnd]);

  const pageFilters = usePageFilters();

  const costImpactParams = useDerivedDataEntry({
    prefilter: {
      status: ACTIVE_OPPORTUNITY_STATUSES,
    },
    pageFilters,
    tableFilters: {},
  }, identity);

  const costImpact = useCostImpactSummary(costImpactParams);

  const thisMonthParams = useDerivedDataEntry({
    startDate: thisMonthStart.format('YYYY-MM-DD'),
    endDate: today.format('YYYY-MM-DD'),
    prefilter: {},
    pageFilters,
    tableFilters: {},
  }, identity);

  const thisMonth = useSavedAndWastedSummary(thisMonthParams);

  const lastMonthParams = useDerivedDataEntry({
    startDate: lastMonthStart.format('YYYY-MM-DD'),
    endDate: lastMonthEnd.format('YYYY-MM-DD'),
    prefilter: {},
    pageFilters,
    tableFilters: {},
  }, identity);

  const lastMonth = useSavedAndWastedSummary(lastMonthParams);

  const summary = useDerivedDataEntry({
    costImpact,
    thisMonth,
    lastMonth
  }, ({
    costImpact,
    thisMonth,
    lastMonth
  }) => ({
    costImpact: costImpact.length > 0 ? costImpact[0] : null,
    thisMonth: thisMonth.length > 0 ? thisMonth[0] : null,
    lastMonth: lastMonth.length > 0 ? lastMonth[0] : null,
  }));

  return (
    <Section className={`p-5 relative ${className}`}>
      <h2 className='font-medium'>
        Saving Potential
        <span className='ml-2 font-normal text-caption-1'>
          This Month
        </span>
      </h2>

      {
        summary.status === 'success' && 
        summary.data.costImpact &&
        summary.data.thisMonth &&
        summary.data.lastMonth && (
        <div>
          <div className='text-large-title my-2.5 font-medium'>
            {formatCurrency(summary.data.costImpact.cost_impact_sum * daysLeft)}
          </div>

          <div className='text-caption-1'>
            This month:
            {' '}

            <span className={`inline-flex items-center rounded-1 px-1 bg-green/20`}>
              <span className={`rounded-0.5 inline-block w-2 h-2 mr-1 bg-green`} />
              {formatCurrency(summary.data.thisMonth.saved)}
            </span>

            {' '}
            saved
            {' / '}
            <span className={`inline-flex items-center rounded-1 px-1 bg-yellow/20`}>
              <span className={`rounded-0.5 inline-block w-2 h-2 mr-1 bg-yellow`} />
              {formatCurrency(summary.data.thisMonth.wasted)}
            </span>
            {' '}
            wasted
          </div>

          <div className='mt-1 -mb-1.5 text-caption-2'>
            Last month:
            {' '}

            <span className={`inline-flex items-center rounded-1 px-1 bg-green/20`}>
              <span className={`rounded-0.5 inline-block w-2 h-2 mr-1 bg-green`} />
              {formatCurrency(summary.data.lastMonth.saved)}
            </span>

            {' '}
            saved
            {' / '}

            <span className={`inline-flex items-center rounded-1 px-1 bg-yellow/20`}>
              <span className={`rounded-0.5 inline-block w-2 h-2 mr-1 bg-yellow`} />
              {formatCurrency(summary.data.lastMonth.wasted)}
            </span>
            {' '}
            wasted
          </div>
        </div>
      )}

      <StatusOverlay status={summary.status} />
    </Section>
  );
}
