import { message } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button } from 'shared/Button';
import {
  useDashboardsStore,
  useDashboardDeleteDialogStore
} from 'store/dashboards';

export interface DeleteDialogProps {
  onConfirm?: () => void;
}

export const DeleteDialog = (props: DeleteDialogProps) => {
  const { onConfirm } = props;
  const { id, close } = useDashboardDeleteDialogStore();

  const dashboard = useDashboardsStore((state) => id !== null && state.getDashboardEntry(id));
  const deleteDashboard = useDashboardsStore((state) => state.deleteDashboard);

  const name = dashboard && dashboard.status === 'success' && dashboard.data.name;

  return (
    <Dialog
      className='w-[420px] pt-[40px] px-[30px] pb-[20px]'
      open={id !== null}
      onClose={close}
    >
      <div className='font-semibold text-center text-subtitle-1'>
        Are you sure you want to delete dashboard '{name}' permanently?
      </div>

      <Button
        className='justify-center mt-[30px] bg-negative'
        style='caladon'
        label={`Yes, delete '${name}'`}
        onClick={() => {
          onConfirm && onConfirm();
          close();
          deleteDashboard(id as number)
            .then(() => {
              message.success(`Dashboard ${name} deleted`);
            })
            .catch(() => {
              message.error(`Unable to delete dashboard ${name}`);
            })
        }}
      />

      <Button
        className='justify-center mt-[20px]'
        type='tertiary'
        label='Cancel'
        onClick={close}
      />
    </Dialog>
  )
}
