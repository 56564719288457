import Dialog from 'shared/Dialog/Dialog'
import Button from 'shared/Button/Button'

export interface DeleteDialogProps {
  opened: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
export const DeleteDialog = (props: DeleteDialogProps) => {
  const { opened, onClose, onConfirm } = props;

  return (
    <Dialog className="w-[420px] pt-[40px] px-[30px] pb-[20px]" open={opened} onClose={onClose}>
      <div className="font-semibold text-center text-subtitle-1">Are you sure you want to delete this unit metric permanently?</div>
      <Button className="justify-center mt-[30px] bg-negative" style="caladon" label="Yes, delete this metric" onClick={onConfirm} />
      <Button className="justify-center mt-[20px]" type="tertiary" label="Cancel" onClick={onClose} />
    </Dialog>
  )
};
