import { Button2 as Button } from 'shared/Button';

export interface FilterButtonProps {
  checked: boolean;
  onClick: () => void;
}

export const FilterButton = (props: FilterButtonProps) => {
  const {
    checked,
    onClick
  } = props;

  return (
    //<div className={`ml-[6px] border-[4px] border-transparent ${checked ? 'rounded-[11px] border-cyanine-blue-20%' : 'border-transparent'}`}>
        <Button
          //className={`border ${checked ? 'border-cyanine-blue bg-cyanine-blue-5%' : 'border-transparent'}`}
          iconLeft={checked ? 'close' : 'bars-filter'}
          size='m'
          theme='black'
          layout='inline'
          onClick={onClick}
        >
          Filters
        </Button>
    // </div>
    )
}
