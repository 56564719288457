import {message} from 'antd';
import { Button2 as Button } from 'shared/Button';
import { useUsersStore } from 'store/users';

export interface RecoverButtonProps {
  id: number;
}

export const RecoverButton = (props: RecoverButtonProps) => {
  const {
    id
  } = props;

  const recoverUser = useUsersStore((store) => store.recoverEntry);
  const action = useUsersStore((store) => store.getAction(id));
  const user = useUsersStore((store) => store.getEntry(id));

  return <Button
    className='hover:!text-green !flex'
    icon='undo'
    size='s'
    theme='black'
    layout='inline'
    disabled={!!action}
    onClick={() => {
      recoverUser(id)
        .then(() => {
          message.success(<>
            User {user.status === 'success' && <b>{user.data.name} ({user.data.email})</b>} recovered
          </>);
        })
        .catch(() => {
          message.error(<>
            Unable to recover user {user.status === 'success' && <b>{user.data.name} ({user.data.email})</b>}
          </>);
        });
    }}
  >
    Recover User
  </Button>;
}
