import type { RuleValueCondition } from 'types/rules';
import type { CostViewFilter } from 'types/costViews';

import { formatCurrency, formatPercent } from 'helpers/formatter';
import { useDatesQuery } from 'hooks/useDatesQuery';
import { useCostFilterValue } from 'hooks/costFilter';
import { useCostViewChart } from 'store/costViewChart';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { cleanupCostViewFilter } from 'helpers/costViews';

export interface NodeSpendProps {
  src: string;
  condition?: RuleValueCondition[];
}

export const NodeSpend = (props: NodeSpendProps) => {
  const {
    src,
    condition = []
  } = props;

  const filter: CostViewFilter = cleanupCostViewFilter({
    src,
    filter: {
      operator: 'or',
      condition: [{
        operator: 'and',
        condition
      }]
    }
  });

  const parentFilter: CostViewFilter = cleanupCostViewFilter({
    src,
    filter: {
      operator: 'or',
      condition: [{
        operator: 'and',
        condition: condition.slice(0, -1)
      }]
    }
  });

  const { startDate, endDate } = useDatesQuery({ defaultGranularity: 'month', defaultLength: 0 });
  const costFilter = useCostFilterValue();

  const params = {
    granularity: 'month' as 'month',
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
    ...costFilter
  };

  const chart = useCostViewChart({
    ...params,
    filter
  });

  const parentChart = useCostViewChart({
    ...params,
    filter: parentFilter
  });

  const totalChart = useCostViewChart({
    ...params,
    filter: { src: 'cur', filter: { operator: 'or', condition: [] }}
  });

  const costs = useDerivedDataEntry({
    chart,
    parentChart,
    totalChart
  }, ({
    chart,
    parentChart,
    totalChart
  }) => {
    return {
      cost: chart.reduce((sum, item) => sum + (item.current_timeframe_cost || 0), 0),
      parentCost: parentChart.reduce((sum, item) => sum + (item.current_timeframe_cost || 0), 0),
      totalCost: totalChart.reduce((sum, item) => sum + (item.current_timeframe_cost || 0), 0)
    };
  });

  if (costs.status !== 'success') {
    return null;
  }

  const {
    cost,
    parentCost,
    totalCost
  } = costs.data;

  return (
    <div className='absolute border rounded top-[-10px] right-[10px] py-[2px] px-[4px] text-white bg-zinc-600 text-[11px] drop-shadow-sm'>
      {formatCurrency(cost)}
      {' ('}
      {parentCost === totalCost ? '' : `${formatPercent(cost / parentCost)} parent / `}
      {formatPercent(cost / totalCost)}
      {' total)'}
    </div>
  );
};
