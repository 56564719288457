import moment from 'moment';
import { useEffect } from 'react';
import { OnPageBanner } from 'shared/OnPageBanner';
import { pluralize } from 'helpers/formatter';
import { pickConfigsWithLatestNonViewedIssues } from 'helpers/dataCollection';
import { createDialogStore } from 'store/createDialogStore';
import { useDataConfigWithThresholdsStore } from 'store/dataConfigs/dataConfigsWithThresholds';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';

const useNewStatusesBannerStore = createDialogStore();

export const NewStatusesBanner = () => {
  const { opened, open, close } = useNewStatusesBannerStore();

  const configs = useDataConfigWithThresholdsStore((store) => store.getLibrary());

  const configsWithIssues = useDerivedDataEntry({ configs }, ({ configs }) => {
    const threeDaysAgo = moment().subtract(3, 'days');

    return pickConfigsWithLatestNonViewedIssues(configs, threeDaysAgo);
  });

  useEffect(() => {
    if (configsWithIssues.status === 'success') {
      if (configsWithIssues.data.length) {
        open();
      } else {
        close();
      }
    }
  }, [configsWithIssues]);

  if (configs.status !== 'success') {
    return null;
  }

  return (
    <OnPageBanner
      type='error'
      opened={opened}
      onClose={close}
      className='my-[20px]'
      title='You have unexamined data collection issues'
    >
      {configsWithIssues.status === 'success' && 
        <ul>
          {configsWithIssues.data.map(([config, issues]) => (
            <li key={config.id} className='my-2'>
              {config.collection_details.aws_account_id}
              @
              {config.data_source_id}
              {' – '}
              {issues.length}
              {' '}
              {pluralize(issues.length, { one: 'issue', other: 'issues' })} 
            </li>
          ))}
        </ul>
      }
    </OnPageBanner>
  );
}

