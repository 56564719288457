import { ReactNode } from 'react';

export interface DialogBodyProps {
  scroll?: boolean;
  className?: string;
  children?: ReactNode;
}

export const DialogBody = (props: DialogBodyProps) => {
  const {
    scroll,
    className = '',
    children
  } = props;

  return (
    <div className={`px-8 ${scroll ? 'border-y overflow-x-auto py-5 ' : ''} ${className}`}>
      {children}
    </div>
  );
}
