import type { Team } from 'types/teams';

import { useState, ReactNode } from 'react';

import { Button, IconButton } from 'shared/Button';
import { Tabs, Tab, Panes, Pane } from 'shared/Tabs';
import { TeamMembers } from './TeamMembers';
import { TeamCostViews } from './TeamCostViews';
import { TeamUnitMetrics } from './TeamUnitMetrics';
import { TeamReportConfigs } from './TeamReportConfigs';
import { useGlobalState } from 'state/globalState';
import { useTeamMembersStore } from 'store/teamMembers';
import { useTeamCostViewsStore } from 'store/teamCostViews';
import { useTeamUnitMetricsStore } from 'store/teamUnitMetrics';
import { useTeamReportConfigsStore } from 'store/teamReportConfigs';

export interface TeamBlockProps {
  team: Team;
  editTeam: (data: Team) => void;
  deleteTeam: (data: Team) => void;
  costColumnsMap: Record<number, string>;
}

export const TeamBlock = (props: TeamBlockProps) => {
  const user = useGlobalState((state) => state.user);
  const { team, editTeam, deleteTeam, costColumnsMap } = props;

  const [currentTab, setCurrentTab] = useState<'members' | 'views' | 'metrics' | null>(null);

  const teamMembers = useTeamMembersStore((state) => team.id ?
    state.getEntry(team.id) :
    { status: 'loading' } as { status: 'loading' }
  );

  const teamCostViews = useTeamCostViewsStore((state) => team.id ?
    state.getEntry(team.id) :
    { status: 'loading' } as { status: 'loading' }
  );

  const teamUnitMetrics = useTeamUnitMetricsStore((state) => team.id ?
    state.getEntry(team.id) :
    { status: 'loading' } as { status: 'loading' }
  );

  const teamReportConfigs = useTeamReportConfigsStore((state) => team.id ?
    state.getEntry(team.id) :
    { status: 'loading' } as { status: 'loading' }
  );

  /*
  useEffect(() => {
    switch (team.status) {
      case 'deleted': {
        message.success(`Team ${team.data.name} deleted`);
        break;
      }
      case 'delete-error': {
        message.error(`Unable to delete team ${team.data.name}`);
        break;
      }
    }
  }, [team.status]);

  if (!teamHasData(team)) {
    return null;
  }
   */

  let filtersTitle: ReactNode | null = null;

  const filterButtons = [
    ['default_cost_metric_filter_id', 'CUR'],
    ['default_cost_metric_filter_cloudwatch_id', 'CloudWatch'],
    ['default_cost_metric_filter_custom_id', 'Custom Data'],
    ['default_cost_metric_filter_gcp_id', 'GCP']
  ].map(([key, title]) => {
    const id = team[key as keyof typeof team] as number | null;

    if (id) {
      if (!filtersTitle) {
        filtersTitle = <span className='text-silver-grey-700'>Filters:</span>;
      }

      return <Button
        key={key}
        size='sm'
        type='tertiary'
        to={`/costs-overview/${id}`}
        label={title}
      />
    }

  return null;
});
  
  return (
    <div className='border rounded border-silver-grey my-[15px] p-[15px]'>
      <div className='flex'>
        <div className='mr-auto font-medium'>
          {team.name}
          <span className='ml-2 font-normal text-[12px] text-black/50'>
            ID: {team.new_id}
          </span>
        </div>

        <div className='flex items-center'>
          {filtersTitle}
          {filterButtons}
          <IconButton
            size='sm'
            type='tertiary'
            icon='edit'
            onClick={() => {
              editTeam(team);
            }}
          />

          {user && !user.team && (
            <IconButton
              size='sm'
              type='tertiary'
              icon='trash'
              onClick={() => {
                deleteTeam(team);
              }}
            />
          )}
        </div>
      </div>

      <Tabs value={currentTab} onChange={setCurrentTab}>
        <Tab value='members'>
          Members
          {teamMembers.status === 'loaded' ?
            ` (${teamMembers.data.size})` :
            ''
          }
        </Tab>
        <Tab value='views'>
          Cost Views
          {teamCostViews.status === 'loaded' ?
            ` (${teamCostViews.data.size})` :
            ''
          }
        </Tab>
        <Tab value='metrics'>
          Unit Metrics
          {teamUnitMetrics.status === 'loaded' ?
            ` (${teamUnitMetrics.data.size})` :
            ''
          }
        </Tab>
        <Tab value='reports'>
          Reports
          {teamReportConfigs.status === 'loaded' ?
            ` (${teamReportConfigs.data.size})` :
            ''
          }
        </Tab>
      </Tabs>

      <Panes value={currentTab}>
        <Pane value='members'>
          <TeamMembers team={team}/>
        </Pane>
        <Pane value='views'>
          <TeamCostViews team={team}/>
        </Pane>
        <Pane value='metrics'>
          <TeamUnitMetrics team={team}/>
        </Pane>
        <Pane value='reports'>
          <TeamReportConfigs team={team}/>
        </Pane>
      </Panes>

      <div className='mt-[10px] text-xs'>{`Slack Channel: ${team.slack_channel ? team.slack_channel : 'None'}`}</div>
      {/*<div className='mt-[5px] text-xs'>{`Default Cost Column: ${team.default_cost_column_id ? costColumnsMap[team.default_cost_column_id] : 'None'}`}</div> */}
    </div>
  );
}
