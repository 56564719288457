import type { ChangeEventHandler, FormEventHandler } from 'react';
import { useState, useCallback } from 'react';
import { message, Input } from 'antd';
import { singleSignOn } from 'services/auth';
import FormField from 'shared/Form/FormField';


export const SSOForm = () => {
  const [slug, setSlug] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const validSlug = !!slug.length;

  const handleSlugChange: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setSlug(event.target.value);
  }, []);

  const handleSubmit: FormEventHandler = useCallback(async (event) => {
    event.preventDefault();

    setSubmitting(true);

    try {
      const { data: { url }} = await singleSignOn(slug);
      window.location.href = url;
    } catch (error) {
      setSubmitting(false);
      const errorMessage = (error as any)?.response?.data?.message || (error as any)?.message;

      if (errorMessage) {
        message.error(errorMessage);
      }
    }
  }, [slug]);

  return (
    <form method='get' onSubmit={handleSubmit}>
      <FormField.SimpleField className='mt-[30px]' label='Organization slug'>
        <Input onChange={handleSlugChange} value={slug} name='slug' size='large' />
      </FormField.SimpleField>

      <button
        disabled={!validSlug || submitting}
        className='w-full h-[46px] mt-[30px] bg-caladon-blue rounded-[10px] font-medium text-[15px] leading-[22px] tracking-[-0.24px] text-white disabled:opacity-25'
      >
        Continue
      </button>
    </form>
  );
};
