import type {
  DrillDownField,
  DrillDownFetchParams,
  DrillDownStoreKey,
  DrillDownStoreKeyProps,
  DrillDownStoreKeyFactory
} from 'types/costViews/drillDown';

import {
  Set as ImmutableSet,
  Record as ImmutableRecord,
  OrderedMap
} from 'immutable';

import {
  defaultCostFilterKeyProps,
  defaultDateFilterKeyProps
} from './defaults';

import { toImmutableFilter } from 'helpers/filters';

const createDrillDownStoreKey: DrillDownStoreKeyFactory = ImmutableRecord<DrillDownStoreKeyProps>({
  ...defaultCostFilterKeyProps,
  ...defaultDateFilterKeyProps,
  breakdown: 'accounts',
  drillDownSteps: OrderedMap(),
  tag: undefined,
  teamId: undefined,
  globalFilterIds: undefined,
  filter: null,
});

export const drillDownFetchParamsToKey = (params: DrillDownFetchParams): DrillDownStoreKey => {
  const filter = toImmutableFilter(params.filter);
  const globalFilterIds = ImmutableSet(params.globalFilterIds);

  const costDimensions = ImmutableSet(params.costDimensions);

  const drillDownSteps = params.drillDownSteps.reduce(
    (map, [key, value]) => map.set(key, value),
    OrderedMap() as OrderedMap<DrillDownField, string>
  );

  return createDrillDownStoreKey({
    ...params,
    globalFilterIds,
    costDimensions,
    filter,
    drillDownSteps
  });
};
