import { Link } from 'react-router-dom';
import { ThreadStatusTooltip } from '../ThreadStatusTooltip';
import { renderThreadSecondaryId } from 'helpers/savings';
import { useSavingsStore } from 'store/savings';

export interface ThreadLinkProps {
  threadId: number;
}

export const ThreadLink = (props: ThreadLinkProps) => {
  const { threadId } = props;

  const thread = useSavingsStore((store) => store.getThread(threadId));

  if (thread.status !== 'success') {
    return null;
  }

  return (
    <Link
      to={`/savings/threads/${thread.data.id}`}
      className='!text-caladon-blue'
    >
      <ThreadStatusTooltip status={thread.data.status} className='relative mr-1 ' />
      [{renderThreadSecondaryId(thread.data)}]
      {' '}
      {thread.data.name}
    </Link>
  );
}
