import { useState } from 'react'
import { Table } from 'antd';

import { Icon } from 'shared/Icon/Icon';
import { InputSearch } from 'shared/Input';
import { StatusOverlay } from 'shared/Overlay';
import { RadioButton, RadioButtonGroup } from 'shared/RadioButtonGroup';

import { RoleButton } from './RoleButton';
import { DeleteButton } from './DeleteButton';
import { RecoverButton } from './RecoverButton';
import { InviteDialogButton } from './InviteDialogButton';

import { useUsersStore } from 'store/users';
import { useGlobalState } from 'state/globalState';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';

const ROLES_ORDER = {
  owner: 0,
  admin: 1,
  member: 2
};

export const UsersTable = () => {
  const currentUser = useGlobalState((state) => state.user);
  const library = useUsersStore((store) => store.getLibrary());
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState<'active' | 'deleted'>('active');

  const filteredUsers = useDerivedDataEntry({
    library,
    searchText,
    statusFilter
  }, ({
    library,
    searchText,
    statusFilter
  }) => {
    const deletedFlag = statusFilter === 'deleted';

    let users = library.filter((user) => user.is_deleted === deletedFlag);

    const normalizedSearch = searchText.trim().toLowerCase();

    if (normalizedSearch) {
      users = users.filter((user) =>
        user.name.toLocaleLowerCase().includes(normalizedSearch) ||
        user.email.toLocaleLowerCase().includes(normalizedSearch)
      );
    }

    return users;
  });

  return (
    <div>
      <div className='flex mb-4 gap-2'>
        <InputSearch
          value={searchText} 
          placeholder='Search'
          onChange={setSearchText}
        />

        <InviteDialogButton />

        <RadioButtonGroup
          name='status'
          value={statusFilter}
          onChange={(_, { value }) => {
            setStatusFilter(value);
          }}
        >
          <RadioButton value='active'>Active Users</RadioButton>
          <RadioButton value='deleted'>Deleted Users</RadioButton>
        </RadioButtonGroup>
      </div>

      <div className='relative'>
        <Table
          size='small'
          dataSource={filteredUsers.status === 'success' ? filteredUsers.data : []}
          rowKey={({ id }) => id}
          columns={[
            {
              key: 'name',
              dataIndex: 'name',
              title: 'Name',
              width: '20%',
              defaultSortOrder: 'ascend',
              sorter: (userA, userB) => userA.name.toLowerCase() > userB.name.toLowerCase() ? 1 : -1
            },
            {
              key: 'email',
              dataIndex: 'email',
              title: 'E-mail',
              width: '30%',
              sorter: (userA, userB) => userA.email.toLowerCase() > userB.email.toLowerCase() ? 1 : -1
            },
            {
              key: 'role',
              dataIndex: 'role',
              title: 'Role',
              width: '10%',
              sorter: (userA, userB) => ROLES_ORDER[userA.role] - ROLES_ORDER[userB.role],
              render: (role, { id }) => <div className='flex items-center capitalize'>
                {role}
                {role !== 'owner' && id !== currentUser?.id &&
                  <RoleButton id={id} />
                }
              </div>
            },
            {
              key: 'team',
              dataIndex: 'team',
              title: 'Team',
              sorter: (userA, userB) => {
                if (!userA.team) {
                  return 1;
                }

                if (!userB.team) {
                  return -1;
                }

                return userA.team.name.toLowerCase() > userB.team.name.toLowerCase() ? 1 : -1;
              },
              render: (team) => team ? team.name : '-'
            },
            {
              key: 'is-verified',
              dataIndex: 'is_verified',
              title: 'Is Verified',
              width: 100,
              sorter: (userA) => userA.is_verified ? 1 : -1,
              render: (is_verfied) => is_verfied ?
                <Icon icon='check-filled' className='text-green' width={14} height={14} /> :
                <Icon icon='close-circle' className='text-red' width={14} height={14} />
            },
            {
              key: 'action',
              width: 30,
              align: 'center',
              render: ({ id, role }) => 
                statusFilter === 'deleted' ?
                  <RecoverButton id={id} /> :
                  role !== 'owner' && id !== currentUser?.id ?
                    <DeleteButton id={id} /> :
                    null
            },
          ]}
        />

        <StatusOverlay status={filteredUsers.status} />
      </div>
    </div>
  )
}
