import { createKeyedStore } from './createKeyedStore';
import { createKeyedStoreSelector } from './createKeyedStoreSelector';

import { drillDownTagViewFetchParamsToKey } from 'helpers/storeKeys/tagViewDrillDown';
import { fetchTagViewDrillDown } from 'services/tagViews';

export const useTagViewDrillDownStore = createKeyedStore({
  paramsToKey: drillDownTagViewFetchParamsToKey,
  fetchData: fetchTagViewDrillDown
});

export const useTagViewDrillDown = createKeyedStoreSelector(useTagViewDrillDownStore);
