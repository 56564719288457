import type { Team } from 'types/teams';
import { ListEditor } from './ListEditor';
import { useTeamMembersStore } from 'store/teamMembers';

export interface TeamMembersProps {
  team: Team;
}

export const TeamMembers = (props: TeamMembersProps) => {
  const { team } = props;

  const members = useTeamMembersStore((state) => state.getEntry(team.id));
  const allUsers = useTeamMembersStore((state) => state.getAllUsers());
  const addTeamMember = useTeamMembersStore((state) => state.addTeamMember);
  const confirmAddition = useTeamMembersStore((state) => state.confirmAddition);
  const removeTeamMember = useTeamMembersStore((state) => state.removeTeamMember);
  const confirmRemoval = useTeamMembersStore((state) => state.confirmRemoval);

  if (members.status === 'idle') {
    return <div />
  }

  if (members.status === 'loading') {
    return <div>
      Loading team members
    </div>;
  }

  if (members.status === 'load-error') {
    return <div>
      Error loading team members
    </div>;
  }

  return (
    <ListEditor
      editable
      items={members.data.toArray().map(([_, member]) => member)}
      addOptions={allUsers.status === 'loaded' ? 
        allUsers.data.map((user) => ({
          status: 'loaded',
          data: user
        })) :
        []
      }
      getItemKey={(member) => '' + member.data.id}
      getItemTitle={(member) => `${member.data.name} (${member.data.email})`}
      addedMessage={(member) => `Member ${member.data.name} added to team ${team.name}`}
      addErrorMessage={(member) => `Unable to add ${member.data.name} to team ${team.name}`}
      removedMessage={(member) => `Member ${member.data.name} removed from team ${team.name}`}
      removeErrorMessage={(member) => `Unable to add ${member.data.name} from team ${team.name}`}
      onAdd={(member) => {
        addTeamMember(team.id, member.data.id, member.data);
      }}
      onAddConfirm={(member) => {
        confirmAddition(team.id, member.data.id);
      }}
      onRemove={(member) => {
        removeTeamMember(team.id, member.data.id);
      }}
      onRemoveConfirm={(member) => {
        confirmRemoval(team.id, member.data.id);
      }}
    >
      {(member) => `${member.data.name} (${member.data.email})`}
    </ListEditor>
  );
}
