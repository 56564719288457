import type { ReactNode } from 'react';
import {
  FilterValueCondition,
  FilterNestedCondition,
  SingleRule,
  ConstructorRules,
  FilterStringValueCondition,
  NestedRule as NestedRuleType,
  ChildRule
} from 'types/filters';

import {
  isStringRule,
  isFilterValueCondition
} from 'types/filters';

import { Fragment } from 'react';
import {
  addValue,
  addCondition,
  setCondition,
  removeCondition,
  createNestedConditon,
  createNestedConditionItem,
  getNestedComparator,
  toggleNestedComparator,
  toggleNestedRegex,
  getNestedFieldName,
  getNestedValueType
} from 'helpers/filters';
import { getOptions } from 'helpers/filters';
import { Button } from 'shared/Button';
import { Branch } from './Branch';
import { RuleRow } from './RuleRow';
import { RuleHeader } from './RuleHeader';
import { RuleValue } from './RuleValue';
import { RuleValues } from './RuleValues';
import { getIcon, getTitleSingle, getTitlePlural } from './constants';

export interface NestedRuleProps {
  value: FilterNestedCondition;
  parentValue?: FilterStringValueCondition[];
  rules: ConstructorRules;
  prefix?: ReactNode
  disabled?: boolean;
  onChange: (value: FilterNestedCondition) => void;
  onRemove?: () => void;
}

export const NestedRule = (props: NestedRuleProps) => {
  const {
    value,
    rules,
    parentValue = [],
    prefix,
    disabled,
    onChange,
    onRemove
  } = props;

  const fieldName = getNestedFieldName(value);
  const rule = rules[fieldName] as NestedRuleType;

  const childRules = isStringRule(rule) ? rule.children?.map((child) => rules[child]) as ChildRule[] : [];

  const options: string[] = getOptions(parentValue, rule);

  const comparator = getNestedComparator(value);
  const valueType = getNestedValueType(value);

  return (
    <div>
      <RuleHeader
        icon={getIcon(rule.name)}
        title={getTitleSingle(rule.name)}
        prefix={prefix}
        required={rule.required}
        comparator={comparator}
        valueType={valueType}
        disabled={disabled}
        onAdd={() => {
          const newValue = comparator === 'equals' ?
            addCondition(value, [], createNestedConditionItem(rule, valueType)):
            setCondition(value, [0, 0], addValue(value.condition[0].condition[0]));

          onChange(newValue);
        }}
        onRemove={rule.required ? undefined: onRemove}
        onValueTypeChange={() => {
          const newValue = toggleNestedRegex(value, options);

          onChange(newValue);
        }}
        onComparatorChange={() => {
          const newValue = toggleNestedComparator(value);

          onChange(newValue);
        }}
      />
      {value.condition.map((item, index) => {
        if (isFilterValueCondition(item)) {
          return null;
        }

        const [baseCondition, ...childConditions]: [FilterValueCondition, ...FilterNestedCondition[]]= item.condition;
        const baseValues = baseCondition.values;
        const baseValue = baseValues[0];

        const siblingValues = value.condition.map((sibling) => sibling.condition[0].values[0]);

        siblingValues.splice(index);

        return (
          <Branch key={index} last={index === value.condition.length - 1}>
            <RuleRow
              disabled={disabled}
              onRemove={value.condition.length > 1 ? () => {
                const newValue = removeCondition(value, [index]);

                onChange(newValue);
              } : undefined}
            >
              {comparator === 'equals' ? (
                <RuleValue
                  rule={rules[baseCondition.field_name] as SingleRule}
                  value={baseValue}
                  options={options}
                  valueType={baseCondition.value_type}
                  siblingValues={siblingValues}
                  parentValue={parentValue}
                  disabled={disabled}
                  onChange={(newBaseValue) => {
                    const newBaseCondition = {
                      ...baseCondition,
                      values: [newBaseValue]
                    } as FilterValueCondition ;

                    const newValue = setCondition(value, [index, 0], newBaseCondition);

                    onChange(newValue);
                  }}
                />
              ) : (
                <div className='px-2 pb-2 border rounded grow'>
                  <RuleValues
                    rule={rules[baseCondition.field_name] as SingleRule}
                    condition={baseCondition}
                    options={options}
                    disabled={disabled}
                    onChange={(newBaseCondition) => {
                      const newValue = setCondition(value, [index, 0], newBaseCondition);

                      onChange(newValue);
                    }}
                  />
                </div>
              )}
            </RuleRow>
            {childRules && childRules.map((childRule, childRuleIndex) => {
              const childConditionIndex = childConditions.findIndex((cond) =>
                !!cond.condition.length && (
                  isFilterValueCondition(cond.condition[0]) ?
                    cond.condition[0].field_name === childRule.name :
                    cond.condition[0].condition[0].field_name === childRule.name
                )
              );

              const childCondition = childConditions[childConditionIndex];
              const childRuleOptions = getOptions([...parentValue, baseCondition as FilterStringValueCondition], childRule);

              return <Fragment key={childRule.name}>
                {(childCondition && childCondition.condition.length) ? (
                  <Branch last={childRuleIndex === childRules.length - 1}>
                    <NestedRule
                      key={childRule.name}
                      value={childCondition}
                      rules={rules}
                      parentValue={[...parentValue, baseCondition as FilterStringValueCondition]}
                      prefix='and'
                      disabled={disabled}
                      onRemove={() => {
                        const newValue = removeCondition(value, [index, childConditionIndex + 1]);

                        onChange(newValue);

                      }}
                      onChange={(newChildCondition) => {
                        const newValue = setCondition(value, [index, childConditionIndex + 1], newChildCondition);

                        onChange(newValue);
                      }}
                    />
                  </Branch>
                ) : !disabled && (
                  <Branch small last={childRuleIndex === childRules.length - 1}>
                    <Button
                      label={`Add ${getTitlePlural(childRule.name).toLowerCase()}`}
                      size='xs'
                      type='tertiary'
                      icon={getIcon(childRule.name)}
                      style='caladon'
                      disabled={childRuleOptions.length === 0}
                      onClick={() => {
                        const newValue = addCondition(value, [index], createNestedConditon(childRule as any));

                        onChange(newValue);
                      }}
                    />
                  </Branch>
                )}
              </Fragment>;
            })}
          </Branch>
        );
      })}
    </div>
  );
};
