import { ReactNode } from 'react'

export interface LabelProps {
  size: 'xs' | 's' | 'm' | 'l';
  className?: string;
  children?: ReactNode;
}

const FONT_SIZE = {
  xs: 'text-caption-1',
  s: 'text-body',
  m: 'text-subtitle-2',
  l: 'text-subtitle-1'
}

export const Label = (props: LabelProps) => {
  const {
    size = 'm',
    className = '',
    children
  } = props

  return (
    <div className={`mb-1.5 font-medium ${FONT_SIZE[size]} ${className}`}>
      {children}
    </div>
  );
}
