export interface OverlayProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Overlay = (props: OverlayProps) => {
  const {
    className = ''
  } = props;

  return (
    <div
      {...props}
      className={`flex items-center justify-center absolute inset-0 z-10 bg-white/50 rounded-inherit ${className}`}
    />
  );
}
