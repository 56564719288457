import { useEffect, useState, useMemo } from 'react'
import type { CFNStack } from 'types/dataCollection';
import { Table, message } from 'antd'
import { Icon } from 'shared/Icon/Icon';
import IconButton from 'shared/Button/IconButton';
import { StatusOverlay } from 'shared/Overlay';
import { DeleteCFNStack } from './DeleteCFNStack';
import { useCFNStacksStore } from 'store/cfnStacks';
import { createNullableStringsComparator } from 'helpers/sort';
import moment from 'moment';

const CFNStacksLibrary = () => {
  const { 
    status,
    cfnStacks,
    fetchCFNStacks,
    deleteCFNStacks,
  } = useCFNStacksStore();
  const [searchText, setSearchText] = useState('');
  const [stackToDelete, setStackToDelete] = useState<CFNStack | null>(null);

  const handleConnectClick = (link: string) => {
    window.open(link, '_blank')
  }

  useEffect(() => {
    fetchCFNStacks();
  }, [])
  
  const filteredConfigs = useMemo(() => {
    return searchText ?
      cfnStacks.filter((stack) => stack.status.toLowerCase().includes(searchText.toLowerCase())) :
      cfnStacks; 
  }, [cfnStacks, searchText]);

  return (
    <div className='pt-[20px]'>
      <div>
        <div className='mt-[20px] mb-[20px] text-subtitle-1 font-medium'>Existing AWS Collection Cloudformation Stacks</div>
        
        <div className='flex mb-[20px]'>
          <div className='flex items-center grow pl-[11px]'>
            <Icon icon='search' />
            <input
              type='text'
              className='w-full leading-[20px] text-[14px] my-[-5px] py-[10px] pl-[40px] ml-[-29px] bg-transparent outline-none border-b border-b-transparent focus:border-b-cyanine-blue'
              value={searchText} 
              placeholder='Search Status'
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
            />
          </div>
        </div>
        
        <Table
          rowKey={({ id }) => id}
          size='small'
          dataSource={filteredConfigs}
          columns={[
            {
              key: 'provider',
              title: 'Provider',
              dataIndex: 'provider',
              sorter: (a, b) => a.status > b.status ? 1 : a.status < b.status ? -1 : 0,
              render: (_) => <div>AWS</div> //Waiting for GCP
            },
            {
              key: 'status',
              title: 'Template Status',
              dataIndex: 'status',
              sorter: (a, b) => a.status > b.status ? 1 : a.status < b.status ? -1 : 0,
              render: (status) => <div>{status}</div>
            },
            {
              key: 'link',
              title: 'Link',
              dataIndex: 'link',
              render: (link) => <a onClick={() => handleConnectClick(link)}>Template Link</a>
            },
            {
              key: 'stack_token',
              title: 'Token Identifier',
              dataIndex: 'stack_token',
              sorter: (a, b) => a.stack_token > b.stack_token ? 1 : a.stack_token < b.stack_token ? -1 : 0,
              render: (stack_token) => <div>{stack_token}</div>
            },
            {
              key: 'stack_type',
              title: 'Stack Type',
              dataIndex: 'stack_type',
              render: (stack_type) => <div>{stack_type}</div>,
            },
            {
              key: 'created_at',
              title: 'Created at',
              dataIndex: 'created_at',
              sorter: createNullableStringsComparator('created_at'),
              render: (createdAt?: string) => createdAt ? <div className='w-35 truncate'>{moment(createdAt).format('YYYY-MM-DD HH:MM:SS')}</div> : <div className='w-20 truncate'>n/a</div>
            },
            {
              key: 'delete',
              width: 32,
              render: (_, stack) => <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='trash' onClick={() => setStackToDelete(stack)} />
            },
          ]}
        />
      </div>
      
      <StatusOverlay status={status} />
      
      <DeleteCFNStack
        open={!!stackToDelete}
        onClose={() => setStackToDelete(null)}
        onConfirm={async () => {
          if (!stackToDelete) {
            return;
          }

          try {
            setStackToDelete(null);
            await deleteCFNStacks(stackToDelete.id, stackToDelete.stack_type);
            message.success(`Stack deleted`);
          } catch {
            message.error(`Unable to delete stack`);
          }
        }}
      />
    </div>
  )
}

export default CFNStacksLibrary
