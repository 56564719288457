import type { Opportunity } from 'types/savings';

import { message } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button2 as Button } from 'shared/Button';
import { Loading } from 'shared/Loading';
import { JiraIssueForm } from '../JiraIssueForm';
import { renderOpportunitySlug, validateIssueCreatePayload } from 'helpers/savings';
import { 
  useSavingsStore,
  useJiraIssuesStore,
  useJiraIssueCreateDialog,
  useJiraCloudProjects,
  useJiraServerProjects,
  useJiraCloudUsersStore,
  useJiraServerUsersStore,
} from 'store/savings';

export interface JiraIssueCreateDialogProps {
  setSelectedIntegration: (provider: string | null) => void;
  threadId: number;
  provider: 'jira_cloud' | 'jira_server';
  selected: Opportunity[];
}

const projectStore = {
  'jira_cloud': useJiraCloudProjects,
  'jira_server': useJiraServerProjects,
}
const userStore = {
  'jira_cloud': useJiraCloudUsersStore,
  'jira_server': useJiraServerUsersStore,
}

export const JiraIssueCreateDialog = (props: JiraIssueCreateDialogProps) => {
  const { opened, close } = useJiraIssueCreateDialog();

  const {
    setSelectedIntegration,
    threadId,
    provider,
    selected
  } = props;
  
  const viableOpportunityIds = selected.filter((opp) => 
    opp.jira_issues.find((iss) => iss.issue_type === 'cost_optimization_validation' && iss.thread_id === threadId) === undefined).map((opp) => opp.id)
  const skippedOpportunityIds = selected.filter((opp) => 
    opp.jira_issues.find((iss) => iss.issue_type === 'cost_optimization_validation' && iss.thread_id === threadId) !== undefined)

  const draft = useJiraIssuesStore((store) => store.getDraft(threadId, viableOpportunityIds));
  const setDraft = useJiraIssuesStore((store) => store.setDraft);
  const discardDraft = useJiraIssuesStore((store) => store.discardDraft);
  const createIssue = useJiraIssuesStore((store) => store.createIssue);
  const action = useJiraIssuesStore((store) => store.getAction(threadId, viableOpportunityIds));

  const flushOpportunities = useSavingsStore((store) => store.flushOpportunities);

  const creating = action && action.type === 'create' && action.status === 'in-progress';

  return (
    <Dialog
      className='w-[480px]'
      open={opened}
      onClose={() => {
        close();
        discardDraft(threadId, viableOpportunityIds);
        setSelectedIntegration(null);
      }}
    >
      <Dialog.Title>
        Create JIRA Issue
      </Dialog.Title>

      <div className='px-8'>
        <JiraIssueForm
          value={draft}
          onChange={(value) => {
            setDraft(threadId, viableOpportunityIds, value);
          }}
          projectStore={projectStore[provider]}
          userStore={userStore[provider]}
          provider={provider}
        />
      </div>

      <div className='flex justify-end p-4'>
        <Button
          size='m'
          theme='filled'
          disabled={!validateIssueCreatePayload(draft) || creating}
          onClick={() => {
            createIssue(threadId, viableOpportunityIds, provider)
              .then(() => {
                message.success('Issue created');
                flushOpportunities();
                close();
                discardDraft(threadId, viableOpportunityIds);
                setSelectedIntegration(null);
              })
              .catch(() => {
                message.error('Unable to create issue');
                close();
                setSelectedIntegration(null);
              });
          }}
        >
          Create JIRA Issue
        </Button>
      </div>
      {viableOpportunityIds.length === 0 && (
        <div className='px-8'>
          <div className='flex justify-between mb-2 text-red text-xs'>
            All selected opportunities are skipped - please reselect
          </div>
        </div>
      )}
      {skippedOpportunityIds.length > 0 && (
        <div className='px-8'>
          <div className='flex justify-between mb-2 font-medium text-subtitle-1'>
            Validation Issue Already Exists ({skippedOpportunityIds.length} skipped)
          </div>

          <ul className='mb-5'>
            {skippedOpportunityIds.map((opp) => <li className='my-[5px]'>
              {renderOpportunitySlug(opp)}
            </li>)}
          </ul>
        </div>
      )}

      {creating && <Loading />}
    </Dialog>
  );
};
