import type { CostViewFilter, CostViewConstructor, CostViewFilterCondition } from 'types/costViews';
import { Fragment } from 'react';
import { Button2 as Button } from 'shared/Button';
import { Filter } from './Filter';
import { createGroupCondition, addCondition, setCondition } from 'helpers/filters';
import { useGlobalState } from 'state/globalState';
import {TeamFilterConstructor, TeamFilterMap} from 'types/teams';
import {TeamFilterDisplay} from './TeamFilterDisplay';
import {Source} from 'types/unitMetrics';

export interface CostViewFiltersProps {
  value: CostViewFilter;
  costViewConstructor: CostViewConstructor;
  disabled?: boolean;
  onChange: (value: CostViewFilter) => void;
  teamFilters?: TeamFilterMap;
  teamFilterConstructor?: TeamFilterConstructor;
}

export const CostViewFilters = (props: CostViewFiltersProps) => {
  const {
    value,
    onChange,
    disabled,
    costViewConstructor,
    teamFilters,
    teamFilterConstructor
  } = props;

  const {
    src,
    filter
  } = value;

  const logEvent = useGlobalState((state) => state.logEvent);

  const handleFilterChange = (filter: CostViewFilterCondition) => {
    onChange({ src, filter });
  };

  const handleAddFilterClick = () => {
    const newFilter = addCondition(filter, [], createGroupCondition('and'));

    handleFilterChange(newFilter);
    logEvent('add_or_filter', { 'view': 'cost_view' });
  };

  const { maxItems, rules } = costViewConstructor[src];

  return (
    <div className='flex-col py-[10px]'>
      {teamFilters && teamFilterConstructor &&
        <TeamFilterDisplay
          src={value.src as Source}
          filters={teamFilters}
          teamFilterConstructor={teamFilterConstructor}
        />
      }

      {value.filter.condition.map((condition, index) => (
        <Fragment key={index}>
          <div className='relative px-3 border rounded-3 border-black/10'>
            <div className='absolute flex bg-white top-[-7px] right-[-7px]'>
              <Button
                className='hover:text-red'
                size='m'
                theme='black'
                layout='inline'
                icon='close-circle'
                onClick={() => {
                  const condition = [...value.filter.condition];

                  condition.splice(index, 1);

                  onChange({
                    ...value,
                    filter: {
                      ...value.filter,
                      condition
                    }
                  });
                }}
              />
            </div>
            <Filter
              value={condition}
              rules={rules}
              disabled={disabled}
              onChange={(newCondition) => {
                const newFilter = setCondition(filter, [index], newCondition);

                handleFilterChange(newFilter);
              }}
              onRuleAdd={(rule) => { logEvent('add_filter_rule', { view: 'cost_view', rule: rule.name }); }}
              onRuleRemove={(rule) => { logEvent('remove_filter_rule', { view: 'cost_view', rule: rule.name }); }}
              onValueAdd={(rule) => { logEvent('add_filter_value', { view: 'cost_view', rule: rule.name }); }}
              onValueChange={(rule) => { logEvent('change_filter_value', { view: 'cost_view', rule: rule.name }); }}
              onValueRemove={(rule) => { logEvent('clear_filter_value', { view: 'cost_view', rule: rule.name }) }}
              onValueTypeChange={(rule) => { logEvent('adjust_value_type_filter_value', { view: 'cost_view', rule: rule.name }); }}
              onComparatorChange={(rule) => { logEvent('adjust_comparator_filter_value', { view: 'cost_view', rule: rule.name }); }}
            />
          </div>
          {index < value.filter.condition.length -1 &&
            <div className='my-1.5 font-medium text-center text-subtitle-2'>OR</div>
          }
        </Fragment>
      ))}

      {(!maxItems || value.filter.condition.length < maxItems) && !disabled &&
        <Button
          className='w-full mt-3'
          size='m'
          theme='bordered'
          iconLeft='circle-plus'
          onClick={handleAddFilterClick}
        >
          <span>
            Add <b>OR</b> Filter
          </span>
        </Button>
      }
    </div>
  );
};
