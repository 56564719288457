import type { ReactNode } from 'react';

import { Tooltip } from 'antd';
import { Icon } from 'shared/Icon/Icon';

export interface InfoIconProps {
  className?: string;
  children: ReactNode;
}

export const InfoIcon = (props: InfoIconProps) => {
  const {
    className = '',
    children
  } = props;

  return (
    <Tooltip title={children}>
      <span className={`inline-block align-[-2px] ml-[4px] ${className}`}>
        <Icon icon='info' />
      </span>
    </Tooltip>
  );
};
