import IconButton from 'shared/Button/IconButton'

export interface Action {
  available?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  to?: string;
}

export interface ActionButtonsProps {
  del: Action;
  save: Action;
  saveAsNew: Action;
  newAlert: Action;
  newReport: Action;
}

export const ActionButtons = (props: ActionButtonsProps) => {
  const {
    del: {
      available: delAvailable = true,
      ...delProps
    },
    save: {
      available: saveAvailable = true,
      ...saveProps
    },
    saveAsNew: {
      available: saveAsNewAvailable = true,
      ...saveAsNewProps
    },
    newAlert: {
      available: newAlertAvailable = true,
      ...newAlertProps
    },
    newReport: {
      available: newReportAvailable = true,
      ...newReportProps
    },
  } = props;

  return (
    <div className='flex items-end mr-auto'>
      {delAvailable && (
        <IconButton
          className="ml-[20px] text-[10px]"
          icon="trash-can"
          geometry="circle"
          hoverStyle="negative"
          type="tertiary"
          size="sm"
          direction="column"
          {...delProps}
        >
          Delete
        </IconButton>
      )}
      {saveAvailable && (
        <IconButton
          className="ml-[20px] text-[10px]"
          icon="save" geometry="circle"
          hoverStyle="positive"
          type="tertiary"
          size="sm"
          direction="column"
          {...saveProps}
        >
          Save
        </IconButton>
      )}
      {saveAsNewAvailable && (
        <IconButton
          className="ml-[20px] text-[10px]"
          icon="circle-plus"
          geometry="circle"
          hoverStyle="normal"
          type="tertiary"
          size="xs"
          direction="column"
          {...saveAsNewProps}
        >
          Save as...
        </IconButton>
      )}
      {newAlertAvailable && (
        <IconButton
          className="ml-[20px] text-[10px]"
          icon="alert"
          geometry="circle"
          hoverStyle="normal"
          type="tertiary"
          size="xs"
          direction="column"
          {...newAlertProps}
        >
          Alert
        </IconButton>
      )}
      {newReportAvailable && (
        <IconButton
          className="ml-[20px] text-[10px]"
          icon="notes"
          geometry="circle"
          hoverStyle="normal"
          type="tertiary"
          size="xs"
          direction="column"
          {...newReportProps}
        >
          Report
        </IconButton>
      )}
    </div>
  );
}
