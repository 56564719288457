import React from 'react'
import { Link } from 'react-router-dom'

type InputFieldPropsType = {
  type?: string
  placeholder?: string
  label: string
  value: string
  disabled?: boolean
  error?: string
  forgotPassword?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const InputField = ({
  type = 'text',
  placeholder = '',
  label,
  value,
  disabled = false,
  forgotPassword = false,
  error = '',
  onChange,
}: InputFieldPropsType) => (
  <div className="flex flex-col mt-[30px] relative">
    <div className='flex'>
    <span className="font-medium text-[13px] leading-[20px] text-oxford-blue mb-[10px]">
      {label}
    </span>
    {forgotPassword && (
      <Link className='ml-auto text-caladon-blue hover:text-caladon-blue text-medium text-[13px] leading-[20px]' to='/forgot-password'>
        Forgot your password?
      </Link>
    )}
    </div>
    <input
      className="w-full h-[46px] border border-silver-grey-400 rounded-[8px] px-[15px] py-[12px] focus-visible:outline-none text-[14px] leading-[22px] text-oxford-blue placeholder:text-grey-transparent-placeholder"
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
    {error && <span className="absolute bottom-[-20px] left-[5px] text-[12px] text-red-600">{error}</span> }
  </div>
)

export default InputField
