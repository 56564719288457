import { Popover } from '@headlessui/react';
import { useMemo, useEffect } from 'react'
import { COST_TYPE_LABELS, COST_DIMENSION_LABELS } from './constants';
import { useCostFilterValue } from 'hooks/costFilter';
import { useCostColumnStore } from 'store/costColumns';
import { useGlobalState } from 'state/globalState';
import { useTeamsStore } from 'store/teams';

export const CostFilterButton = () => {
  const {
    costType,
    costDimensions,
    costAmortization
  } = useCostFilterValue();

  const { 
    costColumnsMap,
    fetchCostColumns,
  } = useCostColumnStore();

  const user = useGlobalState((state) => state.user);
  const costColumnsTabAvailable = useMemo(() => user?.permissions.includes('admin'), [user]);
  const team = useTeamsStore((store) => user?.team ? store.getEntry(user.team.id) : null);

  const default_cost_column_name = team?.status === 'success' ? team.data.default_cost_column_name : null;

  useEffect(() => {
    if (costColumnsTabAvailable) {
      fetchCostColumns();
    }
  }, [costColumnsTabAvailable])

  const showInfo = costType !== 'public_cost';
  const mainTitle = useMemo(() => {
    if (default_cost_column_name) {
      return default_cost_column_name
    } else if (Object.keys(COST_TYPE_LABELS).includes(costType)) {
      return COST_TYPE_LABELS[costType] + ' cost' + (showInfo && costAmortization ? ' (Amortized)' : '')
    } else if (Object.keys(costColumnsMap).length) {
      return costColumnsMap[parseInt(costType)]
    } else {
      return  'Loading cost columns'
    }
  }, [costType, costColumnsMap])
  const subtitle = showInfo && costDimensions.length > 0 ?
    `- ${costDimensions.map((dimension) => COST_DIMENSION_LABELS[dimension]).join(', ')}` :
    '';

  return (
    <Popover.Button
      title={`${mainTitle} ${subtitle}`}
      className='basis-[220px] border rounded border-silver-grey-700/40 px-2 text-left h-[43px] flex flex-col justify-center whitespace-nowrap overflow-hidden align-stretch'
    >
      <div className='min-w-0 overflow-hidden text-ellipsis w-[100%]'>
        {mainTitle}
      </div>

      {subtitle && (
        <div className='text-caption-2 overflow-hidden text-ellipsis w-[100%]'>
          {subtitle}
        </div>
      )}
    </Popover.Button>
  );
};
