import type { Tags } from 'types/tags';

import { useEffect } from 'react';
import { message, Input } from 'antd';
import { Button } from 'shared/Button';
import { TagTable } from './TagTable';
import { useTagCatalogsStore } from 'store/tagCatalogs';
import { AddTagControl } from './AddTagControl';

export interface EditCatalogFormProps {
  id: number;
}

export const EditCatalogForm = (props: EditCatalogFormProps) => {
  const { id } = props;

  const tagCatalog = useTagCatalogsStore((store) => {
    const listEntry = store.getEntries();

    if (listEntry.status !== 'success') {
      return null;
    }

    return listEntry.data.find((catalog) => catalog.source.id === id) || null;
  });

  const setTagCatalog = useTagCatalogsStore((store) => store.setEditEntry);
  const updateTagCatalog = useTagCatalogsStore((store) => store.updateEntry);
  const confirmUpdateSuccess = useTagCatalogsStore((store) => store.confirmUpdateSuccess);
  const discardUpdateError = useTagCatalogsStore((store) => store.discardUpdateError);

  useEffect(() => {
    if (tagCatalog?.action?.type !== 'update') {
      return;
    }

    if (tagCatalog.action.status === 'success') {
      message.success(`Tag catalog '${tagCatalog.draft.name}' saved`);
      confirmUpdateSuccess(id);
    } else if (tagCatalog.action.status === 'error') {
      message.error(<>
        {`Unable to save '${tagCatalog.draft.name}' `}
        <button
          onClick={() => {
            updateTagCatalog(id);
          }}
        >
          Retry
        </button>
      </>);

      discardUpdateError(id);
    }

  }, [tagCatalog?.action?.status]);

  if (!tagCatalog) {
    return null;
  }

  const {
    source,
    draft
  } = tagCatalog;

  const name = draft.name !== undefined ? draft.name : source.name;
  const catalogKey = draft.catalog_key !== undefined ? draft.catalog_key : source.catalog_key;
  const tags = draft.tags !== undefined ? draft.tags : source.tags;

  const setName = (newName: string) => {
    const newCatalog = {
      ...tagCatalog,
      draft: {
        ...draft,
        name: newName
      }
    };

    setTagCatalog(newCatalog);
  };

  const setCatalogKey = (newCatalogKey: string) => {
    const newCatalog = {
      ...tagCatalog,
      draft: {
        ...draft,
        name: newCatalogKey,
        catalog_key: newCatalogKey
      }
    };

    setTagCatalog(newCatalog);
  };

  const setTags = (newTags: Tags) => {
    const newCatalog = {
      ...tagCatalog,
      draft: {
        ...draft,
        tags: newTags
      }
    };

    setTagCatalog(newCatalog);
  };

  const changed = source.name !== name ||
    source.catalog_key !== catalogKey ||
    source.tags !== tags;

  const valid = name.trim() !== '' && catalogKey.trim() !== '';

  const actionInProgress = !!tagCatalog.action;
  const canReset = !actionInProgress && changed;
  const canSave = !actionInProgress && changed && valid;

  return <div className='gap-[15px]'>
    <div className='mb-[20px]'>
      Catalog key
      <Input
        size='large'
        disabled={actionInProgress}
        value={catalogKey}
        onChange={(event) => {
          setCatalogKey(event.target.value);
        }}
      />
    </div>
    <div className='grow'>
      <TagTable
        disabled={actionInProgress}
        tags={tags}
        onChange={setTags}
      />

      <AddTagControl
        tags={tags}
        onAdd={(key, value) => {
          setTags({
            ...tags,
            [key]: value
          });
        }}
        onKeyCreate={() => {}}
        onValueCreate={() => {}}
      />

      <div className='flex justify-end gap-[10px]'>
        <Button
          size='md'
          disabled={!canReset}
          onClick={() => {
            setTagCatalog({
              ...tagCatalog,
              draft: {}
            });
          }}
          label='Reset'
        />

        <Button
          size='md'
          disabled={!canSave}
          onClick={() => {
            updateTagCatalog(id);
          }}
          label='Save Catalog'
        />
      </div>
    </div>
  </div>;
};
