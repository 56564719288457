import type { DataEntry } from 'types/dataEntries';
import type { Integration, IntegrationProvider } from 'types/users';

import create from 'zustand';

import { fetchIntegrations, validateCode } from 'services/integrations';

export interface IntegrationsStoreState {
  integrations: DataEntry<Integration[]>;
}

export interface IntegrationsStoreActions {
  fetchIntegrations: () => void;
  getIntegrations: () => DataEntry<Integration[]>;
  validate: (provider: IntegrationProvider, code: string) => Promise<Integration>;
}

export interface IntegrationsStore extends IntegrationsStoreState, IntegrationsStoreActions {};

export const useIntegrationsStore = create<IntegrationsStore>((set, get) => ({
  integrations: { status: 'idle' },

  fetchIntegrations() {
    set({
      integrations: {
        status: 'loading'
      }
    });

    fetchIntegrations()
      .then((data) => {
        set({
          integrations: {
            status: 'success',
            data
          }
        });
      })
      .catch((error) => {
        set({
          integrations: {
            status: 'error',
            error
          }
        });
      });
  },

  getIntegrations() {
    if (get().integrations.status === 'idle') {
      get().fetchIntegrations();
    }

    return get().integrations;
  },

  validate(provider: IntegrationProvider, code: string) {
    return validateCode(provider, code)
      .then((integration) => {
        const { integrations } = get();

        if (integrations.status === 'success') {
          set({
            integrations: {
              ...integrations,
              data: [
                ...integrations.data,
                integration
              ]
            }
          });
        }

        return integration;
      });
  }
}));
