import { useEffect } from 'react';

export const Chat = () => {
  useEffect(() => {
    //@ts-ignore
    window.$crisp = [];
    //@ts-ignore
    window.CRISP_WEBSITE_ID = '15872808-5ff2-4232-a298-0d33d5295b51';

    const script = document.createElement('script');

    script.src = 'https://client.crisp.chat/l.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return null;
};
