import type { Opportunity } from 'types/savings';

import { Button2 as Button } from 'shared/Button';
import { formatCurrency, pluralize } from 'helpers/formatter';

export interface SelectionSummaryProps {
  opportunities: Opportunity[];
  onReset: () => void;
}

export const SelectionSummary = (props: SelectionSummaryProps) => {
  const {
    opportunities,
    onReset
  } = props;

  const sum = opportunities.reduce((sum, opp) => sum + opp.cost_impact, 0);

  return (
    <div>
      {'Selected '}
      {opportunities.length}
      {' '}
      {pluralize(opportunities.length, { one: 'opportunity', other: 'opportunities' })}
      {' '}
      ({formatCurrency(sum * 30)} est. monthly savings)
      {onReset &&
        <Button
          size='xs'
          theme='link'
          layout='inline'
          className='ml-3'
          onClick={onReset}
        >
          Clear Selection
        </Button>
      }
    </div>
  );
}
