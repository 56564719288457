import { ComponentProps, useState } from 'react';
import { Button } from 'shared/Button';
import { Select } from 'antd';

export interface AddControlProps {
  options: ComponentProps<typeof Select>['options'];
  onAdd: (key: string) => void;
}

export const AddControl = (props: AddControlProps) => {
  const { 
    options,
    onAdd
  } = props;

  const [value, setValue] = useState<string | null>(null);

  return (
    <div className='flex items-stretch w-[320px]'>
      <Select
        className='grow'
        value={value}
        onChange={setValue}
        options={options}
      />
      <Button
        size='sm'
        style='caladon'
        label='Add'
        className='ml-2'
        disabled={value === null}
        onClick={() => {
          onAdd(value as string);
        }}
      />
    </div>
  );
};
