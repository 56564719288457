import type { ReportConfig } from 'types/reports';
import type { ListEntry } from 'types/dataEntries';
import type { GeneralMember } from 'types/teams';

import create from 'zustand';
import { Map as ImmutableMap } from 'immutable';
import { fetchReportConfigs } from 'services/report';
import {
  fetchReportConfigMembers,
  addReportConfig,
  removeReportConfig,
} from 'services/teams';

type MemberData = GeneralMember;
type TeamReportConfigEntry = ListEntry<MemberData>;

interface TeamReportConfigsIdle { status: 'idle' }
interface TeamReportConfigsLoading { status: 'loading' }
interface TeamReportConfigsLoadError { status: 'load-error'; error: any; }
interface TeamReportConfigsLoaded {
  status: 'loaded';
  data: ImmutableMap<number, TeamReportConfigEntry>;
}

type TeamReportConfigsEntry = TeamReportConfigsIdle | TeamReportConfigsLoading | TeamReportConfigsLoadError | TeamReportConfigsLoaded;

type ReportConfigEntry = 
  { status: 'idle' } |
  { status: 'loading' } |
  { status: 'load-error'; error: any } |
  {
    status: 'loaded',
    data: ReportConfig[]
  }

export interface TeamReportConfigsState {
  allReportConfigs: ReportConfigEntry;
  entries: ImmutableMap<number, TeamReportConfigsEntry>;
}

export interface TeamReportConfigsActions {
  getAllReportConfigs: () => ReportConfigEntry;
  getEntry: (teamId: number) => TeamReportConfigsEntry;
  setTeamReportConfig: (teamId: number, memberId: number, memberEntry: TeamReportConfigEntry) => void;
  addTeamReportConfig: (teamId: number, memberId: number, memberData: MemberData) => void;
  confirmAddition: (teamId: number, memberId: number) => void;
  removeTeamReportConfig: (teamId: number, memberId: number) => void;
  confirmRemoval: (teamId: number, memberId: number) => void;
}

export interface TeamReportConfigsStore extends TeamReportConfigsState, TeamReportConfigsActions {}

const DEFAULT_STATE: TeamReportConfigsState = {
  allReportConfigs: { status: 'idle' },
  entries: ImmutableMap()
};

export const useTeamReportConfigsStore = create<TeamReportConfigsStore>((set, get) => ({
  ...DEFAULT_STATE,

  getAllReportConfigs: () => {
    let { allReportConfigs } = get();

    if (allReportConfigs.status === 'idle') {
      allReportConfigs = { status: 'loading' };

      set({ allReportConfigs });

      fetchReportConfigs()
        .then(({ data }) => {
          set({
            allReportConfigs: {
              status: 'loaded',
              data
            }
          });
        })
        .catch((error: any) => {
          set({
            allReportConfigs: {
              status: 'load-error',
              error
            }
          });
        });
    }

    return allReportConfigs;
  },

  setTeamReportConfig: (teamId: number, memberId: number, member: TeamReportConfigEntry) => {
    const { entries } = get();

    const team = entries.get(teamId);

    if (!team || team.status !== 'loaded') {
      return;
    }

    set({
      entries: entries.set(teamId, {
        ...team,
        data: team.data.set(memberId, member)
      })
    });
  },

  getEntry: (teamId: number) => {
    let { entries } = get();
    
    let members = entries.get(teamId);

    if (members) {
      return members;
    }
    
    members = { status: 'loading' };
    entries = entries.set(teamId, members);
    set({ entries });

    fetchReportConfigMembers(teamId)
      .then(({ data }) => {
        entries = get().entries;
        
        const memberEntries = data.reduce(
          (map: ImmutableMap<number, TeamReportConfigEntry>, member) => map.set(member.id, {
            status: 'loaded',
            data: member
          }),
          ImmutableMap() as ImmutableMap<number, TeamReportConfigEntry>
        );

        entries = entries.set(teamId, {
          status: 'loaded',
          data: memberEntries
        });

        set({ entries });
      })
      .catch((error: any) => {
        entries = get().entries;
        entries.set(teamId, {
          status: 'load-error',
          error
        });
      });

    return members;
  },

  removeTeamReportConfig(teamId: number, memberId: number) {
    let {
      getEntry,
      setTeamReportConfig
    } = get();

    let team = getEntry(teamId);

    if (team.status !== 'loaded') {
      return;
    }

    const member = team.data.get(memberId);

    if (!member || member.status !== 'loaded') {
      return;
    }
    
    const deletingMember: TeamReportConfigEntry = {
      data: member.data,
      status: 'removing'
    };
    
    setTeamReportConfig(teamId, memberId, deletingMember);

    removeReportConfig(teamId, memberId)
      .then(() => {
        const deletedMember: TeamReportConfigEntry = {
          ...deletingMember,
          status: 'removed'
        };

        setTeamReportConfig(teamId, memberId, deletedMember);
      })
      .catch((error) => {
        const deleteErrorMember: TeamReportConfigEntry = {
          ...deletingMember,
          status: 'remove-error',
          error
        };

        setTeamReportConfig(teamId, memberId, deleteErrorMember);
      });
  },

  confirmRemoval: (teamId: number, memberId: number) => {
    let { entries } = get();

    let team = entries.get(teamId);

    if (!team || team.status === 'idle' || team.status === 'loading' || team.status === 'load-error') {
      return;
    }

    team = {
      ...team,
      data: team.data.delete(memberId)
    };

    set({
      entries: entries.set(teamId, team)
    });
  },

  addTeamReportConfig: (teamId: number, memberId: number, memberData: MemberData) => {
    const {
      entries,
      setTeamReportConfig
    } = get();

    const team = entries.get(teamId);
    
    if (!team || team.status !== 'loaded') {
      return;
    }

    const oldTeamKey = entries.findKey((team) => {
      if (team.status !== 'loaded') {
        return false;
      }

      return team.data.has(memberId);
    });

    if (oldTeamKey) {
      set({ 
        entries: entries.remove(memberId)
      });
    }

    const addingMember: TeamReportConfigEntry = {
      status: 'adding',
      data: memberData
    };

    setTeamReportConfig(teamId, memberId, addingMember);

    addReportConfig(teamId, memberId)
      .then(() => {
        const addedMember: TeamReportConfigEntry = {
          status: 'added',
          data: memberData
        };

        setTeamReportConfig(teamId, memberId, addedMember);
      })
      .catch((error: any) => {
        const addErrorMember: TeamReportConfigEntry = {
          status: 'add-error',
          error,
          data: memberData
        };

        setTeamReportConfig(teamId, memberId, addErrorMember);
      });
  },

  confirmAddition: (teamId: number, memberId: number) => {
    let { entries } = get();

    let team = entries.get(teamId);

    if (!team || team.status === 'idle' || team.status === 'loading' || team.status === 'load-error') {
      return;
    }

    let member = team.data.get(memberId);

    if (!member) {
      return;
    }

    team = {
      ...team,
      data: team.data.set(memberId, {
        status: 'loaded',
        data: member.data
      })
    };

    set({
      entries: entries.set(teamId, team)
    });
  }
}));
