import { useState } from 'react';

import { message, Select } from 'antd';
import { Button2 as Button } from 'shared/Button';
import { Dialog, DialogBody, DialogFooter } from 'shared/Dialog';
import { FormRow, Label } from 'shared/Form';

import { useTeamsStore } from 'store/teams';
import { useOpportunityTeamDialog, useSavingsStore } from 'store/savings';
import { Opportunity } from 'types/savings';
import { renderOpportunityLink, renderOpportunitySlug } from 'helpers/savings';
import { pluralize} from 'helpers/formatter';
import { TeamRenderer } from 'shared/TeamRenderer';

export const OpportunityTeamDialog = () => {
  const {
    id: opportunities,
    open,
    close
  } = useOpportunityTeamDialog();

  const teams = useTeamsStore((store) => store.getLibrary());
  const [teamId, setTeamId] = useState<number | null>(null);

  const assignOpportunityTeam = useSavingsStore((store) => store.assignOpportunityTeam);

  return (
    <Dialog
      className='w-[480px]'
      open={opportunities !== null}
      onClose={close}
    >
      <Dialog.Title>
        Assign
        {' '}
        {opportunities ?
          opportunities.length === 1 ? 
            renderOpportunitySlug(opportunities[0]) :
            `${opportunities.length} ${pluralize(opportunities.length, { one: 'opportunity', other: 'opportunities' })}` :
          'opportunities'
        }
        {' '}
        to a team
      </Dialog.Title>

      <DialogBody>
        {opportunities && opportunities.length > 1 && 
          <FormRow size='m'>
            <Label size='m'>
              Opportunites to assign:
            </Label>

            {opportunities.map((opp, i) => <span>
              {i > 0 && ', '}
              {renderOpportunityLink(opp)}
              {' '}
              <Button
                size='xs'
                theme='gray'
                layout='inline'
                icon='close-circle'
                onClick={() => {
                  open(opportunities.filter((o) => o !== opp));
                }}
              />
            </span>)}
          </FormRow>
        }

        <FormRow size='m'>
          <Label size='m'>
            Team
          </Label>

          <Select
            size='large'
            className='w-full'
            value={teamId}
            disabled={teams.status !== 'success'}
            options={teams.status !== 'success' ? [] : teams.data.map((team) => ({
              value: team.id,
              label: team.name
            }))}
            onChange={setTeamId}
          />
        </FormRow>

      </DialogBody>

      <DialogFooter>
        <Button
          size='m'
          theme='link'
          onClick={close}
        >
          Cancel
        </Button>

        <Button
          size='m'
          theme='filled'
          disabled={teamId === null || opportunities === null}
          onClick={() => {
            if (teamId !== null && opportunities !== null) {
              Promise.allSettled(
                opportunities.map(({ id }) => assignOpportunityTeam(id, teamId))
              )
                .then((results) => {
                  const fulfilled: Opportunity[] = [];
                  const rejected: Opportunity[] = [];

                  results.forEach((result, i) => {
                    (result.status === 'fulfilled' ? fulfilled : rejected).push(opportunities[i])
                  });

                  if (rejected.length === 0) {
                    message.success(<>
                      {fulfilled.length === 1 ?
                        <b>{renderOpportunitySlug(fulfilled[0])}</b> :
                        <>
                          All
                          {' '}
                          <b>{fulfilled.length} {pluralize(fulfilled.length, { one: 'opportunity', other: 'opportunities' })}</b>
                        </>
                      }
                      {' '}
                      assigned to team
                      {' '}
                      <b><TeamRenderer id={teamId} /></b>
                    </>);
                    close();
                  } else if (fulfilled.length === 0) {
                    message.error(<>
                      Cannot assign
                      {' '}
                      {rejected.length === 1 ?
                        <b>{renderOpportunitySlug(rejected[0])}</b> :
                        <b>{rejected.length} {pluralize(fulfilled.length, { one: 'opportunity', other: 'opportunities' })}</b>
                      }
                      {' '}
                      to team
                      {' '}
                      <b><TeamRenderer id={teamId} /></b>
                    </>);
                  } else {
                    message.warning(<>
                      Opportunities partially assigned to team <b><TeamRenderer id={teamId} /></b><br/>
                      Assigned: {fulfilled.map(renderOpportunitySlug).join(', ')}<br/>
                      Could not assign: {rejected.map(renderOpportunitySlug).join(', ')}
                    </>);

                    open(rejected);
                  }
                });
            }
          }}
        >
          Assign
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
