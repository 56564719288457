import type { FetchStatus } from 'types/common';
import create from 'zustand';
import {
  fetchSSOSettings,
  createSSOSettings,
  updateSSOSettings
} from 'services/sso';

export interface SSOSettingsState {
  status: FetchStatus;
  settingsCreated: boolean;
  connectionCreated: boolean;
  enabled: boolean;
  profilesOutsideAllowed: boolean;
  allowedDomains: string[]
  domainToAdd: string;
}

export interface SSOSettingsActions {
  toggleEnabled: () => void;
  toggleProfilesOutsideAllowed: () => void;
  addDomain: () => void;
  removeDomain: (index: number) => void;
  setDomainToAdd: (domainToAdd: string) => void;
  fetchSSOSettings: () => Promise<void>;
  createSSOSettings: () => Promise<void>;
  updateSSOSettings: () => Promise<void>;
}

export interface SSOSettingsStore extends SSOSettingsState, SSOSettingsActions {}

const DEFAULT_STATE: SSOSettingsState = {
  status: 'idle',
  settingsCreated: false,
  connectionCreated: false,
  enabled: false,
  profilesOutsideAllowed: false,
  allowedDomains: [],
  domainToAdd: ''
};

export const useSSOSettingsStore = create<SSOSettingsStore>((set, get) => ({
  ...DEFAULT_STATE,

  toggleEnabled: async () => {
    set({ enabled: !get().enabled });
  },

  toggleProfilesOutsideAllowed: async () => {
    set({
      profilesOutsideAllowed: !get().profilesOutsideAllowed
    });
  },

  setDomainToAdd: (domainToAdd: string) => {
    set({ domainToAdd });
  },

  addDomain: async () => {
    const {
      domainToAdd,
      allowedDomains: oldAllowedDomains
    } = get();

    const newAllowedDomains = [
      ...oldAllowedDomains,
      domainToAdd
    ];

    set({
      domainToAdd: '',
      allowedDomains: newAllowedDomains
    });
  },

  removeDomain: (index: number) => {
    const {
      allowedDomains: oldAllowedDomains
    } = get();

    const newAllowedDomains = [...oldAllowedDomains];
    
    newAllowedDomains.splice(index, 1);

    set({ allowedDomains: newAllowedDomains });
  },

  fetchSSOSettings: async () => {
    try {
      set({ status: 'loading' });

      const { data } = await fetchSSOSettings();

      set ({
        connectionCreated: data.is_sso_connection_created || false,
        enabled: data.is_sso_enabled,
        profilesOutsideAllowed: data.is_allow_profiles_outside_organization,
        allowedDomains: data.allowed_domains
      });

      set({
        settingsCreated: true,
        status: 'success'
      });
    } catch (error: any)  {
      if (error?.response?.data?.error === 'resource_not_found') {
        set({
          status: 'success'
        });
      } else {
        set({
          status: 'error'
        });
      }
    }
  },

  createSSOSettings: async () => {
    try {
      const {
        enabled,
        profilesOutsideAllowed,
        allowedDomains
      } = get();

      await createSSOSettings({ 
        is_sso_enabled: enabled,
        is_allow_profiles_outside_organization: profilesOutsideAllowed,
        allowed_domains: allowedDomains
      });

      set({
        settingsCreated: true,
      });
    } catch (error) {
      throw error;
    }
  },

  updateSSOSettings: async () => {
    try {
      const {
        enabled,
        profilesOutsideAllowed,
        allowedDomains
      } = get();

      await updateSSOSettings({ 
        is_sso_enabled: enabled,
        is_allow_profiles_outside_organization: profilesOutsideAllowed,
        allowed_domains: allowedDomains
      });
    } catch (error) {
      throw error;
    }
  }
}));
