import type { DataCollectionStatus } from 'types/dataCollection';

import moment from 'moment';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { OnPageBanner } from 'shared/OnPageBanner';
import { createDialogStore } from 'store/createDialogStore';
import { useDataConfigWithThresholdsStore } from 'store/dataConfigs/dataConfigsWithThresholds';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { pickLatestNonViewedIssues } from 'helpers/dataCollection';

import { isCostExplorerOpportunityTypeId, isComputeOptimizerOpportunityTypeId } from 'types/savings';

const useDataCollectionBannerStore = createDialogStore();

const hasIssue = (
  statuses: DataCollectionStatus[],
  checkTypeId: (typeId: string) => boolean,
  statusMessage: string
) => statuses.some((status) =>
  status.status_type === 'access' &&
  status.status === statusMessage && 
  checkTypeId(status.collection_id)
) ;

export const DataCollectionBanner = () => {
  const { opened, open, close } = useDataCollectionBannerStore();

  const configs = useDataConfigWithThresholdsStore((store) => store.getLibrary());

  const flags = useDerivedDataEntry({ configs }, ({ configs }) => {
    const threeDaysAgo = moment().subtract(3, 'days');
    const issues = pickLatestNonViewedIssues(configs, threeDaysAgo);
   
    return {
      hasCostExplorerOptInIssue: hasIssue(
        issues,
        isCostExplorerOpportunityTypeId,
        'Cost Explorer needs to be enabled for this account.'
      ),
      hasCostExplorerRecIssue: hasIssue(
        issues,
        isCostExplorerOpportunityTypeId,
        'Cost Explorer recommendations need to be enabled for this account.'
      ),
      hasComputeOptimizerOptinIssue: hasIssue(
        issues,
        isComputeOptimizerOpportunityTypeId,
        'Compute Optimizer needs to be enabled for this account.'
      ),
      hasComputeOptimizerSavingsIssue: hasIssue(
        issues,
        isComputeOptimizerOpportunityTypeId,
        "Opportunities found with no savings. Cost Explorer 'Receive Amazon EC2 resource recommendations' needs to be enabled from the payer account in Cost Explorer preferences."
      )
    }
  });

  useEffect(() => {
    if (flags.status === 'success'  && Object.values(flags.data).some((flag) => flag)) {
      open();
    }
  }, [configs.status]);

  return (
    <OnPageBanner
      type='warning'
      opened={opened}
      onClose={close}
      className='mb-[10px]'
      title='Unlock More Savings Opportunities'
    >
      {flags.status === 'success' && <>
        {flags.data.hasComputeOptimizerOptinIssue || flags.data.hasComputeOptimizerSavingsIssue &&
          <h3 className='text-subtitle-2 mt-[20px] mb-[10px] font-medium'>AWS Compute Optimizer</h3>
        }

        {flags.data.hasComputeOptimizerOptinIssue &&
          <p className='mt-[10px]'>
            Turn on AWS Compute Optimizer <a className='!text-caladon-blue font-medium' href='https://us-east-1.console.aws.amazon.com/compute-optimizer/home#/dashboard' target='_blank'>here</a>.
            {' '}
            It’s free but must be turned on.
            When it’s turned on, Compute Optimizer recommendations will populate below.
          </p>
        }

        {flags.data.hasComputeOptimizerSavingsIssue &&
          <p className='mt-[10px]'>
            Turn on 'Receive Amazon EC2 resource recommendations' in Cost Explorer preferences <a className='!text-caladon-blue font-medium' href='https://us-east-1.console.aws.amazon.com/cost-management/home#/settings' target='_blank'>here</a>,
            {' '}
            so that AWS Compute Optimizer can calculate monthly estimated savings.
            It’s free but must be turned on.
            When it’s turned on, Compute Optimizer recommendations will populate below with monthly estimate savings values.
          </p>
        }

        {flags.data.hasCostExplorerOptInIssue || flags.data.hasCostExplorerRecIssue && 
          <h3 className='text-subtitle-2 mt-[20px] mb-[10px] font-medium'>AWS Cost Explorer</h3>
        }

        {flags.data.hasCostExplorerOptInIssue &&
          <p className='mt-[10px]'>
            Turn on AWS Cost Explorer <a className='!text-caladon-blue font-medium' href='https://us-east-1.console.aws.amazon.com/cost-management/home#/dashboard' target='_blank'>here</a>.
            {' '}
            It’s free but must be turned on.
          </p>
        }
        
        {flags.data.hasCostExplorerRecIssue &&
          <p className='mt-[10px]'>
            Turn on AWS Cost Explorer Recommendations<a className='!text-caladon-blue' href='https://us-east-1.console.aws.amazon.com/cost-management/home#/settings' target='_blank'>here</a>.
            {' '}
            It’s free but must be turned on.
            When it’s turned on, Cost Explorer Reserved Instance and Savings Plans recommendations will be populated below.
          </p>
        }

        <p className='mt-[20px]'>
          <Link to='/settings/pull-data-collection' className='font-medium !text-caladon-blue'>Manage Data Collection settings</Link>
        </p>
      </>}
    </OnPageBanner>
  );
}
