import { Button2 as Button } from 'shared/Button';
import { Section } from 'shared/Section';
import { TeamRenderer } from 'shared/TeamRenderer';
import { StatusOverlay } from 'shared/Overlay';
import { SimpleTable, SimpleTableRow, SimpleTableCell } from 'shared/SimpleTable';

import { useSavingsStore, useThreadTeamDialog, useThreadInfoDialog, useThreadAssigneeDialog } from 'store/savings';
import { useGlobalState } from 'state/globalState';
import { formatCurrency } from 'helpers/formatter';

export interface ThreadInfoSectionProps {
  id: number;
  className?: string;
}

export const ThreadInfoSection = (props: ThreadInfoSectionProps) => {
  const {
    id,
    className = ''
  } = props;

  const user = useGlobalState((state) => state.user);
  const thread = useSavingsStore((store) => store.getThread(id));
  const openThreadTeamDialog = useThreadTeamDialog((store) => store.open);
  const openThreadInfoDialog = useThreadInfoDialog((store) => store.open);
  const openThreadAssigneeDialog = useThreadAssigneeDialog((store) => store.open);

  return (
    <Section
      className={`relative py-2 px-5 ${className}`}
    >
      <SimpleTable>
        <SimpleTableRow>
          <SimpleTableCell className='w-1/3 align-baseline whitespace-nowrap'>
            <b>Active Savings:</b>
          </SimpleTableCell>

          <SimpleTableCell className='text-right align-baseline'>
            {thread.status === 'success' && <>
              <span className='font-medium text-title-2'>
                {formatCurrency(thread.data.cost_impact_sum * 30)}
              </span>
              {' / month'}
            </>}
          </SimpleTableCell>
        </SimpleTableRow>
      </SimpleTable>

      <SimpleTable className='mt-2'>
        <SimpleTableRow>
          <SimpleTableCell className='w-1/3'>
            <b>Name:</b>
          </SimpleTableCell>

          <SimpleTableCell>
            {thread.status === 'success' &&
              thread.data.name
            }
            {' '}
            <Button
              size='s'
              theme='gray'
              layout='inline'
              icon='edit'
              className='float-right'
              onClick={() => {
                openThreadInfoDialog(id);
              }}
            />
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell>
            <b>Description:</b>
          </SimpleTableCell>

          <SimpleTableCell>
            {thread.status === 'success' &&
              thread.data.body || '–'
            }
            {' '}
            <Button
              size='s'
              theme='gray'
              layout='inline'
              icon='edit'
              className='float-right'
              onClick={() => {
                openThreadInfoDialog(id);
              }}
            />
          </SimpleTableCell>
        </SimpleTableRow>
      </SimpleTable>

      <SimpleTable className='mt-2'>
        <SimpleTableRow>
          <SimpleTableCell className='w-1/3'>
            <b>Team:</b>
          </SimpleTableCell>

          <SimpleTableCell>
            {thread.status === 'success' && thread.data.team_id ?
              <TeamRenderer id={thread.data.team_id} /> :
              '–'
            }
            {user && ['owner', 'admin'].includes(user.role) && <>
              {' '}
              <Button
                size='s'
                theme='gray'
                layout='inline'
                icon='edit'
                className='float-right'
                onClick={() => {
                  openThreadTeamDialog(id);
                }}
              />
            </>}
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell>
            <b>Assignee:</b>
          </SimpleTableCell>

          <SimpleTableCell>
            {thread.status === 'success' &&
              thread.data.assignee?.name || '-'
            }
            {user && ['owner', 'admin'].includes(user.role) && <>
              {' '}
              <Button
                size='s'
                theme='gray'
                layout='inline'
                icon='edit'
                className='float-right'
                onClick={() => {
                  openThreadAssigneeDialog(id);
                }}
              />
            </>}
          </SimpleTableCell>
        </SimpleTableRow>

        <SimpleTableRow>
          <SimpleTableCell>
            <b>Created by:</b>
          </SimpleTableCell>

          <SimpleTableCell>
            {thread.status === 'success' &&
              thread.data.owner?.name || '-'
            }
          </SimpleTableCell>
        </SimpleTableRow>

      </SimpleTable>

      <StatusOverlay status={thread.status} />
    </Section>
  );
}
