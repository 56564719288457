import type { DataCollectionStatus, DataConfig } from 'types/dataCollection';

import moment from 'moment';
import { useState, useMemo } from 'react'
import { Table, message } from 'antd'
import { Icon } from 'shared/Icon';
import { Toggle } from 'shared/Toggle'
import { StatusOverlay } from 'shared/Overlay';
import { DeleteDataConfig } from './DeleteDataConfig';
import { IconButton } from 'shared/Button';
import { useDataConfigWithThresholdsStore } from 'store/dataConfigs/dataConfigsWithThresholds';
import { StatusesTable } from './StatusesTable';
import { NewStatusesBanner } from './NewStatusesBanner';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';


const DataConfigsLibrary = () => {
  const dataConfigs = useDataConfigWithThresholdsStore((store) => store.getLibrary());
  const updateDataConfig = useDataConfigWithThresholdsStore((store) => store.updateEntry);
  const deleteDataConfig = useDataConfigWithThresholdsStore((store) => store.deleteEntry);
  const viewDataConfig = useDataConfigWithThresholdsStore((store) => store.viewEntry);
  const getDetailedEntry = useDataConfigWithThresholdsStore((store) => store.getDetailedEntry);
  const detailedEntries = useDataConfigWithThresholdsStore((store) => store.detailedEntries);

  const [searchText, setSearchText] = useState('');
  const [configToDelete, setConfigToDelete] = useState<DataConfig | null>(null);

  const [statusConfigId, setStatusConfigId] = useState<number | null>(null);
  const detailedConfig = statusConfigId ? detailedEntries.get(statusConfigId) : null

  const detaileddDataConfig = useDerivedDataEntry({
    dataConfigs,
    detailedConfig
  }, ({dataConfigs, detailedConfig}) => {
    if (detailedConfig) {
      const index = dataConfigs.findIndex((c) => c.id === detailedConfig.id)
      dataConfigs[index] = detailedConfig
    }

    return dataConfigs
  })

  const handleSetActive = (config: DataConfig, is_active: boolean) => {
    updateDataConfig(config.id, { is_active });
  };

  const filteredConfigs: DataConfig[] = useMemo(() => {
    if (dataConfigs.status !== 'success') {
      return [];
    }

    if (detaileddDataConfig.status !== 'success') {
      return searchText ? (
        dataConfigs.data.filter((config) => {
          if (config.data_source_id.includes('AWS')) {
            return config.collection_details.aws_account_id
              .concat(config.collection_details.aws_account_name ?? '')
              .toLowerCase().includes(searchText.toLowerCase())
          } else {
            return config.collection_details.project_id.toLowerCase().includes(searchText.toLowerCase())
          }
        }) 
      ) : dataConfigs.data;
    }

    return searchText ? (
      detaileddDataConfig.data.filter((config) => {
        if (config.data_source_id.includes('AWS')) {
          return config.collection_details.aws_account_id
            .concat(config.collection_details.aws_account_name ?? '')
            .toLowerCase().includes(searchText.toLowerCase())
        } else {
          return config.collection_details.project_id.toLowerCase().includes(searchText.toLowerCase())
        }
      }) 
    ) : detaileddDataConfig.data;
  }, [detaileddDataConfig, dataConfigs, searchText]);

  return <>
      <NewStatusesBanner />

      <div className='mt-[20px] mb-[20px] text-subtitle-1 font-medium'>Collection Configs</div>

      <div className='flex mb-[20px]'>
        <div className='flex items-center grow pl-[11px] mb-[20px]'>
          <Icon icon='search' />
          <input
            type='text'
            className='w-full leading-[20px] text-[14px] my-[-5px] py-[10px] pl-[40px] ml-[-29px] bg-transparent outline-none border-b border-b-transparent focus:border-b-cyanine-blue'
            value={searchText} 
            placeholder='Search Account or Project'
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
          />
        </div>
      </div>

      <Table
        rowKey={({ id }) => id}
        size='small'
        dataSource={filteredConfigs.filter((config) => config.data_source_id.includes('AWS'))}
        expandable={{
          onExpand: (expanded, dataConfig) => {
            if (expanded) {
              setStatusConfigId(dataConfig.id)
              getDetailedEntry(dataConfig.id)
            }
          },
          expandedRowRender: (dataConfig) => (
            <div>
              {detaileddDataConfig.status === 'success' && (
                <StatusesTable dataConfig={dataConfig} />
              )}
            </div>
          )
        }}
        columns={[
          {
            key: 'aws_account_id',
            title: 'AWS Account ID',
            width: 400,
            sorter: ({ collection_details: a }, { collection_details: b }) => a.aws_account_id > b.aws_account_id ? 1 : a.aws_account_id < b.aws_account_id ? -1 : 0,
            render: (config) => <div >{
                config.collection_details.aws_account_name ? 
                config.collection_details.aws_account_name :
                config.collection_details.aws_account_id
              }</div>
          },
          {
            key: 'is_active',
            dataIndex: 'is_active',
            title: 'Active',
            sorter: (a) => a.is_active ? 1 : -1,
            render: (is_active, alert) => <Toggle checked={is_active} onChange={() => handleSetActive(alert, !is_active)} />
          },
          {
            key: 'data_source_id',
            title: 'Data Type',
            width: 200,
            dataIndex: 'data_source_id',
            sorter: (a, b) => a.data_source_id > b.data_source_id ? 1 : a.data_source_id < b.data_source_id ? -1 : 0,
            render: (data_source_id) => <div>{data_source_id}</div>
          },  
          {
            key: 'status_last_viewed_at',
            title: 'Last Viewed At',
            width: 200,
            render: (config) => config.status_last_viewed_at ?
              moment(config.status_last_viewed_at).format('YYYY/MM/DD HH:mm') :
              'Never'
          },
          {
            key: 'view',
            width: 32,
            render: (config) => {
              const nonViewed = !config.status_last_viewed_at && config.statuses.length > 0 ||
                config.statuses.some((status: DataCollectionStatus) => status.created_at > config.status_last_viewed_at);

              return nonViewed ?
                <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='eye' onClick={() => viewDataConfig(config.id)} /> :
                null;
            }
          },
          {
            key: 'delete',
            width: 32,
            render: (config) => <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='trash' onClick={() => setConfigToDelete(config)} />
          },
        ]}
      />

      <Table
        rowKey={({ id }) => id}
        size='small'
        dataSource={filteredConfigs.filter((config) => config.data_source_id.includes('GCP'))}
        expandable={{
          expandedRowRender: (dataConfig) => <StatusesTable dataConfig={dataConfig} />
        }}
        columns={[
          {
            key: 'project_id',
            title: 'GCP Project ID',
            width: 400,
            sorter: ({ collection_details: a }, { collection_details: b }) => a.project_id > b.project_id ? 1 : a.project_id < b.project_id ? -1 : 0,
            render: (config) => <div>{config.collection_details.project_id}</div>
          },
          {
            key: 'is_active',
            dataIndex: 'is_active',
            title: 'Active',
            sorter: (a) => a.is_active ? 1 : -1,
            render: (is_active, alert) => <Toggle checked={is_active} onChange={() => handleSetActive(alert, !is_active)} />
          },
          {
            key: 'data_source_id',
            title: 'Data Type',
            width: 200,
            dataIndex: 'data_source_id',
            sorter: (a, b) => a.data_source_id > b.data_source_id ? 1 : a.data_source_id < b.data_source_id ? -1 : 0,
            render: (data_source_id) => <div>{data_source_id}</div>
          },  
          {
            key: 'status_last_viewed_at',
            title: 'Last Viewed At',
            width: 200,
            render: (config) => config.status_last_viewed_at ?
              moment(config.status_last_viewed_at).format('YYYY/MM/DD HH:mm') :
              'Never'
          },
          {
            key: 'view',
            width: 32,
            render: (config) => {
              const nonViewed = !config.status_last_viewed_at && config.statuses.length > 0 ||
                config.statuses.some((status: DataCollectionStatus) => status.created_at > config.status_last_viewed_at);

              return nonViewed ?
                <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='eye' onClick={() => viewDataConfig(config.id)} /> :
                null;
            }
          },
          {
            key: 'delete',
            width: 32,
            render: (config) => <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='trash' onClick={() => setConfigToDelete(config)} />
          },
        ]}
      />

      <StatusOverlay status={detaileddDataConfig.status} />

      <DeleteDataConfig
        open={!!configToDelete}
        onClose={() => setConfigToDelete(null)}
        onConfirm={async () => {
          if (!configToDelete) {
            return;
          }

          try {
            setConfigToDelete(null);
            await deleteDataConfig(configToDelete.id);
            message.success(`Config deleted`);
          } catch {
            message.error(`Unable to delete config`);
          }
        }}
      />
  </>;
}

export default DataConfigsLibrary
