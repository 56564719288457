import { GroupByType } from 'services/metric'

export const GROUPBY: { key: GroupByType, value: string, title: string }[] = [
  {
    key: 'accounts',
    value: 'By Account',
    title: 'account',
  },
  {
    key: 'regions',
    value: 'By Region',
    title: 'region',
  },
  {
    key: 'services',
    value: 'By Service',
    title: 'service',
  },
  {
    key: 'usage_types',
    value: 'By Usage Type',
    title: 'usage type',
  },
  {
    key: 'operations',
    value: 'By Operation',
    title: 'operation',
  },
  {
    key: 'resources',
    value: 'By Resource',
    title: 'resource',
  },
]

export const FIELDSWAP: { key: GroupByType, value: string }[] = [
  {
    key: 'accounts',
    value: 'account',
  },
  {
    key: 'regions',
    value: 'region',
  },
  {
    key: 'services',
    value: 'service',
  },
  {
    key: 'usage_types',
    value: 'usage type',
  },
  {
    key: 'operations',
    value: 'operation',
  },
  {
    key: 'resources',
    value: 'resource',
  },
]

export const FIELD_TITLES: Record<GroupByType, string> = {
  accounts: 'account',
  regions: 'region',
  services: 'service',
  usage_types: 'usage type',
  operations: 'operation',
  resources: 'resource'
}

export const FIELD_TITLES_PLURAL: Record<GroupByType, string> = {
  accounts: 'Accounts',
  regions: 'Regions',
  services: 'Services',
  usage_types: 'Usage Types',
  operations: 'Operations',
  resources: 'Resources'
}

export const DEFAULT_GROUP_BY: Record<GroupByType, GroupByType | null> = {
  accounts: 'regions',
  regions: 'services',
  services: 'usage_types',
  usage_types: 'operations',
  operations: 'resources',
  resources: null
}

export const getDefaultGroupByFor = (field: GroupByType) => DEFAULT_GROUP_BY[field]
