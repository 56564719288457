import type {
  CostImpactSummaryParams,
  CostImpactSummaryStoreKey,
  CostImpactSummaryStoreKeyProps,
  CostImpactSummaryStoreKeyFactory,
  CostImpactSummaryParamsChunk,
  CostImpactSummaryStoreKeyChunk,
  CostImpactSummaryStoreKeyPropsChunk,
  CostImpactSummaryStoreKeyFactoryChunk,
  SavedAndWastedParams,
  SavedAndWastedStoreKey,
  SavedAndWastedStoreKeyProps,
  SavedAndWastedStoreKeyFactory,
  SavedAndWastedParamsChunk,
  SavedAndWastedStoreKeyChunk,
  SavedAndWastedStoreKeyPropsChunk,
  SavedAndWastedStoreKeyFactoryChunk
} from 'types/savings';

import { Record as ImmutableRecord, Set as ImmutableSet } from 'immutable';

import {
  createPageFiltersQueryKey,
  createDedupeBodyKey,
  createPrefilterQueryKey,
  createTableFiltersQueryKey,
  pageFiltersQueryToKey,
  dedupeBodyToKey,
  prefilterQueryToKey,
  tableFiltersQueryToKey,
} from './storeKeys';

import { 
  costImpactSummaryParamsToRequestParams,
  costImpactSummaryParamsToRequestParamsChunk,
  savedAndWastedParamsToRequestParams,
  savedAndWastedParamsToRequestParamsChunk,
} from './summaryRequest';

const createCostImpactSummaryStoreKey: CostImpactSummaryStoreKeyFactory = ImmutableRecord<CostImpactSummaryStoreKeyProps>({
  pageFilters: createPageFiltersQueryKey(),
  dedupeBody: createDedupeBodyKey(),
  prefilter: createPrefilterQueryKey(),
  tableFilters: createTableFiltersQueryKey(),
  groupBy: null,
});

export const costImpactSummaryParamsToKey = (params: CostImpactSummaryParams): CostImpactSummaryStoreKey => {
  const [query, body] = costImpactSummaryParamsToRequestParams(params);

  const pageFilters = pageFiltersQueryToKey(query);
  const dedupeBody = dedupeBodyToKey(body);
  const prefilter = prefilterQueryToKey(query);
  const tableFilters = tableFiltersQueryToKey(query);
  return createCostImpactSummaryStoreKey({
    ...params,
    pageFilters,
    dedupeBody,
    prefilter,
    tableFilters,
  });
}

const createCostImpactSummaryStoreKeyChunk: CostImpactSummaryStoreKeyFactoryChunk = ImmutableRecord<CostImpactSummaryStoreKeyPropsChunk>({
  pageFilters: createPageFiltersQueryKey(),
  dedupeBody: createDedupeBodyKey(),
  prefilter: createPrefilterQueryKey(),
  tableFilters: createTableFiltersQueryKey(),
  groupBy: null,
  teamChunks: null,
});

export const costImpactSummaryParamsToKeyChunk = (params: CostImpactSummaryParamsChunk): CostImpactSummaryStoreKeyChunk => {
  const queries = costImpactSummaryParamsToRequestParamsChunk(params);
  if (queries.length === 0) {
    return createCostImpactSummaryStoreKeyChunk()
  }
  const static_entry_query = queries[0][0]
  const static_entry_body = queries[0][1]

  const teamChunks = ImmutableSet(params.teamChunks.reduce((acc, teamChunk) => acc.concat(teamChunk), []));
  const pageFilters = pageFiltersQueryToKey(static_entry_query);
  const dedupeBody = dedupeBodyToKey(static_entry_body);
  const prefilter = prefilterQueryToKey(static_entry_query);
  const tableFilters = tableFiltersQueryToKey(static_entry_query);
  return createCostImpactSummaryStoreKeyChunk({
    ...params,
    teamChunks,
    pageFilters,
    dedupeBody,
    prefilter,
    tableFilters,
  });
}

const createSavedAndWastedStoreKey: SavedAndWastedStoreKeyFactory = ImmutableRecord<SavedAndWastedStoreKeyProps>({
  pageFilters: createPageFiltersQueryKey(),
  dedupeBody: createDedupeBodyKey(),
  prefilter: createPrefilterQueryKey(),
  tableFilters: createTableFiltersQueryKey(),
  startDate: '',
  endDate: '',
  granularity: null,
  groupBy: null,
  teamsDeduplication: null,
});

export const savedAndWastedParamsToKey = (params: SavedAndWastedParams): SavedAndWastedStoreKey => {
  const [query, body] = savedAndWastedParamsToRequestParams(params);

  const pageFilters = pageFiltersQueryToKey(query);
  const dedupeBody = dedupeBodyToKey(body);
  const prefilter = prefilterQueryToKey(query);
  const tableFilters = tableFiltersQueryToKey(query);

  return createSavedAndWastedStoreKey({
    ...params,
    pageFilters,
    dedupeBody,
    prefilter,
    tableFilters,
  });
}

const createSavedAndWastedStoreKeyChunk: SavedAndWastedStoreKeyFactoryChunk = ImmutableRecord<SavedAndWastedStoreKeyPropsChunk>({
  pageFilters: createPageFiltersQueryKey(),
  dedupeBody: createDedupeBodyKey(),
  prefilter: createPrefilterQueryKey(),
  tableFilters: createTableFiltersQueryKey(),
  startDate: '',
  endDate: '',
  granularity: null,
  groupBy: null,
  teamChunks: null,
  teamsDeduplication: null,
});

export const savedAndWastedParamsToKeyChunk = (params: SavedAndWastedParamsChunk): SavedAndWastedStoreKeyChunk => {
  const queries = savedAndWastedParamsToRequestParamsChunk(params);
  if (queries.length === 0) {
    return createSavedAndWastedStoreKeyChunk()
  }
  const static_entry_query = queries[0][0]
  const static_entry_body = queries[0][1]

  const teamChunks = ImmutableSet(params.teamChunks.reduce((acc, teamChunk) => acc.concat(teamChunk), []));
  const pageFilters = pageFiltersQueryToKey(static_entry_query);
  const dedupeBody = dedupeBodyToKey(static_entry_body);
  const prefilter = prefilterQueryToKey(static_entry_query);
  const tableFilters = tableFiltersQueryToKey(static_entry_query);

  return createSavedAndWastedStoreKeyChunk({
    ...params,
    teamChunks,
    pageFilters,
    dedupeBody,
    prefilter,
    tableFilters,
  });
}
