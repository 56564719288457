import type { Tags } from 'types/tags';

import { Table } from 'antd';
import { IconButton } from 'shared/Button';
import { TagValueSelect } from './TagValueSelect';

export interface TagTableProps {
  tags: Tags;
  disabled?: boolean;
  onChange: (tags: Tags) => void;
}

export const TagTable = (props: TagTableProps) => {
  const {
    tags,
    onChange
  } = props;

  const tagsList = Object.entries(tags);
  
  return (
    <Table
      size='small'
      columns={[
        {
          key: 'key',
          title: 'Tag Key',
          dataIndex: 0
        },
        {
          className: 'w-[50%]',
          key: 'value',
          title: 'Tag Value',
          dataIndex: 1,
          render: (_, [key, value]) => <TagValueSelect
            tagKey={key}
            value={value}
            onChange={(value) => {
              onChange({
                ...tags,
                [key]: value
              });
            }}
          />
        },
        {
          key: 'delete',
          className: 'w-[24px]',
          render: (_, [key]) =>
            <IconButton
              icon='trash'
              size='sm'
              type='tertiary'
              onClick={() => {
                const newTags = { ...tags };

                delete newTags[key];

                onChange(newTags);
              }}
            />
        }
      ]}
      dataSource={tagsList} 
    />
  );
};
