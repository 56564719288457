import { useState, cloneElement } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Icon, IconName } from 'shared/Icon/Icon'
import { SubItem, SubItemProps } from './SubItem'
import { useGlobalState } from '../../state/globalState'
import { useHover } from '../../hooks/useHover'

export interface MenuItem {
  icon: IconName
  title: string
  route: string
  subItems?: SubItemProps[]
  disabled?: boolean
}

export const Item = ({ item, onClick }: { item: MenuItem, onClick?: () => void }) => {
  const location = useLocation().pathname
  const { sideMenuCollapsed, logEvent } = useGlobalState()
  const [subMenuOpened, setSubMenuOpened] = useState(false)

  const { setPopoverRef, setTargetRef } = useHover(
    () => setSubMenuOpened(true),
    () => setSubMenuOpened(false),
  )

  let isActive = false;

  if (!item.subItems) {
    const path = `/${item.route.split('/').filter((v) => !!v).shift()}`
    isActive = location.startsWith(path)
  } else {
    isActive = item.subItems.some(({ route, matches }) => 
      new RegExp(matches || `^${route}$`).test(location)
    );
  }

  //const [expanded, setExpanded] = useState(isActive)
  const expanded = true;

  const buttonClassName = [
    'flex grow-1 items-center h-9 overflow-hidden cursor-pointer whitespace-nowrap font-semibold hover:text-blue',
    sideMenuCollapsed ? 'w-10 justify-center rounded' : 'mx-2.5',
    isActive && 'text-blue',
    item.disabled && 'pointer-events-none opacity-50'
  ].filter(Boolean).join(' ');

  const button = item.route ? <Link to={item.route} /> : <button type='button' />;

  const handleClick = () => {
    logEvent('side_menu_click', { route: item.route, title: item.title });
    onClick && onClick();
  }

  return (
    <div className='flex flex-col items-start text-body-2'>
      {cloneElement(button, {
        className: buttonClassName,
        onClick: handleClick,
        ref: setTargetRef
      }, (
        <>
          <Icon className={`${!sideMenuCollapsed && 'mr-2'} w-[20px] h-[20px] shrink-0`} icon={item.icon} />
          {!sideMenuCollapsed && item.title}
        </>
      ))}
      {!sideMenuCollapsed
        && expanded
        && item.subItems?.map((subProps) => (
          <SubItem
            key={subProps.route}
            {...subProps}
          />
        ))
      }

      {sideMenuCollapsed && subMenuOpened && (
        <div
          className='absolute flex-col flex left-[55px] z-10 p-[5px] gap-[5px] bg-white rounded-[8px] border border-grey-transparent'
          ref={setPopoverRef}
          onClick={() => setSubMenuOpened(false)}
        >
          <span
            className={`whitespace-nowrap uppercase font-medium px-[10px] py-[7px] text-[10px] leading-[12px] text-silver-grey-700`}
          >
            {item.title}
          </span>

          {item.subItems?.map((subProps) => (
            <SubItem
              key={subProps.route}
              {...subProps}
            />
          ))}

        </div>
      )}
    </div>
  );
};
