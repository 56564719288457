import { useState } from 'react'
import { Select } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button } from 'shared/Button'
import { useDataStreamConfigStore } from 'store/dataStreamConfigs';
export type StreamType = 'custom' | 'kubernetes'

const streamTypeOptions = [
  { value: 'custom', label: 'Custom'},
  { value: 'kubernetes', label: 'Kubernetes'},
]

export interface CreateDialogProps {
  opened: boolean;
  onClose: (search?: string) => void;
}

export const CreateDialog = (props: CreateDialogProps) => {
  const {
    opened,
    onClose,
  } = props;
  const { saveDataStreamConfig } = useDataStreamConfigStore();
  const [streamType, setStreamType] = useState<StreamType>('custom')

  const handleChangeStreamType = (option: any) => {
    setStreamType(option)
  }

  const handleClose = (search?: string) => {
    onClose(search);
  };

  return (
    <Dialog
      open={opened}
      onClose={() => handleClose()}
      className='w-[510px] !overflow-visible'
    >
      <Dialog.Title>
      {'Create New Data Stream'}
      </Dialog.Title>

      <div className='px-[30px] pb-[30px]'>
        <div className='mb-[30px]'>
          <div className='mb-2 font-medium text-[16px]'>
            Stream Type
          </div>
          <Select
            className='w-full mt-2'
            onChange={handleChangeStreamType}
            value={streamType}
            options={streamTypeOptions}
          />
        </div>
        <div className='flex justify-end'>
          <Button
            label='Cancel'
            type='tertiary'
            style='caladon'
            onClick={() => handleClose()}
          />
          <Button
            label='Save Data Stream'
            type='primary'
            style='caladon'
            onClick={async () => {
              const config = {
                data_stream_id: streamType,
              }

              saveDataStreamConfig(config);
              handleClose();
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default CreateDialog
