import type { FetchStatus } from 'types/common';
import type { CostViewFilter } from 'types/costViews';
import type { TagView } from 'types/tagViews'

import create from 'zustand';
import moment from 'moment';
import {
  fetchTagView
} from 'services/tagViews';

import {
  GroupByType,
  saveTagView,
  updateTagView,
  deleteTagView,
} from 'services/metric';
import { SERVER_DATE_FORMAT } from 'helpers/constants'

type DrillDownStep = [GroupByType, string];

interface ReturnAction {
  type: 'saveAsNew' | 'drillDown';
}

interface TagOverviewState {
  id: number | null;
  view: TagView | null;
  viewStatus: FetchStatus;
  newViewName: string;
  drillDownOptions: Record<string, { value: string, label: string }>;
  drillDownOpened: boolean;
  chartCollapsed: boolean;
  filterParam: CostViewFilter;
  filterChanged: boolean;
  startDate: string;
  endDate: string;
  returnAction: ReturnAction | null;
}

interface TagOverviewActions {
  setNewViewName: (name: string) => void;
  setDrillDownOpened: (opened: boolean) => void;
  setChartCollapsed: (collapsed: boolean) => void;
  setDrillDown: (drillDownSteps: DrillDownStep[]) => void;
  setPeriod: (
    startDate: string,
    endDate: string,
  ) => void;
  isFiltersApplied: () => boolean;
  setFilter: (filter: CostViewFilter) => void;
  updateFilter: (filter: CostViewFilter) => void;
  saveView: () => Promise<TagView>;
  updateView: () => Promise<void>;
  deleteView: () => Promise<void>;
  fetchView: (id: number | null) => Promise<void>;
}

interface TagOverviewStore extends TagOverviewState, TagOverviewActions {};

export const EMPTY_FILTER: CostViewFilter = {
  src: 'cur',
  filter: { operator: 'or', condition: [] }
};

const DEFAULT_STATE: TagOverviewState = {
  id: null,
  view: null,
  viewStatus: 'loading',
  newViewName: '',
  drillDownOptions: {},
  drillDownOpened: false,
  chartCollapsed: false,
  filterParam: EMPTY_FILTER,
  filterChanged: false,
  startDate: moment().subtract(7, 'days').format(SERVER_DATE_FORMAT),
  endDate: moment().subtract(1, 'days').format(SERVER_DATE_FORMAT),
  returnAction: null,
}

export const useTagOverviewStore = create<TagOverviewStore>((set, get) => {
  return ({
    ...DEFAULT_STATE,

    setDrillDownOpened: (drillDownOpened: boolean) => {
      set({ drillDownOpened })
    },

    setChartCollapsed: (chartCollapsed: boolean) => {
      set({chartCollapsed})
    },

    isFiltersApplied: () => {
      const { filterParam } = get()
      return filterParam && !!filterParam.filter.condition.length
    },

    setDrillDown: async (drillDownSteps) => {
      set({
        drillDownOpened: drillDownSteps.length > 0
      });
    },

    saveView: async () => {
      const { newViewName, filterParam } = get();
      const { data: view } = await saveTagView(newViewName, filterParam);

      return view;
    },

    updateView: async () => {
      const { view, newViewName, filterParam } = get();

      if (!view) {
        return;
      }
      const name = newViewName !== '' && newViewName !== view.name ? newViewName : view.name
      const { data: { filter }} = await updateTagView(view.id, name, filterParam)

      set({ view: { ...view, filter } })
    },

    deleteView: async () => {
      const { view } = get();

      if (!view) {
        return;
      }

      try {
        await deleteTagView(view.id)
      } catch {
      }
    },

    setPeriod: async (
      startDate: string,
      endDate: string,
    ) => {
      set({ startDate, endDate })
    },

    setFilter: (filter: CostViewFilter) => {
      set({
        filterChanged: false,
        filterParam: filter
      })
    },

    updateFilter: (filter: CostViewFilter) => {
      set({
        filterChanged: true,
        filterParam: filter
      })
    },

    fetchView: async (id) => {
      set({ id });

      if (id === null) {
        set({
          view: null,
          viewStatus: 'success',
        });

      } else {
        try {
          set({ viewStatus: 'loading' });

          const { data: view } = await fetchTagView(id);

          set({
            view,
            viewStatus: 'success',
            filterParam: view.filter
          });
        } catch {
          set({ viewStatus: 'error' });
        }
      }
    },

    setNewViewName: (newViewName: string) => {
      set({ newViewName });
    }
  });
});
