import type {
  RateOptKeyProps,
  RateOptKeyFactory,

  ImmutableDedupeBody,
  ImmutableDedupeBodyProps,
  ImmutableDedupeBodyFactory,

  ImmutablePageFiltersQuery,
  ImmutablePageFiltersQueryProps,
  ImmutablePageFiltersQueryFactory,
  ImmutablePrefilterQuery,
  ImmutablePrefilterQueryProps,
  ImmutablePrefilterQueryFactory,
  ImmutableTableFiltersQueryFactory,
  ImmutableTableFiltersQueryProps,
  ImmutableTableFiltersQuery,
  ImmutableSortQueryProps,
  ImmutableSortQueryFactory,
  ImmutableSortQuery,
  ImmutablePagingQueryProps,
  ImmutablePagingQueryFactory,
  ImmutablePagingQuery
} from 'types/savings';

import { Record as ImmutableRecord, Set as ImmutableSet } from 'immutable';
import { OpportunityDedupeBody, OpportunityPageFiltersQuery, OpportunityPagingQuery, OpportunityPrefilterQuery, OpportunitySortQuery, OpportunityTableFiltersQuery } from 'types/savings/query';

const defaultRateOptKeyProps: RateOptKeyProps = {
  term: '',
  payment_opt: '',
  lookback_days: '',
  offering_class: '',
};

export const createRateOptKey: RateOptKeyFactory = ImmutableRecord(defaultRateOptKeyProps);

const defaultDedupeBodyProps: ImmutableDedupeBodyProps = {
  source: '',
  savings_plans: null,
  reserved_instances: null
};

export const createDedupeBodyKey: ImmutableDedupeBodyFactory = ImmutableRecord<ImmutableDedupeBodyProps>(defaultDedupeBodyProps);

export const dedupeBodyToKey = (body: OpportunityDedupeBody): ImmutableDedupeBody => {
  const {
    source,
    savings_plans,
    reserved_instances
  } = body;

  return createDedupeBodyKey({
    source,
    savings_plans: savings_plans && createRateOptKey(savings_plans),
    reserved_instances: reserved_instances && createRateOptKey(reserved_instances)
  });
};

const defaultPageFiltersQueryProps: ImmutablePageFiltersQueryProps = {
  team_id: null,
  platform: null,
  priority: null,
  meta_category: null,
  global_filter_ids: null
};

export const createPageFiltersQueryKey: ImmutablePageFiltersQueryFactory = ImmutableRecord<ImmutablePageFiltersQueryProps>(defaultPageFiltersQueryProps);

export const pageFiltersQueryToKey = (query: OpportunityPageFiltersQuery): ImmutablePageFiltersQuery => {
  const {
    team_id,
    platform,
    priority,
    meta_category,
    global_filter_ids
  } = query;

  return createPageFiltersQueryKey({
    team_id: team_id || null,
    platform: platform ? ImmutableSet(platform) : null,
    priority: priority ? ImmutableSet(priority) : null,
    meta_category: meta_category ? ImmutableSet(meta_category) : null,
    global_filter_ids: global_filter_ids ? ImmutableSet(global_filter_ids) : null
  });
};

const defaultPrefilterQueryProps: ImmutablePrefilterQueryProps = {
  thread_id: null,
  team_id: null,
  status: null,
  created_at_start_date: null,
  created_at_end_date: null
};

export const createPrefilterQueryKey: ImmutablePrefilterQueryFactory = ImmutableRecord<ImmutablePrefilterQueryProps>(defaultPrefilterQueryProps);

export const prefilterQueryToKey = (query: OpportunityPrefilterQuery): ImmutablePrefilterQuery => {
  const {
    thread_id,
    team_id,
    status,
    created_at_start_date,
    created_at_end_date
  } = query;

  return createPrefilterQueryKey({
    thread_id: thread_id || null,
    team_id: team_id || null,
    status: status ? ImmutableSet(status) : null,
    created_at_start_date: created_at_start_date || null,
    created_at_end_date: created_at_end_date || null,
  });
}

const defaultTableFilterQueryProps: ImmutableTableFiltersQueryProps = {
  text_search: null,
  difficulty: null,
  category: null,
  account: null,
  region: null,
  service: null,
  tags: null,
  account_tags: null
};

export const createTableFiltersQueryKey: ImmutableTableFiltersQueryFactory = ImmutableRecord<ImmutableTableFiltersQueryProps>(defaultTableFilterQueryProps);

export const tableFiltersQueryToKey = (query: OpportunityTableFiltersQuery): ImmutableTableFiltersQuery => {
  const {
    text_search,
    difficulty,
    category,
    account,
    region,
    service,
    tags,
    account_tags
  } = query;

  return createTableFiltersQueryKey({
    text_search: text_search || null,
    difficulty: difficulty ? ImmutableSet(difficulty) : null,
    category: category ? ImmutableSet(category) : null,
    account: account ? ImmutableSet(account) : null,
    region: region ? ImmutableSet(region) : null,
    service: service ? ImmutableSet(service) : null,
    tags: tags ? ImmutableSet(tags) : null,
    account_tags: account_tags ? ImmutableSet(account_tags) : null,
  });
};

const defaultSortQueryProps: ImmutableSortQueryProps = {
  order_by: null,
  order_desc: null
};

export const createSortQueryKey: ImmutableSortQueryFactory = ImmutableRecord<ImmutableSortQueryProps>(defaultSortQueryProps);

export const sortQueryToKey = (query: OpportunitySortQuery): ImmutableSortQuery => {
  const {
    order_by,
    order_desc
  } = query;

  return createSortQueryKey({
    order_by: order_by || null,
    order_desc: order_desc || null,
  });
};

const defaultPagingQueryProps: ImmutablePagingQueryProps = {
  paging_size: 10,
  paging_last_id: null
};

export const createPagingQueryKey: ImmutablePagingQueryFactory = ImmutableRecord<ImmutablePagingQueryProps>(defaultPagingQueryProps);

export const pagingQueryToKey = (query: OpportunityPagingQuery): ImmutablePagingQuery => {
  const {
    paging_size,
    paging_last_id
  } = query;

  return createPagingQueryKey({
    paging_size: paging_size || null,
    paging_last_id: paging_last_id || null
  });
};
