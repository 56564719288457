import { Select } from 'antd';

export interface TagPartSelectProps {
  value: string;
  options: string[];
  disabled?: boolean;
  onChange: (value: string) => void;
  onCreate: (value: string) => void;
}

export const TagPartSelect = (props: TagPartSelectProps) => {
  const {
    value,
    options,
    disabled,
    onChange
  } = props;
  
  return (
    <Select
      className='w-[232px]'
      value={value}
      disabled={disabled}
      options={Array.from(new Set(options)).map((value) => ({ label: value, value }))}
      onChange={onChange}
    />
  );
};
