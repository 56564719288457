export interface ToggleProps {
  className?: string;
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
}

export const Toggle = (props: ToggleProps) => {
  const {
    className,
    checked,
    onChange,
    disabled,
  } = props;

  const trackClassName = `${className} inline-flex shrink-0 w-[44px] h-[24px] rounded-[15px] cursor-pointer ${checked  ? 'bg-blue hover:bg-blue-hover' : 'bg-gray-100 hover:bg-gray-200'}`
  const thumbClassName = `rounded-full w-[20px] h-[20px] m-[2px] bg-white transition duration-300 ${checked ? 'translate-x-[20px]' : ''}`

  const handleChange = () => {
    onChange(!checked)
  }

  return (
    <button className={`disabled:opacity-50 ${trackClassName}`} onClick={handleChange} type="button" disabled={disabled}>
      <div className={thumbClassName} />
    </button>
  );
};
