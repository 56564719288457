import create from 'zustand'

import type { FetchStatus } from 'types/common';
import type { EventsData } from 'types/costViews/events';

import { getEvents, getEventTypes } from 'services/metric'

interface FetchParams {
  force?: boolean;
  startDate: string | null;
  endDate: string | null;
}

export interface EventsState {
  status: FetchStatus;
  typeStatus: FetchStatus;
  events: EventsData[];
  eventTypes: string[];
  selectedEventTypes: string[];
}

export interface EventActions {
  fetchEvents: (params?: FetchParams) => void;
  fetchEventTypes: (params?: FetchParams) => void;
  setEventTypes: (types: string[]) => void;
}

export interface EventStore extends EventsState, EventActions {}

const DEFAULT_STATE: EventsState = {
  status: 'idle',
  typeStatus: 'idle',
  events: [],
  eventTypes: [],
  selectedEventTypes: [],
};

export const useEventStore = create<EventStore>((set, get) => {
  let requestCount = 0;
  let typeRequestCount = 0;

  return {
    ...DEFAULT_STATE,

    fetchEvents: async (params = { startDate: null, endDate: null }) => {
      const { force, startDate, endDate } = params;
      const { status } = get();

      /*
      if (!force && ['loading', 'success'].includes(status)) {
        return;
      }
      */

      try {
        if (status !== 'success') {
          set({ status: 'loading' });
        }

        const requestNumber = ++requestCount;
        if (startDate && endDate) {
          const { data: events } = await getEvents({ startDate, endDate });
          if (requestNumber === requestCount) {
            set({
              events,
              status: 'success'
            });
          }
        }
      } catch(error) {
        set({ status: 'error' });
      }
    },

    fetchEventTypes: async (params = { startDate: null, endDate: null }) => {
      const { force, startDate, endDate } = params;
      const { typeStatus } = get();

      try {
        if (typeStatus !== 'success') {
          set({ typeStatus: 'loading' });
        }

        const requestNumber = ++typeRequestCount;
        if (startDate && endDate) {
          const { data: eventTypes } = await getEventTypes({ startDate, endDate });
          if (requestNumber === typeRequestCount) {
            set({
              eventTypes,
              selectedEventTypes: eventTypes,
            });
          }
        }
      } catch(error) {
        set({ typeStatus: 'error' });
      }
    },

    setEventTypes: (types: string[]) => {
      set({ selectedEventTypes: types })
    },
  };
});
