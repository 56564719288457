const specialChars = ['/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\'];
const specialPattern = `(\\${specialChars.join('|\\')})`;
const specialRegExp = new RegExp(specialPattern, 'g');

export function escapePattern(pattern: string): string {
  const escapedPattern = pattern.replace(specialRegExp, '\\$1');

  return escapedPattern;
}

export function likeToRegExp(pattern: string): RegExp {
  const escapedPattern = escapePattern(pattern);
  const likePattern = `^${escapedPattern.replace(/_/g, '.').replace(/%/g, '.*')}$`;

  const likeRegExp = new RegExp(likePattern, 'ig');

  return likeRegExp;
}

export function matchLike(pattern: string, str: string): boolean {
  const likeRegExp = likeToRegExp(pattern);

  return likeRegExp.test(str);
}
