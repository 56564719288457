import type { TagListFilterFetchPararms } from 'types/tagViews';
import { useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useTagListValue = (): string[] => {
  const [searchParams] = useSearchParams();
  const tagListParam = searchParams.get('tagList');

  return useMemo(() => {
    const tagList: string[] = (tagListParam || '').split(',')

    return tagList;
  }, [tagListParam]);
}

type Setter<T> = (searchParams: URLSearchParams, value: T) => void;

export const useTagListFilterValue = (): TagListFilterFetchPararms => ({
  tagList: useTagListValue(),
});

const setTagList: Setter<string[]> = (searchParams, tagList) => {
  if (tagList.length === 0) {
    searchParams.delete('tagList');
  } else {
    searchParams.set('tagList', tagList.join(','));
  }
}

const setTagListFilter: Setter<TagListFilterFetchPararms> = (searchParams, tagListFilter) => {
  setTagList(searchParams, tagListFilter.tagList);
}

const createSetterHook = <T>(setter: ((searchParams: URLSearchParams, value: T) => void)) =>
  (): ((value: T) => void) => {
    const [searchParams, setSearchParams] = useSearchParams();
    
    return useCallback((newValue: T) => {
      const newSearchParams = new URLSearchParams(searchParams);

      setter(newSearchParams, newValue);

      setSearchParams(newSearchParams);
    }, [searchParams]);
  }

export const useTagListSetter = createSetterHook(setTagList);
export const useTagListFilterSetter = createSetterHook(setTagListFilter);

const combine = <T>(getter: () => T, setter: () => ((value: T) => void)):
  (() => [T, (value: T) => void]) =>
    () => [getter(), setter()];

export const useTagList = combine(useTagListValue, useTagListSetter);
export const useTagListFilter = combine(useTagListFilterValue, useTagListFilterSetter);
