import { IntegrationProvider, isJiraProvider } from 'types/users';
import { INTEGRATION_PROVIDERS, isIntegrationProvider } from 'types/users';

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { message } from 'antd';
import { Icon } from 'shared/Icon';
import { Button } from 'shared/Button';
import { Loading } from 'shared/Loading';
import { getAuthorizeURL } from 'services/integrations';
import { useIntegrationsStore } from 'store/integrations';
import { useJiraServerCreateDialog } from 'store/integrations/jiraServerCreateDialog'
import { JiraIssueCreateDialog } from './JiraSeverCreateDialog'
import { JiraWebhook } from './JiraWebhook';

export const TITLES: Record<IntegrationProvider, string> = {
  slack: 'Slack',
  'jira_cloud': 'JIRA Cloud',
  'jira_server': 'JIRA Server',
};

export const IntegrationsTab = () => {
  const [searchParams] = useSearchParams();
  const validate = useIntegrationsStore((store) => store.validate);
  const integrations = useIntegrationsStore((store) => store.getIntegrations());
  const openJiraServerCreateDialog = useJiraServerCreateDialog((store) => store.open);

  const handleIntegration = (url: string) => {
    window.location.href = url
  }

  useEffect(() => {
    if (integrations.status === 'success') {
      const provider = searchParams.get('provider');
      const code = searchParams.get('code');

      if (isIntegrationProvider(provider) && code) {
        validate(provider, code)
          .then(() => {
            message.success(`${TITLES[provider]} integrated`);
          })
          .catch(() => {
            message.error(`Unable to integrate ${TITLES[provider]}`);
          });
      }
    }
  }, [integrations.status]);

  if (integrations.status === 'loading' || integrations.status === 'idle') {
    return <div className='relative h-[240px]'>
      <Loading />
    </div>;
  }

  if (integrations.status === 'error') {
    return <span>Error</span>;
  }

  return <>
    <ul>
      {INTEGRATION_PROVIDERS.map((provider: IntegrationProvider) => {
        const integration = integrations.data.find((item) => item.provider === provider);

        return <li
          key={provider}
          className='mt-4 border rounded-[10px] w-[50%] min-h-[80px]'
        >
          <div className='flex items-center text-[15px] font-medium p-4 '>
            <Icon icon={provider} className={['jira_cloud', 'jira_server'].includes(provider) ? 'w-[54px] h-[54px] m-[-4px]' : 'w-[46px] h-[46px]'} />

            <span className='ml-4 mr-auto'>
              {TITLES[provider]}
            </span>

            {integration && integration.status === 'active' ? (
              <span className='text-positive px-[15px] text-center'>
                Integrated
              </span>
            ) : (
              <Button
                onClick={() => {
                  if (provider == 'jira_server') {
                    openJiraServerCreateDialog()
                  } else {
                    handleIntegration(getAuthorizeURL(provider))
                  }
                }}
                label={integration ? 'Re-Integrate' : 'Integrate'}
              />
            )}
          </div>
          {integration && integration.status === 'active' && isJiraProvider(provider) && 
          <>
            <hr/>
            <JiraWebhook provider={provider} />
          </>
          }

        </li>;
      })}
    </ul>

    <JiraIssueCreateDialog clientId={''} />
  </>;
}
