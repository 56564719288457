import { useNavigate } from 'react-router-dom'
import { ReactComponent as LogoLines } from 'assets/images/LogoLines.svg'
import { Section } from 'shared/Section'
import { Icon } from 'shared/Icon/Icon'

export const CfnRunning = () => {
  const navigate = useNavigate()

  return (
    <Section className="flex flex-col items-center p-[50px]">
      <LogoLines />
      <h5 className="text-center mt-[30px]">We are looking into your infrastrucure </h5>
      <p className="subtitle-2 text-center mt-[30px] font-medium">
        Cloudthread is gathering the initial data on your AWS infrastructure.
        <br />
        It usually takes several minutes.
        <br />
        <div className='mt-5 mb-3'>
          <a className='text-cyanine-blue' onClick={() => navigate('/onboarding')}>Go Back to CloudFormation selection</a>
        </div>
        <br />
        Status:
        {' '}
        <span className="text-caladon-blue">Waiting</span>
      </p>
      <Icon className="mt-[50px] w-[40px] h-[40px] text-cyanine-blue" icon="spinner" spin />
    </Section>
  )
}
