import { Icon } from 'shared/Icon';

export interface AddWidgetButtonProps {
  onClick: () => void;
}

export const AddWidgetButton = (props: AddWidgetButtonProps) => {
  const {
    onClick
  } = props;

  return (
    <button
      className='flex grow items-center justify-center min-h-[320px] text-[24px] font-medium rounded-lg text-silver-grey-500 hover:text-cyanine-blue hover:bg-white border border-dashed border-[4px] border-current'
      onClick={onClick}
    >
      <Icon
        icon='circle-plus'
        className='mr-[10px] w-[24px] h-[24px]'
      />
      Add Widget
    </button>
  );
};
