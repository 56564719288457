import type { Granularity } from 'types/common';
import type { DataEntry } from 'types/dataEntries';
import type { DrillDownData } from 'types/tagViews/drillDown';
import type { GroupByType } from 'services/metric';
import type { ChartType } from 'types/chart'
import type { Moment } from 'moment';

import { useState, useMemo } from 'react';
import { Section } from 'shared/Section';
import { Select } from 'antd';
import { useTagOverviewStore } from '../useTagOverviewStore';
import { AbsoluteCostsChart } from './AbsoluteTagsChart';
import { FIELD_TITLES_PLURAL } from '../../CostsOverview/groupByOptions';
import { BreakdownTable } from './BreakdownTable';
import { HeaderStatus } from './HeaderStatus';
import { Button } from 'shared/Button';
import { useTagKey } from 'hooks/useTagKey';
import { useCostViewConstructor } from 'store/costViewConstructor';

export interface BreakdownProps {
  startDate: string;
  endDate: string;
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
  granularity: Granularity;
  className?: string;
  breakdown: GroupByType;
  drillDown: DataEntry<DrillDownData>;
  drillDownEnabled: boolean;
  availableBreakdowns: GroupByType[];
  setBreakdown: (breakdown: GroupByType) => void;
  setCurrentStep: (value: string) => void;
}

export const Breakdown = (props: BreakdownProps) => {
  const {
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment,
    granularity,
    className = '',
    breakdown,
    drillDown,
    drillDownEnabled,
    availableBreakdowns,
    setBreakdown,
    setCurrentStep,
  } = props;

  const [
    tagKey,
    setTagKey,
  ] = useTagKey();

  const costViewConstructor = useCostViewConstructor();

  const tags = useMemo(() => {
    if (costViewConstructor.status === 'success') {
      return ((costViewConstructor.data.cur?.rules.tags?.options || []) as string[]).map((key) => ({ key, value: key }));
    }

    return [];
  }, [costViewConstructor]);


  const {
    drillDownOpened,
    chartCollapsed,
    setDrillDownOpened,
    setChartCollapsed,
  } = useTagOverviewStore()

  //const logEvent = useGlobalState((state) => state.logEvent);

  const [chartType, setChartType] = useState<ChartType>('line')

  const options = availableBreakdowns.length ? 
    availableBreakdowns.map((value) => ({ value, label: FIELD_TITLES_PLURAL[value] })) :
    [{ value: breakdown, label: FIELD_TITLES_PLURAL[breakdown]}];

  return (
    <div className={`overflow-hidden ${className}`}>
      <Section className="mb-[15px]">
        <Section.Head>
          <div className='overflow-hidden font-medium text-ellipsis'>Tag Coverage - Breakdown</div>
          <div className="mx-2">
            <div className='flex'>
                <Select
                  className="min-w-[130px]"
                  options={options}
                  onChange={(key) => {
                    setBreakdown(key);
                    //logEvent('set_breakdown', { 'breakdown': key, 'source': 'breakdown_pane' });
                  }}
                  value={breakdown}
                  disabled={availableBreakdowns.length <= 1}
                />
                <div className="mx-2">
                  <div className='flex'>
                    {/*
                    <Select
                      className="min-w-[80px]"
                      options={[
                        {key: 'line', value: 'Line'},
                        {key: 'bar', value: 'Bar'},
                      ]}
                      onChange={(key) => {
                        setChartType(key);
                      }}
                      value={chartType}
                      type="secondary"
                      size="sm"
                      enableFilter={false}
                  />
                  */}
                </div>
              </div>
              {!drillDownOpened && drillDownEnabled && (
                <Button 
                  type="tertiary"
                  icon="aside-layout"
                  label="Show"
                  onClick={() => {
                    setDrillDownOpened(true);
                    //logEvent('open_drilldown');
                  }}
                  size='sm'
                />
              )}
              <Button 
                onClick={() => {
                  setChartCollapsed(!chartCollapsed);
                  //logEvent('collapse_drilldown_chart', { 'state': !chartCollapsed })
                }}
                label={chartCollapsed ? 'Show Chart' : 'Hide Chart'}
                size='sm'
                type="tertiary"
              />
            </div>
          </div>
          <Section.Right>
            <HeaderStatus drillDown={drillDown} />
          </Section.Right>
        </Section.Head>
        {!chartCollapsed && (
          <AbsoluteCostsChart 
            drillDown={drillDown} 
            chartType={chartType}
            setCurrentStep={setCurrentStep} 
          />
        )}
        <Section.Content>
          <BreakdownTable
            breakdown={breakdown}
            drillDown={drillDown}
            canDrillDown={availableBreakdowns.length > 0}
            setCurrentStep={setCurrentStep}
            startMoment={startMoment}
            endMoment={endMoment}
            prevStartMoment={prevStartMoment}
            prevEndMoment={prevEndMoment}
          />
        </Section.Content>
      </Section>
    </div>
  )
};
