export interface DifficultyIconProps {
  difficulty: 'easy' | 'medium' | 'hard';
}

export const DifficultyIcon = (props: DifficultyIconProps) => {
  const { difficulty } = props;

  return (
    <span className='inline-flex w-[0.714em] h-[0.714em] justify-between items-end'>
      <span className='bg-current rounded-[1px] w-[20%] h-[40%]' />
      <span className={`bg-current rounded-[1px] w-[20%] h-[70%] ${difficulty === 'easy' && 'opacity-20'}`} />
      <span className={`bg-current rounded-[1px] w-[20%] h-[100%] ${difficulty !== 'hard' && 'opacity-20'}`} />
    </span>
  );
};
