import { Action } from 'types/dataEntries';
import type { PartialState } from 'zustand';

export const createStartAction = <
  TActionType extends string,
  TState extends {
    actions: { [key: number]: Action<TActionType> }
  }
>({
  get,
  set
} : {
  get: () => TState;
  set: (state: PartialState<TState>) => void;
}) => (
  id: number,
  type: TActionType
) => {
  const { actions } = get();

  const existingAction = actions[id];

  if (existingAction && existingAction.status === 'in-progress') {
    throw new Error(`Cannot run ${type} action: another action of type '${existingAction.type}' is in progress`);
  }

  set({
    actions: {
      ...actions,
      [id]: {
        type,
        status: 'in-progress'
      }
    }
  } as PartialState<TState>);
}

export const createFinishAction = <
  TActionType extends string,
  TState extends {
    actions: { [key: number]: Action<TActionType> }
  }
>({
  get,
  set
} : {
  get: () => TState;
  set: (state: PartialState<TState>) => void;
}) => (id: number) => {
  let { actions } = get();

  actions = { ...actions };

  delete actions[id];

  set({ actions } as PartialState<TState>);
}
