import type { ReactNode } from 'react';

export interface DataFilterRowProps {
  children?: ReactNode;
}

export const DataFilterRow = (props: DataFilterRowProps) => {
  const {
    children
  } = props;

  return (
    <div
      className='flex flex-wrap justify-start items-center gap-x-[10px] gap-y-[6px]'
    >
      {children}
    </div>
  );
};
