import { Button2 as Button } from 'shared/Button';
import { useCustomOpportunityDialog } from 'store/savings';

export const OpportunityCreateButton = () => {
  const openOpportunityCreateDialog = useCustomOpportunityDialog((store) => store.open);

  return (
    <Button
      size='s'
      theme='black'
      iconLeft='circle-plus'
      className='mx-5'
      onClick={() => {
        openOpportunityCreateDialog('new');
      }}
    >
      New Manual Opportunity
    </Button>
  );
};
