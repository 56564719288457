import moment from 'moment';
import { Link } from 'react-router-dom';
import { createDialogStore } from 'store/createDialogStore';
import { useGlobalState } from 'state/globalState';
import { OnPageBanner } from 'shared/OnPageBanner';
import { useEffect, useMemo } from 'react';

const useNonPayingBanner = createDialogStore();

export const NonPayingBanner = ({ className = '' } : { className?: string }) => {
  const user = useGlobalState((state) => state.user);
  let state: 'trial' | 'trialEnded' | 'paying'  = useMemo(() => {
    if (user?.billing_details.is_paying) {
      return 'paying';
    }

    return moment().isAfter(user?.billing_details.trial_end_date) ?
      'trialEnded' : 
      'trial';
  }, [user]);

  const { opened, open, close } = useNonPayingBanner();

  useEffect(() => {
    if (state !== 'paying') {
      open();
    }
  }, []);

  const bannerProps: Record<string, Pick<React.ComponentProps<typeof OnPageBanner>, 'type' | 'title' | 'children'>> = {
    trial: {
      type: 'info',
      title: 'Reminder - You’re On The Free Trial',
      children: <p>
        Welcome to Cloudthread! You are on a full-featured free trial which will end on {moment(user?.billing_details.trial_end_date).format('YYYY/MM/DD')} unless you upgrade here.
        When the free trial expires you’ll still have access to the Cloudthread platform but won’t be able to see all savings opportunities
      </p>
    },
    trialEnded: {
      type: 'warning',
      title: 'Upgrade to Unlock All Savings Opportunities',
      children: <p>
        Your free trial is over and now you’re on the Cloudthread’s Forever Free plan.
        You’re restricted from seeing all savings opportunities.
        See
        {' '}
        <a
          className='!text-caladon-blue'
          href='https://docs.cloudthread.io/fundamentals/cost-savings/key-concepts/optimization-opportunities#supported-opportunities'
        >
          our docs
        </a>
        {' '}
        for a full list of savings opportunities you could view if and act on if you upgraded
        {' '}
        <Link
          className='!text-caladon-blue'
          to='/settings/payment'
        >
          here
        </Link>
      </p>
    },
  };

  if (state === 'paying' || !opened) {
    return null;
  }

  return (
    <OnPageBanner
      className={className}
      opened={true}
      onClose={close}
      {...bannerProps[state]}
    />
  );
}
