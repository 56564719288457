import type { ReactNode } from 'react';
import type { IconName } from 'shared/Icon';
import type { FilterComparator, FilterValueType } from 'types/filters';

import { Icon } from 'shared/Icon';
import { IconButton } from 'shared/Button';
import { TextToggle } from './TextToggle';

export interface RuleHeaderProps {
  prefix?: ReactNode;
  required?: boolean;
  icon: IconName;
  title?: ReactNode;
  comparator?: FilterComparator;
  disabled?: boolean;
  onComparatorChange?: (comparator: FilterComparator) => void;
  valueType?: FilterValueType;
  onValueTypeChange?: (valueType: FilterValueType) => void;
  onAdd?: () => void;
  onRemove?: () => void;
}

export const RuleHeader = (props: RuleHeaderProps) => {
  const {
    prefix,
    icon,
    title,
    required,
    disabled,
    comparator = 'equals',
    onComparatorChange,
    valueType = 'string',
    onValueTypeChange,
    onAdd,
    onRemove
  } = props;

  const baseValueType = valueType.split('-')[0] as 'string' | 'dict';
  const regexValueType = `${baseValueType}-regex` as 'string-regex' | 'dict-regex';

  const comparatorOptions = {
    equals: 'is',
    'not equals': 'is not'
  };

  const valueTypeOptions = {
    [baseValueType]: 'in',
    [regexValueType]: 'like'
  } as Record<FilterValueType, ReactNode>;

  return (
    <div className='flex pt-[14px] items-center'>
      {prefix}
      <Icon className='mx-[5px]' icon={icon} />
      <span className='font-medium'>{title}</span>
      {required && <span className='font-medium text-red-600'>*</span>}
      {' '}
      {onComparatorChange && !disabled ? (
        <TextToggle
          value={comparator}
          onChange={onComparatorChange}
        >
          {comparatorOptions}
        </TextToggle>
      ) : (
        <span className='ml-1'>
          {comparatorOptions[comparator]}
        </span>
      )}
      {onValueTypeChange && !disabled  ? (
        <TextToggle
          value={valueType}
          onChange={onValueTypeChange}
        >
          {valueTypeOptions}
        </TextToggle>
      ) : (
        <span className='ml-1'>
          {valueTypeOptions[valueType]}
        </span>
      )}
      <div className='flex ml-auto'>
        {onRemove && !disabled && <IconButton icon='trash' size='xs' type='tertiary' onClick={onRemove} />}
        {onAdd &&  !disabled && <IconButton className='p-[10px]' icon='plus' size='xs' type='tertiary' onClick={onAdd} />}
      </div>
    </div>
  );
};
