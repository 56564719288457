import type { Action } from 'types/dataEntries';
import type { GlobalFilterId } from 'types/globalFilters';

export const OPPORTUNITY_DIFFICULTIES = ['easy', 'medium', 'hard'] as const;
export type OpportunityDifficulty = typeof OPPORTUNITY_DIFFICULTIES[number];

export const OPPORTUNITY_STATUSES = ['unassigned', 'assigned', 'validation', 'implementation', 'done', 'rejected', 'trash'] as const;
export type OpportunityStatus = typeof OPPORTUNITY_STATUSES[number];

export type TeamIdSetting = number;
export type GlobalFilterIdsSetting = GlobalFilterId[];
export type PrioritySetting = number[];
export type GroupSetting = 'both' | 'usage' | 'rate';
export type SourceSetting = 'costexplorer' | 'trustedadvisor';
export type Term = 'ONE_YEAR' | 'THREE_YEARS';
export type Payment = 'NO_UPFRONT' | 'PARTIAL_UPFRONT' | 'ALL_UPFRONT';
export type RateCommitmentType = 'savingsPlans' | 'reservedInstances';
export type EC2OfferingClass = 'STANDARD' | 'CONVERTIBLE';
export type Lookback = 'SEVEN_DAYS' | 'THIRTY_DAYS' | 'SIXTY_DAYS';
export type Platform = 'aws' | 'gcp';
export type PlatformSetting = 'all' | Platform;

export interface RateCommitmentSettings {
  enabled: boolean;
  term: Term;
  payment: Payment;
  ec2OfferingClass?: EC2OfferingClass;
  lookback?: Lookback;
}

export interface OpportunityPageFilters {
  teamId?: TeamIdSetting;
  globalFilterIds?: GlobalFilterIdsSetting;
  priority?: PrioritySetting;
  platform: PlatformSetting;
  group: GroupSetting;
  source: SourceSetting;
  rateCommitments: Record<RateCommitmentType, RateCommitmentSettings>;
}

export interface OpportunityCreatePayload {
  opportunity_type_id: 'manual_opportunity';
  cost_impact: number;
  record_id: string;
  thread_id?: number | null;
  priority?: number;

  opportunity_details: {
    difficulty: OpportunityDifficulty;
    opportunity_description: string;
    opportunity_recommendation: { text: string; };

    cost_attributes?: {
      aws_account_id?: string;
      region_name?: string;
      service?: string;
    };
  }
}

export type OpportunityUpdatePayload = Partial<
  Omit<OpportunityCreatePayload, 'record_id' | 'opportunity_type_id' | 'opportunity_details'> & {
    opportunity_details?: Partial<OpportunityCreatePayload['opportunity_details']>
    status?: OpportunityStatus;
  }
>;

export interface OpportunityDraft {
  opportunityTypeId?: string;
  costImpact?: number;
  recordId?: string;
  threadId?: number | null;
  difficulty?: OpportunityDifficulty;
  description?: string;
  recommendation?: string;
  account?: string | null;
  region?: string | null;
  service?: string | null;
}

export interface ValidOpportunityCreateDraft extends OpportunityDraft {
  opportunityTypeId: Exclude<string, ''>;
  costImpact: number;
  recordId: Exclude<string, ''>;
  difficulty: OpportunityDifficulty;
  description: Exclude<string, ''>;
}

export interface ValidOpportunityUpdateDraft extends OpportunityDraft {
  recordId?: Exclude<string, ''>;
  description?: Exclude<string, ''>;
  recommendation?: Exclude<string, ''>;
}

export type TrustedAdvisorOpportunityTypeId = `0002_trusted_advisor${string}`;
export type KnownOpportunityTypeId = '0001_unattached_ebs_volumes' |
  '0003_standardstorage_ebs_snapshots' |
  '0004_unused_elastic_ips' |
  '0005_unused_classic_loadbalancers' |
  '0006_rightsizing_ec2' |
  '0007_rightsizing_ecs' |
  '0008_rightsizing_lambda' |
  '0009_rightsizing_ebs' | 
  '0010_ce_ri_ec2' |
  '0011_ce_ri_rds' |
  '0012_ce_ri_redshift' |
  '0013_ce_ri_elasticache' |
  '0014_ce_ri_elasticsearch' |
  '0015_ce_ri_opensearch' |
  '0016_rds_rightsizing' |
  '0017_opensearch_rightsizing' |
  '0018_redis_rightsizing' |
  '0019_memcached_rightsizing' |
  '0020_dynamodb_rightsizing' |
  '0021_ce_sp_compute' |
  '0022_ce_sp_ec2' |
  '0023_ce_sp_sagemaker' |
  '0024_rightsizing_asg' |
  '0025_natgw' |
  '0026_gp3' |
  '0027_route53' |
  '0028_memcached_graviton' |
  '0029_mwaa_rightsizing' |
  '0030_redshift_rightsizing' |
  '0031_ec2_t2_migration' |
  '0100_unused_cw_alarms_ec2' |
  '0101_unused_cw_alarms_rds' |
  '0102_unused_cw_dashboards' |
  '0103_cw_log_retention' |
  '0104_stopped_instance_ebs_volumes' |
  '0105_unused_machine_images' |
  '0106_old_machine_images' |
  '0107_old_archivestorage_ebs_snapshots' |
  '0108_efs_lifecycle_policy' |
  '0109_s3_intelligent_tiering' |
  '0110_idle_nat_gateways' |
  '0111_auroa_instances_to_graviton' |
  '0112_ddb_infrequent_access' |
  '0113_opensearch_instances_to_graviton' |
  '0114_idle_vpc_endpoints' |
  '0115_duplicate_cloudtrail' |
  '0116_recommender'

export type UnknownOpportunityTypeId = string;

export type OpportunityTypeId = KnownOpportunityTypeId | TrustedAdvisorOpportunityTypeId | UnknownOpportunityTypeId;

export const isTrustedAdvisorOpportunityTypeId = (id: OpportunityTypeId): id is TrustedAdvisorOpportunityTypeId => id.startsWith('0002_trusted_advisor');
export const isComputeOptimizerOpportunityTypeId = (id: OpportunityTypeId): id is KnownOpportunityTypeId => id.startsWith('0006') 
  || id.startsWith('0007') || id.startsWith('0008') || id.startsWith('0009') || id.startsWith('0024');
export const isCostExplorerOpportunityTypeId = (id: OpportunityTypeId): id is KnownOpportunityTypeId => id.startsWith('0010') 
  || id.startsWith('0011') || id.startsWith('0012') || id.startsWith('0013') || id.startsWith('0014') || id.startsWith('0015')
  || id.startsWith('0021') || id.startsWith('0022') || id.startsWith('0023');
export const isRecommenderOpportunityTypeId = (id: OpportunityTypeId): id is KnownOpportunityTypeId => id.startsWith('0116_recommender');

export interface CostHistorical {
  cost_impact: number | null;
  _: number | null;
  date: string;
}

export interface OpportunityTag {
  key: string;
  value: string;
}

export interface Usage {
  label: string;
  data: [string, number][];
}

type SizingOption = string | {};

export interface OpportunityDetails {
  cost_impact_calculated: boolean;
  cost_impact_description?: string;
  cost_impact_details?: {
    current_option?: SizingOption;
    new_option?: SizingOption;
  };
  cost_attributes?: {
    aws_account_id?: string;
    aws_account_name?: string;
    bill_payer_account_id?: string;
    region_name?: string;
    service?: string;
    tags?: OpportunityTag[];
    tags_historical?: OpportunityTag[];
    account_tags?: OpportunityTag[];
    account_tags_historical?: OpportunityTag[];
    term?: Term;
    payment_opt?: Payment;
    offering_class?: EC2OfferingClass;
    lookback_days?: Lookback;
  };
  record_id: string;
  record?: {
    check_name?: string;
    flagged_resource?: {};
    flagged_resource_metadata?: {
      Account?: string;
      Region?: string;
    }
  };
  cost_historical?: CostHistorical[];
  usage_historical?: Usage[];
  opportunity_title?: string;
  opportunity_description: string;
  opportunity_recommendation?: {
    text: string;
  };
  meta_category?: 'usage' | 'rate';
  difficulty?: OpportunityDifficulty | 'unknown';
}

export interface Opportunity {
  id: number;
  secondary_id: number;
  opportunity_type_id: OpportunityTypeId;
  priority: number;

  platform?: Platform;
  source: string;
  record_id: string;
  organization_id: number;
  status: OpportunityStatus;
  is_expired?: boolean;
  thread_id?: number | null;
  team_id?: number | null;
  opportunity_details?: OpportunityDetails;
  cost_impact: number;

  created_at?: string;
  status_updated_at?: string;
  last_detected_at?: string | null;

  jira_issues: {
    integration_id: number;
    jira_issue_key: string;
    issue_type: 'cost_optimization_validation' | 'cost_optimization_implementation';
    thread_id: number;
  }[];

  in_progress_job_id: number | null;
}

export interface PaywalledOpportunity extends Opportunity {}

export const isPaywalledOpportunity = (opportunity: Opportunity): opportunity is PaywalledOpportunity => !opportunity.record_id;


export type OpportunityAssignAction = Action<'assign'>;
export type OpportunityUnassignAction = Action<'unassign'>;
export type OpportunityUpdateAction = Action<'update'>;
export type OpportunityArchiveAction = Action<'archive'>;
export type OpportunityRestoreAction = Action<'restore'>;
export type OpportunityResolveAction = Action<'resolve'>;
export type OpportunityRejectAction = Action<'reject'>;
export type OpportunityValidateAction = Action<'validate'>;
export type OpportunityImplementAction = Action<'implement'>;

export type OpportunityAction = OpportunityAssignAction |
  OpportunityUnassignAction |
  OpportunityUpdateAction |
  OpportunityArchiveAction |
  OpportunityRestoreAction |
  OpportunityResolveAction |
  OpportunityRejectAction |
  OpportunityValidateAction |
  OpportunityImplementAction;
