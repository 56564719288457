import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tabs, Tab } from 'shared/Tabs'
import { ReportsLibrary } from './ScheduledReports'
import { AlertsLibrary } from './ThresholdAlerts'


const NotificationCenter = () => {
  const [searchParams] = useSearchParams();
  const [viewReport, setViewReport] = useState(() => searchParams.get('new_view_report'));
  const [metricReport, setMetricReport] = useState(() => searchParams.get('new_metric_report'));
  const [viewAlert, setViewAlert] = useState(() => searchParams.get('new_view_alert'));
  const [metricAlert, setMetricAlert] = useState(() => searchParams.get('new_metric_alert'));
  const [currentTab, setCurrentTab] = useState<'reports' | 'alerts'>('reports');

  useEffect(() => {
    if (viewAlert || metricAlert) {
      setCurrentTab('alerts')
    }
  }, [viewAlert, metricAlert])

  const minSpend = searchParams.has('minSpend') ? parseFloat(searchParams.get('minSpend') as string) : undefined;
  const absoluteThreshold = searchParams.has('absoluteThreshold') ? parseInt(searchParams.get('absoluteThreshold') as string) : undefined;
  const relativeThreshold = searchParams.has('relativeThreshold') ? parseInt(searchParams.get('relativeThreshold') as string) : undefined;

  return (
    <div className="w-full pt-[20px] px-[15px] pb-[42px] rounded-[16px] mb-[30px]">
      <div className='pl-[30px]'>
        <Tabs value={currentTab} onChange={(value) => {
          setCurrentTab(value);
          setViewReport(null);
          setMetricReport(null);
          setViewAlert(null)
          setMetricAlert(null);
        }}>
          <Tab value='reports'>Reports</Tab>
          <Tab value='alerts'>Alerts</Tab>
        </Tabs>
      </div>
      {currentTab === 'reports' ? (
        <ReportsLibrary
          viewReport={viewReport}
          metricReport={metricReport}
          minSpend={minSpend}
          absoluteThreshold={absoluteThreshold}
          relativeThreshold={relativeThreshold}
        />
      ) : (
        <AlertsLibrary viewAlert={viewAlert} metricAlert={metricAlert}/>
      )}
    </div>
  )
}

export default NotificationCenter
