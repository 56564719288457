import type { TagCatalog } from 'types/tags';
// TODO: move to types
import type { TagCatalogEditEntry } from 'store/tagCatalogs';

import { Table } from 'antd';
import { Button } from 'shared/Button';
import { EditCatalogForm } from './EditCatalogForm';
import { DeleteCatalogButton } from './DeleteCatalogButton';

export interface CatalogTableProps {
  catalogs: TagCatalogEditEntry[];
  onClone: (catalog: TagCatalog) => void;
}

export const CatalogTable = (props: CatalogTableProps) => {
  const {
    catalogs,
    onClone
  } = props;


  return (
    <Table
      dataSource={catalogs.map((catalog) => catalog.source)}
      rowKey='id'
      expandable={{
        rowExpandable: () => true,
        expandedRowRender: (catalog) => <EditCatalogForm id={catalog.id} />
      }}
      columns={[
        {
          title: 'Catalog Key',
          dataIndex: 'catalog_key'
        },
        {
          dataIndex: 'id',
          className: 'w-[24px]',
          render: (id, catalog) => <div className='flex'>
            <Button
              size='sm'
              label='Copy'
              onClick={() => {
                onClone(catalog);
              }}
            />
            <DeleteCatalogButton id={id} />
          </div>
        }
      ]}
    />
  );
};
