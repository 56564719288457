import { Select } from 'antd';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useTeamsStore } from 'store/teams';
import { useSavingsSettingsStore } from 'store/savings';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

export const TeamSelect = () => {
  const teams = useTeamsStore((store) => store.getLibrary());
  const teamId = useSavingsSettingsStore((store) => store.teamId);
  const setTeamId = useSavingsSettingsStore((store) => store.setTeamId);

  const [searchParams, setSearchParams] = useSearchParams();
  const teamIdParam = searchParams.get('teamId');

  useEffect(() => {
    if (teamIdParam) {
      setTeamId(+teamIdParam);

      setSearchParams((currentSearchParams) => {
        const newSearchParams = new URLSearchParams(currentSearchParams);

        newSearchParams.delete('teamId');

        return newSearchParams;
      });
    }
  }, [teamIdParam])

  const options = useDerivedDataEntry(
    { teams },
    ({ teams }) => [
      {
        value: 'all',
        label: 'All Teams'
      },
      ...teams.map(({ id, name }) => ({
        value: id + '',
        label: name
      }))
    ]
  );

  return (
    <Select
      popupClassName='z-20'
      className='w-[150px]'
      showSearch={true}
      filterOption={(input, option) => option !== undefined && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      value={(teamId === undefined || teamId === null) ? 'all' : teamId + ''}
      options={options.status === 'success' ? options.data : []}
      disabled={options.status !== 'success'}
      onChange={(value) => {
        setTeamId(value === 'all' ? null : +value);
      }}
    />
  );
}
