import {
  Chart,
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  BarElement,
} from 'chart.js';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  BarElement,
  annotationPlugin,
);

Chart.defaults.responsive = true;
Chart.defaults.font.family = 'Inter';
Chart.defaults.layout.padding = { top: 20, left: 20, right: 20, bottom: 10 };
Chart.defaults.maintainAspectRatio = false;
Chart.defaults.interaction.intersect = false;

Chart.defaults.plugins.tooltip.usePointStyle = true;
Chart.defaults.plugins.tooltip.boxHeight = 5;
Chart.defaults.plugins.tooltip.boxWidth = 5;
Chart.defaults.plugins.tooltip.boxPadding = 3;

Chart.defaults.interaction.mode = 'index';

Chart.defaults.datasets.line.borderWidth = 2;
Chart.defaults.datasets.line.pointRadius = 1;
Chart.defaults.datasets.line.pointBorderWidth = 9;
Chart.defaults.datasets.line.pointBorderColor = 'transparent'
Chart.defaults.datasets.line.pointHoverRadius = 3;
Chart.defaults.datasets.line.pointHoverBorderWidth = 9;
Chart.defaults.datasets.line.clip = 10;

Chart.defaults.scales.linear.beginAtZero = true;

Chart.defaults.datasets.pie = { ...Chart.defaults.datasets.pie, clip: 10 };
Chart.defaults.datasets.doughnut = { ...Chart.defaults.datasets.doughnut, clip: 10 };

export const verticalLinePlugin = [
  {
    id: 'verticalLine',
    afterDraw: (chart: { tooltip?: any; scales?: any; ctx?: any }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (chart.tooltip && chart.tooltip._active && chart.tooltip._active.length) {
        // find coordinates of tooltip
        const middle_points = chart.tooltip._active.map((point: any) => point.element.x as number)
        const middle = middle_points.reduce((a: number, b: number) => a + b) / middle_points.length;
        const { ctx } = chart;
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom;

        // draw vertical line
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(middle, topY);
        ctx.lineTo(middle, bottomY);
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#445261';
        ctx.stroke();
        ctx.restore();
      }
    },
  },
];

export {
  Line as LineChart,
  Doughnut as DoughnutChart,
  Bar as BarChart,
  Chart
} from 'react-chartjs-2';
