import type { DataEntry } from 'types/dataEntries';

import { useEffect, useMemo, useState } from 'react';
import { Input, message } from 'antd';
import { Dialog, DialogBody, DialogFooter } from 'shared/Dialog';
import { Button2 as Button } from 'shared/Button';
import { StatusOverlay } from 'shared/Overlay';
import { FormRow, Label } from 'shared/Form';
import { renderThreadSecondaryId } from 'helpers/savings';
import { useSavingsStore, useThreadInfoDialog } from 'store/savings';

export const ThreadInfoDialog = () => {
  const {
    id,
    close
  } = useThreadInfoDialog();

  const idEntry: DataEntry<number> = useMemo(() => id ? { status: 'success', data: id } : { status: 'idle' }, [id]);
  const thread = useSavingsStore((store) => store.getThread(idEntry));
  const updateThread = useSavingsStore((store) => store.updateThread);

  const [name, setName] = useState('');
  const [body, setBody] = useState('');

  useEffect(() => {
    if (thread.status === 'success') {
      setName(thread.data.name);
      setBody(thread.data.body);
    } else {
      setName('');
      setBody('');
    }
  }, [thread]);

  return (
    <Dialog
      className='w-dialog-s'
      open={id !== null}
      onClose={close}
    >
      <Dialog.Title>
        Edit {thread.status === 'success' ? renderThreadSecondaryId(thread.data) : 'thread'} info
      </Dialog.Title>

      <DialogBody>
        <FormRow size='m'>
          <Label size='m'>
            Name
          </Label>

          <Input
            size='large'
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </FormRow>

        <FormRow size='m'>
          <Label size='m'>
            Description
          </Label>

          <Input.TextArea
            size='large'
            value={body}
            onChange={(event) => {
              setBody(event.target.value);
            }}
          />
        </FormRow>

      </DialogBody>
      
      <DialogFooter>
        <Button
          size='m'
          theme='link'
          onClick={close}
        >
          Cancel
        </Button>

        <Button
          size='m'
          theme='filled'
          disabled={
            thread.status !== 'success' ||
            name.trim() === '' ||
            name === thread.data.name &&
            body === thread.data.body
          }
          onClick={() => {
            if (id && thread.status === 'success') {
              updateThread(id, {
                name,
                body
              }).then(() => {
                message.success(<>
                  <b>{renderThreadSecondaryId(thread.data)}</b> updated
                </>);

                close();
              }).catch(() => {
                message.error(<>
                  Unable to update <b>{renderThreadSecondaryId(thread.data)}</b>
                </>);
              });
            }
          }}
        >
          Save
        </Button>
      </DialogFooter>

      <StatusOverlay status={thread.status} />
    </Dialog>
  );
}
