import type {
  OpportunityFiltersParams,
  OpportunityFiltersStoreKey,
  OpportunityFiltersStoreKeyProps,
  OpportunityFiltersStoreKeyFactory
} from 'types/savings';

import { Record as ImmutableRecord } from 'immutable';

import {
  createPageFiltersQueryKey,
  createDedupeBodyKey,
  createPrefilterQueryKey,
  pageFiltersQueryToKey,
  dedupeBodyToKey,
  prefilterQueryToKey
} from './storeKeys';

import { opportunityFiltersParamsToRequestParams } from './filterRequest';

const createOpportunityFiltersStoreKey: OpportunityFiltersStoreKeyFactory = ImmutableRecord<OpportunityFiltersStoreKeyProps>({
  pageFilters: createPageFiltersQueryKey(),
  dedupeBody: createDedupeBodyKey(),
  prefilter: createPrefilterQueryKey()
});

export const opportunityFiltersParamsToKey = (params: OpportunityFiltersParams): OpportunityFiltersStoreKey => {
  const [query, body] = opportunityFiltersParamsToRequestParams(params);

  const pageFilters = pageFiltersQueryToKey(query);
  const dedupeBody = dedupeBodyToKey(body);
  const prefilter = prefilterQueryToKey(query);

  return createOpportunityFiltersStoreKey({
    pageFilters,
    dedupeBody,
    prefilter
  });
}
