import type { Granularity } from 'types/common';
import type { DataEntry } from 'types/dataEntries';
import type { CostViewChart } from 'types/costViews/charts';
import type { EventsData } from 'types/costViews/events';
import type { CostViewFilter, CostViewFilterWithTeam } from 'types/costViews';
import type { ChartType } from 'types/chart'
import type { Moment } from 'moment';

import moment from 'moment';
import { useMemo, useState } from 'react';
import { Select, Checkbox } from 'antd';

import { Section, SectionTitle } from 'shared/Section';
import { EventsTable } from 'shared/EventsTable/EventsTable';
import { ChartTypeControl } from 'shared/ChartTypeControl';

import { TotalChart } from './TotalChart/TotalChart';
import { GrowthRate } from './GrowthRate';
import { BudgetEditor } from '../Budget';
import { BudgetDialog } from '../BudgetDialog';
import { CumulativeToggle } from '../CumulativeToggle';
import { EstTotalValue } from './EstTotalValue';
import { CurrentTotalValue } from './CurrentTotalValue';
import { PreviousTotalValue } from './PreviousTotalValue';

import { formatCurrency, formatCompact, formatRange } from 'helpers/formatter';
import { ExportToCsvButton } from 'shared/ExportToCsvButton';
import { Legend } from 'shared/Legend';
import { useCostOverviewStore } from '../useCostOverviewStore';
import { useCostFilterValue } from 'hooks/costFilter';
import { useForecast } from '../hooks/useForecast';
import {useDerivedDataEntry} from 'hooks/derivedDataEntry';
import {identity} from 'helpers/utils';
import {ForecastDialog} from '../ForecastDialog';
import {useCumulative} from '../hooks/cumulative';

export interface TotalProps {
  unit: 'cost' | 'unit';
  viewId: number | null;
  className?: string;
  chartDataEntry: DataEntry<CostViewChart>;
  prevChartDataEntry: DataEntry<CostViewChart>;
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
  events: EventsData[];
  eventTypes: string[];
  selectedEventTypes: string[];
  setEventTypes: (types: string[]) => void;
  granularity: Granularity;
  requestFilter: DataEntry<CostViewFilter | CostViewFilterWithTeam>;
}

export const Total = (props: TotalProps) => {
  const {
    unit,
    viewId,
    className,
    chartDataEntry,
    prevChartDataEntry,
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment,
    events,
    eventTypes,
    selectedEventTypes,
    setEventTypes,
    granularity,
    requestFilter
  } = props;

  const [cumulative, setCumulative] = useCumulative();

  const [chartType, setChartType] = useState<ChartType>('line')
  const [eventsOverlay, setEventsOverlay] = useState<boolean>(false)
  const [eventsToDisplay, setEventsToDisplay] = useState<EventsData[]>([])

  const handleSetEventsToDisplay = (targetEvents: EventsData[]) => {
    if (targetEvents.length && eventsToDisplay.length) {
      setEventsToDisplay(targetEvents)
    }
  }

  const filteredEvents = useMemo(() => {
    return events.filter((event) => selectedEventTypes.includes(event.payload.type))
  }, [selectedEventTypes])

  const filteredEventsToDisplay = useMemo(() => {
    return eventsToDisplay.filter((event) => selectedEventTypes.includes(event.payload.type))
  }, [selectedEventTypes, eventsToDisplay])

  const format = unit === 'cost' ? formatCurrency : formatCompact;
  const data = chartDataEntry.status === 'success' ? chartDataEntry.data : [];

  const showBudget = useCostOverviewStore((state) => viewId ===null || (state.view as any)?.is_custom);
  const costFilterValue = useCostFilterValue();

  const forecastParams = useDerivedDataEntry({
    ...costFilterValue,
    startDate: startMoment.format('YYYY-MM-DD'),
    endDate: moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
    forecastStartDate: moment().startOf('day').format('YYYY-MM-DD'),
    forecastEndDate: endMoment.format('YYYY-MM-DD'),
    granularity: (cumulative ? {
      status: 'success',
      data: 'day'
    } : {
      status: 'idle'
    }) as DataEntry<Granularity>,
    filter: requestFilter
  }, identity);

  const forecast = useForecast(forecastParams);

  const view = useCostOverviewStore((store) => store.view);

  const [budgetEnabled, setBudgetEnabled] = useState(true);

  const budget = useMemo(() => {
    const budgets = view?.budgets.filter((budget) => budget.period === (granularity === 'quarter' ? 'quarterly' : 'monthly'));
    return budgets?.length ? budgets[0] : null
  }, [view, granularity]);

  return (
    <Section className={className}>
      <div className='flex'>
        <div className='min-w-0 basis-[100%] shrink'>
          <div className='relative flex items-center px-5 gap-5 h-[60px] -mb-5 z-1'>
            <SectionTitle className='overflow-hidden whitespace-nowrap text-ellipsis'>
              Absolute {unit === 'cost' ? 'Costs' : 'Values'} - Overview
            </SectionTitle>

            <ChartTypeControl
              value={chartType}
              onChange={setChartType}
            />

            <CumulativeToggle />

            {!cumulative && (
              <div className='flex ml-auto gap-5'>
                <PreviousTotalValue
                  chartData={chartDataEntry}
                  format={format}
                />

                <CurrentTotalValue
                  chartData={chartDataEntry}
                  format={format}
                />
              </div>
            )}
          </div>

          <TotalChart 
            chartDataEntry={chartDataEntry}
            prevChartDataEntry={prevChartDataEntry}
            forecast={forecast}
            chartType={chartType}
            events={eventsOverlay ? filteredEvents : []}
            setEventsToDisplay={handleSetEventsToDisplay}
            format={format}
            granularity={granularity}
            budget={cumulative && budgetEnabled && budget ? budget.value : undefined}
          />
        </div>

        {cumulative &&
          <div className='flex flex-col py-2 mx-5 gap-4 shrink-0' >
            <EstTotalValue
              granularity={granularity}
              chartData={chartDataEntry}
              forecast={forecast}
              budget={budget}
              format={format}
            />

            <PreviousTotalValue
              chartData={chartDataEntry}
              format={format}
            />

            {showBudget && 
              <BudgetEditor
                costViewId={viewId}
                granularity={granularity}
              />
            }

            {requestFilter &&
              <GrowthRate
                requestFilter={requestFilter}
              />
            }
          </div>
        }
      </div>

      <div className='flex items-center px-5 py-2 mt-2 border-t gap-5'>
        <Legend series={[
          {
            name: 'Current period',
            color: '#00b4d8',
            subtitle: formatRange(startMoment, endMoment, granularity)
          },
          {
            name: 'Previous period',
            color: '#DA5665',
            subtitle: formatRange(prevStartMoment, prevEndMoment, granularity)
          },
        ]} />

        {showBudget &&
          <div className='flex items-center -mr-2 gap-1'>
            <Checkbox
              checked={cumulative && budgetEnabled}
              onChange={() => {
                if (!cumulative) {
                  setCumulative(true);
                  setBudgetEnabled(true);
                } else {
                  setBudgetEnabled(!budgetEnabled);
                }
              }}
            >
              <Legend series={[
                {
                  name: 'Budget',
                  color: 'gray',
                  subtitle: 'Cumulative only'
                }
              ]} />
            </Checkbox>
          </div>
        }

        <div className='flex items-center gap-1'>
          <Checkbox
            checked={eventsOverlay}
            onChange={() => {
              setEventsOverlay(!eventsOverlay);
            }}
          >
            <span
              className='inline-block w-[10px] h-[10px] rounded-[50%] mr-2'
              style={({ backgroundColor: 'rgba(5, 223, 247, 0.8)' })}
            />

            Events
          </Checkbox>

          {eventsOverlay && 
            <Select 
              size='small'
              mode='multiple'
              className='min-w-[300px]'
              value={selectedEventTypes}
              onChange={setEventTypes}
              options={eventTypes.map((type) => ({
                label: type,
                value: type
              }))}
              filterOption
            />
          }
        </div>

        <div className='ml-auto'>
          <ExportToCsvButton fileName='total_chart.csv' data={data} />
        </div>
      </div>

      {filteredEventsToDisplay.length > 0 && (
        <div className='mx-5 mt-4'>
          <EventsTable events={filteredEventsToDisplay} />
        </div>
      )}

      {forecast.status === 'success' && 
        <ForecastDialog
          value={forecast.data.settings}
          onChange={forecast.data.setSettings}
          lastCost={forecast.data.lastCost}
          avgCost={forecast.data.avgCost}
          format={format}
        />
      }

      <BudgetDialog
        granularity={granularity}
      />
    </Section>
  );
};
