import type { DataEntry, DraftKey } from 'types/dataEntries';
import type { ConstructorRules } from 'types/filters';
import { 
  GlobalFilterDraft, 
  GLOBAL_FILTER_SOURCES,
  GlobalFilterId 
} from 'types/globalFilters';

import { message, Input } from 'antd';
import { Dialog } from 'shared/Dialog';
import { Button } from 'shared/Button';
import { Filter } from 'shared/Filters';

import { getSourceTitle } from 'shared/Filters/constants';
import { identity } from 'helpers/utils';

import { useGlobalFiltersStore } from 'store/globalFilters';
import { useGlobalFilterConstructor } from 'store/globalFilterConstructor';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { PortalRoot } from 'shared/Portal';
import { fixedDraftKey } from 'types/dataEntries';

interface CreateDialogProps {
  draftKey: DraftKey | GlobalFilterId | null;
  onClose: () => void;
}

export const CreateDialog = (props: CreateDialogProps) => {
  const {
    draftKey,
    onClose
  } = props;

  const globalFilterConstructor = useGlobalFilterConstructor();
  const globalFilterDeps = useDerivedDataEntry({ globalFilterConstructor }, identity);

  const draft = useGlobalFiltersStore((state) => draftKey ? state.getDraft(draftKey, globalFilterDeps) : { status: 'idle' } as DataEntry<GlobalFilterDraft>);
  const setDraft = useGlobalFiltersStore((store) => store.setDraft);
  const discardDraft = useGlobalFiltersStore((store) => store.discardDraft);
  const createGlobalFilter = useGlobalFiltersStore((state) => state.createEntry);
  const updateGlobalFilter = useGlobalFiltersStore((state) => state.updateEntry);
  const action = useGlobalFiltersStore((state) => draftKey && state.actions.get(draftKey));

  const isNew = draftKey === fixedDraftKey;

  return (
    <Dialog
      open={draftKey !== null}
      onClose={onClose}
      className='w-[80vw] max-w-[1200px] h-[80vh] flex flex-col'
    >
      <Dialog.Title>
        {`${ isNew ? 'Create New' : 'Edit'} Global Filter`}
      </Dialog.Title>

      {draft.status === 'success' && draftKey !== null && <>
        <PortalRoot className='border-t border-t-silver-grey grid grid-cols-3 gap-[20px] px-[30px] py-[15px]'>
          <div>
            <div className='mb-1 font-medium'>
              Global Filter Name
            </div>

            <Input
              size='large'
              value={draft.data.name}
              onChange={(event) => {
                setDraft(draftKey, { 
                  ...draft.data,
                  name: event.target.value
                });
              }}
            />
          </div>
        </PortalRoot>

        <div className='mb-1 font-medium px-[30px]'>
          Global Filters
        </div>

        <div className='min-h-0 grid grid-cols-3 grow shrink gap-[20px] px-[30px] pb-[30px]'>
          {GLOBAL_FILTER_SOURCES.map((src) => (
            <div className='flex flex-col min-h-0 border rounded shrink'>
              <div className='p-[15px] border-b font-medium'>
                {getSourceTitle(src)}
              </div>

              <PortalRoot className='overflow-y-auto shadow-border-l px-[15px] grow'>
                {globalFilterConstructor.status === 'success' && globalFilterConstructor.data[src] &&
                  <Filter
                    rules={globalFilterConstructor.data[src] as ConstructorRules}
                    value={draft.data.filters[src].filter}
                    onChange={(filter) => {
                      draftKey && setDraft(draftKey, {
                        ...draft.data,
                        filters: {
                          ...draft.data.filters,
                          [src]: {
                            src,
                            filter
                          }
                        }
                      });
                    }}
                    removeTeams={false}
                  />
                }
              </PortalRoot>
            </div>
          ))}
        </div>

        <footer className='flex justify-end p-[15px] border-t mt-auto'>
          <Button
            disabled={draft.data.name === ''}
            style='caladon'
            label='Save Global Filter'
            onClick={() => {
              if (draftKey === null) {
                return;
              } 

              if (isNew) {
                createGlobalFilter(draftKey, globalFilterDeps)
                  .then(() => {
                    message.success('Global filter created');
                    discardDraft(draftKey);
                    onClose();
                  })
                  .catch(() => {
                    message.error('Unable to create global filter');
                  });
              } else {
                updateGlobalFilter(draftKey, globalFilterDeps)
                  .then(() => {
                    message.success('Global filter updated');
                    discardDraft(draftKey);
                    onClose();
                  })
                  .catch(() => {
                    message.error('Unable to update global filter');
                  });
              }
            }}
          />
        </footer>
      </>}
    </Dialog>
  );
};
