import type { ReactNode } from 'react';

export interface BranchProps {
  last?: boolean;
  small?: boolean;
  theme?: 'default' | 'dark';
  children: ReactNode;
}

export const Branch = (props: BranchProps) => {
  const {
    last,
    small,
    children,
    theme = 'default'
  } = props;

  const borderColor = theme === 'default' ? 'border-grey' : 'border-gray-300';

  return (
    <div className='relative pl-[16px]'>
      <div className={`absolute top-0 left-[8px] w-[8px] ${small ? 'h-[12px]' : 'h-[26px]'} border-box rounded-lt-[4px] border-l border-b ${borderColor}`} />
      {!last && <div className={`absolute top-0 bottom-0 left-[8px] border-l ${borderColor}`} />}
      {children}
    </div>
  );
};
