import { Dialog as HeadlessDialog } from '@headlessui/react'
import {
  createContext, createRef, MutableRefObject, ReactNode, useContext,
} from 'react'
import { ClassNameProps } from 'props'
import { useClassifyOnScroll } from 'hooks/useClassifyOnScroll'
import { Button2 as Button } from 'shared/Button';
import { PortalRoot } from 'shared/Portal';

export interface DialogProps extends ClassNameProps {
  open: boolean
  onClose: (value: boolean) => void
  children: ReactNode
}

interface DialogState {
  scrollable: MutableRefObject<HTMLDivElement | null>
  header: MutableRefObject<HTMLDivElement | null>
  onClose: (value: boolean) => void
}

const DialogContext = createContext<DialogState | null>(null)

const Dialog = ({
  open,
  onClose,
  className,
  children,
}: DialogProps) => {

  const state: DialogState = {
    scrollable: createRef(),
    header: createRef(),
    onClose,
  }

  useClassifyOnScroll(state.header, state.scrollable, { className: 'shadow-light-shadow' })

  return (
    <DialogContext.Provider value={state}>
      <HeadlessDialog
        open={open}
        onClose={onClose}
        as="div"
        className="fixed inset-0 z-30 flex flex-col items-center justify-center shadow-light-shadow"
      >
        <HeadlessDialog.Overlay className="fixed inset-0 pointer-events-none bg-silver-grey-600/60 backdrop-blur-sm" />
        <PortalRoot
          className={`${className} flex flex-col text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl my-[20px] min-h-0`}
        >
          {children}
        </PortalRoot>
      </HeadlessDialog>
    </DialogContext.Provider>
  )
}

const Overlay = () => (<HeadlessDialog.Overlay className="fixed inset-0" />)

const Title = ({ children }: { children: ReactNode }) => {
  const context = useContext(DialogContext)

  return (
    <div className="" ref={context?.header}>
      <HeadlessDialog.Title
        as='header'
        className='flex items-center h-[60px] pl-8 text-subtitle-1 font-medium'
      >
        {children}
        <div className='ml-auto flex items-center justify-center h-[60px] w-[60px]'>
          <Button
            size='xs'
            theme='black'
            icon='close'
            onClick={() => context?.onClose(true)}
          />
        </div>
      </HeadlessDialog.Title>
    </div>
  )
}

const Body = ({
  children,
  className = '',
}: { className?: string, children: ReactNode }) => {
  const context = useContext(DialogContext)

  return (
    <div className={`${className} px-[30px] mx-[3px] mb-[60px] mt-[20px] overflow-auto`} ref={context?.scrollable}>
      {children}
    </div>
  )
}

export default Object.assign(Dialog, {
  Overlay,
  Title,
  Body,
})
