import create from 'zustand'

import type { FetchStatus } from 'types/common';
import type { CostViewLibraryEntry } from 'types/costViews';

import { getCostViewLibrary, deleteCostView } from 'services/metric'
import moment from 'moment';
import { SERVER_DATE_FORMAT } from 'helpers/constants'

interface FetchParams {
  force?: boolean;
}

export interface CostViewLibraryState {
  status: FetchStatus;
  costViews: CostViewLibraryEntry[];
  startDate: string;
  endDate: string;
}

export interface CostViewLibraryActions {
  fetchCostViewLibrary: (params?: FetchParams) => void;
  deleteCostView: (id: number) => Promise<void>;
  setPeriod: (
    startDate: string,
    endDate: string,
  ) => void;
}

export interface CostViewLibraryStore extends CostViewLibraryState, CostViewLibraryActions {}

const DEFAULT_STATE: CostViewLibraryState = {
  status: 'idle',
  costViews: [],
  startDate: moment().subtract(8, 'days').format(SERVER_DATE_FORMAT),
  endDate: moment().subtract(1, 'days').format(SERVER_DATE_FORMAT),
};

export const useCostViewLibraryStore = create<CostViewLibraryStore>((set, get) => {
  let requestCount = 0;

  return {
    ...DEFAULT_STATE,

    setPeriod: async (
      startDate: string,
      endDate: string,
    ) => {
      set({ startDate, endDate, status: 'idle' })

      get().fetchCostViewLibrary()
    },

    fetchCostViewLibrary: async (params = {}) => {
      const { force } = params;
      const { status, startDate, endDate } = get();

      /*
      if (!force && ['loading', 'success'].includes(status)) {
        return;
      }
      */

      try {
        if (status !== 'success') {
          set({ status: 'loading' });
        }

        const requestNumber = ++requestCount;
        
        const { data: costViews } = await getCostViewLibrary(
          startDate, 
          endDate,
        );

        if (requestNumber === requestCount) {
          set({
            costViews,
            status: 'success'
          });
        }
      } catch(error) {
        set({ status: 'error' });
      }
    },

    deleteCostView: async (id: number) => {
      const { costViews } = get();
      set({ costViews: costViews.filter((view) => view.id !== id) });

      try {
        await deleteCostView(id);
      } catch(error) {
        set({ costViews });
        throw error;
      }
    }
  };
});
