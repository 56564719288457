import { ReactComponent as open } from 'assets/images/ThreadStatusOpen.svg';
import { ReactComponent as validation } from 'assets/images/ThreadStatusValidation.svg';
import { ReactComponent as implementation } from 'assets/images/ThreadStatusImplementation.svg';
import { ReactComponent as done } from 'assets/images/ThreadStatusDone.svg';
import { ReactComponent as rejected } from 'assets/images/ThreadStatusRejected.svg';
import { ReactComponent as total } from 'assets/images/ThreadStatusTotal.svg';

const icons = {
  open,
  validation,
  implementation,
  done,
  rejected,
  deleted: rejected,
  total
}

export interface ThreadStatusIconProps {
  status: keyof typeof icons;
  className?: string;
}

export const ThreadStatusIcon = (props: ThreadStatusIconProps) => {
  const {
    status,
    className = ''
  } = props;

  const Icon = icons[status];

  return <Icon className={`inline-block mb-0.5 ${className}`} width={12} height={12} />;
}
