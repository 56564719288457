import { fetchCostViewChart } from 'services/costViews';
import { costViewChartFetchParamsToKey } from 'helpers/storeKeys/costViewChart';
import { createKeyedStore } from './createKeyedStore';
import { createKeyedStoreSelector } from './createKeyedStoreSelector';

export const useCostViewChartStore = createKeyedStore({
  paramsToKey: costViewChartFetchParamsToKey,
  fetchData: fetchCostViewChart
});

export const useCostViewChart = createKeyedStoreSelector(useCostViewChartStore);
