import type {
  OpportunitySort,
  OpportunitySortField,
  SortDir
} from 'types/savings';

import { useSearchParams } from 'react-router-dom';
import {
  isOpportunitySortField,
  isSortDir
} from 'types/savings';

import { DEFAULT_SORT_DIRS } from 'helpers/savings';

const DEFAULT_FIELD = 'costImpact';

export const useOpportunitySortQuery = (): [
  value: OpportunitySort,
  setValue: (value: OpportunitySort) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const fieldParam = searchParams.get('sortBy');
  const dirParam = searchParams.get('sortDir');

  const field: OpportunitySortField = isOpportunitySortField(fieldParam) ? fieldParam : DEFAULT_FIELD;
  const dir: SortDir = isSortDir(dirParam) ? dirParam : DEFAULT_SORT_DIRS[field];

  const value: OpportunitySort = {
    field,
    dir
  };

  const setValue = (value: OpportunitySort) => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (value.field === DEFAULT_FIELD) {
      newSearchParams.delete('sortBy');
    } else {
      newSearchParams.set('sortBy', value.field);
    }

    if (value.dir === DEFAULT_SORT_DIRS[value.field]) {
      newSearchParams.delete('sortDir');
    } else {
      newSearchParams.set('sortDir', value.dir);
    }

    setSearchParams(newSearchParams, { replace: true });
  }

  return [value, setValue];
}
