import type { DataEntry } from 'types/dataEntries';
import type {
  TagCatalog,
  TagCatalogCreatePayload,
  TagCatalogUpdatePayload
} from 'types/tags';

import create from 'zustand';

import {
  fetchTagCatalogs,
  createTagCatalog,
  updateTagCatalog,
  deleteTagCatalog
} from 'services/tags';

interface ActionInProgress<TActionType extends string> {
  type: TActionType;
  status: 'in-progress';
}

interface ActionError<TActionType extends string, TError = any> {
  type: TActionType;
  status: 'error';
  error: TError;
}

interface ActionSuccess<TActionType extends string, TResult> {
  type: TActionType;
  status: 'success';
  result: TResult;
}

type Action<TActionType extends string, TError = any, TResult = any> = 
  ActionInProgress<TActionType> |
  ActionSuccess<TActionType, TResult> |
  ActionError<TActionType, TError>;

interface EditEntry<TData, TUpdatePayload, TAction> {
  source: TData;
  draft: TUpdatePayload;
  action?: TAction;
}

interface CreateEntry<TCreatePayload, TAction> {
  key: string;
  draft: TCreatePayload;
  action?: TAction;
}

export type TagCatalogEditEntry = EditEntry<TagCatalog, TagCatalogUpdatePayload, Action<'update' | 'delete'>>;
type TagCatalogCreateEntry = CreateEntry<TagCatalogCreatePayload, Action<'create', any, TagCatalog>>;

const getTagCatalogUpdatePayload = (catalog: TagCatalog): TagCatalogUpdatePayload => ({
  name: catalog.name,
  catalog_key: catalog.catalog_key,
  tags: catalog.tags
})

interface TagCatalogsState {
  entries: DataEntry<TagCatalogEditEntry[]>;
  newEntries: TagCatalogCreateEntry[];
}

interface TagCatalogsActions {
  getEntries: () => DataEntry<TagCatalogEditEntry[]>;
  getNewEntry: () => TagCatalogCreateEntry;

  setEditEntry: (entry: TagCatalogEditEntry) => void;
  setCreateEntry: (entry: TagCatalogCreateEntry) => void;

  createEntry: (key: string) => Promise<void>;
  confirmCreateSuccess: (key: string) => void;
  discardCreateError: (key: string) => void;

  deleteEntry: (id: number) => Promise<void>;
  confirmDeletionSuccess: (id: number) => void;
  discardDeletionError: (id: number) => void;

  updateEntry: (id: number) => Promise<void>;
  confirmUpdateSuccess: (id: number) => void;
  discardUpdateError: (id: number) => void;

  fetchEntries: () => void;
}

interface TagCatalogsStore extends TagCatalogsState, TagCatalogsActions {}

const DEFAULT_STATE: TagCatalogsState = {
  newEntries: [],
  entries: { status: 'idle' }
};

export const useTagCatalogsStore = create<TagCatalogsStore>((set, get) => ({
  ...DEFAULT_STATE,

  getEntries: () => {
    if (get().entries.status === 'idle') {
      get().fetchEntries();
    }

    return get().entries;
  },

  getNewEntry: () => {
    let { newEntries } = get();

    let newEntry = newEntries.find((entry) => !entry.action);

    if (!newEntry) {
      newEntry = {
        key: `new-${newEntries.length + 1}`,
        draft: {
          name: '',
          catalog_key: '',
          tags: {}
        }
      };

      newEntries = [...newEntries, newEntry];
      set({ newEntries });
    }

    return newEntry;
  },

  setCreateEntry: (newEntry: TagCatalogCreateEntry) => {
    let { newEntries } = get();

    let index = newEntries.findIndex((entry) => entry.key === newEntry.key);

    if (index === -1) {
      index = newEntries.length;
    }

    newEntries = [...newEntries];
    newEntries[index] = newEntry;

    set({ newEntries });
  },

  fetchEntries: () => {
    set({
      entries: {
        status: 'loading'
      }
    });
    
    fetchTagCatalogs()
      .then(({ data }) => {
        set({
          entries: {
            status: 'success',
            data: data.map((catalog) => ({
              source: catalog,
              draft: getTagCatalogUpdatePayload(catalog)
            }))
          }
        });
      })
      .catch((error) => {
        set({
          entries: {
            status: 'error',
            error
          }
        });
      });
  },

  createEntry: (key: string) => {
    let { newEntries } = get();

    const index = newEntries.findIndex((entry) => entry.key === key);

    if (index === -1) {
      throw new Error(`Cannot create new catalog with key ${key}`);
    }

    const creatingEntry: TagCatalogCreateEntry = {
      ...newEntries[index],
      action: {
        type: 'create',
        status: 'in-progress'
      }
    };

    newEntries = [...newEntries];
    newEntries[index] = creatingEntry;

    return createTagCatalog(creatingEntry.draft)
      .then(({ data }) => {
        let {
          newEntries
        } = get();


        const index = newEntries.findIndex((entry) => entry.key === key);
        newEntries = [...newEntries];

        newEntries[index] = {
          ...creatingEntry,
          action: {
            type: 'create',
            status: 'success',
            result: data
          }
        };

        set({ newEntries });
      })
      .catch((error) => {
        let { newEntries } = get();
        const index = newEntries.findIndex((entry) => entry.key === 'key');

        const createErrorEntry: TagCatalogCreateEntry = {
          ...creatingEntry,
          action: {
            type: 'create',
            status: 'error',
            error
          }
        };

        newEntries = [...newEntries];
        newEntries[index] = createErrorEntry;
        set({ newEntries });

        throw error;
      });
  },

  confirmCreateSuccess: (key: string) => {
    let { newEntries, entries } = get();

    let index = newEntries.findIndex((entry) => entry.key === key);
    let newEntry = newEntries[index];

    if (entries.status !== 'success' || newEntry?.action?.status !== 'success') {
      throw new Error('Cannot confirm create success');
    }

    const entry: TagCatalogEditEntry = {
      source: newEntry.action.result,
      draft: {}
    };

    newEntries = [...newEntries];
    newEntries.splice(index, 1);
    entries = {
      ...entries,
      data: [...entries.data, entry]
    };

    set({ newEntries, entries });
  },

  discardCreateError: (key: string) => {
    let { newEntries } = get();

    const index = newEntries.findIndex((entry) => entry.key === key);
    let entry = newEntries[index];

    if (!entry) {
      throw new Error(`Cannot discard create error of tag catalog with key: ${key}, tag catalog is not present`);
    }

    newEntries = [...newEntries];
    newEntries.splice(index, 1);

    set({ newEntries });
  },

  updateEntry: (id: number) => {
    let { entries, setEditEntry } = get();

    if (entries.status !== 'success') {
      throw new Error('Cannot update tag catalog before tag catalogs are loaded');
    }

    const index = entries.data.findIndex((catalog) => catalog.source.id === id);

    if (index === -1) {
      throw new Error(`Cannot update tag catalog with id: ${id} - no such catalog`);
    }

    const entry = entries.data[index];

    if (entry.action) {
      throw new Error(`Cannot update tag catalog with id: ${id} - blocked by another action`);
    }
    
    const inProgressEntry: TagCatalogEditEntry = {
      ...entry,
      action: {
        type: 'update',
        status: 'in-progress'
      }
    };

    setEditEntry(inProgressEntry);

    return updateTagCatalog(entry.source.id, entry.draft)
      .then(() => {
        const successEntry: TagCatalogEditEntry = {
          ...entry,
          source: {
            ...entry.source,
            ...entry.draft
          },
          action: {
            type: 'update',
            status: 'success',
            result: null
          }
        };
        
        setEditEntry(successEntry);
      })
      .catch((error) => {
        const errorEntry: TagCatalogEditEntry = {
          ...entry,
          action: {
            type: 'update',
            status: 'error',
            error
          }
        };

        setEditEntry(errorEntry);
      });
  },

  confirmUpdateSuccess: (id: number) => {
    let { entries } = get();

    if (entries.status !== 'success') {
      throw new Error('Cannot confirm tag catalog update before tag catalogs are loaded');
    }

    const index = entries.data.findIndex((entry) => entry.source.id === id);
    let entry = entries.data[index];

    if (!entry) {
      throw new Error(`Cannot confirm deletion of tag catalog with id: ${id}, tag catalog is not present`);
    }

    if (entry.action?.type !== 'update' || entry.action.status !== 'success') {
      throw new Error(`Cannot confirm update of tag catalog with id: ${id}, different action or deletion not successful`);
    }

    entries = {
      ...entries,
      data: [...entries.data]
    }

    entry = { ...entry };

    delete entry.action;

    entries.data[index] = entry;

    set({ entries });
  },

  discardUpdateError: (id: number) => {
    let { entries } = get();

    if (entries.status !== 'success') {
      throw new Error('Cannot discard tag catalog update error before tag catalogs are loaded');
    }

    const index = entries.data.findIndex((entry) => entry.source.id === id);
    let entry = entries.data[index];

    if (!entry) {
      throw new Error(`Cannot discard update error of tag catalog with id: ${id}, tag catalog is not present`);
    }

    if (entry.action?.type !== 'update' || entry.action.status !== 'error') {
      throw new Error(`Cannot discard update error of tag catalog with id: ${id}, different action or update not failed`);
    }

    entries = {
      ...entries,
      data: [...entries.data]
    }

    entry = { ...entry };

    delete entry.action;

    entries.data[index] = entry;

    set({ entries });
  },

  setEditEntry: (newEntry: TagCatalogEditEntry) => {
    let { entries } = get();

    if (entries.status !== 'success') {
      throw new Error('Cannot set single catalog entries until they are loaded');
    }

    const index = entries.data.findIndex((entry) => entry.source.id === newEntry.source.id);

    entries = {
      ...entries,
      data: [
        ...entries.data
      ]
    };

    entries.data.splice(index, index === -1 ? 0 : 1, newEntry);

    set({ entries });
  },

  deleteEntry: (id: number) => {
    const { entries, setEditEntry } = get();

    if (entries.status !== 'success') {
      throw new Error('Cannot delete tag catalog before tag catalogs are loaded');
    }

    const entryIndex = entries.data.findIndex((entry) => entry.source.id === id);
    const entry = entries.data[entryIndex];

    if (!entry) {
      throw new Error(`Cannot delete non-existing tag catalog with id: ${id}`);
    }

    if (entry.action && entry.action.status === 'in-progress') {
      throw new Error(`Cannot delete tag catalog with id: ${id} while action ${entry.action.type} is in progress`);
    }

    const inProgressEntry: TagCatalogEditEntry = {
      ...entry,
      action: {
        type: 'delete',
        status: 'in-progress'
      }
    };

    setEditEntry(inProgressEntry);

    return deleteTagCatalog(id)
      .then(() => {
        const successEntry: TagCatalogEditEntry = {
          ...entry,
          action: {
            type: 'delete',
            status: 'success',
            result: null
          }
        };

        setEditEntry(successEntry);
      })
      .catch((error) => {
        const errorEntry: TagCatalogEditEntry = {
          ...entry,
          action: {
            type: 'delete',
            status: 'error',
            error
          }
        };

        setEditEntry(errorEntry);
      });
  },

  confirmDeletionSuccess: (id: number) => {
    let { entries } = get();

    if (entries.status !== 'success') {
      throw new Error('Cannot confirm tag catalog deletion before tag catalogs are loaded');
    }

    const index = entries.data.findIndex((entry) => entry.source.id === id);
    const entry = entries.data[index];

    if (!entry) {
      throw new Error(`Cannot confirm deletion of tag catalog with id: ${id}, tag catalog is not present`);
    }

    if (entry.action?.type !== 'delete' || entry.action.status !== 'success') {
      throw new Error(`Cannot confirm deletion of tag catalog with id: ${id}, different action or deletion not successful`);
    }

    entries = {
      ...entries,
      data: [...entries.data]
    }

    entries.data.splice(index, 1);

    set({ entries });
  },

  discardDeletionError: (id: number) => {
    let { entries } = get();

    if (entries.status !== 'success') {
      throw new Error('Cannot discard tag catalog deletion error before tag catalogs are loaded');
    }

    const index = entries.data.findIndex((entry) => entry.source.id === id);
    let entry = entries.data[index];

    if (!entry) {
      throw new Error(`Cannot discard deletion error of tag catalog with id: ${id}, tag catalog is not present`);
    }

    if (entry.action?.type !== 'delete' || entry.action.status !== 'error') {
      throw new Error(`Cannot discard deletion error of tag catalog with id: ${id}, different action or deletion not failed`);
    }

    entries = {
      ...entries,
      data: [...entries.data]
    }

    entry = { ...entry };

    delete entry.action;

    entries.data[index] = entry;

    set({ entries });
  }
}));
