// eslint-disable-next-line max-len
export const groupBy = <T, K extends keyof T>(arr: T[], key: string) => arr.reduce((result, next) => {
  const name = (next as any)[key] as string

  if (!result[name]) {
    result[name] = []
  }

  result[name].push(next)

  return result
}, {} as Record<string, T[]>)
