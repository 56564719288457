import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import Page from 'shared/Page/Page';
import { DateText } from 'shared/DateText'
import { Section } from 'shared/Section';
import { DateFilter } from 'shared/DateFilter';
import { SERVER_DATE_FORMAT } from 'helpers/constants'
import { Icon } from 'shared/Icon/Icon';
import Button from 'shared/Button/Button';
import IconButton from 'shared/Button/IconButton';
import { DeleteDialog } from '../UnitMetric/DeleteDialog';
import { useUnitMetricsLibraryStore } from './useUnitMetricsLibraryStore';
import { formatPercent, formatCurrency } from 'helpers/formatter';
import { RadioButton, RadioButtonGroup } from 'shared/RadioButtonGroup';
import { Loading } from 'shared/Loading/Loading';
import { useGlobalState } from 'state/globalState';
import { createNullableNumbersComparator } from 'helpers/sort';
import { useSearchTerm } from 'hooks/useSearchTerm';
import { useDatesQuery } from 'hooks/useDatesQuery';
import { UnitMetricsLibraryEntry } from 'types/unitMetrics';

const Dot = ({ className = '', color }: { className?: string, color: string }) => <span className={`inline-block scale-[1.5] ${className}`} style={{ color }}>•</span>;
const sortStrings = (a: string, b: string) => a > b ? 1 : a < b ? -1 : 0;
export type src = 'custom_data' | 'cur' | 'gcp' | 'cloudwatch'
const srcMap = { custom_data: 'Custom', cur: 'AWS CUR', gcp: 'GCP CUR', cloudwatch: 'Cloudwatch' }

export const UnitMetricsLibrary = () => {
  const logEvent = useGlobalState((state) => state.logEvent);
  const {
    loading,
    unitMetrics,
    setPeriod: setDatesToStore,
    metricToDelete,
    deleteMetric,
    setMetricToDelete,
    unsetMetricToDelete
  } = useUnitMetricsLibraryStore();

  const [
    searchTerm,
    setSearchTerm,
  ] = useSearchTerm();

  const {
    startDate,
    endDate,
    granularity,
    setDatesQuery
  } = useDatesQuery();

  const [metricType, setMetricType] = useState<'all' | 'base' | 'custom'>('all');

  useEffect(() => {
    setDatesToStore(
      startDate.format(SERVER_DATE_FORMAT),
      endDate.format(SERVER_DATE_FORMAT),
    );
  }, [startDate, endDate])

  const filteredUnitMetrics = useMemo(() => {
    const searchTextNormalized = searchTerm.trim().toLowerCase();

    const isCustom = metricType === 'custom';
    const filteredByType = metricType === 'all' ? unitMetrics : unitMetrics.filter((metric) => metric.is_custom === isCustom);

    if (searchTextNormalized === '') {
      return filteredByType;
    }

    return filteredByType.filter(({ name, default_filters, numerator_src, denominator_src }) => {
      return [
        name,
        default_filters?.team_name,
        srcMap[numerator_src],
        srcMap[denominator_src]
      ].some((str) => str && str.toLowerCase().includes(searchTextNormalized));
    })
  }, [searchTerm, metricType, unitMetrics]);

  const getHandleDeleteButtonClick = (metric: UnitMetricsLibraryEntry) => () => {
    setMetricToDelete(metric);
  }

  return (
    <Page>
      <Page.Head title='Unit Metrics Library'>
        <Page.Right>
        <div className='flex items-center justify-end ml-[15px] shrink min-w-0'>
              <DateFilter
                withCumulative
                startDate={startDate}
                endDate={endDate}
                granularity={'day'}
                onChange={(params) => {
                  setDatesQuery(params);
                }}
              />
              <Button
                className='ml-5'
                icon='circle-plus'
                size='sm'
                type='primary'
                style='caladon'
                label='Create New Metric'
                to='/unit-metrics/'
              />
            </div>
        </Page.Right>
      </Page.Head>
      <Section className='relative p-[20px]'>
        <div className='flex mb-[20px]'>
          <div className='flex items-center grow pl-[11px]'>
            <Icon icon='search' />
            <input
              type='text'
              className='w-full leading-[20px] text-[14px] my-[-5px] py-[10px] pl-[40px] ml-[-29px] bg-transparent outline-none border-b border-b-transparent focus:border-b-cyanine-blue'
              value={searchTerm} 
              placeholder='Search'
              onChange={(e) => {
                setSearchTerm(e.target.value)
              }}
            />
          </div>
        </div>
        <div className='mb-5'>
          <div className='flex'>
            <DateText
              description={'Current Period'}
              startMoment={startDate}
              endMoment={endDate}
            />
            <div className='ml-5'>
              <DateText
                description={'Previous Period'}
                startMoment={startDate.clone().subtract(endDate.diff(startDate, 'days') + 1, 'days')}
                endMoment={endDate.clone().subtract(endDate.diff(startDate, 'days') + 1, 'days')}
              />
            </div>
          </div>
        </div>
        <Table
          dataSource={filteredUnitMetrics}
          rowKey={({ id }) => id}
          size='small'
          rowClassName='text-[12px] line-[14px]'
          onHeaderRow={() => ({ className: 'text-[12px] line-[14px]' })}
          columns={[
            {
              key: 'is_custom',
              dataIndex: 'is_custom',
              render: (isCustom: boolean) => <Dot color={isCustom ? '#EABB56' : '#2A7BE4'} />
            },
            {
              key: 'name',
              title: 'Unit Metric',
              dataIndex: 'name',
              sorter: (a, b) => sortStrings(a.name, b.name),
              render: (name, unitMetric) => (
                <Link
                  to={`/unit-metrics/${unitMetric.id}/`}
                  onClick={() => { logEvent('click_library_row', { view: 'unit_metrics_lib' }); }}
                >
                  {name}
                </Link>
              )
            },
            {
              key: 'team',
              title: 'Team',
              render: (_, metric) => <div className='w-auto'>{metric.default_filters?.team_name}</div>
            },
            {
              key: 'numerator_src',
              title: 'Numerator Source',
              dataIndex: 'numerator_src',
              sorter: (a, b) => sortStrings(a.numerator_src, b.numerator_src),
              render: (src: src) => srcMap[src]
            },
            {
              key: 'denominator_src',
              title: 'Denominator Source',
              dataIndex: 'denominator_src',
              sorter: (a, b) => sortStrings(a.denominator_src, b.denominator_src),
              render: (src: src) => srcMap[src]
            },
            {
              key: 'daily_average',
              title: 'Current Period',
              dataIndex: 'daily_average',
              sorter:  createNullableNumbersComparator('daily_average'),
              render: (dailyAverage, metric) => <div className='truncate'>{!metric.library_data ? 'New or edited library entries take up to 6 hours to update' : !dailyAverage ? '' : formatCurrency(dailyAverage)}</div>
            },
            {
              key: 'prev_daily_average',
              title: 'Previous Period',
              dataIndex: 'prev_daily_average',
              sorter:  createNullableNumbersComparator('prev_daily_average'),
              render: (prevDailyAverage, metric) => !metric.library_data || !prevDailyAverage ? '' : formatCurrency(prevDailyAverage)
            },
            {
              key: 'wow_change_daily',
              title: 'Period-over-period Change %',
              dataIndex: 'wow_change',
              sorter: createNullableNumbersComparator('wow_change_daily'),
              render: (wowChange, metric) => <div className='truncate'>{metric.prev_daily_average === 0 ? 'Undefined (divide by 0)' : !wowChange ? '' : formatPercent(wowChange)}</div>
            },
            {
              key: 'delete',
              width: 44,
              align: 'center',
              render: (_, unitMetric) => unitMetric.is_custom ? (
                <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='trash' onClick={getHandleDeleteButtonClick(unitMetric)} />
              ) : ''
            }
          ]}
        />
        {loading && <Loading />}
      </Section>
      <DeleteDialog
        opened={metricToDelete !== null}
        onClose={unsetMetricToDelete}
        onConfirm={deleteMetric}
      />
    </Page>      
  );
};
