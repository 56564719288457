import create from 'zustand';

export interface DialogStore {
  opened: boolean;
  open: () => void;
  close: () => void;
}

export const createDialogStore = () => create<DialogStore>((set) => ({
  opened: false,
  open() {
    set({ opened: true });
  },
  close() {
    set({ opened: false });
  }
}));
