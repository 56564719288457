import type { DataEntry } from 'types/dataEntries';
import type { Granularity } from 'types/dateFilter';
import type { TagViewChart } from 'types/tagViews';

import { ValueBox } from 'shared/ValueBox';

export interface HeaderStatusProps {
  chartDataEntry: DataEntry<TagViewChart>;
  granularity: Granularity;
  format: (value: number) => string;
}

export const HeaderStatus = (props: HeaderStatusProps) => {
  const {
    chartDataEntry,
    format
  } = props

  const chartData = chartDataEntry.status === 'success' ? chartDataEntry.data : [];

  const currentTotal = chartData.reduce((st, next) => st + next.current_timeframe_tagged_percent, 0)
  const previousTotal = chartData.reduce((st, next) => st + next.previous_timeframe_tagged_percent, 0)

  const values = [
    { label: 'Current Average', text: format(currentTotal / chartData.length) },
    { label: 'Previous Average', text: format(previousTotal / chartData.length) },
  ];

  return (
    <div className="flex">
      {values.map((v) => <ValueBox.SimpleValueBox key={v.label} className="mr-7" label={v.label} text={v.text} />)}
    </div>
  )
};
