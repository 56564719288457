import type { Setter } from 'types/common';
import { useEffect, useRef, useState } from 'react';
import {InputSearch} from 'shared/Input';

export interface TextSearchProps {
  value: string;
  onChange: Setter<string>;
}
export const TextSearch = (props: TextSearchProps) => {
  const {
    value,
    onChange
  } = props;

  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const timeoutRef = useRef<any>(null);

  const clear = () => {
    timeoutRef.current !== null && clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      onChange(localValue)
    }, 1000);

    return clear;
  }, [localValue]);

  return (
    <InputSearch
      value={localValue}
      onChange={setLocalValue}
      onBlur={() => {
        onChange(localValue);
        clear();
      }}
      onKeyPress={(event) => {
        if (event.key === 'Enter') {
          onChange(localValue);
          clear();
        }
      }}
    />
  );
}
