import { useEffect, useState, useMemo } from 'react'
import type { AlertConfig } from 'types/alerts';
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import { Section } from 'shared/Section';
import { Loading } from 'shared/Loading/Loading';
import Dialog from 'shared/Dialog/Dialog';
import { formatPercent, formatCurrency, formatNormal } from 'helpers/formatter';
import { useAlertStore } from 'store/alerts';
import { createNullableStringsComparator, createNullableNumbersComparator } from 'helpers/sort';
import moment from 'moment';

export interface AlertsTableProps {
  opened: boolean;
  alertToView: AlertConfig;
  onClose: () => void;
}

const AlertsTable = (props: AlertsTableProps) => {
  const {
    opened,
    alertToView,
    onClose
  } = props;
  const { status, alerts, fetchAlerts } = useAlertStore();
  const [searchText, setSearchText] = useState('');

  const filteredAlerts = useMemo(() => {
    return alerts; 
  }, [alerts]);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    fetchAlerts(alertToView.metric_type, alertToView.id);
  }, [alertToView])

  return (
    <Dialog
      open={opened}
      onClose={() => handleClose()}
      className='w-[1020px]'
    >
      <Dialog.Title>
      {`Fired Alerts`}
      </Dialog.Title>
      <Section className='p-[20px] relative'>
        {status === 'loading' && <Loading />}
        <Table
          rowKey={({ id }) => id}
          size='small'
          rowClassName='text-[12px] line-[14px]'
          onHeaderRow={() => ({ className: 'text-[12px] line-[14px]' })}
          dataSource={filteredAlerts}
          columns={[
            {
              key: 'executed_at',
              title: 'Alert At',
              dataIndex: 'executed_at',
              defaultSortOrder: 'descend',
              sorter: createNullableStringsComparator('executed_at'),
              render: (alertAt: string) => <Link to={alertToView.metric_type === 'cost' ? `/costs-overview/${alertToView.filter_id}` : `/unit-metrics/${alertToView.filter_id}`}>{alertAt ? moment(alertAt).subtract(1, "days").format('YYYY-MM-DD') : 'n/a'}</Link>
            },
            {
              key: 'is_violated',
              title: 'Is Violated',
              dataIndex: 'is_violated',
              sorter: createNullableNumbersComparator('is_violated'),
              render: (isViolated: number) => isViolated === 1 ? 'Yes' : 'No'
            },
            {
              key: 'threshold',
              title: 'Threshold',
              dataIndex: 'threshold',
              render: (_, alert) => `${alertToView.alert_type === 'Absolute' ? `$${alertToView.threshold}` : `${formatNormal((alertToView.threshold - 1) * 100)}%`}`
            },
            {
              key: 'last_period_value',
              title: 'Value Last 7 Days',
              dataIndex: 'last_period_value',
              sorter: createNullableNumbersComparator('is_violated'),
              render: (lastValue: null | number) => lastValue === null ? 'n/a' : formatCurrency(lastValue)
            },
            {
              key: 'prelast_period_value',
              title: 'Value Last 7 to 14 Days (relative only)',
              dataIndex: 'prelast_period_value',
              sorter: createNullableNumbersComparator('is_violated'),
              render: (preLastValue: null | number) => preLastValue === null ? 'n/a' : formatCurrency(preLastValue)
            },
            {
              key: 'values_diff',
              title: 'WoW Change (relative only)',
              dataIndex: 'values_diff',
              sorter: createNullableNumbersComparator('is_violated'),
              render: (valuesDiff: null | number) => valuesDiff === null ? 'n/a' : formatPercent(valuesDiff - 1)
            },
            {
              key: 'created_at',
              title: 'Created at',
              dataIndex: 'created_at',
              sorter: createNullableStringsComparator('created_at'),
              render: (createdAt?: string) => createdAt ? moment(createdAt).format('YYYY-MM-DD') : 'n/a'
            }
          ]}
        />
      </Section>
    </Dialog>
    
  )
}

export default AlertsTable
