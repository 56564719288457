import type { DictRule, FilterValueCondition } from 'types/filters';
import type { DataEntry } from 'types/dataEntries';

import { DictRuleValue } from './DictRuleValue';
import { useDictValuesStore } from 'store/dictValues';

export interface AsyncDictRuleValueProps {
  rule: DictRule;
  field: string;
  value: Record<string, string>;
  options: string[];
  siblingValues: Record<string, string>[];
  parentValue?: FilterValueCondition[];
  disabled?: boolean;
  onChange: (value: Record<string, string>) => void;
}

export const AsyncDictRuleValue = (props: AsyncDictRuleValueProps) => {
  const {
    rule,
    field,
    value,
    options,
    siblingValues,
    parentValue,
    disabled,
    onChange
  } = props;

  const key = Object.keys(value)[0];

  const syncOptions = options.reduce((acc, opt) => ({ ...acc, [opt]: [] }), {} as Record<string, string[]>);

  const values = useDictValuesStore((store) => {
    if (key) {
      const path = parentValue ? parentValue.map(({ values }) => values[0]) as string[] : undefined;

      return store.getEntry({
        field,
        key,
        path
      });
    }

    return { status: 'idle' } as DataEntry;
  });

  if (values.status === 'success') {
    syncOptions[key] = values.data.tag_values;
  }
  
  return (
    <DictRuleValue
      rule={rule}
      value={value}
      options={syncOptions}
      siblingValues={siblingValues}
      disabled={disabled}
      onChange={onChange}
    />
  );
}
