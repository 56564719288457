import { useNavigate } from 'react-router-dom'
import Logo from 'assets/images/Logo.svg'
import LogoSmall from 'assets/images/LogoSmall.svg'

type HeaderPropsType = {
  type: string
  tokenQueryParam?: string
}

const Header = ({ type, tokenQueryParam }: HeaderPropsType) => {
  const navigate = useNavigate()

  const handleAuthRedirect = () => {
    // type === Current location
    if (type === 'signup') {
      navigate(`/login${tokenQueryParam ? '?' + tokenQueryParam : ''}`)
    } else if (type === 'login') {
      navigate(`/sign-up${tokenQueryParam ? '?' + tokenQueryParam : ''}`)
    }
  }

  return (
    <div className="flex justify-between items-center w-full pr-[15px] md:pr-[30px] h-[60px]">
      <img className="hidden md:block h-full" src={Logo} alt="Logo" />
      <img className="visible md:invisible h-full p-[10px]" src={LogoSmall} alt="Logo" />
      <div className="flex items-center">
        <span className="text-oxford-blue text-[13px] mr-[20px] hidden md:block">
          {type === 'signup' ? 'Have an account?' : "Don't have an account?"}
        </span>
        <button type="button" className="bg-caladon-blue h-[30px] px-[12px] rounded-[6px] text-white text-[13px] font-medium" onClick={handleAuthRedirect}>
          {type === 'signup' ? 'Sign In' : 'Create account now'}
        </button>
      </div>
    </div>
  )
}

export default Header
