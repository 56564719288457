import axios from 'axios'
import { useGlobalState } from 'state/globalState';
import { getCookie } from './cookie';

export const API_HOST = process.env.REACT_APP_API_HOST

const axiosInstance = axios.create({
  baseURL: API_HOST,
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  const csrfToken = getCookie('csrf_token');

  if (csrfToken) {
    config.headers = config.headers || {};
    config.headers['X-CSRF-TOKEN'] = csrfToken;
  };

  return config;
});

axiosInstance.interceptors.response.use(
  undefined,
  (error) => {
    if (
      error.response?.status === 401 &&
      error.response?.data?.error === 'invalid_auth_token'
    ) {
      const {
        initialized,
        logout
      } = useGlobalState.getState();  

      if (initialized) {
        logout();
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
