import type { Moment } from 'moment';
import type { ReactNode } from 'react';

import { Section } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { useTotalCost } from 'hooks/totalCost';
import { formatCurrency, formatPercent } from 'helpers/formatter';
import { useSavingsSettingsStore } from 'store/savings';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';

export interface SpendSectionProps {
  className?: string;
  title: ReactNode;
  currentPeriodText: ReactNode;
  currentPeriodStart: Moment;
  currentPeriodEnd: Moment;
  previousPeriodText: ReactNode;
  previousPeriodStart: Moment;
  previousPeriodEnd: Moment;
}

export const SpendSection = (props: SpendSectionProps) => {
  const {
    className = '',
    title,
    currentPeriodText,
    currentPeriodStart,
    currentPeriodEnd,
    previousPeriodText,
    previousPeriodStart,
    previousPeriodEnd
  } = props;

  const teamId = useSavingsSettingsStore((store) => store.teamId);
  const globalFilterIds = useSavingsSettingsStore((store) => store.globalFilterIds);
  const platform = useSavingsSettingsStore((store) => store.platform);
  
  const currentPeriodSpendAWS = useTotalCost({
    startDate: currentPeriodStart.format('YYYY-MM-DD'),
    endDate: currentPeriodEnd.format('YYYY-MM-DD'),
    filter: {
      src: 'cur',
      filter: { operator: 'or', condition: [] }
    },
    teamId,
    globalFilterIds,
  });

  const currentPeriodSpendGCP = useTotalCost({
    startDate: currentPeriodStart.format('YYYY-MM-DD'),
    endDate: currentPeriodEnd.format('YYYY-MM-DD'),
    filter: {
      src: 'gcp',
      filter: { operator: 'or', condition: [] }
    },
    teamId,
    globalFilterIds,
  });

  const currentPeriodSpendAll = useDerivedDataEntry(
    { currentPeriodSpendAWS, currentPeriodSpendGCP },
    ({ currentPeriodSpendAWS, currentPeriodSpendGCP }) => currentPeriodSpendAWS + currentPeriodSpendGCP
  );

  const previousPeriodSpendAWS = useTotalCost({
    startDate: previousPeriodStart.format('YYYY-MM-DD'),
    endDate: previousPeriodEnd.format('YYYY-MM-DD'),
    filter: {
      src: 'cur',
      filter: { operator: 'or', condition: [] }
    },
    teamId,
    globalFilterIds,
  });

  const previousPeriodSpendGCP = useTotalCost({
    startDate: previousPeriodStart.format('YYYY-MM-DD'),
    endDate: previousPeriodEnd.format('YYYY-MM-DD'),
    filter: {
      src: 'gcp',
      filter: { operator: 'or', condition: [] }
    },
    teamId,
    globalFilterIds,
  });

  const previousPeriodSpendAll = useDerivedDataEntry(
    { previousPeriodSpendAWS, previousPeriodSpendGCP },
    ({ previousPeriodSpendAWS, previousPeriodSpendGCP }) => previousPeriodSpendAWS + previousPeriodSpendGCP
  );

  const [
    currentPeriodSpend,
    previousPeriodSpend
  ] = platform === 'aws' ? [
    currentPeriodSpendAWS,
    previousPeriodSpendAWS
  ] : platform === 'gcp' ? [
    currentPeriodSpendGCP,
    previousPeriodSpendGCP
  ] : [
    currentPeriodSpendAll,
    previousPeriodSpendAll
  ];

  return (
    <Section
      className={`p-5 relative ${className}`}
    >
      <h2 className='font-medium'>
        {title}
        <span className='ml-2 font-normal text-caption-1'>
          {currentPeriodText}
        </span>
      </h2>

      {currentPeriodSpend.status === 'success' && <>
        <h3 className='my-2.5 font-medium text-large-title'>
          {formatCurrency(currentPeriodSpend.data)}
        </h3>

        {previousPeriodSpend.status === 'success' && (() => {
          if (previousPeriodSpend.data === 0) {
            return;
          }

          const ratio = currentPeriodSpend.data / previousPeriodSpend.data;
          const diff = 1 - ratio;

          return (
            <div className='mb-1 text-caption-1'>
              <span className={`inline-flex items-center rounded-1 px-1 ${diff > 0 ? 'bg-green/20' : 'bg-red/20'}`}>
                <span className={`rounded-0.5 inline-block w-2 h-2 mr-1 ${diff > 0 ? 'bg-green' : 'bg-red'}`} />
                {' '}
                {diff < 0 ? '+' : ''}
                {formatPercent(-diff)}
              </span>

              {' '}from {previousPeriodText}
            </div>
          );
        })()}
      </>}
      
      <StatusOverlay status={currentPeriodSpend.status} />
    </Section>
  );
};
