import type {
  EC2OfferingClass,
  Lookback,
  CostImpactSummaryParams,
  CostImpactSummaryRequestBody,
  CostImpactSummaryRequestQuery,
  CostImpactSummaryRequestParams,
  CostImpactSummaryParamsChunk,
  CostImpactSummaryRequestQueryChunk,
  CostImpactSummaryRequestParamsChunk,
  SavedAndWastedParams,
  SavedAndWastedRequestBody,
  SavedAndWastedRequestQuery,
  SavedAndWastedRequestParams,
  SavedAndWastedParamsChunk,
  SavedAndWastedRequestQueryChunk,
  SavedAndWastedRequestParamsChunk,
} from 'types/savings';

import { serializeTags, buildTextSearch} from './requestUtils';

export const costImpactSummaryParamsToRequestParams = (params: CostImpactSummaryParams): CostImpactSummaryRequestParams => {
  const {
    prefilter,
    tableFilters: {
      difficulty,
      category,
      service,
      account,
      region,
      tags,
      //account_tags,
    },
    pageFilters,
    groupBy,
  } = params;

  const query: CostImpactSummaryRequestQuery = {
    group_by: groupBy,

    ...prefilter,
    team_id: prefilter.team_id ? prefilter.team_id : pageFilters && pageFilters.teamId,
    global_filter_ids: pageFilters?.globalFilterIds,
    meta_category: pageFilters?.group && {
      both: ['usage', 'rate', 'custom'],
      usage: ['usage', 'custom'],
      rate: ['rate', 'custom']
    }[pageFilters.group] as ('usage' | 'rate' | 'custom')[],
    platform: pageFilters?.platform && (pageFilters.platform === 'all' ? ['aws', 'gcp'] : [pageFilters.platform]),
    priority: pageFilters?.priority,

    text_search: buildTextSearch(params),
    difficulty,
    category,
    service,
    account,
    region,
    tags: tags ? serializeTags(tags) : undefined,
    //account_tags: serializeTags(account_tags),

    paging_size: 1000
  };

  const body: CostImpactSummaryRequestBody = {
    source: pageFilters?.source,
    savings_plans: pageFilters?.rateCommitments?.savingsPlans.enabled ? {
      term: pageFilters.rateCommitments.savingsPlans.term,
      payment_opt: pageFilters.rateCommitments.savingsPlans.payment,
      lookback_days: pageFilters.rateCommitments.savingsPlans.lookback as Lookback
    } : undefined,
    reserved_instances: pageFilters?.rateCommitments?.reservedInstances.enabled ? {
      term: pageFilters.rateCommitments.reservedInstances.term,
      payment_opt: pageFilters.rateCommitments.reservedInstances.payment as 'NO_UPFRONT' | 'PARTIAL_UPFRONT',
      offering_class: pageFilters.rateCommitments.reservedInstances.ec2OfferingClass as EC2OfferingClass
    } : undefined
  };

  return [query, body]
};

export const costImpactSummaryParamsToRequestParamsChunk = (params: CostImpactSummaryParamsChunk): CostImpactSummaryRequestParamsChunk => {
  const {
    prefilter,
    tableFilters: {
      text_search,
      difficulty,
      category,
      service,
      account,
      region,
      tags,
      //account_tags,
    },
    pageFilters,
    groupBy,
    teamChunks,
  } = params;



  const queries = teamChunks.map((teamChunk) => {
    return {
      group_by: groupBy,
      group_by_team_filter: teamChunk,
  
      ...prefilter,
      team_id: prefilter.team_id ? prefilter.team_id : pageFilters && pageFilters.teamId,
      global_filter_ids: pageFilters && pageFilters.globalFilterIds,
      meta_category: pageFilters && {
        both: ['usage', 'rate', 'custom'],
        usage: ['usage', 'custom'],
        rate: ['rate', 'custom']
      }[pageFilters.group] as ('usage' | 'rate' | 'custom')[],
      platform: pageFilters && (pageFilters.platform === 'all' ? ['aws', 'gcp'] : [pageFilters.platform]),
      priority: pageFilters && pageFilters.priority,
  
      text_search,
      difficulty,
      category,
      service,
      account,
      region,
      tags: tags ? serializeTags(tags) : undefined,
      //account_tags: serializeTags(account_tags),
  
      paging_size: 1000
    } as CostImpactSummaryRequestQueryChunk
  });

  const body: CostImpactSummaryRequestBody = {
    source: pageFilters && pageFilters.source,
    savings_plans: pageFilters && pageFilters.rateCommitments.savingsPlans.enabled ? {
      term: pageFilters.rateCommitments.savingsPlans.term,
      payment_opt: pageFilters.rateCommitments.savingsPlans.payment,
      lookback_days: pageFilters.rateCommitments.savingsPlans.lookback as Lookback
    } : undefined,
    reserved_instances: pageFilters && pageFilters.rateCommitments.reservedInstances.enabled ? {
      term: pageFilters.rateCommitments.reservedInstances.term,
      payment_opt: pageFilters.rateCommitments.reservedInstances.payment as 'NO_UPFRONT' | 'PARTIAL_UPFRONT',
      offering_class: pageFilters.rateCommitments.reservedInstances.ec2OfferingClass as EC2OfferingClass
    } : undefined
  };

  return queries.map((query) => [query, body])
};

export const savedAndWastedParamsToRequestParams = (params: SavedAndWastedParams): SavedAndWastedRequestParams => {
  const {
    prefilter,
    tableFilters: {
      difficulty,
      category,
      service,
      account,
      region,
      tags,
      //account_tags,
    },
    pageFilters: {
      teamId,
      globalFilterIds,
      priority,
      platform,
      group,
      source,
      rateCommitments: {
        savingsPlans,
        reservedInstances
      },
    },
    startDate,
    endDate,
    groupBy,
    granularity,
    teamsDeduplication,
  } = params;

  const query: SavedAndWastedRequestQuery = {
    start_date: startDate,
    end_date: endDate,
    group_by: groupBy,
    granularity,
    teams_deduplication: teamsDeduplication,

    ...prefilter,
    team_id: prefilter.team_id ? prefilter.team_id : teamId,
    global_filter_ids: globalFilterIds,
    meta_category: {
      both: ['usage', 'rate', 'custom'],
      usage: ['usage', 'custom'],
      rate: ['rate', 'custom']
    }[group] as ('usage' | 'rate' | 'custom')[],
    platform: platform === 'all' ? ['aws', 'gcp'] : [platform],
    priority,

    difficulty,
    category,
    service,
    account,
    region,
    tags: tags ? serializeTags(tags) : undefined,
    //account_tags: serializeTags(account_tags),

    paging_size: 1000
  };

  const body: SavedAndWastedRequestBody = {
    source,
    savings_plans: savingsPlans.enabled ? {
      term: savingsPlans.term,
      payment_opt: savingsPlans.payment,
      lookback_days: savingsPlans.lookback as Lookback
    } : undefined,
    reserved_instances: reservedInstances.enabled ? {
      term: reservedInstances.term,
      payment_opt: reservedInstances.payment as 'NO_UPFRONT' | 'PARTIAL_UPFRONT',
      offering_class: reservedInstances.ec2OfferingClass as EC2OfferingClass
    } : undefined
  };

  return [query, body]
};

export const savedAndWastedParamsToRequestParamsChunk = (params: SavedAndWastedParamsChunk): SavedAndWastedRequestParamsChunk => {
  const {
    prefilter,
    tableFilters: {
      difficulty,
      category,
      service,
      account,
      region,
      tags,
      account_tags,
    },
    pageFilters: {
      teamId,
      globalFilterIds,
      priority,
      platform,
      group,
      source,
      rateCommitments: {
        savingsPlans,
        reservedInstances
      },
    },
    startDate,
    endDate,
    groupBy,
    teamChunks,
    granularity,
    teamsDeduplication,
  } = params;

  const queries = teamChunks.map((teamChunk) => {
    return {
      start_date: startDate,
      end_date: endDate,
      group_by: groupBy,
      group_by_team_filter: teamChunk,
      granularity,
      teams_deduplication: teamsDeduplication,
  
      ...prefilter,
      team_id: prefilter.team_id ? prefilter.team_id : teamId,
      global_filter_ids: globalFilterIds,
      meta_category: {
        both: ['usage', 'rate', 'custom'],
        usage: ['usage', 'custom'],
        rate: ['rate', 'custom']
      }[group] as ('usage' | 'rate' | 'custom')[],
      platform: platform === 'all' ? ['aws', 'gcp'] : [platform],
      priority,
  
      text_search: buildTextSearch(params),
      difficulty,
      category,
      service,
      account,
      region,
      tags: tags ? serializeTags(tags) : undefined,
      account_tags: account_tags? serializeTags(account_tags) : undefined,
  
      paging_size: 1000
    } as SavedAndWastedRequestQueryChunk
  });

  const body: SavedAndWastedRequestBody = {
    source,
    savings_plans: savingsPlans.enabled ? {
      term: savingsPlans.term,
      payment_opt: savingsPlans.payment,
      lookback_days: savingsPlans.lookback as Lookback
    } : undefined,
    reserved_instances: reservedInstances.enabled ? {
      term: reservedInstances.term,
      payment_opt: reservedInstances.payment as 'NO_UPFRONT' | 'PARTIAL_UPFRONT',
      offering_class: reservedInstances.ec2OfferingClass as EC2OfferingClass
    } : undefined
  };

  return queries.map((query) => [query, body])
};
