import type { DataEntry } from 'types/dataEntries';
import type { JiraProject } from 'types/savings'
import { useMemo } from 'react';
import { Select } from 'antd';

export interface JiraProjectSelectProps {
  value: string;
  onChange: (value: string) => void;
  store: () => DataEntry<JiraProject[], any>;
}

export const JiraProjectSelect = (props: JiraProjectSelectProps) => {
  const {
    value,
    onChange,
    store
  } = props;
  
  const projects = store();

  const options = useMemo(() => projects.status === 'success' ?
    projects.data.map(({ key, name }) => ({
      value: key,
      label: name
    })) :
    []
  , [projects]);

  return (
    <Select
      className='w-full'
      value={value}
      options={options}
      onChange={onChange}
      allowClear
    />
  );
};
