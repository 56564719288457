import type { DataNode } from 'antd/lib/tree';
import type { Setter } from 'types/common';
import type { ListDataFilterValue, ListDataFilterOption, ListDataFilterOptions, ListDataFilterConfig } from 'types/dataFilters';

import { useState, useEffect, useMemo } from 'react';
import { Tree, TreeDataNode, Input } from 'antd';
import { DropdownFooter } from './DropdownFooter';

export interface ListDataFilterDropdownProps<T extends (string | number)> {
  opened: boolean;
  value: ListDataFilterValue<T>;
  config: ListDataFilterConfig;
  options: ListDataFilterOptions<T>;
  onChange: Setter<ListDataFilterValue<T>>;
}

const filterTreeData = (data: TreeDataNode[], search: string): TreeDataNode[] => data.map((item) => {
  if (typeof item.title === 'string' && item.title.toLowerCase().includes(search)) {
      return item;
  }

  if (item.children) {
    const children = filterTreeData(item.children, search);
    
    return children.length ? { ...item, children } : null;
  }

  return null;
}).filter(Boolean) as TreeDataNode[];

export const ListDataFilterDropdown = <T extends (string | number)>(props: ListDataFilterDropdownProps<T>) => {
  const {
    value,
    opened,
    config,
    options,
    onChange
  } = props;

  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    //if (opened) {
      setLocalValue(value);
    //}
  }, [opened]);

  const [
    treeData,
    leavesMap
  ] = useMemo(() => {
    const leavesMap: { [key: string]: boolean } = {};

    const toTree = (item: ListDataFilterOption<string | number>): DataNode  => {
      leavesMap[item.value] = !item.children;

      return {
        key: item.value,
        title: item.text,
        children: item.children ? item.children.map(toTree) : undefined
      };
    };

    return [
      options.map(toTree),
      leavesMap
    ];
  }, [options]);

  const [search, setSearch] = useState('');

  const filteredData = useMemo(() =>
    (config.searchable ?
      filterTreeData(treeData, search.trim().toLowerCase()) :
      treeData
    ).slice(0, 50),
    [config, search, treeData]
  );

  return (
    <div className={`-m-3 ${config.searchable ? 'w-[400px]' : ''}`}>
      {config.searchable &&
        <div className='p-2 border-b'>
          <Input
            value={search}
            allowClear
            placeholder='Search options'
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
        </div>
      }

      <div className='max-h-[280px] pt-[4px] pr-[8px] overflow-y-auto'>
        {filteredData.length > 100 &&
          <div className='p-2 text-center text-gray-500 text-caption-1'>
            Showing first 100 out of {filteredData.length} items.<br/>
            Please filter list to find your desired value
          </div>
        }
        {filteredData.length > 0 ?
          <Tree
            checkable
            checkedKeys={localValue}
            onCheck={(newValue) => {
              Array.isArray(newValue) && setLocalValue(newValue as T[]);
            }}
            selectable={false}
            defaultExpandAll
            treeData={filteredData.slice(0, 100)}
          /> :
          <div className='p-4 text-center'>
            No results found
          </div>
        }
      </div>

      <DropdownFooter
        onApply={() => {
          onChange(localValue.filter((item) => leavesMap[item]));
        }}
        onReset={() => {
          onChange([]);
        }}
      />
    </div>
  );
}
