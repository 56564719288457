import { useCallback, useEffect, useState, } from 'react'

const current: {onClose?: () => void, tid?: number} = {
}

export const useHover = (onOpen: () => void, onClose: () => void) => {
  const [targetElem, setTargetElem] = useState<HTMLElement | null>()
  const [popoverElem, setPopoverElem] = useState<HTMLElement | null>()

  const clearTimer = () => {
    if (current.tid) { clearTimeout(current.tid) }
    delete current.tid
  }
  const hideImmediate = () => onClose()
  const hide = () => {
    current.tid = window.setTimeout(hideImmediate, 300)
  }
  const open = () => {
    if (current.onClose) {
      current.onClose()
    }

    clearTimer()

    onOpen()
    current.onClose = onClose
  }

  useEffect(() => {
    if (!targetElem) {
      return () => {}
    }

    targetElem.addEventListener('mouseenter', open)
    targetElem.addEventListener('mouseleave', hide)

    return () => {
      targetElem?.removeEventListener('mouseenter', onOpen)
      targetElem?.removeEventListener('mouseleave', hide)
    }
  }, [targetElem])

  useEffect(() => {
    if (!popoverElem) {
      return () => {}
    }

    popoverElem.addEventListener('mouseenter', clearTimer)
    popoverElem.addEventListener('mouseleave', hide)

    return () => {
      popoverElem.removeEventListener('mouseenter', clearTimer)
      popoverElem.removeEventListener('mouseleave', hide)
    }
  }, [popoverElem])

  const setTargetRef = useCallback((node: HTMLElement | null) => setTargetElem(node), [])
  const setPopoverRef = useCallback((node: HTMLElement | null) => setPopoverElem(node), [])

  return {
    setTargetRef,
    setPopoverRef,
  }

  // const hideImmediate = () => onClose()
  //
  // const hide = () => setTimeout(hideImmediate, 200)
  //
  // useEffect(() => {
  //   // console.log('H', target.current, popover.current)
  //   if (!target.current || !popover.current) {
  //     return () => {}
  //   }
  //
  //   const handleTargetEnter = () => {
  //     console.log('ENTERING')
  //   }
  //
  //   const handleTargetLeave = () => {
  //     hide()
  //   }
  //
  //   target.current.addEventListener('mouseenter', handleTargetEnter)
  //   target.current.addEventListener('mouseleave', handleTargetLeave)
  //
  //   return () => {
  //     target.current?.removeEventListener('mouseenter', handleTargetEnter)
  //     target.current?.removeEventListener('mouseleave', handleTargetLeave)
  //   }
  // }, [target.current, popover.current])
}
