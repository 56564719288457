import type { Moment } from 'moment';
import type { Granularity } from 'types/common';

export const minDate = (momentA: Moment, momentB: Moment) => momentA.isBefore(momentB) ? momentA : momentB;
export const maxDate = (momentA: Moment, momentB: Moment) => momentA.isAfter(momentB) ? momentA : momentB;

export const mapDates = <T>(params: {
  start: Moment,
  end: Moment,
  step?: Granularity
  callback: (date: Moment) => T
}): T[] => {
  const {
    start,
    end,
    step = 'day',
    callback
  } = params;

  const result: T[] = [];

  let index = start.clone().startOf(step);

  while (!index.isAfter(end)) {
    result.push(callback(index));

    index.add(step, 1);
  }

  return result;
}
