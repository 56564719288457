import type { Dayjs } from 'dayjs';
import type { Opportunity } from 'types/savings';

import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Input, message, Radio } from 'antd';
import { FormRow, Label } from 'shared/Form';
import { Button2 as Button } from 'shared/Button';
import { Dialog, DialogBody, DialogFooter } from 'shared/Dialog';
import { pickOpportunityCategory, renderOpportunitySlug, renderOpportunityTypeIdString, AUTOMATION_DESCRIPTIONS, renderOpportunityLink } from 'helpers/savings';
import { useOpportunityAutomationDialog, useSavingsStore } from 'store/savings';
import { pluralize } from 'helpers/formatter';
import { Link } from 'react-router-dom';

export const OpportunityAutomationDialog = () => {
  const { id: opportunities, close, open } = useOpportunityAutomationDialog();

  const [schedulingEnabled, setSchedulingEnabled] = useState(false);
  const [runAt, setRunAt] = useState<Dayjs | null>(null);
  const [comment, setComment] = useState('');

  const minDate = useMemo(dayjs, []);

  const automateOpportunity = useSavingsStore((store) => store.automateOpportunity);

  useEffect(() => {
    if (opportunities === null) {
      setSchedulingEnabled(false);
      setRunAt(null);
      setComment('');
    }
  }, [opportunities]);

  const byCategory = useMemo(() => {
    if (!opportunities) {
      return {};
    }

    return opportunities.reduce((acc, opp) => {
      const category = pickOpportunityCategory(opp);

      if (category === null) {
        return acc;
      }

      if (!acc[category]) {
        acc[category] = {
          title: renderOpportunityTypeIdString(opp),
          opportunities: []
        };
      }

      acc[category].opportunities.push(opp);

      return acc;
    }, {} as {
      [key: string]: {
        title: string,
        opportunities: Opportunity[]
      }
    });
  }, [opportunities]);

  return (
    <Dialog
      className='w-[640px]'
      open={opportunities !== null}
      onClose={close}
    >
      {opportunities && <>
        <Dialog.Title>
          Automate {opportunities.length === 1 ?
            renderOpportunitySlug(opportunities[0]) :
            `${opportunities.length} ${pluralize(opportunities.length, { one: 'opportunity', other: 'opportunities' })}`
          }
        </Dialog.Title> 

        <DialogBody scroll className='text-subtitle-2'>
          {Object.entries(byCategory)
            .map(([category, info]) => (
              <FormRow key={category} size='m'>
                <Label size='m'>{info.title}</Label>
                
                <p className='my-2'>
                  {opportunities.length > 1 && info.opportunities.map((opp, index) => <span>
                    {index > 0 && ', '}
                    {renderOpportunityLink(opp)}
                    <Button
                      className='ml-1'
                      size='xs'
                      theme='gray'
                      layout='inline'
                      icon='close'
                      onClick={() => {
                        open(opportunities.filter((o) => o !== opp));
                      }}
                    />
                  </span>)}
                </p>

                <p className='my-2'>
                  {AUTOMATION_DESCRIPTIONS[category]}
                </p>
              </FormRow>
            ))
          }

          <hr className='mb-5 -mx-8'/>

          <FormRow size='m' 
            //className='flex items-center gap-2'
          >
            <Label size='m'>
              Execution time
            </Label>
            {/*
            <Toggle
              checked={schedulingEnabled}
              onChange={setSchedulingEnabled}
            />
              */}

            <div className='mb-2'>
              <Radio
                className='text-subtitle-2'
                checked={!schedulingEnabled}
                onChange={(event) => {
                  if (event.target.checked) {
                    setSchedulingEnabled(false);
                  }
                }}
              >
                Execute now
              </Radio>

              <p className='ml-[23px] text-gray-500 text-caption-1'>
                Action will be executed within 1 minute
              </p>
            </div>

            <div>
              <Radio
                className='text-subtitle-2'
                checked={schedulingEnabled}
                onChange={(event) => {
                  if (event.target.checked) {
                    setSchedulingEnabled(true);
                  }
                }}
              >
                Schedule for
              </Radio>

              <DatePicker
                size='large'
                disabled={!schedulingEnabled}
                disabledDate={(date) => date.isBefore(minDate)}
                format='YYYY-MM-DD HH:mm'
                showTime={{
                  format: 'HH:mm',
                  minuteStep: 5
                }}
                value={runAt}
                onChange={setRunAt}
              />
            </div>
          </FormRow>

          <FormRow size='m'>
            <Label size='m'>
              Comment
            </Label>

            <Input.TextArea
              size='large'
              rows={3}
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
            />
          </FormRow>

          <p>
            Actions will be logged in <Link
              to='/settings/savings-opportunities#automation-log'
              className='text-blue hover:text-blue-hover'
              target='_blank'
            >
              Change Log
            </Link>
          </p>
        </DialogBody>
        
        <DialogFooter>
          <Button
            size='m'
            theme='link'
            onClick={close}
          >
            Cancel
          </Button>

          <Button
            size='m'
            theme='filled'
            disabled={schedulingEnabled && runAt === null}
            onClick={() => {
              const basePayload = {
                run_at: schedulingEnabled && runAt ?
                  runAt.toISOString() :
                  dayjs().toISOString(),
                comment
              };
              
              Promise.allSettled(
                opportunities.map((opp) => 
                  automateOpportunity({
                    ...basePayload,
                    opportunity_filter: {
                      id: opp.id
                    }
                  })
                    .then(() => {
                      message.success(
                        <>
                          <b>{renderOpportunitySlug(opp)}</b> {schedulingEnabled ? 'scheduled' : 'executed'}
                        </>
                      );
                    })
                    .catch((error) => {
                      message.error(
                        <>
                          Could not {schedulingEnabled ? 'schedule' : 'execute'} <b>{renderOpportunitySlug(opp)}</b>
                          {error?.response?.data?.error === 'aws_account_automation_not_enabled' &&
                            `: ${error?.response?.data?.message}`
                          }
                        </>
                      );

                      throw error;
                    })
                )
              )
                .then((results) => {
                  const fulfilled: Opportunity[] = [];
                  const rejected: Opportunity[] = [];

                  results.forEach((result, i) => {
                    (result.status === 'fulfilled' ?
                      fulfilled :
                      rejected
                    ).push(opportunities[i])
                  });

                  if (rejected.length === 0) {
                    close()
                  } else {
                    open(rejected);
                  }
                });
            }}
          >
            {schedulingEnabled ? 'Schedule Action' : 'Execute action'}
          </Button>
        </DialogFooter>
      </>}
    </Dialog>
  );
}
