import type { DraftKey } from 'types/dataEntries';
import { useState, useEffect } from 'react';
import { message } from 'antd'
import { Button } from 'shared/Button';
import { GlobalFilterBlock } from './GlobalFilterBlock';
import { CreateDialog } from './CreateDialog';
import { useGlobalFiltersStore } from 'store/globalFilters';
import { GlobalFilter, GlobalFilterId } from 'types/globalFilters'
import { DeleteGlobalFilterDialog } from './DeleteGlobalFiltersDialog';
import { StatusOverlay } from 'shared/Overlay';
import { fixedDraftKey } from 'types/dataEntries';

export const GlobalFiltersTab = () => {
  const globalFilters = useGlobalFiltersStore((state) => state.getLibrary());

  const deleteGlobalFilter = useGlobalFiltersStore((state) => state.deleteEntry);

  const [editKey, setEditKey] = useState<DraftKey | GlobalFilterId | null>(null);
  const [filterToDelete, setGlobalFilterToDelete] = useState<GlobalFilter | null>(null);

  const handleEditGlobalFilter = (filter: GlobalFilter) => {
    setEditKey(filter.id);
  }

  return (
    <div className='pt-[35px]'>
      <div className='min-h-[200px]'>
        <div className='flex mb-[20px]'>
          <Button
            className='ml-auto'
            size='sm'
            type='primary'
            icon='circle-plus'
            style='caladon'
            label='Create New GlobalFilter'
            onClick={() => {
              setEditKey(fixedDraftKey);
            }}
          />
        </div>

        {globalFilters.status === 'success' && globalFilters.data.map((filter) => (
          <GlobalFilterBlock
            filter={filter}
            editGlobalFilter={handleEditGlobalFilter}
            deleteGlobalFilter={setGlobalFilterToDelete}
          />
        ))}

        <StatusOverlay status={globalFilters.status }/>

        <CreateDialog
          draftKey={editKey}
          onClose={() => {
            setEditKey(null);
          }}
        />

        <DeleteGlobalFilterDialog
          open={!!filterToDelete}
          name={filterToDelete?.name || ''}
          onClose={() => setGlobalFilterToDelete(null)}
          onConfirm={() => {
            if (!filterToDelete) {
              return;
            }

            deleteGlobalFilter(filterToDelete.id)
              .then(() => {
                setGlobalFilterToDelete(null);
                message.success(`GlobalFilter '${filterToDelete.name}' deleted`);
              })
              .catch(() => {
                message.error(`Unable to delete '${filterToDelete.name}'`);
              });
          }}
        />
      </div>
    </div>
  );
};
