import { Outlet } from 'react-router-dom'
import SideMenu from 'components/SideMenu'

export const ShellRoute = () => {
  return (
    <div className='max-w-[1680px] w-full flex mx-auto transition-all'>
      <SideMenu />
      <Outlet />
    </div>
  )
};
