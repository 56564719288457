import create from 'zustand'

import type { FetchStatus } from 'types/common';
import type { CFNStack } from 'types/dataCollection';

import {
  getCFNStacks,
  saveCFNStack,
  deleteCFNStack,
} from 'services/dataCollection'

interface FetchParams {
  force?: boolean;
}

export interface DataState {
  status: FetchStatus;
  cfnStacks: CFNStack[];
  connectURL: string;
  checkInterval: number | null;
}

export interface DataActions {
  setCheckInterval: (checkInterval: number | null) => void;
  fetchCFNStacks: (params?: FetchParams) => void;
  saveCFNStacks: (stack: any) => void;
  deleteCFNStacks: (id: number, stack_type?: string) => Promise<void>;
}

export interface CFNStacksStore extends DataState, DataActions {}

const DEFAULT_STATE: DataState = {
  status: 'idle',
  cfnStacks: [],
  connectURL: '',
  checkInterval: 2000,
};

export const buildAwsUrl = (stackToken: string, stackBucket: string, stackKey: string) => {
  const newToken = stackToken.replace(/[^0-9a-z]/gi, '').slice(0,10)
  return `https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?stackName=cloudthread-access-${newToken}&templateURL=http://s3.us-west-2.amazonaws.com/${stackBucket}/${stackKey}`
}

export const useCFNStacksStore = create<CFNStacksStore>((set, get) => {
  let requestCount = 0;

  return {
    ...DEFAULT_STATE,

    fetchCFNStacks: async (params = {}) => {
      const { force } = params;
      const { status } = get();

      /*
      if (!force && ['loading', 'success'].includes(status)) {
        return;
      }
      */

      try {
        if (status !== 'success') {
          set({ status: 'loading' });
        }

        const requestNumber = ++requestCount;
        
        const { data: stacks } = await getCFNStacks();
        const cfnStacks = stacks.map((data: any) => {
          const newCFNStack: CFNStack = {
            id: data.id,
            stack_token: data.stack_token,
            stack_type: data.stack_details.stack_type,
            stack_key: data.stack_details.stack_key,
            stack_bucket: data.stack_details.stack_bucket,
            aws_org_root_id: data.stack_details.aws_org_root_id,
            aws_ou_ids: data.stack_details.aws_ou_ids,
            aws_accounts: data.stack_details.aws_accounts,
            aws_account_filter_type: data.stack_details.aws_account_filter_type,
            aws_org_existing_cur_report_name: data.stack_details.aws_org_existing_cur_report_name,
            aws_org_existing_cur_report_prefix: data.stack_details.aws_org_existing_cur_report_prefix,
            aws_org_existing_cur_report_bucket: data.stack_details.aws_org_existing_cur_report_bucket,
            status: data.status,
            link: buildAwsUrl(data.stack_token, data.stack_details.stack_bucket, data.stack_details.stack_key),
            created_at: data.created_at,
          }
          return newCFNStack
        })

        if (requestNumber === requestCount) {
          set({
            cfnStacks,
            status: 'success'
          });
        }
      } catch(error) {
        set({ status: 'error' });
      }
    },

    saveCFNStacks: async (stack: any) => {
      const { cfnStacks } = get();
      try {
        const { data } = await saveCFNStack(stack)
        const newCFNStack: CFNStack = {
          id: data.id,
          stack_token: data.stack_token,
          stack_type: data.stack_details.stack_type,
          stack_key: data.stack_details.stack_key,
          stack_bucket: data.stack_details.stack_bucket,
          aws_org_root_id: data.stack_details.aws_org_root_id,
          aws_ou_ids: data.stack_details.aws_ou_ids,
          aws_accounts: data.stack_details.aws_accounts,
          aws_account_filter_type: data.stack_details.aws_account_filter_type,
          aws_org_existing_cur_report_name: data.stack_details.aws_org_existing_cur_report_name,
          aws_org_existing_cur_report_prefix: data.stack_details.aws_org_existing_cur_report_prefix,
          aws_org_existing_cur_report_bucket: data.stack_details.aws_org_existing_cur_report_bucket,
          status: data.status,
          link: buildAwsUrl(data.stack_token, data.stack_details.stack_bucket, data.stack_details.stack_key),
          created_at: data.created_at,
        }
        const static_stacks = cfnStacks.filter((stack) => stack.id !== newCFNStack.id)
        set({ cfnStacks: [newCFNStack, ...static_stacks] })
      } catch(error) {
        set({ cfnStacks });
        throw error;
      }
    },

    deleteCFNStacks: async (id: number) => {
      const { cfnStacks } = get();
      set({ cfnStacks: cfnStacks.filter((stack) => stack.id !== id) });

      try {
        await deleteCFNStack(id);
      } catch(error) {
        set({ cfnStacks });
        throw error;
      }
    },

    setCheckInterval(checkInterval: number | null) {
      set({ checkInterval })
    },
  };
});
