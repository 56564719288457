import { useGlobalState } from 'state/globalState';
import {useTeamsStore} from 'store/teams';

export const UserTeam = () => {
  const user = useGlobalState((state) => state.user);
  const team = useTeamsStore((store) => user && user.team ? store.getEntry(user.team.id) : null);

  if (team && team.status === 'success') {
    return (
      <div className='m-[10px] mr-auto'>
        <div className='mb-1 font-bold text-[11px]'>
          Team: {team.data.name}
        </div>
      </div>
    );
  }

  return null;
}
