import type { Moment } from 'moment';
import type { Granularity } from 'types/common';
import type {
  UnitMetricChart,
  UnitMetricChartDataKey
} from 'types/unitMetrics';
import { ChartType } from 'types/chart';

import { useCallback, useMemo } from 'react';
import { Table } from 'antd'
import { formatCurrency } from 'helpers/formatter';
import { Section } from 'shared/Section';
import { Loading } from 'shared/Loading';
import { DateText } from 'shared/DateText'
import { PeriodChart } from 'shared/PeriodChart';
import { PeriodBarChart } from 'shared/PeriodBarChart';
import { ChartInfo } from './ChartInfo';
import { EventsData } from 'types/costViews/events';
import { Select as AntdSelect } from 'antd'


type ChartDataType = 'unit_metric' | 'numerator' | 'denominator';

export interface ChartSectionProps {
  type: ChartDataType;
  chart: UnitMetricChart | null;
  status: 'empty' | 'loading' | 'success' | 'error';
  granularity: Granularity;
  format?: (value: number) => string;
  chartType: ChartType;
  startMoment: Moment;
  endMoment: Moment;
  prevStartMoment: Moment;
  prevEndMoment: Moment;
  events?: EventsData[];
  setEventsToDisplay?: (targetElements: EventsData[]) => void;
  eventsToDisplay?: EventsData[];
  evnetsOverlay?: boolean;
  setEventTypes?: (types: string[]) => void;
  eventTypes?: string[];
  selectedEventTypes?: string[];
}

const TITLES = {
  unit_metric: 'Cost per Unit',
  numerator: 'Numerator',
  denominator: 'Denominator',
};

export const ChartSection = (props: ChartSectionProps) => {
  const {
    type,
    chart,
    status,
    granularity,
    format = formatCurrency,
    chartType,
    startMoment,
    endMoment,
    prevStartMoment,
    prevEndMoment,
    events = [],
    setEventsToDisplay,
    eventsToDisplay = [],
    evnetsOverlay,
    setEventTypes,
    eventTypes = [],
    selectedEventTypes = []
  } = props;

  const currentKey: UnitMetricChartDataKey = `current_timeframe_${type}`;
  const previousKey: UnitMetricChartDataKey = `previous_timeframe_${type}`;
  const chartFormatter = useCallback(({ value } : { value: number }) => format(value), [format]);

  const eventClick = (url: string) => {
    window.open(url, '_blank')
  }
  const filteredEvents = useMemo(() => {
    return events.filter((event) => selectedEventTypes.includes(event.payload.type))
  }, [selectedEventTypes])

  const filteredEventsToDisplay = useMemo(() => {
    return eventsToDisplay.filter((event) => selectedEventTypes.includes(event.payload.type))
  }, [selectedEventTypes, eventsToDisplay])

  return (
    <div style={{ minHeight: 270 }}>
      <Section className='relative flex flex-col h-full'>
        <div className='font-medium text-subtitle-1 ml-[20px] mt-[15px]'>
          {TITLES[type]}
        </div>
        <div className='mb-5'>     
          <div className='flex min-h-0 grow shrink'>
            <div className='min-w-0 grow shrink'>
            {chartType === 'bar' ? (
              <PeriodBarChart
                height='100%'
                events={evnetsOverlay ? filteredEvents : []}
                setEventsToDisplay={setEventsToDisplay}
                data={chart || []}
                formatter={chartFormatter}
                currentKey={`current_timeframe_${type}`}
                previousKey={`previous_timeframe_${type}`}
                granularity={granularity}
              />
            ) : (
              <PeriodChart
                height='100%'
                events={evnetsOverlay ? filteredEvents : []}
                setEventsToDisplay={setEventsToDisplay}
                data={chart || []}
                formatter={chartFormatter}
                currentKey={`current_timeframe_${type}`}
                previousKey={`previous_timeframe_${type}`}
                granularity={granularity}
              />
            )}
          </div>
          <div className='min-w-[200px] py-[20px] px-[10px] grow-0 shrink-0 flex flex-col'>
            {chart && chart.length > 0 && (
              <ChartInfo
                chart={chart}
                currentKey={currentKey}
                previousKey={previousKey}
                format={format}
                granularity={granularity}
                startMoment={startMoment}
                endMoment={endMoment}
                prevStartMoment={prevStartMoment}
                prevEndMoment={prevEndMoment}
              />
            )}
          </div>
          </div> 
          {status === 'loading' && <Loading />}
          {status === 'empty' && (
            <div className='absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center rounded-inherit bg-white/80'>
              <div className='font-medium text-[24px] text-gray-300'>
                Select denominator service and operation
              </div>
            </div>
          )}
        </div>
        {evnetsOverlay && (
          <div className='mx-5 mb-5'>
            <div className='text-[11px] text-silver-grey-900 mb-2'>Event Types</div>
            <div  className='mb-2 w-[200[x]'>
              <AntdSelect 
                mode="multiple"
                className='w-[200px]'
                onChange={setEventTypes}
                options={eventTypes.map((type) => {
                  return { label: type, value: type }
                })}
                filterOption={true}
                defaultValue={selectedEventTypes}
              />
            </div>

            {eventsToDisplay.length > 0 && (
              <Table 
                rowKey={({ id }) => id}
                size='small'
                rowClassName='text-[12px] line-[14px]'
                onHeaderRow={() => ({ className: 'text-[12px] line-[14px]' })}
                columns={[
                  {
                    key: 'type',
                    title: 'Type',
                    dataIndex: 'type',
                    render: (type) => <span className='truncate w-[200px]'>{type}</span>
                  },
                  {
                    key: 'description',
                    title: 'Description',
                    dataIndex: 'description',
                    render: (description) => <span className='truncate w-[200px]'>{description}</span>
                  },
                  {
                    key: 'event_url',
                    title: 'Link',
                    dataIndex: 'event_url',
                    render: (event_url) => <a onClick={() => eventClick(event_url)} className='truncate w-[200px]'>{event_url}</a>
                  }
                ]}
                dataSource={filteredEventsToDisplay.map((e) => {
                  return { id: e.id, ...e.payload }
                })}
              />
            )}
          </div>
        )}
      </Section>
    </div>
  );
};
