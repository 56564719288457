import { Section, SectionTitle } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { JSONDump } from './JSONDump';
import { useSavingsStore } from 'store/savings';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';

export interface OpportunityResourceDetailsSectionProps {
  opportunityId: number;
  className?: string;
}

export const OpportunityResourceDetailsSection = (props: OpportunityResourceDetailsSectionProps) => {
  const { opportunityId, className = '' } = props;

  const opportunity = useSavingsStore((store) => store.getOpportunity(opportunityId));

  const record = useDerivedDataEntry({ opportunity }, ({ opportunity }) => 
    opportunity.opportunity_details?.record?.flagged_resource_metadata ||
    opportunity.opportunity_details?.record ||
    {}
  );

  if (record.status === 'success' && Object.keys(record.data).length === 0) {
    return null;
  }

  return (
    <Section className={`p-5 relative ${className}`}>
      <SectionTitle className='mb-4'>
        Resource Details
      </SectionTitle>

      <JSONDump>
        {record.status === 'success' ? record.data : ''}
      </JSONDump>

      <StatusOverlay status={record.status} />
    </Section>
  );
};
