import { Toggle } from 'shared/Toggle';
import { useSSOSettingsStore } from 'store/ssoSettings';

export const ToggleProfilesOutsideAllowedControl = () => {
  const profilesOutsideAllowed = useSSOSettingsStore((state) => state.profilesOutsideAllowed);
  const toggleProfilesOutsideAllowed = useSSOSettingsStore((state) => state.toggleProfilesOutsideAllowed);

  return (
    <div className='my-[30px] flex items-center'>
      <Toggle
        className='mr-[8px]'
        checked={profilesOutsideAllowed}
        onChange={toggleProfilesOutsideAllowed}
      /> 
      Allow profiles outside allowed domains
    </div>
  );
};
