import { message } from 'antd';
import { Button } from 'shared/Button';
import { Dialog } from 'shared/Dialog';
import {StatusOverlay} from 'shared/Overlay';
import { useUsersStore, useUserDeleteDialogStore } from 'store/users';

export const DeleteDialog = () => {
  const {
    id,
    close
  } = useUserDeleteDialogStore();

  const user = useUsersStore((store) => id === null ? null : store.getEntry(id));
  const deleteUser = useUsersStore((store) => store.deleteEntry);
  const action = useUsersStore((store) => id && store.getAction(id));

  return (
    <Dialog className='w-[420px] pt-[40px] px-[30px] pb-[20px]' open={id !== null} onClose={close}>
      <div className='font-semibold text-center text-subtitle-1'>
        Are you sure you want to delete 
        {user && user.status === 'success' ? ` ${user.data.name} (${user.data.email}) ` : ' this user '}?
      </div>

      <Button
        className='justify-center mt-[30px] bg-negative'
        style='caladon'
        label='Yes, delete this user'
        disabled={action !== null}
        onClick={() => {
          if (id && user && user.status === 'success') {
            deleteUser(id)
              .then(() => {
                close();
                message.success(<>User <b>{user.data.name} ({user.data.email})</b> deleted</>);
              })
              .catch(() => {
                message.error(<>Unable to delete <b>{user.data.name} ({user.data.email})</b></>);
            });
          }
        }}
      />

      <Button
        className='justify-center mt-[20px]'
        type='tertiary'
        label='Cancel'
        onClick={close}
      />

      <StatusOverlay
        status={
          action && action.type === 'delete' && action.status === 'in-progress' ?
          'loading' :
          'success'
        }
      />

    </Dialog>
  )
};
