import type { 
  FilterValueCondition,
  SingleRule,
  ConstructorRule
 } from 'types/filters';

import { RuleRow } from './RuleRow';
import { RuleValue } from './RuleValue';
import { removeValue } from 'helpers/filters';

export interface RuleValuesProps<TCondition extends FilterValueCondition> {
  condition: TCondition;
  rule: SingleRule;
  options: string[];
  disabled?: boolean;
  excludedValues?: TCondition['values'];
  onChange: (condition: TCondition) => void;
  onValueChange?: (rule: ConstructorRule) => void;
  onValueRemove?: (rule: ConstructorRule) => void;
}

const noop = () => {};

export const RuleValues = <TCondition extends FilterValueCondition>(props: RuleValuesProps<TCondition>) => {
  const {
    condition,
    rule,
    options,
    onChange,
    disabled,
    excludedValues = [],
    onValueChange = noop,
    onValueRemove = noop
  } = props;

  const handleChange = (value: string | Record<string, string>, i: number) => {
    const values = [...condition.values];

    values[i] = value;
    onChange({ ...condition, values })
    onValueChange(rule);
  }

  const handleValueRemove = (index: number) => {
    onChange(removeValue(condition, index));
    onValueRemove(rule);
  }

  return (
    <div>
      {condition.values.map((value, index) => {
        const siblingValues = [...condition.values];

        siblingValues.splice(index, 1);
        siblingValues.push(...excludedValues as string[] | Record<string, string>[]);

        return (
          <RuleRow
            key={index}
            disabled={disabled}
            onRemove={condition.values.length > 1 ?
              () => { handleValueRemove(index); } :
              undefined
            }
          >
            <RuleValue
              rule={rule}
              value={value}
              disabled={disabled}
              valueType={condition.value_type}
              siblingValues={siblingValues}
              options={options}
              onChange={(value) => {
                handleChange(value, index);
              }}
            />
          </RuleRow>
        );
      })}
    </div>
  );
};
