import { useEffect, useState, useMemo } from 'react'
import type { AlertConfig } from 'types/alerts';
import { Link } from 'react-router-dom'
import { Section } from 'shared/Section';
import { Table, message } from 'antd'
import { Icon } from 'shared/Icon/Icon';
import { Toggle } from  'shared/Toggle/Toggle'
import { Loading } from 'shared/Loading/Loading';
import { DeleteAlertDialog } from './DeleteAlertDialog';
import Button from 'shared/Button/Button';
import IconButton from 'shared/Button/IconButton';
import { useAlertConfigStore } from 'store/alertConfigs';
import { useUnitMetricsLibraryStore } from 'store/unitMetricsLibrary'
import { useCostViewLibraryStore } from 'store/costViewLibrary'
import { useSlackChannelsStore } from 'store/slackChannels'
import { useGlobalState } from 'state/globalState'
import { createNullableStringsComparator, createNullableNumbersComparator } from 'helpers/sort';
import { formatNormal } from 'helpers/formatter'
import moment from 'moment';
import CreateDialog from './CreateDialog'
import AlertsTable from './Alerts';

export interface LibraryProps {
  viewAlert: string | null;
  metricAlert: string | null;
}

const AlertsLibrary = (props: LibraryProps) => {
  const { viewAlert, metricAlert } = props;
  const { 
    status: configStatus,
    alertConfigs,
    fetchAlertConfigs,
    deleteAlertConfig,
    setViewedAlertConfig,
    setIsActiveAlertConfig,
  } = useAlertConfigStore();
  const { user } = useGlobalState();
  const { status: costViewStatus, costViews: allCostViews, fetchCostViewLibrary } =  useCostViewLibraryStore();
  const { status: unitMetricStatus, unitMetrics, fetchUnitMetricsLibrary } = useUnitMetricsLibraryStore();
  const { slackChannels, slackIntegraitonId, fetchSlackChannels } = useSlackChannelsStore();
  const [searchText, setSearchText] = useState('');
  const [alertToDelete, setAlertToDelete] = useState<AlertConfig | null>(null);
  const [alertToEdit, setAlertToEdit] = useState<AlertConfig | null>(null);
  const [alertToView, setAlertToView] = useState<AlertConfig | null>(null);
  const [createDialogOpened, setCreateDialogOpened] = useState(false);

  const costViews = useMemo(() => allCostViews.filter((view) => view.src === 'cur'), [allCostViews]);

  const handleView = (config: AlertConfig) => {
    setViewedAlertConfig(config.metric_type, config.id);
  };

  const handleSetActive = (config: AlertConfig, is_active: boolean) => {
    setIsActiveAlertConfig(config.metric_type, config.id, is_active);
  };

  useEffect(() => {
    fetchAlertConfigs();
    fetchCostViewLibrary();
    fetchUnitMetricsLibrary();
    fetchSlackChannels();
    if (viewAlert) {
      handleEditAlert({
        id: -1,
        table_id: 'cost0',
        name: '',
        owner: '',
        metric_type: 'cost',
        filter_id: parseInt(viewAlert),
        alert_type: 'Absolute',
        threshold: 0,
        period: 1,
        cost_column: 'unblended_cost',
        amortization: false,
        exclude: '',
        channels: '',
        email_recepients: [],
        slack_id: slackIntegraitonId,
        slack_channels: [],
        is_active: true,
        last_violated_at: '',
        last_viewed_at: '',
        created_at: '',
      })
    } else if (metricAlert) {
      handleEditAlert({
        id: -1,
        table_id: 'unit0',
        name: '',
        owner: '',
        metric_type: 'unit',
        filter_id: parseInt(metricAlert),
        alert_type: 'Absolute',
        threshold: 0,
        period: 1,
        cost_column: 'unblended_cost',
        amortization: false,
        exclude: '',
        channels: '',
        email_recepients: [],
        slack_id: slackIntegraitonId,
        slack_channels: [],
        is_active: true,
        last_violated_at: '',
        last_viewed_at: '',
        created_at: '',
      })
    }
  }, [])

  const handleEditAlert = (alert: AlertConfig) => {
    setAlertToEdit(alert)
    setCreateDialogOpened(true)
  }

  const filteredAlerts = useMemo(() => {
    return searchText ?
      alertConfigs.filter((alert) => alert.name.toLowerCase().includes(searchText.toLowerCase())) :
      alertConfigs; 
  }, [alertConfigs, searchText]);

  return (
    <div className='pt-[35px]'>
      <Section className='p-[20px]'>
        <div className='flex mb-[20px]'>
          <div className='flex items-center grow pl-[11px]'>
            <Icon icon='search' />
            <input
              type='text'
              className='w-full leading-[20px] text-[14px] my-[-5px] py-[10px] pl-[40px] ml-[-29px] bg-transparent outline-none border-b border-b-transparent focus:border-b-cyanine-blue'
              value={searchText} 
              placeholder='Search'
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
            />
          </div>
          <Button
            icon='circle-plus'
            size='sm'
            type='primary'
            style='caladon'
            label='Create New Alert'
            disabled={user?.org_is_data_ready ? false : true}
            onClick={() => setCreateDialogOpened(true)}
          />
        </div>
        <Table
          rowKey={({ table_id }) => table_id}
          size='small'
          rowClassName='text-[12px] line-[14px]'
          onHeaderRow={() => ({ className: 'text-[12px] line-[14px]' })}
          dataSource={filteredAlerts}
          columns={[
            {
              key: 'not_viewed',
              width: 32,
              render: (_, alert) => alert.last_violated_at && alert.last_violated_at > alert.last_viewed_at ? <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='eye' onClick={() => handleView(alert)} /> : null
            },
            {
              key: 'name',
              title: 'Name',
              dataIndex: 'name',
              sorter: (a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0,
              render: (name, alert) => <a className='truncate w-[200px]' onClick={() => setAlertToView(alert)}>{name}</a>
            },
            {
              key: 'filter_id',
              title: 'Cost View / Unit Metric',
              dataIndex: 'filter_id',
              render: (_, alert) => (
                <div className='truncate w-[200px]'>
                  {alert.metric_type === 'cost' ? (
                      <Link className='text-cyanine-blue' to={`/costs-overview/${alert.filter_id}`}>
                        {`View: ${costViews.filter((view) => view.id === alert.filter_id)[0]?.name}`}
                      </Link>
                    ) : (
                      <Link  className='text-cyanine-blue' to={`/unit-metrics/${alert.filter_id}`}>
                        {`Metric: ${unitMetrics.filter((metric) => metric.id === alert.filter_id)[0]?.name}`}
                      </Link>
                    )
                  }
                </div>
              )  
            },
            {
              key: 'threshold',
              title: 'Threshold',
              dataIndex: 'threshold',
              sorter: createNullableNumbersComparator('threshold'),
              render: (_, alert) => <div className='w-10 truncate'>{`${alert.alert_type === 'Absolute' ? `$${alert.threshold}` : `${formatNormal((alert.threshold - 1) * 100)}%`}`}</div>
            },
            {
              key: 'last_violated_at',
              title: 'Last Violated',
              dataIndex: 'last_violated_at',
              defaultSortOrder: 'descend',
              sorter: createNullableStringsComparator('last_violated_at'),
              render: (lastViolatedAt?: string) => lastViolatedAt ? <div className='w-20 truncate'>{moment(lastViolatedAt).format('YYYY-MM-DD')}</div> : <div className='w-20 truncate'>n/a</div>
            },
            {
              key: 'last_viewed_at',
              title: 'Last Viewed',
              dataIndex: 'last_viewed_at',
              sorter: createNullableStringsComparator('last_viewed_at'),
              render: (lastViewedAt?: string) => lastViewedAt ? <div className='w-20 truncate'>{moment(lastViewedAt).format('YYYY-MM-DD')}</div> : <div className='w-20 truncate'>n/a</div>
            },
            {
              key: 'owner',
              dataIndex: 'owner',
              title: 'Created By',
              sorter: createNullableStringsComparator('owner'),
              render: (owner) => <div className='w-20 truncate'>{owner}</div>
            },
            {
              key: 'is_active',
              dataIndex: 'is_active',
              title: 'Active',
              sorter: (a) => a.is_active ? 1 : -1,
              render: (is_active, alert) => <Toggle checked={is_active} onChange={() => handleSetActive(alert, !is_active)} />
            },
            {
              key: 'created_at',
              title: 'Created at',
              dataIndex: 'created_at',
              sorter: createNullableStringsComparator('created_at'),
              render: (createdAt?: string) => createdAt ? <div className='w-20 truncate'>{moment(createdAt).format('YYYY-MM-DD')}</div> : <div className='w-20 truncate'>n/a</div>
            },
            {
              key: 'edit',
              width: 32,
              render: (_, alert) => <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='edit' onClick={() => handleEditAlert(alert)} />
            },     
            {
              key: 'delete',
              width: 32,
              render: (_, alert) => <IconButton className='my-[-6px]' size='sm' type='tertiary' icon='trash' onClick={() => setAlertToDelete(alert)} />
            },
          ]}
        />
        {(configStatus === 'loading' || costViewStatus === 'loading' || unitMetricStatus === 'loading') && <Loading />}
        <CreateDialog
          opened={createDialogOpened}
          onClose={(search) => {
            setCreateDialogOpened(false)
            setAlertToEdit(null);
            if (search) {
              setSearchText(search)
            }
          }}
          alertToEdit={alertToEdit}
          costViews={costViews}
          unitMetrics={unitMetrics}
          slackIntegraitonId={slackIntegraitonId}
          slackChannels={slackChannels}
        />
        {alertToView && (
          <AlertsTable 
            opened={!!alertToView} 
            onClose={() => setAlertToView(null)}
            alertToView={alertToView}
          />
        )}
        <DeleteAlertDialog
          open={!!alertToDelete}
          name={alertToDelete?.name || ''}
          onClose={() => setAlertToDelete(null)}
          onConfirm={async () => {
            if (!alertToDelete) {
              return;
            }

            try {
              await deleteAlertConfig(alertToDelete.metric_type, alertToDelete.id);
              setAlertToDelete(null);
              message.success(`Alert '${alertToDelete.name}' deleted`);
            } catch {
              message.error(`Unable to delete '${alertToDelete.name}'`);
            }
          }}
        />
      </Section>
    </div>
  )
}

export default AlertsLibrary
