import type { 
  FilterValueCondition,
  SingleRule,
  ConstructorRule
 } from 'types/filters';

import { RuleValues } from './RuleValues';
import { RuleHeader } from './RuleHeader';
import { getIcon, getTitleSingle } from './constants';
import { toggleRegex } from 'helpers/filters';

export interface RuleProps<TCondition extends FilterValueCondition> {
  condition: TCondition;
  excludedValues?: TCondition['values']
  rule: SingleRule;
  prefix?: string;
  disabled?: boolean;
  onRemove?: () => void;
  onChange: (condition: TCondition) => void;
  onValueAdd?: (rule: ConstructorRule) => void;
  onValueChange?: (rule: ConstructorRule) => void;
  onValueRemove?: (rule: ConstructorRule) => void;
  onValueTypeChange?: (rule: ConstructorRule) => void;
  onComparatorChange?: (rule: ConstructorRule) => void;
}

const noop = () => {};

export const Rule = <TCondition extends FilterValueCondition>(props: RuleProps<TCondition>) => {
  const {
    condition,
    rule,
    onChange,
    onRemove,
    prefix,
    disabled,
    excludedValues,
    onValueAdd = noop,
    onValueChange = noop,
    onValueRemove = noop,
    onValueTypeChange = noop,
    onComparatorChange = noop
  } = props;

  const handleValueAdd = () => {
    const values = rule.valueType.startsWith('string') ?
      [...condition.values, ''] :
      [...condition.values, {}];

    onChange({ ...condition, values })
    onValueAdd(rule);
  };

  return (
    <div>
      <RuleHeader
        prefix={prefix}
        icon={getIcon(rule.name)}
        title={getTitleSingle(rule.name)}
        required={rule.required}
        valueType={condition.value_type}
        comparator={condition.comparator}
        disabled={disabled}
        onComparatorChange={rule.exclusionAllowed ?
          (newComparator) => {
            onChange({
              ...condition,
              comparator: newComparator
            });
            onComparatorChange(rule);
          } : undefined
        }
        onValueTypeChange={rule.regexAllowed ?
          () => {
            onChange(toggleRegex(condition, rule.options));
            onValueTypeChange(rule);
          } : undefined
        }
        onRemove={rule.required ? undefined: onRemove}
        onAdd={handleValueAdd}
      />

      <RuleValues
        condition={condition}
        rule={rule}
        options={rule.options}
        onChange={onChange}
        disabled={disabled}
        excludedValues={excludedValues}
        onValueChange={onValueChange}
        onValueRemove={onValueRemove}
      />
    </div>
  );
};
