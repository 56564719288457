import type {
  CostViewChartKey,
  CostViewChartKeyProps,
  CostViewChartKeyFactory,
  CostViewChartFetchParams
} from 'types/costViews';

import {
  Set as ImmutableSet,
  Record as ImmutableRecord
} from 'immutable';

import {
  defaultCostFilterKeyProps,
  defaultDateFilterKeyProps
} from './defaults';

import { toImmutableFilter } from 'helpers/filters';

const createCostViewChartKey: CostViewChartKeyFactory = ImmutableRecord<CostViewChartKeyProps>({
  ...defaultCostFilterKeyProps,
  ...defaultDateFilterKeyProps,
  teamId: undefined,
  globalFilterIds: undefined,
  filter: null,
});

export const costViewChartFetchParamsToKey = (params: CostViewChartFetchParams): CostViewChartKey => {
  const costDimensions = ImmutableSet(params.costDimensions);
  const globalFilterIds = ImmutableSet(params.globalFilterIds);
  const filter = toImmutableFilter(params.filter);

  return createCostViewChartKey({
    ...params,
    costDimensions,
    globalFilterIds,
    filter
  });
};
