export type TeamLeaderboardSortField = 'name' | 'active' | 'in-progress' | 'this-month-spend' | `${string}-wasted` | `${string}-achieved` | `${string}-spent`

export const isTeamLeaderboardSortField = (value: any): value is TeamLeaderboardSortField => typeof value === 'string' && (
  value === 'name' ||
  value === 'active' ||
  value === 'in-progress' ||
  value === 'this-month-spend' || 
  value.endsWith('wasted') ||
  value.endsWith('achieved') ||
  value.endsWith('spent')
);
