import type { CFNStack, StackSetType } from 'types/dataCollection';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button2 as Button } from 'shared/Button'
import CFNCreateDialog from './CFNCreateDialog';
import { useCFNStacksStore } from 'store/cfnStacks';
import { useAWSConfigCreateDialog } from 'store/dataConfigs/awsCreateDialog';


export interface CFNStackSelectionProps {
  onboarding: boolean;
}

export const CFNStackSelection = (props: CFNStackSelectionProps) => {
  const { onboarding } = props;
  const navigate = useNavigate()
  const openAWSConfigCreateDialog = useAWSConfigCreateDialog((store) => store.open);
  const [metaStackType, setMetaStackType] = useState<'co_new_cur' | 'co_existing_cur' | 'co_base' | 'automation' | null>(null)
  const [stackType, setStackType] = useState<StackSetType | null>(null)
  const [initialStack, setInitialStack] = useState<CFNStack | null>(null)
  
  const {
    cfnStacks,
    fetchCFNStacks,
    saveCFNStacks,
 } = useCFNStacksStore()

  const handleTemplateClick = () => {
    window.open('https://github.com/cloudthread/docs/blob/main/policy_cfn_cldthrd.yaml', '_blank')
  }

  const handleLearnMoreClick = () => {
    window.open('https://docs.cloudthread.io/fundamentals/data-collection', '_blank')
  }

  const handleConnectClick = () => {
    window.open(initialStack ? initialStack.link : '', '_blank')
    if (onboarding) {
      navigate('sync')
    }
  }

  const handleCostExplorerClick = () => {
    window.open('https://docs.aws.amazon.com/cost-management/latest/userguide/ce-enable.html', '_blank')
  }

  const handleCostExplorerRIClick = () => {
    window.open('https://docs.aws.amazon.com/cost-management/latest/userguide/ce-rightsizing.html#rr-getting-started', '_blank')
  }

  const handleComputeOptimizerClick = () => {
    window.open('https://docs.aws.amazon.com/compute-optimizer/latest/ug/getting-started.html', '_blank')
  }

  const handleComputeOptimizerCEClick = () => {
    window.open('https://docs.aws.amazon.com/compute-optimizer/latest/ug/viewing-dashboard.html', '_blank')
  }

  const handleAWSOrgsClick = () => {
    window.open('https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/stacksets-orgs-enable-trusted-access.html', '_blank')
  }

  const handlSetStackType = async (inputStackType: StackSetType) => {
    setStackType(inputStackType)
    if (
      inputStackType === 'co_new_cur' 
      || inputStackType === 'co_base'
      || inputStackType === 'automation'
    ) {
      const existingStack = cfnStacks.find((stack) => stack.stack_type === inputStackType)
      if (!onboarding || !existingStack) {
        saveCFNStacks({stack_type: inputStackType})
      }
    } else {
      openAWSConfigCreateDialog();
    }
  }

  useEffect(() => {
    fetchCFNStacks()
  }, [])

  useEffect(() => {
    if (onboarding) {
      const initialStack = cfnStacks.find((stack) => stack.stack_type === stackType)
      if (initialStack) {
        setInitialStack(initialStack)
      } else {
        setInitialStack(null)
      }
    }
  }, [cfnStacks, stackType])

  return (
    <div>
      <div className='p-5'>
        {onboarding && (
          <div className='mb-5'>
            <div className="mb-5 text-xl font-semibold ">Connecting to AWS</div>
            <p>
              The easiest way to securely give Cloudthread access to your AWS
              cost and resource data is using a CloudFormation stack.
            </p>
          </div>
        )}
        <div>
          {!metaStackType ? (
            <div>
              <div className='mb-5'>Please select from the following:</div>
              <ul className='ml-5 list-decimal list-outside'>
                <li className="mt-[5px]">
                  <Button
                    size='m'
                    theme='black'
                    layout='inline'
                    onClick={() => setMetaStackType('co_new_cur')}
                  >
                    I need to integrate {onboarding ? '' : 'more'} accounts for Costs Savings detection and need a <span className='font-bold italic'>new</span> Cost and Usage Report for them
                  </Button>
                </li>
                <li className="mt-[5px]">
                  <Button
                    size='m'
                    theme='black'
                    layout='inline'
                    onClick={() => setMetaStackType('co_existing_cur')}
                  >
                    I need to integrate {onboarding ? '' : 'more'} accounts for Costs Savings detection and have an <span className='font-bold italic'>existing</span> Cost and Usage Report for them
                  </Button>
                </li>
                {!onboarding && (
                  <div>
                    <li className="mt-[5px]">
                      <Button
                        size='m'
                        theme='black'
                        layout='inline'
                        onClick={() => setMetaStackType('co_base')}
                      >
                        I need to integrate {onboarding ? '' : 'more'} accounts for Costs Savings detection that <span className='font-bold italic'>already appear</span> in my previously integrated Cost and Usage Reports
                      </Button>
                    </li>
                    <li className="mt-[5px]">
                      <Button
                        size='m'
                        theme='black'
                        layout='inline'
                        onClick={() => setMetaStackType('automation')}
                      >
                        I need to integrate Automation
                      </Button>
                    </li>
                  </div>
                )}
              </ul>
              <p className='mt-[15px]'>
                If you are using an <span className='font-bold'>existing CUR Report</span>, Cloudthread requires it to be: Hourly time granularity, Parquet file type, Overwrite Report file versioning, and Resource ID level report content.
              </p>
              {onboarding && (
                <div>
                  <p className="mt-[25px]">
                    Cloudthread access follows the principle of least privilege and you can see policy 
                    specifics <a className='underline' onClick={handleTemplateClick}>here.</a> Once 
                    the stack is created, Cloudthread will integrate automatically.
                  </p>
                  <p className='mt-[15px]'>
                    Not sure what a Cost and Usage Report is? <a className='underline' onClick={handleLearnMoreClick}>Click here for more info.</a>
                  </p> 
                  <div className="mt-[35px] mb-5 text-xl font-semibold ">Getting the most out of AWS</div>
                  <p className="mt-[25px] mb-5">
                    Cloudthread computes proprietary Saving Opportunities but we also pull native AWS savings suggestions. 
                    The following actions are recommended to get the most out of Cloudthread and AWS:
                  </p>
                  <ul className='ml-5 list-disc list-outside'>
                    <li className="mt-[5px]">
                      Enable <a className='font-bold underline' onClick={handleCostExplorerClick}>Cost Explorer</a>
                    </li>
                    <li className="mt-[5px]">
                      Enable <a className='font-bold underline' onClick={handleCostExplorerRIClick}>Cost Explorer Rightsizing Recommendations</a>
                    </li>
                    <li className="mt-[5px]">
                      Enable <a className='font-bold underline' onClick={handleComputeOptimizerClick}>Compute Optimizer</a>
                    </li>
                  </ul>
                  <p className="mt-[15px]">
                    If you are using <span className='font-bold'>AWS Organizations</span>, all the above actions should be taken from your managementa
                    account.
                  </p>
                  <p className="mt-[25px]">
                    Please note that enabling <span className='px-2 bg-grey-transparent'>Receive Amazon EC2 resource 
                    recommendations</span> in Preferences within <span className='font-bold'>Cost Explorer</span> is required
                    for <span className='font-bold'>Cost Explorer Rightsizing Recommendations</span> and <span className='font-bold'>Compute Optimizer.</span> This requirement is highlighted for <span className='font-bold'>Compute Optimizer</span> under 
                    the Savings opportunity section <a className='underline' onClick={handleComputeOptimizerCEClick}>here.</a>
                  </p>
                  <p className='mt-[15px]'>
                    For <span className='font-bold'>Compute Optimizer,</span> Cloudthread currently supports <span className='px-2 bg-grey-transparent'>Standalone 
                    AWS account</span> and <span className='px-2 bg-grey-transparent'>Management 
                    account of an organization</span> accounts.
                  </p>
                </div>
              )}
            </div>
          ) : (
            metaStackType && !stackType ? (
              <div>
                <div className='mb-5'>Please select from the following:</div>
                <ul className='ml-5 list-decimal list-outside'>
                  <li className="mt-[5px]">
                    <Button
                      size='m'
                      theme='black'
                      layout='inline'
                      onClick={() => handlSetStackType(
                        metaStackType === 'co_new_cur' ? 'co_stack_set_new_cur' :
                          metaStackType === 'co_existing_cur' ? 'co_stack_set_existing_cur' :
                          metaStackType === 'co_base' ? 'co_stack_set' : 
                          'automation_stack_set'
                      )}
                    >
                      I use AWS Organizations
                    </Button>
                  </li>
                  <li className="mt-[5px]">
                    <Button
                      size='m'
                      theme='black'
                      layout='inline'
                      onClick={() => handlSetStackType(metaStackType)}
                    >
                      I do not use AWS Organizations
                    </Button>
                  </li>
                </ul>
                <p className='mt-[15px]'>
                  If you are using <span className='font-bold'>AWS Organizations</span>, you will need 
                  to enable <a className='font-bold underline' onClick={handleAWSOrgsClick}>Trusted Access</a> within <span className='font-bold'>AWS 
                  Organizations</span> in order to deploy the StackSet within your account.
                </p>
                <div className='mt-[15px]'>
                  <a type='secondary' className="text-xs underline" onClick={() => setMetaStackType(null)}>{"... or pick another option"}</a>
                </div>
              </div>
            ) : onboarding ? (
                <div>
                  <Button 
                    size='l'
                    theme='link'
                    layout='inline'
                    onClick={handleConnectClick} 
                  >
                    Go to New Report Template
                  </Button>
                  <div className='mt-[15px]'>
                    <a type='secondary' className="text-xs underline" onClick={() => setStackType(null)}>{"... or pick another option"}</a>
                  </div>
                </div>
              ) : (
                <div>
                  To use the new template, click the Link column in the table below for the newly generated row.
                  <div className='mt-[15px]'>
                    <a type='secondary' className="text-xs underline" onClick={() => setStackType(null)}>{"... or pick another option"}</a>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      {stackType && (
        <CFNCreateDialog
          initialStack={initialStack}
          stackType={stackType}
          setStackType={setStackType}
        />
      )}
    </div>
  )
}

export default CFNStackSelection
