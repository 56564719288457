import type { Team } from 'types/teams';
import { Link } from 'react-router-dom';
import { ListEditor } from './ListEditor';
import { useTeamCostViewsStore } from 'store/teamCostViews';

export interface TeamCostViewsProps {
  team: Team;
}

export const TeamCostViews = (props: TeamCostViewsProps) => {
  const {
    team
  } = props;

  const teamEntry = useTeamCostViewsStore((state) => state.getEntry(team.id));
  const allCostViews = useTeamCostViewsStore((state) => state.getAllCostViews());
  const addCostView = useTeamCostViewsStore((state) => state.addTeamCostView);
  const confirmAddition = useTeamCostViewsStore((state) => state.confirmAddition);
  const removeCostView = useTeamCostViewsStore((state) => state.removeTeamCostView);
  const confirmRemoval = useTeamCostViewsStore((state) => state.confirmRemoval);

  if (teamEntry.status === 'idle') {
    return null;
  }

  if (teamEntry.status === 'loading') {
    return <div>
      Loading
    </div>;
  }

  if (teamEntry.status === 'load-error') {
    return <div>
      Error
    </div>;
  }


  return (
    <ListEditor
      items={teamEntry.data.toArray().map(([_, costView]) => costView)}
      addOptions={allCostViews.status === 'loaded' ? 
        allCostViews.data.map((view) => ({
          status: 'loaded',
          data: view
        })) :
        []
      }
      getItemKey={(costView) => '' + costView.data.id}
      getItemTitle={(costView) => costView.data.name}
      addedMessage={(costView) => `Cost view ${costView.data.name} added to team ${team.name}`}
      addErrorMessage={(costView) => `Unable to add cost view ${costView.data.name} to team ${team.name}`}
      removedMessage={(costView) => `Cost view ${costView.data.name} removed from team ${team.name}`}
      removeErrorMessage={(costView) => `Unable to add cost view ${costView.data.name} from team ${team.name}`}
      onAdd={(costView) => {
        addCostView(team.id, costView.data.id, costView.data);
      }}
      onAddConfirm={(costView) => {
        confirmAddition(team.id, costView.data.id);
      }}
      onRemove={(costView) => {
        removeCostView(team.id, costView.data.id);
      }}
      onRemoveConfirm={(costView) => {
        confirmRemoval(team.id, costView.data.id);
      }}
    >
      {(costView) => <Link to={`/costs-overview/${costView.data.id}`}>{costView.data.name}</Link>}
    </ListEditor>
  );
};
