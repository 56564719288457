import { Select } from 'antd';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useGlobalFiltersStore } from 'store/globalFilters';
import { useSavingsSettingsStore } from 'store/savings';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

export const GlobalFilterSelect = () => {
  const globalFilters = useGlobalFiltersStore((store) => store.getLibrary());
  const globalFilterIds = useSavingsSettingsStore((store) => store.globalFilterIds);
  const setGlobalFilterIds = useSavingsSettingsStore((store) => store.setGlobalFilterIds);

  /*
  const [searchParams, setSearchParams] = useSearchParams();
  const globalFilterIdsParam = searchParams.get('globalFilterIds');

  useEffect(() => {
    if (globalFilterIdsParam) {
      setGlobalFilterIds(+globalFilterIdsParam);

      setSearchParams((currentSearchParams) => {
        const newSearchParams = new URLSearchParams(currentSearchParams);

        newSearchParams.delete('globalFilterId');

        return newSearchParams;
      });
    }
  }, [globalFilterIdsParam])
  */

  const options = useDerivedDataEntry(
    { globalFilters },
    ({ globalFilters }) => [
      ...globalFilters.map(({ id, name }) => ({
        value: id + '',
        label: name
      }))
    ]
  );

  return (
    <Select
      maxTagCount={'responsive'}
      mode='multiple'
      popupClassName='z-20'
      className='w-[240px] whitespace-nowrap'
      showSearch
      allowClear
      filterOption={(input, option) => option !== undefined && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      value={globalFilterIds === null ? [] : globalFilterIds}
      options={options.status === 'success' ? options.data : []}
      disabled={options.status !== 'success'}
      onChange={(value) => {
        setGlobalFilterIds(value);
      }}
    />
  );
}
