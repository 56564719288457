import { InputNumber } from 'antd';

export interface CostImpactInputProps {
  value: number;
  disabled?: boolean;
  onChange: (value: number) => void;
}

export const CostImpactInput = (props: CostImpactInputProps) => {
  const {
    value,
    disabled,
    onChange
  } = props;

  return (
    <InputNumber
      className='w-full'
      prefix='$'
      min={0}
      value={value}
      disabled={disabled}
      onChange={(val) => onChange(val || 0)}
    />
  );
}
