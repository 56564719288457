import type { JiraSeverCreatePayload } from 'types/users';

import create from 'zustand';
import { Map as ImmutableMap } from 'immutable';

export interface JiraServerStoreState {
  drafts: ImmutableMap<string, JiraSeverCreatePayload>;

  setDraft: (clientId: string, draft: JiraSeverCreatePayload) => void;
  getDraft: (clientId: string) => JiraSeverCreatePayload;
  discardDraft: (clientId: string) => void;
}

export const useJiraServerStore = create<JiraServerStoreState>((set, get) => ({
  drafts: ImmutableMap(),

  getDraft: (clientId: string) => {
    const { drafts } = get();

    let draft = drafts.get(clientId);

    if (!draft) {
      draft = {
        client_id: clientId,
        client_secret: '',
        jira_server_url: '',
      };

      set({
        drafts: drafts.set(clientId, draft)
      });
    }

    return draft;
  },

  setDraft: (clientId: string, draft:JiraSeverCreatePayload) => {
    set({
      drafts: get().drafts.set(clientId, draft)
    });
  },

  discardDraft: (clientId: string) => {
    set({ drafts: get().drafts.remove(clientId) });
  },
}));
