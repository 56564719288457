import { H1, P, A } from './Typography';

export const CustomMessage = () => {
  return (
    <div>
      <H1>
        Your organization is on a custom billing plan
      </H1>

      <P>
        For questions, contact <A href='mailto:support@cloudthread.io'>support@cloudthread.io</A>
      </P>
    </div>
  );
}
