import { pickOpportunityRightsizing } from 'helpers/savings';
import {ReactNode} from 'react';
import {Icon} from 'shared/Icon';
import {Section, SectionTitle} from 'shared/Section';
import { useSavingsStore } from 'store/savings';
import {JSONDump} from './JSONDump';

export interface RightsizingOptionProps {
  label: ReactNode;
  option: null | string | {};
}

export const RightsizingOption = (props: RightsizingOptionProps) => {
  const {
    label,
    option
  } = props;

  return (
    <div className={`flex flex-col ${option && typeof option !== 'string' ? 'basis-0 grow min-w-0 self-stretch' : ''}`}>
      <div className='mb-1 font-medium'>
        {label}
      </div>
      {option === null ? (
        <pre className='inline-block p-3 rounded bg-red/20 text-red'>
          <Icon icon='close-circle' className='inline-block mr-2'/>
          Deprovisioned
        </pre>
      ) : typeof option === 'string' ? (
        <pre className='inline-block p-3 bg-gray-100 rounded'>
          {option}
        </pre>
      ) : (
        <JSONDump
          expandable={false}
          className='basis-[100%] grow shrink min-w-0 self-stretch'
        >
          {option}
        </JSONDump>
      )}
    </div>
  );
}
  
export interface RightsizingSectionProps {
  className?: string;
  opportunityId: number;
}


export const RightsizingSection = (props: RightsizingSectionProps) => {
  const {
    className = '',
    opportunityId
  } = props;

  const opportunity = useSavingsStore((store) => store.getOpportunity(opportunityId));

  if (opportunity.status !== 'success') {
    return null;
  }

  const rightsizing = pickOpportunityRightsizing(opportunity.data);

  if (rightsizing.current_option === null) {
    return null;
  }

  return (
    <Section className={`p-5 ${className}`}>
      <SectionTitle>
        Resource Info
      </SectionTitle>

      <div className={`mt-4 flex w-full items-center min-w-0 gap-4 ${className}`}>
        <RightsizingOption
          label='Current'
          option={rightsizing.current_option}
        />

        <Icon icon='arrow-right' width={14} height={14} className='relative my-auto top-2 shrink-0' />

        <RightsizingOption
          label='Recommended'
          option={rightsizing.new_option}
        />
      </div>
    </Section>
  );
}
