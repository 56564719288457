import {
  ConstructorRule,
  FilterGroupCondition,
  isSingleRule
} from 'types/filters';

import {
  isFilterValueCondition,
  isFilterNestedCondition
} from 'types/filters';

import { Rule } from './Rule';
import { NestedRule } from './NestedRule';
import { AddRuleMenu } from './AddRuleMenu';

import { getNestedFieldName, removeCondition } from 'helpers/filters';

export interface FilterProps<TValue extends FilterGroupCondition = FilterGroupCondition> {
  className?: string;
  rules: Record<string, ConstructorRule>;
  value: TValue;
  disabled?: boolean;
  onChange: (value: TValue) => void;
  onRuleAdd?: (rule: ConstructorRule) => void;
  onRuleRemove?: (rule: ConstructorRule) => void;
  onValueAdd?: (rule: ConstructorRule) => void;
  onValueChange?: (rule: ConstructorRule) => void;
  onValueRemove?: (rule: ConstructorRule) => void;
  onValueTypeChange?: (rule: ConstructorRule) => void;
  onComparatorChange?: (rule: ConstructorRule) => void;
  removeTeams?: boolean;
}

const noop = () => {};

export const Filter = <TValue extends FilterGroupCondition = FilterGroupCondition>(props: FilterProps<TValue>) => {
  let {
    className = '',
    rules,
    value,
    disabled,
    onChange,
    onRuleAdd = noop,
    onRuleRemove = noop,
    onValueAdd,
    onValueChange,
    onValueRemove,
    onValueTypeChange,
    onComparatorChange,
    removeTeams = true,
  } = props;

  return (
    <div className={`pb-[10px] ${className}`}>
      {value.condition.map((condition, index) => {
        if (isFilterNestedCondition(condition)) {
          const fieldName = getNestedFieldName(condition);
          const rule = rules[fieldName];

          return (
            <NestedRule
              key={fieldName}
              value={condition}
              rules={rules}
              prefix={index === 0 ? 'where' : 'and'}
              disabled={disabled}
              onRemove={() => {
                const newValue = removeCondition(value, [index]);

                onChange(newValue);
                onRuleRemove(rule);
              }}
              onChange={(condition) => {
                let newValue: TValue = {
                  ...value,
                  condition: [...value.condition]
                };

                newValue.condition[index] = condition;

                onChange(newValue);
              }}
            />
          );
        }

        if (!isFilterValueCondition(condition)) {
          return null;
        }

        const rule = rules[condition.field_name];

        if (!rule || !isSingleRule(rule)) {
          return null;
        }

        return (
          <Rule
            key={condition.field_name}
            prefix={index === 0 ? 'where' : 'and'}
            condition={condition}
            rule={rule}
            disabled={disabled}
            onValueAdd={onValueAdd}
            onValueRemove={onValueRemove}
            onValueChange={onValueChange}
            onValueTypeChange={onValueTypeChange}
            onComparatorChange={onComparatorChange}
            onChange={(condition) => {
              let newValue: TValue = {
                ...value,
                condition: [...value.condition]
              };

              newValue.condition[index] = condition;

              onChange(newValue);
            }}
            onRemove={() => {
              const newValue = removeCondition(value, [index]);

              onChange(newValue);
              onRuleRemove(rule);
            }}
          />
        );
      })}

      {!disabled && 
        <AddRuleMenu
          value={value}
          rules={rules}
          onChange={onChange}
          onRuleAdd={onRuleAdd}
          removeTeams={removeTeams}
        />
      }
    </div>
  );
};
