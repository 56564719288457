import { Button2 as Button } from 'shared/Button';
import { Section, SectionTitle } from 'shared/Section';
import { StatusOverlay } from 'shared/Overlay';
import { InfoIcon } from 'shared/InfoIcon';
import { DifficultyBadge } from '../DifficultyBadge';
import { SimpleTable, SimpleTableRow, SimpleTableCell, SimpleTableHeadCell } from 'shared/SimpleTable'

import { formatCurrency } from 'helpers/formatter';
import { ACTIVE_OPPORTUNITY_STATUSES } from 'helpers/savings';
import { usePageFilters } from '../hooks/pageFilters';
import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { useCostImpactSummary } from 'store/savings';
import { identity } from 'helpers/utils';


type Difficulty = 'easy' | 'medium' | 'hard' | 'unknown';

export interface DifficultySavingsSectionProps {
  className?: string;
}

export const DifficultySavingsSection = (props: DifficultySavingsSectionProps) => {
  const {
    className
  } = props;

  const pageFilters = usePageFilters();
  const params = useDerivedDataEntry({
    prefilter: {
      status: ACTIVE_OPPORTUNITY_STATUSES,
    },
    pageFilters,
    tableFilters: {},
    groupBy: 'difficulty' as 'difficulty',
  }, identity);

  const costImpactSummary = useCostImpactSummary(params);

  const summary = useDerivedDataEntry({ costImpactSummary }, ({ costImpactSummary }) => {
    const result = {
      easy: { count: 0, savings: 0 },
      medium: { count: 0, savings: 0 },
      hard: { count: 0, savings: 0 },
      unknown: { count: 0, savings: 0 },
      total: { count: 0, savings: 0 }
    };

    costImpactSummary.forEach((data) => {
      const difficulty = data.group_by as Difficulty;
      if (!difficulty) {
        return
      }
      const count = data.opportunities_count;
      const savings = data.cost_impact_sum * 30;

      result.total.count += count;
      result.total.savings += savings;
      result[difficulty].count += count;
      result[difficulty].savings += savings;
    });

    return result;
  });

  return (
    <Section className={`p-[20px] relative ${className}`}>
      <div className='flex items-center mb-[20px]'>
        <SectionTitle>
          Unassigned Opportunities

          <InfoIcon>
            Opportunities are cost saving recommendations associated to your cloud resources
          </InfoIcon>
        </SectionTitle>
      
        <Button
          to='/savings/opportunities'
          size='xs'
          theme='link'
          layout='inline'
          iconRight='arrow-right'
          className='ml-auto'
        >
          Explore Opportunities
        </Button>
      </div>

      {summary.status === 'success' && (
        <SimpleTable className='text-right'>
          <thead>
            <SimpleTableHeadCell className='text-left'>
              Difficulty
            </SimpleTableHeadCell>

            <SimpleTableHeadCell>
              # of Opportunities
            </SimpleTableHeadCell>

            <SimpleTableHeadCell>
              Active Savings / Mo
            </SimpleTableHeadCell>
          </thead>

          <tbody>
          {Object.entries(summary.data).filter(([diffculty, _]) => diffculty != 'unknown').map(([difficulty, { count, savings }]) => (
            <SimpleTableRow className={difficulty === 'total' ? 'font-medium' : ''}>
              <SimpleTableCell className='text-left'>
                {difficulty === 'total' ?
                  <span className='ml-4'>
                    Total
                  </span> :
                  <DifficultyBadge difficulty={difficulty as 'easy' | 'medium' | 'hard'} />
                }
              </SimpleTableCell>

              <SimpleTableCell>
                {count}
              </SimpleTableCell>

              <SimpleTableCell>
                {formatCurrency(savings)}
              </SimpleTableCell>
            </SimpleTableRow>
          ))}
          </tbody>
        </SimpleTable>
      )}
      
      <StatusOverlay status={summary.status} />
    </Section>
  );
}
