import { useEffect, useState } from 'react'
import { Icon } from 'shared/Icon';
import GCPSelection from './GCPSelection';
import CFNStackSelection from './CFNStackSelection';


export interface ProviderSelectionProps {
  onboarding: boolean;
}

export const ProviderSelection = (props: ProviderSelectionProps) => {
  const { onboarding } = props;
  const [provider, setProvider] = useState<'aws' | 'gcp' | null>(null)

  return (
    <>
      <div className='mb-[20px] text-subtitle-1 font-medium'>New Collection Configs</div>
      <div
        className='flex p-4 mb-5 border-2 border-dotted rounded-lg hover:border-silver-grey-800'
        onClick={() => setProvider(provider === 'aws' ? null : 'aws')}
      >
        <div className='flex'>
          <Icon className={`my-auto ${provider !== 'aws' ? 'mr-1' : ''}`} icon={provider !== 'aws' ? 'arrow-right' : 'arrow-down'}/>
          <div className='flex ml-10'>
            <div className='h-[65px] w-[120px]'><Icon icon='aws' className='mx-auto h-[75px] w-[75px]'/></div>
            <span className="my-auto ml-10 text-2xl font-bold align-middle">Amazon Web Services (AWS)</span>
          </div>
        </div>
      </div>
      {provider === 'aws' && (
        <CFNStackSelection onboarding={onboarding} />
      )}
      <div 
        className='flex p-4 mb-5 border-2 border-dotted rounded-lg hover:border-silver-grey-800'
        onClick={() => setProvider(provider === 'gcp' ? null : 'gcp')}
      >
        <div className='flex'>
          <Icon className={`my-auto ${provider !== 'gcp' ? 'mr-1' : ''}`} icon={provider !== 'gcp' ? 'arrow-right' : 'arrow-down'}/>
          <div className='flex ml-10'>
            <Icon icon='gcp' className='h-[65px] w-[120px]'/>
            <span className="my-auto ml-10 text-2xl font-bold align-middle">Google Cloud Platform (GCP)</span>
          </div>
        </div>
      </div>
      {provider === 'gcp' && (
        <GCPSelection onboarding={onboarding} />
      )}
    </>
  )
}

export default ProviderSelection
