import { Icon } from 'shared/Icon';

export interface InputSearchProps {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: React.FocusEventHandler;
  onKeyPress?: React.KeyboardEventHandler;
}

export const InputSearch = (props: InputSearchProps) => {
  const{
    value,
    placeholder,
    onChange,
    onBlur,
    onKeyPress
  } = props;

  return (
    <div className='flex items-center grow'>
      <Icon
        icon='search'
        className='w-3 h-3 mr-2' 
      />

      <input
        type='text'
        className={`w-full text-body pt-0.5 pb-[1px] pl-5 -ml-5 bg-transparent outline-none border-b border-b-transparent
          hover:border-b-grey-200
          focus:border-b-blue
        `}
        value={value} 
        placeholder={placeholder}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
      />
    </div>
  );
}
