import type { OpportunityThresholds } from 'types/dataCollection';
import { Checkbox, message } from 'antd';
import { Button } from 'shared/Button';
import { Dialog } from 'shared/Dialog';
import { StatusOverlay } from 'shared/Overlay';
import { ThresholdEditor } from './ThresholdEditor';
import { AccountsList } from './AccountsList';
import { useDataConfigWithThresholdsStore, useThresholdEditDialog } from 'store/dataConfigs';
import { useSavingsStore } from 'store/savings';
import { mergeDrafts } from 'helpers/dataCollection';
import { useState } from 'react';

export const ThresholdEditDialog = () => {
  const {
    id: dialogKey,
    open,
    close
  } = useThresholdEditDialog();


  const ids = dialogKey && dialogKey.ids;
  const category = dialogKey && dialogKey.category;
  const title = dialogKey && dialogKey.title;

  const updateParams = useDataConfigWithThresholdsStore((store) => store.updateParams);
  const setDraft = useDataConfigWithThresholdsStore((store) => store.setDraft);
  const discardAllDrafts = useDataConfigWithThresholdsStore((store) => store.discardAllDrafts);
  const configs = useDataConfigWithThresholdsStore((store) => store.getLibrary());
  const drafts = useDataConfigWithThresholdsStore((store) => ids ? ids.map(store.getDraft) : null);
  const action = useDataConfigWithThresholdsStore((store) => ids ? store.actions.get(ids[0]) : null);

  const updateOpportunityPriorities = useSavingsStore((store) => store.updateOpportunityPriorities);

  const draft = drafts && mergeDrafts(drafts);

  const thresholds = draft !== null && category && draft.thresholds && draft.thresholds[category];
  const submitting = !!action && action.type === 'update' && action.status === 'in-progress';

  const onClose = () => {
    discardAllDrafts();
    close();
  };

  const [shouldUpdateOpps, setShouldUpdateOpps] = useState(false);

  return (
    <Dialog
      className='w-[800px] flex max-h-[80vh]'
      open={ids !== null}
      onClose={onClose}
    >
      <Dialog.Title>
        Edit Savings Opportunities Thresholds 
      </Dialog.Title>

      {ids && category && title && configs.status === 'success' && (
        <div className='relative flex overflow-hidden border-y border-y-silver-grey-100 shrink'>
          <AccountsList
            value={ids}
            onChange={(ids) => {
              open({
                ...dialogKey,
                ids
              });
            }}
          />

          {thresholds ?
            <div className='overflow-y-auto pl-[20px] pr-[30px]'>
              <h3 className='my-[20px]'>
                <b>Opportunities category:</b> {title}
              </h3>

              {Object.entries(thresholds)
                .filter(([thresholdKey]) => thresholdKey !== 'new_resource_waiting_period_days')
                .map(([thresholdKey, thresholdConfig]) => <>
                  <ThresholdEditor
                    config={thresholdConfig}
                    disabled={submitting}
                    onChange={(newThresholdConfig) => {
                      drafts && drafts.forEach((draft, i) => {
                        const newDraft = {
                          ...draft,
                          thresholds: {
                            ...draft.thresholds,
                            [category]: {
                              ...(draft.thresholds as OpportunityThresholds)[category],
                              [thresholdKey]: {
                                ...newThresholdConfig,
                                isUpdated: true,
                              }
                            }
                          }
                        };

                        setDraft(ids[i], newDraft);
                      });
                    }}
                  />
                  {thresholdKey === 'priority' && (
                    <Checkbox
                      className='mb-[20px]'
                      checked={shouldUpdateOpps}
                      onChange={(event) => {
                        setShouldUpdateOpps(event.target.checked);
                      }}
                    >
                      Update existing opportunities
                    </Checkbox>
                  )}
                </>)
              }
            </div> :
            <div className='flex items-center justify-center font-medium grow text-subtitle-1 text-silver-grey-700'>
              Select Accounts
            </div>
          }

          {submitting && <StatusOverlay status='loading' />}
        </div>
      )}
      
      <div className='flex justify-end p-[15px]'>
        <Button
          size='md'
          style='caladon'
          label='Update Thresholds'
          disabled={submitting}
          onClick={() => {
            if (!ids || !category || !draft || !draft.thresholds) {
              return;
            }

            let requests: Promise<any>[] = [];

            Object.entries(draft.thresholds[category]).forEach(([thresholdKey, thresholdConfig]) => {
              if (thresholdConfig.isUpdated !== undefined && thresholdConfig.isUpdated) {
                requests.push(updateParams({
                  data_collection_config_ids: ids,
                  parameter_group: category,
                  name: thresholdKey,
                  value: thresholdConfig.value + '',
                }))
              }
            });

            if (shouldUpdateOpps && thresholds && thresholds.priority) {
              requests.push(updateOpportunityPriorities(category, thresholds.priority.value as number));
            }

            Promise.all(requests)
              .then(() => {
                message.success('Thresholds updated');
                onClose();
              })
              .catch(() => {
                message.error('Unable to update thresholds');
              });
          }}
        />
      </div>
    </Dialog>
  )}
