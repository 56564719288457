import type { BillingOverview } from 'types/billing';

import moment from 'moment';
import { H1, P, A } from './Typography';
import { PlanText } from './PlanText';
// import { SubscribeButton } from './SubscribeButton';

export interface CanceledMessageProps {
  overview: BillingOverview;
}

export const CanceledMessage = (props: CanceledMessageProps) => {
  const {
    overview
  } = props;

  const {
    current_subscription,
    last_calculated_total_spend,
    last_calculated_start_date,
    last_calculated_end_date,
    last_calculated_plan
  } = overview;

  return (
    <div>
      <H1>
        Your Cloudthread subscription is active until
        {' '}
        {current_subscription?.cancel_at ?
          <strong className='text-negative'>
            {moment(current_subscription.cancel_at).format('MMMM DD, YYYY')}
          </strong>:
          ' the end of current subscription period'
        }
      </H1>

      <PlanText
        totalSpend={last_calculated_total_spend}
        startDate={last_calculated_start_date}
        endDate={last_calculated_end_date}
        plan={last_calculated_plan}
      />

      <P>
        Cloudthread computes your <b>last 31 days of spend</b> to determine your Tier.
      </P>

      {/*
        <H2>
          Billing
        </H2>
      */}

      <P>
        {/*Manage your billing information and subscription. */}
        For questions, contact <b><A href='mailto:support@cloudthread.io'>support@cloudthread.io</A></b>
      </P>

      {/**
        * TODO: bring back when resubscribe endpoint is in place
        *
        <SubscribeButton>
          Re-subscribe
        </SubscribeButton>
      */}
    </div>
  );
}
