import { Page } from 'shared/Page';
import { Grid } from 'shared/Grid';
import { Section } from 'shared/Section';
import { ThreadsTable } from './content/ThreadsTable';
import { ThreadsSummary } from './ThreadsSummary';
import { ThreadCreateDialog } from './dialogs/ThreadCreateDialog';
import { NonPayingBanner } from './NonPayingBanner';
import { ThreadFromSelectedButton } from './controls/ThreadFromSelectedButton'

export const ThreadsPage = () => {
  return (
    <Page>
      <Page.Head title='Savings Threads'>
        <ThreadFromSelectedButton />
      </Page.Head>
      

      <Grid className='pb-[60px]'>
        <NonPayingBanner className='col-span-12' />

        <ThreadsSummary className='col-span-6' />

        <Section className='p-5 col-span-12'>
          <ThreadsTable
            title='Threads'
            useQuery
          />
        </Section>
      </Grid>

      <ThreadCreateDialog />
    </Page>
  );
};
