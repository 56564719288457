import type { 
    DataConfig, 
    DetailedDataConfig,
    DataConfigUpdatePayload, 
    DataConfigCreatePayload,
    DataConfigsParamUpdatePayload,
    DataConfigSource,
    GCPServiceAccount,
    ParameterGroup
} from 'types/dataCollection';
import api from '../helpers/api'

export const fetchDataConfigs = () => api.get<DataConfig[]>('/configs/datacollector');
export const fetchDataConfig = (id: number) => api.get<DetailedDataConfig>(`/configs/datacollector/${id}`);
export const saveDataConfig = (source: DataConfigSource, payload: DataConfigCreatePayload) => api.post<DataConfig>(`/connectors/${source}/config`, payload)
export const updateDataConfig = (id: number, payload: DataConfigUpdatePayload) => api.patch<DataConfig>(`/configs/datacollector/${id}`, payload);
export const viewDataConfig = (id: number) => api.patch<DetailedDataConfig>(`/configs/datacollector/${id}/view-status`);
export const deleteDataConfig = (id: number) => api.delete(`/configs/datacollector/${id}`);

export const updateConfigsParams = (payload: DataConfigsParamUpdatePayload) => api.post<ParameterGroup[]>('/configs/datacollector/parameters', payload)
export const getConfigsParams = () => api.get<ParameterGroup[]>('/configs/datacollector/parameters')

export const getCFNStack = (id: number) => api.get(`/cfn-stacks/${id}`);
export const getCFNStacks = () => api.get(`/cfn-stacks`);
export const saveCFNStack = (stack: any) => api.post(`/cfn-stacks`, stack);
export const deleteCFNStack = (id: number) => api.delete(`/cfn-stacks/${id}`);

export const getGCPServiceAccount = () => api.get<GCPServiceAccount>('/connectors/gcp/config/service-account')
export const validateDataConfig = (source: DataConfigSource, payload: DataConfigCreatePayload) => api.post<boolean>(`/connectors/${source}/config/validate`, payload)
