import type { Role } from 'types/users';

import { useMemo } from 'react';

import { message, Input, Select } from 'antd';
import { Dialog, DialogBody } from 'shared/Dialog';
import { Button } from 'shared/Button';

import { validateEmail } from 'helpers/validator';

import { useUsersStore } from 'store/users';
import { useTeamsStore } from 'store/teams';
import { useInviteDialogStore } from 'store/inviteDialogStore';
import {FormRow, Label} from 'shared/Form';

export const InviteDialog = () => {
  const { opened, close } = useInviteDialogStore();

  const draft = useUsersStore((store) => store.getDraft('new'));
  const setDraft = useUsersStore((store) => store.setDraft);
  const discardDraft = useUsersStore((store) => store.discardDraft);
  const createEntry = useUsersStore((store) => store.createEntry);

  const teams = useTeamsStore((store) => store.getLibrary());

  const { name, email, role, team_id } = draft;

  const nameIsValid = name.trim().length > 0;
  const emailIsValid = useMemo(() => validateEmail(email), [email]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;

    setDraft('new', {
      ...draft,
      name
    });
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;

    setDraft('new', {
      ...draft,
      email
    });
  };

  const onRoleChange = (role: Role) => {
    setDraft('new', {
      ...draft,
      role
    });
  }

  const teamOptions = useMemo(() => {
    if (teams.status !== 'success') {
      return [];
    }

    return [
      {
        value: 'none',
        label: 'No Team *'
      },
      ...teams.data
        .map((team) => ({
          value: team.id + '',
          label: team.name
        }))
    ];
  }, [teams]);

  const onTeamIdChange = (teamId: string) => {
    setDraft('new', {
      ...draft,
      team_id: teamId === 'none' ?  null : +teamId
    });
  };

  return <Dialog
    className='w-[420px]'
    open={opened}
    onClose={() => {
      discardDraft('new');
      close();
    }}
  >
    <Dialog.Title>
      Invite Users to Your Team
    </Dialog.Title>

    <DialogBody>
    <FormRow size='m'>
      <Label size='m'>
        Name
      </Label>

      <Input
        size='large'
        value={name}
        onChange={onNameChange}
      />
    </FormRow>

    <FormRow size='m'>
      <Label size='m'>
        E-mail
      </Label>

      <Input
        size='large'
        value={email}
        onChange={onEmailChange}
      />
    </FormRow>
      

    <FormRow size='m'>
      <Label size='m'>
        Role
      </Label>

      <Select
        className='w-full'
        value={role}
        size='large'
        options={[
          { value: 'admin', label: 'Admin' },
          { value: 'member', label: 'Member' }
        ]}
        onChange={onRoleChange}
      />
    </FormRow>

    <FormRow size='m'>
      <Label size='m'>
        Team
      </Label>

      <Select
        className='w-full'
        size='large'
        value={team_id ? team_id + '' : 'none'}
        disabled={teamOptions.length === 0}
        options={teamOptions}
        onChange={onTeamIdChange}
      />

      {team_id === null && (
        <div className='mt-2 text-gray-500 text-caption-1'>
          <b>*</b> If not assigned to a team, the user will have access to <b>all</b> of the organisation's cloud spend
        </div>
      )}
    </FormRow>

    <Button
      className='my-8 ml-auto'
      style='caladon'
      label='Invite User'
      disabled={!nameIsValid || !emailIsValid}
      onClick={() => {
        createEntry('new')
          .then((newUser) => {
            message.success(`User ${newUser.name} (${newUser.email}) invited`);
            close();
          })
          .catch(() => {
            message.error('Cannot invite user');
          });
      }}
    />
    </DialogBody>
  </Dialog>
}
