import type { DataEntry } from 'types/dataEntries';
import type { DrillDownData } from 'types/tagViews/drillDown';

import { ValueBox } from 'shared/ValueBox';
import { GroupByType } from 'services/metric';
import { formatPercent } from 'helpers/formatter';
import { useDrillDownQuery } from 'hooks/useDrillDownQuery';

const LABELS: Record<GroupByType, string> = {
  accounts: 'Accounts',
  regions: 'Regions',
  services: 'Services',
  usage_types: 'Usage Types',
  operations: 'Operations',
  resources: 'Resources',
}

export interface HeaderStatusProps {
  drillDown: DataEntry<DrillDownData>;
}

export const HeaderStatus = (props: HeaderStatusProps) => {
  const { drillDown } = props;
  const { breakdown } = useDrillDownQuery();

  const drilldownTableData = drillDown.status === 'success' ? drillDown.data.table_data : [];

  const currentTotal = drilldownTableData.reduce((st, next) => st + next.current_timeframe_tagged_percent, 0)

  const format = formatPercent;

  const values = [
    { label: `Total ${LABELS[breakdown]}`, text: drilldownTableData.length },
    { label: 'Current Average', text: format(currentTotal / drilldownTableData.length) },
  ]

  return (
    <div className="flex">
      {values.map((v) => <ValueBox.SimpleValueBox key={v.label} className="mr-7" label={v.label} text={v.text} />)}
    </div>
  )
};
