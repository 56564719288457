import type { JiraSeverCreatePayload } from 'types/users';

import { Input } from 'antd';

export interface JiraServerFormProps {
  value: JiraSeverCreatePayload;
  onChange: (value: JiraSeverCreatePayload) => void;
}

export const JiraServerForm = (props: JiraServerFormProps) => {
  const {
    value,
    onChange
  } = props;


  return (
    <div>
      <div className='mb-1 font-medium'>
        Client ID
      </div>
      <Input
        value={value.client_id}
        onChange={(event) => {
          const client_id = event.target.value;

          onChange({
            ...value,
            client_id
          });
        }}
      />

      <div className='mt-4 mb-1 font-medium'>
        Client Secret
      </div>
      <Input
        value={value.client_secret || ''}
        onChange={(event) => {
          const client_secret = event.target.value;

          onChange({
            ...value,
            client_secret
          });
        }}
      />

      <div className='mt-4 mb-1 font-medium'>
        Jira Server Url (including 'https://')
      </div>
      <Input
        value={value.jira_server_url}
        onChange={(event) => {
          const jira_server_url = event.target.value;

          onChange({
            ...value,
            jira_server_url
          });
        }}
      />
    </div>
  );
};
