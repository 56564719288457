import type { DataEntry } from 'types/dataEntries';
import type { OpportunitiesParams } from 'types/savings';

import { useDerivedDataEntry } from 'hooks/derivedDataEntry';
import { identity } from 'helpers/utils';
import { formatCurrency, pluralize } from 'helpers/formatter';
import { useCostImpactSummary } from 'store/savings/costImpactSummary';

export interface OpportunitiesTableSummaryProps {
  opportunitiesParams: OpportunitiesParams | DataEntry<OpportunitiesParams>;
}

export const OpportunitiesTableSummary = (props: OpportunitiesTableSummaryProps) => {
  const {
    opportunitiesParams
  } = props;

  const summaryParams = useDerivedDataEntry({
    opportunitiesParams
  }, ({
    opportunitiesParams
  }) => ({
    prefilter: opportunitiesParams.prefilter,
    pageFilters: opportunitiesParams.pageFilters,
    tableFilters: {},
  }));

  const costImpactSummary = useCostImpactSummary(summaryParams);
  const filteredCostImpactSummary = useCostImpactSummary(opportunitiesParams);

  const data = useDerivedDataEntry({ 
    costImpactSummary, 
    filteredCostImpactSummary
  }, identity);

  if (data.status !== 'success') {
    return null;
  }

  if (data.data.costImpactSummary.length === 0 || data.data.filteredCostImpactSummary.length === 0) {
    return null;
  }

  const totalData = data.data.costImpactSummary[0];
  const filteredData = data.data.filteredCostImpactSummary[0];

  return (
    <>
      {filteredData.opportunities_count < totalData.opportunities_count && 
        `${filteredData.opportunities_count} out of `
      }
      {totalData.opportunities_count}
      {' '}
      {pluralize(totalData.opportunities_count, { one: 'opportunity', other: 'opportunities' })}
      {' ≈ '}
      {filteredData.opportunities_count < totalData.opportunities_count &&
        `${formatCurrency(filteredData.cost_impact_sum * 30)} out of `
      }
      {formatCurrency(totalData.cost_impact_sum * 30)} monthly savings
    </>
  );
}
