import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export type UseSearchTermResult = [
  string,
  (searchTerm: string) => void
]

export const useSearchTerm = (): UseSearchTermResult => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchTermParam = searchParams.get('searchTerm');

  const searchTerm: string = searchTermParam ? searchTermParam : '';

  const setSearchTerm = useCallback((newSearchTerm: string) => {
    if (newSearchTerm === searchTerm) {
      return;
    }

    const newSearchParams = new URLSearchParams(searchParams);

    if (newSearchTerm === '') {
      newSearchParams.delete('searchTerm');
    } else {
      newSearchParams.set('searchTerm', newSearchTerm);
    }

    setSearchParams(newSearchParams, { replace: true });
  }, [searchParams]);

  return [
    searchTerm,
    setSearchTerm
  ];
};
