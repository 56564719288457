import { createKeyedStoreChunk } from '../createKeyedStoreChunk';
import { fetchCostImpactSummaryChunk } from 'services/savings';
import { costImpactSummaryParamsToKeyChunk } from 'helpers/savings';
import { createKeyedStoreSelector } from 'store/createKeyedStoreSelector';

export const useCostImpactSummaryStoreChunk = createKeyedStoreChunk({
  fetchData: fetchCostImpactSummaryChunk,
  paramsToKey: costImpactSummaryParamsToKeyChunk
});

export const useCostImpactSummaryChunk = createKeyedStoreSelector(useCostImpactSummaryStoreChunk);
