import { isSortDir, QueryParamParser, QueryParamSerializer, Sort, SortDir, ValueAndSetter } from 'types/common';
import type { RangeDataFilterValue } from 'types/dataFilters';

import { useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useRangeQueryParam = <T>({
  name,
  parse,
  serialize,
} : {
  name: string;
  parse: (param: string) => T | null;
  serialize: (value: T | null) => string;
}, defaults?: {
  min: T,
  max: T
}): ValueAndSetter<RangeDataFilterValue<T>> => {
  const [searchParams, setSearchParams] = useSearchParams();

  let param = searchParams.get(name);

  const value: RangeDataFilterValue<T> = useMemo(() => {
    let value: RangeDataFilterValue<T> = {
      min: null,
      max: null
    };

    if (param) {
      const [minParam, maxParam] = param.split(',');
      const min = parse(minParam);
      const max = parse(maxParam);

      if (!defaults || min !== defaults.min || max !== defaults.max) {
        value = { min, max };
      }
    }

    return value;
  }, [param, defaults]);

  const setValue = (newValue: RangeDataFilterValue<T>, externalParams?: URLSearchParams) => {
    let newParams = externalParams || new URLSearchParams(searchParams);

    if (newValue.min !== null || newValue.max !== null) {
      newParams.set(name, [
        serialize(newValue.min),
        serialize(newValue.max)
      ].join(','));
    } else {
      newParams.delete(name);
    }

    if (!externalParams) {
      setSearchParams(newParams, { replace: true });
    }
  };

  return [value, setValue];
}

export const useBooleanQueryParam = (name: string): ValueAndSetter<boolean> => useQueryParam({
  name,
  parse: (param) => !!param,
  serialize: (value) => value ? 'true' : null
});

export const useTextQueryParam = <T = string>(
  name: string,
  {
    parse,
    serialize
  } : {
    parse: (param: string | null) => T | null,
    serialize: (value: T | null) => string | null
  }
): [T | null, (value: T | null) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = parse(searchParams.get(name)) || null;

  const setValue = (newValue: T | null, externalParams?: URLSearchParams) => {
    let newParams = externalParams || new URLSearchParams(searchParams);

    const newParam = serialize(newValue);

    if (newParam) {
      newParams.set(name, newParam);
    } else {
      newParams.delete(name);
    }

    if (!externalParams) {
      setSearchParams(newParams, { replace: true });
    }
  }

  return [value, setValue];
}

export const useQueryParam = <T>(params: {
  name: string,
  parse: QueryParamParser<T>,
  serialize: QueryParamSerializer<T>
}): ValueAndSetter<T> => {
  const {
    name,
    parse,
    serialize
  } = params;
  const [searchParams, setSearchParams] = useSearchParams();
  const param = searchParams.get(name);

  const value = useMemo(() => parse(param), [param, parse]);

  const setValue = (newValue: T, externalParams?: URLSearchParams) => {
    const newParam = serialize(newValue);

    if (newParam === param) {
      return;
    }

    const newSearchParams = externalParams || new URLSearchParams(searchParams);
    
    if (newParam === null) {
      newSearchParams.delete(name);
    } else {
      newSearchParams.set(name, newParam);
    }

    if (!externalParams) {
      setSearchParams(newSearchParams, { replace: true });
    }
  };

  return [value, setValue];
};

export const useListQueryParam = <T>(params: {
  name: string,
  parse: (paramPart: string) => T,
  serialize: QueryParamSerializer<T>
}): ValueAndSetter<T[]> => {
  const {
    name,
    parse,
    serialize
  } = params;

  const parseList = useCallback(
    (param: string | null) =>
       param === null ?
        [] :
        param.split(',')
          .map(parse)
          .filter((val) => val !== null) as T[],
    [parse]
  );

  const serializeList = useCallback(
    (value: T[]) => value.map(serialize).join(',') || null,
    [serialize]
  );

  return useQueryParam({
    name,
    parse: parseList,
    serialize: serializeList
  });
};


export const useSortQueryParams = <TField extends string>(params: {
  fieldParamName?: string,
  dirParamName?: string,
  defaultField: TField,
  isField: (field: any) => field is TField,
  defaultDirs: Record<TField, SortDir>
}): ValueAndSetter<Sort<TField>> => {
  const {
    fieldParamName = 'sortBy',
    dirParamName = 'sortDir',
    defaultField,
    isField,
    defaultDirs
  } = params;

  const [searchParams, setSearchParams] = useSearchParams();

  const fieldParam = searchParams.get(fieldParamName);
  const dirParam = searchParams.get(dirParamName);

  const field: TField = isField(fieldParam) ? fieldParam : defaultField;
  const dir: SortDir = isSortDir(dirParam) ? dirParam : defaultDirs[field];

  const value: Sort<TField> = {
    field,
    dir
  };

  const setValue = (value: Sort<TField>) => {
    const newSearchParams = new URLSearchParams(searchParams);

    if (value.field === defaultField) {
      newSearchParams.delete(fieldParamName);
    } else {
      newSearchParams.set(fieldParamName, value.field);
    }

    if (value.dir === defaultDirs[value.field]) {
      newSearchParams.delete(dirParamName);
    } else {
      newSearchParams.set(dirParamName, value.dir);
    }

    setSearchParams(newSearchParams, { replace: true });
  }

  return [value, setValue];
}
