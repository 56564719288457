import type {
  UnitMetricConstructor,
  UnitMetricFilter,
  UnitMetricFilterPart,
  UnitMetricConstructorPart,
  UnitMetricFilterCondition,
  ImmutableUnitMetricFilter,
  UnitMetricFilterWithTeam
} from 'types/unitMetrics';

import type {
  ConstructorRules,
  ImmutableFilterCondition
} from 'types/filters';

import { Record as ImmutableRecord } from 'immutable';
import {
  parseFilter,
  toImmutableCondition,
  validateFilter,
  cleanupCondition
} from 'helpers/filters';
import type { CostViewFilter } from 'types/costViews';

const parseUnitMetricFilterCondition = parseFilter as (filter: UnitMetricFilterCondition, constructor: ConstructorRules) => UnitMetricFilterCondition;

export const parseUnitMetricFilter = (
  filter: UnitMetricFilter,
  constructor: UnitMetricConstructor
): UnitMetricFilter => {

  return {
    numerator: {
      src: filter.numerator.src,
      filter: parseUnitMetricFilterCondition(
        filter.numerator.filter,
        constructor.numerator[filter.numerator.src].rules
      )
    },
    denominator: {
      src: filter.denominator.src,
      filter: parseUnitMetricFilterCondition(
        filter.denominator.filter,
        constructor.denominator[filter.denominator.src].rules
      )
    },
  };
};

const createImmutableUnitMetricFilterPart = ImmutableRecord<{
  src: string;
  filter: ImmutableFilterCondition | null;
}>({
  src: '',
  filter: toImmutableCondition({ operator: 'and', condition: [] })
});

const createImmutableUnitMetricFilter = ImmutableRecord<{
  numerator: ImmutableRecord<{
    src: string;
    filter: ImmutableFilterCondition | null;
  }>;
  denominator: ImmutableRecord<{
    src: string;
    filter: ImmutableFilterCondition | null;
  }>;
}>({
  numerator: createImmutableUnitMetricFilterPart(),
  denominator: createImmutableUnitMetricFilterPart(),
});

export const toImmutableUnitMetricFilter = (filter: UnitMetricFilter): ImmutableUnitMetricFilter => {
  return createImmutableUnitMetricFilter({
    numerator: createImmutableUnitMetricFilterPart({
      src: filter.numerator.src,
      filter: toImmutableCondition(filter.numerator.filter)
    }),
    denominator: createImmutableUnitMetricFilterPart({
      src: filter.denominator.src,
      filter: toImmutableCondition(filter.denominator.filter)
    }),
  });
}

export const validateUnitMetricFilterPart = (filter: UnitMetricFilterPart, unitMetricConstructorPart: UnitMetricConstructorPart): boolean => {
  const {
    src
  } = filter;

  const {
    rules
  } = unitMetricConstructorPart[src];

  return validateFilter(
    filter,
    {
      [src]: rules
    }
  );
};

export const validateUnitMetricFilter = (filter: UnitMetricFilter, unitMetricConstructor: UnitMetricConstructor): boolean => {
  return (
    validateUnitMetricFilterPart(filter.numerator, unitMetricConstructor.numerator) &&
    validateUnitMetricFilterPart(filter.denominator, unitMetricConstructor.denominator)
  );
}

export const cleanupUnitMetricFilter = (filter: UnitMetricFilter): UnitMetricFilter => {
  return {
    ...filter,
    numerator: {
      ...filter.numerator,
      filter: cleanupCondition(filter.numerator.filter) || { operator: 'and', condition: [] },
    },
    denominator: {
      ...filter.denominator,
      filter: cleanupCondition(filter.denominator.filter) || { operator: 'and', condition: [] },
    }
  };
};

export const appendTeamFilters = (filter: UnitMetricFilter, numeratorTeamFilter: CostViewFilter | null, denominatorTeamFilter: CostViewFilter | null): UnitMetricFilterWithTeam => {
  return {
    numerator: (numeratorTeamFilter && numeratorTeamFilter.filter.condition.length > 0) ? {
      src: filter.numerator.src,
      filter: {
        operator: 'and',
        condition: [
          numeratorTeamFilter.filter,
          filter.numerator.filter
        ]
      }
    } : filter.numerator,
    denominator: (denominatorTeamFilter && denominatorTeamFilter.filter.condition.length > 0) ? {
      src: filter.denominator.src,
      filter: {
        operator: 'and',
        condition: [
          denominatorTeamFilter.filter,
          filter.denominator.filter
        ]
      }
    } : filter.numerator,
  }
};
