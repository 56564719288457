import type {
  CostViewFilter,
  CostViewConstructor,
  ImmutableCostViewFilter
} from 'types/costViews';

import {
  parseFilter,
  toImmutableFilter,
  validateFilter,
  cleanupCondition
} from 'helpers/filters';

export const parseCostViewFilter = (filter: CostViewFilter, costViewConstructor: CostViewConstructor): CostViewFilter => {
  let { condition } = filter.filter;
  const { minItems, maxItems, rules } = costViewConstructor[filter.src];

  if (minItems !== undefined) {
    condition = [...condition];

    while (condition.length < minItems) {
      condition.push({
        operator: 'and',
        condition: []
      });
    }
  }

  if (maxItems !== undefined) {
    condition = condition.slice(0, maxItems);
  }

  condition = condition.map((item) => parseFilter(item, rules));

  return {
    src: filter.src,
    filter: {
      ...filter.filter,
      condition
    }
  };
}

export const validateCostViewFilter = (filter: CostViewFilter, costViewConstructor: CostViewConstructor): boolean => {
  const {
    src,
    filter: {
      condition
    }
  } = filter;

  const {
    rules,
    minItems = 0,
    maxItems = Infinity
  } = costViewConstructor[src];

  return (
    condition.length >= minItems && 
    condition.length <= maxItems &&
    condition.every((item) => validateFilter(
      {
        src,
        filter: item
      },
      {
        [src]: rules
      }
    ))
  );
};

export const cleanupCostViewFilter = (filter: CostViewFilter): CostViewFilter => {
  return {
    ...filter,
    filter: cleanupCondition(filter.filter) || { operator: 'or', condition: [] }
  };
}

export const toImmutableCostViewFilter = toImmutableFilter as (filter: CostViewFilter) => ImmutableCostViewFilter;
