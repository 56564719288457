import type { ConstructorRules } from 'types/filters';
import type { RuleValueCondition } from 'types/rules';

import { Rule } from 'shared/Filters';
import { getNodeConstructorRule } from 'helpers/rules';

export interface ValueSelectorProps {
  field: string;
  value: RuleValueCondition;
  parentConditions?: RuleValueCondition[];
  constructorRules: ConstructorRules;
  siblingValues?: string[] | Record<string, string>[];
  onChange: (value: RuleValueCondition) => void;
  onRemove?: () => void;
}

export const ValueSelector = (props: ValueSelectorProps) => {
  const {
    field,
    value,
    constructorRules,
    parentConditions = [],
    siblingValues = [],
    onChange,
    onRemove
  } = props;

  const constructorRule = getNodeConstructorRule(constructorRules, parentConditions, field);

  return (
    <Rule
      condition={value}
      rule={constructorRule}
      excludedValues={siblingValues}
      onChange={onChange}
      onRemove={onRemove}
    />
  );
}
