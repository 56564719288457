import type { Team } from 'types/teams';
import { Link } from 'react-router-dom';
import { ListEditor } from './ListEditor';
import { useTeamUnitMetricsStore } from 'store/teamUnitMetrics';

export interface TeamUnitMetricsProps {
  team: Team;
}

export const TeamUnitMetrics = (props: TeamUnitMetricsProps) => {
  const {
    team
  } = props;

  const teamEntry = useTeamUnitMetricsStore((state) => state.getEntry(team.id));
  const allUnitMetrics = useTeamUnitMetricsStore((state) => state.getAllUnitMetrics());
  const addUnitMetric = useTeamUnitMetricsStore((state) => state.addTeamUnitMetric);
  const confirmAddition = useTeamUnitMetricsStore((state) => state.confirmAddition);
  const removeUnitMetric = useTeamUnitMetricsStore((state) => state.removeTeamUnitMetric);
  const confirmRemoval = useTeamUnitMetricsStore((state) => state.confirmRemoval);

  if (teamEntry.status === 'idle') {
    return null;
  }

  if (teamEntry.status === 'loading') {
    return <div>
      Loading
    </div>;
  }

  if (teamEntry.status === 'load-error') {
    return <div>
      Error
    </div>;
  }


  return (
    <ListEditor
      items={teamEntry.data.toArray().map(([_, unitMetric]) => unitMetric)}
      addOptions={allUnitMetrics.status === 'loaded' ? 
        allUnitMetrics.data.map((metric) => ({
          status: 'loaded',
          data: metric
        })) :
        []
      }
      getItemKey={(unitMetric) => '' + unitMetric.data.id}
      getItemTitle={(unitMetric) => unitMetric.data.name}
      addedMessage={(unitMetric) => `Unit metric ${unitMetric.data.name} added to team ${team.name}`}
      addErrorMessage={(unitMetric) => `Unable to add unit metric ${unitMetric.data.name} to team ${team.name}`}
      removedMessage={(unitMetric) => `Unit metric ${unitMetric.data.name} removed from team ${team.name}`}
      removeErrorMessage={(unitMetric) => `Unable to add unit metric ${unitMetric.data.name} from team ${team.name}`}
      onAdd={(unitMetric) => {
        addUnitMetric(team.id, unitMetric.data.id, unitMetric.data);
      }}
      onAddConfirm={(unitMetric) => {
        confirmAddition(team.id, unitMetric.data.id);
      }}
      onRemove={(unitMetric) => {
        removeUnitMetric(team.id, unitMetric.data.id);
      }}
      onRemoveConfirm={(unitMetric) => {
        confirmRemoval(team.id, unitMetric.data.id);
      }}
    >
      {(unitMetric) => <Link to={`/unit-metrics/${unitMetric.data.id}`}>{unitMetric.data.name}</Link>}
    </ListEditor>
  );
};
