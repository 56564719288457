import {
  ThresholdConfig,
  ThresholdConfigs,
  ThresholdsSummary,
  DetailedDataConfig,
  isThresholdConfigSummary
} from 'types/dataCollection';

import { useMemo, Fragment } from 'react';
import { Table, Tooltip } from 'antd';
import { Button } from 'shared/Button';
import { useThresholdEditDialog } from 'store/dataConfigs/thresholdEditDialog';
import { SummaryValue } from './SummaryValue';

export interface ThresholdsTableProps {
  dataConfig: DetailedDataConfig | ThresholdsSummary | null;
}

const isThresholdsSummary = (val: DetailedDataConfig | ThresholdsSummary | null) : val is ThresholdsSummary => !!(val && (val as any).thresholds);

export const ThresholdsTable = (props: ThresholdsTableProps) => {
  const {
    dataConfig
  } = props;

  const isSummary = isThresholdsSummary(dataConfig);

  const thresholds: [string, string, ThresholdConfigs][] = useMemo(() => {
    if (!dataConfig) {
      return []
    }

    const thresholdsMap = isSummary ?
      dataConfig.thresholds :
      dataConfig.collection_details._opportunity_thresholds;

    const titles = isSummary ?
      dataConfig.thresholdsTitles :
      dataConfig.collection_details._opportunity_thresholds_titles;

    return Object.entries(thresholdsMap)
      .filter(([_, thresholdConfigs]) => {
        const configKeys = Object.keys(thresholdConfigs);

        return configKeys.length > 1 || configKeys[0] !== 'new_resource_waiting_period_days'
      }).map(([key, value]) => [key, titles[key], value])
  }, [dataConfig]);

  const openThresholdEditDialog = useThresholdEditDialog((store) => store.open);

  return (
    <Table
      size='small'
      rowKey={([category]) => category}
      dataSource={thresholds}
      columns={[
        {
          title: 'Category',
          key: 'category',
          sortOrder: 'ascend',
          render: ([_, title]) => (
            <Tooltip title={title}>
              <div className='truncate w-[300px]'>{title}</div>
            </Tooltip>
          ),
          sorter: (a, b) => a[0] > b[0] ? 1 : a[0] < b[0] ? -1 : 0,
        },
        {
          title: 'Thresholds',
          key: 'thresholds',
          render: ([category, title, configs]) => {
            const configsList: [string, ThresholdConfig][] = Object.entries(configs);

            return <>
              {configsList
                .filter(([key]) => key !== 'new_resource_waiting_period_days')
                .map(([key, config]) =>
                  <div key={key} className='mb-2 last:mb-0'>
                    {config.title}
                    {': '}
                    {isThresholdConfigSummary(config) ?
                      Object.entries(config.values)
                        .map(([value, configIds], index, array) => 
                          <Fragment key={value}>
                            <SummaryValue
                              value={value}
                              configIds={configIds}
                              category={category}
                              title={title}
                            />
                            {index < array.length -1 && ', '}
                          </Fragment>
                        ):
                      <b>{config.value + ''}</b>
                  }
                  </div>
                )
              }
            </>;
          }
        },
        {
          key: 'edit',
          width: 1,
          render: ([category, title]) => (
            <Button
              size='sm'
              type='tertiary'
              icon='edit'
              label='Edit Thresholds'
              onClick={() => {
                dataConfig && openThresholdEditDialog({
                  ids: isSummary ? dataConfig.ids : [dataConfig.id as number],
                  category,
                  title,
                });
              }}
            />
          )
        }
      ]}
    />
  );
};
