import { fetchUnitMetricChart } from 'services/unitMetrics';
import { unitMetricChartFetchParamsToKey } from 'helpers/storeKeys/unitMetricChart';
import { createKeyedStore } from './createKeyedStore';
import { createKeyedStoreSelector } from './createKeyedStoreSelector';

export const useUnitMetricChartStore = createKeyedStore({
  paramsToKey: unitMetricChartFetchParamsToKey,
  fetchData: fetchUnitMetricChart
});

export const useUnitMetricChart = createKeyedStoreSelector(useUnitMetricChartStore);
