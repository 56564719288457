import { createKeyedStore } from './createKeyedStore';
import { createKeyedStoreSelector } from './createKeyedStoreSelector';

import { fetchDictValues } from 'services/dictValues';

export const useDictValuesStore = createKeyedStore({
  fetchData: fetchDictValues,
  paramsToKey: ({ field, key, path }: { field: string, key: string, path?: string[] }) => `${field}//${key}${path ? `[${path.join('//')}]` : ''}`
});

export const useDictValues = createKeyedStoreSelector(useDictValuesStore);
